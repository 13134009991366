/**
 * @generated SignedSource<<141c07966f0f971a38308a08b68e49fd>>
 * @relayHash 9ff632bd13bd31ff0c375d1f0743c1d2
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3f977a90565c1816dc370ed17ec2be894299acbf93113e12ac815f85079aecc7

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type srcVirtualAgentAiAnswersQuery$variables = {
  cloudId: string;
  projectKey: string;
};
export type srcVirtualAgentAiAnswersQuery$data = {
  readonly jira: {
    readonly jiraProjectByKey: {
      readonly key: string;
      readonly name: string;
      readonly virtualAgentConfiguration: {
        readonly __typename: string;
        readonly " $fragmentSpreads": FragmentRefs<"ui_servicedeskSettingsVirtualAgentAiAnswers_VirtualAgentAiAnswers_virtualAgentConfiguration">;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"ui_servicedeskSettingsVirtualAgentAiAnswers_VirtualAgentAiAnswers_jiraProjectRef" | "virtualAgentPageWrapper_servicedeskSettingsVirtualAgentCommon_projectRef">;
    };
  };
  readonly virtualAgent: {
    readonly virtualAgentEntitlements: {
      readonly " $fragmentSpreads": FragmentRefs<"ui_servicedeskSettingsVirtualAgentAiAnswers_VirtualAgentAiAnswers_virtualAgentFeatures">;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"virtualAgentPageWrapper_servicedeskSettingsVirtualAgentCommon_featuresRef">;
};
export type srcVirtualAgentAiAnswersQuery = {
  response: srcVirtualAgentAiAnswersQuery$data;
  variables: srcVirtualAgentAiAnswersQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider": checkForAiEnabledFF_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider: {
    readonly get: () => boolean;
  };
});

import checkForAiEnabledFF_provider from '@atlassian/jira-relay-provider/src/jsm-va-is-require-ai-enabled.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v2 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v3 = [
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "projectKey"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "name"
},
v5 = {
  "kind": "ScalarField",
  "name": "key"
},
v6 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v7 = [
  (v2/*: any*/)
],
v8 = {
  "kind": "ScalarField",
  "name": "id"
},
v9 = [
  (v8/*: any*/)
],
v10 = [
  {
    "kind": "ScalarField",
    "name": "isAiEnabledInAdminHub"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "srcVirtualAgentAiAnswersQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v3/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProjectByKey",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "virtualAgentPageWrapper_servicedeskSettingsVirtualAgentCommon_projectRef"
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "virtualAgentConfiguration",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "kind": "FragmentSpread",
                        "name": "ui_servicedeskSettingsVirtualAgentAiAnswers_VirtualAgentAiAnswers_virtualAgentConfiguration"
                      }
                    ]
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "ui_servicedeskSettingsVirtualAgentAiAnswers_VirtualAgentAiAnswers_jiraProjectRef"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.jiraProjectByKey"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      },
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "VirtualAgentQueryApi",
          "kind": "LinkedField",
          "name": "virtualAgent",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v7/*: any*/),
                "concreteType": "VirtualAgentFeatures",
                "kind": "LinkedField",
                "name": "virtualAgentEntitlements",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "ui_servicedeskSettingsVirtualAgentAiAnswers_VirtualAgentAiAnswers_virtualAgentFeatures"
                  }
                ]
              },
              "action": "THROW",
              "path": "virtualAgent.virtualAgentEntitlements"
            }
          ]
        },
        "action": "THROW",
        "path": "virtualAgent"
      },
      {
        "args": (v7/*: any*/),
        "kind": "FragmentSpread",
        "name": "virtualAgentPageWrapper_servicedeskSettingsVirtualAgentCommon_featuresRef"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "srcVirtualAgentAiAnswersQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProjectByKey",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "name": "virtualAgentConfiguration",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v8/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "isAiResponsesEnabled"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "defaultJiraRequestTypeId"
                      }
                    ],
                    "type": "VirtualAgentConfiguration"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v9/*: any*/),
                    "type": "VirtualAgentQueryError"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v9/*: any*/),
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ]
              },
              (v4/*: any*/),
              {
                "args": (v7/*: any*/),
                "kind": "LinkedField",
                "name": "knowledgeBaseArticlesCount",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "count"
                      }
                    ],
                    "type": "KnowledgeBaseArticleCountSource"
                  }
                ]
              },
              (v8/*: any*/),
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "__id"
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "concreteType": "VirtualAgentQueryApi",
        "kind": "LinkedField",
        "name": "virtualAgent",
        "plural": false,
        "selections": [
          {
            "args": (v7/*: any*/),
            "concreteType": "VirtualAgentFeatures",
            "kind": "LinkedField",
            "name": "virtualAgentEntitlements",
            "plural": false,
            "selections": (v10/*: any*/)
          }
        ]
      },
      {
        "concreteType": "VirtualAgentQueryApi",
        "kind": "LinkedField",
        "name": "virtualAgent",
        "plural": false,
        "selections": [
          {
            "args": (v7/*: any*/),
            "concreteType": "VirtualAgentFeatures",
            "kind": "LinkedField",
            "name": "virtualAgentEntitlements",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "isVirtualAgentAvailable"
              },
              {
                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider",
                "kind": "Condition",
                "passingValue": true,
                "selections": (v10/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "3f977a90565c1816dc370ed17ec2be894299acbf93113e12ac815f85079aecc7",
    "metadata": {},
    "name": "srcVirtualAgentAiAnswersQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider": checkForAiEnabledFF_provider
    }
  }
};
})();

(node as any).hash = "19529bb28005c693e530427ab6ca9cc6";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;

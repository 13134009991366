import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorTitle: {
		id: 'issue-field-team.error-title',
		defaultMessage: "We couldn't set the team value",
		description: 'Error message informing the user that updating the team field failed',
	},
	errorMessage: {
		id: 'issue-field-team.error-message',
		defaultMessage: 'Check your connection, then give it another try.',
		description: 'Error message when save fails',
	},
	teamErrorViewTooltip: {
		id: 'issue-field-team.team-error-view-tooltip',
		defaultMessage: 'This team may have been deleted, or you do not have access',
		description:
			'Message displayed when hovering the team field and information about the team is deleted or user does not have access',
	},
	teamErrorViewText: {
		id: 'issue-field-team.team-error-view-text',
		defaultMessage: 'Not available',
		description:
			'Message displayed in the team field when information about the team is not available',
	},
	placeholderTeamEditSelector: {
		id: 'issue-field-team.placeholder-team-edit-selector',
		defaultMessage: 'Choose a team',
		description: 'A placeholder shown in the team field while no value is selected',
	},
	defaultNoValueText: {
		id: 'issue-field-team.default-no-value-text',
		defaultMessage: 'None',
		description: 'The default text displayed on the no team option',
	},
	createTeamButtonText: {
		id: 'issue-field-team.create-team-button-text',
		defaultMessage: 'Create a team',
		description: 'The label of the inline Create team button in the teams field dropdown',
	},
	emptyStateDescription: {
		id: 'issue-field-team.empty-state-description',
		defaultMessage: 'Create a team to assign work across Jira and track it on your team’s profile',
		description: 'The description for the empty state of the teams field dropdown',
	},
	noOptionsMessage: {
		id: 'issue-field-team.no-options-message',
		defaultMessage: 'No options',
		description: 'The title for the list of all teams',
	},
});

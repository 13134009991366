/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { AppSkeletonImage } from '../../../common/ui/AppSkeletonImage.tsx';
import imgSVG from '../assets/global-issue-navigator-skeleton.tpl.svg';

const customCssStyles = css({
	// increase self specificity to ensure deterministic override
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		borderRightWidth: '32px',
		borderLeftWidth: '32px',
		/* Fix FF 2x scale bug on high res devices https://bugzilla.mozilla.org/show_bug.cgi?id=986403 */
		'@media (min--moz-device-pixel-ratio: 2)': {
			borderLeftWidth: '16px',
		},
	},
});

export const GlobalIssueNavigatorSkeleton = () => (
	<AppSkeletonImage src={imgSVG} css={customCssStyles} />
);

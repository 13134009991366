import { fg } from '@atlassian/jira-feature-gating';
import {
	PREMIUM_EDITION,
	STANDARD_EDITION,
	FREE_EDITION,
} from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';

/**
 * Overviews is only available to users with JSM Premium or JWM Premium
 * AND who have no other licences.
 */
export const useShouldRenderOverviews = (): boolean => {
	const appEditions = useAppEditions();
	if (!fg('blu-2858-sidebar-menu-overviews')) return false;

	// JSW Premium does not have Overviews because it has the newer Plans feature available
	if (appEditions.software === PREMIUM_EDITION) return false;

	// JWM Premium has Overviews if JSW Premium is not also present
	// Note: This means there are some cases where both the Plans and Overviews menu items will be available;
	// in these cases it's because the user should see the Plans upsell (that logic is taken care of within the Plans menu item)
	if (appEditions.core === PREMIUM_EDITION) return true;

	// JSM Premium has Overviews if any edition of JWM is also present
	if (
		appEditions.serviceDesk === PREMIUM_EDITION &&
		(appEditions.core === FREE_EDITION || appEditions.core === STANDARD_EDITION)
	) {
		return true;
	}

	return false;
};

import { fetchQuery, graphql } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import {
	AUTHORIZATION_FAILED_INSUFFICIENT_PERMISSIONS,
	DATA_CLASSIFICATION_NO_ACCESS,
	FAILED_TO_FETCH,
	getErrorType,
} from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-error-type/index.tsx';
import { measureExecutionTimeMetrics } from '@atlassian/jira-forge-ui-analytics/src/common/utils/measure-execution-time/index.tsx';
import { FORGE_SAMPLING_RATE } from '@atlassian/jira-forge-ui-analytics/src/constants.tsx';
import {
	fireInitializationFetchFailedEvent,
	fireInitializationFetchFinishedEvent,
} from '@atlassian/jira-forge-ui-analytics/src/services/fetch-modules/index.tsx';
import { SOURCE_UNKNOWN } from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type { Source } from '@atlassian/jira-forge-ui-types/src/common/types/analytics.tsx';
import type {
	AggExtension,
	ExtensionContextsMapNew,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { ExtensionPointModule } from '@atlassian/jira-forge-ui-types/src/common/types/module.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import QUERY, {
	type fetchModulesV2Query$data,
} from '@atlassian/jira-relay/src/__generated__/fetchModulesV2Query.graphql';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import { canFetchForgeModulesV2 } from '../can-fetch-forge/index.tsx';
import { toMappedExtensions } from './data-transformer/index.tsx';
import { trackGraphQLErrors } from './errors/index.tsx';

type FetchParams<ModuleType> = {
	cloudId: CloudId;
	isAnonymous: boolean;
	types: ModuleType[];
	context: { issueKey?: string; projectKey?: string };
	includeHidden: boolean;
	includeScopes?: boolean;
	source?: Source;
};

export const fetchModules = async <
	ModuleType extends ExtensionPointModule,
	Response = {
		[Key in ModuleType]: ExtensionContextsMapNew[Key][];
	},
>(
	params: FetchParams<ModuleType>,
): Promise<Response> => {
	const { types, cloudId, isAnonymous, source = SOURCE_UNKNOWN } = params;

	if (!canFetchForgeModulesV2({ cloudId, isAnonymous })) {
		return toMappedExtensions(types, []);
	}

	try {
		const extensions = await measureExecutionTimeMetrics(() =>
			doFetchWithGraphQLErrorsTracking(params),
		);

		if (Math.random() * 100 < FORGE_SAMPLING_RATE) {
			fireInitializationFetchFinishedEvent(source);
		}

		return toMappedExtensions(types, extensions);
	} catch (error: unknown) {
		const errorType = getErrorType(error);

		fireInitializationFetchFailedEvent(source, {
			isSSR: __SERVER__,
			errorType,
			error,
		});

		if (EXCLUDED_ERROR_TYPES.includes(errorType)) {
			return toMappedExtensions(types, []);
		}

		throw error;
	}
};

export function doFetchWithGraphQLErrorsTracking<ModuleType extends ExtensionPointModule>(
	props: FetchParams<ModuleType>,
): Promise<AggExtension[]> {
	const { cloudId, types, context, includeHidden, includeScopes = false } = props;

	if (types.length === 0) {
		throw new Error('No modules to fetch provided');
	}

	const operationName = QUERY.params.name;
	const { stopGraphQLErrorTracking, getGraphQLError } = trackGraphQLErrors(operationName);
	let extensions: AggExtension[] | null;

	return new Promise((resolve, reject) => {
		const subscription = fetchQuery(
			getRelayEnvironment(),
			/* eslint-disable @atlassian/relay/unused-fields */
			graphql`
				query fetchModulesV2Query(
					$cloudId: ID!
					$context: JiraExtensionRenderingContextInput!
					$types: [String!]!
					$includeHidden: Boolean!
					$includeScopes: Boolean!
					$includeUserAccess: Boolean!
				) {
					jira {
						forge {
							extensions(
								cloudId: $cloudId
								context: $context
								types: $types
								includeHidden: $includeHidden
							) {
								hiddenBy @include(if: $includeHidden)
								scopes @include(if: $includeScopes)
								type
								id
								environmentId
								environmentKey
								environmentType
								installationId
								appVersion
								consentUrl
								properties
								userAccess @include(if: $includeUserAccess) {
									hasAccess
								}
								egress {
									addresses
									type
								}
								license {
									active
									type
									supportEntitlementNumber
									trialEndDate
									subscriptionEndDate
									isEvaluation
									billingPeriod
									ccpEntitlementId
									ccpEntitlementSlug
									capabilitySet
								}
							}
						}
					}
				}
			`,
			/* eslint-enable @atlassian/relay/unused-fields */
			{
				cloudId,
				context,
				types,
				includeHidden,
				includeScopes,
				includeUserAccess: fg('forge_query_include_user_access'),
			},
		).subscribe({
			next: (response) => {
				stopGraphQLErrorTracking();
				extensions =
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(response as fetchModulesV2Query$data).jira?.forge?.extensions?.concat() || null;
			},
			error: (error: unknown) => {
				stopGraphQLErrorTracking();
				reject(error);
				subscription.unsubscribe();
			},
			complete: () => {
				// If there is a GraphQL error and extensions are `null`, it means a critical error has happened, and we have to reject the promise.
				// However, if the list of extensions is present in the response, it shouldn't be considered an error.
				const graphQLError = getGraphQLError();
				if (graphQLError) {
					extensions != null ? resolve(extensions || []) : reject(graphQLError);
				} else {
					resolve(extensions || []);
				}
				subscription.unsubscribe();
			},
		});
	});
}

export const EXCLUDED_ERROR_TYPES = [
	FAILED_TO_FETCH,
	AUTHORIZATION_FAILED_INSUFFICIENT_PERMISSIONS,
	DATA_CLASSIFICATION_NO_ACCESS,
];

import { ROUTE_NAMES_SERVICEDESK_SERVICE_MODAL_NEW } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';
import {
	SERVICE_DESK_ROUTE_PREFIX_WITHOUT_PROJECT,
	SERVICE_MODAL_ROUTE_SUFFIX,
} from './common/constants.tsx';

export const servicedeskServiceModalNewRoute: InvariantRoute = {
	name: ROUTE_NAMES_SERVICEDESK_SERVICE_MODAL_NEW,
	path: `${SERVICE_DESK_ROUTE_PREFIX_WITHOUT_PROJECT}${SERVICE_MODAL_ROUTE_SUFFIX}`,
	exact: true,
};

import type { MessageDescriptorV2 } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';

// Trait registry: https://data-portal.internal.atlassian.com/targeting/traits?search=jira-platform_ui_nav4&status=ACTIVE&entityType=ATLASSIAN_ACCOUNT
export const Nav4OnboardingComponentNames = {
	GLOBAL_MODAL: 'nav4GlobalOnboardingModal',
	JIRA_SIDEBAR_TOGGLE_SPOTLIGHT: 'nav4SidebarToggleOnboardingSpotlight',
	// CONFLUENCE_SIDEBAR_TOGGLE_SPOTLIGHT: 'nav4ConfluenceSidebarToggleOnboardingSpotlight', // TODO: Spotlight has not been implemented in Confluence yet so this may change
	SIDEBAR_PROJECT_SPOTLIGHT: 'nav4SidebarProjectsOnboardingSpotlight',
	TAB_NAV_SPOTLIGHT: 'nav4TabNavigationOnboardingSpotlight',
	ATLAS_GLOBAL_MODAL: 'nav4AtlasGlobalOnboardingModal',
	ATLAS_GLOBAL_MODAL2: 'nav4AtlasGlobalOnboardingModal2',
};

export type Nav4OnboardingComponentName =
	(typeof Nav4OnboardingComponentNames)[keyof typeof Nav4OnboardingComponentNames];

export const COMPONENT_TAP_TRAIT_MAP: Record<Nav4OnboardingComponentName, string> = {
	nav4GlobalOnboardingModal:
		'jira-platform_ui_nav4globalonboardingmodal_dismissed_jiraatlassianonboardingnav4_changed',
	nav4SidebarToggleOnboardingSpotlight:
		'jira-platform_ui_nav4sidebartoggleonboardingspotlight_dismissed_jiraatlassianonboardingnav4_changed',
	// nav4ConfluenceSidebarToggleOnboardingSpotlight: '', // TODO: Add when Confluence spotlight is implemented
	nav4SidebarProjectsOnboardingSpotlight:
		'jira-platform_ui_nav4sidebarprojectsonboardingspotlight_dismissed_jiraatlassianonboardingnav4_changed',
	nav4TabNavigationOnboardingSpotlight:
		'jira-platform_ui_nav4tabnavigationonboardingspotlight_dismissed_jiraatlassianonboardingnav4_changed',
	nav4AtlasGlobalOnboardingModal:
		'townsquare_ui_atlassianhomeonboardingnav4_dismissed_teamcentral_changed',
};

export type Nav4OnboardingTraitKey =
	(typeof COMPONENT_TAP_TRAIT_MAP)[keyof typeof COMPONENT_TAP_TRAIT_MAP];

type MessageTraitAttribute = {
	name: Nav4OnboardingComponentName;
	value: string;
};

export type MessageTraitResponse = {
	attributes: MessageTraitAttribute[];
};

export type SpotlightTourStep = React.ComponentProps<typeof JiraSpotlight> & {
	text: MessageDescriptorV2;
	initialize?: () => void;
	onNextClick?: () => void;
	onPreviousClick?: () => void;
	onStop?: () => void;
	messageId: string;
};

import React, { useEffect, useCallback, useState } from 'react';
import StarStarredIcon from '@atlaskit/icon/core/star-starred';
import StarUnstarredIcon from '@atlaskit/icon/core/star-unstarred';
import { ButtonItem } from '@atlaskit/menu';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { ItemTypename } from '@atlassian/jira-favourite-change-provider/src/model/types.tsx';
import { useChangeFavorite } from '@atlassian/jira-favourite-change-provider/src/view/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useStopPropagation } from '../../common/utils/use-stop-propagation/index.tsx';
import messages from './messages.tsx';

export type FavoriteToggleButtonProps = {
	entityId: string;
	entityTypeName: ItemTypename;
	defaultIsStarred?: boolean;
	onClick?: () => void;
	name?: string;
};

const UnstarredIcon = <StarUnstarredIcon label="" color="currentColor" spacing="spacious" />;

const StarredIcon = (
	<StarStarredIcon
		label=""
		color={token('color.icon.accent.yellow', 'var(--ds-icon-accent-yellow)')}
		spacing="spacious"
	/>
);

export const FavoriteToggleButton = ({
	entityId,
	entityTypeName,
	defaultIsStarred = false,
	name,
	onClick,
}: FavoriteToggleButtonProps) => {
	const { formatMessage } = useIntl();
	const [isStarred, setIsStarred] = useState(defaultIsStarred);

	const { changeFavoriteMutation } = useChangeFavorite();

	const handleOnClick = useCallback(() => {
		setIsStarred((prevIsStarred) => !prevIsStarred);
		onClick?.();
	}, [onClick]);

	useEffect(() => {
		if (isStarred !== defaultIsStarred) {
			changeFavoriteMutation({
				id: entityId,
				typename: entityTypeName,
				value: isStarred,
			});
		}
	}, [entityId, entityTypeName, isStarred, defaultIsStarred, changeFavoriteMutation]);

	const handleOnClickWithStopPropagation = useStopPropagation(handleOnClick);

	return isStarred ? (
		<ButtonItem
			iconBefore={StarredIcon}
			onClick={handleOnClickWithStopPropagation}
			aria-label={
				name
					? formatMessage(messages.removeFromStarredWithName, { name })
					: formatMessage(messages.removeFromStarred)
			}
		>
			<Text color="color.text">{formatMessage(messages.removeFromStarred)}</Text>
		</ButtonItem>
	) : (
		<ButtonItem
			iconBefore={UnstarredIcon}
			onClick={handleOnClickWithStopPropagation}
			aria-label={
				name
					? formatMessage(messages.addToStarredWithName, { name })
					: formatMessage(messages.addToStarred)
			}
		>
			<Text color="color.text">{formatMessage(messages.addToStarred)}</Text>
		</ButtonItem>
	);
};

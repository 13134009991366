/**
 * @generated SignedSource<<36e17dcd5ce3ff2971120c2bb95528a0>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type DashboardPopupMenu$data = {
  readonly dashboardId: AGG$Long;
  readonly favouriteValue: {
    readonly isFavourite: boolean | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "DashboardPopupMenu";
};
export type DashboardPopupMenu$key = {
  readonly " $data"?: DashboardPopupMenu$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardPopupMenu">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "DashboardPopupMenu",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "dashboardId"
      },
      "action": "THROW",
      "path": "dashboardId"
    },
    {
      "concreteType": "JiraFavouriteValue",
      "kind": "LinkedField",
      "name": "favouriteValue",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isFavourite"
        }
      ]
    }
  ],
  "type": "JiraDashboard"
};

(node as any).hash = "a386558bb17b8fafc916eb203a4d28ee";

export default node;

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { projectSettingsSoftwareDataClassificationsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-software-routes/src/projectSettingsSoftwareDataClassificationsRoute.tsx';
import { ProjectSettingsDetailsSkeleton } from '@atlassian/jira-skeletons/src/ui/project-settings/ProjectSettingsFeaturesSkeleton.tsx';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout/src/index.tsx';
import DataClassifications, {
	LazyDataClassificationsSettings,
} from './ui/project-settings/data-classifications/index.tsx';
import { getSettingsNavigationResources } from './ui/project-settings/product-routes/common/index.tsx';

export const projectSettingsSoftwareDataClassificationsRouteEntry = createEntry(
	projectSettingsSoftwareDataClassificationsRoute,
	() => ({
		group: ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE,
		component: DataClassifications,
		skeleton: ProjectSettingsDetailsSkeleton,
		layout: softwareProjectLayout,
		navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getSettingsNavigationResources()],
		forPaint: [LazyAtlassianNavigation, LazyDataClassificationsSettings],
	}),
);

/**
 * Contains all software settings routes using the new url format `jira/:product/projects/:projectKey/settings`.
 */

import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { softwareNextgenRedirectRoute } from '@atlassian/jira-router-routes-next-gen-routes/src/softwareNextgenRedirectRoute.tsx';
import { LazyBoardPage } from '@atlassian/jira-router-routes-software-board-entries/src/ui/SoftwareBoardComponent.tsx';
import { AsyncHorizontalNavJiraSoftware as AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout/src/index.tsx';
import { Redirect, type RouteContext, type Route } from '@atlassian/react-resource-router';
import { pickNextGenView } from './ui/next-gen/index.tsx';

const SoftwareNextgenRedirectComponent = ({ location }: RouteContext) => (
	<Redirect to={pickNextGenView(location)} />
);

export const softwareNextgenRedirectRouteEntry: Route = createEntry(
	softwareNextgenRedirectRoute,
	() => ({
		group: ROUTE_GROUPS_SOFTWARE,
		component: SoftwareNextgenRedirectComponent,
		layout: softwareProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			horizontal: AsyncHorizontalNavJira,
			onlyShowHorizontalOnNav4: true,
		},
		resources: [...getNavigationResources()],
		afterPaint: [LazyIssueApp],
		forPaint: [LazyAtlassianNavigation, LazyBoardPage],
	}),
);

import React from 'react';
import DefaultAppIcon from '@atlaskit/icon/core/app';
import { token } from '@atlaskit/tokens';
import { IconCustom } from '@atlassian/jira-icon-custom/src/index.tsx';

type AppIconProps = {
	iconUrl: string;
};

export function AppIcon({ iconUrl }: AppIconProps) {
	return iconUrl.trim().length > 0 ? (
		<IconCustom url={iconUrl} />
	) : (
		<DefaultAppIcon label="" color={token('color.icon')} />
	);
}

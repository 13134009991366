import React, { useMemo, type Ref, useCallback, useContext } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { CustomisableSidebarModalEntrypoint } from '@atlassian/jira-navigation-apps-sidebar-nav4-customise-sidebar/entrypoint.tsx';
import { MoreMenuIsOpenContext } from '../../../controllers/context.tsx';
import messages from './messages.tsx';
import { CustomiseSidebarTrigger } from './trigger/index.tsx';
import type { CustomiseSidebarProps } from './types.tsx';

export function CustomiseSidebar({ navItems }: CustomiseSidebarProps) {
	const { setMoreMenuIsOpen } = useContext(MoreMenuIsOpenContext);

	const onClose = useCallback(() => {
		setMoreMenuIsOpen(false);
	}, [setMoreMenuIsOpen]);

	const entryPointParams = useMemo(() => ({ navItems, onClose }), [navItems, onClose]);
	const { formatMessage } = useIntl();
	const modalProps = useMemo(
		() => ({
			width: 400,
			height: 440,
		}),
		[],
	);

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={CustomisableSidebarModalEntrypoint}
			entryPointProps={entryPointParams}
			title={formatMessage(messages.header)}
			modalProps={modalProps}
			useInternalModal={false}
		>
			{/* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */}
			{({ ref }) => <CustomiseSidebarTrigger ref={ref as Ref<HTMLButtonElement>} />}
		</ModalEntryPointPressableTrigger>
	);
}

import { CONNECT_PROJECT_QUERY_PARAMS } from '@atlassian/jira-common-constants/src/connect-project-query-params.tsx';
import { ROUTE_NAMES_CONNECT_PROJECT } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

// Don't forget to update CONNECT_ROUTES_SET in @atlassian/jira-common-constants/src/spa-routes
export const connectProjectRoute: InvariantRoute = {
	name: ROUTE_NAMES_CONNECT_PROJECT,
	path: '/projects/:projectKey',
	query: CONNECT_PROJECT_QUERY_PARAMS,
};

import React from 'react';
import Lozenge from '@atlaskit/lozenge';

type EnvLozengeProps = {
	label?: string;
};

export function EnvLozenge({ label }: EnvLozengeProps) {
	return label ? (
		<Lozenge appearance={label === 'Dev' ? 'default' : 'moved'}>{label}</Lozenge>
	) : null;
}

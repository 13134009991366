import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { ROUTE_GROUPS_CLASSIC_BUSINESS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { jiraBusinessTimelineEmbedRoute } from '@atlassian/jira-router-routes-business-timeline-routes/src/jiraBusinessTimelineEmbedRoute.tsx';
import { timelineEmbedEntrypoint } from '@atlassian/jira-spa-apps-business-timeline-embed/entrypoint.tsx';

const timelineEmbedPageEntrypoint = createChromelessEntryPoint({
	main: timelineEmbedEntrypoint,
});

export const jiraBusinessTimelineEmbedRouteEntry = createEntry(
	jiraBusinessTimelineEmbedRoute,
	() => ({
		group: ROUTE_GROUPS_CLASSIC_BUSINESS,
		ufoName: 'jwm.timeline-embed-view',
		layout: chromelessLayout,
		skeleton: ThemedLazySkeleton,

		resources: getProjectViewResources(),

		entryPoint: () => timelineEmbedPageEntrypoint,
	}),
);

import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { MainHeightFixCss } from '@atlassian/jira-main-height-fix/src/ui/MainHeightFixCss.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { Main } from '@atlassian/jira-navigation-system/src/index.tsx';
import { useProjectTheme } from '@atlassian/jira-project-theme-page-container/src/controllers/project-theme/index.tsx';
import {
	ProjectThemeWrapper,
	ThemedContentLayout,
} from '@atlassian/jira-project-theme-page-container/src/ui/project-theme-wrapper/ProjectThemeWrapper.tsx';
import { RenderTracerMark } from '@atlassian/jira-render-tracer-analytics/src/main.tsx';
import SpaEntry, { type Props } from '@atlassian/jira-spa/src/index.tsx';
import { MainContentSkipLink } from '../../../common/ui/page-container/page-container-skip-links/index.tsx';
import { LayoutMainWrapper } from './styled.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Props };

/* IMPORTANT:
 * When making any changes to the OLD component, please ensure that you also make the same changes to the
 * NEW component. The feature flag used is part of the Navigation Refresh work and we need to ensure that
 * we do not introduce tech debt for the roll-out by only updating the current component that is due to be
 * removed.
 *
 * If you have any concerns or questions please reach out to the #jira-navigation channel. Thanks!
 */

const SPA_TRACE_KEY = 'spa';

const NewContent = (props: Props) => {
	const { isInset, hasTheming } = useProjectTheme();

	const renderContent = (
		<>
			<JSErrorBoundary id="mainContentSkipLink" packageName="jiraPageContainer" fallback="unmount">
				<MainContentSkipLink />
			</JSErrorBoundary>
			<RenderTracerMark traceKey={SPA_TRACE_KEY} type="start" />
			<SpaEntry {...props} />
			<RenderTracerMark traceKey={SPA_TRACE_KEY} type="end" />
		</>
	);

	if (hasTheming) {
		return (
			<ThemedContentLayout appearance={isInset ? 'inset' : 'default'}>
				{renderContent}
			</ThemedContentLayout>
		);
	}

	return renderContent;
};

const OldContent = (props: Props) => {
	const { isInset, hasProjectViewNavigation } = useProjectTheme();

	const renderContent = (
		<>
			<MainHeightFixCss />
			<JSErrorBoundary id="mainContentSkipLink" packageName="jiraPageContainer" fallback="unmount">
				<MainContentSkipLink />
			</JSErrorBoundary>
			<Main id="ak-main-content">
				<RenderTracerMark traceKey={SPA_TRACE_KEY} type="start" />
				<SpaEntry {...props} />
				<RenderTracerMark traceKey={SPA_TRACE_KEY} type="end" />
			</Main>
		</>
	);

	if (hasProjectViewNavigation) {
		return <ProjectThemeWrapper withWhiteBackground={isInset}>{renderContent}</ProjectThemeWrapper>;
	}

	return <LayoutMainWrapper>{renderContent}</LayoutMainWrapper>;
};

export const Content = componentWithCondition(getWillShowNav4, NewContent, OldContent);

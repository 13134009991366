import { getRouteForViewType } from '@atlassian/jira-business-navigation-common/src/utils.tsx';
import {
	JIRA_PROJECT_TYPE_CORE_PROJECT,
	JIRA_PROJECT_TYPE_SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { JiraNavigationItemTypeKey } from '@atlassian/jira-relay/src/__generated__/ui_navigationKitTabList_TabList_tabsFragment.graphql';

type GenericProject = Record<string, unknown> & {
	key: string;
	projectType: string;
	defaultUrl?: string;
};

type BusinessProject = GenericProject & {
	projectType: typeof JIRA_PROJECT_TYPE_CORE_PROJECT;
	defaultViewType: JiraNavigationItemTypeKey | null | undefined;
};

type SoftwareProject = GenericProject & {
	projectType: typeof JIRA_PROJECT_TYPE_SOFTWARE_PROJECT;
	boardId: string;
	isSimpleBoard: boolean;
	defaultViewType: JiraNavigationItemTypeKey | null | undefined;
};

type BuildProjectUrlParams = GenericProject | BusinessProject | SoftwareProject;

const isBusinessProject = (project: BuildProjectUrlParams): project is BusinessProject =>
	project.projectType === JIRA_PROJECT_TYPE_CORE_PROJECT;

const isSoftwareProject = (project: BuildProjectUrlParams): project is SoftwareProject =>
	project.projectType === JIRA_PROJECT_TYPE_SOFTWARE_PROJECT;

const isSoftwareProjectWithBoard = (project: BuildProjectUrlParams): project is SoftwareProject =>
	isSoftwareProject(project) && 'boardId' in project && 'isSimpleBoard' in project;

/**
 * Builds a URL for a project based on its type and provided parameters.
 *
 * @param {BuildProjectUrlParams} params - The parameters containing information about the project.
 * @param {string} params.key - The unique key of the project.
 * @param {string} params.projectType - The type of the project (e.g. JIRA_PROJECT_TYPE_CORE_PROJECT, JIRA_PROJECT_TYPE_SOFTWARE_PROJECT).
 * @param {string} [params.defaultUrl] - The default URL to use if no specific URL can be built.
 * @param {JiraNavigationItemTypeKey} [params.defaultViewType] - The saved view type for a business or software project.
 * @param {string} [params.boardId] - The board ID for a software project.
 * @param {boolean} [params.isSimpleBoard] - Indicates if the board is a simple board for a software project.
 *
 * @returns {string} The URL path for the project.
 */
export const buildProjectUrl = (params: BuildProjectUrlParams) => {
	const { key, defaultUrl } = params;

	if (isBusinessProject(params)) {
		const businessRoute = params.defaultViewType
			? getRouteForViewType(key, params.defaultViewType)
			: undefined;
		return businessRoute ?? `/jira/core/projects/${key}/board`;
	}

	if (isSoftwareProjectWithBoard(params)) {
		const { boardId, isSimpleBoard, defaultViewType } = params;

		const defaultViewPath = defaultViewType ? `/${defaultViewType.toLowerCase()}` : '';
		return `/jira/software/${isSimpleBoard ? '' : 'c/'}projects/${key}/boards/${boardId}${defaultViewPath && defaultViewType !== 'BOARD' ? defaultViewPath : ''}`;
	}

	if (isSoftwareProject(params) && params.defaultViewType) {
		const { defaultViewType } = params;
		return `/jira/software/projects/${key}/${defaultViewType.toLowerCase()}`;
	}

	return defaultUrl ?? `/browse/${key}`;
};

/**
 * Removes the base URL from the given URL string.
 *
 * @param url - The URL string from which to remove the base URL.
 * @returns The URL string with the base URL removed.
 */
export const removeBaseUrl = (url: string) => url.replace(/https?:\/\/[^/]+/, '');

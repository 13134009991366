import { getEcosystemQuery } from './utils/ecosystem.tsx';

export const ISSUE_VIEW_NON_CRITICAL_GIRA_QUERY = 'issueViewNonCriticalGiraQuery';

export const buildNonCriticalGiraQuery = () => `
query ${ISSUE_VIEW_NON_CRITICAL_GIRA_QUERY}($issueKey: String!) {
    viewIssue(issueKey: $issueKey) {
        ${getEcosystemQuery()}
        deletableAttachmentsCount
    }
}`;

export const getNonCriticalGiraQueryRequestOptions = (issueKey: string) => ({
	method: 'POST',
	body: JSON.stringify({
		operationName: ISSUE_VIEW_NON_CRITICAL_GIRA_QUERY,
		query: buildNonCriticalGiraQuery(),
		variables: {
			issueKey,
		},
	}),
});

import { resources as customerLogResources } from '@atlassian/eoc-customer-logs/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_EOC } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { eocTeamCustomerLogsRoute } from '@atlassian/jira-router-routes-eoc-team-customer-logs-routes/src/eocTeamCustomerLogsRoute.tsx';
import CustomerLogs, { LazyCustomerLogs } from './ui/spa/eoc/customer-logs/index.tsx';

export const eocTeamCustomerLogsRouteEntry = createEntry(eocTeamCustomerLogsRoute, () => ({
	group: ROUTE_GROUPS_EOC,
	component: CustomerLogs,
	layout: globalSettingsLayout,
	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		onlyShowHorizontalOnNav4: true,
	},
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		...customerLogResources,
	],
	forPaint: [LazyHorizontalOperationsConfigurationNav, LazyAtlassianNavigation, LazyCustomerLogs],
}));

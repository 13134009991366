/**
 * @generated SignedSource<<33be10ecfbe9b5c01d2e41c935f70c0c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type DashboardsContent$data = {
  readonly jira: {
    readonly dashboardFavourites: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly dashboardId?: AGG$Long | null | undefined;
          readonly id?: string;
          readonly " $fragmentSpreads": FragmentRefs<"DashboardMenuItem">;
        };
      } | null | undefined> | null | undefined;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
      };
    };
    readonly dashboardRecents: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly dashboardId?: AGG$Long | null | undefined;
          readonly id?: string;
          readonly " $fragmentSpreads": FragmentRefs<"DashboardMenuItem">;
        };
      } | null | undefined> | null | undefined;
    };
  };
  readonly " $fragmentType": "DashboardsContent";
};
export type DashboardsContent$key = {
  readonly " $data"?: DashboardsContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardsContent">;
};

import Nav4DashboardsContentQuery_graphql from './Nav4DashboardsContentQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v1 = {
  "kind": "ScalarField",
  "name": "id"
},
v2 = {
  "kind": "ScalarField",
  "name": "dashboardId"
},
v3 = {
  "kind": "FragmentSpread",
  "name": "DashboardMenuItem"
},
v4 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currentURL"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "fragmentPathInResult": [],
      "operation": Nav4DashboardsContentQuery_graphql
    }
  },
  "name": "DashboardsContent",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": "dashboardFavourites",
              "args": [
                (v0/*: any*/),
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "sort": {
                      "order": "DESC"
                    },
                    "type": "DASHBOARD"
                  }
                },
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 15
                }
              ],
              "concreteType": "JiraFavouriteConnection",
              "kind": "LinkedField",
              "name": "favourites",
              "plural": false,
              "selections": [
                {
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "hasNextPage"
                    }
                  ]
                },
                {
                  "concreteType": "JiraFavouriteEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v1/*: any*/),
                                "action": "THROW",
                                "path": "jira.dashboardFavourites.edges.node.id"
                              },
                              (v2/*: any*/),
                              (v3/*: any*/)
                            ],
                            "type": "JiraDashboard"
                          }
                        ]
                      },
                      "action": "THROW",
                      "path": "jira.dashboardFavourites.edges.node"
                    }
                  ]
                },
                (v4/*: any*/)
              ]
            },
            "action": "THROW",
            "path": "jira.dashboardFavourites"
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": "dashboardRecents",
              "args": [
                (v0/*: any*/),
                {
                  "kind": "Variable",
                  "name": "currentURL",
                  "variableName": "currentURL"
                },
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 20
                },
                {
                  "kind": "Literal",
                  "name": "types",
                  "value": [
                    "DASHBOARD"
                  ]
                }
              ],
              "concreteType": "JiraSearchableEntityConnection",
              "kind": "LinkedField",
              "name": "recentItems",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraSearchableEntityEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v1/*: any*/),
                                "action": "THROW",
                                "path": "jira.dashboardRecents.edges.node.id"
                              },
                              (v2/*: any*/),
                              (v3/*: any*/)
                            ],
                            "type": "JiraDashboard"
                          }
                        ]
                      },
                      "action": "THROW",
                      "path": "jira.dashboardRecents.edges.node"
                    }
                  ]
                },
                (v4/*: any*/)
              ]
            },
            "action": "THROW",
            "path": "jira.dashboardRecents"
          }
        ]
      },
      "action": "THROW",
      "path": "jira"
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "cea841a5380802195bad106b93f97807";

export default node;

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { ROUTE_GROUPS_ISSUE_NAVIGATOR } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { projectIssueNavigatorCoreRoute } from '@atlassian/jira-router-routes-issue-navigator-routes/src/projectIssueNavigatorCoreRoute.tsx';
import { ProjectIssueNavigatorSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/project-app.tsx';
import { getCommonProjectResources } from './common/getCommonProjectResources.tsx';
import { issueNavigatorRouteEntryCommon } from './common/issueNavigatorRouteEntryCommon.tsx';
import { LazyProjectIssueNavigator, ProjectIssueNavigator } from './ui/index.tsx';

/**
 * Business Project Issue Navigator Route Entry
 * Handles Business projects, also known as JWM or Core projects.
 */
export const issueNavigatorProjectBusinessRouteEntry = createEntry(
	projectIssueNavigatorCoreRoute,
	() => ({
		group: ROUTE_GROUPS_ISSUE_NAVIGATOR,
		...issueNavigatorRouteEntryCommon,
		component: ProjectIssueNavigator,
		skeleton: ProjectIssueNavigatorSkeleton,
		forPaint: [LazyAtlassianNavigation, LazyProjectIssueNavigator],
		afterPaint: [LazyIssueApp],
		navigation: {
			menuId: MENU_ID.PROJECTS,
			// delete when cleaning up getWillShowNav4
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalNavJira,
		},
		resources: [...getCommonProjectResources()],
		layout: createLayout(genericProjectLayout),
		// We want to opt out of concurrent mode for all NIN routes https://hello.atlassian.net/wiki/x/wTRfDAE
		// But allow for users with empanada-concurrent-incremental gate enabled to continue testing it
		shouldOptOutConcurrentMode:
			fg('jira-concurrent-incremental') && !fg('empanada-concurrent-incremental'),
	}),
);

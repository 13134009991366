import React from 'react';
import type ForgePersonalSettingsPageType from '@atlassian/jira-spa-apps-forge-personal-settings/src/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyForgePersonalSettingsPage = lazyForPaint<typeof ForgePersonalSettingsPageType>(
	() =>
		import(
			/* webpackChunkName: "async-forge-spa-personal-settings", jiraSpaEntry: "async-forge-spa-personal-settings" */ '@atlassian/jira-spa-apps-forge-personal-settings'
		),
);

export const ForgePersonalSettingsPage = () => (
	<LazyPage
		Page={LazyForgePersonalSettingsPage}
		pageId="forge-spa-personal-settings"
		shouldShowSpinner
	/>
);

export default ForgePersonalSettingsPage;

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createRoadmap: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-roadmaps.common.action-buttons.create-roadmap',
		defaultMessage: 'Create roadmap',
		description:
			'Used as the alt text for an icon button that when used navigates to create roadmap',
	},
});

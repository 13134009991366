import {
  OpsgenieSavedSearchAriResourceOwner,
  OpsgenieSavedSearchAriResourceType
} from "./chunk-BNSU4GVQ.mjs";
import {
  RegisteredAri
} from "./chunk-YG2E6ZVD.mjs";
import {
  AriParser
} from "./chunk-Q7FABYST.mjs";

// src/opsgenie/saved-search/manifest.ts
var opsgenieSavedSearchAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^$"),
  // eslint-disable-line no-useless-escape
  resourceOwner: OpsgenieSavedSearchAriResourceOwner,
  resourceType: OpsgenieSavedSearchAriResourceType,
  resourceIdSlug: "{workspaceId}/{savedSearchId}",
  resourceIdSegmentFormats: {
    workspaceId: /[a-zA-Z0-9\-]+/,
    // eslint-disable-line no-useless-escape
    savedSearchId: /[!a-zA-Z0-9\-_.~@:{}=]+(\/[!a-zA-Z0-9\-_.~@:{}=]+)*/
    // eslint-disable-line no-useless-escape
  }
};

// src/opsgenie/saved-search/index.ts
var OpsgenieSavedSearchAri = class _OpsgenieSavedSearchAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._workspaceId = opts.resourceIdSegmentValues.workspaceId, this._savedSearchId = opts.resourceIdSegmentValues.savedSearchId;
  }
  get workspaceId() {
    return this._workspaceId;
  }
  get savedSearchId() {
    return this._savedSearchId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: opsgenieSavedSearchAriStaticOpts.qualifier,
      platformQualifier: opsgenieSavedSearchAriStaticOpts.platformQualifier,
      cloudId: void 0,
      resourceOwner: opsgenieSavedSearchAriStaticOpts.resourceOwner,
      resourceType: opsgenieSavedSearchAriStaticOpts.resourceType,
      resourceId: `${opts.workspaceId}/${opts.savedSearchId}`,
      resourceIdSegmentValues: {
        workspaceId: opts.workspaceId,
        savedSearchId: opts.savedSearchId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, opsgenieSavedSearchAriStaticOpts);
    return new _OpsgenieSavedSearchAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, opsgenieSavedSearchAriStaticOpts);
    return new _OpsgenieSavedSearchAri(opts);
  }
  getVariables() {
    return {
      workspaceId: this.workspaceId,
      savedSearchId: this.savedSearchId
    };
  }
};

export {
  OpsgenieSavedSearchAri
};

/**
 * @generated SignedSource<<48acf0c978e94f91101110d45cd9c24f>>
 * @relayHash 12383a3e19940070bc3eb1ad86a3f3cf
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 99eb331a0aacef3d3d67f24bc29adf6f893681a082da97179519450c408a263c

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraSoftwareFormBuilderQuery } from './src_jiraSoftwareFormBuilderQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraSoftwareFormBuilderQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "99eb331a0aacef3d3d67f24bc29adf6f893681a082da97179519450c408a263c",
    "metadata": {},
    "name": "src_jiraSoftwareFormBuilderQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import { ROUTE_NAMES_HELP_CENTER_SERVICE_HUBS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { RouterContext } from '@atlassian/react-resource-router';
import { MENU_ID_CUSTOMER_SERVICE } from '../../../../constants.tsx';
import type { SelectedPath } from '../../../../types.tsx';

const routes = new Set<string>([ROUTE_NAMES_HELP_CENTER_SERVICE_HUBS]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;
	const path: string[] = [];

	switch (routeName) {
		case ROUTE_NAMES_HELP_CENTER_SERVICE_HUBS:
			path.push(MENU_ID_CUSTOMER_SERVICE);
			break;

		default:
	}

	return path;
}

export const customerServiceConverter = { routes, convert };

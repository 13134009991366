import React, { type ReactNode } from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';

export type FlagsBoundaryProps = {
	children: ReactNode;
	packageName: string;
};

export const FlagsBoundary = ({ children, packageName }: FlagsBoundaryProps) => (
	<ErrorBoundary id="flags" packageName={packageName}>
		<Placeholder name="flags-boundary" fallback={<></>}>
			{children}
		</Placeholder>
	</ErrorBoundary>
);

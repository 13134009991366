import React from 'react';
import { ROUTE_GROUPS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { advancedRoadmapsPlanPageInitialChecksResource } from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { softwareAdvancedRoadmaps5Route } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/softwareAdvancedRoadmaps5Route.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { LazyAdvancedRoadmapsPlanExport } from './ui/plan-export/index.tsx';
import { LazyPlanTimeline } from './ui/plan-timeline/index.tsx';

/**
 * Export Page
 */

const ExportPageComponent = () => <LazyAdvancedRoadmapsPlanExport />;

const ExportPage = () => (
	<LazyPage Page={ExportPageComponent} pageId="advanced-roadmap" shouldShowSpinner />
);

export const planExportPageRouteEntry: Route = createEntry(softwareAdvancedRoadmaps5Route, () => ({
	group: ROUTE_GROUPS_SOFTWARE,
	ufoName: 'software-advanced-roadmaps-scenario-export',
	component: ExportPage,
	layout: { isChromeless: true, globalComponents: [], rightSidebars: [] },
	resources: [advancedRoadmapsPlanPageInitialChecksResource],
	forPaint: [LazyPlanTimeline],
}));

import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import Button, { LoadingButton } from '@atlaskit/button';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';

import { messages } from './messages';
import { type DeleteModalProps } from './types';

export const DeleteModal: React.FC<DeleteModalProps> = ({
	isDeleteDialogOpen,
	onDeleteAttemptCancel,
	onDelete,
	isLoading,
	messageDescriptors,
	returnFocusRef,
}) => {
	return (
		<ModalTransition>
			{isDeleteDialogOpen && (
				<Modal
					shouldCloseOnOverlayClick={!isLoading}
					onClose={onDeleteAttemptCancel}
					shouldReturnFocus={returnFocusRef}
				>
					<ModalHeader>
						<ModalTitle appearance="danger">
							<FormattedMessage {...messageDescriptors.title} />
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<FormattedMessage {...messageDescriptors.description} />
					</ModalBody>
					<ModalFooter>
						<Button isDisabled={isLoading} appearance="subtle" onClick={onDeleteAttemptCancel}>
							<FormattedMessage {...messages.cancelButton} />
						</Button>
						<LoadingButton appearance="danger" onClick={onDelete} autoFocus isLoading={isLoading}>
							<FormattedMessage {...messages.deleteButton} />
						</LoadingButton>
					</ModalFooter>
				</Modal>
			)}
		</ModalTransition>
	);
};

import type { CompoundClause, TerminalClause } from '@atlaskit/jql-ast';
import type { Store, Props } from './types.tsx';

/**
 * getClausesData Generate clauses data needed to build Fields pickers (check Store type for more info)
 */
export const getClausesData = ({ clauseMap, excludedFields, irremovableFields }: Props): Store => {
	const clausesByFieldId: Record<string, TerminalClause | CompoundClause | undefined> = {};
	const secondaryClausesByFieldId: Record<string, TerminalClause | CompoundClause | undefined> = {};
	const astClauseOrder: string[] = [];

	Object.keys(clauseMap).forEach((fieldId) => {
		const fieldIsNotIrremovable = !irremovableFields.has(fieldId);

		const fieldIsNotYetIncluded = !astClauseOrder.includes(fieldId);
		const isNotExcludedField = !excludedFields.includes(fieldId);

		if (fieldIsNotIrremovable && fieldIsNotYetIncluded && isNotExcludedField) {
			astClauseOrder.push(fieldId);
		}

		if (isNotExcludedField) {
			const clauses = clauseMap[fieldId];
			clausesByFieldId[fieldId] = clauses && clauses[0] ? clauses[0] : undefined;
			secondaryClausesByFieldId[fieldId] = clauses && clauses[1] ? clauses[1] : undefined;
		}
	});

	return {
		astClauseOrder,
		clausesByFieldId,
		secondaryClausesByFieldId,
	};
};

import { resources as alertPoliciesResources } from '@atlassian/eoc-policies/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_EOC } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalPoliciesRoute } from '@atlassian/jira-router-routes-eoc-policies-routes/src/globalPoliciesRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { AlertPoliciesComponent, LazyAlertPolicies } from './ui/AlertPoliciesComponent.tsx';

export const globalPoliciesRouteEntry: Route = createEntry(globalPoliciesRoute, () => ({
	group: ROUTE_GROUPS_EOC,
	component: AlertPoliciesComponent,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		...alertPoliciesResources,
	],
	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		onlyShowHorizontalOnNav4: true,
	},
	forPaint: [LazyHorizontalOperationsConfigurationNav, LazyAtlassianNavigation, LazyAlertPolicies],
}));

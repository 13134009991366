{
  "got-it": [
    {
      "type": 0,
      "value": "Got it"
    }
  ],
  "mercury.email.footer.sent-by": [
    {
      "type": 0,
      "value": "This message was sent to you by Atlassian Focus"
    }
  ],
  "mercury.email.footer.unsubscribe": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "mercury.focus-areas.date-changed": [
    {
      "type": 0,
      "value": "Date changed"
    }
  ],
  "mercury.focus-areas.focus-icon.alt": [
    {
      "type": 0,
      "value": "Focus area icon"
    }
  ],
  "mercury.focus-areas.for": [
    {
      "type": 0,
      "value": "for"
    }
  ],
  "mercury.focus-areas.heading": [
    {
      "type": 0,
      "value": "You're following "
    },
    {
      "type": 1,
      "value": "total"
    },
    {
      "type": 0,
      "value": " active focus areas, here's the breakdown."
    }
  ],
  "mercury.focus-areas.status-changed": [
    {
      "type": 0,
      "value": "Status changed"
    }
  ],
  "mercury.focus-areas.status.at-risk": [
    {
      "type": 0,
      "value": "At risk"
    }
  ],
  "mercury.focus-areas.status.completed": [
    {
      "type": 0,
      "value": "Completed ✨"
    }
  ],
  "mercury.focus-areas.status.off-track": [
    {
      "type": 0,
      "value": "Off track"
    }
  ],
  "mercury.focus-areas.status.on-track": [
    {
      "type": 0,
      "value": "On track"
    }
  ],
  "mercury.focus-areas.status.paused": [
    {
      "type": 0,
      "value": "Paused"
    }
  ],
  "mercury.focus-areas.status.pending": [
    {
      "type": 0,
      "value": "Pending"
    }
  ],
  "mercury.focus-areas.view-area": [
    {
      "type": 0,
      "value": "View Focus Area"
    }
  ],
  "mercury.focus.catch.up.link": [
    {
      "type": 0,
      "value": "Jump into Atlassian Focus"
    }
  ],
  "mercury.focus.catch.up.text": [
    {
      "type": 0,
      "value": "Want to catch up on previous updates? "
    },
    {
      "type": 1,
      "value": "link"
    }
  ],
  "mercury.mercury-monthly-digest.message-template.email.subject.no-translate": [
    {
      "type": 0,
      "value": "Post Office Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    },
    {
      "type": 0,
      "value": " - Total Focus Areas: "
    },
    {
      "type": 1,
      "value": "total"
    }
  ],
  "mercury.message-templates.edit-focus-area-heirarchy-spotlight-body": [
    {
      "type": 0,
      "value": "Whenever you need to make changes to your hierarchy you can do so from settings."
    }
  ],
  "mercury.message-templates.edit-focus-area-heirarchy-spotlight-heading": [
    {
      "type": 0,
      "value": "Edit your focus area hierarchy"
    }
  ]
}
/**
 * @generated SignedSource<<88e6b74d9cd0f6199e352d7287cdc139>>
 * @relayHash 550be86a84988bdbd21f24b80ceb24b9
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9c1b5bc55b0e3a4a61ff20a7b89ec0bf355a6530849d7e75a79eb5d499feda60

import type { ConcreteRequest, Query } from 'relay-runtime';
export type useJqlBuilderFetchQuery$variables = {
  cloudId: string;
  jql: string;
  jqlTerm: string;
  searchString: string;
};
export type useJqlBuilderFetchQuery$data = {
  readonly jira: {
    readonly jqlBuilder: {
      readonly fieldValues: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly displayName?: string;
            readonly issueTypes?: ReadonlyArray<{
              readonly avatar: {
                readonly small: string | null | undefined;
              } | null | undefined;
              readonly issueTypeId: string | null | undefined;
            }>;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type useJqlBuilderFetchQuery = {
  response: useJqlBuilderFetchQuery$data;
  variables: useJqlBuilderFetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jql"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jqlTerm"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchString"
},
v4 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "jqlContext",
    "variableName": "jql"
  },
  {
    "kind": "Variable",
    "name": "jqlTerm",
    "variableName": "jqlTerm"
  },
  {
    "kind": "Variable",
    "name": "searchString",
    "variableName": "searchString"
  }
],
v6 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v7 = {
  "kind": "ScalarField",
  "name": "issueTypeId"
},
v8 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "small"
    }
  ]
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    (v6/*: any*/)
  ],
  "type": "JiraJqlLabelFieldValue"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "useJqlBuilderFetchQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "concreteType": "JiraJqlBuilder",
            "kind": "LinkedField",
            "name": "jqlBuilder",
            "plural": false,
            "selections": [
              {
                "args": (v5/*: any*/),
                "concreteType": "JiraJqlFieldValueConnection",
                "kind": "LinkedField",
                "name": "fieldValues",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraJqlFieldValueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v6/*: any*/),
                              {
                                "concreteType": "JiraIssueType",
                                "kind": "LinkedField",
                                "name": "issueTypes",
                                "plural": true,
                                "selections": [
                                  (v7/*: any*/),
                                  (v8/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlIssueTypeFieldValue"
                          },
                          (v9/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "useJqlBuilderFetchQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "concreteType": "JiraJqlBuilder",
            "kind": "LinkedField",
            "name": "jqlBuilder",
            "plural": false,
            "selections": [
              {
                "args": (v5/*: any*/),
                "concreteType": "JiraJqlFieldValueConnection",
                "kind": "LinkedField",
                "name": "fieldValues",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraJqlFieldValueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "__typename"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v6/*: any*/),
                              {
                                "concreteType": "JiraIssueType",
                                "kind": "LinkedField",
                                "name": "issueTypes",
                                "plural": true,
                                "selections": [
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "id"
                                  }
                                ]
                              }
                            ],
                            "type": "JiraJqlIssueTypeFieldValue"
                          },
                          (v9/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "9c1b5bc55b0e3a4a61ff20a7b89ec0bf355a6530849d7e75a79eb5d499feda60",
    "metadata": {},
    "name": "useJqlBuilderFetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "f9c373fe0144a8b52692f108c8ed6461";

export default node;

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	publishPageSuccessTitle: {
		id: 'issue-create-confluence-content.controllers.use-embedded-page-draft.publish-page-success-title',
		defaultMessage: 'Published!',
		description:
			'The title text on the notification displayed when a user successfully publishes a page',
	},
	publishPageSuccessDescription: {
		id: 'issue-create-confluence-content.controllers.use-embedded-page-draft.publish-page-success-description',
		defaultMessage: 'Successfully published page',
		description:
			'The description text on the notification displayed when a user successfully publishes a page',
	},
	deferSpacePublishPageSuccessDescription: {
		id: 'issue-create-confluence-content.controllers.use-embedded-page-draft.defer-space-publish-page-success-description',
		defaultMessage: 'Your page was successfully published in the {spaceName} space',
		description:
			'The description text on the notification displayed when a user successfully publishes a page',
	},
	publishPageFailedTitle: {
		id: 'issue-create-confluence-content.controllers.use-embedded-page-draft.publish-page-failed-title',
		defaultMessage: 'Something went wrong',
		description: 'The title text on the notification displayed when a user fails to publish a page',
	},
	publishPageFailedDescription: {
		id: 'issue-create-confluence-content.controllers.use-embedded-page-draft.publish-page-failed-description',
		defaultMessage: 'We are unable to publish your page at this time',
		description:
			'The description text on the notification displayed when a user fails to publish a page',
	},
	linkPageFailedTitle: {
		id: 'issue-create-confluence-content.controllers.use-embedded-page-draft.link-page-failed-title',
		defaultMessage: 'Something went wrong.',
		description: 'The title text on the notification displayed when a user fails to link a page',
	},
	linkPageFailedDescription: {
		id: 'issue-create-confluence-content.controllers.use-embedded-page-draft.link-page-failed-description',
		defaultMessage: 'We are unable to link your page at this time',
		description:
			'The description text on the notification displayed when a user fails to link a page',
	},
	publishAndLinkPageSuccessTitle: {
		id: 'issue-create-confluence-content.controllers.use-embedded-page-draft.publish-and-link-page-success-title',
		defaultMessage: 'Published!',
		description:
			'The title text on the notification displayed when a user successfully publishes and links a page',
	},
	publishAndLinkPageSuccessDescription: {
		id: 'issue-create-confluence-content.controllers.use-embedded-page-draft.publish-and-link-page-success-description',
		defaultMessage: 'Your page has been published and linked to your Jira issue.',
		description:
			'The description text on the notification displayed when a user publishes and successfully links a page',
	},
	publishAndLinkPageSuccessDescriptionIssueTermRefresh: {
		id: 'issue-create-confluence-content.controllers.use-embedded-page-draft.publish-and-link-page-success-description-issue-term-refresh',
		defaultMessage: 'Your page has been published and linked to your Jira issue.',
		description:
			'The description text on the notification displayed when a user publishes and successfully links a page',
	},
});

import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';
import { createJpdExperience } from '../../utils/experience/main.tsx';

const updatePlayField = createJpdExperience(
	new UFOExperience('jpd.play-sidepanel.field-update', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	updatePlayField,
};

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorTitle: {
		id: 'jet-plan-action-menu.duplicate-modal.error-title',
		defaultMessage: "We couldn't duplicate your plan",
		description: 'Message to be shown in an error flag if the duplicate plan operation fails.',
	},
	errorDescription: {
		id: 'jet-plan-action-menu.duplicate-modal.error-description',
		defaultMessage: "We couldn't duplicate {planTitle}.\nRefresh the page and try again.",
		description: 'Message to be shown in an error flag if the duplicate plan operation fails.',
	},
});

import React, { useMemo, useState, type PropsWithChildren } from 'react';
import { MoreMenuIsOpenContext } from '../../../controllers/context.tsx';

type MoreMenuIsOpenContextProviderProps = PropsWithChildren<{}>;

export const MoreMenuIsOpenContextProvider = ({ children }: MoreMenuIsOpenContextProviderProps) => {
	const [moreMenuIsOpen, setMoreMenuIsOpen] = useState<boolean>(false);

	const moreMenuIsOpenContext = useMemo(
		() => ({ moreMenuIsOpen, setMoreMenuIsOpen }),
		[moreMenuIsOpen],
	);

	return (
		<MoreMenuIsOpenContext.Provider value={moreMenuIsOpenContext}>
			{children}
		</MoreMenuIsOpenContext.Provider>
	);
};

import {
	createPageEntryPoint,
	createChromelessEntryPoint,
} from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { listEmbedEntryPoint } from '@atlassian/jira-spa-apps-jsw-list-embed/entrypoint.tsx';
import { listEntryPoint } from '@atlassian/jira-spa-apps-jsw-list/entrypoint.tsx';

export const softwareListRouteEntry = createPageEntryPoint({
	main: listEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const softwareListEmbedRouteEntry = createChromelessEntryPoint({
	main: listEmbedEntryPoint,
});

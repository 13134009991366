import { useCallback, useContext } from 'react';
import { NAVIGATION_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import { SECTION_ITEM_CUSTOMIZE_SIDEBAR } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { NavItemsContext } from '../../controllers/context.tsx';
import type { ConfigurableMenuId } from '../../types.tsx';

type SectionItemAnalyticsParams = { menuId?: ConfigurableMenuId; sectionItem: string };
type GetIsInMoreParams = {
	sectionItem: string;
	menuId?: ConfigurableMenuId;
	moreMenuIds: ConfigurableMenuId[];
};

export const getIsInMore = ({ sectionItem, menuId, moreMenuIds }: GetIsInMoreParams) => {
	if (sectionItem === SECTION_ITEM_CUSTOMIZE_SIDEBAR) {
		return moreMenuIds.length !== 0;
	}
	if (menuId) {
		return moreMenuIds.includes(menuId);
	}
	return false;
};

export function useSectionItemAnalytics(): ({
	menuId,
	sectionItem,
}: SectionItemAnalyticsParams) => void {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { moreMenuIds } = useContext(NavItemsContext);

	const sendSectionItemAnalyticsEvent = useCallback(
		({ menuId, sectionItem }: SectionItemAnalyticsParams) => {
			const event = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: NAVIGATION_ITEM,
			});

			const isInMore = getIsInMore({ sectionItem, menuId, moreMenuIds });

			fireUIAnalytics(event, sectionItem, { isInMore });
		},
		[createAnalyticsEvent, moreMenuIds],
	);

	return sendSectionItemAnalyticsEvent;
}

import React from 'react';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { ROUTE_GROUPS_CLASSIC_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { projectDirectoryRedirectRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/projectDirectoryRedirectRoute.tsx';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/ScreenRedirect.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';

const ProjectsDirectoryRedirect = () => <ScreenRedirect to="/jira/projects" />;

export const projectDirectoryRedirectRouteEntry = createEntry(
	projectDirectoryRedirectRoute,
	() => ({
		group: ROUTE_GROUPS_CLASSIC_SOFTWARE,
		component: ProjectsDirectoryRedirect,
		layout: globalLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
		},

		isRedirect: true,
		resources: [...getNavigationResources()],
		forPaint: [LazyAtlassianNavigation],
	}),
);

import React, { useCallback, useMemo } from 'react';
import noop from 'lodash/noop';
import DeleteIcon from '@atlaskit/icon/core/delete';
import { ButtonItem } from '@atlaskit/menu';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import {
	convertToProjectType,
	type JiraProjectType,
	TEAM_MANAGED_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { shouldShowTrashProjectTrigger } from '@atlassian/jira-project-restore-utils/src/utils/show-trash-trigger/index.tsx';
import { deleteProjectModalEntryPoint } from '@atlassian/jira-projects-directory-v3-delete-project-modal/entrypoint.tsx';
import type { ProjectActionData } from '../../types.tsx';
import messages from './messages.tsx';

type DeleteProjectProps = ProjectActionData & {
	jiraProjectType: JiraProjectType;
	onClick?: () => void;
};

export function DeleteProject({
	projectId,
	jiraProjectStyle,
	jiraProjectType,
	isAdmin = false,
	canAdministerProject = false,
	setActiveEntryPoint,
	setProjectToRestore,
	onDeleteSuccess: deleteSuccessCallback,
	onClick,
}: DeleteProjectProps) {
	const entryPointParams = useMemo(() => ({ projectId }), [projectId]);
	const { formatMessage } = useIntl();

	const { entryPointReferenceSubject: deleteRefSubject, entryPointActions: deleteActions } =
		useEntryPoint(deleteProjectModalEntryPoint, entryPointParams, undefined, {
			timeToIntent: 50,
		});

	const deleteEntryPointRuntimeProps = useMemo(
		() => ({
			onClose: () => noop,
			onDeleteProjectSuccess: () => {
				deleteActions.unload();
				setActiveEntryPoint(null);
				deleteSuccessCallback?.();
			},
			onProjectRestore: setProjectToRestore,
		}),
		[deleteActions, setActiveEntryPoint, setProjectToRestore, deleteSuccessCallback],
	);

	const deleteTriggerRef = useEntryPointButtonTrigger(deleteActions);

	const handleClick = useCallback(() => {
		setActiveEntryPoint({
			entryPointReferenceSubject: deleteRefSubject,
			runtimeProps: deleteEntryPointRuntimeProps,
			entryPointActions: deleteActions,
			id: 'project-delete-modal',
		});
		onClick?.();
	}, [deleteRefSubject, deleteEntryPointRuntimeProps, deleteActions, setActiveEntryPoint, onClick]);

	if (
		!shouldShowTrashProjectTrigger({
			projectType: convertToProjectType(jiraProjectType),
			isAdmin,
			canAdministerProject,
			isSimplified: jiraProjectStyle === TEAM_MANAGED_PROJECT,
		})
	) {
		return null;
	}

	return (
		<ButtonItem
			ref={deleteTriggerRef}
			iconBefore={<DeleteIcon label="" color={token('color.icon.danger')} spacing="spacious" />}
			onClick={handleClick}
		>
			<Text color="color.text.danger">{formatMessage(messages.deleteProject)}</Text>
		</ButtonItem>
	);
}

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	overview: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.overview',
		defaultMessage: 'Overview',
		description: 'Assets dropdown item to link customer to Operations Overview page.',
	},
	alerts: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.alerts',
		defaultMessage: 'Alerts',
		description: 'Assets dropdown item to link customer to Operations Alerts page.',
	},
	onCallSchedules: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.on-call-schedules',
		defaultMessage: 'On-call schedules',
		description: 'Assets dropdown item to link customer to Operations On-call Schedules page.',
	},
	generalConfiguration: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.general-configuration',
		defaultMessage: 'General configuration',
		description:
			'Used as the alt text for an icon button that when used navigates to create project',
	},
	reports: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.reports',
		defaultMessage: 'Reports',
		description: 'Reports item on the sidebar',
	},
	defaultFilters: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.default-filters',
		defaultMessage: 'Default filters',
		description: 'Shared with me expantable menu item',
	},
	sharedWithMe: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.shared-with-me',
		defaultMessage: 'Shared with me',
		description: 'Shared with me expantable menu item',
	},
	createdByMe: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.created-by-me',
		defaultMessage: 'Created by me',
		description: 'Created by me expantable menu item',
	},
	starred: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.starred',
		defaultMessage: 'Starred',
		description: 'Starred alerts',
	},
	allAlerts: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.all-alerts',
		defaultMessage: 'All alerts',
		description: 'The label of showing all alerts link',
	},
	myAlerts: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.my-alerts',
		defaultMessage: 'My alerts',
		description: 'The label of alerts that assigned to me link',
	},
	allSearch: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.all-search',
		defaultMessage: 'All',
		description: 'Predefined search query in the alert list navigation.',
	},
	openSearch: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.open-search',
		defaultMessage: 'Open',
		description: 'Predefined search query in the alert list navigation.',
	},
	closedSearch: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.closed-search',
		defaultMessage: 'Closed',
		description: 'Predefined search query in the alert list navigation.',
	},
	unackedSearch: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.unacked-search',
		defaultMessage: 'Unacknowledged',
		description: 'Predefined search query in the alert list navigation.',
	},
	notSeenSearch: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.not-seen-search',
		defaultMessage: 'Not seen',
		description: 'Predefined search query in the alert list navigation.',
	},
	assignedToMeSearch: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.assigned-to-me-search',
		defaultMessage: 'Assigned to me',
		description: 'Predefined search query in the alert list navigation.',
	},
});

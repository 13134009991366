import { createContext, useContext } from 'react';
import noop from 'lodash/noop';
import type { PerformanceMarks } from './constants.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { JQL_BUILDER_PERFORMANCE_MARKS } from './constants';

export type OnPerformanceMark = (attributes: { mark: PerformanceMarks }) => void;

export const PerformanceContext = createContext<OnPerformanceMark>(noop);

export const usePerformanceContext = () => useContext(PerformanceContext);

import React, { type Ref } from 'react';

import { useIntl } from 'react-intl-next';

import ClockIcon from '@atlaskit/icon/core/clock';
import {
	FlyoutMenuItem,
	FlyoutMenuItemContent,
	type FlyoutMenuItemProps,
	FlyoutMenuItemTrigger,
} from '@atlassian/navigation-system/side-nav/flyout-menu-item';

import { GlobalRecentMenuContent } from './menu-content';
import { messages } from './messages';

export type GlobalRecentMenuItemProps = {
	onOpenChange?: FlyoutMenuItemProps['onOpenChange'];
	isDefaultOpen?: FlyoutMenuItemProps['isDefaultOpen'];
	triggerRef?: Ref<HTMLButtonElement>;
};

export const withGlobalRecentMenuItem =
	(Content: React.FunctionComponent) =>
	({ onOpenChange, isDefaultOpen, triggerRef }: GlobalRecentMenuItemProps) => {
		const { formatMessage } = useIntl();

		return (
			<FlyoutMenuItem onOpenChange={onOpenChange} isDefaultOpen={isDefaultOpen}>
				<FlyoutMenuItemTrigger
					ref={triggerRef}
					iconBefore={<ClockIcon label="" color="currentColor" />}
				>
					{formatMessage(messages.flyoutMenuItemTriggerTitle)}
				</FlyoutMenuItemTrigger>
				<FlyoutMenuItemContent>
					<Content />
				</FlyoutMenuItemContent>
			</FlyoutMenuItem>
		);
	};

export const GlobalRecentMenuItem = withGlobalRecentMenuItem(GlobalRecentMenuContent);

import { useCallback } from 'react';

// Adds stop propagation to any event handler.
// The event handler can be undefined, a new event handler will still be returned that doesn't propagate.
// The new event handler is wrapped in a callback.
export const useStopPropagation = <E extends { stopPropagation: () => void }>(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onEvent: ((e: E, ...args: any[]) => void) | undefined,
) =>
	useCallback<NonNullable<typeof onEvent>>(
		(e, ...args) => {
			try {
				onEvent?.(e, ...args);
			} finally {
				e.stopPropagation();
			}
		},
		[onEvent],
	);

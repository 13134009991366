/* eslint-disable jira/import-whitelist */
import { loadQuery, type PreloadedQuery } from 'react-relay';
import { ROUTE_NAMES_PROJECTS_DIRECTORY_MANAGE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import {
	DEFAULT_LIMIT,
	filterInitialState,
	filterAliasMap,
} from '@atlassian/jira-projects-directory-v3-utils/src/constants.tsx';
import {
	mapQueryToFilter,
	sanitizeLegacyQuery,
} from '@atlassian/jira-projects-directory-v3-utils/src/utils/index.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import { QueryPromisesMap } from '@atlassian/jira-relay-query-promises/src/index.tsx';
import { RELAY_RESOURCE_TYPE } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import ProjectDirectoryPageQuery, {
	type pageLayout_projectsDirectoryLayout_RootQuery,
} from '@atlassian/jira-relay/src/__generated__/pageLayout_projectsDirectoryLayout_RootQuery.graphql';
import { createResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_PROJECTS_DIRECTORY_RELAY } from '../constants.tsx';

export const projectsDirectoryPageResource = createResource<
	PreloadedQuery<pageLayout_projectsDirectoryLayout_RootQuery>
>({
	type: `${RELAY_RESOURCE_TYPE}_${RESOURCE_TYPE_PROJECTS_DIRECTORY_RELAY}`,
	getKey: () => 'projects-directory-relay',
	// @ts-expect-error - might return null
	getData: async ({ query, route }, { tenantContext }) => {
		const { cloudId, isAnonymous } = tenantContext;

		// SSR/AGG does not currently work for anonymous users and returns a 403. We should temporally disable anonymous SSR until
		// the underlying auth issues have been fixed. Duplo team will push the real fix later.
		if (__SERVER__ && isAnonymous) {
			return null;
		}

		const queryReference = loadQuery(
			getRelayEnvironment(),
			ProjectDirectoryPageQuery,
			{
				cloudId: tenantContext.cloudId,
				filter: mapQueryToFilter(filterInitialState, {
					cloudId,
					query: sanitizeLegacyQuery(query),
					aliases: filterAliasMap,
				}),
				first: query?.page ? DEFAULT_LIMIT * (parseInt(query.page, 10) || 1) : DEFAULT_LIMIT,
				last: DEFAULT_LIMIT,
				isAdminSettingsContext: route.name === ROUTE_NAMES_PROJECTS_DIRECTORY_MANAGE,
				isAnonymous,
			},
			{
				fetchPolicy: 'network-only',
			},
		);

		if (__SERVER__ && queryReference.id != null) {
			await QueryPromisesMap.get(queryReference.id);
		}

		return queryReference;
	},
});

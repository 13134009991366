import React from 'react';
import Icon from '@atlaskit/icon';
import type { CustomGlyphProps, Size } from '@atlaskit/icon/types';

const sortOrderGlyph = (props: CustomGlyphProps) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 16 16"
		data-testid={props['data-testid']}
		aria-label={props['aria-label']}
	>
		<path
			fill="none"
			d="M1.7749 3.25001L4.24978 0.775132L6.72465 3.25001M14.2251 10.75L11.7502 13.2249L9.27535 10.75M11.75 12.5V0.75M4.25 13.25L4.25 1.50001"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="square"
			strokeLinejoin="round"
		/>
	</svg>
);

const SortIcon = ({ label, size = 'medium' }: { label: string; size?: Size }) => (
	<Icon glyph={sortOrderGlyph} label={label} size={size} />
);

export default SortIcon;

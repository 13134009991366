export const BOARD_CREATE_ID = 'jsw-board-create';
export const FLYOUT_MENU_CONTENT_ID = 'jsw-board-create-flyout-menu-content';
export const BOARD_CREATE_PACKAGE_NAME = 'jiraSoftwareBoardCreate';
export const TEAM_NAME = 'deliveroo';

export const BOARD_TYPES = {
	KANBAN: 'KANBAN',
	SCRUM: 'SCRUM',
} as const;

export const BOARD_SOURCE_FIELDS = {
	TEAM: 'team',
	ISSUE_TYPES: 'issueTypes',
	LABELS: 'labels',
	SAVED_FILTER: 'savedFilter',
} as const;

export const BOARD_SOURCE_FIELDS_JQLTERM = {
	[BOARD_SOURCE_FIELDS.ISSUE_TYPES]: 'type',
	[BOARD_SOURCE_FIELDS.LABELS]: 'labels',
} as const;

export const PICKER_SEARCH_DEBOUNCE_TIME = 500;

export const VALIDATION_ERROR_EMPTY = 'VALIDATION_ERROR_EMPTY';

export const BOARD_NAME_LIMIT = 155;

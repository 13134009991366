/**
 * @generated SignedSource<<e540039759309713590ca510c2309350>>
 * @relayHash e9ff20c2004074e5f8487931ba2a1933
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1d641f5a11915eaab7ddcbe516b834693321fb25d4219fb83ab66d4ef39c3a61

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessApprovalsSettingsQuery } from './src_jiraBusinessApprovalsSettingsQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessApprovalsSettingsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "1d641f5a11915eaab7ddcbe516b834693321fb25d4219fb83ab66d4ef39c3a61",
    "metadata": {},
    "name": "src_jiraBusinessApprovalsSettingsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import type { ExperienceDetails } from '@atlassian/jira-business-experience-tracking/src/types.tsx';

export const VIEW_THEME_PICKER_EXPERIENCE_OLD: ExperienceDetails = {
	experience: 'viewThemePicker',
	packageName: 'jiraBusinessThemeComponents',
	teamName: 'work-management-frontier',
};

export const VIEW_THEME_PICKER_EXPERIENCE = {
	experience: 'viewThemePicker',
	analyticsSource: 'jiraProjectThemeComponents',
	teamName: 'work-management-frontier',
};

/**
 * @generated SignedSource<<eef91b5559b75acdf91ac8c3d02c174f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4FiltersContent$data = {
  readonly jira: {
    readonly favouriteFilters: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly __typename?: string;
          readonly filterId?: string;
          readonly " $fragmentSpreads": FragmentRefs<"Nav4FilterMenuItem">;
        };
      } | null | undefined> | null | undefined;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
      };
    };
    readonly recentFilters: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly filterId?: string;
          readonly " $fragmentSpreads": FragmentRefs<"Nav4FilterMenuItem">;
        };
      } | null | undefined> | null | undefined;
    };
    readonly systemFilters: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly filterId: string;
          readonly " $fragmentSpreads": FragmentRefs<"Nav4FilterMenuItem">;
        };
      } | null | undefined> | null | undefined;
    };
  };
  readonly " $fragmentType": "Nav4FiltersContent";
};
export type Nav4FiltersContent$key = {
  readonly " $data"?: Nav4FiltersContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4FiltersContent">;
};

import Nav4FiltersContentQuery_graphql from './Nav4FiltersContentQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v1 = {
  "kind": "ScalarField",
  "name": "filterId"
},
v2 = {
  "kind": "FragmentSpread",
  "name": "Nav4FilterMenuItem"
},
v3 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currentURL"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "fragmentPathInResult": [],
      "operation": Nav4FiltersContentQuery_graphql
    }
  },
  "name": "Nav4FiltersContent",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": "favouriteFilters",
              "args": [
                (v0/*: any*/),
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "sort": {
                      "order": "DESC"
                    },
                    "type": "FILTER"
                  }
                },
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 15
                }
              ],
              "concreteType": "JiraFavouriteConnection",
              "kind": "LinkedField",
              "name": "favourites",
              "plural": false,
              "selections": [
                {
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "hasNextPage"
                    }
                  ]
                },
                {
                  "concreteType": "JiraFavouriteEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "__typename"
                              },
                              (v1/*: any*/),
                              (v2/*: any*/)
                            ],
                            "type": "JiraFilter",
                            "abstractKey": "__isJiraFilter"
                          }
                        ]
                      },
                      "action": "THROW",
                      "path": "jira.favouriteFilters.edges.node"
                    }
                  ]
                },
                (v3/*: any*/)
              ]
            },
            "action": "THROW",
            "path": "jira.favouriteFilters"
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": "recentFilters",
              "args": [
                (v0/*: any*/),
                {
                  "kind": "Variable",
                  "name": "currentURL",
                  "variableName": "currentURL"
                },
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 20
                },
                {
                  "kind": "Literal",
                  "name": "types",
                  "value": [
                    "FILTER"
                  ]
                }
              ],
              "concreteType": "JiraSearchableEntityConnection",
              "kind": "LinkedField",
              "name": "recentItems",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraSearchableEntityEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": (v4/*: any*/),
                            "type": "JiraCustomFilter"
                          }
                        ]
                      },
                      "action": "THROW",
                      "path": "jira.recentFilters.edges.node"
                    }
                  ]
                },
                (v3/*: any*/)
              ]
            },
            "action": "THROW",
            "path": "jira.recentFilters"
          },
          {
            "kind": "RequiredField",
            "field": {
              "args": [
                (v0/*: any*/)
              ],
              "concreteType": "JiraSystemFilterConnection",
              "kind": "LinkedField",
              "name": "systemFilters",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraSystemFilterEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "concreteType": "JiraSystemFilter",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v4/*: any*/)
                      },
                      "action": "THROW",
                      "path": "jira.systemFilters.edges.node"
                    }
                  ]
                }
              ]
            },
            "action": "THROW",
            "path": "jira.systemFilters"
          }
        ]
      },
      "action": "THROW",
      "path": "jira"
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "855858f36cb0de864abf601ce3c9d552";

export default node;

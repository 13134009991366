import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout/src/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { newIssueViewLockInStatusResource } from '@atlassian/jira-router-resources-classic-projects/src/services/new-issue-view-lock-in-status/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { jsmRepackagingBannerResource } from '@atlassian/jira-router-resources-jsm-repackaging-banner/src/index.tsx';
import {
	getQueuesResources,
	getKnowledgeBaseResources,
	getInsightResources,
	getIssueListSsrResource,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { customPracticeQueueRoute } from '@atlassian/jira-router-routes-servicedesk-queue-routes/src/customPracticeQueueRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import {
	CustomQueueRouteComponent,
	LazyServicedeskQueuesAgentAndIssueView,
} from './ui/CustomQueueRouteComponent.tsx';

export const customPracticeQueueRouteEntry: Route = createEntry(customPracticeQueueRoute, () => ({
	group: ROUTE_GROUPS_SERVICEDESK,
	component: CustomQueueRouteComponent,
	layout: composeLayouts(
		serviceProjectLayout,
		embeddedIssuePreloadLayoutBuilder([
			{
				params: [
					{
						key: 'issueKey',
					},
				],
			},
		]),
	),
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},
	resources: [
		...getQueuesResources(),
		...getKnowledgeBaseResources(),
		...getInsightResources(),
		...getIssueListSsrResource(),
		newIssueViewLockInStatusResource,
		getConsolidationStateResource(),
		jsmRepackagingBannerResource,
	],
	afterPaint: [LazyIssueApp],
	forPaint: [LazyAtlassianNavigation, LazyServicedeskQueuesAgentAndIssueView],
}));

import React, { type ReactElement } from 'react';
import { graphql, useFragment } from 'react-relay';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { SECTION_ITEM_PROJECTS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_PROJECTS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { Nav4ExpandableMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-expandable-menu-item/index.tsx';
import { getTestId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-test-id/index.tsx';
import { useSectionItemAnalytics } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-section-item-analytics/index.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import { Nav4ProjectsContent } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-core/src/common/ui/content/Nav4ProjectsContent.tsx';
import type { Nav4ProjectsForLanding$key } from '@atlassian/jira-relay/src/__generated__/Nav4ProjectsForLanding.graphql';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { ExpandableMenuItemContent, ExpandableMenuItemTrigger } from '@atlassian/navigation-system';
import { PACKAGE_NAME, TEAM_NAME } from '../../../common/constants.tsx';
import { Footer } from '../../../common/ui/footer/index.tsx';

type Nav4ProjectsForLandingProps = {
	actionsOnHover: ReactElement;
	isExpanded: boolean;
	queryRef: Nav4ProjectsForLanding$key;
};

export function Nav4ProjectsForLanding(props: Nav4ProjectsForLandingProps) {
	const { actionsOnHover, isExpanded, queryRef } = props;
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(L1_MENU_ID.PROJECTS);
	const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();

	const data = useFragment<Nav4ProjectsForLanding$key>(
		graphql`
			fragment Nav4ProjectsForLanding on Query {
				...Nav4ProjectsContent
			}
		`,
		queryRef,
	);

	const menuId = MENU_ID_PROJECTS;

	return (
		<Nav4ExpandableMenuItem
			isExpanded={isExpanded}
			menuId={menuId}
			onExpansionToggle={(hasExpanded) => {
				if (hasExpanded) {
					sendSectionItemAnalyticsEvent({
						menuId: L1_MENU_ID.PROJECTS,
						sectionItem: SECTION_ITEM_PROJECTS,
					});
				}
			}}
		>
			<ExpandableMenuItemTrigger
				actionsOnHover={actionsOnHover}
				// Prevent double-up of accessible name that occurs in icon + text situation.
				aria-label={formattedMessage}
				elemBefore={icon}
				testId={getTestId(menuId)}
			>
				{formattedMessage}
			</ExpandableMenuItemTrigger>
			<ExpandableMenuItemContent>
				<UFOSegment name="nav4-sidebar-projects-landing">
					<JSErrorBoundary
						id="nav4-sidebar-projects-landing"
						packageName={PACKAGE_NAME}
						teamName={TEAM_NAME}
						fallback="flag"
					>
						{/* This component uses the fragment from the ancestor query. */}
						<Nav4ProjectsContent queryRef={data} />
					</JSErrorBoundary>
				</UFOSegment>
				<Footer />
			</ExpandableMenuItemContent>
		</Nav4ExpandableMenuItem>
	);
}

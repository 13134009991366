/**
 * @generated SignedSource<<a5c829927141e6d94163517ce1d4d438>>
 * @relayHash 05cd1e591b336cecd17e4d119a089db9
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2fa7826555f7e47c6f25502ea0cc3733efe0e7dba669d14a0547a015abad3763

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessSummaryQuery } from './src_jiraBusinessSummaryQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessSummaryQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "2fa7826555f7e47c6f25502ea0cc3733efe0e7dba669d14a0547a015abad3763",
    "metadata": {},
    "name": "src_jiraBusinessSummaryQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { useFormState } from '@atlaskit/form';
import { Stack } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import NewBoardIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/software/components/bug-and-task-cards-in-a-column/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { BoardCreateFormValues } from '../../../common/types.tsx';
import messages from './messages.tsx';

export const ModalIllustration = () => {
	const state = useFormState<BoardCreateFormValues>();
	const { formatMessage } = useIntl();
	return (
		<Stack>
			<h5 css={modalIllustrationHeaderStyles}>
				{state?.values.boardName?.trim() || formatMessage(messages.illustrationPlaceHolderHeader)}
			</h5>
			<Stack alignBlock="center">
				<NewBoardIllustration alt={formatMessage(messages.illustrationAltText)} height="334px" />
			</Stack>
		</Stack>
	);
};

const modalIllustrationHeaderStyles = css({
	font: token('font.heading.small'),
	fontWeight: token('font.weight.semibold', '600'),
	color: token('color.text.subtle'),
	marginLeft: `calc(${token('space.800')} - ${token('space.150')})`,
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	maxWidth: '240px',
});

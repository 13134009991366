import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServiceDesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';

export const getLegacyAutomationRoutesCommon = () => ({
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServiceDesk,
		sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
	},
	resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
});

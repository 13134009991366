import React from 'react';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyAlertList = lazyForPaint(
	() =>
		import(
			/* webpackChunkName: "async-jira-eoc-alert-list", jiraSpaEntry: "async-jira-eoc-alert-list" */ '@atlassian/jira-eoc-alert-list/src/ui/index.tsx'
		),
);

export const AlertListComponent = () => (
	<LazyPage Page={LazyAlertList} pageId="async-jira-eoc-alert-list" shouldShowSpinner={false} />
);

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	// Delete genericPickerAriaLabel when cleaning up nav4_simplified_board_create_beta
	genericPickerAriaLabel: {
		id: 'software-board-create.modal.form.source-value-picker.generic-picker.generic-picker-aria-label',
		defaultMessage: 'Select an option',
		description: 'Label for a select',
	},
	genericPickerPlaceholder: {
		id: 'software-board-create.modal.form.source-value-picker.generic-picker.generic-picker-placeholder',
		defaultMessage: 'Select',
		description: 'Placeholder for a select',
	},
});

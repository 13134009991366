import React, {
	type PropsWithChildren,
	useContext,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react';
import { CONFIGURABLE_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { useSidebarNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-context/index.tsx';
import { useSidebarNav4Controller } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-controller-context/index.tsx';
import {
	NavItemsContext,
	PeekMenuIdContext,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/controllers/context.tsx';
import type { ConfigurableMenuId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';

// Eventually this will be all configurable menu ids and can be removed at that point.
// TODO: Adam, I think we've reached that point.
const CONFIGURABLE_MENU_IDS_USING_SIDEBAR_CONTEXT: ConfigurableMenuId[] = [
	CONFIGURABLE_MENU_ID.ASSETS,
	CONFIGURABLE_MENU_ID.APPS,
	CONFIGURABLE_MENU_ID.DASHBOARDS,
	CONFIGURABLE_MENU_ID.FILTERS,
	CONFIGURABLE_MENU_ID.OPERATIONS,
	CONFIGURABLE_MENU_ID.PLANS,
	CONFIGURABLE_MENU_ID.PROJECTS,
];

type PeekMenuIdContextProviderProps = PropsWithChildren<{}>;

export const PeekMenuIdContextProvider = ({ children }: PeekMenuIdContextProviderProps) => {
	const { isSelectedPath } = useSidebarNav4();
	const { isControlledNav, isControlledPath } = useSidebarNav4Controller();
	const { moreMenuIds } = useContext(NavItemsContext);

	const [peekMenuId, setPeekMenuId] = useState(() =>
		getAutoPeekMenuId(isSelectedPath, isControlledNav, isControlledPath, moreMenuIds),
	);

	// This effect automatically overrides the manual value of peek menu id (set when clicking in the more menu item).
	// This effect is only triggered when controlled, selected, or more menu ids change.
	// Use layout effect so peek menu id is set before render.
	// An alternative to any effect would be to set peek menu id when setting selected path.
	// However, that may require elevating this context.
	useLayoutEffect(
		() =>
			setPeekMenuId((currentPeekMenuId) =>
				getAutoPeekMenuId(
					isSelectedPath,
					isControlledNav,
					isControlledPath,
					moreMenuIds,
					currentPeekMenuId,
				),
			),
		[isControlledNav, isControlledPath, isSelectedPath, moreMenuIds],
	);

	const peekMenuIdContext = useMemo(() => ({ peekMenuId, setPeekMenuId }), [peekMenuId]);

	return (
		<PeekMenuIdContext.Provider value={peekMenuIdContext}>{children}</PeekMenuIdContext.Provider>
	);
};

const getAutoPeekMenuId = (
	isSelectedPath: (menuId: string) => boolean,
	isControlledNav: boolean,
	isControlledPath: (menuId: string) => boolean,
	moreMenuIds: ConfigurableMenuId[],
	peekMenuId?: ConfigurableMenuId | undefined,
): ConfigurableMenuId | undefined => {
	// When controlled...
	if (isControlledNav) {
		return moreMenuIds.find(isControlledPath);
	}

	// Otherwise selected...
	const autoPeekMenuId = moreMenuIds.find(isSelectedPath);

	// Once all configurable menu ids are using sidebar context we can just return `autoPeekMenuId`.
	if (autoPeekMenuId) {
		return autoPeekMenuId;
	}

	// Unpeek if peek menu id is no longer selected.
	// Only do this for menu ids using the sidebar context for now.
	// Legacy menu ids set peek menu id manually.
	if (peekMenuId && CONFIGURABLE_MENU_IDS_USING_SIDEBAR_CONTEXT.includes(peekMenuId)) {
		return undefined;
	}

	// Otherwise unchanged.
	return peekMenuId;
};

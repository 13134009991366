import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { advancedRoadmapsPlanPageInitialChecksResource } from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { softwareAdvancedRoadmapsSetupRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/softwareAdvancedRoadmapsSetupRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import SetupPage, { LazyAdvancedRoadmapsSetup } from './ui/setup/index.tsx';

export const planSetupPageRouteEntry: Route = createEntry(
	softwareAdvancedRoadmapsSetupRoute,
	() => ({
		group: ROUTE_GROUPS_SOFTWARE,
		component: SetupPage,
		layout: globalLayout,
		resources: [...getNavigationResources(), advancedRoadmapsPlanPageInitialChecksResource],
		forPaint: [LazyAtlassianNavigation, LazyAdvancedRoadmapsSetup],
	}),
);

/**
 * @generated SignedSource<<1e80ec120bc56b1532601415a1a168aa>>
 * @relayHash 92c770d34e02b4cbf895f8e8557e142c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 98ff9896a534365343e79a6d1c788e50aedc51a89613efbd1d99e193ec977064

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentBrowseIntentsStatisticsQuery } from './srcVirtualAgentBrowseIntentsStatisticsQuery.graphql';

import newPerformancePage_provider from '@atlassian/jira-relay-provider/src/jsm-va-enable-performance-page.relayprovider';

const node: PreloadableConcreteRequest<srcVirtualAgentBrowseIntentsStatisticsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "98ff9896a534365343e79a6d1c788e50aedc51a89613efbd1d99e193ec977064",
    "metadata": {},
    "name": "srcVirtualAgentBrowseIntentsStatisticsQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaenableperformancepagerelayprovider": newPerformancePage_provider
    }
  }
};

export default node;

import React, { useEffect } from 'react';
import { Section, SkeletonItem } from '@atlaskit/side-navigation';
import { CORE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { type IntlShape, useIntl } from '@atlassian/jira-intl';
import { ExpandableSubmenuItem } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/expandable-submenu-item/index.tsx';
import { MenuItem } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/menu-item/main.tsx';
import { TitleHeader } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/title-header/main.tsx';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project/index.tsx';
import { NestingMenuItem } from '@atlassian/jira-navigation-apps-sidebar-common/src/ui/nesting-menu-item/main.tsx';
import { NotificationsSubMenu } from '@atlassian/jira-navigation-apps-sidebar-common/src/ui/notifications-sub-menu/index.tsx';
import { shouldRenderNesting } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/should-render/index.tsx';
import { withThrowOnError } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/throw-when-no-data-and-error/index.tsx';
import { matchUrlPathname } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/url-matchers/index.tsx';
import { getNextgenSettingsUrl } from '@atlassian/jira-project-settings-apps-common/src/urls.tsx';
import {
	ConnectAppsContainer,
	useConnectApps,
} from '@atlassian/jira-project-settings-apps-connect-apps/src/baskets/connect-apps/index.tsx';
import { useIsPremiumUserSeat } from '@atlassian/jira-router-resources-business-navigation/src/index.tsx';
import { useApplicationPermissions } from '@atlassian/jira-tenant-context-controller/src/components/application-permissions/index.tsx';
import { useDlpEntitlement } from '@atlassian/jira-tenant-context-controller/src/components/dlp-entitlement/index.tsx';
import { SECTION_ID } from '../../../../../../common/constants/index.tsx';
import { useSettingFeatures } from '../../../../../../controllers/features/index.tsx';
import { EcosystemAppsMenu } from './ecosystem-apps/main.tsx';
import { messages as ecosystemAppsMessages } from './ecosystem-apps/messages.tsx';
import { IssueTypes } from './issue-types/index.tsx';
import { messages } from './messages.tsx';

export const useThrowProject = withThrowOnError(useProject);

type SettingsProps = {
	stack: string[];
};

interface DisplayNotificationsOptionsProps {
	hasCoreAccess: boolean;
	formatMessage: IntlShape['formatMessage'];
	baseSettingsUrl: string;
	projectId: number;
}

const DisplayNotificationsOptions = ({
	hasCoreAccess,
	formatMessage,
	baseSettingsUrl,
	projectId,
}: DisplayNotificationsOptionsProps) => {
	if (!hasCoreAccess) {
		return <></>;
	}

	return (
		<ExpandableSubmenuItem
			analytics={{ itemId: 'notifications' }}
			menuItemLabel={formatMessage(messages.itemNotifications)}
			forceExpand
			submenuId="core-notifications-settings"
		>
			<NotificationsSubMenu
				settingsUrl={`${baseSettingsUrl}/notifications`}
				issueLogsUrl={`${baseSettingsUrl}/notifications/issue-logs?projectId=${projectId}`}
			/>
		</ExpandableSubmenuItem>
	);
};

export const Settings = ({ stack }: SettingsProps) => {
	const { formatMessage } = useIntl();
	const { data: project } = useThrowProject();
	const { hasCoreAccess } = useApplicationPermissions();
	const hasDlpEnabled = useDlpEntitlement();
	const isPremiumUserSeat = useIsPremiumUserSeat();
	const { data: settingFeatures } = useSettingFeatures();

	const [{ data: connectAppsData }, { fetch: fetchConnectApps }] = useConnectApps();

	// to remove on isAppControlsEnabled() cleanup
	useEffect(() => {
		if (project && !connectAppsData) fetchConnectApps(project.key);
	}, [connectAppsData, fetchConnectApps, project]);

	if (project == null) {
		return <SkeletonItem />;
	}

	const baseSettingsUrl = getNextgenSettingsUrl(CORE_PROJECT, project.key);
	const showCoreForms = fg('proforma_mvp_jwm_project_settings') && hasCoreAccess;

	const BackMenuItem = {
		href: baseSettingsUrl,
		title: formatMessage(messages.title),
	};

	return (
		<>
			<TitleHeader>{formatMessage(messages.title)}</TitleHeader>
			<Section>
				<MenuItem
					analytics={{ itemId: 'details' }}
					selectedOn={matchUrlPathname()}
					href={`${baseSettingsUrl}/details`}
				>
					{formatMessage(messages.itemDetails)}
				</MenuItem>
				{hasCoreAccess && (
					<MenuItem
						analytics={{ itemId: 'access' }}
						href={`${baseSettingsUrl}/access`}
						selectedOn={matchUrlPathname()}
					>
						{formatMessage(messages.itemAccess)}
					</MenuItem>
				)}
				<DisplayNotificationsOptions
					hasCoreAccess={hasCoreAccess}
					formatMessage={formatMessage}
					baseSettingsUrl={baseSettingsUrl}
					projectId={project.id}
				/>
				<MenuItem
					analytics={{ itemId: 'automation' }}
					selectedOn={matchUrlPathname()}
					href={`${baseSettingsUrl}/automation`}
				>
					{formatMessage(messages.automation)}
				</MenuItem>
				{hasCoreAccess &&
					settingFeatures?.hasProjectEfaFields &&
					fg('project_fields_page_for_tmp_-_mvp') && (
						<MenuItem
							analytics={{ itemId: 'fields' }}
							href={`${baseSettingsUrl}/fields`}
							selectedOn={matchUrlPathname()}
						>
							{formatMessage(messages.itemFieldsNonFinal)}
						</MenuItem>
					)}
				{shouldRenderNesting(hasCoreAccess, stack, SECTION_ID.ISSUE_TYPES) && (
					<NestingMenuItem
						analytics={{ itemId: 'issuetypes' }}
						href={`${baseSettingsUrl}/issuetypes`}
						id={SECTION_ID.ISSUE_TYPES}
						overrides={{ BackMenuItem }}
						title={formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.itemIssueTypesIssueTermRefresh
								: messages.itemIssueTypes,
						)}
					>
						<IssueTypes />
					</NestingMenuItem>
				)}
				{isPremiumUserSeat && (
					<MenuItem
						analytics={{ itemId: 'approvals' }}
						selectedOn={matchUrlPathname()}
						href={`${baseSettingsUrl}/approvals`}
					>
						{formatMessage(messages.itemApprovals)}
					</MenuItem>
				)}
				{showCoreForms && (
					<MenuItem
						analytics={{ itemId: 'proforma-forms' }}
						selectedOn={matchUrlPathname()}
						href={`${baseSettingsUrl}/forms`}
					>
						{formatMessage(messages.itemAdvancedForms)}
					</MenuItem>
				)}
				{shouldRenderNesting(hasCoreAccess, stack, 'apps') && (
					<ExpandableSubmenuItem
						analytics={{ itemId: 'apps' }}
						menuItemLabel={formatMessage(ecosystemAppsMessages.apps)}
						forceExpand
						submenuId="nextgen-apps-settings"
					>
						<EcosystemAppsMenu />
					</ExpandableSubmenuItem>
				)}
				{hasDlpEnabled && (
					<MenuItem
						analytics={{ itemId: 'data-classification' }}
						href={`${baseSettingsUrl}/data-classifications`}
						selectedOn={matchUrlPathname()}
					>
						{formatMessage(messages.dataClassificationNonFinal)}
					</MenuItem>
				)}
			</Section>
		</>
	);
};

export const SettingsMenu = (props: SettingsProps) => {
	const { data: project } = useThrowProject();

	if (project == null) return <SkeletonItem />;

	return (
		// to remove on isAppControlsEnabled() cleanup
		<ConnectAppsContainer onError={undefined} scope={project.key}>
			<Settings {...props} />
		</ConnectAppsContainer>
	);
};

import React, { useCallback } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import {
	Nav4Apps as Nav4AppsWithoutErrorBoundary,
	type Nav4AppsProps,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-apps/src/ui/apps/Nav4Apps.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './common/constants.tsx';
import { createAttributes } from './common/utils/get-attributes/index.tsx';

export const Nav4Apps = (props: Nav4AppsProps) => {
	const getAttributes = useCallback(
		(error: Error) => createAttributes({ error, component: 'Nav4Apps' }),
		[],
	);
	return (
		<UFOSegment name="nav4-sidebar-apps">
			<JSErrorBoundary
				fallback="flag"
				id="apps-section"
				attributes={getAttributes}
				packageName={PACKAGE_NAME}
				teamName={TEAM_NAME}
			>
				<Nav4AppsWithoutErrorBoundary {...props} />
			</JSErrorBoundary>
		</UFOSegment>
	);
};

import { isFedRamp } from '@atlassian/atl-context';
// eslint-disable-next-line jira/import-whitelist
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { getMultivariateFeatureFlag } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import type { IssueTransitionOptInStatusGetResponse } from '../../types.tsx';

const FF_POSSIBLE_VALUES = ['OFF', 'BY_DEFAULT_USER_OPT_IN', 'BY_DEFAULT_USER_OPT_OUT'];

const getDefaultIssueTransitionLabsOptInState = (): IssueTransitionOptInStatusGetResponse => {
	const isModernITSRenderEnabled = fg('render-modern-issue-transition') || isFedRamp();

	if (!isModernITSRenderEnabled) {
		return {
			isEnabled: false,
			expiryTime: -1,
		};
	}

	const isModernIssueTransitionSRBCohort =
		fg('issue-transition-customisation-for-srb') || isFedRamp();
	if (isModernIssueTransitionSRBCohort) {
		return {
			isEnabled: true,
		};
	}

	const isExplicitlyUseTurnedOff =
		expValEquals(
			'its_modernisation_toggle_migration_check',
			'consistency_check_stage',
			'CHECK_RETURN_NEW',
		) || expValEquals('its_modernisation_toggle_migration_check', 'consistency_check_stage', 'NEW')
			? expValEquals(
					'jira_issue_transition_screen_modernisation_toggle',
					'value',
					'BY_DEFAULT_USER_OPT_OUT',
				)
			: getMultivariateFeatureFlag(
					'jira-issue-transition-screen-modernisation-toggle',
					'OFF',
					FF_POSSIBLE_VALUES,
				) === 'BY_DEFAULT_USER_OPT_OUT';

	return isExplicitlyUseTurnedOff
		? { isEnabled: false, expiryTime: -1 }
		: {
				isEnabled: true,
			};
};

export default getDefaultIssueTransitionLabsOptInState;

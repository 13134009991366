import {
	CHANGED_OPERATORS,
	WAS_IN_OPERATORS,
	WAS_OPERATORS,
	type OperatorValue,
} from '@atlaskit/jql-ast';

const UNSUPPORTED_OPERATORS: OperatorValue[] = [
	...CHANGED_OPERATORS,
	...WAS_OPERATORS,
	...WAS_IN_OPERATORS,
];

export const getUnsupportedOperators = () => UNSUPPORTED_OPERATORS;

import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	issues: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.issues-menu.issues',
		defaultMessage: 'Issues',
		description: 'Issues project menu header.',
	},
	issuesIssueTermRefresh: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.issues-menu.issues-issue-term-refresh',
		defaultMessage: 'Work items',
		description: 'Work items project menu header',
	},
});
export default messages;

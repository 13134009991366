import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { projectSettingsServicedeskAccessRedirectRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskAccessRedirectRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { LazyPeopleSettings, peopleAccessRedirect } from './ui/people/index.tsx';
import { SERVICE_DESK_PRODUCT } from './ui/product-routes/common/index.tsx';

export const projectSettingsServicedeskAccessRedirectRouteEntry: Route = createEntry(
	projectSettingsServicedeskAccessRedirectRoute,
	() => ({
		group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
		component: peopleAccessRedirect(`/jira/${SERVICE_DESK_PRODUCT}`),
		layout: serviceProjectLayout,
		navigation: {
			horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
		forPaint: [LazyAtlassianNavigation, LazyPeopleSettings],
	}),
);

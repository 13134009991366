import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	hideFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.hide-from-sidebar',
		defaultMessage: 'Hide from sidebar',
		description: 'Used as the text for the Hide from sidebar menu item',
	},

	a11YAlertHideDefaultFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-alert-hide-default-from-sidebar',
		defaultMessage: 'Menu Item have been hidden from the sidebar',
		description:
			'Used as the text for screen-reader to support a11y when the default - menu item gets hidden. This is used when there is no match found.',
	},

	// apps
	a11YHideAppsFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-hide-apps-from-sidebar',
		defaultMessage: 'Hide apps from sidebar',
		description:
			'Used as the text for screen-reader to support a11y for the - Hide from sidebar - menu item with added context of a menu id - Apps',
	},
	a11YAlertHideAppsFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-alert-hide-apps-from-sidebar',
		defaultMessage: 'Apps have been hidden from the sidebar',
		description:
			'Used as the text for screen-reader to support a11y when the apps - menu item gets hidden',
	},

	// assets
	a11YHideAssetsFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-hide-assets-from-sidebar',
		defaultMessage: 'Hide assets from sidebar',
		description:
			'Used as the text for screen-reader to support a11y for the - Hide from sidebar - menu item with added context of a menu id - Assets',
	},
	a11YAlertHideAssetsFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-alert-hide-assets-from-sidebar',
		defaultMessage: 'Assets have been hidden from the sidebar',
		description:
			'Used as the text for screen-reader to support a11y when the assets - menu item gets hidden',
	},
	// dashboards
	a11YHideDashboardsFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-hide-dashboards-from-sidebar',
		defaultMessage: 'Hide dashboards from sidebar',
		description:
			'Used as the text for screen-reader to support a11y for the - Hide from sidebar - menu item with added context of a menu id - Dashboards',
	},
	a11YAlertHideDashboardsFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-alert-hide-dashboards-from-sidebar',
		defaultMessage: 'Dashboards have been hidden from the sidebar',
		description:
			'Used as the text for screen-reader to support a11y when the dashboards - menu item gets hidden',
	},
	// filters
	a11YHideFiltersFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-hide-filters-from-sidebar',
		defaultMessage: 'Hide filters from sidebar',
		description:
			'Used as the text for screen-reader to support a11y for the - Hide from sidebar - menu item with added context of a menu id - Filters',
	},
	a11YAlertHideFiltersFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-alert-hide-filters-from-sidebar',
		defaultMessage: 'Filters have been hidden from the sidebar',
		description:
			'Used as the text for screen-reader to support a11y when the filters - menu item gets hidden',
	},
	// goals
	a11YHideGoalsFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-hide-goals-from-sidebar',
		defaultMessage: 'Hide goals from sidebar',
		description:
			'Used as the text for screen-reader to support a11y for the - Hide from sidebar - menu item with added context of a menu id - Goals',
	},
	a11YAlertHideGoalsFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-alert-hide-goals-from-sidebar',
		defaultMessage: 'Goals have been hidden from the sidebar',
		description:
			'Used as the text for screen-reader to support a11y when the goals - menu item gets hidden',
	},
	// operations
	a11YHideOperationsFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-hide-operations-from-sidebar',
		defaultMessage: 'Hide operations from sidebar',
		description:
			'Used as the text for screen-reader to support a11y for the - Hide from sidebar - menu item with added context of a menu id - Operations',
	},
	a11YAlertHideOperationsFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-alert-hide-operations-from-sidebar',
		defaultMessage: 'Operations have been hidden from the sidebar',
		description:
			'Used as the text for screen-reader to support a11y when the operations - menu item gets hidden',
	},
	// overviews
	a11YHideOverviewsFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-hide-overviews-from-sidebar',
		defaultMessage: 'Hide overviews from sidebar',
		description:
			'Used as the text for screen-reader to support a11y for the - Hide from sidebar - menu item with added context of a menu id - Overviews',
	},
	a11YAlertHideOverviewsFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-alert-hide-overviews-from-sidebar',
		defaultMessage: 'Overviews have been hidden from the sidebar',
		description:
			'Used as the text for screen-reader to support a11y when the overviews - menu item gets hidden',
	},
	// plans
	a11YHidePlansFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-hide-plans-from-sidebar',
		defaultMessage: 'Hide plans from sidebar',
		description:
			'Used as the text for screen-reader to support a11y for the - Hide from sidebar - menu item with added context of a menu id - Plans',
	},
	a11YAlertHidePlansFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-alert-hide-plans-from-sidebar',
		defaultMessage: 'Plans have been hidden from the sidebar',
		description:
			'Used as the text for screen-reader to support a11y when the plans - menu item gets hidden',
	},
	// projects
	a11YHideProjectsFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-hide-projects-from-sidebar',
		defaultMessage: 'Hide projects from sidebar',
		description:
			'Used as the text for screen-reader to support a11y for the - Hide from sidebar - menu item with added context of a menu id - Projects',
	},
	a11YAlertHideProjectsFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-alert-hide-projects-from-sidebar',
		defaultMessage: 'Projects have been hidden from the sidebar',
		description:
			'Used as the text for screen-reader to support a11y when the projects - menu item gets hidden',
	},
	// teams
	a11YHideTeamsFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-hide-teams-from-sidebar',
		defaultMessage: 'Hide teams from sidebar',
		description:
			'Used as the text for screen-reader to support a11y for the - Hide from sidebar - menu item with added context of a menu id - Teams',
	},
	a11YAlertHideTeamsFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-alert-hide-teams-from-sidebar',
		defaultMessage: 'Teams have been hidden from the sidebar',
		description:
			'Used as the text for screen-reader to support a11y when the teams - menu item gets hidden',
	},
	// roadmaps
	a11YHideRoadmapsFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-hide-roadmaps-from-sidebar',
		defaultMessage: 'Hide roadmaps from sidebar',
		description:
			'Used as the text for screen-reader to support a11y for the - Hide from sidebar - menu item with added context of a menu id - Roadmaps',
	},
	a11YAlertHideRoadmapsFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.a-11-y-alert-hide-roadmaps-from-sidebar',
		defaultMessage: 'Roadmaps have been hidden from the sidebar',
		description:
			'Used as the text for screen-reader to support a11y when the roadmaps - menu item gets hidden',
	},

	// flags related texts
	moveBackToSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.move-back-to-sidebar',
		defaultMessage: 'Move back to sidebar',
		description:
			'A text that is displayed in the action button of a flag when an item is successfully hidden',
	},
	defaultHiddenFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.default-hidden-from-sidebar',
		defaultMessage: 'Hidden from sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully hidden - default',
	},
	appsHiddenFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.apps-hidden-from-sidebar',
		defaultMessage: 'Apps hidden from sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully hidden',
	},
	assetsHiddenFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.assets-hidden-from-sidebar',
		defaultMessage: 'Assets hidden from sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully hidden',
	},
	dashboardsHiddenFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.dashboards-hidden-from-sidebar',
		defaultMessage: 'Dashboards hidden from sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully hidden',
	},
	filtersHiddenFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.filters-hidden-from-sidebar',
		defaultMessage: 'Filters hidden from sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully hidden',
	},
	goalsHiddenFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.goals-hidden-from-sidebar',
		defaultMessage: 'Goals hidden from sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully hidden',
	},
	operationsHiddenFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.operations-hidden-from-sidebar',
		defaultMessage: 'Operations hidden from sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully hidden',
	},
	overviewsHiddenFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.overviews-hidden-from-sidebar',
		defaultMessage: 'Overviews hidden from sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully hidden',
	},
	plansHiddenFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.plans-hidden-from-sidebar',
		defaultMessage: 'Plans hidden from sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully hidden',
	},
	projectsHiddenFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.projects-hidden-from-sidebar',
		defaultMessage: 'Projects hidden from sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully hidden',
	},
	teamsHiddenFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.teams-hidden-from-sidebar',
		defaultMessage: 'Teams hidden from sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully hidden',
	},
	roadmapsHiddenFromSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.roadmaps-hidden-from-sidebar',
		defaultMessage: 'Roadmaps hidden from sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully hidden',
	},
	defaultMovedBackToSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.default-moved-back-to-sidebar',
		defaultMessage: 'Moved back to sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully restored - default',
	},
	appsMovedBackToSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.apps-moved-back-to-sidebar',
		defaultMessage: 'Apps moved back to sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully restored',
	},
	assetsMovedBackToSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.assets-moved-back-to-sidebar',
		defaultMessage: 'Assets moved back to sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully restored',
	},
	dashboardsMovedBackToSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.dashboards-moved-back-to-sidebar',
		defaultMessage: 'Dashboards moved back to sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully restored',
	},
	filtersMovedBackToSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.filters-moved-back-to-sidebar',
		defaultMessage: 'Filters moved back to sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully restored',
	},
	goalsMovedBackToSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.goals-moved-back-to-sidebar',
		defaultMessage: 'Goals moved back to sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully restored',
	},
	operationsMovedBackToSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.operations-moved-back-to-sidebar',
		defaultMessage: 'Operations moved back to sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully restored',
	},
	overviewsMovedBackToSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.overviews-moved-back-to-sidebar',
		defaultMessage: 'Overviews moved back to sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully restored',
	},
	plansMovedBackToSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.plans-moved-back-to-sidebar',
		defaultMessage: 'Plans moved back to sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully restored',
	},
	projectsMovedBackToSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.projects-moved-back-to-sidebar',
		defaultMessage: 'Projects moved back to sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully restored',
	},
	teamsMovedBackToSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.teams-moved-back-to-sidebar',
		defaultMessage: 'Teams moved back to sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully restored',
	},
	roadmapsMovedBackToSidebar: {
		id: 'navigation-apps-sidebar-nav4-sidebars-common-core.hide-l1-menu-item-action.roadmaps-moved-back-to-sidebar',
		defaultMessage: 'Roadmaps moved back to sidebar',
		description:
			'A text that is displayed as a title on a flag when an item is successfully restored',
	},
});

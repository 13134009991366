import React, { useMemo, useCallback } from 'react';
import noop from 'lodash/noop';
import {
	type JiraProjectType,
	SOFTWARE_PROJECT,
	JIRA_PROJECT_TYPE_SOFTWARE_PROJECT,
	JIRA_PROJECT_TYPE_CORE_PROJECT,
	convertToProjectType,
	TEAM_MANAGED_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	isFreeEdition,
	isStandardEdition,
} from '@atlassian/jira-project-archive-trigger/src/ui/utils.tsx';
import { archiveProjectFeatureGateModalEntryPoint } from '@atlassian/jira-projects-directory-v3-archive-project-feature-gate-modal/entrypoint.tsx';
import { archiveProjectModalEntryPoint } from '@atlassian/jira-projects-directory-v3-archive-project-modal/entrypoint.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import type { ProjectActionData } from '../../types.tsx';
import { ArchiveTriggerButton } from './ArchiveTriggerButton.tsx';
import messages from './messages.tsx';

type ArchiveProjectProps = ProjectActionData & {
	isCorePremiumUserSeat?: boolean;
	jiraProjectType: JiraProjectType;
	onArchiveSuccess?: () => void;
	onClick?: () => void;
};

export function ArchiveProject({
	projectId,
	jiraProjectStyle,
	jiraProjectType,
	isAdmin = false,
	canAdministerProject = false,
	isCorePremiumUserSeat = false,
	setActiveEntryPoint,
	setProjectToRestore,
	onArchiveSuccess: archiveSuccessCallback,
	onClick,
}: ArchiveProjectProps) {
	const appEditions = useAppEditions();
	const projectType = convertToProjectType(jiraProjectType);
	const entryPointParams = useMemo(() => ({ projectId }), [projectId]);
	const { formatMessage } = useIntl();

	const isSoftwareAppStandardEdition = isStandardEdition(SOFTWARE_PROJECT, appEditions);
	const isSoftwareAppFreeEdition = isFreeEdition(SOFTWARE_PROJECT, appEditions);

	// Standard archiving
	const { entryPointReferenceSubject: archiveRefSubject, entryPointActions: archiveActions } =
		useEntryPoint(archiveProjectModalEntryPoint, entryPointParams, undefined, {
			timeToIntent: 50,
		});
	const archiveEntryPointRuntimeProps = useMemo(
		() => ({
			onClose: () => noop,
			onArchiveProjectSuccess: () => {
				archiveActions.unload();
				setActiveEntryPoint(null);
				archiveSuccessCallback?.();
			},
			onProjectRestore: setProjectToRestore,
		}),
		[archiveActions, setProjectToRestore, setActiveEntryPoint, archiveSuccessCallback],
	);
	const archiveTriggerRef = useEntryPointButtonTrigger(archiveActions);

	// Feature gated archiving
	const {
		entryPointReferenceSubject: archiveProjectFeatureGateRefSubject,
		entryPointActions: archiveProjectFeatureGateActions,
	} = useEntryPoint(archiveProjectFeatureGateModalEntryPoint, {});
	const archiveFeatureGateEntryPointRuntimeProps = useMemo(
		() => ({
			onClose: () => {
				archiveProjectFeatureGateActions.unload();
			},
		}),
		[archiveProjectFeatureGateActions],
	);
	const archiveFeatureGateTriggerRef = useEntryPointButtonTrigger(archiveProjectFeatureGateActions);

	const archiveTriggerRefToUse =
		(jiraProjectType === JIRA_PROJECT_TYPE_SOFTWARE_PROJECT ||
			jiraProjectType === JIRA_PROJECT_TYPE_CORE_PROJECT) &&
		(isSoftwareAppStandardEdition || isSoftwareAppFreeEdition)
			? archiveFeatureGateTriggerRef
			: archiveTriggerRef;

	const handleClick = useCallback(() => {
		if (
			(isSoftwareAppStandardEdition || isSoftwareAppFreeEdition) &&
			(jiraProjectType === JIRA_PROJECT_TYPE_SOFTWARE_PROJECT ||
				jiraProjectType === JIRA_PROJECT_TYPE_CORE_PROJECT)
		) {
			setActiveEntryPoint({
				entryPointReferenceSubject: archiveProjectFeatureGateRefSubject,
				runtimeProps: archiveFeatureGateEntryPointRuntimeProps,
				entryPointActions: archiveProjectFeatureGateActions,
				id: 'project-archive-feature-gate-modal',
			});
		} else {
			setActiveEntryPoint({
				entryPointReferenceSubject: archiveRefSubject,
				runtimeProps: archiveEntryPointRuntimeProps,
				entryPointActions: archiveActions,
				id: 'project-archive-modal',
			});
		}
		onClick?.();
	}, [
		isSoftwareAppStandardEdition,
		isSoftwareAppFreeEdition,
		jiraProjectType,
		setActiveEntryPoint,
		archiveProjectFeatureGateRefSubject,
		archiveFeatureGateEntryPointRuntimeProps,
		archiveProjectFeatureGateActions,
		archiveRefSubject,
		archiveEntryPointRuntimeProps,
		archiveActions,
		onClick,
	]);

	return (
		<ArchiveTriggerButton
			ref={archiveTriggerRefToUse}
			projectType={projectType}
			isAdmin={isAdmin}
			canAdministerProject={canAdministerProject}
			isSimplified={jiraProjectStyle === TEAM_MANAGED_PROJECT}
			onClick={handleClick}
			isCorePremiumUserSeat={isCorePremiumUserSeat}
			customLabelText={formatMessage(messages.archiveProject)}
		/>
	);
}

import type { Props, Boards, Users, Groups } from '../model/index.tsx';

// SET APP PROPS

export const SET_APP_PROPS = 'state.SET_APP_PROPS' as const;

export type SetAppPropsAction = {
	type: typeof SET_APP_PROPS;
	payload: Props;
};

export const setAppProps = (payload: Props): SetAppPropsAction => ({
	type: SET_APP_PROPS,
	payload,
});

// GET ASSOCIATED ITEMS

export const GET_ASSOCIATED_ITEMS = 'state.GET_ASSOCIATED_ITEMS' as const;

export type GetAssociatedItemsAction = {
	type: typeof GET_ASSOCIATED_ITEMS;
};

export const getAssociatedItems = (): GetAssociatedItemsAction => ({
	type: GET_ASSOCIATED_ITEMS,
});

// ASSOCIATED ITEMS RETRIEVED

export const ASSOCIATED_ITEMS_RETRIEVED = 'state.ASSOCIATED_ITEMS_RETRIEVED' as const;

type AssociatedItemsRetrievedPayload = {
	boards: Boards;
	users: Users;
	groups: Groups;
};

export type AssociatedItemsRetrievedAction = {
	type: typeof ASSOCIATED_ITEMS_RETRIEVED;
	payload: AssociatedItemsRetrievedPayload;
};

export const associatedItemsRetrieved = (
	payload: AssociatedItemsRetrievedPayload,
): AssociatedItemsRetrievedAction => ({
	type: ASSOCIATED_ITEMS_RETRIEVED,
	payload,
});

// GET ASSOCIATED ITEMS FAILED

export const GET_ASSOCIATED_ITEMS_FAILED = 'state.GET_ASSOCIATED_ITEMS_FAILED' as const;

export type GetAssociatedItemsFailedAction = {
	type: typeof GET_ASSOCIATED_ITEMS_FAILED;
};

export const getAssociatedItemsFailed = (): GetAssociatedItemsFailedAction => ({
	type: GET_ASSOCIATED_ITEMS_FAILED,
});

// DELETE FILTER

export const DELETE_FILTER = 'state.DELETE_FILTER' as const;

export type DeleteFilterAction = {
	type: typeof DELETE_FILTER;
	onComplete?: () => void;
};

export const deleteFilter = (onComplete?: () => void): DeleteFilterAction => ({
	type: DELETE_FILTER,
	onComplete,
});

// DELETE FILTER FAILED

export const DELETE_FILTER_FAILED = 'state.DELETE_FILTER_FAILED' as const;

export type DeleteFilterFailedAction = {
	type: typeof DELETE_FILTER_FAILED;
};

export const deleteFilterFailed = (): DeleteFilterFailedAction => ({
	type: DELETE_FILTER_FAILED,
});

// Filter DELETED

export const FILTER_DELETED = 'state.FILTER_DELETED' as const;

export type FilterDeletedAction = {
	type: typeof FILTER_DELETED;
};

export const filterDeleted = (): FilterDeletedAction => ({
	type: FILTER_DELETED,
});

export type Action =
	| SetAppPropsAction
	| GetAssociatedItemsAction
	| AssociatedItemsRetrievedAction
	| GetAssociatedItemsFailedAction
	| DeleteFilterAction
	| FilterDeletedAction
	| DeleteFilterFailedAction;

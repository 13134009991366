import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import {
	AsyncSidebarFiltersGlobal,
	AsyncForPaintSidebarFiltersGlobal,
} from '@atlassian/jira-navigation-apps-sidebar-filters/src/async.tsx';

export const getCommonGlobalNavigation = () => ({
	menuId: MENU_ID.FILTERS,
	sidebar: componentWithFG(
		'make_nav3_sidebar_lazyforpaint',
		AsyncForPaintSidebarFiltersGlobal,
		AsyncSidebarFiltersGlobal,
	),
});

import { forgeProjectSettingsModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticProjectSettingsSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-settings-connect-addons/index.tsx';
import { navigationSidebarAutomationLegacyEnabledResource } from '@atlassian/jira-router-resources-navigation-sidebar-automation/src/index.tsx';
import { newIssueViewTransitionLockInStatusResource } from '@atlassian/jira-router-resources-new-issue-view-transition-lock-in-status/src/services/index.tsx';
import { projectContextResourceWithCache } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';

export const getNavigationSidebarProjectSettingsResource = () => [
	newIssueViewTransitionLockInStatusResource,
	navigationSidebarAutomationLegacyEnabledResource,
	projectContextResourceWithCache,
	forgeProjectSettingsModuleResource,
	staticProjectSettingsSidebarConnectAddonsNavigationResource,
];

/**
 * @generated SignedSource<<c834bed95690edeb5b5d9f5778b02b05>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type searchMode_useSearchMode_JQLBuilder$data = {
  readonly __id: string;
  readonly " $fragmentType": "searchMode_useSearchMode_JQLBuilder";
};
export type searchMode_useSearchMode_JQLBuilder$key = {
  readonly " $data"?: searchMode_useSearchMode_JQLBuilder$data;
  readonly " $fragmentSpreads": FragmentRefs<"searchMode_useSearchMode_JQLBuilder">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "searchMode_useSearchMode_JQLBuilder",
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    }
  ],
  "type": "JiraUserPreferences"
};

(node as any).hash = "b610d5310493b4f35efc509255a102c4";

export default node;

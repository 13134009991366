import React, { forwardRef, useEffect, useState, type Ref } from 'react';
import type { MenuId } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/types.tsx';
import { useSidebarNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-context/index.tsx';
import { useSidebarNav4Controller } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-controller-context/index.tsx';
import { ExpandableMenuItem, type ExpandableMenuItemProps } from '@atlassian/navigation-system';

type Props = Omit<ExpandableMenuItemProps, 'isDefaultExpanded' | 'isChildSelected'> & {
	menuId: MenuId;
	onSystemToggle?: (isExpanded: boolean) => void;
};

/**
 * Nav4ExpandableMenuItem provides `menuId` instead of `isDefaultExpanded`
 * `menuId` is used to set the expanded-state of the underlying ExpandableMenuItem, using the useSidebarNav4() hook.
 *
 * Ideally, we want to pass the menuId to the trigger element.
 */
export const Nav4ExpandableMenuItem = forwardRef<HTMLDivElement, Props>(_Nav4ExpandableMenuItem);

function _Nav4ExpandableMenuItem(props: Props, ref: Ref<HTMLDivElement>) {
	const { isSelectedPath, isInitialSelectedPath } = useSidebarNav4();
	const { isControlledNav, isControlledPath } = useSidebarNav4Controller();
	const { children, menuId, onExpansionToggle, isExpanded = false, onSystemToggle } = props;
	// controlled state, `isActuallyExpanded` decides whether the component is expanded or not (based on logic in this component and props.isExpanded)
	const [isActuallyExpanded, setActuallyExpanded] = useState<boolean>(
		isInitialSelectedPath(menuId) || isExpanded,
	);

	// By default, the expandable component is closed. When we want it to be initially open, we also need to trigger onExpansionToggle().
	useEffect(() => {
		if (isActuallyExpanded === true && isExpanded === false) {
			onSystemToggle?.(isActuallyExpanded);
		}
		// We want to run this only once, on init.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// If isSelectedPath changes (external change), update our internal state.
	useEffect(() => {
		// Fyi, the following logic causes the component to _collapse_ when the selectedPath changes (preserved here for posterity):
		//   const localIsExpanded = isSelectedPath(menuId);

		// ...but this logic _keeps the component expanded_ (if it is) when the selectedPath changes. We can only auto-expand component, not auto-collapse it though (which is fine, for current UX):
		const localIsExpanded = isSelectedPath(menuId) || isActuallyExpanded;

		// Trigger the callbacks ONLY when the previous state doesn't match the new state.
		if (localIsExpanded !== isActuallyExpanded) {
			setActuallyExpanded(localIsExpanded);
			onSystemToggle?.(localIsExpanded);
		}

		// We only want to run this when isSelectedPath() changes, not when isActuallyExpanded changes.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSelectedPath]);

	useEffect(() => {
		if (!isControlledNav) {
			return;
		}
		const localIsExpanded = isControlledPath(menuId);

		if (localIsExpanded !== isActuallyExpanded) {
			setActuallyExpanded(localIsExpanded);
			onSystemToggle?.(localIsExpanded);
		}
		// We only want to run this when isControlledPath() changes, not when isActuallyExpanded changes.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isControlledPath]);

	return (
		<ExpandableMenuItem
			ref={ref}
			isExpanded={isActuallyExpanded}
			onExpansionToggle={(hasExpanded: boolean) => {
				// Since we are controlling `isExpanded` state in this component (via `isActuallyExpanded`), we need to change it on onExpansionToggle user-event too.
				setActuallyExpanded(hasExpanded);
				onExpansionToggle?.(hasExpanded);
			}}
		>
			{children}
		</ExpandableMenuItem>
	);
}

import React, { useCallback } from 'react';
import FilterIcon from '@atlaskit/icon/core/filter';
import { handleGinSPAClick } from '@atlassian/jira-navigation-apps-common/src/utils/gin-spa/index.tsx';
import { ENTITY_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';

type SimpleFilterMenuItemProps = {
	actionsOnHover?: React.ReactNode;
	filterId: string;
	name: string;
	onClick: () => void;
};

export function SimpleFilterMenuItem({
	actionsOnHover,
	filterId,
	name,
	onClick,
}: SimpleFilterMenuItemProps) {
	const handlOnClick = useCallback(
		(
			e:
				| React.MouseEvent<HTMLAnchorElement, MouseEvent>
				| React.KeyboardEvent<HTMLAnchorElement>
				| React.MouseEvent<EventTarget, MouseEvent>
				| React.KeyboardEvent<EventTarget>,
		) => {
			onClick?.();
			handleGinSPAClick(e);
		},
		[onClick],
	);

	return (
		<Nav4MenuLinkItem
			actionsOnHover={actionsOnHover}
			elemBefore={<FilterIcon label="" color="currentColor" />}
			href={`/issues/?filter=${filterId}`}
			menuId={ENTITY_ID.FILTER(filterId)}
			onClick={handlOnClick}
		>
			{name}
		</Nav4MenuLinkItem>
	);
}

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	boardCreationTitle: {
		id: 'software-board-create.common.board-creation-title',
		defaultMessage: 'Create a board',
		description:
			'The title for both the board creation flyout menu that allows a user to pick between a creating a scrum or kanban board as well as the modal that opens after the user has made a selection',
	},
	scrumBoardCreationHeader: {
		id: 'software-board-create.common.scrum-board-creation-header',
		defaultMessage: 'Scrum',
		description:
			'The header for the scrum board creation option in the board creation flyout menu.',
	},
	scrumBoardCreationDescription: {
		id: 'software-board-create.common.scrum-board-creation-description',
		defaultMessage: 'Sprint towards your team objectives with a board, backlog, and reports.',
		description:
			'The description for both the scrum board creation option in the board creation flyout menu and the modal that opens after the user has made a selection. In the modal, this appears under the boardCreationTitle',
	},

	kanbanBoardCreationHeader: {
		id: 'software-board-create.common.kanban-board-creation-header',
		defaultMessage: 'Kanban',
		description:
			'The header for the kanban board creation option in the board creation flyout menu.',
	},
	kanbanBoardCreationDescription: {
		id: 'software-board-create.common.kanban-board-creation-description',
		defaultMessage: 'Manage a continuous delivery of work with a kanban board and reports.',
		description:
			'The description for both the kanban board creation option in the board creation flyout menu and the modal that opens after the user has made a selection. In the modal, this appears under the boardCreationTitle',
	},
	loadSavedFiltersErrorTitle: {
		id: 'software-board-create.common.load-saved-filters-error-title',
		defaultMessage: 'We can’t load saved filters right now',
		description:
			'Error message instructing user that the saved filters list did not load successfully',
	},
	loadSavedFiltersErrorDescription: {
		id: 'software-board-create.common.load-saved-filters-error-description',
		defaultMessage: 'Refresh the page and try again.',
		description:
			'Error description instructing user to refresh the page to try again after an error occurred.',
	},
	requiredFieldsHeader: {
		id: 'software-board-create.common.required-fields-header',
		defaultMessage: 'Required fields are marked with an asterisk *',
		description: 'Description within header to explain required fields are marked with an asterisk',
	},
});

import React from 'react';
import Blanket from '@atlaskit/blanket';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { ChangeOwnerFilterModule as ChangeFilterModalType, Props } from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const NoLazyAsyncChangeOwnerFilterModal = lazy<typeof ChangeFilterModalType>(
	() => import(/* webpackChunkName: "async-change-owner-filter-modal" */ './index'),
);

const AsyncChangeOwnerFilterModal = (props: Props) => (
	<JSErrorBoundary
		id="configure-owner-modal"
		packageName="filter"
		fallback="flag"
		teamName="endeavour"
	>
		<Placeholder name="change-owner-filter-modal" fallback={<Blanket />}>
			<NoLazyAsyncChangeOwnerFilterModal {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

export default AsyncChangeOwnerFilterModal;

import React from 'react';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { type Layout, createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyArchives = lazyForPaint(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-issues-archive", jiraSpaEntry: "async-issues-archive" */ '@atlassian/jira-spa-apps-archives'
		).then(({ JiraIssuesArchive }) => JiraIssuesArchive),
	),
);

export const Archives = () => (
	<LazyPage Page={LazyArchives} pageId="spa-apps/archives" shouldShowSpinner />
);

export const getCommonProjectLayout = (): Layout => createLayout(genericProjectLayout);

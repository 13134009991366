/**
 * @generated SignedSource<<ac7c3102407dc4292a3f94d1e99fca02>>
 * @relayHash 0a37fae3aa0a9ad0c6882c7864457879
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 897373a9ab169540f10b217dda3ac0ada024bc716be58e55e2f4ab636b2bc48c

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessShortcutsQuery } from './src_jiraBusinessShortcutsQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessShortcutsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "897373a9ab169540f10b217dda3ac0ada024bc716be58e55e2f4ab636b2bc48c",
    "metadata": {},
    "name": "src_jiraBusinessShortcutsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

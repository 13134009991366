import type { FieldKey } from './types.tsx';

/**
 * FieldKey constants that can be used to find fields which often require special treatment.
 */
export const KEY_FIELDKEY: FieldKey = 'key';
export const ISSUEID_FIELDKEY: FieldKey = 'issueid';
export const SUMMARY_FIELDKEY: FieldKey = 'summary';
export const STATUS_FIELDKEY: FieldKey = 'status';
export const ISSUETYPE_FIELDKEY: FieldKey = 'issuetype';
export const LABELS_FIELDKEY: FieldKey = 'labels';
export const VOTES_FIELDKEY: FieldKey = 'votes';
export const REPORTER_FIELDKEY: FieldKey = 'reporter';
export const DESCRIPTION_FIELDKEY: FieldKey = 'description';
export const ASSIGNEE_FIELDKEY: FieldKey = 'assignee';
export const CREATOR_FIELDKEY: FieldKey = 'creator';
export const CREATED_FIELDKEY: FieldKey = 'created';
export const UPDATED_FIELDKEY: FieldKey = 'updated';
export const PROJECT_FIELDKEY: FieldKey = 'project';

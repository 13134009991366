// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchWithRetry = async <TResponse = any,>(
	fetchCall: () => Promise<TResponse>,
	retryAttempts: number,
): Promise<TResponse> => {
	try {
		return await fetchCall();
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		if (retryAttempts > 0) {
			return fetchWithRetry(fetchCall, retryAttempts - 1);
		}
		throw error;
	}
};

import { isFedRamp } from '@atlassian/atl-context';
import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServiceDesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { browseIntentsRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-routes/src/browseIntentsRoute.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import { browseIntentsLayout } from '@atlassian/jira-servicedesk-virtual-agent-browse-intents-layout/src/index.tsx';
import { VirtualAgentBrowseIntentsSkeleton } from '@atlassian/jira-skeletons/src/ui/virtual-agent/browse-intents/VirtualAgentStatisticsSkeleton.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { browseIntentsEntryPoint } from '@atlassian/jira-spa-apps-virtual-agent-browse-intents/entrypoint.tsx';
import { FedRAMPFallbackRedirectToProjectSettings } from './common/index.tsx';

const browseIntentsPageEntryPoint = createPageEntryPoint({
	main: browseIntentsEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const browseIntentsRouteEntry: Route = createEntry(browseIntentsRoute, () => ({
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServiceDesk,
		sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
	},
	entryPoint() {
		if (isFedRamp()) {
			return undefined;
		}
		return browseIntentsPageEntryPoint;
	},
	// this is only used when entryPoint() returns undefined, which is only the case in FedRAMP
	component: FedRAMPFallbackRedirectToProjectSettings,
	layout: browseIntentsLayout,
	skeleton: VirtualAgentBrowseIntentsSkeleton,
	resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
	meta: {
		reporting: {
			id: APP_NAMES.VIRTUAL_AGENT_BROWSE_INTENTS,
			packageName: toPackageName(APP_NAMES.VIRTUAL_AGENT_BROWSE_INTENTS),
			teamName: 'jsd-flux-capacitors',
		},
	},
}));

import { useEffect, useState } from 'react';
import { useRelayEnvironment, type Environment } from 'react-relay';
import { JiraProjectAri } from '@atlassian/ari/jira/project';
import { memoizedFetchSitePersonalization } from '@atlassian/jira-personalization-service/src/services/site/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { TRAIT_NAME } from '../common/constants.tsx';
import { useIsEligibleForTrialSprintBoard } from '../common/eligibility.tsx';
import { getTrialSprintBoardProjectData, createTrialSprintBoard } from '../services/index.tsx';

export function useCreateTrialSprintBoard() {
	const isEligibleForExperiment = useIsEligibleForTrialSprintBoard();
	return () => {
		if (isEligibleForExperiment) {
			createTrialSprintBoard();
		}
	};
}

export const getTrialSprintBoardProjectId = async (cloudId: string) => {
	const siteTraits = await memoizedFetchSitePersonalization({ cloudId });
	const trialSprintBoardTrait = siteTraits.find((traits) => traits.name === TRAIT_NAME);
	if (typeof trialSprintBoardTrait?.value !== 'string') {
		return undefined;
	}
	return trialSprintBoardTrait.value;
};

export const isTrialSprintBoardProject = async (cloudId: string, projectId: string) => {
	const trialSprintBoardId = await getTrialSprintBoardProjectId(cloudId);
	return trialSprintBoardId === projectId;
};

export const isTrialSprintBoardProjectActive = async (
	cloudId: string,
	relayEnvironment: Environment,
) => {
	const trialSprintBoardId = await getTrialSprintBoardProjectId(cloudId);

	if (trialSprintBoardId === undefined) {
		return false;
	}

	const projectAri = JiraProjectAri.create({
		siteId: cloudId,
		projectId: trialSprintBoardId,
	}).toString();

	const { isProjectActive } = await getTrialSprintBoardProjectData(projectAri, relayEnvironment);
	return isProjectActive;
};

export const getTrialSprintBoardFirstIssuePath = async (
	cloudId: string,
	relayEnvironment: Environment,
) => {
	const trialSprintBoardId = await getTrialSprintBoardProjectId(cloudId);

	if (trialSprintBoardId === undefined) {
		return undefined;
	}

	const projectAri = JiraProjectAri.create({
		siteId: cloudId,
		projectId: trialSprintBoardId,
	}).toString();

	const { projectKey, boardId } = await getTrialSprintBoardProjectData(
		projectAri,
		relayEnvironment,
	);

	if (projectKey === null || boardId === null) {
		return undefined;
	}

	const firstIssueKey = `${projectKey}-1`;
	const projectBoardWithIssueSelectedPath = `/jira/software/projects/${projectKey}/boards/${boardId}?selectedIssue=${firstIssueKey}`;

	return projectBoardWithIssueSelectedPath;
};

export const useIsNotTrialSprintBoardProject = (currentProjectId: string) => {
	const cloudId = useCloudId();

	const [isNotTsbProject, setIsNotTsbProject] = useState<boolean>(false);

	useEffect(() => {
		isTrialSprintBoardProject(cloudId, currentProjectId).then((isTsbProject) =>
			setIsNotTsbProject(!isTsbProject),
		);
	}, [cloudId, currentProjectId]);

	return isNotTsbProject;
};

export const useTrialSprintBoardCtaLink = () => {
	const cloudId = useCloudId();
	const relayEnvironment = useRelayEnvironment();

	const [ctaLink, setCtaLink] = useState<string>('');

	useEffect(() => {
		const getCtaLink = async () => {
			const isProjectAvailable = await isTrialSprintBoardProjectActive(cloudId, relayEnvironment);

			if (isProjectAvailable) {
				const tsbIssuePath = await getTrialSprintBoardFirstIssuePath(cloudId, relayEnvironment);

				if (tsbIssuePath !== undefined) {
					setCtaLink(tsbIssuePath);
				}
			}
		};
		getCtaLink();
	}, [cloudId, relayEnvironment]);

	return ctaLink;
};

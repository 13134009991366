import { fg } from '@atlassian/jira-feature-gating';
import parameters from '@atlassian/jira-relay/src/__generated__/recentMenuQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = { cloudId: string; count: number };

export const sidebarRecentMenuEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-nav4-sidebar-recent-menu" */ './index'),
	),
	getPreloadProps: ({ cloudId, count }: EntryPointParams) => ({
		queries: {
			recentMenuData: {
				parameters,
				options: {
					fetchPolicy: 'store-and-network' as const,
				},
				variables: {
					cloudId,
					count,
					keyword: '',
					isEapDrop2Enabled: fg('jira_nav4_eap_drop_2'),
				},
			},
		},
	}),
});

export type validArrayValue = any[] | null | undefined;

export default function shallowEqualArrays(
  arrA: validArrayValue,
  arrB: validArrayValue
): boolean {
  if (arrA === arrB) {
    return true;
  }

  if (!arrA || !arrB) {
    return false;
  }

  const len = arrA.length;

  if (arrB.length !== len) {
    return false;
  }

  for (let i = 0; i < len; i++) {
    if (arrA[i] !== arrB[i]) {
      return false;
    }
  }

  return true;
}

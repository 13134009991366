import React from 'react';
import { useOverviewsAnalyticsL1 } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/controllers/use-overviews-analytics-l1/index.tsx';
import { ENTITY_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';

type OverviewsMenuItemProps = {
	name: string;
	id: string;
};

export function OverviewsMenuItem({ id, name }: OverviewsMenuItemProps) {
	const { icon } = useConfigurableMessageAndIconMap(L1_MENU_ID.OVERVIEWS);
	const { sendOverviewMenuItemAnalyticsEvent } = useOverviewsAnalyticsL1();

	const overviewUrl = `/jira/core/overviews/${id}/summary`;

	return (
		<Nav4MenuLinkItem
			onClick={() => {
				sendOverviewMenuItemAnalyticsEvent({
					overviewId: id,
				});
			}}
			href={overviewUrl}
			menuId={ENTITY_ID.OVERVIEW(String(id))}
			elemBefore={icon}
		>
			{name}
		</Nav4MenuLinkItem>
	);
}

import React, { type FC } from 'react';
import type { ConfigurableMenuId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import {
	MenuButtonItem,
	type MenuButtonItemProps,
	MenuLinkItem,
} from '@atlassian/navigation-system';
import { USER_NAVIGATION_ITEM_LINKS } from '../../../../common/constants/index.tsx';

type MenuItemProps = { menuId: ConfigurableMenuId } & Omit<
	MenuButtonItemProps,
	'children' | 'iconBefore'
>;

export const MenuItem: FC<MenuItemProps> = ({ menuId, ...props }) => {
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(menuId);

	// This is part of an intermediate state workaround where links are accounted for;
	// we don't expect links to be in the More menu at the end state,
	// at which point this and the MenuLinkItem below can be removed.
	const url = USER_NAVIGATION_ITEM_LINKS[menuId];

	return (
		<>
			{url ? (
				<MenuLinkItem {...props} elemBefore={icon} href={url}>
					{formattedMessage}
				</MenuLinkItem>
			) : (
				<MenuButtonItem {...props} elemBefore={icon}>
					{formattedMessage}
				</MenuButtonItem>
			)}
		</>
	);
};

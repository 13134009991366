import { useRegisterCommandsWithRegistry } from '../../controllers/use-register-commands/index.tsx';
import type { RegisterCommandsProps } from './types.tsx';

/**
 * Hooks cannot be called conditionally
 * so this component can be used to avoid calling
 * useRegisterCommands when unnecessary
 */
export function RegisterCommandsWithRegistry<T extends string>({
	registrationId,
	commands,
	deps,
}: RegisterCommandsProps<T>) {
	useRegisterCommandsWithRegistry(registrationId, commands, deps);

	return null;
}

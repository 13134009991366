import { useCallback } from 'react';

import { commitLocalUpdate, useRelayEnvironment } from 'react-relay';

import { OpsgenieSavedSearchAri } from '@atlassian/ari/opsgenie/saved-search';
import { assert, AssertionError } from '@atlassian/eoc-common';
import { getMeta } from '@atlassian/eoc-fetch';

import { type SavedSearch } from '../../common/types';
import { getCloudIdForDevEnvironment } from '../../common/utils';

import { type SavedSearchKey, type SavedSearchRelayUtilsReturn } from './types';

export const useSavedSearchRelayUtils = (): SavedSearchRelayUtilsReturn => {
	const relayEnvironment = useRelayEnvironment();
	const cloudId = getMeta('ajs-cloud-id') ?? getCloudIdForDevEnvironment();

	assert(!!cloudId, new AssertionError('cloudId', 'useSavedSarchRelayUtils: cloudId is undefined'));

	const removeSavedSearch = useCallback(
		(savedSearchId: string, key: SavedSearchKey) => {
			commitLocalUpdate(relayEnvironment, (store) => {
				const root = store.getRoot();
				const opsgenieRecord = root.getLinkedRecord('opsgenie');

				if (opsgenieRecord) {
					const savedSearches = opsgenieRecord.getLinkedRecord('savedSearches', {
						cloudId,
					});

					if (savedSearches) {
						const removeItem = savedSearches.getLinkedRecords(key);

						if (removeItem) {
							const filteredItems = removeItem.filter(
								(record) => record.getValue('id') !== savedSearchId,
							);

							savedSearches.setLinkedRecords(filteredItems, key);
						}
					}
				}
			});
		},
		[cloudId, relayEnvironment],
	);

	const addSavedSearch = useCallback(
		(savedSearch: SavedSearch, key: string) => {
			commitLocalUpdate(relayEnvironment, (store) => {
				const newSavedSearchId = OpsgenieSavedSearchAri.create({
					workspaceId: cloudId as string,
					savedSearchId: savedSearch.id,
				}).toString();
				const newSavedSearch = store.create(newSavedSearchId, 'OpsgenieSavedSearch');

				newSavedSearch.setValue(newSavedSearchId, 'id');
				newSavedSearch.setValue(savedSearch.name, 'name');
				newSavedSearch.setValue(savedSearch.query, 'alertSearchQuery');

				const root = store.getRoot();
				const opsgenieRecord = root.getLinkedRecord('opsgenie');

				if (opsgenieRecord) {
					const savedSearches = opsgenieRecord.getLinkedRecord('savedSearches', {
						cloudId,
					});

					if (savedSearches) {
						const savedSearchesList = savedSearches.getLinkedRecords(key) || [];
						const updatedSavedSearches = [...savedSearchesList, newSavedSearch];

						savedSearches.setLinkedRecords(updatedSavedSearches, key);
					}
				}
			});
		},
		[cloudId, relayEnvironment],
	);

	const addSavedSearchToCreatedByMe = useCallback(
		(savedSearch: SavedSearch) => {
			addSavedSearch(savedSearch, 'createdByMe');
		},
		[addSavedSearch],
	);

	const removeSavedSearchFromCreatedByMe = useCallback(
		(savedSearchId: string) => {
			removeSavedSearch(savedSearchId, 'createdByMe');
		},
		[removeSavedSearch],
	);

	return {
		addSavedSearchToCreatedByMe,
		removeSavedSearchFromCreatedByMe,
	};
};

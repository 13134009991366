import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	projects: {
		id: 'navigation-apps-sidebar-nav4-starred.common.projects',
		defaultMessage: 'Projects',
		description: 'Message for projects nav menu item in the sidebar',
	},
	filters: {
		id: 'navigation-apps-sidebar-nav4-starred.common.filters',
		defaultMessage: 'Filters',
		description: 'Message for filters nav menu item in the sidebar',
	},
	dashboards: {
		id: 'navigation-apps-sidebar-nav4-starred.common.dashboards',
		defaultMessage: 'Dashboards',
		description: 'Message for dashboards nav menu item in the sidebar',
	},
	plans: {
		id: 'navigation-apps-sidebar-nav4-starred.common.plans',
		defaultMessage: 'Plans',
		description: 'Message for plans nav menu item in the sidebar',
	},
	boards: {
		id: 'navigation-apps-sidebar-nav4-starred.common.boards',
		defaultMessage: 'Boards',
		description: 'Message for boards nav menu item in the sidebar',
	},
	queues: {
		id: 'navigation-apps-sidebar-nav4-starred.common.queues',
		defaultMessage: 'Queues',
		description: 'Message for queues nav menu item in the sidebar',
	},
	all: {
		id: 'navigation-apps-sidebar-nav4-starred.common.all',
		defaultMessage: 'All',
		description: 'Message for all nav menu item in the sidebar',
	},
});

import React, { useCallback, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Flex } from '@atlaskit/primitives';
import {
	STAR,
	UNSTAR,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/star.tsx';
import { FavoriteToggleButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/favorite-toggle-button/index.tsx';
import { MoreNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/main.tsx';
import type { DashboardPopupMenu$key } from '@atlassian/jira-relay/src/__generated__/DashboardPopupMenu.graphql';
import { MenuList } from '@atlassian/navigation-system';
import { MEATBALL_DROPDOWN } from '../../constants.tsx';
import { AnalyticContext } from '../../controllers/analytic-context/index.tsx';

type Props = {
	dashboardData: DashboardPopupMenu$key;
};

export const DashboardPopupMenu = ({ dashboardData }: Props) => {
	const data = useFragment<DashboardPopupMenu$key>(
		graphql`
			fragment DashboardPopupMenu on JiraDashboard {
				id @required(action: THROW)
				dashboardId @required(action: THROW)
				favouriteValue {
					isFavourite
				}
			}
		`,
		dashboardData,
	);

	const { fireUIAnalyticForFixButton, fireUIAnalyticForFavouriteToggle } =
		useContext(AnalyticContext);

	const MenuItems = useCallback(() => {
		const { id, dashboardId } = data;
		const isFavourite = Boolean(data?.favouriteValue?.isFavourite);

		return (
			<MenuList>
				<FavoriteToggleButton
					entityId={id}
					defaultIsStarred={isFavourite}
					entityTypeName="JiraDashboard"
					onClick={() => {
						fireUIAnalyticForFavouriteToggle({
							starType: isFavourite ? UNSTAR : STAR,
							parentItemId: String(dashboardId),
						});
					}}
				/>
			</MenuList>
		);
	}, [data, fireUIAnalyticForFavouriteToggle]);

	return (
		<Flex gap="space.050" alignItems="center">
			<MoreNavMenuButton
				onOpen={() => fireUIAnalyticForFixButton({ itemId: MEATBALL_DROPDOWN })}
				MenuItems={MenuItems}
			/>
		</Flex>
	);
};

import React from 'react';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/ScreenRedirect.tsx';
import { usePathParam } from '@atlassian/react-resource-router';

const OverviewRedirect = () => {
	const [overviewId] = usePathParam('overviewId');

	return <ScreenRedirect to={`/jira/core/overviews/${overviewId}/summary`} />;
};

export default OverviewRedirect;

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_ISSUE_NAVIGATOR } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen, {
	ForPaintSoftwareProjectSidebar as AsyncForPaintSidebarNextGen,
} from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { softwareIssueNavigatorRoute } from '@atlassian/jira-router-routes-issue-navigator-routes/src/softwareIssueNavigatorRoute.tsx';
import { ProjectIssueNavigatorSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/project-app.tsx';
import { AsyncHorizontalNavJiraSoftware as AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { softwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout/src/index.tsx';
import { getCommonProjectResources } from './common/getCommonProjectResources.tsx';
import { issueNavigatorRouteEntryCommon } from './common/issueNavigatorRouteEntryCommon.tsx';
import { LazyProjectIssueNavigator, ProjectIssueNavigator } from './ui/index.tsx';

/**
 * Software Project Issue Navigator Route Entry
 * Handles Software projects.
 */
export const issueNavigatorProjectSoftwareRouteEntry = createEntry(
	softwareIssueNavigatorRoute,
	() => ({
		group: ROUTE_GROUPS_ISSUE_NAVIGATOR,
		...issueNavigatorRouteEntryCommon,
		component: ProjectIssueNavigator,
		skeleton: ProjectIssueNavigatorSkeleton,
		forPaint: [LazyAtlassianNavigation, LazyProjectIssueNavigator],
		afterPaint: [LazyIssueApp],
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: componentWithFG(
				'make_nav3_sidebar_lazyforpaint',
				AsyncForPaintSidebarNextGen,
				AsyncSidebarNextGen,
			),
			horizontal: AsyncHorizontalNavJira,
			onlyShowHorizontalOnNav4: true,
		},
		resources: [...getCommonProjectResources()],
		layout: createLayout(softwareProjectLayoutNoOnboarding),
		// We want to opt out of concurrent mode for all NIN routes https://hello.atlassian.net/wiki/x/wTRfDAE
		// But allow for users with empanada-concurrent-incremental gate enabled to continue testing it
		shouldOptOutConcurrentMode:
			fg('jira-concurrent-incremental') && !fg('empanada-concurrent-incremental'),
	}),
);

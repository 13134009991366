import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import {
	Nav4Filters as Nav4FiltersWithoutErrorBoundary,
	type Nav4FiltersProps,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-filters/src/ui/filters/Nav4Filters.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './common/constants.tsx';

export const Nav4Filters = (props: Nav4FiltersProps) => (
	<UFOSegment name="nav4-sidebar-filters">
		<JSErrorBoundary
			fallback="flag"
			id="filters-section"
			packageName={PACKAGE_NAME}
			teamName={TEAM_NAME}
		>
			<Nav4FiltersWithoutErrorBoundary {...props} />
		</JSErrorBoundary>
	</UFOSegment>
);

/**
 * @generated SignedSource<<37ff7b13b5e99666e77ea2718cceb3f7>>
 * @relayHash 8194602523312eb9e26e84e1d3b9d4fa
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a167719dd25bf7bab3041ef961fefa3d673c39f413e94051e24f3dca8b62fac9

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { JourneyConfigurationPageQuery } from './JourneyConfigurationPageQuery.graphql';

const node: PreloadableConcreteRequest<JourneyConfigurationPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "a167719dd25bf7bab3041ef961fefa3d673c39f413e94051e24f3dca8b62fac9",
    "metadata": {},
    "name": "JourneyConfigurationPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

/**
 * @generated SignedSource<<44f95efd505204b94fde9c354c8fb220>>
 * @relayHash 7fee8b341915e756eb39281276465fe6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f0a7347331c5c53934d27a4679dad2ab94c9ddca76cfb2039e02f1e073652c68

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessBoardQuery } from './src_jiraBusinessBoardQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessBoardQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "f0a7347331c5c53934d27a4679dad2ab94c9ddca76cfb2039e02f1e073652c68",
    "metadata": {},
    "name": "src_jiraBusinessBoardQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	cancelButton: {
		id: 'eoc.alert-detail.delete-modal.cancel-button.no-translate',
		defaultMessage: 'Cancel',
		description: 'The text will be displayed in the delete modal footer for cancel button.',
	},
	deleteButton: {
		id: 'eoc.alert-detail.extra-property.delete-modal.delete-button.no-translate',
		defaultMessage: 'Delete',
		description: 'The text will be displayed in the delete modal footer for delete button.',
	},
});

import React from 'react';
import { graphql, useFragment } from 'react-relay';
import type { OperationsMenuForLanding$key } from '@atlassian/jira-relay/src/__generated__/OperationsMenuForLanding.graphql';
import { AlertsMenuItemContent } from './alerts-content/alerts-menu-item/AlertsMenuItemContent.tsx';
import { OperationsExpandableMenuItem } from './operations-expandable-menu-item/index.tsx';

type OperationsMenuProps = {
	isExpanded: boolean;
	queryRef: OperationsMenuForLanding$key;
};

export const OperationsMenuForLanding = ({ isExpanded, queryRef }: OperationsMenuProps) => {
	const data = useFragment<OperationsMenuForLanding$key>(
		graphql`
			fragment OperationsMenuForLanding on Query {
				...AlertsMenuItemContent
			}
		`,
		queryRef,
	);

	return (
		<OperationsExpandableMenuItem
			isExpanded={isExpanded}
			AlertsMenuItemContent={<AlertsMenuItemContent queryRef={data} />}
		/>
	);
};

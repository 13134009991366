import { useEffect } from 'react';
import { type RefetchFnDynamic, useRelayEnvironment, fetchQuery } from 'react-relay';
import type { ConcreteRequest, OperationType } from 'relay-runtime';
import { fg } from '@atlassian/jira-feature-gating';
import { useGetCurrentUrl, useGetCurrentUrlPath } from '../use-get-current-url/index.tsx';

type OperationWithCloudIdAndCurrentURL = Omit<OperationType, 'variables'> & {
	readonly variables: { cloudId: string; currentURL: string };
};

type UseRefetchProjectsParams<
	RefetchContentKey extends Readonly<{ ' $data'?: unknown; ' $fragmentSpreads': unknown }>,
> = {
	cloudId: string;
	isSelectedPath(menuId: string): boolean;
	menuId: string;
	refetch: RefetchFnDynamic<OperationWithCloudIdAndCurrentURL, RefetchContentKey>;
	QUERY: ConcreteRequest;
};

export function useRefetchWhenIsSelectedOld<
	K extends Readonly<{ ' $data'?: unknown; ' $fragmentSpreads': unknown }>,
>(params: UseRefetchProjectsParams<K>) {
	const { cloudId, isSelectedPath, menuId, refetch, QUERY } = params;
	const environment = useRelayEnvironment();
	const currentURL = useGetCurrentUrl();

	useEffect(() => {
		if (isSelectedPath(menuId)) {
			fetchQuery(environment, QUERY, { cloudId, currentURL }).subscribe({
				complete: () => {
					refetch(
						{ cloudId, currentURL },
						{
							fetchPolicy: 'store-only',
						},
					);
				},
			});
		}
	}, [cloudId, isSelectedPath, menuId, refetch, currentURL, environment, QUERY]);
}

export function useRefetchWhenIsSelectedNew<
	K extends Readonly<{ ' $data'?: unknown; ' $fragmentSpreads': unknown }>,
>(params: UseRefetchProjectsParams<K>) {
	const { cloudId, isSelectedPath, menuId, refetch, QUERY } = params;
	const environment = useRelayEnvironment();
	const currentURL = useGetCurrentUrl();
	const currentURLPath = useGetCurrentUrlPath();

	useEffect(() => {
		if (isSelectedPath(menuId)) {
			fetchQuery(environment, QUERY, { cloudId, currentURL, currentURLPath }).subscribe({
				complete: () => {
					refetch(
						{ cloudId, currentURL },
						{
							fetchPolicy: 'store-only',
						},
					);
				},
			});
		}
	}, [cloudId, isSelectedPath, menuId, refetch, currentURL, currentURLPath, environment, QUERY]);
}

export const useRefetchWhenIsSelected = <
	K extends Readonly<{ ' $data'?: unknown; ' $fragmentSpreads': unknown }>,
>(
	params: UseRefetchProjectsParams<K>,
) =>
	fg('plans_-_avoid_refetching_plans_nav_on_query_change')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useRefetchWhenIsSelectedNew(params)
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useRefetchWhenIsSelectedOld(params);

import { ROUTE_NAMES_SERVICEDESK_SERVICES_WITHOUT_PROJECT } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';
import {
	SERVICE_DESK_ROUTE_PREFIX_WITHOUT_PROJECT,
	SERVICES_ROUTE_SUFFIX,
} from './common/constants.tsx';

export const serviceDeskServicesWithoutProjectRoute: InvariantRoute = {
	name: ROUTE_NAMES_SERVICEDESK_SERVICES_WITHOUT_PROJECT,
	path: `${SERVICE_DESK_ROUTE_PREFIX_WITHOUT_PROJECT}${SERVICES_ROUTE_SUFFIX}`,
	exact: true,
};

import React, { useEffect } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useCurrentRoute as useCurrentRouteDI } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import {
	useAnalyticsEvents as useAnalyticsEventsDI,
	fireScreenAnalytics as fireScreenAnalyticsDI,
} from '@atlassian/jira-product-analytics-bridge';
import { isNinGlobalScopeEnrolmentEnabledResource } from '@atlassian/jira-router-resources-is-nin-global-scope-enrolment-enabled/src/index.tsx';

import {
	Redirect as RedirectDI,
	useResource,
	useQueryParam as useQueryParamDI,
} from '@atlassian/react-resource-router';

import type { QuickSearchToGlobalIssueNavigatorRedirectProps } from './types.tsx';

export const QuickSearchToGlobalIssueNavigatorRedirect = ({
	Redirect = RedirectDI,
	fireScreenAnalytics = fireScreenAnalyticsDI,
	useAnalyticsEvents = useAnalyticsEventsDI,
	useCurrentRoute = useCurrentRouteDI,
	useQueryParam = useQueryParamDI,
}: QuickSearchToGlobalIssueNavigatorRedirectProps) => {
	const route = useCurrentRoute();
	const [searchString] = useQueryParam('searchString');

	const { createAnalyticsEvent } = useAnalyticsEvents();
	let isNinEnabled: boolean | null | undefined;
	if (fg('nin-text-fields-new-text-search-field')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ data: isNinEnabled } = useResource(isNinGlobalScopeEnrolmentEnabledResource));
	} else {
		isNinEnabled = true;
	}

	useEffect(() => {
		if (!route) return;

		const screenEvent = createAnalyticsEvent({
			screenName: 'quickSearchRedirect',
			attributes: {
				type: route.name,
			},
		});
		fireScreenAnalytics(screenEvent);
	}, [createAnalyticsEvent, fireScreenAnalytics, route]);

	let jql: string;
	if (isNinEnabled && fg('nin-text-fields-new-text-search-field')) {
		jql = searchString != null ? `textfields ~ "${searchString}"` : 'ORDER BY created DESC';
	} else {
		jql = searchString != null ? `text ~ "${searchString}"` : 'order by created DESC';
	}

	const redirectUrl = `/issues/?jql=${encodeURIComponent(jql)}`;

	return <Redirect to={redirectUrl} />;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => <QuickSearchToGlobalIssueNavigatorRedirect />;

/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { AppSkeletonImage } from '../../common/ui/AppSkeletonImage.tsx';
import imgSVG from './assets/calendar-app-skeleton.tpl.svg';
import embedImgSVG from './assets/calendar-embed-app-skeleton.tpl.svg';
import noHeaderImgSVG from './assets/calendar-no-header-skeleton.tpl.svg';

const customCssStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		border: 'none',
	},
});

export const CalendarSkeleton = () => <AppSkeletonImage src={imgSVG} />;

export const CalendarEmbedSkeleton = () => <AppSkeletonImage src={embedImgSVG} />;

export const CalendarNoHeaderSkeleton = () => (
	<AppSkeletonImage src={noHeaderImgSVG} css={customCssStyles} />
);

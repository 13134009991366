import type { Action } from '@atlassian/react-sweet-state';
import {
	type State,
	createTitleFromTemplate,
	type DocumentTitleContainerProps,
	checkDocumentTitle,
} from '../../utils.tsx';

/**
 * This action is used to update the title of the document
 * This action also includes the `checkDocumentTitle` check (so we only update the title if it's the latest title in the context list)
 * By doing this we avoid requiring consumers of updateTitle to require listening to contextArray state
 */
export const updateTitle =
	(title: string, context: symbol): Action<State, DocumentTitleContainerProps> =>
	({ setState, getState }, { titleSuffix }) => {
		const state = getState();
		if (checkDocumentTitle(context, state.contextArray)) {
			const titleTemplate = titleSuffix ? `%s - ${titleSuffix}` : '%s - Jira';
			const newTitle = createTitleFromTemplate(title, titleTemplate);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.title = newTitle;

			setState({
				title: newTitle,
			});
		}
	};

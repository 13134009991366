import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { MoreNavMenuItem } from '../../common/ui/more-nav-menu-item/main.tsx';
import type { L1MoreNavMenuItemProps, L1MoreNavMenuItemSubmenuProps } from './types.tsx';

// The output for the submenu when in sidenav-expanded mode vs flyout mode is slightly different.
// They're here together as isolated components for clarity, so if this changes in the future
// we can easily see that they're now the same and maybe we can adjust what's passed down from the MoreMenu component;
// e.g. we may not need to pass an array if we can just pass one identical component for both use cases.
const L1MoreNavMenuItemSubmenu = ({ MenuItems, onClose }: L1MoreNavMenuItemSubmenuProps) => (
	<Box xcss={menuGroupWrapperStyles}>
		{MenuItems.map((GroupedMenuItems, index) => (
			<Box key={index} as="ul" xcss={[menuSectionStyles, index > 0 && menuSectionDividerStyles]}>
				<GroupedMenuItems onClose={onClose} />
			</Box>
		))}
	</Box>
);

/**
 * A top level more nav menu item within the left sidebar.
 */
export const L1MoreNavMenuItem = (props: L1MoreNavMenuItemProps) => {
	const { MenuItems, isOpen, onClose, onToggle } = props;

	return (
		<MoreNavMenuItem
			MenuItems={() => <L1MoreNavMenuItemSubmenu MenuItems={MenuItems} onClose={onClose} />}
			isOpen={isOpen}
			onToggle={onToggle}
			onClose={onClose}
		/>
	);
};

const menuGroupWrapperStyles = xcss({
	paddingTop: 'space.050',
	paddingInline: 'space.050',
});

const menuSectionStyles = xcss({
	// Override default <ul> padding
	padding: 'space.0',
	paddingBottom: 'space.050',
});

const menuSectionDividerStyles = xcss({
	borderTop: `1px solid ${token('color.border')}`,
	paddingTop: 'space.050',
	marginTop: 'space.0',
});

import { useMemo } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { useCreateProjectPermission } from '@atlassian/jira-nav-item-service/src/services/create-project-permisson/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { recommendationContext } from '../common/types.tsx';

export const useRecommendationContext = (): recommendationContext => {
	const locale = useLocale();
	const accountId = useAccountId();
	const { cloudId, licensedProducts, firstActivationDateMs, areProductSuggestionsEnabled } =
		useTenantContext();
	const [{ canCreateProject }] = useCreateProjectPermission();
	const { software: edition } = useAppEditions();
	const environment = useRelayEnvironment();

	return useMemo(
		() => ({
			locale,
			cloudId,
			accountId,
			licensedProducts,
			edition,
			firstActivationDateMs,
			areProductSuggestionsEnabled,
			environment,
			canCreateProject,
		}),
		[
			accountId,
			areProductSuggestionsEnabled,
			canCreateProject,
			cloudId,
			edition,
			environment,
			firstActivationDateMs,
			licensedProducts,
			locale,
		],
	);
};

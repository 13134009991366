import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { ROUTE_GROUPS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { jswOnboardingResource } from '@atlassian/jira-router-resources-jsw-onboarding/src/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { softwareOnboardingRoute } from '@atlassian/jira-router-routes-software-onboarding-routes/src/softwareOnboardingRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';

import { SoftwareOnboardingPage, LazySoftwareOnboardingPage } from './ui/index.tsx';

export const softwareOnboardingRouteEntry: Route = createEntry(softwareOnboardingRoute, () => ({
	group: ROUTE_GROUPS_SOFTWARE,
	component: SoftwareOnboardingPage,
	layout: chromelessLayout,
	resources: [jswOnboardingResource, themePreferenceResource],
	forPaint: [LazySoftwareOnboardingPage],
	canTransitionIn: () => false,
	shouldOptOutConcurrentMode: fg('jira_concurrent_mode_onboarding'),
}));

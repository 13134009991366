import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_GLOBAL_SETTINGS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { productsJsmKbPermissionsRoute } from '@atlassian/jira-router-routes-products-jsm-kb-permissions-routes/src/productsJsmKbPermissionsRoute.tsx';
import ProductsJsmKbPermissions, { LazyProductsJsmKbPermissions } from './ui/index.tsx';

export const productsJsmKbPermissionsRouteEntry = createEntry(
	productsJsmKbPermissionsRoute,
	() => ({
		group: ROUTE_GROUPS_GLOBAL_SETTINGS,
		component: ProductsJsmKbPermissions,
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

		resources: [...getNavigationResources(), ...getNavigationSidebarProductsSettingResource()],
		forPaint: [LazyAtlassianNavigation, LazyProductsJsmKbPermissions],
	}),
);

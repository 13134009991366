export const ENTITY_LIMIT_ERROR_TYPE = {
	COMMENTS_PER_VIEW: 'commentsPerView',
	FIELDS_PER_VIEW: 'fieldsPerView',
	FIELDS_PER_PROJECT: 'fieldsPerProject',
	INSIGHTS_PER_IDEA: 'insightsPerIdea',
	INSIGHTS_PER_PROJECT: 'insightsPerProject',
	LABELS_PER_SNIPPET: 'labelsPerSnippet',
	OPTIONS_PER_FIELD: 'optionsPerField',
	PLAY_CONTRIBUTIONS_PER_PLAY: 'playContributionsPerPlay',
	PLAY_CONTRIBUTIONS_PER_PROJECT: 'playContributionsPerProject',
	PLAYS_PER_PROJECT: 'playsPerProject',
	SNIPPETS_PER_INSIGHT: 'snippetsPerInsight',
	VIEWS_PER_PROJECT: 'viewsPerProject',
	VIEWSETS_PER_PROJECT: 'viewsetsPerProject',
} as const;

export type LimitType = (typeof ENTITY_LIMIT_ERROR_TYPE)[keyof typeof ENTITY_LIMIT_ERROR_TYPE];

import React, { type ComponentType, type FC, type MouseEvent } from 'react';
import { MenuGroup } from '@atlaskit/menu';
import type { ContentProps } from '@atlaskit/popup';
import { Box } from '@atlaskit/primitives';
import { MENU_GROUP_MIN_WIDTH } from '../../../common/constants/index.tsx';
import type { OnCloseProps } from '../../../common/types.tsx';

export type MoreNavMenuButtonMenuItemsProps = OnCloseProps;

export type MoreNavMenuButtonSubmenuPassThroughProps = {
	MenuItems: ComponentType<MoreNavMenuButtonMenuItemsProps>;
};

type MoreNavMenuButtonSubmenuProps = MoreNavMenuButtonSubmenuPassThroughProps & ContentProps;

const stopEventPropagation = (event: MouseEvent<HTMLDivElement>) => event.stopPropagation();

export const MoreNavMenuButtonSubmenu: FC<MoreNavMenuButtonSubmenuProps> = ({
	MenuItems,
	onClose,
}) => (
	<Box onMouseEnter={stopEventPropagation} onMouseLeave={stopEventPropagation}>
		<MenuGroup minWidth={MENU_GROUP_MIN_WIDTH}>
			<MenuItems onClose={onClose} />
		</MenuGroup>
	</Box>
);

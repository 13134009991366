import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	upsellLozengeText: {
		defaultMessage: 'Premium',
		description:
			'A Lozenge located after Assets label to attract non premium customer explore Assets',
		id: 'navigation-apps-sidebar-nav4-sidebars-content-assets.assets-menu.upsell-lozenge-text',
	},
});

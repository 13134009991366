import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addButton: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-teams.common.action-buttons.add-button',
		defaultMessage: 'Create or invite',
		description: 'Button to create a new team or invite new user',
	},
	createTeam: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-teams.common.action-buttons.create-team',
		defaultMessage: 'Create a team',
		description: 'Used in popup menu for user to create a team',
	},
	invitePeople: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-teams.common.action-buttons.invite-people',
		defaultMessage: 'Invite people',
		description: 'Used in popup menu for user to invite new user',
	},
});

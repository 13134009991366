import differenceInDays from 'date-fns/differenceInDays';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';

export const getDurationInTrash = (projectTrashedDate: string): number => {
	const parsedProjectTrashedDate = isValid(parseISO(projectTrashedDate))
		? parseISO(projectTrashedDate)
		: new Date(projectTrashedDate);

	return differenceInDays(new Date(), parsedProjectTrashedDate);
};

import { getProjectKeyOrId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-or-id/index.tsx';
import { createResource, useResource } from '@atlassian/react-resource-router';
import type { HelpCenterJirafield } from './get-data/index.tsx';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "help-center/form-edit-jirafields-resource", jiraSpaEntry: help-center/form-edit-jirafields-resource" */ './get-data'
	);

export const formEditJirafieldsResource = createResource<HelpCenterJirafield>({
	type: 'HELP_CENTER_FORM_EDIT_JIRAFIELDS_RESOURCE',
	getKey: ({ match }) => getProjectKeyOrId(match),
	getDataLoader,
});

export const useFormEditJirafields = () =>
	useResource<HelpCenterJirafield>(formEditJirafieldsResource);

import React, { useMemo, type ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';
import { ForgeScreenEvent } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { TriggerAnalyticsWrapper } from '@atlassian/jira-forge-ui-analytics/src/ui/global-page/index.tsx';
import { GLOBAL_PAGE_MODULE } from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ADDON_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { Nav4ConnectMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-apps/src/common/ui/connect-menu-item/Nav4ConnectMenuItem.tsx';
import { Nav4ForgeMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-apps/src/common/ui/forge-menu-item/Nav4ForgeMenuItem.tsx';
import type { Nav4AppsContent$key } from '@atlassian/jira-relay/src/__generated__/Nav4AppsContent.graphql';
import {
	MenuListItem,
	MenuList,
	MenuSection,
	MenuSectionHeading,
} from '@atlassian/navigation-system';
import messages from './messages.tsx';

type Nav4AppsContentProps = {
	queryRef: Nav4AppsContent$key;
};

export function Nav4AppsContent({ queryRef }: Nav4AppsContentProps) {
	const data = useFragment<Nav4AppsContent$key>(
		graphql`
			fragment Nav4AppsContent on JiraQuery {
				globalAppNavigationItems(cloudId: $cloudId) @optIn(to: "JiraNavigationItems") {
					edges @required(action: THROW) {
						node @required(action: THROW) {
							id @required(action: THROW)
							... on JiraAppNavigationItem {
								label
								appId
								appType
							}
							...Nav4ConnectMenuItem
							...Nav4ForgeMenuItem
						}
					}
				}
			}
		`,
		queryRef,
	);

	const { formatMessage } = useIntl();

	const menuItemNodes = useMemo(
		() =>
			(data?.globalAppNavigationItems?.edges || [])
				.filter((edge) => {
					// Keep only Forge and Connect apps on the list
					if (edge?.node?.appType !== 'CONNECT' && edge?.node?.appType !== 'FORGE') return false;
					// Filter out system apps just like it was done in the old navigation
					return edge?.node?.appId !== ADDON_ID.DISCOVER;
				})
				.map((edge) => edge?.node)
				.sort((a, b) => (a?.label || '').localeCompare(b?.label || '')) || [],
		[data?.globalAppNavigationItems],
	);

	const renderedMenuItems = useMemo(
		() =>
			menuItemNodes
				.map((node) => {
					switch (node?.appType) {
						case 'CONNECT':
							return <Nav4ConnectMenuItem key={node.id} queryRef={node} />;
						case 'FORGE':
							return <Nav4ForgeMenuItem key={node.id} queryRef={node} />;
						default:
							return null;
					}
				})
				.filter(Boolean),
		[menuItemNodes],
	);

	const hasForgeApps = useMemo(
		() => menuItemNodes.some((node) => node?.appType === 'FORGE'),
		[menuItemNodes],
	);

	return renderedMenuItems.length ? (
		<ForgeAnalyticsWrapper shouldReport={hasForgeApps}>
			<MenuListItem>
				<MenuSection>
					<MenuSectionHeading>{formatMessage(messages.yourApps)}</MenuSectionHeading>
					<MenuList>
						{renderedMenuItems}
						{hasForgeApps && <ForgeScreenEvent attributes={{ moduleType: GLOBAL_PAGE_MODULE }} />}
					</MenuList>
				</MenuSection>
			</MenuListItem>
		</ForgeAnalyticsWrapper>
	) : null;
}

function ForgeAnalyticsWrapper({
	shouldReport,
	children,
}: {
	shouldReport: boolean;
	children: ReactNode;
}) {
	return shouldReport ? <TriggerAnalyticsWrapper>{children}</TriggerAnalyticsWrapper> : children;
}

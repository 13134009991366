import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import { createJpdExperience } from '../../utils/experience/main.tsx';

const pageSegmentLoad = createJpdExperience(
	new UFOExperience('jpd.board-view', {
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	}),
);

// measure list scroll performance
const scrollPerformance = createJpdExperience(
	new UFOExperience('jpd.board-view.scroll-performance', {
		type: ExperienceTypes.Operation,
		performanceType: ExperiencePerformanceTypes.Custom,
	}),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	scrollPerformance,
	pageSegmentLoad,
};

/**
 * @generated SignedSource<<977f32d87616dfd4cd4320d66f749127>>
 * @relayHash 9a8439c429672969a3cd34c586871906
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5b0466e32aeb0eda9302be834e1d989247757918380dc053b604748256b01a6b

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraSummaryBusinessEmbedQuery } from './src_jiraSummaryBusinessEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraSummaryBusinessEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "5b0466e32aeb0eda9302be834e1d989247757918380dc053b604748256b01a6b",
    "metadata": {},
    "name": "src_jiraSummaryBusinessEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import React, { useCallback, useContext } from 'react';
import ClockIcon from '@atlaskit/icon/core/clock';
import { GlobalRecentMenuItem } from '@atlassian/global-recent';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { NAVIGATION_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import { SECTION_ITEM_RECENT } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { NavItemsContext } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/controllers/context.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { SkeletonNavMenuItems } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/skeleton-nav-menu-items/main.tsx';
import { useFormattedMessageAndIcon } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-formatted-message-and-icon/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	FlyoutMenuItem,
	FlyoutMenuItemTrigger,
	FlyoutMenuItemContent,
} from '@atlassian/navigation-system';
import { PACKAGE_NAME, TEAM_NAME, RECENT_QUERY_LIMIT } from '../common/constants.tsx';
import messages from './messages.tsx';
import { sidebarRecentMenuEntryPoint } from './recent-menu/entrypoint.tsx';

export type RecentMenuProps = {
	isDefaultOpen?: boolean;
};

export function RecentMenu({ isDefaultOpen = false }: RecentMenuProps) {
	const cloudId = useCloudId();
	const { formattedMessage, icon } = useFormattedMessageAndIcon(messages.recent, ClockIcon);
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		sidebarRecentMenuEntryPoint,
		{ cloudId, count: RECENT_QUERY_LIMIT },
	);

	const triggerRef = useEntryPointButtonTrigger(entryPointActions);
	const { moreMenuIds } = useContext(NavItemsContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onOpenChange = useCallback(
		(isMenuOpen: boolean) => {
			if (isDefaultOpen && isMenuOpen) {
				entryPointActions.load();
			}

			if (isMenuOpen) {
				const event = createAnalyticsEvent({
					action: 'clicked',
					actionSubject: NAVIGATION_ITEM,
				});
				const isInMore = moreMenuIds.includes(L1_MENU_ID.RECENT);
				fireUIAnalytics(event, SECTION_ITEM_RECENT, { isInMore });
			}
		},
		[entryPointActions, isDefaultOpen, moreMenuIds, createAnalyticsEvent],
	);
	return fg('jira_global_recent_menu_item') ? (
		<GlobalRecentMenuItem
			triggerRef={triggerRef}
			onOpenChange={onOpenChange}
			isDefaultOpen={isDefaultOpen}
		/>
	) : (
		<FlyoutMenuItem onOpenChange={onOpenChange} isDefaultOpen={isDefaultOpen}>
			<FlyoutMenuItemTrigger
				interactionName="nav4-sidebar-recent"
				ref={triggerRef}
				iconBefore={icon}
			>
				{formattedMessage}
			</FlyoutMenuItemTrigger>
			<FlyoutMenuItemContent>
				<JiraEntryPointContainer
					entryPointReferenceSubject={entryPointReferenceSubject}
					id="nav4-sidebar-recent-default"
					packageName={PACKAGE_NAME}
					teamName={TEAM_NAME}
					errorFallback="flag"
					fallback={<SkeletonNavMenuItems />}
					runtimeProps={{}}
				/>
			</FlyoutMenuItemContent>
		</FlyoutMenuItem>
	);
}

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	recent: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-dashboards.common.content.recent',
		defaultMessage: 'Recent',
		description: 'Message for recent heading in the projects menu items',
	},
	starred: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-dashboards.common.content.starred',
		defaultMessage: 'Starred',
		description: 'Message for starred heading in the projects menu items',
	},
	viewAllStarred: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-dashboards.common.content.view-all-starred',
		defaultMessage: 'View all starred',
		description: 'Message for view all starred link in the starred projects section',
	},
});

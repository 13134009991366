import React, { useCallback } from 'react';
import SearchIcon from '@atlaskit/icon/core/search';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { NAVIGATION_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import { FIXED_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { LEVEL_ONE } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { MENU_ID_FILTERS_SEARCH } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import type { NavSubmenuHeader } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import { useFilterActionsAnalytics } from '../../utils/analytics/index.tsx';
import messages from './messages.tsx';

export const Header: NavSubmenuHeader = ({ onClose }) => {
	const { formatMessage } = useIntl();
	const sendFilterAnalytics = useFilterActionsAnalytics();

	const handleOnClick = useCallback(
		(event: Event | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
			sendFilterAnalytics(NAVIGATION_ITEM, {
				level: LEVEL_ONE,
				componentType: FIXED_ITEM,
				itemId: 'searchAllIssues',
			});
			onClose?.(event);
		},
		[onClose, sendFilterAnalytics],
	);

	return (
		<Nav4MenuLinkItem
			href="/issues/?jql=created%20%3E%3D%20-30d%20order%20by%20created%20DESC"
			onClick={handleOnClick}
			menuId={MENU_ID_FILTERS_SEARCH}
			elemBefore={<SearchIcon label="" color="currentColor" />}
		>
			{formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.searchAllIssuesIssueTermRefresh
					: messages.searchAllIssues,
			)}
		</Nav4MenuLinkItem>
	);
};

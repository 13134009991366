import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { MENU_ID_OPERATIONS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { useSidebarNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-context/index.tsx';
import type { Nav4Operations$key } from '@atlassian/jira-relay/src/__generated__/Nav4Operations.graphql';
import { OperationsMenuDefault } from './OperationsMenuDefault.tsx';
import { OperationsMenuForLanding } from './OperationsMenuForLanding.tsx';

export type Nav4OperationsProps = {
	isExpanded: boolean;
	queryRef: Nav4Operations$key;
};

export const Nav4Operations = ({ isExpanded, queryRef }: Nav4OperationsProps) => {
	const data = useFragment<Nav4Operations$key>(
		graphql`
			fragment Nav4Operations on Query {
				...OperationsMenuForLanding @include(if: $isOperationsExpanded)
			}
		`,
		queryRef,
	);
	const { isInitialSelectedPath } = useSidebarNav4();

	return isInitialSelectedPath(MENU_ID_OPERATIONS) ? (
		<OperationsMenuForLanding isExpanded={isExpanded} queryRef={data} />
	) : (
		<OperationsMenuDefault isExpanded={isExpanded} />
	);
};

/**
 * @generated SignedSource<<dcd95b7125edd7b76df5c5013b522205>>
 * @relayHash c4c7edcb69c35f51ea220574d0815858
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID af9ae1db2f285065c82807efe7982bd15a72bebc7c10b3ab0526c030f540c5ad

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { pageLayout_projectsDirectoryLayout_RootQuery } from './pageLayout_projectsDirectoryLayout_RootQuery.graphql';

const node: PreloadableConcreteRequest<pageLayout_projectsDirectoryLayout_RootQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "af9ae1db2f285065c82807efe7982bd15a72bebc7c10b3ab0526c030f540c5ad",
    "metadata": {},
    "name": "pageLayout_projectsDirectoryLayout_RootQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

/**
 * @generated SignedSource<<9cb82d8b8a0606478bd6482643028af4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4Filters$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4FiltersForLanding">;
  readonly " $fragmentType": "Nav4Filters";
};
export type Nav4Filters$key = {
  readonly " $data"?: Nav4Filters$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Filters">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isFiltersExpanded"
    }
  ],
  "kind": "Fragment",
  "name": "Nav4Filters",
  "selections": [
    {
      "condition": "isFiltersExpanded",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "Nav4FiltersForLanding"
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "bc99d6e3499fdf4ab0d2fdec5f064e1f";

export default node;

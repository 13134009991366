import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	filterDeletedModalTitleSuccessNonFinal: {
		id: 'navigation-apps-sidebar-nav4-filters.common.filter-actions.delete-filter-modal.filter-deleted-modal-title-success-non-final',
		defaultMessage: '{name} was deleted',
		description: 'Title of the flag shown when the request for deleting a filter succesed',
	},
	filterDeletedModalTitleErrorNonFinal: {
		id: 'navigation-apps-sidebar-nav4-filters.common.filter-actions.delete-filter-modal.filter-deleted-modal-title-error-non-final',
		defaultMessage: "We couldn't delete filter",
		description:
			'Title of the flag shown in Filters Directory when the request for deleting a filter failed',
	},
	filterDeletedModalDescriptionErrorNonFinal: {
		id: 'navigation-apps-sidebar-nav4-filters.common.filter-actions.delete-filter-modal.filter-deleted-modal-description-error-non-final',
		defaultMessage: 'There was an error, please try again.',
		description:
			'Message shown in the flag displayed in Filters Directory when the request for deleting a filter failed',
	},
});

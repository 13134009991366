import React, { useCallback } from 'react';
import AsyncChangeOwnerModal from '@atlassian/jira-change-owner-filter-modal/src/async.tsx';

type Props = {
	isOpen: boolean;
	filterId: string;
	onRefetch: (options: { onComplete: () => void }) => void;
	onClose: () => void;
};

export const ChangeOwnerModal = ({ isOpen, filterId, onRefetch, onClose }: Props) => {
	const onSuccess = useCallback(() => {
		onRefetch({ onComplete: () => undefined });
		onClose();
	}, [onRefetch, onClose]);

	return (
		isOpen && (
			<AsyncChangeOwnerModal
				id={filterId}
				onInitiateSuccess={() => undefined}
				onSuccess={onSuccess}
				onCancel={onClose}
				onInitiateFailed={() => undefined}
			/>
		)
	);
};

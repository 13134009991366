import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createBoard: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-projects-common.project-actions.create-board',
		defaultMessage: 'Create board',
		description:
			'Used as the alt text for an icon button that when used navigates to create a board',
	},
});

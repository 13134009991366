import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import { createJpdExperience } from '../../utils/experience/main.tsx';

const pageSegmentLoad = createJpdExperience(
	new UFOExperience('jpd.list-view', {
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	}),
);

const createIdeaInline = createJpdExperience(
	new UFOExperience('jpd.list-view.create-idea-inline', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

// this metric is meant to measure the time between clicking to add an idea inline and the input row appearing
const createIdeaInlineClickResponse = createJpdExperience(
	new UFOExperience('jpd.list-view.create-idea-inline-button-click', {
		type: ExperienceTypes.Operation,
		performanceType: ExperiencePerformanceTypes.Custom,
	}),
);

const changeViewName = createJpdExperience(
	new UFOExperience('jpd.list-view.change-view-name', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

// measure list scroll performance
const listScrollPerformance = createJpdExperience(
	new UFOExperience('jpd.list-view.scroll-performance', {
		type: ExperienceTypes.Operation,
		performanceType: ExperiencePerformanceTypes.Custom,
	}),
);

/**
 * Since field updates can be frequent, makeFieldUpdate is a factory
 * to avoid conccurency problems as JPDExperience objects are singletons.
 */
const makeFieldUpdate = () =>
	createJpdExperience(
		new UFOExperience('jpd.list-view.field-update', {
			type: ExperienceTypes.Experience,
			performanceType: ExperiencePerformanceTypes.InlineResult,
			timings: [
				{ key: 'internal_state', endMark: 'backend_request_start' },
				{ key: 'backend_request', startMark: 'backend_request_start' },
			],
		}),
	);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	changeViewName,
	createIdeaInline,
	createIdeaInlineClickResponse,
	listScrollPerformance,
	pageSegmentLoad,
	makeFieldUpdate,
};

import React, { type ReactNode } from 'react';
import { DropdownItem, type DropdownItemProps } from '@atlaskit/dropdown-menu';
import {
	type ModalEntryPoint,
	ModalEntryPointPressableTrigger,
	type ModalEntryPointPressableTriggerProps,
} from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';

type ModalEntryPointDropdownItemTriggerProps<TEntryPoint extends ModalEntryPoint> = Omit<
	ModalEntryPointPressableTriggerProps<TEntryPoint>,
	'children' | 'title' | 'width'
> & {
	modalTitle?: ReactNode;
} & DropdownItemProps & { forwardedRef?: React.Ref<HTMLElement> };

export const ModalEntryPointDropdownItemTrigger = <TEntryPoint extends ModalEntryPoint>({
	children,
	entryPoint,
	entryPointParams,
	entryPointProps,
	errorAttributes,
	Fallback,
	interactionName,
	modalTitle,
	modalProps,
	useInternalModal,
	forwardedRef,
	...dropdownProps
}: ModalEntryPointDropdownItemTriggerProps<TEntryPoint>) => {
	return (
		<ModalEntryPointPressableTrigger
			forwardedRef={forwardedRef}
			entryPoint={entryPoint}
			entryPointParams={entryPointParams}
			entryPointProps={entryPointProps}
			errorAttributes={errorAttributes}
			Fallback={Fallback}
			interactionName={interactionName}
			title={modalTitle}
			modalProps={modalProps}
			useInternalModal={useInternalModal}
		>
			{({ ref }) => (
				<DropdownItem ref={ref} {...dropdownProps}>
					{children}
				</DropdownItem>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

import React from 'react';
import InboxIcon from '@atlaskit/icon/core/inbox';
import { SECTION_ITEM_YOUR_WORK } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_YOUR_WORK } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import { useFormattedMessageAndIcon } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-formatted-message-and-icon/index.tsx';
import { jiraHomeRoute } from '@atlassian/jira-router-routes-jira-home-routes/src/jiraHomeRoute.tsx';
import messages from './messages.tsx';

const JIRA_HOME_PATH = jiraHomeRoute.path;

const analytics = {
	actionSubjectId: SECTION_ITEM_YOUR_WORK,
	itemId: 'yourWork',
	level: 1,
	// Note: Your work doesn't seem to be configurable via Customise sidebar - More menu
	isInMore: false,
};

export const YourWork = () => {
	const { formattedMessage, icon } = useFormattedMessageAndIcon(messages.yourWork, InboxIcon);

	return (
		<Nav4MenuLinkItem
			analytics={analytics}
			elemBefore={icon}
			href={JIRA_HOME_PATH}
			menuId={MENU_ID_YOUR_WORK}
		>
			{formattedMessage}
		</Nav4MenuLinkItem>
	);
};

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	deleteSavedSearch: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.alerts-content.alerts-menu-item.action-button.delete-saved-search',
		defaultMessage: 'Delete',
		description: 'Delete saved search',
	},
	confirmTrashHeading: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.alerts-content.alerts-menu-item.action-button.confirm-trash-heading',
		defaultMessage: 'Delete saved search',
		description: 'Heading for the confirm trash modal',
	},
	confirmTrashMessage: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.alerts-content.alerts-menu-item.action-button.confirm-trash-message',
		defaultMessage: 'Are you sure you want to delete this saved search?',
		description: 'Message for the confirm trash modal',
	},
	errorTitle: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.alerts-content.alerts-menu-item.action-button.error-title',
		defaultMessage: 'Error',
		description: 'Title for the error toast',
	},
	errorMessage: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.alerts-content.alerts-menu-item.action-button.error-message',
		defaultMessage: 'Something went wrong. Please try again.',
		description: 'Message for the error toast',
	},
});

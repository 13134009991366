import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { getFilterName, getOnCancel, isDataRetrieved } from '../state/selectors.tsx';
import type { State } from '../state/types.tsx';
import Content from './content/index.tsx';
import Footer from './footer/index.tsx';
import DeleteFiltersModal from './view.tsx';

const mapStateToProps = (state: State) => ({
	Content,
	Footer,
	name: getFilterName(state),
	onCancel: getOnCancel(state),
	isOpen: isDataRetrieved(state),
});

export default connect(mapStateToProps, {})(DeleteFiltersModal);

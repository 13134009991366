import React, { useCallback, useRef, useState, type FormEvent } from 'react';
import debounce from 'lodash/debounce';
import { Field, ErrorMessage, HelperMessage, useFormState } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { VALIDATION_ERROR_EMPTY, BOARD_NAME_LIMIT } from '../../../../common/constants.tsx';
import type { BoardCreateFormValues } from '../../../../common/types.tsx';
import messages from './messages.tsx';

export const BoardNameField = () => {
	const { formatMessage } = useIntl();
	const formState = useFormState<BoardCreateFormValues>();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [value, setValue] = useState('');
	const hasEdited = useRef(false);

	const debouncedHandleOnChange = useRef(
		debounce(() => {
			if (!hasEdited.current) {
				fireUIAnalytics(
					createAnalyticsEvent({
						action: 'changed',
						actionSubject: 'input',
					}),
					'boardName',
				);
				hasEdited.current = true;
			}
		}, 300),
	).current;

	const handleOnChange = useCallback(
		(event: FormEvent<HTMLInputElement>) => {
			setValue(event.currentTarget.value);
			const newValue = event.currentTarget.value;
			setValue(newValue);
			debouncedHandleOnChange();
		},
		[debouncedHandleOnChange],
	);

	return (
		<Field<BoardCreateFormValues['boardName']>
			label={formatMessage(messages.boardNameFieldLabel)}
			name="boardName"
			isRequired
		>
			{({ fieldProps: { onChange, ...rest }, error }) => (
				<>
					<Textfield
						{...rest}
						placeholder={formatMessage(messages.boardNameFieldPlaceholder)}
						maxLength={BOARD_NAME_LIMIT}
						testId="software-board-create.ui.modal.form.board-name.board-name-input"
						value={value}
						onChange={(event) => {
							handleOnChange(event);
							onChange(event);
						}}
					/>
					{error === VALIDATION_ERROR_EMPTY && (
						<ErrorMessage testId="software-board-create.ui.modal.form.board-name.board-name-error">
							{formatMessage(messages.boardNameEmptyError)}
						</ErrorMessage>
					)}
					{formState?.values.boardName && formState.values.boardName.length >= BOARD_NAME_LIMIT && (
						<HelperMessage>
							{formatMessage(messages.boardNameMaxChars, {
								maxLength: BOARD_NAME_LIMIT,
							})}
						</HelperMessage>
					)}
				</>
			)}
		</Field>
	);
};

{
  "playground.playground-local-execution-message.message-template-template.email.body.img.alt.no-translate": [
    {
      "type": 0,
      "value": "Description of image"
    }
  ],
  "playground.playground-local-execution-message.message-template.email.subject.no-translate": [
    {
      "type": 0,
      "value": "Post Office Test Message from "
    },
    {
      "type": 1,
      "value": "env"
    }
  ],
  "playground.playground-local-execution-message.test-message.email.body.hello.no-translate": [
    {
      "type": 0,
      "value": "Hello, fellow child "
    },
    {
      "type": 1,
      "value": "name"
    },
    {
      "type": 0,
      "value": ","
    }
  ],
  "playground.playground-local-execution-message.test-message.email.body.no-translate": [
    {
      "type": 0,
      "value": "We are currently trying to locally execute this message!"
    }
  ],
  "playground.playground-local-execution-message.test-message.email.body.regards.no-translate": [
    {
      "type": 0,
      "value": "Playground Team"
    }
  ],
  "playground.playground-local-execution-message.test-message.email.body.signoff.no-translate": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "playground.playground-local-execution-message.test-message.email.unsubscribe.text.no-translate": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "post-office.playground.go-faster-techops-rollup-notifier.sample-hello-message.no-translate": [
    {
      "type": 0,
      "value": "Hello, *This is notification test from post office*"
    }
  ],
  "post-office.playground.test-playground-message.sample-mobile-notification-message.no-translate": [
    {
      "type": 0,
      "value": "Hello, this is a mobile push notification test from post office."
    }
  ]
}
import parameters, {
	type JiraFavouriteFilter,
} from '@atlassian/jira-relay/src/__generated__/starredMenuQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = {
	cloudId: string;
	filter: JiraFavouriteFilter;
	count: number;
};

export const sidebarStarredMenuEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-nav4-sidebar-starred-menu" */ './index'),
	),
	getPreloadProps: ({ cloudId, filter, count }: EntryPointParams) => ({
		queries: {
			starredMenuData: {
				parameters,
				options: {
					fetchPolicy: 'store-and-network' as const,
				},
				variables: {
					cloudId,
					count,
					filter,
				},
			},
		},
	}),
});

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_GLOBAL_SETTINGS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalSettingsPerformanceMetricDetailRoute } from '@atlassian/jira-router-routes-admin-pages-performance-routes/src/globalSettingsPerformanceMetricDetailRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { RedirectToExactMetricRoutes } from './ui/spa/admin-pages/performance/metric-detail/redirect-to-new/index.tsx';

// This is an old route that is not used anymore. It is kept here for backward compatibility.
export const globalSettingsPerformanceMetricDetailRouteEntry: Route = createEntry(
	globalSettingsPerformanceMetricDetailRoute,
	() => ({
		group: ROUTE_GROUPS_GLOBAL_SETTINGS,
		component: RedirectToExactMetricRoutes,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},
		resources: [...getNavigationResources(), navigationSidebarGlobalResource],
		forPaint: [LazyAtlassianNavigation],
	}),
);

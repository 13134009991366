import React, { memo } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import type { AppRecommendations } from './common/types.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './constants.tsx';
import { Recommendations as RecommendationsWithoutErrorBoundary } from './ui/recommendations.tsx';

type RecommendationProps = {
	appRecommendations: AppRecommendations;
};

export const Recommendations = memo(_Recommendations);

function _Recommendations({ appRecommendations }: RecommendationProps) {
	return (
		<JSErrorBoundary
			id="growth-recommendations"
			fallback="flag"
			packageName={PACKAGE_NAME}
			teamName={TEAM_NAME}
		>
			<Placeholder name="recommendations">
				<RecommendationsWithoutErrorBoundary appRecommendations={appRecommendations} />
			</Placeholder>
		</JSErrorBoundary>
	);
}

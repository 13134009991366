import { createContext, type Dispatch, type SetStateAction } from 'react';
import noop from 'lodash/noop';

export const MoreMenuIsOpenContext = createContext<MoreMenuIsOpenContext>({
	moreMenuIsOpen: false,
	setMoreMenuIsOpen: noop,
});

type MoreMenuIsOpenContext = {
	moreMenuIsOpen: boolean;
	setMoreMenuIsOpen: Dispatch<SetStateAction<boolean>>;
};

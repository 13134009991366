import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import { createJpdExperience } from '../../utils/experience/main.tsx';

const precalculateFormulaFieldAllValuesSelector = createJpdExperience(
	new UFOExperience('jpd.formula-field.precalculate-all-values-selector', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	precalculateFormulaFieldAllValuesSelector,
};

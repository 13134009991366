/**
 * @generated SignedSource<<be49f2e7ce153ce5dfcf7f1f686dad93>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraPlanStatus = "ACTIVE" | "ARCHIVED" | "TRASHED" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type PlanMenuItem$data = {
  readonly favouriteValue: {
    readonly isFavourite: boolean | null | undefined;
  } | null | undefined;
  readonly isReadOnly: boolean | null | undefined;
  readonly planId: AGG$Long | null | undefined;
  readonly planStatus: JiraPlanStatus | null | undefined;
  readonly scenario: {
    readonly scenarioId: AGG$Long | null | undefined;
  } | null | undefined;
  readonly title: string | null | undefined;
  readonly " $fragmentType": "PlanMenuItem";
};
export type PlanMenuItem$key = {
  readonly " $data"?: PlanMenuItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlanMenuItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "PlanMenuItem",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "planId"
    },
    {
      "kind": "ScalarField",
      "name": "title"
    },
    {
      "kind": "ScalarField",
      "name": "isReadOnly"
    },
    {
      "kind": "ScalarField",
      "name": "planStatus"
    },
    {
      "concreteType": "JiraFavouriteValue",
      "kind": "LinkedField",
      "name": "favouriteValue",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isFavourite"
        }
      ]
    },
    {
      "concreteType": "JiraScenario",
      "kind": "LinkedField",
      "name": "scenario",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "scenarioId"
        }
      ]
    }
  ],
  "type": "JiraPlan"
};

(node as any).hash = "18d103ddd016cfaaf7283f93556817eb";

export default node;

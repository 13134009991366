import React, { useCallback, useMemo, type ComponentType } from 'react';
import { SEARCH_MODE_BASIC } from '../../common/constants.tsx';
import type { SearchMode, SearchModeSwitcherProps } from '../../common/types.tsx';
import { SearchModeSwitcherWithTarget as DefaultSearchModeSwitcher } from '../../common/ui/components/search-mode-switcher/index.tsx';
import { SearchModeSwitcherContainer } from '../../common/ui/search-mode-switcher-container/index.tsx';
import { useSelectedSearchMode } from '../../controllers/selected-search-mode-state/index.tsx';
import { useIsQuerySupported } from '../../controllers/use-query/index.tsx';

type Props = {
	isSearchModeToggleEnabled: boolean;
	SearchModeSwitcher?: ComponentType<SearchModeSwitcherProps>;
	isHydrating: boolean;
	isSearchModeSwitcherOnLeft: boolean;
};
export const SearchModeSwitcherRenderer = ({
	isSearchModeToggleEnabled,
	SearchModeSwitcher = DefaultSearchModeSwitcher,
	isHydrating,
	isSearchModeSwitcherOnLeft,
}: Props) => {
	const { searchMode: selectedSearchMode, setSelectedSearchMode } = useSelectedSearchMode();

	const { querySupported } = useIsQuerySupported();

	const onUpdateSearchMode = useCallback(
		(searchMode: SearchMode) => {
			setSelectedSearchMode(searchMode, true);
		},
		[setSelectedSearchMode],
	);

	const isBasicModeDisabled = useMemo(
		() =>
			// We disable basic mode if:
			// If query is not supported
			!querySupported ||
			// Or if is supported but is also hydrating and we are not in basic mode
			(isHydrating && selectedSearchMode !== SEARCH_MODE_BASIC),
		[isHydrating, querySupported, selectedSearchMode],
	);

	return isSearchModeToggleEnabled ? (
		<SearchModeSwitcherContainer
			testId="jql-builder.ui.search-mode-switcher-renderer.toggle-container"
			isSearchModeSwitcherOnLeft={isSearchModeSwitcherOnLeft}
		>
			<SearchModeSwitcher
				searchMode={selectedSearchMode}
				isBasicModeDisabled={isBasicModeDisabled}
				onUpdateSearchMode={onUpdateSearchMode}
			/>
		</SearchModeSwitcherContainer>
	) : null;
};

import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { LicensedJiraProduct } from '../common/types.tsx';
import type { AiEnablementResponse } from './types.tsx';

export const fetchAiEnablementUrl = (jiraProduct: LicensedJiraProduct, version = '1') =>
	`/rest/api/${version}/aiconfig/ai-enabled/${jiraProduct}`;

export const fetchAiEnablement = (
	jiraProduct: LicensedJiraProduct,
	version = '1',
): Promise<AiEnablementResponse> => {
	const url = fetchAiEnablementUrl(jiraProduct, version);
	return fetchJson(url, {
		method: 'GET',
	});
};

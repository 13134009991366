import React, { useMemo } from 'react';
import ChartBarIcon from '@atlaskit/icon/core/chart-bar';
import { useIntl } from '@atlassian/jira-intl';
import { ASSETS_REPORTS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-id.tsx';
import { MENU_ID_ASSETS_REPORTS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import { getAssetsReportsUrl } from '@atlassian/jira-servicedesk-insight-urls/src/index.tsx';
import { useAssetsUIAnalytics } from '../../use-assets-ui-analytics/index.tsx';
import messages from './messages.tsx';

export const ReportsMenuItem = () => {
	const { formatMessage } = useIntl();
	const reportsLink = useMemo(() => getAssetsReportsUrl(), []);
	const { fireUINavigationItemAnalytics } = useAssetsUIAnalytics();

	return (
		<Nav4MenuLinkItem
			elemBefore={
				<ChartBarIcon
					label={formatMessage(messages.jsmAssetsReports)}
					color="currentColor"
					spacing="spacious"
				/>
			}
			href={reportsLink}
			menuId={MENU_ID_ASSETS_REPORTS}
			onClick={() => fireUINavigationItemAnalytics(ASSETS_REPORTS)}
		>
			{formatMessage(messages.jsmAssetsReports)}
		</Nav4MenuLinkItem>
	);
};

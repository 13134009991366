/**
 * @generated SignedSource<<36e48bbc800f6fcd05f9ae0af54e02a8>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4OverviewsContent$data = {
  readonly jira: {
    readonly jwmOverviews: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  };
  readonly " $fragmentType": "Nav4OverviewsContent";
};
export type Nav4OverviewsContent$key = {
  readonly " $data"?: Nav4OverviewsContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4OverviewsContent">;
};

import Nav4OverviewsContentQuery_graphql from './Nav4OverviewsContentQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "fragmentPathInResult": [],
      "operation": Nav4OverviewsContentQuery_graphql
    }
  },
  "name": "Nav4OverviewsContent",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "cloudId",
                "variableName": "cloudId"
              }
            ],
            "concreteType": "JiraWorkManagementGiraOverviewConnection",
            "kind": "LinkedField",
            "name": "jwmOverviews",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraWorkManagementGiraOverviewEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraWorkManagementGiraOverview",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "id"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "name"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      "action": "THROW",
      "path": "jira"
    }
  ],
  "type": "Query"
};

(node as any).hash = "e24d9b35e956ee89177e09fcc3033bc6";

export default node;

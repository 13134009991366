import { alphabeticalSort } from '@atlassian/jira-common-util-software-filters-sort/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	ADMIN_PAGE_MODULE,
	BACKLOG_ACTION_MODULE,
	BOARD_ACTION_MODULE,
	PERSONAL_SETTINGS_PAGE_MODULE,
	PROJECT_PAGE_MODULE,
	PROJECT_SETTINGS_PAGE_MODULE,
	GLOBAL_PAGE_MODULE,
	CUSTOM_FIELD_TYPE_MODULE,
	SERVICEDESK_QUEUE_PAGE_MODULE,
	SOURCE_NAVIGATION,
	SOURCE_FULL_PAGE_APP,
	SOURCE_ROUTER,
	SPRINT_ACTION_MODULE,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type {
	AdminPage,
	BacklogAction,
	BoardAction,
	GlobalPage,
	ProjectPage,
	ProjectSettingsPage,
	ServiceDeskQueuePage,
	AdminPageWithAccessNarrowing,
	ProjectPageWithAccessNarrowing,
	ProjectSettingsPageWithAccessNarrowing,
	GlobalPageWithAccessNarrowing,
	FullPage,
	CustomFieldType,
	ServiceDeskQueuePageWithAccessNarrowing,
	SprintAction,
	BoardViewActions,
	PersonalSettingsPage,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type {
	FullPageModule,
	FullPageModuleWithAccessNarrowing,
} from '@atlassian/jira-forge-ui-types/src/common/types/module.tsx';
import type { DataClassificationProps } from '@atlassian/jira-forge-ui-utils/src/types.tsx';
import { fetchModules } from '@atlassian/jira-forge-ui-utils/src/utils/fetch-modules-v2/index.tsx';
import fetchForgeModules from '@atlassian/jira-forge-ui-utils/src/utils/fetch-modules/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { getProjectKeyOrId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-or-id/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import type { CloudId, ActivationId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { TenantContext } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import {
	// eslint-disable-next-line jira/restricted/@atlassian/react-resource-router
	createResource,
	useResource,
	type RouterDataContext,
	type RouteResource,
	type Match,
} from '@atlassian/react-resource-router';
import { fetchForgeModule as fetchForgeModulesWithFilter } from '../../services/forge/index.tsx';
import { uniqueExtensionsFilter, adminPageFilter } from '../../utils/forge-filters/index.tsx';

export const validateDataClassification = (
	params: Match,
	moduleName: FullPageModule,
): DataClassificationProps => {
	const isModuleWithAccessNarrowing = [
		SERVICEDESK_QUEUE_PAGE_MODULE,
		PROJECT_PAGE_MODULE,
		PROJECT_SETTINGS_PAGE_MODULE,
	] // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		.includes(moduleName as FullPageModuleWithAccessNarrowing);

	const projectIdOrKey = getProjectKeyOrId(params);
	if (typeof projectIdOrKey === 'string' && projectIdOrKey !== '' && isModuleWithAccessNarrowing) {
		return { projectIdOrKey };
	}
	return null;
};

const createDataGetter =
	<TFullPage extends FullPage>(
		moduleName: FullPageModule,
		shouldReturnANExtensions = false,
		createContextIds?: ({
			cloudId,
			activationId,
		}: {
			cloudId: CloudId;
			activationId: ActivationId;
		}) => string[],
	) =>
	(
		route: RouterDataContext,
		{ tenantContext: { cloudId, activationId, locale } }: { tenantContext: TenantContext },
	) => {
		const { match: params } = route;
		const dataClassification = validateDataClassification(params, moduleName);

		const contextIds =
			(typeof createContextIds === 'function' && createContextIds({ cloudId, activationId })) ||
			undefined;

		return fetchForgeModulesWithFilter<TFullPage>({
			cloudId,
			moduleName,
			dataClassification,
			shouldReturnANExtensions,
			contextIds,
			locale,
		});
	};

const createContextIds = ({
	cloudId,
	activationId,
}: {
	cloudId: CloudId;
	activationId: ActivationId;
}) => [`ari:cloud:jira:${cloudId}:workspace/${activationId}`];

export const forgeAdminModuleV1Resource = createResource<AdminPageWithAccessNarrowing>({
	type: 'FORGE_ADMIN_MODULE',
	getKey: () => 'extensions',
	getData: createDataGetter(ADMIN_PAGE_MODULE, false, createContextIds),
	maxAge: Number.MAX_SAFE_INTEGER,
});

export const forgeAdminModuleV2Resource = createResource<AdminPage[]>({
	type: 'FORGE_ADMIN_MODULE',
	getKey: () => 'extensions',
	getData: (_, { tenantContext: { cloudId, isAnonymous } }) =>
		fetchModules({
			cloudId,
			isAnonymous,
			types: [ADMIN_PAGE_MODULE],
			context: {},
			includeHidden: true,
			source: SOURCE_FULL_PAGE_APP,
		}).then((modules) => normalizeModules(modules[ADMIN_PAGE_MODULE], adminPageFilter)),
	maxAge: Number.MAX_SAFE_INTEGER,
});

const forgeAdminModuleResource = resourceWithCondition2(
	() => fg('new_graphql_endpoint_for_fetching_forge_modules'),
	forgeAdminModuleV2Resource,
	forgeAdminModuleV1Resource,
);

export const getNavigationSidebarGlobalSettingsResources = () => [forgeAdminModuleResource];

export const forgeProjectModuleV1Resource: ReturnType<
	typeof createResource<ProjectPageWithAccessNarrowing>
> = createResource<ProjectPageWithAccessNarrowing>({
	type: 'FORGE_PROJECT_MODULE',
	getKey: (routeContext) => {
		const projectKeyOrId = getProjectKeyOrId(routeContext.match);
		return `extensions-${projectKeyOrId}`;
	},
	getData: createDataGetter(PROJECT_PAGE_MODULE, true, createContextIds),
	maxAge: Number.MAX_SAFE_INTEGER,
});

export const forgeProjectModuleV2Resource = createResource<ProjectPage[]>({
	type: 'FORGE_PROJECT_MODULE',
	getKey: (routeContext) => {
		const projectKeyOrId = getProjectKeyOrId(routeContext.match);
		return `extensions-${projectKeyOrId}`;
	},
	getData: (routeContext, { tenantContext: { cloudId, isAnonymous } }) =>
		fetchModules({
			cloudId,
			isAnonymous,
			types: [PROJECT_PAGE_MODULE],
			context: { projectKey: getProjectKeyOrId(routeContext.match) },
			includeHidden: true,
			source: SOURCE_FULL_PAGE_APP,
		}).then((modules) => normalizeModules(modules[PROJECT_PAGE_MODULE], uniqueExtensionsFilter)),
	maxAge: Number.MAX_SAFE_INTEGER,
});

export const forgeProjectModuleResource = resourceWithCondition2(
	() => fg('new_graphql_endpoint_for_fetching_forge_modules'),
	forgeProjectModuleV2Resource,
	forgeProjectModuleV1Resource,
);

export const forgeProjectModuleResourceOnNav3Only = resourceWithCondition2(
	() => !(getWillShowNav4() && fg('perf-push-big-components-nav-remove-resources')),
	forgeProjectModuleResource,
);

export const forgeBacklogModuleV2Resource = createResource<BacklogAction[]>({
	type: 'FORGE_BACKLOG_MODULE',
	getKey: (routeContext) => {
		const projectKeyOrId = getProjectKeyOrId(routeContext.match);
		return `extensions-${projectKeyOrId}`;
	},
	getData: (routeContext, { tenantContext: { cloudId, isAnonymous } }) =>
		fetchModules({
			cloudId,
			isAnonymous,
			types: [BACKLOG_ACTION_MODULE],
			context: { projectKey: getProjectKeyOrId(routeContext.match) },
			includeHidden: false,
			source: SOURCE_ROUTER,
		}).then((modules) => normalizeModules(modules[BACKLOG_ACTION_MODULE], uniqueExtensionsFilter)),
	maxAge: Number.MAX_SAFE_INTEGER,
});

export const forgeBacklogModuleResource = resourceWithCondition2(
	() => fg('forge-ui-project-web-items'),
	forgeBacklogModuleV2Resource,
);

export const forgeBoardModuleV2Resource = createResource<BoardAction[]>({
	type: 'FORGE_BOARD_MODULE',
	getKey: (routeContext) => {
		const projectKeyOrId = getProjectKeyOrId(routeContext.match);
		return `extensions-${projectKeyOrId}`;
	},
	getData: (routeContext, { tenantContext: { cloudId, isAnonymous } }) =>
		fetchModules({
			cloudId,
			isAnonymous,
			types: [BOARD_ACTION_MODULE],
			context: { projectKey: getProjectKeyOrId(routeContext.match) },
			includeHidden: false,
			source: SOURCE_ROUTER,
		}).then((modules) => normalizeModules(modules[BOARD_ACTION_MODULE], uniqueExtensionsFilter)),
	maxAge: Number.MAX_SAFE_INTEGER,
});

export const forgeBoardModuleResource = resourceWithCondition2(
	() => fg('forge-ui-project-web-items'),
	forgeBoardModuleV2Resource,
);

export const forgeSprintModuleV2Resource = createResource<SprintAction[]>({
	type: 'FORGE_SPRINT_MODULE',
	getKey: (routeContext) => {
		const projectKeyOrId = getProjectKeyOrId(routeContext.match);
		return `extensions-${projectKeyOrId}`;
	},
	getData: (routeContext, { tenantContext: { cloudId, isAnonymous } }) =>
		fetchModules({
			cloudId,
			isAnonymous,
			types: [SPRINT_ACTION_MODULE],
			context: { projectKey: getProjectKeyOrId(routeContext.match) },
			includeHidden: false,
			source: SOURCE_ROUTER,
		}).then((modules) => normalizeModules(modules[SPRINT_ACTION_MODULE], uniqueExtensionsFilter)),
	maxAge: Number.MAX_SAFE_INTEGER,
});

export const forgeSprintModuleResource = resourceWithCondition2(
	() => fg('forge-ui-project-web-items'),
	forgeSprintModuleV2Resource,
);

export const forgePersonalSettingsPageModuleV2Resource = createResource<PersonalSettingsPage[]>({
	type: 'FORGE_PERSONAL_SETTINGS_MODULE',
	getKey: () => 'extensions',
	getData: (_, { tenantContext: { cloudId, isAnonymous } }) =>
		fetchModules({
			cloudId,
			isAnonymous,
			types: [PERSONAL_SETTINGS_PAGE_MODULE],
			context: {},
			includeHidden: false,
			source: SOURCE_FULL_PAGE_APP,
		}).then((modules) =>
			normalizeModules(modules[PERSONAL_SETTINGS_PAGE_MODULE], uniqueExtensionsFilter),
		),
	maxAge: Number.MAX_SAFE_INTEGER,
});

export const forgePersonalSettingsPageModuleResource = resourceWithCondition2(
	() => fg('forge-ui-project-web-items'),
	forgePersonalSettingsPageModuleV2Resource,
);

export const getNavigationForgePersonalSettingsPageResources = () => [
	forgePersonalSettingsPageModuleResource,
];

export const forgeProjectSettingsModuleV1Resource: ReturnType<
	typeof createResource<ProjectSettingsPageWithAccessNarrowing>
> = createResource<ProjectSettingsPageWithAccessNarrowing>({
	type: 'FORGE_PROJECT_SETTINGS_MODULE',
	getKey: (routeContext) => {
		const projectKeyOrId = getProjectKeyOrId(routeContext.match);
		return `extensions-${projectKeyOrId}`;
	},
	getData: createDataGetter(PROJECT_SETTINGS_PAGE_MODULE, true, createContextIds),
	maxAge: Number.MAX_SAFE_INTEGER,
});

export const forgeProjectSettingsModuleV2Resource = createResource<ProjectSettingsPage[]>({
	type: 'FORGE_PROJECT_SETTINGS_MODULE',
	getKey: (routeContext) => {
		const projectKeyOrId = getProjectKeyOrId(routeContext.match);
		return `extensions-${projectKeyOrId}`;
	},
	getData: (routeContext, { tenantContext: { cloudId, isAnonymous } }) =>
		fetchModules({
			cloudId,
			isAnonymous,
			types: [PROJECT_SETTINGS_PAGE_MODULE],
			context: { projectKey: getProjectKeyOrId(routeContext.match) },
			includeHidden: true,
			source: SOURCE_FULL_PAGE_APP,
		}).then((modules) =>
			normalizeModules(modules[PROJECT_SETTINGS_PAGE_MODULE], uniqueExtensionsFilter),
		),
	maxAge: Number.MAX_SAFE_INTEGER,
});

export const forgeProjectSettingsModuleResource = resourceWithCondition2(
	() => fg('new_graphql_endpoint_for_fetching_forge_modules'),
	forgeProjectSettingsModuleV2Resource,
	forgeProjectSettingsModuleV1Resource,
);

export const useForgeProjectSettingsModuleResource = () =>
	useResource(forgeProjectSettingsModuleResource);

export const forgeGlobalModuleV1Resource = createResource<GlobalPageWithAccessNarrowing>({
	type: 'FORGE_GLOBAL_MODULE',
	getKey: () => 'extensions',
	getData: createDataGetter(GLOBAL_PAGE_MODULE, false, createContextIds),
	maxAge: Number.MAX_SAFE_INTEGER,
});

export const forgeGlobalModuleV2Resource = createResource<GlobalPage[]>({
	type: 'FORGE_GLOBAL_MODULE',
	getKey: () => 'extensions',
	getData: (_, { tenantContext: { cloudId, isAnonymous } }) =>
		fetchModules({
			cloudId,
			isAnonymous,
			types: [GLOBAL_PAGE_MODULE],
			context: {},
			includeHidden: true,
			source: SOURCE_FULL_PAGE_APP,
		}).then((modules) => normalizeModules(modules[GLOBAL_PAGE_MODULE], uniqueExtensionsFilter)),
	maxAge: Number.MAX_SAFE_INTEGER,
});

const forgeGlobalModuleResource = resourceWithCondition2(
	() => fg('new_graphql_endpoint_for_fetching_forge_modules'),
	forgeGlobalModuleV2Resource,
	forgeGlobalModuleV1Resource,
);

export const getNavigationForgeGlobalPageResources = () => [forgeGlobalModuleResource];

export const forgeServiceDeskQueueModuleV1Resource: ReturnType<
	typeof createResource<ServiceDeskQueuePageWithAccessNarrowing>
> = createResource<ServiceDeskQueuePageWithAccessNarrowing>({
	type: 'FORGE_SERVICEDESK_QUEUE_MODULE',
	getKey: (routeContext) => {
		const projectKeyOrId = getProjectKeyOrId(routeContext.match);
		return `extensions-${projectKeyOrId}`;
	},
	getData: createDataGetter(SERVICEDESK_QUEUE_PAGE_MODULE, true, createContextIds),
	maxAge: Number.MAX_SAFE_INTEGER,
});

export const forgeServiceDeskQueueModuleV2Resource = createResource<ServiceDeskQueuePage[]>({
	type: 'FORGE_SERVICEDESK_QUEUE_MODULE',
	getKey: (routeContext) => {
		const projectKeyOrId = getProjectKeyOrId(routeContext.match);
		return `extensions-${projectKeyOrId}`;
	},
	getData: (routeContext, { tenantContext: { cloudId, isAnonymous } }) =>
		fetchModules({
			cloudId,
			isAnonymous,
			types: [SERVICEDESK_QUEUE_PAGE_MODULE],
			context: { projectKey: getProjectKeyOrId(routeContext.match) },
			includeHidden: true,
			source: SOURCE_FULL_PAGE_APP,
		}).then((modules) =>
			normalizeModules(modules[SERVICEDESK_QUEUE_PAGE_MODULE], uniqueExtensionsFilter),
		),
	maxAge: Number.MAX_SAFE_INTEGER,
});

export const forgeServiceDeskQueueModuleResource = resourceWithCondition2(
	() => fg('new_graphql_endpoint_for_fetching_forge_modules'),
	forgeServiceDeskQueueModuleV2Resource,
	forgeServiceDeskQueueModuleV1Resource,
);

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const forgeCustomFieldTypeResource = createResource<CustomFieldType[]>({
	type: 'FORGE_CUSTOM_FIELD_TYPE_MODULE',
	getKey: () => 'extensions',
	getData: (_route, { tenantContext: { cloudId, isAnonymous, activationId, locale } }) =>
		fg('new_graphql_endpoint_for_fetching_forge_modules')
			? fetchModules({
					cloudId,
					isAnonymous,
					types: [CUSTOM_FIELD_TYPE_MODULE],
					context: {},
					includeHidden: false,
					source: SOURCE_NAVIGATION,
				}).then((modules) => modules[CUSTOM_FIELD_TYPE_MODULE])
			: fetchForgeModules(
					cloudId,
					[CUSTOM_FIELD_TYPE_MODULE],
					null,
					undefined,
					SOURCE_NAVIGATION,
					createContextIds({ cloudId, activationId }),
					locale,
				) // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					.then((modules) => modules.extensions.jiraCustomFieldType as CustomFieldType[]),
	maxAge: Number.MAX_SAFE_INTEGER,
}) as RouteResource<CustomFieldType[]>;

/**
 * Makes sure modules are filtered out depending on the predicate and sorted alphabetically by title.
 */
function normalizeModules<T extends FullPage | BoardViewActions>(
	modules: T[],
	filter: (modules: T[]) => T[],
) {
	return filter(modules).sort((a, b) => alphabeticalSort(a.properties.title, b.properties.title));
}

/**
 * @generated SignedSource<<ecf8b34f32f1f45836b4c83f504cc073>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type JswProjectMenu$data = {
  readonly projectStyle: JiraProjectStyle | null | undefined;
  readonly softwareBoardCount: AGG$Long | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"JswExpandableProject" | "JswNonExpandableProject">;
  readonly " $fragmentType": "JswProjectMenu";
};
export type JswProjectMenu$key = {
  readonly " $data"?: JswProjectMenu$data;
  readonly " $fragmentSpreads": FragmentRefs<"JswProjectMenu">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "JswProjectMenu",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "softwareBoardCount"
    },
    {
      "kind": "ScalarField",
      "name": "projectStyle"
    },
    {
      "kind": "FragmentSpread",
      "name": "JswNonExpandableProject"
    },
    {
      "kind": "FragmentSpread",
      "name": "JswExpandableProject"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "7cc91a753899861d32e66014f2049a36";

export default node;

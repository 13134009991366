import React, { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { FIXED_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { LEVEL_ONE } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import {
	MENU_ID_PLANS,
	MENU_ID_PLANS_VIEW_ALL,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { ViewAllEntityMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/view-all-entity-menu-item/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics';
import { plansDirectoryRoute } from '@atlassian/jira-router-routes-directories-routes/src/plansDirectoryRoute.tsx';
import { MenuList, MenuListItem, MenuSection } from '@atlassian/navigation-system';
import messages from './messages.tsx';

const VIEW_ALL_PLANS_HREF = plansDirectoryRoute.path;

export const Nav4PlansFooter = () => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const viewAllPlansClickHandler = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'clicked',
			actionSubject: 'navigationItem',
			actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_PLANS],
			attributes: {
				level: LEVEL_ONE,
				componentType: FIXED_ITEM,
				itemId: 'viewAllPlans',
			},
		});
	}, [createAnalyticsEvent]);

	return (
		<MenuListItem>
			<MenuSection>
				<MenuList>
					<ViewAllEntityMenuItem
						href={VIEW_ALL_PLANS_HREF}
						menuId={MENU_ID_PLANS_VIEW_ALL}
						onClick={viewAllPlansClickHandler}
					>
						{formatMessage(messages.viewAllPlans)}
					</ViewAllEntityMenuItem>
				</MenuList>
			</MenuSection>
		</MenuListItem>
	);
};

/**
 * @generated SignedSource<<68447f3a18999ab923f002acb0431127>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type OperationsMenuForLanding$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AlertsMenuItemContent">;
  readonly " $fragmentType": "OperationsMenuForLanding";
};
export type OperationsMenuForLanding$key = {
  readonly " $data"?: OperationsMenuForLanding$data;
  readonly " $fragmentSpreads": FragmentRefs<"OperationsMenuForLanding">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "OperationsMenuForLanding",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "AlertsMenuItemContent"
    }
  ],
  "type": "Query"
};

(node as any).hash = "00bc278faf903f3ef25d308d4771d2d6";

export default node;

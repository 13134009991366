import React, { forwardRef, type Ref, memo } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button, { type ButtonProps } from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import AddIcon from '@atlaskit/icon/utility/add';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const StyledButton = styled(Button)({
	// Atlaskit button has a border radius !important.
	// This is the only way to overwrite it to create a round button.
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	borderRadius: '50% !important',
	// the z-index allows the button to be above the collapse sidebar handle click area
	zIndex: 1,
});

type Props = {
	label: string;
	testId?: string;
	onClick: (e: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void;
} & Omit<ButtonProps, 'as'>;

const RoundCreateButton = forwardRef(
	({ label, onClick, testId, ...props }: Props, ref: Ref<HTMLElement>) => (
		<Tooltip content={label} hideTooltipOnClick>
			{isVisualRefreshEnabled() ? (
				<IconButton
					testId={testId}
					onClick={onClick}
					spacing="compact"
					appearance="default"
					shape="circle"
					label={label}
					icon={() => <AddIcon label="" color={token('color.text')} />}
				/>
			) : (
				<StyledButton
					{...props}
					ref={ref}
					testId={testId}
					spacing="compact"
					iconBefore={
						<EditorAddIcon label={label} primaryColor={token('color.text')} size="small" />
					}
					onClick={onClick}
				/>
			)}
		</Tooltip>
	),
);

export default memo(RoundCreateButton);

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createButtonLabel: {
		id: 'polaris-component-collection-create.form.create-button-label',
		defaultMessage: 'Create roadmap',
		description: 'Label for create button',
	},
	ideasCount: {
		id: 'polaris-component-collection-create.form.ideas-count',
		defaultMessage: 'Ideas in roadmap:',
		description: 'Ideas count of the roadmap based on projects selections and filtering',
	},
	ideasCountLimitMessage: {
		id: 'polaris-component-collection-create.form.footer.ideas-count-limit-message',
		defaultMessage:
			'The roadmap views have a limit of {ideasCountLimit} ideas. To display the views correctly, filter ideas out.',
		description: 'Content of the tooltip explaining the limit of ideas in a roadmap',
	},
});

import React, { type ReactNode, type CSSProperties } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Size, Props } from './types.tsx';

// NOTE: Based on values taken from Atlaskit source for Icon component
export const Sizes: Record<Size, number> = {
	small: 16,
	medium: 24,
	large: 32,
	xlarge: 48,
};

export const IconButton = ({
	children,
	onClick,
	ariaLabel,
	style,
	tabIndex,
}: {
	children: ReactNode;
	onClick: Props['onClick'];
	ariaLabel?: string;
	style?: CSSProperties;
	tabIndex?: number;
}) => (
	<button
		onClick={onClick}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		className={styles.button}
		aria-label={ariaLabel}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		style={style}
		tabIndex={tabIndex}
	>
		{children}
	</button>
);

IconButton.displayName = 'IconButton';

import React, { useEffect } from 'react';
import { di } from 'react-magnetic-di';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { userHomepageResource } from '@atlassian/jira-router-resources-user-homepage/src/services/index.tsx';
import { useResource, Redirect } from '@atlassian/react-resource-router';
import { LOG_LOCATION } from './constants.tsx';

const JiraBaseRedirectWrapper = () => {
	di(window);
	const { data, loading } = useResource(userHomepageResource);

	useEffect(() => {
		if (!loading && data == null) {
			log.safeInfoWithoutCustomerData(LOG_LOCATION, 'userHomepage data is null');
		}
	}, [loading, data]);

	if (loading) {
		return null;
	}

	// redirect to monolith to handle "/jira" route if homepageData is null (should not happen)
	if (data == null) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.location.replace('/jira');
		return null;
	}

	return <Redirect to={data.homepagePreference} />;
};

export default JiraBaseRedirectWrapper;

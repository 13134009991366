import { ROUTE_GROUPS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import {
	advancedRoadmapsPlanPageInitialChecksResource,
	advancedRoadmapsReduxStoreStateResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { softwarePlanIncrementEmbedRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-embed-routes/src/softwarePlanIncrementEmbedRoute.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { PlanIncrementEmbedPage, LazyPlanIncrementEmbed } from './ui/index.tsx';

export const arjIncrementEmbedRouteEntry: Route = createEntry(
	softwarePlanIncrementEmbedRoute,
	() => ({
		group: ROUTE_GROUPS_SOFTWARE,
		component: componentWithFG('smart_links_for_plans', PlanIncrementEmbedPage, ErrorPagesNotFound),

		layout: createLayout({
			isChromeless: true,
		}),

		resources: [
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
			themePreferenceResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		forPaint: [LazyPlanIncrementEmbed],
	}),
);

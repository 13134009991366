import { isFedRamp } from '@atlassian/atl-context';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_CLASSIC_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { AGGRESSIVE_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout/src/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import {
	forgeBoardModuleResource,
	forgeProjectModuleResource,
} from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';

import { staticSoftwareProjectHorizontalNavResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/software-horizontal-navigation/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';

import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';

import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';

import { classicBoardSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { getBoardDetailsResource } from '@atlassian/jira-router-resources-classic-projects/src/services/board-details/index.tsx';
import { getEditModelResource } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/index.tsx';
import {
	getRapidboardResources,
	getRapidBoardDataResource,
	shouldLoadUIFBoardResources,
} from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { nextBestTaskFeatureResource } from '@atlassian/jira-router-resources-next-best-task/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';

import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';

import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board/src/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { classicSoftwareBoardRoute } from '@atlassian/jira-router-routes-software-classic-board-routes/src/classicSoftwareBoardRoute.tsx';
import {
	UIFBoardSpa,
	RapidBoardSpaPage,
} from '@atlassian/jira-router-routes-user-board-entries/src/ui/async.tsx';
import { ClassicBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/classic-board/ClassicBoardSkeleton.tsx';
import { AsyncHorizontalNavJiraSoftware as AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { classicBoardCapability } from './common/constants.tsx';

/**
 * Ordinarily it would be inadvisable to evaluate something like this in
 * a static route, as this could lead to inconsistent behaviour between
 * the server and the browser.
 *
 * In this case it is necessary and safe because:
 * 1) There are two versions of the board that share the same route
 *    and we can only tell them apart at runtime. When the old version
 *    is eventually removed, this should be cleaned up.
 * 2) No requests for board data are made on the server, so even
 *    if the value is incorrect on the server it won't be utilised
 * */

export const classicSoftwareBoardRouteEntry = createEntry(classicSoftwareBoardRoute, () => ({
	group: ROUTE_GROUPS_CLASSIC_SOFTWARE,
	ufoName: 'classic-board',
	component: RapidBoardSpaPage,
	skeleton: ClassicBoardSkeleton,
	shouldOptOutConcurrentMode: fg('jira-concurrent-incremental-board'),

	layout: composeLayouts(
		genericProjectLayout,
		embeddedIssuePreloadLayoutBuilder([
			{ query: ['selectedIssue', 'view=detail'] },
			{ query: ['selectedIssue', 'modal=detail'] },
		]),
	),

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: classicBoardSidebars,
		horizontal: AsyncHorizontalNavJira,
		onlyShowHorizontalOnNav4: true,
	},

	resources: [
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResource,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		staticSoftwareProjectHorizontalNavResource,
		// end of resources expanded from getNavigationSidebarProjectResource
		...getRapidboardResources(),
		getRapidBoardDataResource(),
		resourceWithCondition2(shouldLoadUIFBoardResources, uifBoardResource),
		// Load the NBT resource if we opt-in UIF, not fedRamp is enabled
		resourceWithCondition2(
			() => shouldLoadUIFBoardResources() && !isFedRamp(),
			nextBestTaskFeatureResource,
		),
		getEditModelResource(),
		getBoardDetailsResource(),
		forgeBoardModuleResource,
	],

	afterPaint: [LazyIssueApp],
	forPaint: [LazyAtlassianNavigation, UIFBoardSpa],

	preloadOptions: {
		// TODO: TNK-1977 use 'software-cmp-board-early-entry' in the next PR
		earlyChunks: ['rapidboard-early-entry'],
	},

	meta: {
		capability: classicBoardCapability,
	},

	earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY,
}));

import { TEAM_MANAGED_BOARD } from '@atlassian/jira-capabilities/src/constants.tsx';
import { ROUTE_GROUPS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import {
	forgeProjectModuleResource,
	forgeBoardModuleResource,
} from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import {
	customFiltersResource,
	boardFilterRefinementResource,
} from '@atlassian/jira-router-resources-board-resource/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { softwareBoardEmbedRoute } from '@atlassian/jira-router-routes-software-board-routes/src/softwareBoardEmbedRoute.tsx';
import { conditionalBoardNBTOptInResource } from './common/conditionalBoardNBTOptInResource.tsx';
import { conditionalBoardRelayResource } from './common/conditionalBoardRelayResource.tsx';
import { conditionalBoardResource } from './common/conditionalBoardResource.tsx';
import { LazyBoardPage, SoftwareBoardComponent } from './ui/SoftwareBoardComponent.tsx';

export const softwareBoardEmbedRouteEntry = createEntry(softwareBoardEmbedRoute, () => ({
	group: ROUTE_GROUPS_SOFTWARE,
	shouldOptOutConcurrentMode: fg('jira-concurrent-incremental-board'),
	component: componentWithFG(
		'jira_board_smartlink_embed',
		SoftwareBoardComponent,
		ErrorPagesNotFound,
	),
	layout: createLayout({
		isChromeless: true,
	}),
	resources: [
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResource,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		// end of resources expanded from getNavigationSidebarProjectResource
		conditionalBoardNBTOptInResource,
		conditionalBoardResource,
		conditionalBoardRelayResource,
		boardFilterRefinementResource,
		customFiltersResource,
		themePreferenceResource,
		forgeBoardModuleResource,
	],
	afterPaint: [LazyIssueApp],
	forPaint: [LazyBoardPage],
	ufoName: 'next-gen-board-embed',
	meta: {
		capability: TEAM_MANAGED_BOARD,
	},
}));

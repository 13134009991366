import React, { useEffect, useMemo } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { SkeletonNavMenuItems } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/skeleton-nav-menu-items/main.tsx';
import { useExpandableEntryPointTrigger } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-expandable-entry-point-trigger/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { asyncAlertsContentViewQuery } from './alerts-content/entrypoint.tsx';
import { OperationsExpandableMenuItem } from './operations-expandable-menu-item/index.tsx';

export type OperationsMenuDefaultProps = {
	isExpanded: boolean;
};

export function OperationsMenuDefault(props: OperationsMenuDefaultProps) {
	const { isExpanded } = props;
	const cloudId = useCloudId();

	const entryPointParams = useMemo(() => ({ cloudId }), [cloudId]);
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		asyncAlertsContentViewQuery,
		entryPointParams,
	);
	const triggerRef = useExpandableEntryPointTrigger(entryPointActions);

	// If starting life expanded, make sure to load.
	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (isExpanded) {
			entryPointActions.load();
		}
	}, [entryPointActions, isExpanded]);

	return (
		<OperationsExpandableMenuItem
			isExpanded={props.isExpanded}
			onSystemToggle={(hasExpanded: boolean) => {
				if (hasExpanded) {
					entryPointActions.load();
				}
			}}
			onExpansionToggle={(hasExpanded: boolean) => {
				if (hasExpanded) {
					entryPointActions.load();
				}
			}}
			triggerRef={triggerRef}
			AlertsMenuItemContent={
				<JiraEntryPointContainer
					// Data stream, will subscribe to load updates.
					entryPointReferenceSubject={entryPointReferenceSubject}
					// Required props for error JSErrorBoundary.
					id="operations-menu-default"
					packageName="jiraNavigationAppsSidebarNav4Operations"
					// Optional props for error JSErrorBoundary.
					errorFallback="flag"
					teamName="platform-united"
					// Fallback for `Suspense`.
					fallback={<SkeletonNavMenuItems />}
					// Props for the root component.
					runtimeProps={entryPointParams}
				/>
			}
		/>
	);
}

/**
 * @generated SignedSource<<269bc8637c7fb324ac64325d1917d50e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4Plans$data = {
  readonly jira: {
    readonly advancedRoadmapsNavigation: {
      readonly hasCreateSamplePlanPermissions: boolean | null | undefined;
      readonly hasEditPermissions: boolean | null | undefined;
      readonly hasGlobalPlansAdminPermissions: boolean | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4PlansForLanding">;
  readonly " $fragmentType": "Nav4Plans";
};
export type Nav4Plans$key = {
  readonly " $data"?: Nav4Plans$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Plans">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "isPlansExpanded"
    }
  ],
  "kind": "Fragment",
  "name": "Nav4Plans",
  "selections": [
    {
      "condition": "isPlansExpanded",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "Nav4PlansForLanding"
        }
      ]
    },
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "cloudId",
              "variableName": "cloudId"
            }
          ],
          "concreteType": "JiraAdvancedRoadmapsNavigation",
          "kind": "LinkedField",
          "name": "advancedRoadmapsNavigation",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "hasEditPermissions"
            },
            {
              "kind": "ScalarField",
              "name": "hasCreateSamplePlanPermissions"
            },
            {
              "kind": "ScalarField",
              "name": "hasGlobalPlansAdminPermissions"
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "3aadd0d82198a5c14ad1e7da7343031b";

export default node;

/**
 * @generated SignedSource<<484a415ac082dc65665b073fa58626e0>>
 * @relayHash 3b19f92126722c87b4d4e885dbe2291e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 242138f8cf0105a8b5461ac09ab3622bcfa01c03bed42a6dfc990ee25a5f0d5a

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessAttachmentsQuery } from './src_jiraBusinessAttachmentsQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessAttachmentsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "242138f8cf0105a8b5461ac09ab3622bcfa01c03bed42a6dfc990ee25a5f0d5a",
    "metadata": {},
    "name": "src_jiraBusinessAttachmentsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	generalConfiguration: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-operations.operations-expandable-menu-item.action-buttons.general-configuration',
		defaultMessage: 'General configuration',
		description:
			'Used as the alt text for an icon button that when used navigates to create project',
	},
});

import React, { useContext } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { MENU_ID_ROADMAPS_VIEW_ALL } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import type { NavSubmenuFooter } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { ViewAllEntityMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/view-all-entity-menu-item/index.tsx';
import { MenuList, MenuListItem, MenuSection } from '@atlassian/navigation-system';
import { AnalyticContext } from '../../controllers/analytic-context/index.tsx';
import messages from './messages.tsx';

const VIEW_ALL_ROADMAPS_HREF = '/jira/polaris/roadmaps';

export const Footer: NavSubmenuFooter = ({ onClose }) => {
	const { formatMessage } = useIntl();

	const { fireUIAnalyticForFixMenuItem } = useContext(AnalyticContext);

	return (
		<MenuListItem>
			<MenuSection>
				<MenuList>
					<ViewAllEntityMenuItem
						href={VIEW_ALL_ROADMAPS_HREF}
						menuId={MENU_ID_ROADMAPS_VIEW_ALL}
						onClick={(e) => {
							fireUIAnalyticForFixMenuItem();
							onClose?.(e);
						}}
					>
						{formatMessage(messages.viewAllRoadmaps)}
					</ViewAllEntityMenuItem>
				</MenuList>
			</MenuSection>
		</MenuListItem>
	);
};

/**
 * @generated SignedSource<<cd53fccb802d557a4a32e350520478a3>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type JswExpandableProject$data = {
  readonly avatar: {
    readonly large: string;
  };
  readonly id: string;
  readonly isFavourite: boolean | null | undefined;
  readonly name: string;
  readonly projectId: string;
  readonly projectKey: string;
  readonly projectType: JiraProjectType | null | undefined;
  readonly webUrl: AGG$URL;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectActions">;
  readonly " $fragmentType": "JswExpandableProject";
};
export type JswExpandableProject$key = {
  readonly " $data"?: JswExpandableProject$data;
  readonly " $fragmentSpreads": FragmentRefs<"JswExpandableProject">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "JswExpandableProject",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "projectKey",
        "kind": "ScalarField",
        "name": "key"
      },
      "action": "THROW",
      "path": "projectKey"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "projectId"
      },
      "action": "THROW",
      "path": "projectId"
    },
    {
      "kind": "ScalarField",
      "name": "isFavourite"
    },
    {
      "kind": "ScalarField",
      "name": "projectType"
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraAvatar",
        "kind": "LinkedField",
        "name": "avatar",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "large"
            },
            "action": "THROW",
            "path": "avatar.large"
          }
        ]
      },
      "action": "THROW",
      "path": "avatar"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "name"
      },
      "action": "THROW",
      "path": "name"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "webUrl"
      },
      "action": "THROW",
      "path": "webUrl"
    },
    {
      "kind": "FragmentSpread",
      "name": "ProjectActions"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "97fa53b11ac066ee14270d6da1ac0254";

export default node;

/**
 * @generated SignedSource<<c2d3821f4f2e892a960ae6bc075260c3>>
 * @relayHash 9d09c4def2122fc686300e42c92c8cfc
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6ddbf49cfff3b1157dc3e1266f9526e79066930cfe3ce54c85171a6c7cdaa032

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessCalendarEmbedQuery } from './src_jiraBusinessCalendarEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessCalendarEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "6ddbf49cfff3b1157dc3e1266f9526e79066930cfe3ce54c85171a6c7cdaa032",
    "metadata": {},
    "name": "src_jiraBusinessCalendarEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

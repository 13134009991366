import type PeopleSettingsType from '@atlassian/jira-spa-apps-project-settings-people/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyPeopleSettings = lazyForPaint<typeof PeopleSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-people", jiraSpaEntry: "async-project-settings-people" */ '@atlassian/jira-spa-apps-project-settings-people'
		),
	),
);

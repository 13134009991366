import { projectSettingsCoreAccessRedirectRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreAccessRedirectRouteEntry.tsx';
import { projectSettingsCoreAccessRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreAccessRouteEntry.tsx';
import { projectSettingsCoreApprovalsRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreApprovalsRouteEntry.tsx';
import { projectSettingsCoreAppsFieldsRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreAppsFieldsRouteEntry.tsx';
import { projectSettingsCoreAppsRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreAppsRouteEntry.tsx';
import { projectSettingsCoreAutomationRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreAutomationRouteEntry.tsx';
import { projectSettingsCoreAutomationV2RouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreAutomationV2RouteEntry.tsx';
import { projectSettingsCoreDataClassificationsRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreDataClassificationsRouteEntry.tsx';
import { projectSettingsCoreDefaultRedirectRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreDefaultRedirectRouteEntry.tsx';
import { projectSettingsCoreDetailsRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreDetailsRouteEntry.tsx';
import { projectSettingsCoreFieldsRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreFieldsRouteEntry.tsx';
import { projectSettingsCoreForgeAppsRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreForgeAppsRouteEntry.tsx';
import { projectSettingsCoreIssueTypesRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreIssueTypesRouteEntry.tsx';
import { projectSettingsCoreIssueTypesWorkflowRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreIssueTypesWorkflowRouteEntry.tsx';
import { projectSettingsCoreNotificationsEmailRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreNotificationsEmailRouteEntry.tsx';
import { projectSettingsCoreNotificationsIssueLogsRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreNotificationsIssueLogsRouteEntry.tsx';
import { projectSettingsCoreNotificationsRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreNotificationsRouteEntry.tsx';
import { projectSettingsCoreProformaJiraFormBuilderRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreProformaJiraFormBuilderRouteEntry.tsx';
import { projectSettingsCoreProformaProjectFormsRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreProformaProjectFormsRouteEntry.tsx';
import { serviceDeskRouteEntries } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/index.tsx';
import { classicProjectSettingsDetailsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/classicProjectSettingsDetailsRouteEntry.tsx';
import { classicProjectSettingsFeaturesRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/classicProjectSettingsFeaturesRouteEntry.tsx';
import { classicProjectSettingsOpsgenieRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/classicProjectSettingsOpsgenieRouteEntry.tsx';
import { classicProjectSettingsSoftwareNotificationsIssueLogsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/classicProjectSettingsSoftwareNotificationsIssueLogsRouteEntry.tsx';
import { classicProjectSettingsSoftwareProformaEditFormsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/classicProjectSettingsSoftwareProformaEditFormsRouteEntry.tsx';
import { classicProjectSettingsSoftwareProformaFormsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/classicProjectSettingsSoftwareProformaFormsRouteEntry.tsx';
import { classicProjectSettingsSoftwareRedirectRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/classicProjectSettingsSoftwareRedirectRouteEntry.tsx';
import { classicProjectSettingsToolchainRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/classicProjectSettingsToolchainRouteEntry.tsx';
import { legacyProjectReleasesNoteConfigRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/legacyProjectReleasesNoteConfigRouteEntry.tsx';
import { legacyProjectReleasesNoteRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/legacyProjectReleasesNoteRouteEntry.tsx';
import { projectSettingsAutomationCRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsAutomationCRouteEntry.tsx';
import { projectSettingsAutomationRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsAutomationRouteEntry.tsx';
import { projectSettingsForgeAppsNextgenRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsForgeAppsNextgenRouteEntry.tsx';
import { projectSettingsForgeAppsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsForgeAppsRouteEntry.tsx';
import { projectSettingsOpsgenieRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsOpsgenieRouteEntry.tsx';
import { projectSettingsSoftwareAccessRedirectRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareAccessRedirectRouteEntry.tsx';
import { projectSettingsSoftwareAccessRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareAccessRouteEntry.tsx';
import { projectSettingsSoftwareAppsFieldsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareAppsFieldsRouteEntry.tsx';
import { projectSettingsSoftwareAppsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareAppsRouteEntry.tsx';
import { projectSettingsSoftwareAutomationRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareAutomationRouteEntry.tsx';
import { projectSettingsSoftwareBoardsCardRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareBoardsCardRouteEntry.tsx';
import { projectSettingsSoftwareBoardsCsmRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareBoardsCsmRouteEntry.tsx';
import { projectSettingsSoftwareBoardsCustomFiltersRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareBoardsCustomFiltersRouteEntry.tsx';
import { projectSettingsSoftwareBoardsRedirectRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareBoardsRedirectRouteEntry.tsx';
import { projectSettingsSoftwareBoardsRoadmapRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareBoardsRoadmapRouteEntry.tsx';
import { projectSettingsSoftwareBoardsTimelineRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareBoardsTimelineRouteEntry.tsx';
import { projectSettingsSoftwareDataClassificationsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareDataClassificationsRouteEntry.tsx';
import { projectSettingsSoftwareDetailsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareDetailsRouteEntry.tsx';
import { projectSettingsSoftwareFieldsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareFieldsRouteEntry.tsx';
import { projectSettingsSoftwareIssuetypesRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareIssuetypesRouteEntry.tsx';
import { projectSettingsSoftwareIssuetypesWorkflowRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareIssuetypesWorkflowRouteEntry.tsx';
import { projectSettingsSoftwareNextGenFeaturesRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareNextGenFeaturesRouteEntry.tsx';
import { projectSettingsSoftwareNotificationEmailCRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareNotificationEmailCRouteEntry.tsx';
import { projectSettingsSoftwareNotificationEmailRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareNotificationEmailRouteEntry.tsx';
import { projectSettingsSoftwareNotificationsIssueLogsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareNotificationsIssueLogsRouteEntry.tsx';
import { projectSettingsSoftwareNotificationsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareNotificationsRouteEntry.tsx';
import { projectSettingsSoftwareProformaJiraFormBuilderRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareProformaJiraFormBuilderRouteEntry.tsx';
import { projectSettingsSoftwareProformaProjectFormsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareProformaProjectFormsRouteEntry.tsx';
import { projectSettingsSoftwareRedirectRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareRedirectRouteEntry.tsx';
import { projectSettingsToolchainRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsToolchainRouteEntry.tsx';
import { softwareReleaseNotesRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/softwareReleaseNotesRouteEntry.tsx';
import { projectSettingsServicedeskClassicAppsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskClassicAppsRouteEntry.tsx';
import { projectSettingsServicedeskDetailsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskDetailsRouteEntry.tsx';
import { projectSettingsServicedeskFieldsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskFieldsRouteEntry.tsx';
import { projectSettingsServicedeskIssuetypesRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskIssuetypesRouteEntry.tsx';
import { projectSettingsServicedeskIssuetypesWorkflowRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskIssuetypesWorkflowRouteEntry.tsx';
import { projectSettingsServicedeskProformaJiraFormBuilderRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskProformaJiraFormBuilderRouteEntry.tsx';
import { projectSettingsServicedeskProformaProjectFormsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskProformaProjectFormsRouteEntry.tsx';
import { projectSettingsServicedeskAccessRedirectRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskAccessRedirectRouteEntry.tsx';
import { projectSettingsServicedeskAccessRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskAccessRouteEntry.tsx';
import { projectSettingsServicedeskAppsFieldsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskAppsFieldsRouteEntry.tsx';
import { projectSettingsServicedeskAppsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskAppsRouteEntry.tsx';
import { projectSettingsServicedeskAutomationRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskAutomationRouteEntry.tsx';
import { projectSettingsServicedeskDataClassificationsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskDataClassificationsRouteEntry.tsx';
import { projectSettingsServicedeskItsmFeaturesRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskItsmFeaturesRouteEntry.tsx';
import { projectSettingsServicedeskNotificationsInternalNotificationsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskNotificationsInternalNotificationsRouteEntry.tsx';
import { projectSettingsServicedeskInternalNotificationsNextGenRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskInternalNotificationsNextGenRouteEntry.tsx';
import { projectSettingsServicedeskNotificationsNotificationEmailRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskNotificationsNotificationEmailRouteEntry.tsx';
import { projectSettingsServicedeskNotificationsEmailRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskNotificationsEmailRouteEntry.tsx';
import { projectSettingsServicedeskNotificationsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskNotificationsRouteEntry.tsx';
import { projectSettingsServicedeskLanguageKeyRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskLanguageKeyRouteEntry.tsx';
import { projectSettingsServicedeskLanguageRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskLanguageRouteEntry.tsx';
import { projectSettingsServicedeskChannelsPermissionsNextGenRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskChannelsPermissionsNextGenRouteEntry.tsx';
import { projectSettingsServicedeskCustomerPermissionsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskCustomerPermissionsRouteEntry.tsx';
import { projectSettingsServicedeskChannelsEmailNextGenRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskChannelsEmailNextGenRouteEntry.tsx';
import { projectSettingsServicedeskEmailRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskEmailRouteEntry.tsx';
import { projectSettingsServicedeskPortalSettingsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskPortalSettingsRouteEntry.tsx';
import { projectSettingsServicedeskChannelsPortalRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskChannelsPortalRouteEntry.tsx';
import { projectSettingsServicedeskExternalLinksRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskExternalLinksRouteEntry.tsx';
import { projectSettingsServicedeskChannelsWidgetRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskChannelsWidgetRouteEntry.tsx';
import { projectSettingsServicedeskWidgetRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskWidgetRouteEntry.tsx';
import { projectSettingsServicedeskChangeManagementRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskChangeManagementRouteEntry.tsx';
import { projectSettingsServicedeskIncidentManagementRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskIncidentManagementRouteEntry.tsx';
import { projectSettingsServicedeskChannelsChatRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskChannelsChatRouteEntry.tsx';
import { projectSettingsServicedeskChatRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskChatRouteEntry.tsx';
import { projectSettingsServicedeskChannelsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskChannelsRouteEntry.tsx';
import { servicedeskSlaSettingsRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-sla-settings-entries/src/servicedeskSlaSettingsRouteEntry.tsx';
import { projectSettingsServicedeskFeedbackRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskFeedbackRouteEntry.tsx';
import { projectSettingsServicedeskForgeAppsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskForgeAppsRouteEntry.tsx';
import { projectSettingsServicedeskRedirectRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskRedirectRouteEntry.tsx';
import type { Route } from '@atlassian/react-resource-router';

/*
 *************************************************** ATTENTION ***************************************************
 * Take special care with any redirect components you render. For legacy routes they should redirect to the legacy URL,
 * and for product routes the should redirect to the product URL.
 */
export const projectSettingsRouteEntries: Route[] = [
	projectSettingsSoftwareIssuetypesRouteEntry,
	projectSettingsSoftwareIssuetypesWorkflowRouteEntry,
	projectSettingsSoftwareAppsFieldsRouteEntry,
	projectSettingsSoftwareAppsRouteEntry,
	projectSettingsSoftwareDetailsRouteEntry,
	projectSettingsSoftwareFieldsRouteEntry,
	projectSettingsSoftwareAccessRouteEntry,
	projectSettingsSoftwareAccessRedirectRouteEntry,
	projectSettingsSoftwareProformaProjectFormsRouteEntry,
	projectSettingsSoftwareProformaJiraFormBuilderRouteEntry,
	projectSettingsAutomationRouteEntry,
	projectSettingsSoftwareDataClassificationsRouteEntry,
	classicProjectSettingsDetailsRouteEntry,
	projectSettingsSoftwareNextGenFeaturesRouteEntry,
	projectSettingsSoftwareNotificationsRouteEntry,
	projectSettingsSoftwareNotificationsIssueLogsRouteEntry,
	projectSettingsOpsgenieRouteEntry,
	classicProjectSettingsOpsgenieRouteEntry,
	projectSettingsToolchainRouteEntry,
	classicProjectSettingsToolchainRouteEntry,
	classicProjectSettingsFeaturesRouteEntry,
	classicProjectSettingsSoftwareNotificationsIssueLogsRouteEntry,
	projectSettingsSoftwareNotificationEmailCRouteEntry,
	projectSettingsSoftwareNotificationEmailRouteEntry,
	legacyProjectReleasesNoteRouteEntry,
	legacyProjectReleasesNoteConfigRouteEntry,
	softwareReleaseNotesRouteEntry,
	projectSettingsSoftwareAutomationRouteEntry,
	classicProjectSettingsSoftwareProformaFormsRouteEntry,
	classicProjectSettingsSoftwareProformaEditFormsRouteEntry,
	projectSettingsSoftwareBoardsRedirectRouteEntry,
	projectSettingsSoftwareBoardsCsmRouteEntry,
	projectSettingsSoftwareBoardsCustomFiltersRouteEntry,
	projectSettingsSoftwareBoardsCardRouteEntry,
	projectSettingsSoftwareBoardsRoadmapRouteEntry,
	projectSettingsSoftwareBoardsTimelineRouteEntry,
	projectSettingsAutomationCRouteEntry,
	projectSettingsForgeAppsRouteEntry,
	projectSettingsForgeAppsNextgenRouteEntry,
	projectSettingsSoftwareRedirectRouteEntry,
	classicProjectSettingsSoftwareRedirectRouteEntry,
	projectSettingsServicedeskClassicAppsRouteEntry,
	projectSettingsServicedeskIssuetypesRouteEntry,
	projectSettingsServicedeskIssuetypesWorkflowRouteEntry,
	projectSettingsServicedeskFieldsRouteEntry,
	projectSettingsServicedeskAppsFieldsRouteEntry,
	projectSettingsServicedeskAppsRouteEntry,
	projectSettingsServicedeskDetailsRouteEntry,
	projectSettingsServicedeskAccessRouteEntry,
	projectSettingsServicedeskAccessRedirectRouteEntry,
	projectSettingsServicedeskProformaProjectFormsRouteEntry,
	projectSettingsServicedeskProformaJiraFormBuilderRouteEntry,
	projectSettingsServicedeskAutomationRouteEntry,
	projectSettingsServicedeskDataClassificationsRouteEntry,
	...serviceDeskRouteEntries,
	projectSettingsServicedeskItsmFeaturesRouteEntry,
	projectSettingsServicedeskNotificationsInternalNotificationsRouteEntry,
	projectSettingsServicedeskInternalNotificationsNextGenRouteEntry,
	projectSettingsServicedeskNotificationsNotificationEmailRouteEntry,
	projectSettingsServicedeskNotificationsEmailRouteEntry,
	projectSettingsServicedeskNotificationsRouteEntry,
	projectSettingsServicedeskLanguageKeyRouteEntry,
	projectSettingsServicedeskLanguageRouteEntry,
	projectSettingsServicedeskChannelsPermissionsNextGenRouteEntry,
	projectSettingsServicedeskCustomerPermissionsRouteEntry,
	projectSettingsServicedeskChannelsEmailNextGenRouteEntry,
	projectSettingsServicedeskEmailRouteEntry,
	projectSettingsServicedeskPortalSettingsRouteEntry,
	projectSettingsServicedeskChannelsPortalRouteEntry,
	projectSettingsServicedeskExternalLinksRouteEntry,
	projectSettingsServicedeskChannelsWidgetRouteEntry,
	projectSettingsServicedeskWidgetRouteEntry,
	projectSettingsServicedeskChangeManagementRouteEntry,
	projectSettingsServicedeskIncidentManagementRouteEntry,
	projectSettingsServicedeskChannelsChatRouteEntry,
	projectSettingsServicedeskChatRouteEntry,
	projectSettingsServicedeskChannelsRouteEntry,
	servicedeskSlaSettingsRouteEntry,
	projectSettingsServicedeskFeedbackRouteEntry,
	projectSettingsServicedeskForgeAppsRouteEntry,
	projectSettingsServicedeskRedirectRouteEntry,
	projectSettingsCoreIssueTypesRouteEntry,
	projectSettingsCoreFieldsRouteEntry,
	projectSettingsCoreIssueTypesWorkflowRouteEntry,
	projectSettingsCoreAppsFieldsRouteEntry,
	projectSettingsCoreAppsRouteEntry,
	projectSettingsCoreDetailsRouteEntry,
	projectSettingsCoreAccessRouteEntry,
	projectSettingsCoreAccessRedirectRouteEntry,
	projectSettingsCoreProformaProjectFormsRouteEntry,
	projectSettingsCoreProformaJiraFormBuilderRouteEntry,
	projectSettingsCoreAutomationV2RouteEntry,
	projectSettingsCoreDataClassificationsRouteEntry,
	projectSettingsCoreAutomationRouteEntry,
	projectSettingsCoreNotificationsRouteEntry,
	projectSettingsCoreNotificationsIssueLogsRouteEntry,
	projectSettingsCoreNotificationsEmailRouteEntry,
	projectSettingsCoreForgeAppsRouteEntry,
	projectSettingsCoreApprovalsRouteEntry,
	projectSettingsCoreDefaultRedirectRouteEntry,
];

import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import { createJpdExperience } from '../../utils/experience/main.tsx';

const globalFieldCreate = createJpdExperience(
	new UFOExperience('jpd.global-fields-administration.field-create', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const globalFieldDelete = createJpdExperience(
	new UFOExperience('jpd.global-fields-administration.field-delete', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const globalFieldAssociate = createJpdExperience(
	new UFOExperience('jpd.global-fields-administration.field-associate', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const globalFieldUnassociate = createJpdExperience(
	new UFOExperience('jpd.global-fields-administration.field-unassociate', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const globalFieldCheckViewsUsage = createJpdExperience(
	new UFOExperience('jpd.global-fields-administration.field-check-views-usage', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const globalFieldUpdate = createJpdExperience(
	new UFOExperience('jpd.global-fields-administration.field-update', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const globalFieldUpdateConfig = createJpdExperience(
	new UFOExperience('jpd.global-fields-administration.field-update-config', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const globalFieldCheckProjectUsage = createJpdExperience(
	new UFOExperience('jpd.global-fields-administration.field-check-project-usage', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadGlobalFields = createJpdExperience(
	new UFOExperience('jpd.global-fields-administration.load-fields', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadGlobalLabels = createJpdExperience(
	new UFOExperience('jpd.global-fields-administration.load-global-labels', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadJpdProjects = createJpdExperience(
	new UFOExperience('jpd.global-fields-administration.load-jpd-projects', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadProjectsWithField = createJpdExperience(
	new UFOExperience('jpd.global-fields-administration.load-projects-with-field', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const fieldAddOptions = createJpdExperience(
	new UFOExperience('jpd.global-fields-administration.field-add-options', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const fieldDeleteOption = createJpdExperience(
	new UFOExperience('jpd.global-fields-administration.field-delete-option', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const fieldRenameOption = createJpdExperience(
	new UFOExperience('jpd.global-fields-administration.field-rename-option', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const fieldMoveOptions = createJpdExperience(
	new UFOExperience('jpd.global-fields-administration.field-move-options', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const copyValues = createJpdExperience(
	new UFOExperience('jpd.global-fields-administration.copy-values', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	globalFieldCreate,
	globalFieldDelete,
	globalFieldAssociate,
	globalFieldUnassociate,
	globalFieldCheckViewsUsage,
	globalFieldUpdate,
	globalFieldUpdateConfig,
	globalFieldCheckProjectUsage,
	loadGlobalFields,
	loadGlobalLabels,
	loadJpdProjects,
	loadProjectsWithField,
	fieldAddOptions,
	fieldDeleteOption,
	fieldRenameOption,
	fieldMoveOptions,
	copyValues,
};

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	flagTitle: {
		id: 'portfolio-3-onboarding.onboarding.flag-finish.flag-title',
		defaultMessage: 'Happy planning!',
		description: 'The title for the onboarding flag.',
	},
	plansFlagContent: {
		id: 'portfolio-3-onboarding.onboarding.flag-finish.plans-flag-content',
		defaultMessage:
			'For more information on how to get started with Plans, check out our latest documentation.',
		description: 'The content for the onboarding flag',
	},
	flagClose: {
		id: 'portfolio-3-onboarding.onboarding.flag-finish.flag-close',
		defaultMessage: 'Close',
		description: 'The label for the onboarding flag Close button',
	},
	flagViewDocumentation: {
		id: 'portfolio-3-onboarding.onboarding.flag-finish.flag-view-documentation',
		defaultMessage: 'View documentation',
		description: 'The label for the onboarding flag "View documentation" button',
	},
});

import React from 'react';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/ScreenRedirect.tsx';
import type FiltersDirectoryType from '@atlassian/jira-spa-apps-directories-filters/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const FiltersDirectoryRedirect = () => <ScreenRedirect to="/jira/filters" />;

export const LazyFiltersDirectory = lazyForPaint<typeof FiltersDirectoryType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-filters-directory", jiraSpaEntry: "async-filters-directory" */ '@atlassian/jira-spa-apps-directories-filters'
		),
	),
);

export const FiltersDirectoryComponent = () => (
	<LazyPage Page={LazyFiltersDirectory} pageId="filters-directory" shouldShowSpinner={false} />
);

import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { performCmdbDeleteRequest } from '@atlassian/jira-servicedesk-cmdb-client/src/fetch.tsx';
import { getCmdbConfigReferenceTypeByIdUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/config.tsx';
import type { ReferenceType, ActionApi } from '../../common/types.tsx';

export const deleteReferenceType =
	(referenceType: ReferenceType): ActionApi =>
	async ({ setState, getState }, { workspaceId, createAnalyticsEvent }) => {
		const state = getState();

		setState({
			...state,
			referenceTypes: { ...state.referenceTypes, deleteLoading: true },
		});

		try {
			await performCmdbDeleteRequest(
				getCmdbConfigReferenceTypeByIdUrl(workspaceId, referenceType.id),
			);

			setState({
				...state,
				referenceTypes: {
					...state.referenceTypes,
					deleteLoading: false,
					data: state.referenceTypes.data
						? state.referenceTypes.data.filter(({ id }) => id !== referenceType.id)
						: undefined,
				},
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'deleteReferenceType succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				...state,
				referenceTypes: {
					...state.referenceTypes,
					deleteLoading: false,
					deleteError: error,
				},
			});

			fireErrorAnalytics({
				meta: {
					id: 'deleteReferenceType',
					packageName: 'jiraServicedeskInsightGlobalConfigurationStore',
					teamName: 'falcons',
				},
				error,
			});

			throw error;
		}
	};

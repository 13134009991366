export type ManagerKey = string;
export const toManagerKey = (managerKey: string): ManagerKey => managerKey;
export const fromManagerKey = (managerKey: ManagerKey): string => String(managerKey);

export type ItemManagerMethods = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	addItem?: (item?: any, ...rest: any[]) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	editItem?: (item?: any, ...rest: any[]) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	deleteItem?: (id: string, ...rest: any[]) => Promise<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	updateItems?: (items?: any, ...rest: any[]) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getItems?: (arg1: undefined) => any;
};
export type ItemManagers = Record<ManagerKey, Promise<ItemManagerMethods>>;
export type RegisterItemManager = (
	managerKey: ManagerKey,
	methods?: ItemManagerMethods | null | undefined,
) => void;
export type GetItemManager = (managerKey: ManagerKey) => Promise<ItemManagerMethods>;
export type Data = {
	getManager: (arg1: ManagerKey) => Promise<ItemManagerMethods>;
	registerManager: RegisterItemManager;
};

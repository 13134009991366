import React from 'react';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyAlertPolicies = lazyForPaint<any>(
	() =>
		import(
			/* webpackChunkName: "async-jira-eoc-policies", jiraSpaEntry: "async-jira-eoc-policies" */ '@atlassian/jira-eoc-policies'
		),
);

export const AlertPoliciesComponent = () => (
	<LazyPage
		Page={LazyAlertPolicies}
		pageId="async-jira-eoc-alert-policies"
		shouldShowSpinner={false}
	/>
);

import { useCallback, useRef } from 'react';
import { ConnectionHandler, type RecordProxy } from 'relay-runtime';
import { useSubscribeToFavorite } from '@atlassian/jira-favourite-change-provider/src/common/utils/favorite-pub-sub/index.tsx';
import type {
	FavoriteSubscriptionPayload,
	FavoriteMutationSubscriptionPayload,
} from '@atlassian/jira-favourite-change-provider/src/common/utils/favorite-pub-sub/types.tsx';
import type { ItemTypename } from '@atlassian/jira-favourite-change-provider/src/model/types.tsx';

/**
 * When these parameters are defined the favorited/unfavorited entity will be optimistically prepended/removed from
 * the connection of favorite items.
 */
export type OptimisticUpdateParams = {
	/**
	 * GraphQL typename of the entity being updated, e.g. `JiraProject`, `JiraSystemFilter`, `JiraCustomFilter`,
	 */
	entityTypename: ItemTypename | ItemTypename[];
	/**
	 * Relay store `id` of the connection items should be prepended to/removed from.
	 */
	favouriteConnectionId: string;
	/**
	 * Relay store `id` of the connection recent items belong to. We check to see if the entity exists in the recent
	 * connection so we can ensure required sidebar data is present in the store before modifying the favorite
	 * connection. If the entity is not found in the recent connection then we trigger a refetch to get the latest data.
	 */
	recentConnectionId: string;
	/**
	 * Function to trigger a refetch of favorite and recent connection data.
	 */
	refetch: ({ onComplete }: { onComplete: () => void }) => void;
};

// Exported for DI in tests
export const useUpdateFavoriteConnection = (
	favouriteConnectionId: string,
	recentConnectionId: string,
) =>
	useCallback(
		({ store, dataId, isFavourite }: FavoriteMutationSubscriptionPayload): boolean => {
			const node = store.get(dataId);
			const favorites = store.get(favouriteConnectionId);

			// Exit if we can't find the relevant data to optimistically update
			if (!node || !favorites) {
				return false;
			}

			if (isFavourite) {
				const recentEdges = store.get(recentConnectionId)?.getLinkedRecords('edges') ?? [];
				const existingEdge = recentEdges.find(
					(edge: RecordProxy) => edge.getLinkedRecord('node')?.getDataID() === dataId,
				);

				// Only optimistically insert a new favorite edge if it exists in the recent connection so we know it
				// has required fragment data.
				if (!existingEdge) {
					return false;
				}

				const edge = ConnectionHandler.createEdge(store, favorites, node, 'JiraFavouriteEdge');
				ConnectionHandler.insertEdgeBefore(favorites, edge);
			} else {
				ConnectionHandler.deleteNode(favorites, dataId);
			}
			return true;
		},
		[favouriteConnectionId, recentConnectionId],
	);

export const useSubscribeAndUpdateFavorites = ({
	entityTypename,
	favouriteConnectionId,
	recentConnectionId,
	refetch,
}: OptimisticUpdateParams) => {
	const updateFavoriteConnection = useUpdateFavoriteConnection(
		favouriteConnectionId,
		recentConnectionId,
	);

	const pendingRefetch = useRef(false); // Track in-flight refetch requests
	const trailingRefetch = useRef(false); // Track queued refetch requests
	const onRefetch = useCallback(() => {
		// If there is already an in-flight refetch then wait until the request is complete before fetching again
		// Relay will dedupe refetch calls with matching variables. Therefore, we need to wait until any pending request
		// has finished before executing the latest refetch
		if (pendingRefetch.current) {
			trailingRefetch.current = true;
			return;
		}
		pendingRefetch.current = true;
		trailingRefetch.current = false;
		refetch({
			onComplete: () => {
				pendingRefetch.current = false;
				// Recursively refetch if it has been queued while a request was in-flight.
				if (trailingRefetch.current) {
					// Wait for Relay to delete the cached request before refetching
					setTimeout(onRefetch, 0);
				}
			},
		});
	}, [refetch]);

	const subscriber = useCallback(
		(payload: FavoriteSubscriptionPayload) => {
			if (payload.type === 'MUTATION') {
				const success = updateFavoriteConnection(payload);
				// If we successfully updated the connection or it's an optimistic update, exit without further action
				if (success || payload.isOptimistic) {
					return;
				}
			}
			// If we can't update the record directly in the Relay store then perform a refetch
			onRefetch();
		},
		[onRefetch, updateFavoriteConnection],
	);
	useSubscribeToFavorite(entityTypename, subscriber);
};

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getServiceDeskNavigationResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { knowledgeHubArticleEmbedEditorRoute } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-routes/src/knowledgeHubArticleEmbedEditorRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { getKnowledgeHubResources } from './common/getKnowledgeHubResources.tsx';
import ServiceDeskKnowledgeArticleEmbedEditor, {
	LazyServiceDeskKnowledgeArticleEmbedEditor,
} from './ui/kb-article-embed-editor/index.tsx';

export const knowledgeHubArticleEmbedEditorRouteEntry: Route = createEntry(
	knowledgeHubArticleEmbedEditorRoute,
	() => ({
		group: ROUTE_GROUPS_SERVICEDESK,
		component: ServiceDeskKnowledgeArticleEmbedEditor,
		layout: serviceProjectLayout,
		resources: [...getServiceDeskNavigationResources(), ...getKnowledgeHubResources()],
		forPaint: [LazyAtlassianNavigation, LazyServiceDeskKnowledgeArticleEmbedEditor],
		perfMetricKey: 'service-management.knowledge.ep-editor',
	}),
);

/**
 * @generated SignedSource<<b09b00fb55c2e5ad7e1d306d7079b4cc>>
 * @relayHash 324bfe18f072a1ca3c1cf1d9034d084a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 013cf62d6837e0c985239a3d2175c1ef4b66da117b6d059ea93c8b36d45996d1

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraForgeEnvironmentType = "DEVELOPMENT" | "PRODUCTION" | "STAGING" | "%future added value";
export type JiraVisibilityControlMechanism = "AppAccessRules" | "DisplayConditions" | "%future added value";
export type JiraExtensionRenderingContextInput = {
  issueKey?: string | null | undefined;
  portalId?: string | null | undefined;
  projectKey?: string | null | undefined;
};
export type fetchModulesV2Query$variables = {
  cloudId: string;
  context: JiraExtensionRenderingContextInput;
  includeHidden: boolean;
  includeScopes: boolean;
  includeUserAccess: boolean;
  types: ReadonlyArray<string>;
};
export type fetchModulesV2Query$data = {
  readonly jira: {
    readonly forge: {
      readonly extensions: ReadonlyArray<{
        readonly appVersion: string;
        readonly consentUrl: string | null | undefined;
        readonly egress: ReadonlyArray<{
          readonly addresses: ReadonlyArray<string> | null | undefined;
          readonly type: string | null | undefined;
        } | null | undefined>;
        readonly environmentId: string;
        readonly environmentKey: string;
        readonly environmentType: JiraForgeEnvironmentType;
        readonly hiddenBy?: JiraVisibilityControlMechanism | null | undefined;
        readonly id: string;
        readonly installationId: string;
        readonly license: {
          readonly active: boolean;
          readonly billingPeriod: string | null | undefined;
          readonly capabilitySet: string | null | undefined;
          readonly ccpEntitlementId: string | null | undefined;
          readonly ccpEntitlementSlug: string | null | undefined;
          readonly isEvaluation: boolean | null | undefined;
          readonly subscriptionEndDate: AGG$DateTime | null | undefined;
          readonly supportEntitlementNumber: string | null | undefined;
          readonly trialEndDate: AGG$DateTime | null | undefined;
          readonly type: string | null | undefined;
        } | null | undefined;
        readonly properties: AGG$JSON;
        readonly scopes?: ReadonlyArray<string>;
        readonly type: string;
        readonly userAccess?: {
          readonly hasAccess: boolean;
        } | null | undefined;
      }> | null | undefined;
    };
  } | null | undefined;
};
export type fetchModulesV2Query = {
  response: fetchModulesV2Query$data;
  variables: fetchModulesV2Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "context"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeHidden"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeScopes"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeUserAccess"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "types"
},
v6 = {
  "kind": "ScalarField",
  "name": "type"
},
v7 = [
  {
    "concreteType": "JiraQuery",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "concreteType": "JiraForgeQuery",
        "kind": "LinkedField",
        "name": "forge",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "cloudId",
                "variableName": "cloudId"
              },
              {
                "kind": "Variable",
                "name": "context",
                "variableName": "context"
              },
              {
                "kind": "Variable",
                "name": "includeHidden",
                "variableName": "includeHidden"
              },
              {
                "kind": "Variable",
                "name": "types",
                "variableName": "types"
              }
            ],
            "concreteType": "JiraForgeExtension",
            "kind": "LinkedField",
            "name": "extensions",
            "plural": true,
            "selections": [
              {
                "condition": "includeHidden",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "hiddenBy"
                  }
                ]
              },
              {
                "condition": "includeScopes",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "scopes"
                  }
                ]
              },
              (v6/*: any*/),
              {
                "kind": "ScalarField",
                "name": "id"
              },
              {
                "kind": "ScalarField",
                "name": "environmentId"
              },
              {
                "kind": "ScalarField",
                "name": "environmentKey"
              },
              {
                "kind": "ScalarField",
                "name": "environmentType"
              },
              {
                "kind": "ScalarField",
                "name": "installationId"
              },
              {
                "kind": "ScalarField",
                "name": "appVersion"
              },
              {
                "kind": "ScalarField",
                "name": "consentUrl"
              },
              {
                "kind": "ScalarField",
                "name": "properties"
              },
              {
                "condition": "includeUserAccess",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraUserAppAccess",
                    "kind": "LinkedField",
                    "name": "userAccess",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "hasAccess"
                      }
                    ]
                  }
                ]
              },
              {
                "concreteType": "JiraForgeAppEgressDeclaration",
                "kind": "LinkedField",
                "name": "egress",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "addresses"
                  },
                  (v6/*: any*/)
                ]
              },
              {
                "concreteType": "JiraForgeExtensionLicense",
                "kind": "LinkedField",
                "name": "license",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "active"
                  },
                  (v6/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "supportEntitlementNumber"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "trialEndDate"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "subscriptionEndDate"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "isEvaluation"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "billingPeriod"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "ccpEntitlementId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "ccpEntitlementSlug"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "capabilitySet"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "name": "fetchModulesV2Query",
    "selections": (v7/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "fetchModulesV2Query",
    "selections": (v7/*: any*/)
  },
  "params": {
    "id": "013cf62d6837e0c985239a3d2175c1ef4b66da117b6d059ea93c8b36d45996d1",
    "metadata": {},
    "name": "fetchModulesV2Query",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "f1fe4da40ce9fa9017b516cd18afaefb";

export default node;

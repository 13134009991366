import React from 'react';
import Icon, { type CustomGlyphProps, type IconProps } from '@atlaskit/icon';

const CustomGlyph = (props: CustomGlyphProps) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
		<g id="icon &#60;icon&#62;">
			<path
				id="Vector"
				d="M19.2405 10.6209L18.9347 11.3057C18.734 11.7553 18.3679 12.2877 17.8832 12.8226L18.5909 13.5303C18.8838 13.8232 18.8838 14.2981 18.5909 14.591C18.298 14.8839 17.8231 14.8839 17.5302 14.591L16.7926 13.8534C16.6895 13.9372 16.5839 14.0197 16.4761 14.1004C15.998 14.4583 15.4587 14.795 14.8732 15.0696L15.3415 16.0062C15.5267 16.3767 15.3766 16.8272 15.0061 17.0125C14.6356 17.1977 14.1851 17.0475 13.9999 16.677L13.4486 15.5744C12.9819 15.6864 12.4971 15.75 11.9999 15.75C11.5026 15.75 11.0179 15.6864 10.5512 15.5744L9.99986 16.677C9.81462 17.0475 9.36412 17.1977 8.99363 17.0125C8.62315 16.8272 8.47298 16.3767 8.65822 16.0062L9.12653 15.0696C8.54102 14.795 8.00173 14.4583 7.5236 14.1004C7.41582 14.0197 7.31026 13.9372 7.20713 13.8534L6.46955 14.591C6.17666 14.8839 5.70179 14.8839 5.40889 14.591C5.116 14.2981 5.116 13.8232 5.40889 13.5303L6.11656 12.8227C5.63186 12.2877 5.26571 11.7553 5.06502 11.3057L4.75928 10.6209L6.12898 10.0094L6.43472 10.6943C6.66455 11.2091 7.37568 12.1158 8.42257 12.8996C9.45878 13.6754 10.7174 14.25 11.9999 14.25C13.2823 14.25 14.541 13.6754 15.5772 12.8996C16.6241 12.1158 17.3352 11.2091 17.565 10.6943L17.8708 10.0094L19.2405 10.6209Z"
				fill="currentColor"
			/>
		</g>
	</svg>
);

export const HideIcon = (props: Omit<IconProps, 'glyph'>) => (
	<Icon glyph={CustomGlyph} {...props} />
);

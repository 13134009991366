import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { AsyncDirectoryNav } from '@atlassian/jira-horizontal-nav-jsm-directory/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { servicedeskOrganizationsRoute } from '@atlassian/jira-router-routes-servicedesk-organizations-routes/src/servicedeskOrganizationsRoute.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { getOrganizationsRoutesCommon } from './common/getOrganizationsRoutesCommon.tsx';
import { ServiceDeskOrganizations, LazyServicedeskOrganizations } from './ui/index.tsx';

export const servicedeskOrganizationsRouteEntry: Route = createEntry(
	servicedeskOrganizationsRoute,
	() => ({
		...getOrganizationsRoutesCommon(),
		component: ServiceDeskOrganizations,
		forPaint: [LazyAtlassianNavigation, LazyServicedeskOrganizations],
		perfMetricKey: 'jsm-organizations-component-load',
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			horizontal: AsyncDirectoryNav,
			// TODO: Remove this during jira_nav4 cleanup
			onlyShowHorizontalOnNav4: true,
		},
		meta: {
			reporting: {
				id: APP_NAMES.ORGANIZATIONS,
				packageName: toPackageName(APP_NAMES.ORGANIZATIONS),
				teamName: 'boysenberry',
			},
		},
	}),
);

import { fg } from '@atlassian/jira-feature-gating';
import { JIRA_PRODUCT_DISCOVERY } from '@atlassian/jira-shared-types/src/application.tsx';
import {
	PREMIUM_EDITION,
	getEdition,
	toEdition,
} from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';

export const useIsPremiumEdition = () => {
	const appEditions = useAppEditions();

	if (fg('polaris_force-premium')) {
		return true;
	}

	const jpdEdition = getEdition(JIRA_PRODUCT_DISCOVERY, appEditions);

	// TODO: POL-10089 use entitlements instead of the edition when it will be available
	return jpdEdition === toEdition(PREMIUM_EDITION);
};

export const useIsViewPermissionsEnabled = () => {
	const isPremium = useIsPremiumEdition();

	return isPremium;
};

export const useIsCrossProjectViewsEnabled = () => {
	const isPremium = useIsPremiumEdition();

	return isPremium;
};

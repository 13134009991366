import type BoardsDirectoryType from '@atlassian/jira-spa-apps-directories-boards/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyBoardsDirectory = lazyForPaint<typeof BoardsDirectoryType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-boards-directory", jiraSpaEntry: "async-boards-directory" */ '@atlassian/jira-spa-apps-directories-boards'
		),
	),
);

import React, { type ReactNode, type ComponentType } from 'react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { messages } from './messages.tsx';

type SpotlightProps = typeof JiraSpotlight extends ComponentType<infer TProps> ? TProps : unknown;

interface Props {
	target: string;
	actionsBeforeElement: ReactNode;
	headingAfterElement: ReactNode;
	actions: (arg0: { learnMoreURL: string }) => SpotlightProps['actions'];
}

export const ShowAndTellSpotlight = ({
	target,
	actionsBeforeElement,
	headingAfterElement,
	actions,
}: Props) => {
	const { formatMessage } = useIntl();
	return (
		<JiraSpotlight
			target={target}
			targetRadius={0}
			targetBgColor={token('elevation.surface', colors.N0)}
			heading={formatMessage(messages.heading)}
			actionsBeforeElement={actionsBeforeElement}
			actions={actions({
				learnMoreURL:
					'https://support.atlassian.com/jira-software-cloud/docs/preconfigured-views-in-advanced-roadmaps/',
			})}
			headingAfterElement={headingAfterElement}
			messageId="portfolio-3-onboarding.ui.onboarding.spotlight-show-and-tell.jira-spotlight"
			messageType="transactional"
		>
			<FormattedMessage
				{...(fg('jira-issue-terminology-refresh-m3')
					? messages.descriptionIssueTermRefresh
					: messages.description)}
			/>
		</JiraSpotlight>
	);
};

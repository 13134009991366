import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
// eslint-disable-next-line jira/import-whitelist
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { createResource } from '@atlassian/react-resource-router';
import { PACKAGE_NAME } from '../constants.tsx';
import { ANALYTICS_ID, RESOURCE_KEY, RESOURCE_TYPE } from './constants.tsx';
import type {
	JPDRecommendationDismissedResourceData,
	JPDRecommendationDismissedResourceResponse,
} from './types.tsx';

export const jpdRecommendationDismissedResource = resourceWithCondition2(
	() => getWillShowNav4() && fg('hot-113350-move-resources-to-rrr'),
	createResource<JPDRecommendationDismissedResourceData>({
		type: RESOURCE_TYPE,
		getKey: () => RESOURCE_KEY,
		getData: async (_, { tenantContext }): Promise<JPDRecommendationDismissedResourceData> => {
			try {
				if (tenantContext.isAnonymous) {
					return {
						hasDismissed: true,
					};
				}
				const accountId = tenantContext.atlassianAccountId;
				const response = await performGetRequest<JPDRecommendationDismissedResourceResponse>(
					`/rest/api/latest/user/properties/JpdRecommendationDismissed?accountId=${accountId}`,
				);

				return {
					hasDismissed: response?.value ?? false,
				};
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				const initialValueNeverSet = error.statusCode === 404;
				if (initialValueNeverSet) {
					/**
					 * If the user has never set the preference, they have never dismissed.
					 */
					return {
						hasDismissed: false,
					};
				}

				fireErrorAnalytics({
					error,
					meta: {
						id: ANALYTICS_ID,
						packageName: PACKAGE_NAME,
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			}

			/* on error return true - this ensures we don't show the recommendation if we don't know if they have dismissed */
			return {
				hasDismissed: true,
			};
		},
		maxAge: Number.MAX_VALUE,
	}),
);

import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { projectSettingsServicedeskNotificationsNotificationEmailRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskNotificationsNotificationEmailRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router/src/common/types.tsx';
import EmailNotifications, {
	LazyProjectSettingsNotificationEmail,
} from './ui/notification-email/index.tsx';
import { ServiceDeskNotificationEmailNextGenRedirect } from './ui/service-desk-notification-email-next-gen-redirect/index.tsx';

export const projectSettingsServicedeskNotificationsNotificationEmailRouteEntry: Route =
	createEntry(projectSettingsServicedeskNotificationsNotificationEmailRoute, () => ({
		group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
		component: componentWithFG(
			'update_jsm_project_settings_pages_urls',
			ServiceDeskNotificationEmailNextGenRedirect,
			EmailNotifications,
		),
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
		forPaint: [LazyProjectSettingsNotificationEmail],
	}));

import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type NextGenBacklogType from '@atlassian/jira-spa-apps-next-gen-backlog/src/ui/backlog/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';

export const LazyBacklogPage = lazyForPaint<typeof NextGenBacklogType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-next-gen-backlog", jiraSpaEntry: "next-gen-backlog" */ '@atlassian/jira-spa-apps-next-gen-backlog'
		),
	),
);

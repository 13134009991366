export const CREATE_JWM_SAMPLE_PROJECT_KEY = 'create-jwm-sample-project';
export const SITE_USER_TRAIT_HAS_DISMISSED_EXPERIMENT =
	'jira_software_ui_moon_experiment_create_jwm_sample_experiment_project_dismissed_first';
export const SITE_USER_TRAIT_HAS_CREATED_SAMPLE_PROJECT =
	'jira_software_ui_moon_experiment_create_jwm_sample_experiment_project_created_first';
export const SITE_USER_TRAIT_HAS_CREATED_JWM_PROJECT = 'jira-core_business_project_created_first';

export const CREATE_JWM_SAMPLE_PROJECT_ENDPOINT = '/rest/internal/latest/jwm/sample-project';
export const CREATE_JWM_SAMPLE_PROJECT_ERROR_FLAG_ID = 'jwmSampleProjectCreateFailure';
export const PROJECTS_MENU_EXISTING_USERS = 'projects-menu-existing-users';

export const SITE_USER_TRAIT_VIEWED_EXPERIMENT_DROPDOWN_DATE =
	'jira_screen_viewed_jwmsampleprojectexperimentdropdown_first';

export const BRAIN_JSW_TO_JWM_IMPLICIT_SITE_BOOLEAN = 'brain_jsw_to_jwm_implicit_site_boolean';

/* eslint-disable @atlassian/relay/query-restriction */
/* eslint-disable @atlassian/relay/graphql-naming */

import { useCallback } from 'react';
import {
	graphql,
	useMutation,
	useFragment,
	commitLocalUpdate,
	useRelayEnvironment,
} from 'react-relay';
import type { RecordSourceProxy } from 'relay-runtime';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type {
	searchMode_useSearchMode_JQLBuilder$key,
	searchMode_useSearchMode_JQLBuilder$data,
} from '@atlassian/jira-relay/src/__generated__/searchMode_useSearchMode_JQLBuilder.graphql';
import type { searchModeJqlBuilder_updateJQLBuilderSearchModeMutation } from '@atlassian/jira-relay/src/__generated__/searchModeJqlBuilder_updateJQLBuilderSearchModeMutation.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import type { SearchMode, ServerSearchMode } from '../../common/types.tsx';
import { toServerSearchMode } from '../../common/utils.tsx';

const SEARCH_MODE_KEY_IN_RELAY_STORE = 'jqlBuilderSearchMode';

/**
 * Simple updater util function for setting the user's preferred JQL Search mode
 * in the Relay store when a mutation was either successful or failed - we update the
 * store regardless so that the search mode does not spontaneously change when
 * switching between the AI container search modes.
 */
const relayStoreUpdater = (
	store: RecordSourceProxy,
	userPreferencesData: searchMode_useSearchMode_JQLBuilder$data | null,
	searchMode: ServerSearchMode,
) => {
	if (typeof userPreferencesData?.__id === 'string') {
		const record = store.get(userPreferencesData.__id);
		record?.setValue(searchMode, SEARCH_MODE_KEY_IN_RELAY_STORE);
	}
};

export const useSearchModeMutation = (
	userPreferences: searchMode_useSearchMode_JQLBuilder$key | null,
) => {
	/*
	 * TODO
	 * Why is there a relay fragment in a hook?!
	 * Right now, we manually update the `jqlBuilderSearchMode` record in relay store.
	 * We update it manually because the field is not a Node type and hence Relay cannot
	 * automatically manage the store state for us.
	 * Instead, we need to manually grab the __id, find the record and update it with the
	 * mutation value so that the two are kept in sync.
	 * This is a temporary measure until the graphQL schema is updated.
	 * The fragment is in the hook for convenience, this hook is only called from
	 * one component: `src/packages/jql-builder/src/ui/index.tsx`
	 * In fact we can probably move this hook back into a UI component potentially.
	 */
	const userPreferencesData = useFragment<searchMode_useSearchMode_JQLBuilder$key>(
		graphql`
			fragment searchMode_useSearchMode_JQLBuilder on JiraUserPreferences {
				__id
			}
		`,
		userPreferences || null,
	);

	const environment = useRelayEnvironment();

	const cloudId = useCloudId();
	const isAnonymous = useIsAnonymous();

	const [commitMutation] = useMutation<searchModeJqlBuilder_updateJQLBuilderSearchModeMutation>(
		graphql`
			mutation searchModeJqlBuilder_updateJQLBuilderSearchModeMutation(
				$cloudId: ID!
				$searchMode: JiraJQLBuilderSearchMode
			) {
				jira {
					userPreferences(cloudId: $cloudId) @optIn(to: "JiraUserPreferences") {
						setJQLBuilderSearchMode(searchMode: $searchMode) {
							success
							errors {
								message
							}
							userSearchMode
						}
					}
				}
			}
		`,
	);

	return useCallback(
		(mode: SearchMode) => {
			if (!isAnonymous) {
				const serverSearchMode = toServerSearchMode(mode);
				commitMutation({
					variables: {
						cloudId,
						searchMode: toServerSearchMode(mode),
					},
					onError: (error) => {
						fireErrorAnalytics({
							sendToPrivacyUnsafeSplunk: true,
							error,
							meta: {
								id: 'mainSelectedJqlBuilderModeMutation',
								packageName: PACKAGE_NAME,
								teamName: TEAM_NAME,
							},
						});

						commitLocalUpdate(environment, (store) =>
							relayStoreUpdater(store, userPreferencesData, serverSearchMode),
						);
					},
					updater: (store) => relayStoreUpdater(store, userPreferencesData, serverSearchMode),
				});
			}
		},
		[cloudId, commitMutation, environment, isAnonymous, userPreferencesData],
	);
};

import { createContext, type Dispatch, type SetStateAction } from 'react';
import noop from 'lodash/noop';
import type { ConfigurableMenuId, JiraConfigurableNavigationItem } from '../types.tsx';

export const NavItemsContext = createContext<NavItemsContext>({
	navItems: [],
	visibleMenuIds: [],
	moreMenuIds: [],
});

export const PeekMenuIdContext = createContext<PeekMenuIdContext>({
	peekMenuId: undefined,
	setPeekMenuId: noop,
});

type NavItemsContext = {
	navItems: JiraConfigurableNavigationItem[];
	visibleMenuIds: ConfigurableMenuId[];
	moreMenuIds: ConfigurableMenuId[];
};

type PeekMenuIdContext = {
	peekMenuId: ConfigurableMenuId | undefined;
	setPeekMenuId: Dispatch<SetStateAction<ConfigurableMenuId | undefined>>;
};

import { type Clause, CLAUSE_TYPE_TERMINAL, OPERAND_TYPE_FUNCTION } from '@atlaskit/jql-ast';
import { AQL_FUNCTION_NAME, IQL_FUNCTION_NAME } from '../common/constants.tsx';

/**
 * utility to check if the cmdb field clause is compatible with Basic mode
 */
export const isCmdbClauseTooComplexForBasicMode = (clause: Clause): boolean =>
	clause.clauseType !== CLAUSE_TYPE_TERMINAL ||
	clause.operand?.operandType !== OPERAND_TYPE_FUNCTION ||
	![AQL_FUNCTION_NAME.toLowerCase(), IQL_FUNCTION_NAME.toLowerCase()].includes(
		clause.operand?.function.value.toLowerCase(),
	) ||
	clause.operand?.arguments.length !== 1;

import type {
	JiraCreateBoardInput,
	JiraBoardType,
	JiraCreateBoardFieldInput,
	JiraCreateBoardSource,
} from '@atlassian/jira-relay/src/__generated__/useBoardCreateMutation.graphql';
import { BOARD_SOURCE_FIELDS } from '../../common/constants.tsx';
import type { BoardCreateFormValues } from '../../common/types.tsx';

const formatFieldInput = (
	input: BoardCreateFormValues,
): Omit<JiraCreateBoardFieldInput, 'projects'> | null => {
	if (
		!input.sourceType ||
		input.sourceType === BOARD_SOURCE_FIELDS.SAVED_FILTER ||
		!input.sourceValue
	) {
		return null;
	}

	const { sourceType, sourceValue } = input;

	switch (sourceType) {
		case BOARD_SOURCE_FIELDS.TEAM:
			return { teams: [{ teamId: sourceValue.id }] };
		case BOARD_SOURCE_FIELDS.ISSUE_TYPES:
			return { issueTypes: sourceValue.map(({ value }) => ({ issueTypeId: value })) };
		case BOARD_SOURCE_FIELDS.LABELS:
			return { labels: sourceValue.map(({ value }) => ({ name: value })) };
		default: {
			const _exhaustiveCheck: never = sourceType;
			return null;
		}
	}
};

const getCreateBoardSource = (
	formValues: BoardCreateFormValues,
	locationProjectId: string,
): JiraCreateBoardSource => {
	if (formValues?.sourceType === BOARD_SOURCE_FIELDS.SAVED_FILTER) {
		return {
			savedFilterId: formValues.sourceValue?.value ? Number(formValues.sourceValue.value) : null,
		};
	}
	return {
		fieldInput: {
			projects: [{ projectId: locationProjectId }],
			...(formValues.sourceType && formValues.sourceValue && formatFieldInput(formValues)),
		},
	};
};

export const transformInput = ({
	boardType,
	locationProjectId,
	formValues,
}: {
	formValues: BoardCreateFormValues;
	locationProjectId: string;
	boardType: JiraBoardType;
}): JiraCreateBoardInput => ({
	createBoardSource: getCreateBoardSource(formValues, locationProjectId),
	location: {
		locationId: locationProjectId,
		locationType: 'PROJECT',
	},
	name: formValues.boardName,
	preset: boardType,
});

// remove when clean up fix_board_url_value_in_graphql_apis
export const buildBoardUrlOld = (
	projectKey: string | undefined,
	boardId: number | null | undefined,
	boardType: JiraBoardType | null | undefined,
) => {
	if (!projectKey || !boardId) {
		return null;
	}

	return `/jira/software/c/projects/${projectKey}/boards/${boardId}${boardType === 'SCRUM' ? '/backlog' : ''}`;
};

export const buildBoardUrl = (
	boardUrl: string | null | undefined,
	boardType: JiraBoardType | null | undefined,
) => {
	if (!boardUrl) {
		return null;
	}

	return `${boardUrl}${boardType === 'SCRUM' ? '/backlog' : ''}`;
};

export const redirectToBacklog = (backlogUrl: string) => {
	// This is a temporary fix for the redirection issue and will be addressed in BLU-5254
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.location.href = backlogUrl;
};

import { useMemo } from 'react';
import { getTrialDaysLeft } from '@atlassian/jira-navigation-apps-sidebar-edition-awareness-utils/src/utils.tsx';
import { SOFTWARE } from '@atlassian/jira-shared-types/src/application-key.tsx';
import { PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { LICENSE_STATE_ACTIVE } from '@atlassian/jira-shared-types/src/license-state.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useLicenseStates } from '@atlassian/jira-tenant-context-controller/src/components/license-states/index.tsx';
import { useLicensedProducts } from '@atlassian/jira-tenant-context-controller/src/components/licensed-products/index.tsx';
import { useProductEntitlementDetails } from '@atlassian/jira-tenant-context-controller/src/components/product-entitlement-details/index.tsx';

/**
 * Checks the eligibility of tenant for our experiment. We have two eligibility criteria:
 * 1. They only have JSW as an active licensed product
 * 2. They are on PREMIUM edition
 * 3. They are on a TRIAL
 * 4. The user is founding admin (checked in parent component)
 */
export function useIsEligibleForTrialSprintBoard() {
	const appEditions = useAppEditions();
	const licensedProducts = useLicensedProducts();
	const licenseStates = useLicenseStates();
	const productEntitlementDetails = useProductEntitlementDetails();
	const jswAppEdition = appEditions.software;

	// Only has JSW in licenses, assumed to be active given we are in onboarding flow
	const hasActiveJswLicense =
		licensedProducts[SOFTWARE] &&
		licenseStates.software === LICENSE_STATE_ACTIVE &&
		Object.keys(licensedProducts).length === 1;

	// JSW Premium
	const isOnPremiumJsw = jswAppEdition === PREMIUM_EDITION;

	// On a trial
	const entitlementDetails = productEntitlementDetails?.[SOFTWARE];
	const currentDate = useMemo(() => Date.now(), []);
	const trialDaysLeft = getTrialDaysLeft(currentDate, entitlementDetails?.trialEndTime) ?? 0;

	const isOnTrial = trialDaysLeft >= 0;

	return hasActiveJswLicense && isOnPremiumJsw && isOnTrial;
}

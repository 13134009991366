import { useMemo } from 'react';
import {
	shouldShowReturnToMobileBanner,
	shouldShowReturnToMobileBannerMobileMvp,
} from '@atlassian/jira-common-is-mobile-user-agent/src/index.tsx';
import { isMobileAndInMvpOrExistingUsersExperiment } from '@atlassian/jira-mobile-web/src/index.tsx';
import { createStore, createHook, type Action } from '@atlassian/react-sweet-state';
import { BANNER_NAME, mobileBannerHeight } from '../common/constants.tsx';

type State = {
	isEnabled: boolean;
};

const initialState: State = {
	isEnabled: shouldShowReturnToMobileBanner(),
};

const actions = {
	disable:
		(): Action<State> =>
		({ setState }) => {
			setState({ isEnabled: false });
		},
} as const;

const store = createStore({ initialState, actions });

export const useBannerReturnToMobileState = createHook(store);

export const useBannerReturnToMobile = () => {
	const [{ isEnabled: isEnabledOriginal }] = useBannerReturnToMobileState();

	const isEnabled = isMobileAndInMvpOrExistingUsersExperiment()
		? isEnabledOriginal && shouldShowReturnToMobileBannerMobileMvp()
		: isEnabledOriginal;

	return useMemo(
		() => ({
			isEnabled,
			name: BANNER_NAME,
			height: mobileBannerHeight,
		}),
		[isEnabled],
	);
};

import type { RecommendationType, RecommendationTypeFG } from '../common/types.tsx';
import { jpdRecommendation, jpdRecommendationFG } from './jpd-recommendation/index.tsx';
import {
	jwmSampleProjectRecommendation,
	jwmSampleProjectRecommendationFG,
} from './jwm-sample-project-recommendation/index.tsx';

const createRecommendationsList = () => {
	const recommendationsList: RecommendationType[] = [];

	recommendationsList.push(jpdRecommendation);
	recommendationsList.push(jwmSampleProjectRecommendation);

	// Add your own templates

	return recommendationsList;
};

const createRecommendationsListFG = () => {
	const recommendationsListFG: RecommendationTypeFG[] = [];

	recommendationsListFG.push(jpdRecommendationFG);
	recommendationsListFG.push(jwmSampleProjectRecommendationFG);

	// Add your own templates

	return recommendationsListFG;
};

export const recommendations: Array<RecommendationType> = createRecommendationsList();
export const recommendationsFG: Array<RecommendationTypeFG> = createRecommendationsListFG();

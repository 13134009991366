import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	teamManagedDescription: {
		id: 'project-type-tile.team-managed-description',
		defaultMessage: 'Team-managed',
		description: 'Description of project type for team managed projects',
	},
	companyManagedDescription: {
		id: 'project-type-tile.company-managed-description',
		defaultMessage: 'Company-managed',
		description: 'Description of project style for company managed projects',
	},
	projectTypeGroupAccessibleLabel: {
		id: 'project-type-tile.project-type-group-accessible-label',
		defaultMessage: 'Project type details',
		description:
			'Label provided to assistive technologies to give context to the content of the project type tile',
	},
	projectTypeDescriptionProductDiscovery: {
		id: 'project-type-tile.project-type-description-product-discovery',
		defaultMessage: 'Discovery project',
		description: 'Label for Product Discovery projects',
	},
	// delete on 'jira_nav4_beta_drop_2' cleanup
	projectTypeDescriptionJiraDash: {
		id: 'project-type-tile.project-type-description-jira-dash',
		defaultMessage: 'Jira - {projectTypeName}',
		description: 'Combined label for projects including the style and the type',
	},
	// delete on 'jira_nav4_beta_drop_2' cleanup
	projectTypeDescriptionJiraProductDiscovery: {
		id: 'project-type-tile.project-type-description-jira-product-discovery',
		defaultMessage: 'Jira Product Discovery',
		description: 'Label for Jira Product Discovery projects',
	},
	// delete on 'jira_nav4_beta_drop_2' cleanup
	projectTypeDescriptionServiceDesk: {
		id: 'project-type-tile.project-type-description-service-desk',
		defaultMessage: 'Jira Service Management',
		description: 'Label for Jira Service Management projects',
	},
	projectTypeDescriptionServiceProject: {
		id: 'project-type-tile.project-type-description-service-project',
		defaultMessage: 'Service project',
		description: 'Label for Jira Service Management projects',
	},
	projectTypeDescriptionBusinessProject: {
		id: 'project-type-tile.project-type-description-business-project',
		defaultMessage: 'Business project',
		description: 'Label for Jira Business projects',
	},
	projectTypeDescriptionSoftwareProject: {
		id: 'project-type-tile.project-type-description-software-project',
		defaultMessage: 'Software project',
		description: 'Label for Jira Software projects',
	},
});

import React, { useMemo } from 'react';
import { graphql, useRefetchableFragment } from 'react-relay';
import { MENU_ID_OVERVIEWS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { useSidebarNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-context/index.tsx';
import { useRefetchWhenIsSelected } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/controller/use-refetch-when-is-selected/index.tsx';
import type { Nav4OverviewsContent$key } from '@atlassian/jira-relay/src/__generated__/Nav4OverviewsContent.graphql';
import QUERY, {
	type Nav4OverviewsContentQuery,
} from '@atlassian/jira-relay/src/__generated__/Nav4OverviewsContentQuery.graphql.ts';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { MenuListItem, MenuList, MenuSection } from '@atlassian/navigation-system';
import { OverviewsEmptyState } from '../../common/ui/empty-state/index.tsx';
import { OverviewsMenuItem } from '../overviews-menu-item/OverviewsMenuItem.tsx';

type Nav4OverviewsContentProps = {
	queryRef: Nav4OverviewsContent$key;
};

export function Nav4OverviewsContent({ queryRef }: Nav4OverviewsContentProps) {
	const { isSelectedPath } = useSidebarNav4();
	const cloudId = useCloudId();

	const [
		{
			jira: { jwmOverviews },
		},
		refetch,
	] = useRefetchableFragment<Nav4OverviewsContentQuery, Nav4OverviewsContent$key>(
		graphql`
			fragment Nav4OverviewsContent on Query @refetchable(queryName: "Nav4OverviewsContentQuery") {
				jira @required(action: THROW) {
					jwmOverviews(cloudId: $cloudId) {
						edges {
							node {
								id
								name
							}
						}
					}
				}
			}
		`,
		queryRef,
	);

	useRefetchWhenIsSelected({
		cloudId,
		isSelectedPath,
		QUERY,
		refetch,
		menuId: MENU_ID_OVERVIEWS,
	});

	const items = useMemo(() => {
		const nodes = jwmOverviews?.edges?.filter(Boolean).map((edge) => edge.node) || [];

		const list =
			nodes
				.filter(Boolean)
				.map((node) =>
					node.name ? <OverviewsMenuItem key={node.id} id={node.id} name={node.name} /> : null,
				)
				.filter(Boolean) ?? [];

		return { list, hasItems: Boolean(list.length) };
	}, [jwmOverviews]);

	if (!items.hasItems) {
		return <OverviewsEmptyState />;
	}

	return (
		<>
			{items.hasItems && (
				<MenuListItem>
					<MenuSection>
						<MenuList>{items.list}</MenuList>
					</MenuSection>
				</MenuListItem>
			)}
		</>
	);
}

import React, { useContext, type ReactElement, useEffect, useMemo, useCallback } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { NAVIGATION_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import { SECTION_ITEM_APPS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_APPS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { NavItemsContext } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/controllers/context.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { Nav4ExpandableMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-expandable-menu-item/index.tsx';
import { SkeletonNavMenuItems } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/skeleton-nav-menu-items/main.tsx';
import { getTestId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-test-id/index.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import { useExpandableEntryPointTrigger } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-expandable-entry-point-trigger/index.tsx';
import { ExploreMoreApps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-apps/src/common/ui/explore-more-apps/ExploreMoreApps.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { ExpandableMenuItemContent, ExpandableMenuItemTrigger } from '@atlassian/navigation-system';
import { PACKAGE_NAME, TEAM_NAME } from '../../../common/constants.tsx';
import { createAttributes } from '../../../common/utils/get-attributes/index.tsx';
import { asyncNav4AppsContentViewQuery } from './async-content/entrypoint.tsx';

type Nav4AppsDefaultProps = {
	actionsOnHover: ReactElement;
	isExpanded: boolean;
	simulateLoading?: boolean; // For testing only
};

export function Nav4AppsDefault(props: Nav4AppsDefaultProps) {
	const { actionsOnHover, simulateLoading = false, isExpanded = simulateLoading } = props;
	const cloudId = useCloudId();
	const entryPointParams = useMemo(() => ({ cloudId }), [cloudId]);
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		asyncNav4AppsContentViewQuery,
		entryPointParams,
	);
	const triggerRef = useExpandableEntryPointTrigger(entryPointActions);
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(L1_MENU_ID.APPS);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { moreMenuIds } = useContext(NavItemsContext);
	const getAttributes = useCallback(
		(error: Error) => createAttributes({ error, component: 'Nav4AppsDefault' }),
		[],
	);

	// If starting life expanded, make sure to load.
	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (isExpanded) {
			entryPointActions.load();
		}
	}, [entryPointActions, isExpanded]);

	const menuId = MENU_ID_APPS;

	return (
		<Nav4ExpandableMenuItem
			isExpanded={isExpanded}
			menuId={menuId}
			onSystemToggle={(hasExpanded: boolean) => {
				if (hasExpanded) {
					entryPointActions.load();
				}
			}}
			onExpansionToggle={(hasExpanded: boolean) => {
				if (hasExpanded) {
					entryPointActions.load();

					const event = createAnalyticsEvent({
						action: 'clicked',
						actionSubject: NAVIGATION_ITEM,
					});
					const isInMore = moreMenuIds.includes(L1_MENU_ID.APPS);
					fireUIAnalytics(event, SECTION_ITEM_APPS, { isInMore });
				}
			}}
		>
			<ExpandableMenuItemTrigger
				interactionName="nav4-sidebar-apps-default"
				actionsOnHover={actionsOnHover}
				// Prevent double-up of accessible name that occurs in icon+text situation.
				aria-label={formattedMessage}
				elemBefore={icon}
				ref={triggerRef}
				testId={getTestId(menuId)}
			>
				{formattedMessage}
			</ExpandableMenuItemTrigger>
			<ExpandableMenuItemContent>
				{simulateLoading ? (
					<SkeletonNavMenuItems />
				) : (
					<JiraEntryPointContainer
						// Data stream, will subscribe to load updates.
						entryPointReferenceSubject={entryPointReferenceSubject}
						// Required props for error JSErrorBoundary.
						id="nav4-sidebar-apps-default"
						errorAttributes={getAttributes}
						packageName={PACKAGE_NAME}
						// Optional props for error JSErrorBoundary.
						errorFallback="flag"
						teamName={TEAM_NAME}
						// Fallback for `Suspense`.
						fallback={<SkeletonNavMenuItems />}
						// Props for the root component.
						runtimeProps={entryPointParams}
					/>
				)}
				<ExploreMoreApps />
			</ExpandableMenuItemContent>
		</Nav4ExpandableMenuItem>
	);
}

import type NextGenSoftwareDeploymentsType from '@atlassian/jira-spa-apps-software-deployments/src/ui/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyDeployments = lazyForPaint<typeof NextGenSoftwareDeploymentsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-deployments", jiraSpaEntry: "async-deployments" */ '@atlassian/jira-spa-apps-software-deployments'
		),
	),
);

import React, { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { NAVIGATION_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import { FIXED_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { LEVEL_ONE } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { MENU_ID_FILTERS_VIEW_ALL } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { ViewAllEntityMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/view-all-entity-menu-item/index.tsx';
import { filtersDirectoryRoute } from '@atlassian/jira-router-routes-directories-routes/src/filtersDirectoryRoute.tsx';
import { MenuList, MenuListItem, MenuSection } from '@atlassian/navigation-system';
import { useFilterActionsAnalytics } from '../../utils/analytics/index.tsx';
import messages from './messages.tsx';

const VIEW_ALL_FILTERS_PATH = filtersDirectoryRoute.path;

export function Footer() {
	const { formatMessage } = useIntl();
	const sendFilterAnalytics = useFilterActionsAnalytics();
	const sendDefaultFilterDropdownAnalytics = useCallback(() => {
		sendFilterAnalytics(NAVIGATION_ITEM, {
			level: LEVEL_ONE,
			componentType: FIXED_ITEM,
			itemId: 'viewAllFilters',
		});
	}, [sendFilterAnalytics]);
	return (
		<MenuListItem>
			<MenuSection>
				<MenuList>
					<ViewAllEntityMenuItem
						onClick={sendDefaultFilterDropdownAnalytics}
						href={VIEW_ALL_FILTERS_PATH}
						menuId={MENU_ID_FILTERS_VIEW_ALL}
					>
						{formatMessage(messages.viewAllFilters)}
					</ViewAllEntityMenuItem>
				</MenuList>
			</MenuSection>
		</MenuListItem>
	);
}

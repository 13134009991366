import { useMemo } from 'react';
import {
	ROUTE_NAMES_RAPIDBOARD_BACKLOG,
	ROUTE_NAMES_RAPIDBOARD_USER_BACKLOG,
	ROUTE_NAMES_RAPIDBOARD_BACKLOG_EMBED,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';

export const useIsBacklogRoute = (): boolean => {
	const { name } = useCurrentRoute();

	return useMemo(
		() =>
			name === ROUTE_NAMES_RAPIDBOARD_BACKLOG ||
			name === ROUTE_NAMES_RAPIDBOARD_USER_BACKLOG ||
			name === ROUTE_NAMES_RAPIDBOARD_BACKLOG_EMBED,
		[name],
	);
};

import React from 'react';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { getTextSearchInputField } from '../../constants.tsx';
import { PickerTypes, type FieldToRender } from '../../types.tsx';
import AddFilterFallback from '../add-filter-fallback/index.tsx';
import ListItemChild from '../list-item-child/index.tsx';
import PickerFallback from '../picker-fallback/index.tsx';
import { StyledChild, StyledListItemChild } from '../styled.tsx';
import OrderByPickerLoading from './order-by-picker-loading/index.tsx';
import { TextSearchInputLoading } from './text-search-input-loading/index.tsx';

type Props = {
	fieldsToRender: FieldToRender[];
	optionalFieldsCount: number;
	/**
	 * Number of order by fields that will appear as a badge on the Order button
	 * has no effect if showOrderByPicker is falsey
	 */
	orderByFieldsCount?: number;
	shouldUseListMarkup?: boolean;
	hideTextSearchInput?: boolean;
	showOrderByPicker?: boolean;
};

export const JQLEditorLoading = ({
	fieldsToRender,
	optionalFieldsCount,
	orderByFieldsCount,
	shouldUseListMarkup,
	hideTextSearchInput,
	showOrderByPicker,
}: Props) => {
	const StyledListItemChildComponent =
		isVisualRefreshEnabled() && fg('visual-refresh_drop_3') ? ListItemChild : StyledListItemChild;
	const FieldWrapper = shouldUseListMarkup ? StyledListItemChildComponent : StyledChild;

	return (
		<>
			{fg('add_nin_press_interactions') && <UFOLoadHold name="jql-builder-basic-loading" />}
			{!hideTextSearchInput && (
				<FieldWrapper
					key={getTextSearchInputField()}
					// eslint-disable-next-line jira/integration/test-id-by-folder-structure
					data-testid="jql-builder-basic-refinement-fallback"
				>
					<TextSearchInputLoading />
				</FieldWrapper>
			)}

			{fieldsToRender.map((fieldToRender) => {
				const { clause, picker, fieldId, fieldLabel, isIrremovable } = fieldToRender;

				if (!isIrremovable) {
					// Optional fields are not rendered in the loading state, instead the number of
					// optional fields is displayed as a badge on the More Dropdown button.
					// Only irremovable field picker trigger is rendered in the loading state.
					return null;
				}

				switch (picker.pickerType) {
					case PickerTypes.Picker: {
						return (
							<FieldWrapper
								key={fieldId}
								// eslint-disable-next-line jira/integration/test-id-by-folder-structure
								data-testid="jql-builder-basic-refinement-fallback"
							>
								<PickerFallback
									clause={clause}
									fieldId={fieldId}
									fieldDisplayName={fieldLabel}
									fieldDescription={null}
									isIrremovable={isIrremovable}
								/>
							</FieldWrapper>
						);
					}
					default:
						return null;
				}
			})}
			<FieldWrapper>
				<AddFilterFallback optionalFieldsCount={optionalFieldsCount} />
			</FieldWrapper>
			{showOrderByPicker && (
				<FieldWrapper>
					<OrderByPickerLoading orderByFieldsCount={orderByFieldsCount ?? 0} />
				</FieldWrapper>
			)}
		</>
	);
};

/**
 * The magic is copied from jira/src/packages/navigation-apps/sidebar/connect/src/common/utils/find-item-stack/index.tsx
 * No clue what that `s` query param stands for, just keeping the link comparison logic 1:1 to avoid regressions.
 */

import isEqual from 'lodash/isEqual';

// JSPA-1062 remove qs items that might not always be there
const splitRelevantQs = (s: string) =>
	s
		.replace('?', '')
		.split('&')
		.filter((v) => v && !/^(ac\.|project|board|user|servicedesk)/.test(v));

// match query strings, but allow case where query param "s" is present in the menu item, but not the window url
const isSameQuery = (locationQuery: Array<string>, itemQuery: Array<string>) =>
	isEqual(locationQuery, itemQuery) ||
	(!locationQuery.some((q) => q.startsWith('s=')) &&
		isEqual(
			itemQuery.filter((q) => !q.startsWith('s=')),
			locationQuery,
		));

const parseUrl = (url = '') => {
	const [pathname, qs = ''] = url.split('#')[0].split('?');
	return { pathname, query: splitRelevantQs(qs) };
};

/**
 * The util is used by a few consumers that operate on different fragments and this is
 * the only way to satisfy all the inputs by defining a basic type that should be satisfied.
 * It looks that ugly because it should match GraphQL codegen output.
 */
type BasicConnectNodeShape = {
	readonly url?: string | null | undefined;
	readonly sections?:
		| ReadonlyArray<
				| {
						readonly label?: string | null | undefined;
						readonly links?:
							| ReadonlyArray<
									| {
											readonly url?: string | null | undefined;
									  }
									| null
									| undefined
							  >
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

/**
 * Global Connect apps that have subpages and none of them match the root url
 * should be rendered as a single entry and this helper helps identifying such apps.
 * The reason for that is the sidebar v3 implementation details. How it works in v3:
 * Global apps can be open via the Apps menu and they all have a single entry, even if some may contain subpages.
 * When such entry is clicked, we navigate user to the root url of the app. Now there comes the issue with sidebar rendering.
 * The sidebar won't be rendered if the app has subpages and none of them match the current url (root url of the app).
 * We are going to preserve this behavior in the v4 and will simply convert multi-page apps into single-page ones in such case.
 */
export function canHaveSubpages<T extends BasicConnectNodeShape>(data: T): boolean {
	const sections = data.sections || [];
	const { pathname: rootPagePathname, query: rootPageQuery } = parseUrl(data.url || '');

	for (const section of sections) {
		if (
			(section?.links || []).some((link) => {
				const { pathname, query } = parseUrl(link?.url || '');
				return rootPagePathname === pathname && isSameQuery(rootPageQuery, query);
			})
		) {
			return true;
		}
	}

	return false;
}

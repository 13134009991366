import { useEffect } from 'react';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { createStore, createHook } from '@atlassian/react-sweet-state';

export const Spotlights = {
	Inactive: 'INACTIVE',
	Views: 'portfolio-3/onboarding/views',
	HeartOfPlan: 'portfolio-3/onboarding/heart-of-plan',
	ShowAndTell: 'portfolio-3/onboarding/show-and-tell',
	ListMode: 'portfolio-3/onboarding/list-mode',
	FindYourLook: 'portfolio-3/onboarding/find-your-look',
	SaveYourChanges: 'portfolio-3/onboarding/save-your-changes',
} as const;

export type Spotlights = (typeof Spotlights)[keyof typeof Spotlights];

const SpotlightStepsOld = [
	Spotlights.Inactive,
	Spotlights.Views,
	Spotlights.HeartOfPlan,
	Spotlights.ShowAndTell,
	Spotlights.ListMode,
	Spotlights.FindYourLook,
	Spotlights.SaveYourChanges,
];

const SpotlightSteps = [
	Spotlights.Inactive,
	Spotlights.Views,
	Spotlights.ShowAndTell,
	Spotlights.ListMode,
	Spotlights.FindYourLook,
	Spotlights.SaveYourChanges,
];

const store = createStore({
	initialState: { current: 0 },
	actions: {
		reset:
			() =>
			({ setState }) =>
				setState({ current: 0 }),
		start:
			() =>
			({ setState }) => {
				setState({ current: 1 });
			},
		next:
			() =>
			({ getState, setState }) => {
				setState({ current: getState().current + 1 });
			},
	},
	name: 'portfolio-3/onboarding/spotlights',
});

export const useCurrentSpotlightOld = () =>
	createHook(store, {
		selector: ({ current }) => {
			const totalStepCount = SpotlightStepsOld.length - 1; // NOTE: All spotlights enums start with an invisible INACTIVE step (step 0)
			return {
				spotlight: SpotlightStepsOld[current],
				currentStepCount: current,
				totalStepCount,
			};
		},
	})();

export const useCurrentSpotlight = () =>
	createHook(store, {
		selector: ({ current }) => {
			const totalStepCount = SpotlightSteps.length - 1; // NOTE: All spotlights enums start with an invisible INACTIVE step (step 0)
			return {
				spotlight: SpotlightSteps[current],
				currentStepCount: current,
				totalStepCount,
			};
		},
	})();

export const useSpotlightActions = createHook(store, {
	selector: null,
});

/**
 * This component toggle the header to be visible when the onboarding tour step highlights the table
 *  Clean up this component when cleaning up getWillShowNav4
 */
export const OnboardingHeaderModeUpdater = ({
	headerMode,
	setHeaderMode,
}: {
	headerMode: 'hidden' | 'toolbar' | 'visible';
	setHeaderMode: (mode: 'hidden' | 'toolbar' | 'visible') => void;
}) => {
	const [{ spotlight }] = useCurrentSpotlightOld();
	useEffect(() => {
		if (!getWillShowNav4()) {
			if (headerMode !== 'visible' && spotlight === Spotlights.HeartOfPlan) {
				setHeaderMode('visible');
			}
		}
	}, [spotlight, headerMode, setHeaderMode]);
	return null;
};

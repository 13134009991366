import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';

export const DefaultJqlBuilderContainer = ({
	showAdvancedEditor,
	children,
}: {
	showAdvancedEditor: boolean;
	children: React.ReactNode;
}) => (
	<Box
		xcss={[
			jqlBuilderContainerStyles,
			showAdvancedEditor ? jqlBuilderContainerAdvancedStyles : jqlBuilderContainerBasicStyles,
		]}
	>
		{children}
	</Box>
);

const jqlBuilderContainerStyles = xcss({
	display: 'flex',
	flexGrow: 1,
});

const jqlBuilderContainerBasicStyles = xcss({
	/* ExtraControls should wrap with refinements when we are NOT in advanced mode */
	flexWrap: 'wrap',
	/* Advanced mode has a footer element with a min-height of 20px. When in basic mode, we need to
      replicate this spacing (20px - negative margin from above) to ensure there is no layout jumping
      when switching between search modes. */
	marginTop: 'space.negative.025',
	marginRight: 'space.negative.050',
	marginBottom: 'space.200',
	marginLeft: 'space.negative.025',
});

const jqlBuilderContainerAdvancedStyles = xcss({
	marginTop: 'space.negative.025',
	marginRight: 'space.negative.050',
	marginBottom: 'space.negative.050',
	marginLeft: 'space.negative.025',
});

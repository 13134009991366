import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	boardNameFieldLabel: {
		id: 'software-board-create.modal.form.board-name.board-name-field-label',
		defaultMessage: 'Name this board',
		description: 'Label for text field asking user to enter a board name',
	},
	boardNameFieldPlaceholder: {
		id: 'software-board-create.modal.form.board-name.board-name-field-placeholder',
		defaultMessage: 'Enter a name for this board',
		description: 'Placeholder for text field asking user to enter a board name',
	},
	boardNameEmptyError: {
		id: 'software-board-create.modal.form.board-name.board-name-empty-error',
		defaultMessage: 'Enter a board name',
		description: 'Error message for board name field telling the user that board name is required',
	},
	boardNameMaxChars: {
		id: 'software-board-create.modal.form.board-name.board-name-max-chars',
		defaultMessage: 'Maximum {maxLength, plural, one {# character} other {# characters}}',
		description:
			'A hint below the board name input to tell the user the maximum number of characters allowed for the board name',
	},
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	appSettings: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-apps.common.app-actions.app-settings',
		defaultMessage: 'App settings',
		description:
			'Link to the admin page of the app. It is shown in the dropdown under the Apps menu on a global sidebar and is triggered per app',
	},
});

/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
/* eslint-disable jira/react/no-style-attribute */
/* eslint-disable @atlaskit/ui-styling-standard/enforce-style-prop */
import React, { useEffect, useState } from 'react';

type ConcurrentAdvisoryProps = {
	consoleMessage?: string;
};

export function ConcurrentAdvisory(props: ConcurrentAdvisoryProps) {
	const { consoleMessage = 'This route is being rendered with React concurrent rendering go/r18' } =
		props;

	const [isAdvisoryVisible, setIsAdvisoryVisible] = useState(true);

	useEffect(() => {
		// eslint-disable-next-line no-console
		console.log(
			`%c‼️ ${consoleMessage} ‼️\nIf you encounter any problems, report them to https://atlassian.enterprise.slack.com/archives/C06176WU6PQ`,
			'color: white; background: darkred; font-size: 16px; padding: 4px',
		);

		const timer = setTimeout(() => {
			setIsAdvisoryVisible(false);
		}, 5000);

		return () => {
			clearTimeout(timer);
		};
	}, [consoleMessage]);

	return (
		<div
			style={{
				position: 'absolute',
				left: 8,
				top: 12,
				display: 'flex',
				alignItems: 'center',
				// By giving this element a height of 0 and no pointer events, we ensure that it doesn't interfere with the page.
				// To make is visible again, we then set the overflow to "visible".
				height: 0,
				overflow: 'visible',
				pointerEvents: 'none',
			}}
		>
			<div
				style={{
					backgroundColor: isAdvisoryVisible ? 'white' : 'red',
					borderRadius: '50%',
					width: 8,
					height: 8,
					zIndex: 20,
				}}
			/>

			{isAdvisoryVisible && (
				<div
					style={{
						backgroundColor: 'red',
						color: 'white',
						height: '1.5em',
						marginLeft: -12,
						paddingLeft: 16,
						paddingRight: 8,
						borderRadius: 4,
						zIndex: 10,
					}}
					// eslint-disable-next-line jira/i18n/text-wrapped-in-format-message
				>
					Rendered in React concurrent mode go/r18
				</div>
			)}
		</div>
	);
}

import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { projectSettingsServicedeskLegacyAutomationRedirectCreateRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-routes/src/projectSettingsServicedeskLegacyAutomationRedirectCreateRoute.tsx';
import { getLegacyAutomationRoutesCommon } from './common/getLegacyAutomationRoutesCommon.tsx';
import { automationRedirect } from './ui/service-desk-automation/redirect/index.tsx';

/* Old urls.
 * Do not remove these redirects, ever.
 * Because customers will have bookmarks and saved links and we need them to keep working forever.
 */
export const projectSettingsServicedeskLegacyAutomationRedirectCreateRouteEntry = createEntry(
	projectSettingsServicedeskLegacyAutomationRedirectCreateRoute,
	() => ({
		...getLegacyAutomationRoutesCommon(),
		forPaint: [],
		component: automationRedirect('create'),
	}),
);

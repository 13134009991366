import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './constants.tsx';
import {
	PlanActionMenu as PlanActionMenuComponent,
	type PlanActionMenuDropdownProps,
} from './ui/index.tsx';

export const PlanActionMenu = (props: PlanActionMenuDropdownProps) => (
	<UFOSegment name="jira-plans-plan-action-menu">
		<JSErrorBoundary
			fallback="flag"
			id="plans-plan-action-menu"
			packageName={PACKAGE_NAME}
			teamName={TEAM_NAME}
		>
			<PlanActionMenuComponent {...props} />
		</JSErrorBoundary>
	</UFOSegment>
);

import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useTeamsPermissionsService } from '@atlassian/teams-common/use-teams-permissions';

export const useTeamPermissions = () => {
	const { data: orgId } = useOrgId();
	const { cloudId } = useTenantContext();

	const { permissions } = useTeamsPermissionsService(
		{
			orgId,
			cloudId,
		},
		{
			enabled: false,
		},
	);

	return permissions;
};

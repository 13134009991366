import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const GrowthRecommendationsEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-jira-navigation-apps-sidebar-nav4-growth-recommendations" */ './src/ui/GrowthRecommendationModal'
		).then(({ GrowthRecommendationModal }) => GrowthRecommendationModal),
	),
	getPreloadProps: (props) => ({
		queries: {}, // Need to specify `queries` as it is a mandatory prop
		props,
	}),
});

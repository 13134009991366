import React, { type KeyboardEvent, useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import Avatar from '@atlaskit/avatar';
import { Flex, xcss, Box } from '@atlaskit/primitives';
import type { MultiValue } from '@atlaskit/react-select';
import { AsyncSelect } from '@atlaskit/select';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { PICKER_SEARCH_DEBOUNCE_TIME } from '../../../../../common/constants.tsx';
import type {
	FieldValueMultiPickerProps,
	JQLTermType,
	GenericPickerOption,
} from '../../../../../common/types.tsx';
import { useLoadOptionsController } from '../../../../../controllers/use-load-options-controller/index.tsx';
import messages from './messages.tsx';

export type GenericPickerProps = {
	jqlTerm: JQLTermType;
	label: string;
} & FieldValueMultiPickerProps<MultiValue<GenericPickerOption>>;

const formatOptionLabel = (option: GenericPickerOption) => (
	<Flex alignItems="center">
		{option.avatar && (
			<Flex xcss={imgStyles}>
				<Avatar appearance="square" size="xsmall" src={option.avatar} borderColor="transparent" />
			</Flex>
		)}
		<Box xcss={optionStyles}>{option.label}</Box>
	</Flex>
);

export const GenericPicker = ({
	onChange,
	value,
	jqlTerm,
	isInvalid,
	label,
}: GenericPickerProps) => {
	const { loadOptions, isLoading } = useLoadOptionsController();
	const [defaultOptions, setDefaultOptions] = useState<GenericPickerOption[] | undefined>();
	const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
	const { formatMessage } = useIntl();

	useEffect(() => {
		loadOptions(jqlTerm)('').then((options) => setDefaultOptions(options));
	}, [jqlTerm, loadOptions]);

	const debouncedLoadOptions = debounce(
		(searchString, callback) =>
			loadOptions(jqlTerm)(searchString).then((options) => callback(options)),
		PICKER_SEARCH_DEBOUNCE_TIME,
	);

	const handleOnKeyDown = useCallback(
		(event: KeyboardEvent<EventTarget>) => {
			if (!menuIsOpen && (event.key === 'ArrowUp' || event.key === 'ArrowDown')) {
				setMenuIsOpen(true);
			}
			// close the menu when focusing on multi value to prevent enter key from propagating
			if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
				setMenuIsOpen(false);
			}
			// prevent form submission error
			if (event.key === 'Enter' && !menuIsOpen) {
				event.preventDefault();
			}
		},
		[menuIsOpen],
	);

	return (
		/* Prevent ESC press on Select bubbling up to the modal */
		fg('nav4_simplified_board_create_beta') ? (
			<EnterEscapeHandler onEscape={noop}>
				<AsyncSelect
					aria-label={label}
					isMulti
					isLoading={isLoading}
					formatOptionLabel={formatOptionLabel}
					defaultOptions={defaultOptions}
					loadOptions={debouncedLoadOptions}
					onChange={onChange}
					value={value}
					placeholder={formatMessage(messages.genericPickerPlaceholder)}
					menuPosition="fixed"
					classNamePrefix="source-value-picker"
					isInvalid={isInvalid}
					onFocus={() => setMenuIsOpen(true)}
					onBlur={() => setMenuIsOpen(false)}
					menuIsOpen={menuIsOpen}
					blurInputOnSelect={false}
					onKeyDown={handleOnKeyDown}
				/>
			</EnterEscapeHandler>
		) : (
			<AsyncSelect
				aria-label={formatMessage(messages.genericPickerAriaLabel)}
				isMulti
				isLoading={isLoading}
				formatOptionLabel={formatOptionLabel}
				defaultOptions={defaultOptions}
				loadOptions={debouncedLoadOptions}
				onChange={onChange}
				value={value}
				placeholder={formatMessage(messages.genericPickerPlaceholder)}
				menuPosition="fixed"
				classNamePrefix="source-value-picker"
				isInvalid={isInvalid}
				onFocus={() => setMenuIsOpen(true)}
				onBlur={() => setMenuIsOpen(false)}
				menuIsOpen={menuIsOpen}
				blurInputOnSelect={false}
			/>
		)
	);
};

const imgStyles = xcss({
	paddingRight: 'space.100',
});

const optionStyles = xcss({
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
});

import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const trialSprintBoardModalEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-trial-sprint-board-modal" */ './index').then(
			({ TrialSprintBoardModalEntryPoint }) => TrialSprintBoardModalEntryPoint,
		),
	),
	getPreloadProps: () => ({}),
});

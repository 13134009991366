import {
	type Jast,
	OPERATOR_EQUALS,
	OPERATOR_LIKE,
	creators,
	print,
	COMPOUND_OPERATOR_OR,
	COMPOUND_OPERATOR_AND,
} from '@atlaskit/jql-ast';
import { fg } from '@atlassian/jira-feature-gating';
import { ISSUE_KEY, getTextSearchInputField } from '../../../common/constants.tsx';
import type { TextSearchInputClauseType } from '../../../common/types.tsx';
import { escapeBackslashes, wrapWithQuotes } from '../../../common/utils.tsx';
import { TextSearchInputVisitor, isIssueKeyValue } from './text-search-input-visitor.tsx';

export type TextValueType = string | undefined;

/**
 * Check if search input clause is valid
 */
const validate = (compoundClause: TextSearchInputClauseType): boolean => {
	// eslint-disable-next-line jira/ff/inline-usage
	const isTextFieldsEnabled = fg('nin-text-fields-new-text-search-field');
	if (!compoundClause) {
		return true;
	}

	try {
		compoundClause.accept(
			new TextSearchInputVisitor({
				isTextFieldsEnabled,
			}),
		);

		return true;
	} catch (e) {
		return false;
	}
};

/**
 * Return value of search input clause or undefined if it's invalid or empty
 */
const value = (textSearchClause: TextSearchInputClauseType): TextValueType => {
	// eslint-disable-next-line jira/ff/inline-usage
	const isTextFieldsEnabled = fg('nin-text-fields-new-text-search-field');
	if (!textSearchClause) {
		return undefined;
	}

	try {
		return textSearchClause.accept(
			new TextSearchInputVisitor({
				isTextFieldsEnabled,
			}),
		);
	} catch (e) {
		return undefined;
	}
};

const createTextSearchInputClause = ({
	quotedKeyword,
	isIssueKey,
}: {
	quotedKeyword: string;
	isIssueKey: boolean;
}): NonNullable<TextSearchInputClauseType> => {
	const textSearchInputClause = creators.terminalClause(
		creators.field(getTextSearchInputField()),
		creators.operator(OPERATOR_LIKE),
		creators.byText.valueOperand(quotedKeyword),
	);

	if (isIssueKey) {
		const issueKeyClause = creators.terminalClause(
			creators.field(ISSUE_KEY),
			creators.operator(OPERATOR_EQUALS),
			creators.byText.valueOperand(quotedKeyword),
		);

		return creators.compoundClause(creators.compoundOperator(COMPOUND_OPERATOR_OR), [
			textSearchInputClause,
			issueKeyClause,
		]);
	}

	return textSearchInputClause;
};

/**
 * Update search input clause with new value
 */
const update = (
	ast: Jast,
	textSearchInputClause: TextSearchInputClauseType,
	val: TextValueType,
): string => {
	if (!validate(textSearchInputClause)) {
		return ast.represents;
	}

	if (val === undefined || val === null || val === '') {
		if (textSearchInputClause) {
			textSearchInputClause.remove();
		} else {
			return ast.represents;
		}
	} else {
		const isIssueKey = isIssueKeyValue(val);
		const quotedKeyword = wrapWithQuotes(escapeBackslashes(val));

		const newTextSearchInputClause = createTextSearchInputClause({ quotedKeyword, isIssueKey });

		if (textSearchInputClause) {
			textSearchInputClause.replace(newTextSearchInputClause);
		} else {
			ast.query?.appendClause(newTextSearchInputClause, COMPOUND_OPERATOR_AND);
		}
	}

	return print(ast, {
		operatorCase: 'upper',
	});
};

const TextFieldType = {
	validate,
	value,
	update,
};

export default TextFieldType;

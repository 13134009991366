import type { ReactNode } from 'react';

export const SSR_STATE_KEY_DETAIL_RENDER_TRACE = 'ssr/detail_render_trace' as const;

type TimingItem = {
	startTime: number;
	duration?: number;
};

export type RenderTrace = {
	[name: string]: TimingItem;
};

export type FormattedTraces = {
	routeName: string | null;
	traces: RenderTrace;
};

type SSRData<T> = {
	data: T;
};

export type State = {
	start?: number;
	traces: RenderTrace;
};

export type FormattedRenderTraceData =
	| {
			['ssr/detail_render_trace']: SSRData<FormattedTraces>;
	  } // eslint-disable-next-line @typescript-eslint/no-explicit-any
	| Record<any, any>;

export type RenderTracerMarkProps = {
	traceKey: string;
	type: 'start' | 'end';
};

export type RenderTracerContainerProps = {
	startKey: string;
	startTime: number;
	children: ReactNode;
};

/**
 * @generated SignedSource<<ce25a067adfeaaf82c317bb116b0f9cc>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type JiraWorkManagementUserLicenseSeatEdition = "FREE" | "PREMIUM" | "STANDARD" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type Nav4ProjectsContent$data = {
  readonly growthRecommendations: {
    readonly recommendations: {
      readonly data?: ReadonlyArray<{
        readonly entityId?: string;
      }> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly jira: {
    readonly favouriteProjects: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id?: string;
          readonly projectKey?: string;
          readonly " $fragmentSpreads": FragmentRefs<"Nav4ProjectMenuItem">;
        };
      } | null | undefined> | null | undefined;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
      };
    };
    readonly jwmLicensing: {
      readonly currentUserSeatEdition: JiraWorkManagementUserLicenseSeatEdition | null | undefined;
    } | null | undefined;
    readonly recentProjects: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id?: string;
          readonly projectKey?: string;
          readonly " $fragmentSpreads": FragmentRefs<"Nav4ProjectMenuItem">;
        };
      } | null | undefined> | null | undefined;
    };
  };
  readonly " $fragmentType": "Nav4ProjectsContent";
};
export type Nav4ProjectsContent$key = {
  readonly " $data"?: Nav4ProjectsContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4ProjectsContent">;
};

import Nav4ProjectsContentQuery_graphql from './Nav4ProjectsContentQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v1 = {
  "kind": "ScalarField",
  "name": "id"
},
v2 = {
  "alias": "projectKey",
  "kind": "ScalarField",
  "name": "key"
},
v3 = {
  "kind": "FragmentSpread",
  "name": "Nav4ProjectMenuItem"
},
v4 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currentURL"
    },
    {
      "kind": "RootArgument",
      "name": "simplifyBoardQuery"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "fragmentPathInResult": [],
      "operation": Nav4ProjectsContentQuery_graphql
    }
  },
  "name": "Nav4ProjectsContent",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": "favouriteProjects",
              "args": [
                (v0/*: any*/),
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "includeArchivedProjects": false,
                    "sort": {
                      "order": "DESC"
                    },
                    "type": "PROJECT"
                  }
                },
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 15
                }
              ],
              "concreteType": "JiraFavouriteConnection",
              "kind": "LinkedField",
              "name": "favourites",
              "plural": false,
              "selections": [
                {
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "hasNextPage"
                    }
                  ]
                },
                {
                  "concreteType": "JiraFavouriteEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v1/*: any*/),
                                "action": "THROW",
                                "path": "jira.favouriteProjects.edges.node.id"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v2/*: any*/),
                                "action": "THROW",
                                "path": "jira.favouriteProjects.edges.node.projectKey"
                              },
                              (v3/*: any*/)
                            ],
                            "type": "JiraProject"
                          }
                        ]
                      },
                      "action": "THROW",
                      "path": "jira.favouriteProjects.edges.node"
                    }
                  ]
                },
                (v4/*: any*/)
              ]
            },
            "action": "THROW",
            "path": "jira.favouriteProjects"
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": "recentProjects",
              "args": [
                (v0/*: any*/),
                {
                  "kind": "Variable",
                  "name": "currentURL",
                  "variableName": "currentURL"
                },
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "includeArchivedProjects": false
                  }
                },
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 20
                },
                {
                  "kind": "Literal",
                  "name": "types",
                  "value": [
                    "PROJECT"
                  ]
                }
              ],
              "concreteType": "JiraSearchableEntityConnection",
              "kind": "LinkedField",
              "name": "recentItems",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraSearchableEntityEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v1/*: any*/),
                                "action": "THROW",
                                "path": "jira.recentProjects.edges.node.id"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v2/*: any*/),
                                "action": "THROW",
                                "path": "jira.recentProjects.edges.node.projectKey"
                              },
                              (v3/*: any*/)
                            ],
                            "type": "JiraProject"
                          }
                        ]
                      },
                      "action": "THROW",
                      "path": "jira.recentProjects.edges.node"
                    }
                  ]
                },
                (v4/*: any*/)
              ]
            },
            "action": "THROW",
            "path": "jira.recentProjects"
          },
          {
            "args": [
              (v0/*: any*/)
            ],
            "concreteType": "JiraWorkManagementLicensing",
            "kind": "LinkedField",
            "name": "jwmLicensing",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "currentUserSeatEdition"
              }
            ]
          }
        ]
      },
      "action": "THROW",
      "path": "jira"
    },
    {
      "concreteType": "GrowthRecQuery",
      "kind": "LinkedField",
      "name": "growthRecommendations",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "fields": [
                {
                  "kind": "Literal",
                  "name": "product",
                  "value": "jira"
                },
                {
                  "kind": "Variable",
                  "name": "tenantId",
                  "variableName": "cloudId"
                },
                {
                  "kind": "Literal",
                  "name": "useCase",
                  "value": "jiraProjectMenuRecommendations"
                }
              ],
              "kind": "ObjectValue",
              "name": "context"
            },
            {
              "kind": "Literal",
              "name": "first",
              "value": 2
            }
          ],
          "kind": "LinkedField",
          "name": "recommendations",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "LinkedField",
                  "name": "data",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "entityId"
                        }
                      ],
                      "type": "GrowthRecJiraTemplateRecommendation"
                    }
                  ]
                }
              ],
              "type": "GrowthRecRecommendations"
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "8b2dedc24b16febccff422b581357d53";

export default node;

import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import { createJpdExperience } from '../../utils/experience/main.tsx';

const loadIssuesRestBatched = createJpdExperience(
	new UFOExperience('jpd.issues.load.rest-batched', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadIssueRest = createJpdExperience(
	new UFOExperience('jpd.issue.load.rest', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadIssuesJpdApi = createJpdExperience(
	new UFOExperience('jpd.issues.load.jpd-api', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadIssueJpdApi = createJpdExperience(
	new UFOExperience('jpd.issue.load.jpd-api', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadIssuesRanksRestBatched = createJpdExperience(
	new UFOExperience('jpd.issues.ranks.load.rest-batched', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadIssuesRanksJpdApi = createJpdExperience(
	new UFOExperience('jpd.issues.ranks.load.jpd-api', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);
const rankIssue = createJpdExperience(
	new UFOExperience('jpd.issue.rank', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadIssuePickerIssuesRest = createJpdExperience(
	new UFOExperience('jpd.issue-picker.load', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const globalJpdIssueCreate = createJpdExperience(
	new UFOExperience('jpd.global.jpd.create-issue', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	loadIssuesRanksRestBatched,
	loadIssuesRanksJpdApi,
	loadIssuesRestBatched,
	loadIssuesJpdApi,
	loadIssueJpdApi,
	loadIssueRest,
	rankIssue,
	loadIssuePickerIssuesRest,
	globalJpdIssueCreate,
};

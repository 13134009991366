import { useCallback } from 'react';
import { graphql, fetchQuery, useRelayEnvironment } from 'react-relay';
import type { HydratedValues, HydratedValue } from '@atlaskit/jql-editor';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type {
	hydrationRelayJqlBuilderAdvancedQuery,
	hydrationRelayJqlBuilderAdvancedQuery$data,
	JiraJqlViewContext,
} from '@atlassian/jira-relay/src/__generated__/hydrationRelayJqlBuilderAdvancedQuery.graphql';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import { EPIC_LINK_SEARCHER_KEY, PARENT_LINK_SEARCHER_KEY } from './constants.tsx';

export const mapHydratedValuesFromData = (
	response?: hydrationRelayJqlBuilderAdvancedQuery$data,
): HydratedValues => {
	if (!response?.jira?.jqlBuilder?.hydrateJqlQuery?.fields) {
		return {};
	}

	const hydratedValues: HydratedValues = {};

	response.jira.jqlBuilder.hydrateJqlQuery.fields.forEach((field) => {
		if (field.values) {
			const fieldValues: Array<HydratedValue> = [];
			field.values.forEach((valueResult) => {
				if (valueResult?.values) {
					valueResult.values.forEach((value) => {
						if (value?.__typename === 'JiraJqlUserFieldValue') {
							fieldValues.push({
								type: 'user',
								id: value.jqlTerm,
								name: value.displayName,
								avatarUrl: value.user?.picture ?? '',
							});
						}
					});
				}
			});

			if (field.field && field.field.searchTemplate) {
				const fieldName = field.field.jqlTerm;
				if (EPIC_LINK_SEARCHER_KEY === field.field.searchTemplate.key) {
					fieldValues.push({
						type: 'deprecated-field',
						id: fieldName,
						deprecatedSearcherKey: field.field.searchTemplate.key,
					});
				}
			}

			if (field.field && field.field.searchTemplate) {
				const fieldName = field.field.jqlTerm;
				if (PARENT_LINK_SEARCHER_KEY === field.field.searchTemplate.key) {
					fieldValues.push({
						type: 'deprecated-field',
						id: fieldName,
						deprecatedSearcherKey: field.field.searchTemplate.key,
					});
				}
			}

			if (fieldValues.length > 0 && field.field) {
				const fieldName = field.field.jqlTerm;
				hydratedValues[fieldName] = fieldValues;
			}
		}
	});

	return hydratedValues;
};

export const useOnHydrate = (viewContext: JiraJqlViewContext | null) => {
	const environment = useRelayEnvironment();
	const { cloudId } = useTenantContext();
	return useCallback<(arg1: string) => Promise<HydratedValues>>(
		async (jql: string) => {
			try {
				const data = await fetchQuery<hydrationRelayJqlBuilderAdvancedQuery>(
					environment,
					graphql`
						query hydrationRelayJqlBuilderAdvancedQuery(
							$jql: String!
							$cloudId: ID!
							$viewContext: JiraJqlViewContext
						) {
							jira {
								jqlBuilder(cloudId: $cloudId) {
									hydrateJqlQuery(query: $jql, viewContext: $viewContext) {
										... on JiraJqlHydratedQuery {
											fields {
												... on JiraJqlQueryHydratedField {
													field {
														jqlTerm
														searchTemplate {
															key
														}
													}
													values {
														... on JiraJqlQueryHydratedValue {
															values {
																jqlTerm
																displayName
																__typename
																... on JiraJqlUserFieldValue {
																	user {
																		picture
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					`,
					{
						jql,
						cloudId,
						viewContext,
					},
					{
						networkCacheConfig: {
							metadata: { META_SLOW_ENDPOINT: true },
						},
					},
				).toPromise();
				return mapHydratedValuesFromData(data);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireErrorAnalytics({
					error,
					meta: {
						id: 'useOnHydrateRelay',
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
				});
				throw error;
			}
		},
		[cloudId, environment, viewContext],
	);
};

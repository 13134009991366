// eslint-disable-next-line jira/import-whitelist -- Unclear how to change the config whilst we transition to flat config
import { expVal } from '@atlassian/jira-feature-experiments';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { createRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
// eslint-disable-next-line jira/import-whitelist -- Unclear how to change the config whilst we transition to flat config
import BOARD_QUERY from '@atlassian/jira-relay/src/__generated__/boardWithRouterRelayQuery$parameters';
import type { boardWithRouterRelayQuery } from '@atlassian/jira-relay/src/__generated__/boardWithRouterRelayQuery.graphql';
// eslint-disable-next-line jira/import-whitelist -- Unclear how to change the config whilst we transition to flat config
import QUERY from '@atlassian/jira-relay/src/__generated__/viewBoardCustomFiltersQuery$parameters';
import type { viewBoardCustomFiltersQuery } from '@atlassian/jira-relay/src/__generated__/viewBoardCustomFiltersQuery.graphql';
import type { boardFilterRefinementQuery } from '@atlassian/jira-relay/src/__generated__/boardFilterRefinementQuery.graphql';
// eslint-disable-next-line jira/import-whitelist -- Unclear how to change the config whilst we transition to flat config
import BOARD_FILTER_REFINEMENT_QUERY from '@atlassian/jira-relay/src/__generated__/boardFilterRefinementQuery$parameters';
import {
	browserOnlyResourceWithCondition,
	resourceWithCondition2,
} from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import type { BoardScopeGQL } from '@atlassian/jira-software-board-fetch-scope-critical/src/types.tsx';
import { createResource } from '@atlassian/react-resource-router';
import {
	RESOURCE_TYPE_NEXTGEN_BOARD,
	RESOURCE_TYPE_NEXTGEN_BOARD_CUSTOM_FILTERS,
	RESOURCE_TYPE_NEXTGEN_BOARD_RELAY,
	RESOURCE_TYPE_NEXTGEN_BOARD_FILTER_REFINEMENT,
} from './common/constants.tsx';

export const boardResource = browserOnlyResourceWithCondition(
	() => __SERVER__,
	createResource<BoardScopeGQL | null>({
		type: RESOURCE_TYPE_NEXTGEN_BOARD,
		getKey: ({ match }) => match.params.boardId || '',
		getDataLoader: () =>
			import(
				/* webpackChunkName: "async-nextgen-common", jiraSpaEntry: "async-nextgen-common" */ './controllers/get-data'
			),
		// SSR doesn't care about this and we can't check FFs here because its not in the render path
		maxAge: 1000 * 60 * 60, // 1 hour max age
	}),
);

export const customFiltersResource = browserOnlyResourceWithCondition(
	() => __SERVER__,
	createRelayResource<viewBoardCustomFiltersQuery>({
		type: RESOURCE_TYPE_NEXTGEN_BOARD_CUSTOM_FILTERS,
		getQuery: ({ match }, { tenantContext }) => ({
			parameters: QUERY,
			variables: {
				boardId: createAri({
					resourceOwner: 'jira-software',
					cloudId: tenantContext.cloudId,
					resourceType: 'board',
					resourceId: String(match.params.boardId),
				}),
			},
			options: { fetchPolicy: 'store-and-network' },
		}),
	}),
);

export const boardFilterRefinementResource = resourceWithCondition2(
	() => expVal('filter_refinement_in_tmp_board', 'isEnabled', false),
	createRelayResource<boardFilterRefinementQuery>({
		type: RESOURCE_TYPE_NEXTGEN_BOARD_FILTER_REFINEMENT,
		getQuery: (_routeContext, { tenantContext }) => ({
			parameters: BOARD_FILTER_REFINEMENT_QUERY,
			variables: {
				cloudId: tenantContext.cloudId,
			},
			options: { fetchPolicy: 'store-and-network' },
		}),
		isBrowserOnly: true,
	}),
);

export const boardRelayResource = browserOnlyResourceWithCondition(
	() => __SERVER__,
	createRelayResource<boardWithRouterRelayQuery>({
		type: RESOURCE_TYPE_NEXTGEN_BOARD_RELAY,
		getQuery: ({ match }, { tenantContext }) => ({
			parameters: BOARD_QUERY,
			variables: {
				boardId: createAri({
					resourceOwner: 'jira-software',
					cloudId: tenantContext.cloudId,
					resourceType: 'board',
					resourceId: String(match.params.boardId),
				}),
			},
			options: { fetchPolicy: 'store-and-network' },
		}),
	}),
);

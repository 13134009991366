/**
 * @generated SignedSource<<b9193460b999e93294b5d730392378de>>
 * @relayHash 77e36b3dffb7d5a8625bf11a1ce73995
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c106dc1a59905d1825d9bb8d83607dc58a81f3f05f768a4777fff4c9cc96714d

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraSoftwareListEmbedQuery } from './src_jiraSoftwareListEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraSoftwareListEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "c106dc1a59905d1825d9bb8d83607dc58a81f3f05f768a4777fff4c9cc96714d",
    "metadata": {},
    "name": "src_jiraSoftwareListEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_EOC } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { opsTeamLayout } from '@atlassian/jira-ops-team-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { eocReportsUnsubscribeRoute } from '@atlassian/jira-router-routes-eoc-reports-routes/src/eocReportsUnsubscribeRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import {
	LazyOpsReportsUnsubscribe,
	OpsReportsUnsubscribe,
} from './ui/spa/eoc/ops-reports/index.tsx';

export const eocReportsUnsubscribeRouteEntry: Route = createEntry(
	eocReportsUnsubscribeRoute,
	() => ({
		group: ROUTE_GROUPS_EOC,
		component: OpsReportsUnsubscribe,
		layout: opsTeamLayout,
		resources: [...getNavigationResources()],
		navigation: {
			menuId: MENU_ID.PEOPLE,
		},
		forPaint: [LazyAtlassianNavigation, LazyOpsReportsUnsubscribe],
	}),
);

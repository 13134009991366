import { useState, useCallback } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { LicensedJiraProduct } from '../common/types.tsx';
import { fetchAiEnablement as fetchAiEnablementService } from '../services/index.tsx';

const useIsAdminHubAIEnabled = (jiraProduct: LicensedJiraProduct, version?: string) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isAdminHubAiToggleEnabled, setIsAdminHubAiToggleEnabled] = useState<boolean | null>(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<FetchError | null>(null);

	const fetchData = useCallback(async () => {
		setLoading(true);
		setError(null);
		try {
			const response = await fetchAiEnablementService(jiraProduct, version);
			fireTrackAnalytics(createAnalyticsEvent({}), 'fetchAiEnablementStatus succeeded');
			setIsAdminHubAiToggleEnabled(response.isEnabled);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			setError(err);
			fireErrorAnalytics({
				error: err,
				meta: {
					id: 'fetchAiEnablementStatus failed',
				},
			});
		} finally {
			setLoading(false);
		}
	}, [createAnalyticsEvent, jiraProduct, version]);

	return { data: isAdminHubAiToggleEnabled, loading, error, fetchAIEnablement: fetchData };
};
export default useIsAdminHubAIEnabled;

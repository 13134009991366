import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import parameters from '@atlassian/jira-relay/src/__generated__/srcCustomerServiceServiceHubsPageQuery$parameters';
import type { HelpCenterSortOrder } from '@atlassian/jira-relay/src/__generated__/srcCustomerServiceServiceHubsPageQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const NUMBER_OF_HELP_CENTERS_INITIAL_FETCH = 20;
const SORT_ORDER: HelpCenterSortOrder = 'CREATED_DATE_DESCENDING';

export const helpCenterSettingsServiceHubsAppEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-help-center-settings-service-hubs-app" */ './src/ui'),
	),
	getPreloadProps: ({ tenantContext: { cloudId, activationId } }: EntryPointRouteParams) => {
		return {
			queries: {
				serviceHubsQuery: {
					parameters,
					variables: {
						cloudId,
						workspaceAri: `ari:cloud:jira:${cloudId}:workspace/${activationId}`,
						SORT_ORDER,
						NUMBER_OF_HELP_CENTERS_INITIAL_FETCH,
					},
					options: {
						fetchPolicy: 'store-or-network' as const,
					},
				},
			},
		};
	},
});

import parameters from '@atlassian/jira-relay/src/__generated__/Nav4PlansListViewQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = { cloudId: string };

export const asyncPlansSectionPlanListViewQuery = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-projects-section-content-view-query" */ './Nav4PlansListViewQuery'
		).then((module) => module.Nav4PlansListViewQuery),
	),
	getPreloadProps: ({ cloudId }: EntryPointParams) => ({
		queries: {
			queryRef: {
				parameters,
				variables: {
					cloudId,
				},
			},
		},
	}),
});

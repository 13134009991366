import { fg } from '@atlassian/jira-feature-gating';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticSoftwareProjectHorizontalNavResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/software-horizontal-navigation/index.tsx';
import { staticSidebarDefaultPINFiltersResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/project-filters/index.tsx';
import { sidebarProjectIssuesResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/project-sidebar-common/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { isNinGlobalScopeEnrolmentEnabledResource } from '@atlassian/jira-router-resources-is-nin-global-scope-enrolment-enabled/src/index.tsx';
import {
	issueNavigatorRelayResource,
	issueNavigatorFilterRelayResource,
	issueNavigatorHydrationRelayResource,
} from '@atlassian/jira-router-resources-issue-navigator/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';

export const getCommonProjectResources = () => [
	issueNavigatorRelayResource,
	issueNavigatorFilterRelayResource,
	resourceWithCondition2(
		() => fg('nin_request_hydrate_query_earlier'),
		issueNavigatorHydrationRelayResource,
	),
	...getNavigationResources(),
	// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
	incidentExperimentTraitResource,
	projectContextResource,
	forgeProjectModuleResource,
	staticSidebarShortcutsResource,
	staticProjectSidebarNavigationResource,
	staticProjectSidebarConnectAddonsNavigationResource,
	staticSoftwareProjectHorizontalNavResource,
	// end of resources expanded from getNavigationSidebarProjectResource
	...getBusinessProjectResources(),
	sidebarProjectIssuesResource,
	isNinGlobalScopeEnrolmentEnabledResource,
	// this resource is also used by the nav4 sidebar for JSM project's 'issues and filters'
	// src/packages/navigation-apps/sidebar-nav4/jsm-project-menu/src/ui/project-menu/issues-and-filters/default-filters/index.tsx
	staticSidebarDefaultPINFiltersResource,
];

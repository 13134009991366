import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_PROFORMA } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { proformaIssueFormsGeneralRoute } from '@atlassian/jira-router-routes-proforma-routes/src/proformaIssueFormsGeneralRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import ProformaIssueFormsGeneral, {
	LazyProformaIssueFormsGeneral,
} from './ui/issue-forms-general/index.tsx';

export const proformaIssueFormsGeneralRouteEntry: Route = createEntry(
	proformaIssueFormsGeneralRoute,
	() => ({
		group: ROUTE_GROUPS_PROFORMA,
		layout: globalLayout,
		resources: [...getNavigationResources()],
		component: ProformaIssueFormsGeneral,
		forPaint: [LazyAtlassianNavigation, LazyProformaIssueFormsGeneral],
		perfMetricKey: 'issue-forms-general',
	}),
);

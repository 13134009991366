import type {
	Route,
	InvariantRoute,
	PartialVariantRoute,
} from '@atlassian/react-resource-router/src/common/types';

/**
 * Create route entry with component/resources/etc. override
 * @param {InvariantRoute} baseRoute - base route object that contains matching info
 * @param {PartialVariantRoute} opts - route definition to fill/override
 */
export const createEntry = (baseRoute: InvariantRoute, opts: () => PartialVariantRoute): Route => {
	// @ts-expect-error Re-using baseRoute to become the full route Object allows us to optimize
	// route transitions as RRR can directly use the route instead of comparing a separate InvariantRoute
	// against the full route list
	// eslint-disable-next-line no-param-reassign
	baseRoute.lazyOpts = opts;
	return baseRoute;
};

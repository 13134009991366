import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { projectSettingsServicedeskFeedbackRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskFeedbackRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import { LazyCsatSettingsPage } from '@atlassian/jira-servicedesk-csat/src/async.tsx';
import type { Route } from '@atlassian/react-resource-router/src/common/types.tsx';
import ServiceDeskFeedbackSettings, {
	LazyProjectSettingsServiceDeskFeedbackSettings,
} from './ui/service-desk-feedback-settings/index.tsx';

export const projectSettingsServicedeskFeedbackRouteEntry: Route = createEntry(
	projectSettingsServicedeskFeedbackRoute,
	() => ({
		group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
		component: ServiceDeskFeedbackSettings,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
		forPaint: [LazyProjectSettingsServiceDeskFeedbackSettings, LazyCsatSettingsPage],
		perfMetricKey: 'jsm-feedback-settings',
		meta: {
			reporting: {
				id: APP_NAMES.CSAT_CONFIG,
				packageName: toPackageName(APP_NAMES.CSAT_CONFIG),
				teamName: 'jsm-request-intake',
			},
		},
	}),
);

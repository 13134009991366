import {
	SECTION_ITEM_STARRED,
	SECTION_ITEM_RECENT,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';

export const getSectionByStarredOrRecent = ({
	starred,
	recent,
}: {
	starred: boolean | null | undefined;
	recent: boolean;
}) => {
	if (starred) {
		return SECTION_ITEM_STARRED;
	}
	if (recent) {
		return SECTION_ITEM_RECENT;
	}
	return '';
};

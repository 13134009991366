import { createResource } from 'react-resource-router';

import { API_GATEWAY_BASE_URL } from '../utils';

import { getFiltersQuery, serialiseFiltersQueryToSearchParams } from './filters';

const PRODUCT_HEADER = 'x-product';
const EXPERIENCE_ID_HEADER = 'x-experience-id';

// Hardcode this for now for the MVP
const CONFLUENCE_PRODUCT = 'confluence';
const CSM_AI_EXPERIENCE_ID = 'csm-ai';

export function createHeaders(init?: HeadersInit): Headers {
	return new Headers({
		[PRODUCT_HEADER]: CONFLUENCE_PRODUCT,
		[EXPERIENCE_ID_HEADER]: CSM_AI_EXPERIENCE_ID,
		...(init || {}),
	});
}

export const conversationsResource = createResource({
	type: 'CONVERSATIONS',
	getKey: (ctx) => serialiseFiltersQueryToSearchParams(getFiltersQuery(ctx.query)).toString(),
	getData: async (ctx) => {
		const headers = createHeaders();
		const queryString = serialiseFiltersQueryToSearchParams(getFiltersQuery(ctx.query));

		return fetch(`${API_GATEWAY_BASE_URL}/assist/csm/v1/reporting/conversations?${queryString}`, {
			method: 'GET',
			headers,
		}).then((response) => response.json());
	},
	maxAge: 86400000,
});

export const messagesResource = createResource({
	type: 'CONVERSATION_MESSAGES',
	getKey: ({ match }) =>
		match.params.conversationId ? match.params.conversationId : 'NO_CONVERSATION_ID',
	getData: async ({ match }) => {
		const conversationId = match.params.conversationId;
		if (!conversationId) {
			return null;
		}

		const headers = createHeaders();

		return fetch(
			`${API_GATEWAY_BASE_URL}/assist/csm/v1/reporting/conversations/${match.params.conversationId}/messages`,
			{
				method: 'GET',
				headers,
			},
		).then((response) => response.json());
	},
	maxAge: 30000,
});

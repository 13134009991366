import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getNavigationForgePersonalSettingsPageResources } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import AsyncSidebarPersonalSettings from '@atlassian/jira-navigation-apps-sidebar-personal-settings/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { forgePersonalSettingsRoute } from '@atlassian/jira-router-routes-forge-routes/src/forgePersonalSettingsRoute.tsx';
import type { Route } from '@atlassian/react-resource-router/src/common/types';
import ForgePersonalSettingsPage, {
	LazyForgePersonalSettingsPage,
} from './ui/personal-settings/index.tsx';

export const forgePersonalSettingsRouteEntry: Route = createEntry(
	forgePersonalSettingsRoute,
	() => ({
		component: ForgePersonalSettingsPage,
		layout: globalLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.PERSONAL_SETTINGS,
			menuId: MENU_ID.PROFILE,
			sidebar: AsyncSidebarPersonalSettings,
		},
		resources: [...getNavigationResources(), ...getNavigationForgePersonalSettingsPageResources()],
		forPaint: [LazyAtlassianNavigation, LazyForgePersonalSettingsPage],
	}),
);

import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import { SearchField } from '@atlassian/jira-searchfield/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

type Props = {
	inputRef?: {
		current: null | HTMLInputElement;
	};
	label: string;
	value: string;
	isDisabled?: boolean;
	onChange: (arg1: string) => void;
	onFocus?: () => void;
	onSendOptionAnalytics?: (arg1: { searchStringLength: number }) => void;
};

type DebounceOnChangeProps = {
	targetValue: string;
	onChange: (arg1: string) => void;
	onSendOptionAnalytics?: (props: { searchStringLength: number }) => void;
};

const debounceOnChange = debounce(
	({ onSendOptionAnalytics = noop, onChange, targetValue }: DebounceOnChangeProps) => {
		onChange(targetValue);
		onSendOptionAnalytics({
			searchStringLength: targetValue ? targetValue.length : 0,
		});
	},
	500,
	{
		leading: false,
		trailing: true,
	},
);

const TextField = ({
	inputRef,
	label,
	value,
	isDisabled = false,
	onChange,
	onFocus,
	onSendOptionAnalytics,
}: Props) => {
	const handleChange = useCallback(
		(targetValue: string) => {
			debounceOnChange({
				onChange,
				targetValue,
				onSendOptionAnalytics,
			});
		},
		[onChange, onSendOptionAnalytics],
	);

	return (
		<StyledContainer>
			<SearchField
				onChange={handleChange}
				onFocus={onFocus}
				value={value}
				placeholder={label}
				placeholderAlwaysVisible
				isCompact
				isResponsive
				inputRef={inputRef}
				testId="jql-builder-basic.common.ui.text-field.search-field"
				isDisabled={isDisabled}
				showIconBeforeInput={isVisualRefreshEnabled()}
			/>
		</StyledContainer>
	);
};

export default TextField;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledContainer = styled.div({
	position: 'relative',
});

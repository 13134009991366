import React from 'react';
import Link from '@atlassian/jira-common-analytics-v2-wrapped-components/src/link.tsx';
import {
	SERVICE_DESK_PROJECT,
	type ProjectType,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { TRASH_AND_RESTORE_HELP_LINK } from '../../common/constants.tsx';
import messages from './messages.tsx';

type Props = {
	projectType: ProjectType | undefined;
};

const isServiceDesk = (projectType?: ProjectType) => projectType === SERVICE_DESK_PROJECT;

export const ModalText = ({ projectType }: Props) => {
	const { formatMessage } = useIntl();

	const getMessage = () => {
		let message;

		if (expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)) {
			message = isServiceDesk(projectType)
				? messages.jsmExplainWhatProjectRestoreDoesIssueTermRefresh
				: messages.explainWhatProjectRestoreDoesPart1IssueTermRefresh;
		} else {
			message = isServiceDesk(projectType)
				? messages.jsmExplainWhatProjectRestoreDoes
				: messages.explainWhatProjectRestoreDoesPart1;
		}

		return message;
	};

	const formattedMessage = formatMessage(getMessage());

	return <span>{formattedMessage}&nbsp;</span>;
};

export const LearnMoreLink = () => {
	const { formatMessage } = useIntl();

	return (
		<Link href={TRASH_AND_RESTORE_HELP_LINK} target="_blank">
			{formatMessage(messages.jsmLearnMoreAboutTrashAndRestore)}
		</Link>
	);
};

export const ModalMessageContent = ({ projectType }: Props) => (
	<>
		<ModalText projectType={projectType} />
		{isServiceDesk(projectType) && <LearnMoreLink />}
	</>
);

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'portfolio-3-onboarding.onboarding.spotlight-heart-of-plan.title',
		defaultMessage: 'The heart of your plan',
		description: 'The title for the spotlight',
	},
	content: {
		id: 'portfolio-3-onboarding.onboarding.spotlight-heart-of-plan.content',
		defaultMessage:
			'View, create, and schedule tasks, then customize which data fields you want to see in context.',
		description: 'The content for the spotlight.',
	},
});

/**
 * @generated SignedSource<<efbb0a4384878cbf9ac9349281af00d4>>
 * @relayHash d2566f4b92610478bcdf364c0e0af1d4
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 09510390bd322002a709c3e14bfaa3718c3667e4b7b98d623c116270f906a2a5

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiServiceHubDetailsPageQuery } from './uiServiceHubDetailsPageQuery.graphql';

const node: PreloadableConcreteRequest<uiServiceHubDetailsPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "09510390bd322002a709c3e14bfaa3718c3667e4b7b98d623c116270f906a2a5",
    "metadata": {},
    "name": "uiServiceHubDetailsPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

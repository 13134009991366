import { resources as teamDashboardResources } from '@atlassian/eoc-team-dashboard/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_EOC } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarEocTeamDashboard, {
	LazyTeamDashboardSidebar,
} from '@atlassian/jira-navigation-apps-sidebar-eoc-team-dashboard/src/async.tsx';
import { opsTeamLayout } from '@atlassian/jira-ops-team-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { eocOnCallRedirectRoute } from '@atlassian/jira-router-routes-eoc-on-call-routes/src/eocOnCallRedirectRoute.tsx';
import { OnCallRedirect } from './ui/spa/eoc/on-call/index.tsx';

export const eocOnCallRouteEntry = createEntry(eocOnCallRedirectRoute, () => ({
	group: ROUTE_GROUPS_EOC,
	component: OnCallRedirect,
	layout: opsTeamLayout,
	resources: [...getNavigationResources(), ...teamDashboardResources],
	navigation: {
		menuId: MENU_ID.PEOPLE,
		sidebar: AsyncSidebarEocTeamDashboard,
	},
	forPaint: [LazyAtlassianNavigation, LazyTeamDashboardSidebar],
	isRedirect: true,
}));

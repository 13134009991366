import {
	type TerminalClause,
	type Clause,
	OPERAND_TYPE_FUNCTION,
	CLAUSE_TYPE_TERMINAL,
	OPERAND_TYPE_VALUE,
	OPERAND_TYPE_LIST,
	OPERAND_TYPE_KEYWORD,
} from '@atlaskit/jql-ast';
import type {
	CascadingSelectOption,
	CascadingSelectState,
} from '@atlassian/jira-jql-builder-basic-cascading-select/src/common/types.tsx';
import { CASCADE_OPTION_FUNCTION_NAME } from '../../common/constants.tsx';
import type { HydrationValues } from '../../common/types.tsx';

export const CASCADING_OPTION_FIELD_VALUE_TYPE_NAME = 'JiraJqlCascadingOptionFieldValue';

/**
 * Types and Utils for handling the top-level "values" array on the field object.
 * i.e. jira.jqlBuilder.hydrateJqlQuery.fields.values[number]
 */
export type HydrationJqlValueOrError = Exclude<
	NonNullable<NonNullable<HydrationValues>[number]>,
	{ readonly __typename: '%other' }
>;
export type HydrationJqlValue = Exclude<
	HydrationJqlValueOrError,
	{ readonly __typename: 'JiraJqlQueryHydratedError' }
>;

const isHydrationJqlValueOrError = (
	value: NonNullable<HydrationValues>[number],
): value is HydrationJqlValueOrError =>
	value?.__typename === 'JiraJqlQueryHydratedValue' ||
	value?.__typename === 'JiraJqlQueryHydratedError';

const matchValueToClause = (
	hydratedValues: HydrationValues,
	clauseValue: string | undefined,
): HydrationJqlValueOrError | undefined =>
	clauseValue
		? hydratedValues?.find(
				(fieldValue): fieldValue is HydrationJqlValueOrError =>
					isHydrationJqlValueOrError(fieldValue) &&
					fieldValue?.jqlTerm?.toLowerCase() === clauseValue?.toLowerCase(),
			)
		: undefined;

/**
 * Types and Utils for handling the bottom-level "values" array on the values object.
 * i.e. jira.jqlBuilder.hydrateJqlQuery.fields.values[number].values[number]
 */
type UncheckedCascadingOptionFieldValue = HydrationJqlValue['values'][number];
type CascadingOptionFieldValue = Exclude<
	UncheckedCascadingOptionFieldValue,
	{ readonly __typename: '%other' }
>;

const isCascadingOptionFieldValue = (
	value: UncheckedCascadingOptionFieldValue,
): value is CascadingOptionFieldValue => value?.__typename === 'JiraJqlCascadingOptionFieldValue';

/**
 * Returns the hydrated select option state (both parent and child) that the
 * cascading select picker accepts
 */
const getCascadingSelectStateFromFieldValue = (
	value: CascadingOptionFieldValue,
): CascadingSelectState | undefined => {
	if (!value?.optionId || !value?.displayName) return undefined;

	if (value.parentOption?.optionId && value.parentOption?.displayName) {
		return {
			childOption: { label: value.displayName, value: value.optionId },
			parentOption: {
				label: value.parentOption.displayName,
				value: value.parentOption.optionId,
			},
		};
	}

	return { parentOption: { label: value.displayName, value: value.optionId } };
};

const getInvalidCascadingSelectOption = (
	valueOrError: HydrationJqlValueOrError,
): CascadingSelectOption => ({
	label: valueOrError.jqlTerm,
	value: valueOrError.jqlTerm,
	isInvalid: true,
});

/**
 * Handles determining the correct hydration to use for values from the `cascadeOption` function
 *
 * @param parent The JqlHydrationValue containing the possible parent options (or hydration error)
 * @param child  The JqlHydrationValue containing the possible child options (or hydration error)
 */
const getCascadingSelectStateForFunction = (
	parent?: HydrationJqlValueOrError,
	child?: HydrationJqlValueOrError,
): CascadingSelectState | undefined => {
	// If there is no parent, then there cannot be a child either
	if (!parent) {
		return undefined;
	}

	// If the parent is invalid, then we can assume that the child is also invalid
	if (parent?.__typename === 'JiraJqlQueryHydratedError') {
		return {
			parentOption: getInvalidCascadingSelectOption(parent),
			childOption: child ? getInvalidCascadingSelectOption(child) : undefined,
		};
	}

	// Find the parent option values. Parent options have parentOption set to null
	const parentValues = parent?.values
		?.filter(isCascadingOptionFieldValue)
		.filter((parentValue) => !parentValue?.parentOption);

	// If there is no child, then we can return the parent option.
	if (!child && parentValues?.length === 1) {
		return getCascadingSelectStateFromFieldValue(parentValues[0]);
	}

	// If there were multiple possible parents and no children, then it is ambiguous and we will return none
	if (!child) {
		return undefined;
	}

	if (child?.__typename === 'JiraJqlQueryHydratedError') {
		// If the child is invalid, we can at least attempt to salvage the parent
		return parentValues?.length === 1
			? {
					...getCascadingSelectStateFromFieldValue(parentValues[0]),
					childOption: getInvalidCascadingSelectOption(child),
				}
			: // otherewise mark the whole thing as invalid
				{
					parentOption: getInvalidCascadingSelectOption(parent),
					childOption: getInvalidCascadingSelectOption(child),
				};
	}

	// Find the child option values. Child options have parentOption set to a parent option. We can
	// use that to find the children that match a parent option found above
	const childValues = child?.values
		?.filter(isCascadingOptionFieldValue)
		.filter((childValue) =>
			parentValues?.some(
				(parentValue) =>
					childValue?.parentOption && parentValue?.optionId === childValue?.parentOption.optionId,
			),
		);

	// If there is only one child value, we can return the parent and child option, otherwise the
	// clause is ambiguous
	if (childValues?.length === 1) {
		return getCascadingSelectStateFromFieldValue(childValues[0]);
	}
};

type Props = {
	/**
	 * The JQL AST clause that we trying to match hydration data to
	 */
	clause: Clause | TerminalClause | undefined;
	/**
	 * Hydration data from the backend for the field in the given clause
	 */
	hydratedValues: HydrationValues | undefined;
};

/**
 * Searches through the hydration values to select the correct matches between the JQL and the
 * hydration data. This is necessary because in some cases the backend returns extra options that
 * might match the clause.
 *
 * @returns The state that can be used to initialise the Cascading Select picker, or undefined if
 * the clause is ambiguous or has no values
 */
export const findUnambiguousCascadeFieldValue = ({
	hydratedValues,
	clause,
}: Props): CascadingSelectState | undefined => {
	if (clause?.clauseType !== CLAUSE_TYPE_TERMINAL) {
		return;
	}
	// Handle the case where clause is a list with mupltiple values
	if (clause?.operand?.operandType === OPERAND_TYPE_LIST && clause?.operand?.values?.length > 1) {
		return;
	}

	// Handle the case where clause is a cascadeOption function (with at most two arguments)
	if (
		clause?.operand?.operandType === OPERAND_TYPE_FUNCTION &&
		clause?.operand?.function.value === CASCADE_OPTION_FUNCTION_NAME &&
		clause?.operand?.arguments &&
		clause?.operand?.arguments.length <= 2
	) {
		const { operand } = clause;

		return getCascadingSelectStateForFunction(
			// Find the field value that matches the first argument of the cascadeOption function (parent)
			matchValueToClause(hydratedValues, operand?.arguments[0]?.value),
			// Find the field value that matches the second argument of the cascadeOption function (child)
			matchValueToClause(hydratedValues, operand?.arguments[1]?.value),
		);
	}

	// Handle the case where clause is a single value or a list with one value
	if (clause?.operand?.operandType !== OPERAND_TYPE_KEYWORD) {
		let clauseValue = '';

		// Get the value of the clause depending on the operand type
		switch (clause?.operand?.operandType) {
			case OPERAND_TYPE_LIST:
				if (clause?.operand?.values?.[0]?.operandType !== OPERAND_TYPE_VALUE) {
					return;
				}
				clauseValue = clause?.operand?.values?.[0]?.value;
				break;
			case OPERAND_TYPE_VALUE:
				clauseValue = clause?.operand?.value;
				break;
			default:
				return;
		}
		// Match the clause value to a field value
		const matchedValue = matchValueToClause(hydratedValues, clauseValue);

		if (matchedValue?.__typename === 'JiraJqlQueryHydratedError') {
			// If there's a hydration error, the best we can do is assume it's the parent
			// This assumption resulsts in a better UX than kicking the user to advanced mode
			// with no further information.
			return {
				parentOption: getInvalidCascadingSelectOption(matchedValue),
			};
		}

		// If there is more than one matched value, the clause is ambiguous, otherwise we can get
		// the parent and child option
		if (
			matchedValue?.values &&
			matchedValue.values.length === 1 &&
			isCascadingOptionFieldValue(matchedValue.values[0])
		) {
			return getCascadingSelectStateFromFieldValue(matchedValue.values[0]);
		}
	}

	return undefined;
};

import isNil from 'lodash/isNil';
import merge from 'lodash/merge';
import {
	Observable,
	type RequestParameters,
	type Variables,
	type GraphQLSingularResponse,
	type GraphQLResponse,
	type CacheConfig,
} from 'relay-runtime';
import { getATLContextDomain } from '@atlassian/atl-context';
import { getInstance } from '@atlassian/heartbeat';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { getDefaultOptions } from '@atlassian/jira-fetch/src/utils/fetch-default-options.tsx';
import {
	performPostRequest,
	performPostRequestWithRetry,
	applyErrorHandling,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { retryOnError, RETRY_ATTEMPTS_LIMIT } from '@atlassian/jira-fetch/src/utils/retries.tsx';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token/src/index.tsx';
import { captureErrors, isUnauthenticated } from '@atlassian/jira-relay-errors/src/index.tsx';
import RelayExperimentalApis from '@atlassian/jira-relay-experimental-apis/src/index.tsx';
import { QueryPromisesMap } from '@atlassian/jira-relay-query-promises/src/index.tsx';
import { QueryResponseCache } from '@atlassian/jira-relay-query-responses/src/index.tsx';
import {
	claimEarlyScriptPromise,
	getEarlyScriptPromiseKey,
} from '@atlassian/jira-relay-vendorless-utils/src/services/early-script-promise/index.tsx';
import { putExtensions } from '@atlassian/relay-capture-extensions';
import { getAggEndpoint, getRequestBody } from '@atlassian/relay-endpoint';
import { addTraceId } from '@atlassian/relay-traceid';
import { showUnauthenticatedFlag } from './services/error-flags/index.tsx';
import { getLoggingAndMetricsHelper } from './services/logging-and-metrics/index.tsx';

let redirecting = false;

const redirect = (): void => {
	const identity = getATLContextDomain('id');

	// Hardcoding to Jira as this will be the only adopter of this service for now.
	const redirectUrl = `https://${identity}/login?application=jira&continue=${encodeURIComponent(
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.location.href,
	)}`;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.location.href = redirectUrl;
};

// Silence error logs from queries in this list
const blocklist = ['issueAggQuery'];

export const getRetryAttemptsForQuery = (queryName: string, operationKind: string) => {
	if (operationKind === 'query') {
		return RETRY_ATTEMPTS_LIMIT;
	}

	return 0;
};

const getTraceIdFromExtensions = ({ extensions }: GraphQLSingularResponse): string | undefined => {
	if (
		extensions !== undefined &&
		extensions.gateway !== undefined &&
		extensions.gateway !== null &&
		typeof extensions.gateway.request_id === 'string'
	) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return extensions.gateway.request_id as string;
	}
	return undefined;
};

/**
 * Given a set of options, produce a new set of options for consumption which contains
 * a combination of the given set and the default options
 * @param {RequestOptions} options
 * @returns RequestOptions
 */
const generateOptions = (options: RequestInit = {}) => {
	// the token fetcher returns undefined if no cookie value was found, also in SSR cookies are unavailable
	const csrfToken = !__SERVER__ ? getXsrfToken() : undefined;

	// If we don't get a token, there is no point trying to add the csrf header
	const csrfTokenHeader = isNil(csrfToken) ? {} : { 'atl-xsrf-token': csrfToken };

	return merge(
		{}, // New object to ensure the other options aren't modified
		{
			// We have to provide general options here or the headers we provide will override the default ones found
			// here /src/packages/platform/utils/fetch/src/utils/requests.js due to a shallow merge
			...getDefaultOptions(null),
			...options,
		},
		{
			headers: {
				'atl-client-name': 'jira-frontend',

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				'atl-client-version': `${window?.BUILD_KEY ?? ''}::${window?.SSR_BUILD_KEY ?? ''}`,
				'X-ExperimentalApi': RelayExperimentalApis.join(','),
				...csrfTokenHeader,
			},
		},
	);
};

const checkAuthentication = () => {
	if (process.env.NODE_ENV !== 'development') {
		if (!__SERVER__ && !redirecting) {
			const heartbeatService = getInstance();
			if (heartbeatService.isInitialized() && !heartbeatService.isAuthenticated()) {
				redirect();
				// Set flag to prevent multiple redirects
				redirecting = true;
			}
		}
	}
};

export const shouldRetry = (error: Error): boolean => {
	if (error instanceof FetchError) {
		return [502, 503, 504].includes(error.statusCode);
	}
	return false;
};

export const fetch = (
	request: RequestParameters,
	variables: Variables,
	cacheConfig: CacheConfig,
): Observable<GraphQLResponse> => {
	checkAuthentication();
	if (redirecting)
		// Hang the request if we are redirecting - should be cleared on redirect
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		return Observable.from(new Promise<GraphQLResponse>(() => {}));

	const { name: queryName, operationKind } = request;

	const earlyScriptPromise =
		(!__SERVER__ && claimEarlyScriptPromise(getEarlyScriptPromiseKey(request, variables))) ||
		undefined;

	const loggingMetadata = {
		queryName,
		operationKind,
	};
	const loggingAndMetrics = getLoggingAndMetricsHelper(loggingMetadata);

	return Observable.create((sink) => {
		try {
			const requestID =
				'cacheID' in request && request.cacheID != null ? request.cacheID : request.id;
			const endpoint = getAggEndpoint(request, cacheConfig);
			const body = getRequestBody(request, variables);

			let ssrCache = null;
			if (!__SERVER__ && requestID != null) {
				ssrCache = QueryResponseCache.get(requestID, variables);
			}

			// On client read cache from window.SPA_STATE if available
			if (!__SERVER__ && !isNil(ssrCache)) {
				sink.next(ssrCache);
				if (requestID != null) {
					QueryResponseCache.delete(requestID, variables);
				}
				sink.complete();
				return;
			}

			const wrappedEarlyScriptPromise =
				earlyScriptPromise &&
				retryOnError<GraphQLSingularResponse>(() => applyErrorHandling(earlyScriptPromise), {
					retryFunc: () =>
						performPostRequest(
							endpoint,
							generateOptions({
								body,
							}),
						),
					retryPredicate: shouldRetry,
					retryAttempts: getRetryAttemptsForQuery(queryName, operationKind),
					onRetry: loggingAndMetrics.logErrorRetry,
				});

			// Throws HttpError
			const promise: Promise<GraphQLSingularResponse> =
				!__SERVER__ && wrappedEarlyScriptPromise !== undefined
					? wrappedEarlyScriptPromise
					: Promise.resolve(
							performPostRequestWithRetry(endpoint, {
								...generateOptions({
									body,
								}),
								retryPredicate: shouldRetry,
								retryAttempts: getRetryAttemptsForQuery(queryName, operationKind),
								onRetry: (error, attempt) => {
									loggingAndMetrics.logErrorRetry(error, attempt);
								},
							}),
						);

			promise
				.then((response: GraphQLSingularResponse) => {
					addTraceId(queryName, getTraceIdFromExtensions(response));
					putExtensions(queryName, response.extensions);

					// @ts-expect-error - TS2339 - Property 'errors' does not exist on type 'GraphQLSingularResponse'.
					const errors = response.errors || undefined;
					if (errors != null) {
						const requestId = response?.extensions?.gateway?.request_id;
						if (!blocklist.includes(queryName)) {
							loggingAndMetrics.logNonBreakingErrors(errors, requestId);
						}
						captureErrors(errors, { operationName: queryName, requestId });

						if (isUnauthenticated(errors)) {
							//  Short circuit operation and handle the unauthorized errors.
							showUnauthenticatedFlag(queryName);
							return;
						}
					}

					if (
						response.data &&
						(response.data.jira === null || response.data.customerService === null)
					) {
						// Enforce non-nullability of the jira and customerService namespaces. This is an intermediary solution to
						// prevent server errors/gateway timeouts from wiping the Relay store. Eventually we'd like this
						// to be enforced at a schema level, see further discussion https://hello.atlassian.net/wiki/spaces/~236031707/pages/2034382845/Top+level+errors+for+namespaces+in+AGG+Relay
						// @ts-expect-error - TS2322 - Relay types do not allow null data for a response with errors, however this is valid according to the spec http://spec.graphql.org/June2018/#sec-Data
						response.data = null;
					}

					// cache query response on SSR
					if (__SERVER__ && requestID != null) {
						QueryResponseCache.setWithMetadata(requestID, variables, response, loggingMetadata);
					}
					sink.next(response);
					sink.complete();
				})
				// We don't await the promise so the catch block below is never
				// triggered so we need an independent catch on the promise
				.catch((error) => {
					loggingAndMetrics.logError(error);
					sink.error(error);
				});

			if (requestID != null) {
				QueryPromisesMap.set(requestID, promise);
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			loggingAndMetrics.logError(error);
			sink.error(error);
		}
	});
};

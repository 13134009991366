import { defineMessages } from '@atlassian/jira-intl';

// The following are the description for the translators for the different text that we need translating.
// Unfortunately, this needs to copied to individual files because the i18n text extraction tool needs
// to be able to resolve variables statically. This duplication is OK.
const NAME_DESC =
	'Name of the site optimizer metric that will be used as the title on the page and menu item.';
const DESCRIPTON_DESC = 'Description of the site optimizer metric.';
const CHART_DESC = 'A tab label of Issue Tabs, to show Issue chart';
const REVIEW_AND_ANALYZE_DESC = 'A tab label of Issue Tabs, to show issue review and analyze';
const WARNING_DESC = 'Warning message if metric are getting close to the threshold.';
const EXCEEDED_DESC = 'Message to show to the user if metric has exceeded the threshold.';
const RISK_DESC = 'One of the risks when this metric exceeded the threshold.';
const MITIGATION_DESC = 'One of the mitigation options when this metric exceeded the threshold.';
const CURRENT_VALUE_LABEL_DESC = 'Label to be shown next to the current value of the metric.';
const THRESHOLD_VALUE_LABEL_DESC = 'Label to be shown next to the threshold value of the metric.';
const ACTIVITY_LOG_DESC =
	'A tab label of Activity Log Tabs, to show Issue Archival past operations';
const GRAPH_HEADER_DESC = 'Text to be shown on the header of the metric graph.';

export default defineMessages({
	name: {
		id: 'admin-pages-performance.common.assets.issue.name',
		defaultMessage: 'Issues',
		description: NAME_DESC,
	},
	description: {
		id: 'admin-pages-performance.common.assets.issue.description',
		defaultMessage:
			'Here’s a quick snapshot of the total number of issues in your site, including some that we think can be archived. Archiving unused issues can improve performance and stability of your site.',
		description: DESCRIPTON_DESC,
	},
	chart: {
		id: 'admin-pages-performance.common.assets.issue.chart',
		defaultMessage: 'Overview',
		description: CHART_DESC,
	},
	reviewAndAnalyze: {
		id: 'admin-pages-performance.common.assets.issue.review-and-analyze',
		defaultMessage: 'Analysis',
		description: REVIEW_AND_ANALYZE_DESC,
	},
	activityLog: {
		id: 'admin-pages-performance.common.assets.issue.activity-log',
		defaultMessage: 'Activity log',
		description: ACTIVITY_LOG_DESC,
	},
	warning: {
		id: 'admin-pages-performance.common.assets.issue.warning',
		defaultMessage: 'The number of issues in the system is nearing our recommended threshold.',
		description: WARNING_DESC,
	},
	exceeded: {
		id: 'admin-pages-performance.common.assets.issue.exceeded',
		defaultMessage: 'There are more issues in the system than our recommended threshold.',
		description: EXCEEDED_DESC,
	},
	risk1: {
		id: 'admin-pages-performance.common.assets.issue.risk-1',
		defaultMessage:
			'If you have too many issues you may find searches, automations, and workflow rules are slower than usual.',
		description: RISK_DESC,
	},
	mitigation1: {
		id: 'admin-pages-performance.common.assets.issue.mitigation-1',
		defaultMessage: 'Delete any projects that are no longer required.',
		description: MITIGATION_DESC,
	},
	mitigation2: {
		id: 'admin-pages-performance.common.assets.issue.mitigation-2',
		defaultMessage: "Disable Jira integrations that create issues you don't need.",
		description: MITIGATION_DESC,
	},
	mitigation3: {
		id: 'admin-pages-performance.common.assets.issue.mitigation-3',
		defaultMessage:
			// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
			'Delete old issues that are no longer required. Use a JQL query to find issues older than a year (“created < -365d order by created DESC“).',
		description: MITIGATION_DESC,
	},
	currentValueLabel: {
		id: 'admin-pages-performance.common.assets.issue.current-value-label',
		defaultMessage: 'Total current issues in your site',
		description: CURRENT_VALUE_LABEL_DESC,
	},
	thresholdValueLabel: {
		id: 'admin-pages-performance.common.assets.issue.threshold-value-label',
		defaultMessage: 'Recommended maximum number of issues',
		description: THRESHOLD_VALUE_LABEL_DESC,
	},
	currentValueAriaLabel: {
		id: 'admin-pages-performance.common.assets.issue.current-value-aria-label',
		defaultMessage: '{number} total current issues in your site',
		description: 'Aria Label for the current value',
	},
	thresholdValueAriaLabel: {
		id: 'admin-pages-performance.common.assets.issue.threshold-value-aria-label',
		defaultMessage: '{number} recommended maximum number of issues',
		description: 'Aria Label for the threshold value',
	},
	graphHeader: {
		id: 'admin-pages-performance.common.assets.issue.graph-header',
		defaultMessage: 'Issues over time',
		description: GRAPH_HEADER_DESC,
	},
	nameIssueTermRefresh: {
		id: 'admin-pages-performance.common.assets.issue.name-issue-term-refresh',
		defaultMessage: 'Issues',
		description: NAME_DESC,
	},
	warningIssueTermRefresh: {
		id: 'admin-pages-performance.common.assets.issue.warning-issue-term-refresh',
		defaultMessage: 'The number of issues in the system is nearing our recommended threshold.',
		description: WARNING_DESC,
	},
	exceededIssueTermRefresh: {
		id: 'admin-pages-performance.common.assets.issue.exceeded-issue-term-refresh',
		defaultMessage: 'There are more issues in the system than our recommended threshold.',
		description: EXCEEDED_DESC,
	},
	risk1IssueTermRefresh: {
		id: 'admin-pages-performance.common.assets.issue.risk-1-issue-term-refresh',
		defaultMessage:
			'If you have too many issues you may find searches, automations, and workflow rules are slower than usual.',
		description: RISK_DESC,
	},
	mitigation2IssueTermRefresh: {
		id: 'admin-pages-performance.common.assets.issue.mitigation-2-issue-term-refresh',
		defaultMessage: "Disable Jira integrations that create issues you don't need.",
		description: MITIGATION_DESC,
	},
	mitigation3IssueTermRefresh: {
		id: 'admin-pages-performance.common.assets.issue.mitigation-3-issue-term-refresh',
		defaultMessage:
			// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
			'Delete old issues that are no longer required. Use a JQL query to find issues older than a year (“created < -365d order by created DESC“).',
		description: MITIGATION_DESC,
	},
	currentValueLabelIssueTermRefresh: {
		id: 'admin-pages-performance.common.assets.issue.current-value-label-issue-term-refresh',
		defaultMessage: 'Total current issues in your site',
		description: CURRENT_VALUE_LABEL_DESC,
	},
	thresholdValueLabelIssueTermRefresh: {
		id: 'admin-pages-performance.common.assets.issue.threshold-value-label-issue-term-refresh',
		defaultMessage: 'Recommended maximum number of issues',
		description: THRESHOLD_VALUE_LABEL_DESC,
	},
	currentValueAriaLabelIssueTermRefresh: {
		id: 'admin-pages-performance.common.assets.issue.current-value-aria-label-issue-term-refresh',
		defaultMessage: '{number} total current issues in your site',
		description: 'Aria Label for the current value',
	},
	thresholdValueAriaLabelIssueTermRefresh: {
		id: 'admin-pages-performance.common.assets.issue.threshold-value-aria-label-issue-term-refresh',
		defaultMessage: '{number} recommended maximum number of issues',
		description: 'Aria Label for the threshold value',
	},
	graphHeaderIssueTermRefresh: {
		id: 'admin-pages-performance.common.assets.issue.graph-header-issue-term-refresh',
		defaultMessage: 'Issues over time',
		description: GRAPH_HEADER_DESC,
	},
});

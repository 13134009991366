import { ROUTE_NAMES_CONNECT_GENERAL_SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

// Don't forget to update CONNECT_ROUTES_SET in @atlassian/jira-common-constants/src/spa-routes
export const connectGeneralServiceDeskProjectRoute: InvariantRoute = {
	name: ROUTE_NAMES_CONNECT_GENERAL_SERVICE_DESK_PROJECT,
	path: '/plugins/servlet/ac/:addonKey/:moduleKey',
	query: [
		'mobile?!=true',
		'includeWebResources?!=true',
		'classifier?!=json',
		'project.key?',
		'project.id?',
		'project.key',
		'servicedesk.serviceDeskId',
	],
};

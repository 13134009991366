import { useCallback } from 'react';
import { useMutation, graphql } from 'react-relay';
import { type FlagConfiguration, useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import type {
	JiraSetIsFavouriteInput,
	setFavouritePlanMutation,
} from '@atlassian/jira-relay/src/__generated__/setFavouritePlanMutation.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import messages from './messages.tsx';
import { updateRelayStore } from './utils.tsx';

type SetFavouriteParams = JiraSetIsFavouriteInput & {
	planTitle: string;
	favouriteConnectionId?: string;
	onErrorCallback: () => void;
};
type SetFavourite = (input: SetFavouriteParams) => void;

export const useSetFavourite = () => {
	const cloudId = useCloudId();
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagsService();

	const displayErrorFlag = useCallback(
		(planTitle: string, isFavourite: boolean) => {
			const addFavouriteErrorFlag = (): FlagConfiguration => ({
				id: 'set-favourite-error',
				type: 'error',
				title: messages.errorFlagTitleNonFinal,
				description: formatMessage(messages.errorFlagDescriptionFailedAddNonFinal, {
					planTitle,
				}),
				isAutoDismiss: true,
			});

			const removeFavouriteErrorFlag = (): FlagConfiguration => ({
				id: 'remove-favourite-error',
				type: 'error',
				title: messages.errorFlagTitleNonFinal,
				description: formatMessage(messages.errorFlagDescriptionFailedRemoveNonFinal, {
					planTitle,
				}),
				isAutoDismiss: true,
			});

			showFlag(isFavourite ? addFavouriteErrorFlag() : removeFavouriteErrorFlag());
		},
		[formatMessage, showFlag],
	);

	const [commitMutation, isLoading] = useMutation<setFavouritePlanMutation>(graphql`
		mutation setFavouritePlanMutation($input: JiraSetIsFavouriteInput!) {
			jira {
				setEntityIsFavourite(input: $input) {
					success
					favouriteValue {
						id
						isFavourite
					}
					errors {
						message
						extensions {
							statusCode
							errorType
						}
					}
				}
			}
		}
	`);

	const setFavourite = useCallback<SetFavourite>(
		({
			entityId,
			planTitle,
			isFavourite,
			favouriteConnectionId,
			beforeEntityId = null,
			onErrorCallback,
		}) => {
			commitMutation({
				variables: { input: { entityId, isFavourite, beforeEntityId } },
				optimisticUpdater: (store) =>
					updateRelayStore({ cloudId, isFavourite, entityId, favouriteConnectionId, store }),
				updater: (store, response) => {
					if (
						response?.jira?.setEntityIsFavourite?.success &&
						response.jira.setEntityIsFavourite.favouriteValue?.isFavourite != null
					) {
						updateRelayStore({
							cloudId,
							isFavourite: response.jira.setEntityIsFavourite.favouriteValue.isFavourite,
							entityId,
							favouriteConnectionId,
							store,
						});
					}
				},
				onCompleted: (response) => {
					if (!response?.jira?.setEntityIsFavourite?.success) {
						displayErrorFlag(planTitle, isFavourite);
						onErrorCallback();
					}
				},
				onError: () => {
					displayErrorFlag(planTitle, isFavourite);
					onErrorCallback();
				},
			});
		},
		[commitMutation, cloudId, displayErrorFlag],
	);

	return { setFavourite, isLoading };
};

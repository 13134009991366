import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_GLOBAL_SETTINGS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { productsJsmOrganizationListResource } from '@atlassian/jira-router-resources-jsm-organizations-list/src/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { productsJsmOrganizationsRoute } from '@atlassian/jira-router-routes-products-jsm-organizations-routes/src/productsJsmOrganizationsRoute.tsx';
import ProductsJsmOrganizations, {
	LazyProductsJsmOrganizations,
} from './ui/admin-pages/products-jsm-organizations/index.tsx';

export const productsJsmOrganizationsRouteEntry = createEntry(
	productsJsmOrganizationsRoute,
	() => ({
		group: ROUTE_GROUPS_GLOBAL_SETTINGS,
		ufoName: 'admin.jsm-organization-page-list',
		isRedirect: false,
		component: ProductsJsmOrganizations,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProductsSettingResource(),
			productsJsmOrganizationListResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyProductsJsmOrganizations],
	}),
);

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { servicedeskCmdbInsightRoute } from '@atlassian/jira-router-routes-servicedesk-cmdb-routes/src/servicedeskCmdbInsightRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { sharedRouteProps } from './common/constants.tsx';
import { getCmdbResources } from './common/getCmdbResources.tsx';
import LandingPage, { LazyServicedeskCmdbLandingPage } from './ui/landing-page/index.tsx';

export const servicedeskCmdbInsightRouteEntry: Route = createEntry(
	servicedeskCmdbInsightRoute,
	() => ({
		...sharedRouteProps,
		component: LandingPage,
		resources: getCmdbResources(),
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbLandingPage],
		perfMetricKey: 'service-management.insight-cmdb.interact-with-landing-page',
	}),
);

import camelCase from 'lodash/camelCase';
import {
	ROUTE_GROUPS_SOFTWARE,
	ROUTE_NAMES_SOFTWARE_VERSION_DETAIL_EMBED,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { versionDetailsRelayResourceCustom } from '@atlassian/jira-router-resources-software-versions/src/controllers/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { softwareVersionDetailEmbedRoute } from '@atlassian/jira-router-routes-software-versions-routes/src/softwareVersionDetailEmbedRoute.tsx';
import { VersionDetailSkeleton } from '@atlassian/jira-skeletons/src/ui/versions/VersionDetailSkeleton.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { LazyVersionDetail, VersionDetailPage } from './ui/version-detail/index.tsx';

// RouteEntry for the Embed view for Version Details. This is only used for Smartlinks in Confluence.
export const softwareVersionDetailEmbedRouteEntry: Route = createEntry(
	softwareVersionDetailEmbedRoute,
	() => ({
		group: ROUTE_GROUPS_SOFTWARE,
		ufoName: 'software.version-details-page-embed',
		component: componentWithFG(
			'jira_release_smartlink_embed',
			VersionDetailPage,
			ErrorPagesNotFound,
		),
		skeleton: VersionDetailSkeleton,
		canTransitionIn: () => true,
		layout: createLayout({
			isChromeless: true,
		}),
		resources: [
			...getNavigationResources(),
			versionDetailsRelayResourceCustom,
			projectContextResource,
		],
		forPaint: [LazyVersionDetail],
		meta: {
			reporting: {
				id: ROUTE_NAMES_SOFTWARE_VERSION_DETAIL_EMBED,
				packageName: camelCase(ROUTE_NAMES_SOFTWARE_VERSION_DETAIL_EMBED),
				teamName: 'fusion-solaris',
			},
		},
	}),
);

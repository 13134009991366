import { fg } from '@atlassian/jira-feature-gating';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import getMeta from '@atlassian/jira-get-meta';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';

const isSynthetic = () =>
	// Filter out synthetic tests using all known ways
	getMeta('ajs-is-synthetic') === 'true' || fg('forge_ui_synthetic_check');

const FAILED_TO_FETCH = 'FAILED_TO_FETCH';

const getErrorType = (_error: unknown) => {
	if (_error == null) {
		return 'NULL_OR_UNDEFINED';
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const error = _error as Error;
	const message = String(error instanceof Error ? error.message : error).trim();

	const aggGiraServerErrorRegex = new RegExp(
		'The underlying service gira status code is: (\\d+)$',
		'im',
	);
	if (aggGiraServerErrorRegex.test(message)) {
		const match = message.match(aggGiraServerErrorRegex);
		const statusCode = match?.[1] ?? 'NO_STATUS_CODE';

		return `AGG_GIRA_ERROR_${statusCode}`;
	}

	const graphqlErrorRegex = new RegExp('^GraphQL error', 'i');
	if (graphqlErrorRegex.test(message)) {
		return 'GRAPHQL_ERROR';
	}

	if (isClientFetchError(error)) {
		return FAILED_TO_FETCH;
	}

	return 'OTHER';
};

export const transformError = (error: Error) => ({
	...error,
	stack: error.stack,
	message: error?.message,
});

export const createAttributes = (rawAttributes?: Attributes): Attributes => {
	const attributes: Attributes = {
		isSynthetic: isSynthetic(),
		isSSR: __SERVER__,
	};

	if (rawAttributes) {
		const { error } = rawAttributes;

		if (error) {
			attributes.error = transformError(error);
			attributes.errorType = getErrorType(error);
		}
	}

	return { ...rawAttributes, ...attributes };
};

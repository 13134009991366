import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import DashboardIcon from '@atlaskit/icon/core/dashboard';
import {
	SECTION_ITEM_RECENT,
	SECTION_ITEM_STARRED,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { ENTITY_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import type { DashboardMenuItem$key } from '@atlassian/jira-relay/src/__generated__/DashboardMenuItem.graphql';
import { AnalyticContext } from '../../controllers/analytic-context/index.tsx';
import { DashboardPopupMenu } from '../dashboard-popup-menu/DashboardPopupMenu.tsx';

type DashboardMenuItemProps = {
	queryRef: DashboardMenuItem$key;
};

export function DashboardMenuItem({ queryRef }: DashboardMenuItemProps) {
	const data = useFragment<DashboardMenuItem$key>(
		graphql`
			fragment DashboardMenuItem on JiraDashboard {
				title @required(action: THROW)
				dashboardId @required(action: THROW)
				favouriteValue {
					isFavourite
				}
				...DashboardPopupMenu
			}
		`,
		queryRef,
	);

	const { fireUIAnalyticForDashboardItems } = useContext(AnalyticContext);

	if (!data) {
		return null;
	}

	const dashboardUrl = `/jira/dashboards/${data.dashboardId}`;

	return (
		<Nav4MenuLinkItem
			href={dashboardUrl}
			menuId={ENTITY_ID.DASHBOARD(String(data.dashboardId))}
			actionsOnHover={<DashboardPopupMenu dashboardData={data} />}
			elemBefore={<DashboardIcon label="" color="currentColor" />}
			onClick={() => {
				fireUIAnalyticForDashboardItems({
					itemId: String(data.dashboardId),
					section: data?.favouriteValue?.isFavourite ? SECTION_ITEM_STARRED : SECTION_ITEM_RECENT,
				});
			}}
		>
			{data.title}
		</Nav4MenuLinkItem>
	);
}

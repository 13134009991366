import React, { type ReactNode } from 'react';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { createContainer, createHook, createStore } from '@atlassian/react-sweet-state';
import type { Store, Props } from './types.tsx';
import { getASTAndClausesFromJQL } from './utils.tsx';

type Actions = {};
const actions = Object.freeze<Actions>({});

const store = createStore<Store, Actions>({
	initialState: {
		ast: {
			query: undefined,
			represents: '',
			errors: [],
		},
		clauseMap: {},
		textSearchInputClause: undefined,
	},
	actions,
	name: 'JQLBasic-ASTService',
});

const Container = createContainer<Store, Actions, Props>(store, {
	onInit:
		() =>
		({ setState }, props) => {
			setState(getASTAndClausesFromJQL(props));
		},

	onUpdate:
		() =>
		({ setState }, props) => {
			setState(getASTAndClausesFromJQL(props));
		},
});

export type WrapperProps = {
	jql: string;
	onSwitchToJql?: () => void;
	children: ReactNode;
};

export const ASTContainer = ({
	jql,
	children,
	onSwitchToJql,
}: WrapperProps & {
	children: ReactNode;
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<Container jql={jql} createAnalyticsEvent={createAnalyticsEvent} onSwitchToJql={onSwitchToJql}>
			{children}
		</Container>
	);
};

export const useAST = createHook(store, {
	selector: ({ ast }) => ast,
});

export const useClauseMap = createHook(store, {
	selector: ({ clauseMap }) => clauseMap,
});

export const useTextSearchInputClause = createHook(store, {
	selector: ({ textSearchInputClause }) => textSearchInputClause,
});

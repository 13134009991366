import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createOverview: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-overviews.common.section-actions.create-overview',
		defaultMessage: 'Create overview',
		description:
			'Used as the alt text for an icon button that when used navigates to create overview',
	},
	moreThanTenWarning: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-overviews.common.section-actions.more-than-ten-warning',
		defaultMessage: 'You’ve reached the limit of 10 overviews.',
		description:
			'Used as the alt text for an icon button that when used navigates to create overview',
	},
});

/**
 * @generated SignedSource<<cf67fe5b58f692aebfb1ba734cbd9edd>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraJqlOperator = "CHANGED" | "CONTAINS" | "EQUALS" | "GREATER_THAN" | "GREATER_THAN_OR_EQUAL" | "IN" | "IS" | "IS_NOT" | "LESS_THAN" | "LESS_THAN_OR_EQUAL" | "NOT_CONTAINS" | "NOT_EQUALS" | "NOT_IN" | "WAS" | "WAS_IN" | "WAS_NOT" | "WAS_NOT_IN" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type useQuery$data = {
  readonly jira?: {
    readonly jqlBuilder: {
      readonly hydrateJqlQuery?: {
        readonly fields?: ReadonlyArray<{
          readonly field?: {
            readonly operators: ReadonlyArray<JiraJqlOperator>;
            readonly searchTemplate: {
              readonly key: string | null | undefined;
            } | null | undefined;
            readonly type: string | null | undefined;
          };
          readonly jqlTerm?: string;
          readonly values?: ReadonlyArray<{
            readonly __typename: "JiraJqlQueryHydratedError";
            readonly jqlTerm: string;
          } | {
            readonly __typename: "JiraJqlQueryHydratedValue";
            readonly jqlTerm: string;
            readonly values: ReadonlyArray<{
              readonly __typename: "JiraJqlCascadingOptionFieldValue";
              readonly displayName: string;
              readonly jqlTerm: string;
              readonly optionId: string | null | undefined;
              readonly parentOption: {
                readonly displayName: string;
                readonly jqlTerm: string;
                readonly optionId: string | null | undefined;
              } | null | undefined;
            } | {
              // This will never be '%other', but we need some
              // value in case none of the concrete values match.
              readonly __typename: "%other";
            } | null | undefined>;
          } | {
            // This will never be '%other', but we need some
            // value in case none of the concrete values match.
            readonly __typename: "%other";
          } | null | undefined>;
        }>;
      } | null | undefined;
      readonly hydrateJqlQueryForFilter?: {
        readonly fields?: ReadonlyArray<{
          readonly field?: {
            readonly operators: ReadonlyArray<JiraJqlOperator>;
            readonly searchTemplate: {
              readonly key: string | null | undefined;
            } | null | undefined;
            readonly type: string | null | undefined;
          };
          readonly jqlTerm?: string;
          readonly values?: ReadonlyArray<{
            readonly __typename: "JiraJqlQueryHydratedError";
            readonly jqlTerm: string;
          } | {
            readonly __typename: "JiraJqlQueryHydratedValue";
            readonly jqlTerm: string;
            readonly values: ReadonlyArray<{
              readonly __typename: "JiraJqlCascadingOptionFieldValue";
              readonly displayName: string;
              readonly jqlTerm: string;
              readonly optionId: string | null | undefined;
              readonly parentOption: {
                readonly displayName: string;
                readonly jqlTerm: string;
                readonly optionId: string | null | undefined;
              } | null | undefined;
            } | {
              // This will never be '%other', but we need some
              // value in case none of the concrete values match.
              readonly __typename: "%other";
            } | null | undefined>;
          } | {
            // This will never be '%other', but we need some
            // value in case none of the concrete values match.
            readonly __typename: "%other";
          } | null | undefined>;
        }>;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "useQuery";
};
export type useQuery$key = {
  readonly " $data"?: useQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"useQuery">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "viewContext",
  "variableName": "viewContext"
},
v1 = {
  "kind": "ScalarField",
  "name": "jqlTerm"
},
v2 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v3 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v4 = {
  "kind": "ScalarField",
  "name": "optionId"
},
v5 = [
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "kind": "LinkedField",
        "name": "fields",
        "plural": true,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/),
              {
                "concreteType": "JiraJqlField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "operators"
                  },
                  {
                    "concreteType": "JiraJqlSearchTemplate",
                    "kind": "LinkedField",
                    "name": "searchTemplate",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "key"
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "type"
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "name": "values",
                "plural": true,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/),
                      (v1/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "values",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v2/*: any*/),
                              (v1/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              {
                                "concreteType": "JiraJqlCascadingOptionFieldValue",
                                "kind": "LinkedField",
                                "name": "parentOption",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v1/*: any*/),
                                  (v4/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraJqlCascadingOptionFieldValue"
                          }
                        ]
                      }
                    ],
                    "type": "JiraJqlQueryHydratedValue"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/),
                      (v1/*: any*/)
                    ],
                    "type": "JiraJqlQueryHydratedError"
                  }
                ]
              }
            ],
            "type": "JiraJqlQueryHydratedField"
          }
        ]
      }
    ],
    "type": "JiraJqlHydratedQuery"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "filterAri"
    },
    {
      "kind": "RootArgument",
      "name": "includeJira"
    },
    {
      "kind": "RootArgument",
      "name": "isFilter"
    },
    {
      "kind": "RootArgument",
      "name": "jql"
    },
    {
      "kind": "RootArgument",
      "name": "viewContext"
    }
  ],
  "kind": "Fragment",
  "name": "useQuery",
  "selections": [
    {
      "condition": "includeJira",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "cloudId",
                  "variableName": "cloudId"
                }
              ],
              "concreteType": "JiraJqlBuilder",
              "kind": "LinkedField",
              "name": "jqlBuilder",
              "plural": false,
              "selections": [
                {
                  "condition": "isFilter",
                  "kind": "Condition",
                  "passingValue": false,
                  "selections": [
                    {
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "query",
                          "variableName": "jql"
                        },
                        (v0/*: any*/)
                      ],
                      "kind": "LinkedField",
                      "name": "hydrateJqlQuery",
                      "plural": false,
                      "selections": (v5/*: any*/)
                    }
                  ]
                },
                {
                  "condition": "isFilter",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "id",
                          "variableName": "filterAri"
                        },
                        (v0/*: any*/)
                      ],
                      "kind": "LinkedField",
                      "name": "hydrateJqlQueryForFilter",
                      "plural": false,
                      "selections": (v5/*: any*/)
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "2f363277414286c5c850b5c424d00b46";

export default node;

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { helpCenterSettingsUnassignedTicketTypeFieldsRoute } from '@atlassian/jira-router-routes-help-center-settings-routes/src/helpCenterSettingsUnassignedTicketTypeFieldsRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { navigation } from './common/constants.tsx';
import {
	HelpCenterSettingsTicketTypeFields,
	LazyHelpCenterSettingsTicketTypeFields,
} from './ui/ticket-type-fields/index.tsx';

export const helpCenterSettingsTicketTypeFieldsRouteEntry: Route = createEntry(
	helpCenterSettingsUnassignedTicketTypeFieldsRoute,
	() => ({
		perfMetricKey: 'help-center-settings-request-types-fields',
		layout: serviceProjectLayout,
		navigation,
		component: componentWithCondition(
			() => fg('jcs_master_flag') && !fg('csm-request-intake-m1'),
			HelpCenterSettingsTicketTypeFields,
			ErrorPagesNotFound,
		),
		forPaint: [LazyAtlassianNavigation, LazyHelpCenterSettingsTicketTypeFields],
		resources: [...getServiceDeskSettingsResources()],
	}),
);

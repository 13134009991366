import React from 'react';
import type Page from '@atlassian/jira-board-settings-roadmap/src/ui/roadmap-settings/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import getMeta from '@atlassian/jira-get-meta';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyRoadmap = lazyForPaint<typeof Page>(
	() =>
		import(
			/* webpackChunkName: "async-board-settings-roadmap", jiraSpaEntry: "async-board-settings-roadmap" */ '@atlassian/jira-spa-apps-board-settings-roadmap'
		),
);

const AsyncProjectSettingsRoadmap = () => (
	<JSErrorBoundary
		id="project-settings.roadmap"
		teamName="chronos"
		attributes={{
			isSynthetic: fg('is_synthetic_tenant') || getMeta('ajs-is-synthetic') === 'true',
		}}
	>
		<LazyPage Page={LazyRoadmap} pageId="board-settings-roadmap" shouldShowSpinner={false} />
	</JSErrorBoundary>
);

export default AsyncProjectSettingsRoadmap;

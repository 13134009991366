import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { projectSettingsServicedeskLanguageKeyRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskLanguageKeyRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import {
	languageTranslationsResource,
	outgoingEmailStateModelResource,
} from '@atlassian/jira-servicedesk-language-settings-translations-resource/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router/src/common/types.tsx';
import ServiceDeskLanguageTranslations, {
	LazyProjectSettingsServiceDeskLanguageTranslations,
} from './ui/service-desk-language-translations/index.tsx';

export const projectSettingsServicedeskLanguageKeyRouteEntry: Route = createEntry(
	projectSettingsServicedeskLanguageKeyRoute,
	() => ({
		group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
		ufoName: 'service-management.translations',
		component: ServiceDeskLanguageTranslations,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getServiceDeskSettingsResources(),
			getConsolidationStateResource(),
			languageTranslationsResource,
			outgoingEmailStateModelResource,
		],
		meta: {
			reporting: {
				id: APP_NAMES.LANGUAGE_SETTINGS,
				packageName: toPackageName(APP_NAMES.LANGUAGE_SETTINGS),
				teamName: 'jsm-banana',
			},
		},
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskLanguageTranslations],
		perfMetricKey: 'jsm-language-translations',
	}),
);

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createProject: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-projects-core.common.action-buttons.create-project',
		defaultMessage: 'Create project',
		description:
			'Used as the alt text for an icon button that when used navigates to create project',
	},
	manageProjects: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-projects-core.common.action-buttons.manage-projects',
		defaultMessage: 'Manage projects',
		description: 'Used as the menu-title for the Manage Projects menu link item',
	},
});

import { useEffect, useState } from 'react';
import type { EmojiProvider, EmojiDescription } from '@atlaskit/emoji';
import { getEmojiProviderWithCustomEmoji } from '@atlassian/jira-common-atlaskit-services/src/emoji.tsx';
import {
	createHook,
	createStore,
	createContainer,
	createSelector,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import type { State, Props } from './types.tsx';

const actions = {
	loadEmoji:
		(emojiId: string) =>
		async ({ getState, setState }: StoreActionApi<State>) => {
			const { provider } = getState();
			if (!provider) {
				return;
			}

			const resolvedProvider = await provider;
			const optionalDescription = await resolvedProvider.findById(emojiId);
			const emoji =
				optionalDescription !== undefined
					? await resolvedProvider.getMediaEmojiDescriptionURLWithInlineToken(optionalDescription)
					: optionalDescription;

			setState({ cachedEmojis: { ...getState().cachedEmojis, [emojiId]: emoji ?? null } });
		},
};

type Actions = typeof actions;

const initialState: State = {
	provider: undefined,
	cachedEmojis: {},
};

const EmojiStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisEmojiStore',
});

const useEmojis = createHook(EmojiStore);

/**
 * used in various places to render emojis
 */
export const useEmoji = (emojiId?: string) => {
	const [emoji, setEmoji] = useState<EmojiDescription | undefined>(undefined);
	const [{ provider }] = useEmojis();

	useEffect(() => {
		if (emojiId !== undefined && provider !== undefined) {
			provider.then((resolvedProvider: EmojiProvider) => {
				Promise.resolve(resolvedProvider.findById(emojiId))
					.then((optionalDescription) =>
						optionalDescription !== undefined
							? resolvedProvider.getMediaEmojiDescriptionURLWithInlineToken(optionalDescription)
							: optionalDescription,
					)
					.then(setEmoji);
			});
		} else {
			setEmoji(undefined);
		}
	}, [provider, emojiId]);

	return emojiId !== undefined ? emoji : undefined;
};

export const useEmojiById = createHook(EmojiStore, {
	selector: createSelector(
		(state, { emojiId }: { emojiId?: string }) =>
			emojiId ? state.cachedEmojis[emojiId] : undefined,
		(emoji) => emoji,
	),
});

/**
 * used to provide ak/emoji-picker with an emoji provider
 */
export const useEmojiProviderPromise = (): Promise<EmojiProvider> => {
	const [{ provider }] = useEmojis();
	return provider !== undefined
		? provider
		: Promise.reject(new Error('emoji provider not initialized'));
};

export const EmojiContainer = createContainer<State, Actions, Props>(EmojiStore, {
	onInit:
		() =>
		(storeActionApi, { cloudId, currentUser }) => {
			storeActionApi.setState({
				provider: getEmojiProviderWithCustomEmoji(cloudId, currentUser) || undefined,
			});
		},
});

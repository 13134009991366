import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_HOME } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { homeRedirectRoute } from '@atlassian/jira-router-routes-home-redirect-routes/src/homeRedirectRoute.tsx';
import JiraBaseRedirect from './common/ui/redirect/jira-base-redirect/index.tsx';

export const homeRedirectRouteEntry = createEntry(homeRedirectRoute, () => ({
	group: ROUTE_GROUPS_HOME,
	component: JiraBaseRedirect,
	layout: globalLayout,
	resources: [...getNavigationResources()],
	isRedirect: true,
	forPaint: [LazyAtlassianNavigation],
}));

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { AsyncHorizontalNavPlans } from '@atlassian/jira-horizontal-nav-plans/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarPlan from '@atlassian/jira-navigation-apps-sidebar-plan/src/async.tsx';
import { planLayout } from '@atlassian/jira-plan-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import {
	advancedRoadmapsPlanPageInitialChecksResource,
	advancedRoadmapsReduxStoreStateResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarPlanResources,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { planIncrementRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-spa-routes/src/planIncrementRoute.tsx';
import { PlanIncrement, LazyPlanIncrement } from './ui/plan-increment/index.tsx';

// "Increment" board page
export const planIncrementRouteEntry = createEntry(planIncrementRoute, () => ({
	group: ROUTE_GROUPS_SOFTWARE,
	component: PlanIncrement,
	layout: composeLayouts(globalLayout, planLayout),
	navigation: {
		menuId: MENU_ID.PLANS,
		sidebar: AsyncSidebarPlan,
		horizontal: AsyncHorizontalNavPlans,
		onlyShowHorizontalOnNav4: true,
	},
	preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarPlanResources(),
		advancedRoadmapsPlanPageInitialChecksResource,
		advancedRoadmapsReduxStoreStateResource,
	],
	forPaint: [LazyAtlassianNavigation, LazyPlanIncrement],
}));

import React, { forwardRef, type Ref } from 'react';
import ShowMoreHorizontalIcon from '@atlaskit/icon/utility/show-more-horizontal';
import { useIntl } from '@atlassian/jira-intl';
import { CommonNavMenuButton } from '../../../common/ui/common-nav-menu-button/main.tsx';
import messages from './messages.tsx';
import type { MoreNavMenuButtonTriggerProps } from './types.tsx';

export const MoreNavMenuButtonTrigger = forwardRef(
	({ name, ...props }: MoreNavMenuButtonTriggerProps, ref: Ref<HTMLButtonElement>) => {
		const { formatMessage } = useIntl();

		return (
			<CommonNavMenuButton
				{...props}
				icon={ShowMoreHorizontalIcon}
				label={
					name
						? formatMessage(messages.moreActionsFor, { name })
						: formatMessage(messages.moreActions)
				}
				ref={ref}
			/>
		);
	},
);

import React, { useEffect } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/ScreenRedirect.tsx';
import { useRouter } from '@atlassian/react-resource-router';

// This component will redirect users to the new routes.
// Previously we had a shared route with this redirect behind a FF
// Now, this is a permanent redirect
// We can remove this component once the frequency of the operational event is 0
export const RedirectToExactMetricRoutes = () => {
	const [{ match }] = useRouter();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		// If we have a metric key in the URL, we know the user has accessed the old route
		// and we should fire an operational analytics event.
		// Once the frequency of this event is 0, we can consider removing the redirect.
		if (match?.params?.metricKey) {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'old-metric-detail-route visited', {
				metricKey: match.params.metricKey,
			});
		}
	}, [match?.params?.metricKey, createAnalyticsEvent]);

	if (match == null || match.params == null || match.params.metricKey == null) {
		return <ScreenRedirect to="/jira/settings/system/performance/overview" />;
	}
	return (
		<ScreenRedirect to={`/jira/settings/system/performance/metrics/${match.params.metricKey}`} />
	);
};

import React from 'react';
import queryString from 'query-string';
import type NextGenBoardType from '@atlassian/jira-spa-apps-next-gen-board/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { RouteContext } from '@atlassian/react-resource-router';

export const LazyBoardPage = lazyForPaint<typeof NextGenBoardType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-next-gen-board", jiraSpaEntry: "next-gen-board" */ '@atlassian/jira-spa-apps-next-gen-board'
		),
	),
);

type Props = RouteContext;
type GetBoardIdArgs = {
	location: RouteContext['location'];
	match: RouteContext['match'];
};

const getBoardId = ({ location, match }: GetBoardIdArgs): string => {
	if (match && match.params.boardId) {
		return match.params.boardId;
	}
	const { rapidView } = queryString.parse(location.search);
	return rapidView;
};

export const SoftwareBoardComponent = ({ location, match }: Props) => (
	<LazyPage
		Page={LazyBoardPage}
		pageId={`next-gen-board-${getBoardId({ location, match })}`}
		shouldShowSpinner={false}
	/>
);

/**
 * @generated SignedSource<<d7cdac59853cc92eddbc34749c0f124e>>
 * @relayHash 5a3ca0a69b27a7a408637e0a2690239d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e8544bded469142a9fb6084a3dfa87bba72016d356584c02847747a9162d0b43

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessFormBuilderQuery } from './src_jiraBusinessFormBuilderQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessFormBuilderQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "e8544bded469142a9fb6084a3dfa87bba72016d356584c02847747a9162d0b43",
    "metadata": {},
    "name": "src_jiraBusinessFormBuilderQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import React from 'react';

import type { NewCoreIconProps } from '@atlaskit/icon';
import AKOperationsIcon from '@atlaskit/icon/core/operations';
import ShieldIcon from '@atlaskit/icon/core/shield';
import { fg } from '@atlassian/jira-feature-gating';

const OperationsIcon = ({ ...props }: NewCoreIconProps) => {
	if (fg('nav4_operations_icon_update')) {
		return <AKOperationsIcon {...props} />;
	}

	return <ShieldIcon {...props} />;
};

export default OperationsIcon;

{
	"name": "@atlassian/post-office-placement-shared",
	"version": "0.4.1",
	"packageManager": "yarn@4.2.2",
	"sideEffects": false,
	"source": "./src/index.ts",
	"main": "./dist/cjs/index.js",
	"module": "./dist/esm/index.js",
	"types": "./dist/types/index.d.ts",
	"exports": {
		".": "./src/index.ts",
		"./request-initial-data": "./src/initialDataClient.ts"
	},
	"publishConfig": {
		"exports": {
			"import": "./dist/esm/index.js",
			"require": "./dist/cjs/index.js",
			"types": "./dist/types/index.d.ts"
		}
	},
	"repository": {
		"type": "git",
		"url": "git+https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/browse/post-office.git",
		"directory": "packages/post-office-clients/post-office-placement-shared"
	},
	"files": [
		"README.md",
		"dist"
	],
	"scripts": {
		"prepack": "atlas packages permission grant && yarn exec parcel build",
		"publish:patch": "yarn version patch && yarn npm publish",
		"publish:minor": "yarn version minor && yarn npm publish"
	},
	"peerDependencies": {
		"react": "^16.8.0 || ^17.0.0 || ^18.2.0",
		"react-dom": "^16.8.0 || ^17.0.0 || ^18.2.0"
	},
	"dependencies": {
		"@atlaskit/analytics-namespaced-context": "workspace:^",
		"@atlaskit/feature-gate-js-client": "workspace:^",
		"@atlassian/post-office-context": "workspace:^",
		"@atlassian/post-office-frontend-performance-tracking": "workspace:^",
		"@atlassian/post-office-placement-data": "workspace:^",
		"@compiled/react": "^0.17.3"
	},
	"devDependencies": {
		"@testing-library/jest-dom": "^6.4.2",
		"@testing-library/react": "14.2.1",
		"@testing-library/react-hooks": "^8.0.1",
		"@types/node": "^20.11.27",
		"@types/react": "^18.2.33",
		"@types/react-dom": "^18.2.14",
		"eslint": "^8.57.0",
		"jest": "^29.7.0",
		"jest-environment-jsdom": "^29.7.0",
		"npm-run-all2": "^5.0.0",
		"parcel": "^2.6.0",
		"react": "^18.2.0",
		"typescript": "~5.4.2"
	},
	"targets": {
		"main": {
			"optimize": true
		},
		"module": {
			"optimize": true
		}
	}
}

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	// Delete createBoardDialogLabel when cleaning up nav4_simplified_board_create_beta
	createBoardDialogLabel: {
		id: 'software-board-create.modal.create-board-dialog-label',
		defaultMessage: 'Create board dialog, {boardDescription}',
		description:
			'Aria label for the create board dialog. The {boardDescription} is either the scrumBoardCreationDescription defaultMessage or kanbanBoardCreationDescription defaultMessage depending on board type',
	},
	loadingLabel: {
		id: 'software-board-create.modal.loading-label',
		defaultMessage: 'Loading',
		description: 'Aria label for loading component',
	},
});

/**
 * @generated SignedSource<<b02630a993ab57f513c5d9a5a5ea9816>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4FilterActions$data = {
  readonly __typename: string;
  readonly filterId: string;
  readonly id: string;
  readonly isEditable?: boolean | null | undefined;
  readonly isFavourite: boolean | null | undefined;
  readonly jql: string;
  readonly name: string;
  readonly owner?: {
    readonly accountId: string;
  } | null | undefined;
  readonly " $fragmentType": "Nav4FilterActions";
};
export type Nav4FilterActions$key = {
  readonly " $data"?: Nav4FilterActions$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4FilterActions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "Nav4FilterActions",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "isFavourite"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "filterId"
    },
    {
      "kind": "ScalarField",
      "name": "jql"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        },
        {
          "kind": "LinkedField",
          "name": "owner",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "accountId"
            }
          ]
        }
      ],
      "type": "JiraCustomFilter"
    }
  ],
  "type": "JiraFilter",
  "abstractKey": "__isJiraFilter"
};

(node as any).hash = "ceac0ec9e5b4a025e25fe096d35c5d3b";

export default node;

import type { ComponentType } from 'react';
import type { TerminalClause, CompoundClause, Operator, OperatorValue } from '@atlaskit/jql-ast';
import type {
	OptionResponse,
	EndCursor,
	FieldOption,
	FieldType,
	SearchTemplate,
} from '@atlassian/jira-jql-builder-basic-picker/src/common/types.tsx';
import type { useQuery$data } from '@atlassian/jira-relay/src/__generated__/useQuery.graphql';

export type NoOptionComponentType = ComponentType<{
	onClickLink?: () => void;
	sourceId?: string;
}>;

export const PickerTypes = {
	CascadeSelect: 'CascadeSelect',
	DateTime: 'DateTime',
	DueDate: 'DueDate',
	DateTimeRange: 'DateTimeRange',
	NumberRange: 'NumberRange',
	Picker: 'Picker',
	Text: 'Text',
	TextArea: 'TextArea',
	BooleanType: 'BooleanType',
	Unsupported: 'Unsupported',
} as const;

export type PickerTypes = (typeof PickerTypes)[keyof typeof PickerTypes];

export const PickerPropTypes = {
	CommonWithEmpty: 'CommonWithEmpty',
	Common: 'Common',
	IssueType: 'IssueType',
	Project: 'Project',
	Sprint: 'Sprint',
	Version: 'Version',
	User: 'User',
	Group: 'Group',
} as const;

export type PickerPropTypes = (typeof PickerPropTypes)[keyof typeof PickerPropTypes];

export const TextAreaPropTypes = {
	Text: 'Text',
	Number: 'Number',
	Aql: 'Aql',
	ExactText: 'ExactText',
} as const;

export type TextAreaPropTypes = (typeof TextAreaPropTypes)[keyof typeof TextAreaPropTypes];

export const GenericPropTypes = {
	NoProp: 'NoProp',
} as const;

export type GenericPropTypes = (typeof GenericPropTypes)[keyof typeof GenericPropTypes];

// The values in this union should all be unique to ensure type safety
export type PropTypes = PickerPropTypes | TextAreaPropTypes | GenericPropTypes;

/**
 * Data for rendering a JQL Builder Basic picker component
 */
export type PickerData = {
	searchTemplate: SearchTemplate;
	fieldType: FieldType;
	pickerType: PickerTypes;
	propType: PropTypes;
	allowedOperators?: Operator[];
};

/**
 * Data used for rendering a field (as a whole) on the JQL Builder Basic. This
 * should include all data required for rendering both the picker, trigger, and
 * the "More" fields dropdown selected option.
 *
 * Therefore, this object must contain all data necessary to create a FieldOption
 */
export type FieldToRender = {
	fieldId: string;
	fieldLabel?: string;
	picker: PickerData;
	clause: TerminalClause | CompoundClause | undefined;
	secondaryClause: TerminalClause | CompoundClause | undefined;
	/**
	 * In basic mode, custom field pickers render with a tooltip containing a
	 * description of the field. This type represents that description data.
	 */
	fieldDescription: string | null;
	/**
	 * Field type name displayed in UI.
	 * This is used for the selected options in the more+ dropdown as it relies on hydrated data.
	 */
	fieldTypeDisplayName: string | null;
	shouldShowInContext?: boolean | null;
	isIrremovable?: boolean;
};

export type HydrationValues =
	| NonNullable<
			NonNullable<
				NonNullable<
					NonNullable<
						NonNullable<NonNullable<useQuery$data>['jira']>['jqlBuilder']
					>['hydrateJqlQuery']
				>['fields']
			>[number]['values']
	  >
	| undefined;

/**
 * This is the base data for a field, representing the information we get back
 * from the Hydration query. We must be able to use this
 */
export type FieldData = {
	jqlTerm: string;
	searchTemplate: SearchTemplate;
	fieldType: FieldType;
	operators: OperatorValue[];
	hydratedValues?: HydrationValues;
};

export type FieldDataMap = {
	[fieldId: string]: FieldData;
};

type LoadOptionsType = (
	search: string,
	renderInitialValues: boolean,
	endCursor: EndCursor,
) => Promise<OptionResponse>;

export type PropsResolver = {
	enableFullList?: boolean;
	operandDisplayValues?: string[];
	showOperandDisplayValuesInFullList?: boolean;
	loadOptions: LoadOptionsType;
};

export type RegisterSearchCriteriaProps = {
	fieldsToRender: FieldToRender[];
	selectedFields: FieldOption[];
	onChange: OnShortcutDialogChange;
};

export type OnClearPicker = (args: {
	selectedFields: FieldOption[];
	fieldsToRender?: FieldToRender[];
}) => void;

type OnShortcutDialogChange = (args: {
	selectedFields?: FieldOption[];
	fieldsToRender?: FieldToRender[];
}) => void;

export type OnSearchWrapper = (jql: string) => void;

export type OnSwitchToJql = () => void;

export type TextSearchInputClauseType = CompoundClause | TerminalClause | undefined;

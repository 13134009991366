import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { projectSettingsServicedeskNotificationsCustomerNotificationsRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-customer-notifications-routes/src/projectSettingsServicedeskNotificationsCustomerNotificationsRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { ServiceDeskCustomerNotificationsNextGenRedirect } from './ui/service-desk-customer-notifications-next-gen-redirect/index.tsx';
import ServiceDeskCustomerNotifications, {
	LazyProjectSettingsServiceDeskCustomerNotifications,
} from './ui/service-desk-customer-notifications/index.tsx';

export const projectSettingsServicedeskNotificationsCustomerNotificationsRouteEntry: Route =
	createEntry(projectSettingsServicedeskNotificationsCustomerNotificationsRoute, () => ({
		group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
		component: componentWithFG(
			'update_jsm_project_settings_pages_urls',
			ServiceDeskCustomerNotificationsNextGenRedirect,
			ServiceDeskCustomerNotifications,
		),
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskCustomerNotifications],
	}));

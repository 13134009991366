import { useMemo } from 'react';
import {
	useEntryPointButtonTrigger,
	getDefaultEntrypointActionEventMap,
} from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import type { EntryPointActions } from '@atlassian/jira-entry-point/src/controllers/utils/types.tsx';

export type ExpandableEntryPointTrigger = (element: HTMLElement | null) => void;

export const useExpandableEntryPointTrigger = (
	actions: EntryPointActions,
	isActive = true,
): ExpandableEntryPointTrigger => {
	// We don't want the click-handler, as ExpandableMenuItem handles the entry-point loading itself
	const eventMap = useMemo(
		() =>
			Object.fromEntries(
				Object.entries(getDefaultEntrypointActionEventMap(actions)).filter(
					([key]) => key !== 'click',
				),
			),
		[actions],
	);

	return useEntryPointButtonTrigger(actions, isActive, eventMap);
};

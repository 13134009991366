import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	projectActionsMenu: {
		id: 'navigation-project-action-menu.menu-container.project-actions-menu',
		defaultMessage: 'More actions',
		description:
			'Used as the alt text for an icon button that when used shows a popup menu of more options.',
	},
});

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { projectSettingsServicedeskAutomationCreateRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-routes/src/projectSettingsServicedeskAutomationCreateRoute.tsx';
import { getLegacyAutomationRoutesCommon } from './common/getLegacyAutomationRoutesCommon.tsx';
import ServiceDeskAutomationCreateRule, {
	LazyProjectSettingsServiceDeskAutomationCreateRule,
} from './ui/service-desk-automation/create-rule/index.tsx';

export const projectSettingsServicedeskAutomationCreateRouteEntry = createEntry(
	projectSettingsServicedeskAutomationCreateRoute,
	() => ({
		group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
		...getLegacyAutomationRoutesCommon(),
		ufoName: 'service-management.automation.create-rule',
		component: ServiceDeskAutomationCreateRule,
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskAutomationCreateRule],
	}),
);

import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { COMPANY_MANAGED_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { JswProjectMenu$key } from '@atlassian/jira-relay/src/__generated__/JswProjectMenu.graphql';
import { JswExpandableProject } from './jsw-expandable-project/JswExpandableProject.tsx';
import { JswNonExpandableProject } from './jsw-non-expandable-project/JswNonExpandableProject.tsx';

type JswProjectMenuProps = {
	projectData: JswProjectMenu$key;
	spotlight?: string;
};

export const JswProjectMenu = ({ projectData, spotlight }: JswProjectMenuProps) => {
	const project = useFragment<JswProjectMenu$key>(
		graphql`
			fragment JswProjectMenu on JiraProject {
				softwareBoardCount @optIn(to: "JiraProjectSoftwareBoardCount")
				projectStyle
				...JswNonExpandableProject
				...JswExpandableProject
			}
		`,
		projectData,
	);

	if (
		project.softwareBoardCount &&
		project.softwareBoardCount > 0 &&
		project.projectStyle === COMPANY_MANAGED_PROJECT
	) {
		return <JswExpandableProject projectData={project} spotlight={spotlight} />;
	}

	return <JswNonExpandableProject projectData={project} spotlight={spotlight} />;
};

import type { Action } from '@atlassian/react-sweet-state';
import type { State, WithSilent } from './types.tsx';
import { update } from './utils.tsx';

const changeboardingActions = {
	initChangeboarding:
		(): Action<State> =>
		async ({ getState, setState }) => {
			await getState()._rehydratePromise;
			if (getState().changeboarding?.stage === undefined) {
				setState({
					changeboarding: { stage: 'READY' },
				});
			}
		},

	closeChangeboarding:
		({ silent }: WithSilent<{}> = { silent: true }, at: Date = new Date()): Action<State> =>
		({ dispatch }) => {
			dispatch(
				update({ ui: !silent, persist: true })({
					changeboarding: { stage: 'CLOSED', closedAt: at },
				}),
			);
		},
};

const onboardingActions = {
	initOnboarding:
		(): Action<State> =>
		async ({ getState, setState }) => {
			await getState()._rehydratePromise;
			if (getState().onboarding?.stage === undefined) {
				setState({
					onboarding: { stage: 'READY' },
				});
			}
		},

	closeOnboarding:
		({ silent }: WithSilent<{}> = { silent: true }): Action<State> =>
		({ dispatch }) => {
			dispatch(
				update({ ui: !silent, persist: true })({
					onboarding: { stage: 'CLOSED' },
				}),
			);
		},
};

export const actions = {
	...changeboardingActions,
	...onboardingActions,
};

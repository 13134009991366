import { fg } from '@atlassian/jira-feature-gating';
import { createRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
// eslint-disable-next-line jira/import-whitelist -- Unclear how to change the config whilst we transition to flat config
import QUERY from '@atlassian/jira-relay/src/__generated__/mainVirtualServiceAgentCtaCardQuery$parameters';
import type { mainVirtualServiceAgentCtaCardQuery } from '@atlassian/jira-relay/src/__generated__/mainVirtualServiceAgentCtaCardQuery.graphql';

export const virtualAgentKnowledgebaseTouchpointResource =
	createRelayResource<mainVirtualServiceAgentCtaCardQuery>({
		type: 'SERVICEDESK_VIRTUAL_AGENT_KNOWLEDGE_BASE_TOUCHPOINT',
		isBrowserOnly: true,
		getQuery: ({ match }, { tenantContext }) => ({
			parameters: QUERY,
			variables: {
				cloudId: tenantContext.cloudId,
				key: match.params.projectKey || '',
				checkForAiEnabledFF: fg('require_ai_enabled_for_jsm_virtual_agent'),
			},
		}),
	});

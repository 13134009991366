/**
 * @generated SignedSource<<a8032edb42a00ca36d90ea2a71940ad1>>
 * @relayHash 2aa586fca9287e14efbd4be2921d5767
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 368d9d372032df69260cc5161593f57aac8d176f1a1801a41074fde2c2cd9c37

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentPerformanceQuery } from './srcVirtualAgentPerformanceQuery.graphql';

import checkForAiEnabledFF_provider from '@atlassian/jira-relay-provider/src/jsm-va-is-require-ai-enabled.relayprovider';
import roiTeaserM1Enabled_provider from '@atlassian/jira-relay-provider/src/jsm-va-roi-teaser-m1-enabled.relayprovider';

const node: PreloadableConcreteRequest<srcVirtualAgentPerformanceQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "368d9d372032df69260cc5161593f57aac8d176f1a1801a41074fde2c2cd9c37",
    "metadata": {},
    "name": "srcVirtualAgentPerformanceQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaroiteaserm1enabledrelayprovider": roiTeaserM1Enabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider": checkForAiEnabledFF_provider
    }
  }
};

export default node;

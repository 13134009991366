import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import { createJpdExperience } from '../../utils/experience/main.tsx';

const dialogLoad = createJpdExperience(
	new UFOExperience('jpd.shared.control-dialog-load', {
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	}),
);

const loadStakeholders = createJpdExperience(
	new UFOExperience('jpd.shared.load-stakeholders', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const addStakeholders = createJpdExperience(
	new UFOExperience('jpd.shared.add-stakeholders', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const removeStakeholders = createJpdExperience(
	new UFOExperience('jpd.shared.remove-stakeholders', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadSettings = createJpdExperience(
	new UFOExperience('jpd.shared.load-settings', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const updateSettings = createJpdExperience(
	new UFOExperience('jpd.shared.update-settings', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadSiteConfig = createJpdExperience(
	new UFOExperience('jpd.shared.load-site-config', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadSitePublicSharingConfig = createJpdExperience(
	new UFOExperience('jpd.shared.load-site-public-sharing-config', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	dialogLoad,
	loadStakeholders,
	addStakeholders,
	removeStakeholders,
	loadSettings,
	updateSettings,
	loadSiteConfig,
	loadSitePublicSharingConfig,
};

const getForgeQuery = () =>
	`forge {
        panels {
          id
          instances {
            id
            collapsed
            added
          }
        }
      }`;

export const getEcosystemQuery = () =>
	`
    ecosystem {
      activityPanels {
        name
        iframe {
          addonKey
          moduleKey
          options
        }
      }
      contextPanels {
        name
        iframe {
          addonKey
          moduleKey
          options
        }
        icon {
          url
        }
        content
        status
        type
      }
      ${getForgeQuery()}
    }
`;

export const getLegacyRightWebPanelsQuery = () => `
        legacyRightWebPanels {
          edges {
            node {
              name
              status
              addonKey
              moduleKey
              options
              content
            }
          }
        }
      `;

import React, { useMemo, type ComponentType } from 'react';
import { styled } from '@compiled/react';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { SearchMode } from '../../common/types.tsx';
import { Divider } from '../../styled.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const StyledChild = styled.li({
	display: 'flex',
	margin: 0,
	listStyle: 'none',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Container = styled.div({
	display: 'flex',
	flexGrow: 0,
	flexShrink: 0,
});

type Props = {
	searchMode: SearchMode;
	renderExtraControls: () => ComponentType<{
		searchMode: SearchMode;
	}>[];
	shouldUseListMarkup?: boolean;
};

const ExtraControls = ({ searchMode, renderExtraControls, shouldUseListMarkup }: Props) => {
	const extraControls = useMemo(() => renderExtraControls(), [renderExtraControls]);

	const shouldApplyVisualRefreshChanges = isVisualRefreshEnabled() && fg('visual-refresh_drop_2');

	const content = shouldUseListMarkup ? (
		extraControls.map((Component, index) => (
			<StyledChild key={index}>
				{index === 0 && !shouldApplyVisualRefreshChanges && <Divider />}
				<Component searchMode={searchMode} />
			</StyledChild>
		))
	) : (
		<Container>
			{!shouldApplyVisualRefreshChanges && <Divider />}
			{extraControls.map((Component, index) => (
				<Component key={index} searchMode={searchMode} />
			))}
		</Container>
	);

	return <>{extraControls.length > 0 && content}</>;
};

export default ExtraControls;

import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useIsPremiumJSW } from '@atlassian/jira-atlassian-navigation/src/controllers/edition/index.tsx';
import { MENU_ID_PLANS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { useSidebarNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-context/index.tsx';
import { useNavigationPlansVisibility } from '@atlassian/jira-plans-item-visibility/src/index.tsx';
import type { Nav4Plans$key } from '@atlassian/jira-relay/src/__generated__/Nav4Plans.graphql';
import { Nav4PlansActionButtons } from './common/action-buttons/Nav4PlansActionButtons.tsx';
import { Nav4PlansDefault } from './default/Nav4PlansDefault.tsx';
import { Nav4PlansForLanding } from './for-landing/Nav4PlansForLanding.tsx';
import { Nav4PlansPremiumUpsellFeatureGate } from './premium-upsell-feature-gate/Nav4PlansPremiumUpsellFeatureGate.tsx';

export type Nav4PlansProps = {
	isExpanded: boolean;
	queryRef: Nav4Plans$key;
};

export function Nav4Plans({ isExpanded, queryRef }: Nav4PlansProps) {
	const data = useFragment<Nav4Plans$key>(
		graphql`
			fragment Nav4Plans on Query {
				...Nav4PlansForLanding @include(if: $isPlansExpanded)
				jira @optIn(to: "JiraAdvancedRoadmapsNavigation") {
					advancedRoadmapsNavigation(cloudId: $cloudId) {
						hasEditPermissions
						hasCreateSamplePlanPermissions
						hasGlobalPlansAdminPermissions
					}
				}
			}
		`,
		queryRef,
	);
	const { isInitialSelectedPath } = useSidebarNav4();
	const hasEditPermissions = data.jira?.advancedRoadmapsNavigation?.hasEditPermissions ?? false;
	const hasCreateSamplePlanPermissions =
		data.jira?.advancedRoadmapsNavigation?.hasCreateSamplePlanPermissions ?? false;
	const hasGlobalPlansAdminPermissions =
		data.jira?.advancedRoadmapsNavigation?.hasGlobalPlansAdminPermissions ?? false;
	const actionButtons = useMemo(
		() => (
			<Nav4PlansActionButtons
				hasEditPermissions={hasEditPermissions}
				hasCreateSamplePlanPermissions={hasCreateSamplePlanPermissions}
				hasGlobalPlansAdminPermissions={hasGlobalPlansAdminPermissions}
			/>
		),
		[hasEditPermissions, hasCreateSamplePlanPermissions, hasGlobalPlansAdminPermissions],
	);
	const [shouldRender] = useNavigationPlansVisibility();
	const isPremium = useIsPremiumJSW();

	if (!shouldRender) {
		return null;
	}

	if (!isPremium) {
		return <Nav4PlansPremiumUpsellFeatureGate />;
	}

	return isInitialSelectedPath(MENU_ID_PLANS) ? (
		<Nav4PlansForLanding actionsOnHover={actionButtons} isExpanded={isExpanded} queryRef={data} />
	) : (
		<Nav4PlansDefault actionsOnHover={actionButtons} isExpanded={isExpanded} />
	);
}

/**
 * @generated SignedSource<<16f7b7e2f22cb823a54baf0f889d22af>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4Projects$data = {
  readonly jira: {
    readonly canCreateProject: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4ProjectsForLanding">;
  readonly " $fragmentType": "Nav4Projects";
};
export type Nav4Projects$key = {
  readonly " $data"?: Nav4Projects$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Projects">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "isProjectsExpanded"
    }
  ],
  "kind": "Fragment",
  "name": "Nav4Projects",
  "selections": [
    {
      "condition": "isProjectsExpanded",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "Nav4ProjectsForLanding"
        }
      ]
    },
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "alias": "canCreateProject",
          "args": [
            {
              "kind": "Variable",
              "name": "cloudId",
              "variableName": "cloudId"
            },
            {
              "kind": "Literal",
              "name": "type",
              "value": "CREATE_PROJECT"
            }
          ],
          "kind": "ScalarField",
          "name": "canPerform"
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "82f2e75eda840a347e0f322cf6e2dade";

export default node;

import { defineMessages } from '@atlassian/jira-intl';

('src/packages/jql-builder/basic/src/ui/jql-editor/order-by-picker/trigger-button/messages.tsx');
const messages = defineMessages({
	name: {
		id: 'jql-builder-basic.common.order-by-trigger-button.name',
		defaultMessage: 'Order',
		description: 'the name for the order button, which is used by screen reader users',
	},
	labelNameWithBadge: {
		id: 'jql-builder-basic.common.order-by-trigger-button.label-name-with-badge',
		defaultMessage:
			'Order, currently {fieldCount, plural, other {# fields} one {# field}} selected',
		description:
			'the label of the order button with number badge, which is used by screen reader users',
	},
});

export default messages;

import FeatureGates, { type DynamicConfig } from '@atlaskit/feature-gate-js-client';
import { addFeatureFlagAccessed } from '@atlaskit/react-ufo/feature-flags-accessed';
import { UNSAFE_initIfNeeded } from '@atlassian/jira-feature-gates-unsafe-init/src/index.tsx';

type AssurePrimitives<T> = T extends string
	? string
	: T extends number
		? number
		: T extends boolean
			? boolean
			: T;

/**
 * Returns the value of a given parameter in an experiment config.
 *
 * @param {string} experimentName - The name of the experiment
 * @param {string} param - The name of the parameter to fetch from the experiment config
 * @param {T} defaultValue - The value to serve if the experiment or parameter do not exist, or if the returned value does not match the expected type.
 */
export function expVal<T>(
	experimentName: string,
	param: string,
	defaultValue: T,
): AssurePrimitives<T> {
	// Handle vendorless scripts use of gates
	UNSAFE_initIfNeeded();

	const value = FeatureGates.getExperimentValue(experimentName, param, defaultValue);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	addFeatureFlagAccessed(`${experimentName}:${param}`, value as never);

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return value as AssurePrimitives<T>;
}

/**
 * Checks if experiment parameter value equals provided comparator value.
 *
 * __Note__: Uses `null` as experiment parameter default value.
 *
 * @param {string} experimentName - The name of the experiment
 * @param {string} param - The name of the parameter to fetch from the experiment config
 * @param {T} comparatorValue - Value to compare against experiment parameter value
 */

// eslint-disable-next-line jira/ff/inline-usage
export function expValEquals<T>(
	experimentName: string,
	param: string,
	comparatorValue: T,
): boolean {
	return expVal(experimentName, param, null) === comparatorValue;
}

class ExperimentConfig {
	readonly _experimentName: string;

	readonly _config: DynamicConfig;

	constructor(experimentName: string, config: DynamicConfig) {
		this._experimentName = experimentName;
		this._config = config;
	}

	public get<T>(
		key: string,
		defaultValue: T,
		typeGuard?: (value: unknown) => value is T,
	): AssurePrimitives<T> {
		// Check if the CRITERION override mechanism is available
		if (typeof globalThis?.window?.__CRITERION__?.getExperimentValueOverride === 'function') {
			const overrideValue = globalThis.window.__CRITERION__.getExperimentValueOverride<T>(
				this._experimentName,
				key,
			);
			if (overrideValue !== undefined && overrideValue !== null) {
				addFeatureFlagAccessed(`${this._experimentName}:${key}`, overrideValue);

				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return overrideValue as AssurePrimitives<T>;
			}
		}

		const value = this._config.get(key, defaultValue, typeGuard);
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		addFeatureFlagAccessed(`${this._experimentName}:${key}`, value as never);

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return value as AssurePrimitives<T>;
	}
}

const cache = new Map();
/**
 * Returns the config for an experiment, and a function to manually fire exposure.
 *
 * @param {string} experimentName - The name of the experiment
 */
export function UNSAFE_noExposureExp(
	experimentName: string,
): [config: ExperimentConfig, fireExposure: () => void] {
	let instance = cache.get(experimentName);

	if (instance == null) {
		// Handle vendorless scripts use of gates
		UNSAFE_initIfNeeded();

		const config = FeatureGates.getExperiment(experimentName, {
			fireExperimentExposure: false,
		});

		const fireExposure = () => FeatureGates.manuallyLogExperimentExposure(experimentName);
		instance = [new ExperimentConfig(experimentName, config), fireExposure];
		cache.set(experimentName, instance);
	}

	return instance;
}

import React from 'react';
import type { PlanDependenciesReportWithoutPageHeader as PlanDependenciesReportType } from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-dependencies-report/index.tsx';
import type { PlanIncrementWithoutPageHeader as PlanIncrementType } from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-increment/index.tsx';
import type PlanSummaryType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-summary/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyPlanSummaryEmbed = lazyForPaint<typeof PlanSummaryType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-plan-summary-embed", jiraSpaEntry: "async-plan-summary-embed" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-summary'
			),
		),
	{ ssr: false },
);

export const PlanSummaryEmbedPage = () => (
	<LazyPage Page={LazyPlanSummaryEmbed} pageId="plan-summary-embed" shouldShowSpinner />
);

// Update to default import when cleaning getWillShowNav4
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyPlanDependenciesReportEmbed = lazyForPaint<typeof PlanDependenciesReportType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-plan-dependencies-report-embed", jiraSpaEntry: "async-plan-dependencies-report-embed" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-dependencies-report'
			).then(
				({ PlanDependenciesReportWithoutPageHeader }) => PlanDependenciesReportWithoutPageHeader,
			),
		),
	{ ssr: false },
);

export const PlanDependenciesReportEmbedPage = () => (
	<LazyPage
		Page={LazyPlanDependenciesReportEmbed}
		pageId="plan-dependencies-report-embed"
		shouldShowSpinner
	/>
);

// Update to default import when cleaning getWillShowNav4
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyPlanIncrementEmbed = lazyForPaint<typeof PlanIncrementType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-plan-increment-embed", jiraSpaEntry: "async-plan-increment-embed" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-increment'
			).then(({ PlanIncrementWithoutPageHeader }) => PlanIncrementWithoutPageHeader),
		),
	{ ssr: false },
);

export const PlanIncrementEmbedPage = () => (
	<LazyPage Page={LazyPlanIncrementEmbed} pageId="plan-increment-embed" shouldShowSpinner />
);

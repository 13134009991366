import React, { useContext, type ReactNode, useCallback } from 'react';
import {
	SECTION_ITEM_MAP,
	SECTION_ITEM_MORE,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import {
	NavItemsContext,
	PeekMenuIdContext,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/controllers/context.tsx';
import type { OnCloseProps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { useSectionItemAnalytics } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-section-item-analytics/index.tsx';
import { L1MoreNavMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/l1-more-nav-menu-item/main.tsx';
import type { ConfigurableMenuItem$key } from '@atlassian/jira-relay/src/__generated__/ConfigurableMenuItem.graphql';
import { MoreMenuIsOpenContext } from '../../../controllers/context.tsx';
import { MenuItem } from './menu-item/index.tsx';
import { PeekMenuItem } from './peek-menu-item/index.tsx';

export type MoreMenuItemProps = {
	customiseSidebar: ReactNode;
	menuData: ConfigurableMenuItem$key;
};

export function MoreMenuItem({ customiseSidebar, menuData }: MoreMenuItemProps) {
	const { moreMenuIds } = useContext(NavItemsContext);
	const { peekMenuId, setPeekMenuId } = useContext(PeekMenuIdContext);
	const { moreMenuIsOpen, setMoreMenuIsOpen } = useContext(MoreMenuIsOpenContext);
	const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();

	const handleMenuClose = useCallback(() => setMoreMenuIsOpen(false), [setMoreMenuIsOpen]);

	const handleMenuToggle = useCallback(() => {
		setMoreMenuIsOpen((value) => !value);
		sendSectionItemAnalyticsEvent({ sectionItem: SECTION_ITEM_MORE });
	}, [sendSectionItemAnalyticsEvent, setMoreMenuIsOpen]);

	const MenuItems = [
		({ onClose }: OnCloseProps) => (
			<>
				{moreMenuIds
					.filter((menuId) => menuId !== peekMenuId)
					.map((menuId) => (
						<MenuItem
							key={menuId}
							menuId={menuId}
							onClick={(e) => {
								setPeekMenuId(menuId);
								onClose?.(e);

								sendSectionItemAnalyticsEvent({
									menuId,
									sectionItem: SECTION_ITEM_MAP[menuId],
								});
							}}
						/>
					))}
			</>
		),
		() => customiseSidebar,
	];

	return moreMenuIds.length === 0 ? (
		customiseSidebar
	) : (
		<>
			<PeekMenuItem menuData={menuData} />
			<L1MoreNavMenuItem
				MenuItems={MenuItems}
				isOpen={moreMenuIsOpen}
				onClose={handleMenuClose}
				onToggle={handleMenuToggle}
			/>
		</>
	);
}

import { servicedeskPlaybooksListRouteEntry } from '@atlassian/jira-router-routes-project-settings-playbooks-entries/src/servicedeskPlaybooksListRouteEntry.tsx';
import { servicedeskPlaybooksEditRouteEntry } from '@atlassian/jira-router-routes-project-settings-playbooks-entries/src/servicedeskPlaybooksEditRouteEntry.tsx';
import { projectSettingsServicedeskAutomationEditRuleRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries/src/projectSettingsServicedeskAutomationEditRuleRouteEntry.tsx';
import { projectSettingsServicedeskAutomationCopyRuleRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries/src/projectSettingsServicedeskAutomationCopyRuleRouteEntry.tsx';
import { projectSettingsServicedeskAutomationCreateRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries/src/projectSettingsServicedeskAutomationCreateRouteEntry.tsx';
import { projectSettingsServicedeskAutomationViewLogRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries/src/projectSettingsServicedeskAutomationViewLogRouteEntry.tsx';
import { projectSettingsServicedeskAutomationSummaryRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries/src/projectSettingsServicedeskAutomationSummaryRouteEntry.tsx';
import { projectSettingsServicedeskLegacyAutomationRedirectCreateRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries/src/projectSettingsServicedeskLegacyAutomationRedirectCreateRouteEntry.tsx';
import { projectSettingsServicedeskLegacyAutomationRedirectEditRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries/src/projectSettingsServicedeskLegacyAutomationRedirectEditRouteEntry.tsx';
import { projectSettingsServicedeskLegacyAutomationRedirectCopyRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries/src/projectSettingsServicedeskLegacyAutomationRedirectCopyRouteEntry.tsx';
import { projectSettingsServicedeskLegacyAutomationRedirectViewLogRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries/src/projectSettingsServicedeskLegacyAutomationRedirectViewLogRouteEntry.tsx';
import { projectSettingsServicedeskLegacyAutomationRedirectSummaryRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries/src/projectSettingsServicedeskLegacyAutomationRedirectSummaryRouteEntry.tsx';

import { projectSettingsServicedeskCustomerNotificationsRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-customer-notifications-entries/src/projectSettingsServicedeskCustomerNotificationsRouteEntry.tsx';
import { projectSettingsServicedeskNotificationsCustomerNotificationsRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-customer-notifications-entries/src/projectSettingsServicedeskNotificationsCustomerNotificationsRouteEntry.tsx';

import { projectSettingsServicedeskJourneysRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-journeys-entries/src/projectSettingsServicedeskJourneysRouteEntry.tsx';
import { projectSettingsServicedeskJourneyConfigurationRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-journeys-entries/src/projectSettingsServicedeskJourneyConfigurationRouteEntry.tsx';

import { projectSettingsServicedeskKbRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-knowledge-base-entries/src/projectSettingsServicedeskKbRouteEntry.tsx';

import { projectSettingsServicedeskUnassignedTicketTypeFieldsRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-type-fields-entries/src/projectSettingsServicedeskUnassignedTicketTypeFieldsRouteEntry.tsx';
import { projectSettingsServicedeskItsmTicketTypeFieldsRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-type-fields-entries/src/projectSettingsServicedeskItsmTicketTypeFieldsRouteEntry.tsx';
import { projectSettingsServicedeskTicketTypeFieldsRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-type-fields-entries/src/projectSettingsServicedeskTicketTypeFieldsRouteEntry.tsx';

import { projectSettingsServicedeskUnassignedTicketTypesConfigRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-types-config-entries/src/projectSettingsServicedeskUnassignedTicketTypesConfigRouteEntry.tsx';
import { projectSettingsServicedeskItsmTicketTypesConfigRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-types-config-entries/src/projectSettingsServicedeskItsmTicketTypesConfigRouteEntry.tsx';
import { projectSettingsServicedeskTicketTypesConfigRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-types-config-entries/src/projectSettingsServicedeskTicketTypesConfigRouteEntry.tsx';

import { landingPageRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-entries/src/landingPageRouteEntry.tsx';
import { aiAnswersRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-entries/src/aiAnswersRouteEntry.tsx';
import { browseConversationsRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-entries/src/browseConversationsRouteEntry.tsx';
import { browseIntentsRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-entries/src/browseIntentsRouteEntry.tsx';
import { browseStandardFlowsRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-entries/src/browseStandardFlowsRouteEntry.tsx';
import { performanceRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-entries/src/performanceRouteEntry.tsx';
import { settingsRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-entries/src/settingsRouteEntry.tsx';
import { viewIntentRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-entries/src/viewIntentRouteEntry.tsx';
import { viewStandardFlowRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-entries/src/viewStandardFlowRouteEntry.tsx';
import { servicedeskWorkflowConfigRouteEntry } from '@atlassian/jira-router-routes-project-settings-workflow-scheme-config-entries/src/servicedeskWorkflowConfigRouteEntry.tsx';
import type { Route } from '@atlassian/react-resource-router';

/**
 * Contains all service desk settings routes using the new url format `jira/:product/projects/:projectKey/settings`.
 */
export const serviceDeskRouteEntries: Route[] = [
	projectSettingsServicedeskKbRouteEntry,
	projectSettingsServicedeskUnassignedTicketTypeFieldsRouteEntry,
	projectSettingsServicedeskItsmTicketTypeFieldsRouteEntry,
	projectSettingsServicedeskTicketTypeFieldsRouteEntry,
	projectSettingsServicedeskUnassignedTicketTypesConfigRouteEntry,
	projectSettingsServicedeskItsmTicketTypesConfigRouteEntry,
	projectSettingsServicedeskTicketTypesConfigRouteEntry,
	projectSettingsServicedeskAutomationEditRuleRouteEntry,
	projectSettingsServicedeskAutomationCopyRuleRouteEntry,
	projectSettingsServicedeskAutomationCreateRouteEntry,
	projectSettingsServicedeskAutomationViewLogRouteEntry,
	projectSettingsServicedeskAutomationSummaryRouteEntry,
	projectSettingsServicedeskLegacyAutomationRedirectCreateRouteEntry,
	projectSettingsServicedeskLegacyAutomationRedirectEditRouteEntry,
	projectSettingsServicedeskLegacyAutomationRedirectCopyRouteEntry,
	projectSettingsServicedeskLegacyAutomationRedirectViewLogRouteEntry,
	projectSettingsServicedeskLegacyAutomationRedirectSummaryRouteEntry,
	projectSettingsServicedeskCustomerNotificationsRouteEntry,
	projectSettingsServicedeskNotificationsCustomerNotificationsRouteEntry,
	landingPageRouteEntry,
	browseIntentsRouteEntry,
	viewIntentRouteEntry,
	browseStandardFlowsRouteEntry,
	viewStandardFlowRouteEntry,
	browseConversationsRouteEntry,
	settingsRouteEntry,
	aiAnswersRouteEntry,
	performanceRouteEntry,
	servicedeskPlaybooksListRouteEntry,
	servicedeskPlaybooksEditRouteEntry,
	projectSettingsServicedeskJourneysRouteEntry,
	projectSettingsServicedeskJourneyConfigurationRouteEntry,
	servicedeskWorkflowConfigRouteEntry,
];

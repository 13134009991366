/**
 * @generated SignedSource<<f1dd304f2b2075cf9083f0c067a308e3>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4AppMenuItem$data = {
  readonly appId: string | null | undefined;
  readonly envLabel: string | null | undefined;
  readonly iconUrl: string | null | undefined;
  readonly label: string | null | undefined;
  readonly sections: ReadonlyArray<{
    readonly label: string | null | undefined;
    readonly links: ReadonlyArray<{
      readonly label: string | null | undefined;
      readonly styleClass: string | null | undefined;
      readonly url: string | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly settingsUrl: string | null | undefined;
  readonly styleClass: string | null | undefined;
  readonly url: string | null | undefined;
  readonly " $fragmentType": "Nav4AppMenuItem";
};
export type Nav4AppMenuItem$key = {
  readonly " $data"?: Nav4AppMenuItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4AppMenuItem">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "label"
},
v1 = {
  "kind": "ScalarField",
  "name": "styleClass"
},
v2 = {
  "kind": "ScalarField",
  "name": "url"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "Nav4AppMenuItem",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "appId"
    },
    {
      "kind": "ScalarField",
      "name": "envLabel"
    },
    {
      "kind": "ScalarField",
      "name": "iconUrl"
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "concreteType": "JiraAppSection",
      "kind": "LinkedField",
      "name": "sections",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "concreteType": "JiraAppNavigationItemNestedLink",
          "kind": "LinkedField",
          "name": "links",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v1/*: any*/)
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "settingsUrl"
    },
    (v2/*: any*/)
  ],
  "type": "JiraAppNavigationItem"
};
})();

(node as any).hash = "fe622bd16e14b11ec5e3f21d8553bac3";

export default node;

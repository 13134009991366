/**
 * @generated SignedSource<<2b5dbf7906b653869505f67a354896a3>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraJQLBuilderSearchMode = "ADVANCED" | "BASIC" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type ui_jqlBuilder_JQLBuilderUI$data = {
  readonly jqlBuilderSearchMode: JiraJQLBuilderSearchMode | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"searchMode_useSearchMode_JQLBuilder">;
  readonly " $fragmentType": "ui_jqlBuilder_JQLBuilderUI";
};
export type ui_jqlBuilder_JQLBuilderUI$key = {
  readonly " $data"?: ui_jqlBuilder_JQLBuilderUI$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_jqlBuilder_JQLBuilderUI">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_jqlBuilder_JQLBuilderUI",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "searchMode_useSearchMode_JQLBuilder"
    },
    {
      "kind": "ScalarField",
      "name": "jqlBuilderSearchMode"
    }
  ],
  "type": "JiraUserPreferences"
};

(node as any).hash = "9fbe210abfde6a98b4eff53d65746395";

export default node;

import React from 'react';
import { AppSkeletonImage } from '../../common/ui/AppSkeletonImage.tsx';
import boardsImgSVG from './assets/boards-directory-app-skeleton.tpl.svg';
import dashboardsImgSVG from './assets/dashboards-directory-app-skeleton.tpl.svg';
import filtersImgSVG from './assets/filters-directory-app-skeleton.tpl.svg';
import projectsImgSVG from './assets/projects-directory-app-skeleton.tpl.svg';

export const BoardsDirectorySkeleton = () => <AppSkeletonImage src={boardsImgSVG} />;

export const DashboardsDirectorySkeleton = () => <AppSkeletonImage src={dashboardsImgSVG} />;

export const FiltersDirectorySkeleton = () => <AppSkeletonImage src={filtersImgSVG} />;

export const ProjectsDirectorySkeleton = () => <AppSkeletonImage src={projectsImgSVG} />;

// TODO: update with a proper SVG image
export const PlansDirectorySkeleton = () => <AppSkeletonImage src={projectsImgSVG} />;

/**
 * @generated SignedSource<<083145ce906c06508a7d07f916ba5d74>>
 * @relayHash f4c8ae0c3d48de47f99b6125543ae72d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4c991741604964ca1c4b6feafb2729360c0bf83e94471b935f5218b649477258

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { JourneysPageQuery } from './JourneysPageQuery.graphql';

const node: PreloadableConcreteRequest<JourneysPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "4c991741604964ca1c4b6feafb2729360c0bf83e94471b935f5218b649477258",
    "metadata": {},
    "name": "JourneysPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

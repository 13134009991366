import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';
import type { OperatorValue } from '@atlaskit/jql-ast';
import type { IntlShape } from '@atlassian/jira-intl';
import type {
	FieldOption,
	SearchTemplate,
	FieldType,
} from '@atlassian/jira-jql-builder-basic-picker/src/common/types.tsx';
import type { jqlEditor_jqlBuilderBasic_JQLEditorUI$data } from '@atlassian/jira-relay/src/__generated__/jqlEditor_jqlBuilderBasic_JQLEditorUI.graphql';
import type { FieldToRender } from '../../common/types.tsx';
import { getPickerType, getUnsupportedPickerType } from '../../controllers/picker-type/index.tsx';
import type { ClausesByFieldId, AstClauseOrder } from '../../controllers/search-state/types.tsx';
import type { IrremovableFields } from '../irremovable-fields/index.tsx';
import { mapOperators } from '../operators/map-operators.tsx';

type HydrationFieldData = {
	[key: string]: {
		/**
		 * displayName: The user-friendly name for the current field, to be displayed in the UI.
		 */
		displayName: string | null;
		/**
		 * searchTemplate: Defines how a field should be represented in the basic search mode of the JQL builder.
		 */
		searchTemplate: SearchTemplate;
		/**
		 * Underlying field type for the given field.
		 */
		fieldType: FieldType;
		/**
		 * The JQL operators that can be used with this field.
		 */
		operators?: OperatorValue[];
		/**
		 * isDuplicated: This field is true if hydrated query returns multiple results with the same jqlTerm.
		 * more context: https://hello.atlassian.net/wiki/spaces/JFE/pages/2428229451/DACI+Solving+field+duplication+in+the+JQL+Builder
		 */
		isDuplicated: boolean;
		/**
		 * Specific to Custom Fields only.
		 * Contains the user-configurable description of a custom field that is included in the current search.
		 * The value is displayed in a popup when clicking the info icon on a custom field picker
		 */
		fieldDescription: string | null;
		/**
		 * Field type name displayed in UI.
		 * This is used for the selected options in the more+ dropdown as it relies on hydrated data.
		 */
		fieldTypeDisplayName: string | null;
		shouldShowInContext: boolean | null;
	};
};

/**
 * getDataFromHydratedData read the fieldsHydratedData to the data hashed by fieldId/jqlTerm
 */
const getDataFromHydratedData = ({
	fieldsHydratedData,
}: {
	fieldsHydratedData?: jqlEditor_jqlBuilderBasic_JQLEditorUI$data;
}): HydrationFieldData => {
	const fieldsData: HydrationFieldData = {};

	(
		fieldsHydratedData?.jira?.jqlBuilder?.hydrateJqlQuery ??
		fieldsHydratedData?.jira?.jqlBuilder?.hydrateJqlQueryForFilter
	)?.fields?.forEach((hydratedField) => {
		if (hydratedField.field && hydratedField.jqlTerm) {
			const {
				field: {
					displayName,
					searchTemplate,
					type,
					description,
					operators,
					fieldType,
					shouldShowInContext,
				},
				jqlTerm,
			} = hydratedField;

			// Searchable fields should always have a type & search template
			if (searchTemplate?.key == null || type == null) {
				return;
			}

			if (!fieldsData[jqlTerm.toLowerCase()]) {
				fieldsData[jqlTerm.toLowerCase()] = {
					// @ts-expect-error - Type 'string | null | undefined' is not assignable to type 'string | null'.
					displayName,
					searchTemplate: searchTemplate.key,
					fieldType: type,
					fieldDescription: description ?? null,
					fieldTypeDisplayName: fieldType?.displayName ?? null,
					operators: operators.map(mapOperators),
					isDuplicated: false,
					shouldShowInContext: shouldShowInContext ?? true,
				};
			} else {
				fieldsData[jqlTerm.toLowerCase()].isDuplicated = true;
			}
		}
	});

	return fieldsData;
};

/**
 * getOptionalFieldsToRender takes data from the AST, selectedFields and hydrated data and generates Optional fields to render array
 */
const getOptionalFieldsToRender = ({
	astClauseOrder,
	selectedFields,
	fieldsData,
	clausesByFieldId,
	secondaryClausesByFieldId,
	excludedFields,
}: {
	astClauseOrder: string[];
	selectedFields: FieldOption[];
	fieldsData: HydrationFieldData;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	clausesByFieldId: Record<string, any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	secondaryClausesByFieldId: Record<string, any>;
	excludedFields: string[];
}): { optionalFieldsToRender: FieldToRender[]; fieldsMissingHydration: string[] } => {
	// First get an array of unique fields we want to add
	const allFields = [
		...new Set([...astClauseOrder, ...selectedFields.map((sf) => sf.value.toLowerCase())]),
	];

	// Create hash map with data of selected fields
	const selectedFieldsData = selectedFields.reduce<HydrationFieldData>((map, selectedField) => {
		// eslint-disable-next-line no-param-reassign
		map[selectedField.value.toLowerCase()] = {
			displayName: selectedField.label,
			searchTemplate: selectedField.searchTemplate,
			fieldType: selectedField.fieldType,
			fieldDescription: selectedField.fieldDescription,
			operators: selectedField.operators,
			fieldTypeDisplayName: selectedField.fieldTypeDisplayName,
			isDuplicated: false,
			shouldShowInContext: true,
		};
		return map;
	}, {});

	const [fieldsWithHydration, fieldsMissingHydration] = partition(
		allFields,
		(fieldId) => fieldsData[fieldId] ?? selectedFieldsData[fieldId],
	);

	// Generate fields to render
	const optionalFieldsToRender = fieldsWithHydration
		.filter((fieldId) => !excludedFields.includes(fieldId))
		.map((fieldId) => {
			const fieldData = fieldsData[fieldId] ?? selectedFieldsData[fieldId];

			const {
				isDuplicated,
				searchTemplate,
				fieldType,
				displayName,
				fieldDescription,
				operators,
				fieldTypeDisplayName,
				shouldShowInContext,
			} = fieldData;

			const fieldToRender: FieldToRender = {
				fieldId,
				fieldLabel: displayName ?? undefined,
				fieldDescription,
				fieldTypeDisplayName,
				shouldShowInContext,
				// We want only to show non-duplicated fieldIds/jqlTerms
				...(isDuplicated
					? {
							picker: getUnsupportedPickerType({
								searchTemplate,
								fieldType,
							}),
							clause: undefined,
							secondaryClause: undefined,
						}
					: {
							picker: getPickerType({
								searchTemplate,
								fieldType,
								operators,
							}),
							clause: clausesByFieldId[fieldId],
							secondaryClause: secondaryClausesByFieldId[fieldId],
						}),
			};

			return fieldToRender;
		});

	return { optionalFieldsToRender, fieldsMissingHydration };
};

/**
 * getIrremovableFieldsToRender
 */
const getIrremovableFieldsToRender = ({
	clausesByFieldId,
	secondaryClausesByFieldId,
	excludedFields,
	irremovableFields,
}: {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	clausesByFieldId: Record<string, any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	secondaryClausesByFieldId: Record<string, any>;
	excludedFields: string[];
	intl: IntlShape;
	irremovableFields: IrremovableFields;
}): FieldToRender[] => {
	const irremovableFieldsToRender: FieldToRender[] = [];

	irremovableFields.forEach((irremovableField) => {
		const isIrremovableFieldConfiguredAsExcludedField = excludedFields.includes(
			irremovableField.jqlTerm,
		);

		if (!isIrremovableFieldConfiguredAsExcludedField) {
			irremovableFieldsToRender.push({
				isIrremovable: true,
				fieldId: irremovableField.jqlTerm,
				fieldLabel: irremovableField.displayName,
				picker: getPickerType({
					searchTemplate: irremovableField.searchTemplate,
					fieldType: irremovableField.fieldType,
					operators: irremovableField.operators,
				}),
				fieldDescription: null,
				fieldTypeDisplayName: null,
				clause: clausesByFieldId[irremovableField.jqlTerm],
				secondaryClause: secondaryClausesByFieldId[irremovableField.jqlTerm],
			});
		}
	});

	return irremovableFieldsToRender;
};

type GatherFields = {
	fieldsToRender: FieldToRender[];
	// count of optional fields including unsupported ones to show in the loading state inside "more" dropdown
	optionalFieldsCount: number;
	missingHydration: boolean;
};

type GatherFieldsOptsBase = {
	excludedFields?: string[];
	intl: IntlShape;
	irremovableFields: IrremovableFields;
	astClauseOrder: AstClauseOrder;
	clausesByFieldId: ClausesByFieldId;
	secondaryClausesByFieldId: ClausesByFieldId;
};

type GatherFieldsOpts = GatherFieldsOptsBase & {
	selectedFields: FieldOption[];
	fieldsHydratedData?: jqlEditor_jqlBuilderBasic_JQLEditorUI$data;
	previousFieldToRender?: FieldToRender[];
};

/**
 * gatherFields is in charge of getting fields from AST and selected fields, data from selected fields and hydrated data
 * and mix it all together to get an array of fields the jql builder basic need to render.
 * It also returns missingHydration that check if there is missing data inside the fields to render
 *
 * Concepts:
 * - irremovable fields: fields that are always on display in the jql builder basic
 * - optional fields: fields that can be added or removed from the more dropdown
 * - fields hydrated data: hydrated data is data that is connected to the jql but is not in the jql itself and it needs to be
 * requested from the backend
 * - selected fields: fields that are selected in the "more" dropdown, this come already "hydrated" data because "more" dropdown
 * is powered by the Fields API
 * - previousFieldToRender: order of the fields from the last fields to render, this allow us to keep pickers in postition
 */
export const gatherFields = ({
	selectedFields,
	excludedFields = [],
	intl,
	fieldsHydratedData,
	previousFieldToRender = [],
	irremovableFields,
	astClauseOrder,
	clausesByFieldId,
	secondaryClausesByFieldId,
}: GatherFieldsOpts): GatherFields => {
	const { optionalFieldsToRender, fieldsMissingHydration } = getOptionalFieldsToRender({
		astClauseOrder,
		clausesByFieldId,
		fieldsData: getDataFromHydratedData({ fieldsHydratedData }),
		secondaryClausesByFieldId,
		selectedFields,
		excludedFields,
	});

	const fieldsToRender = getIrremovableFieldsToRender({
		clausesByFieldId,
		secondaryClausesByFieldId,
		excludedFields,
		intl,
		irremovableFields,
	}).concat(optionalFieldsToRender);
	const missingHydration = fieldsMissingHydration.length > 0;

	/**
	 * Keep order of fields in the more dropdown if order is provided
	 * We use the order array to sort the fieldsToRender adding any new fields at the end
	 * and then remove all the fields that doesn't exist anymore in the new fieldsToRender
	 */
	let sortedFieldsToRender = fieldsToRender;
	if (previousFieldToRender.length > 0) {
		const previousFieldToRendeFieldIds = previousFieldToRender.map((field) => field.fieldId);

		sortedFieldsToRender = sortBy(fieldsToRender, (fieldToRender) => {
			const index = previousFieldToRendeFieldIds.indexOf(fieldToRender.fieldId);
			return index === -1 ? previousFieldToRendeFieldIds.length : index;
		});
	}

	return {
		fieldsToRender: sortedFieldsToRender,
		optionalFieldsCount: astClauseOrder.length,
		missingHydration,
	};
};

/**
 * gatherIrremovableFields is similar to gatherFields but only gather the minimum information to feed the fallback loading state
 */
export const gatherIrremovableFields = ({
	excludedFields = [],
	intl,
	irremovableFields,
	astClauseOrder,
	clausesByFieldId,
	secondaryClausesByFieldId,
}: GatherFieldsOptsBase): GatherFields => {
	const fieldsToRender = getIrremovableFieldsToRender({
		clausesByFieldId,
		secondaryClausesByFieldId,
		excludedFields,
		intl,
		irremovableFields,
	});

	return {
		fieldsToRender,
		optionalFieldsCount: astClauseOrder.length,
		missingHydration: true,
	};
};

import type { State } from './types.tsx';

export const getBaseUrl = (state: State) => state.appProps.baseUrl;

export const getFilter = (state: State) => state.appProps.filter;
export const getFilterName = (state: State) => state.appProps.filter.name;

export const getOnInitialised = (state: State) => state.appProps.onInitialised;
export const getOnFilterDeleted = (state: State) => state.appProps.onFilterDeleted;
export const getOnFilterDeleteFailed = (state: State) => state.appProps.onFilterDeleteFailed;
export const getOnFilterInitiateFailed = (state: State) => state.appProps.onFilterInitiateFailed;
export const getOnCancel = (state: State) => state.appProps.onCancel;

export const isDeleteInProgress = (state: State) => state.isDeleteInProgress;
export const isDataRetrieved = (state: State) => state.isDataRetrieved;
export const getAssociatedBoards = (state: State) => state.boards;
export const getAssociatedUsers = (state: State) => state.users;
export const getAssociatedGroups = (state: State) => state.groups;
export const getCanDelete = (state: State) =>
	Array.isArray(state.boards) && state.boards.length === 0;

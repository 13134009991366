import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { logSafeErrorWithoutCustomerData } from '@atlassian/jira-common-directory-v2/src/utils/log-safe-error/index.tsx';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { FILTERS_ERROR_ANALYTICS } from '@atlassian/jira-filters-common/src/common/constant.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import deleteFilter from '../../services/delete-filter/index.tsx';
import {
	type Action,
	DELETE_FILTER,
	deleteFilterFailed,
	filterDeleted,
} from '../../state/actions.tsx';
import {
	getBaseUrl,
	getFilter,
	getOnFilterDeleted,
	getOnFilterDeleteFailed,
} from '../../state/selectors.tsx';
import type { State } from '../../state/types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(DELETE_FILTER).mergeMap(({ onComplete }) => {
		const state = store.getState();
		const baseUrl = getBaseUrl(state);
		const filter = getFilter(state);

		return Observable.fromPromise(deleteFilter(baseUrl, filter.id))
			.mergeMap(() => {
				getOnFilterDeleted(state)(filter);
				getWillShowNav4() && onComplete?.();
				return Observable.of(filterDeleted());
			})
			.catch((error: FetchError) => {
				logSafeErrorWithoutCustomerData(
					error,
					'directories.filters-v2.delete-modal.ops.delete-filter',
					FILTERS_ERROR_ANALYTICS,
				);
				getOnFilterDeleteFailed(state)(filter);
				return Observable.of(deleteFilterFailed());
			});
	});

import React, { useCallback, useMemo } from 'react';
import {
	type JSErrorBoundaryProps,
	JSErrorBoundary,
} from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { getUserLocation } from '@atlassian/jira-platform-router-utils/src/common/utils/index.tsx';
import { Fallback } from './fallback/index.tsx';

export const SidebarErrorBoundary = (props: JSErrorBoundaryProps) => {
	const userLocation = getUserLocation();

	const extraEventData = useMemo(
		() => ({ ...props.extraEventData, userLocation }),
		[props.extraEventData, userLocation],
	);

	const fallback = useCallback(({ error }: { error: Error }) => <Fallback error={error} />, []);
	return <JSErrorBoundary {...props} extraEventData={extraEventData} fallback={fallback} />;
};

import { useCallback } from 'react';
import { NAVIGATION_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import { FIXED_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { LEVEL_ONE } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import { MENU_ID_ASSETS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { AssetsAnalyticsItemId } from './types.tsx';

export const useAssetsUIAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireUINavigationItemAnalytics = useCallback(
		(analyticsItemId: AssetsAnalyticsItemId) => {
			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'clicked',
					actionSubject: NAVIGATION_ITEM,
				}),
				MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_ASSETS],
				{
					level: LEVEL_ONE,
					componentType: FIXED_ITEM,
					itemId: analyticsItemId,
				},
			);
		},
		[createAnalyticsEvent],
	);

	return {
		fireUINavigationItemAnalytics,
	};
};

import React from 'react';
import AlignLeftIcon from '@atlaskit/icon/core/align-left';
import {
	Nav4MenuLinkItem,
	type Nav4MenuLinkItemProps,
} from '../../common/ui/nav4-menu-link-item/index.tsx';

type Props = Omit<Nav4MenuLinkItemProps, 'elemBefore'>;

export function ViewAllEntityMenuItem(props: Props) {
	return (
		<Nav4MenuLinkItem {...props} elemBefore={<AlignLeftIcon label="" color="currentColor" />} />
	);
}

/**
 * @generated SignedSource<<560d7e5e0cd70de1d6c924f49a1b8b29>>
 * @relayHash cf2a8f845ae7c967ae307cc40eb4b07f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e61d234924072f4c43fcca2a72d097f86d33239be52bd1855eefa77b25c7cd4c

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type OverviewsContentViewQuery$variables = {
  cloudId: string;
};
export type OverviewsContentViewQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4OverviewsContent">;
};
export type OverviewsContentViewQuery = {
  response: OverviewsContentViewQuery$data;
  variables: OverviewsContentViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "OverviewsContentViewQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Nav4OverviewsContent"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OverviewsContentViewQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "cloudId",
                "variableName": "cloudId"
              }
            ],
            "concreteType": "JiraWorkManagementGiraOverviewConnection",
            "kind": "LinkedField",
            "name": "jwmOverviews",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraWorkManagementGiraOverviewEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraWorkManagementGiraOverview",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "id"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "name"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "e61d234924072f4c43fcca2a72d097f86d33239be52bd1855eefa77b25c7cd4c",
    "metadata": {},
    "name": "OverviewsContentViewQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "5c526a403c104de05313b5b30d6f67e2";

export default node;

import { useCallback, useState } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { BOARD_SOURCE_FIELDS_JQLTERM, BOARD_SOURCE_FIELDS } from '../../common/constants.tsx';
import type { GenericPickerOption, JQLTermType } from '../../common/types.tsx';
import { fireBoardCreateErrorAnalytics } from '../../common/utils.tsx';
import { useJQLBuilderFetch } from '../../services/use-jql-builder-fetch/index.tsx';
import { useBoardCreateContext } from '../use-board-create-context/index.tsx';
import { useBoardCreateFlag } from '../use-board-create-flag/index.tsx';
import messages from './messages.tsx';
import type { ErrorMessageMap } from './types.tsx';

const errorMessageMap: ErrorMessageMap = {
	[BOARD_SOURCE_FIELDS_JQLTERM[BOARD_SOURCE_FIELDS.ISSUE_TYPES]]: {
		title: messages.failedToLoadIssueTypesTitle,
		description: messages.failedToLoadIssueTypesDescription,
	},
	[BOARD_SOURCE_FIELDS_JQLTERM[BOARD_SOURCE_FIELDS.LABELS]]: {
		title: messages.failedToLoadLabelsTitle,
		description: messages.failedToLoadLabelsDescription,
	},
	DEFAULT: {
		title: messages.failedToLoadOptionsTitle,
		description: messages.failedToLoadOptionsTitle,
	},
};

export const useIsLoading = () => useState(false);

export const useLoadOptionsController = () => {
	const { projectId } = useBoardCreateContext();
	const jqlBuilderFetch = useJQLBuilderFetch({ projectId });
	const [isLoading, setIsLoading] = useIsLoading();
	const { showErrorFlag } = useBoardCreateFlag();
	const { formatMessage } = useIntl();

	const loadOptions: (
		jqlTerm: JQLTermType,
	) => (searchString: string) => Promise<GenericPickerOption[] | undefined> = useCallback(
		(jqlTerm: JQLTermType) => async (searchString: string) => {
			setIsLoading(true);
			return jqlBuilderFetch(jqlTerm, searchString)
				.catch((error) => {
					const errorText = errorMessageMap[jqlTerm] || errorMessageMap.DEFAULT;
					showErrorFlag({
						title: formatMessage(errorText.title),
						description: formatMessage(errorText.description),
					});
					fireBoardCreateErrorAnalytics({
						error: new Error(`Failed to fetch picker options: ${error}`),
						attributes: {
							jqlTerm,
						},
						id: 'fetchPickerOptions',
					});
					return [];
				})
				.finally(() => {
					setIsLoading(false);
				});
		},
		[formatMessage, jqlBuilderFetch, setIsLoading, showErrorFlag],
	);

	return {
		loadOptions,
		isLoading,
	};
};

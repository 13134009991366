import { Component, useCallback, useEffect, useRef } from 'react';
import {
	createStore,
	createContainer,
	createSubscriber,
	createActionsHook,
	createHook,
} from '@atlassian/react-sweet-state';
import { actions } from './actions/index.tsx';
import { checkDocumentTitle, type State } from './utils.tsx';

type Actions = typeof actions;

type DocumentTitleContainerProps = {
	titleSuffix: string | null;
};

const initialState: State = {
	title: '',
	defaultTitle: '',
	contextArray: [],
};

const Store = createStore<State, Actions>({
	name: 'document-title',
	initialState,
	actions,
});

export const useDocumentTitleActions = createActionsHook(Store);
export const useDocumentTitle = createHook(Store);

export const DocumentTitleContainer = createContainer<State, Actions, DocumentTitleContainerProps>(
	Store,
	{
		displayName: 'DocumentTitleContainer',
	},
);

export const DocumentTitleSubscriber = createSubscriber<
	State,
	Actions,
	undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Record<any, any>
>(Store, {
	displayName: 'DocumentTitleSubscriber',
});

export const DocumentTitleHelper: React.FC<{ title: string }> = ({ title }) => {
	const { addDocumentTitle, removeDocumentTitle, updateTitle } = useDocumentTitleActions();

	const identifierRef = useRef(Symbol('document title identifier'));

	const onUpdate = useCallback(() => {
		updateTitle(title, identifierRef.current);
	}, [updateTitle, title]);

	useEffect(() => {
		const titleRef = identifierRef.current;
		addDocumentTitle(titleRef);
		return () => {
			removeDocumentTitle(titleRef);
			onUpdate();
		};
	}, [addDocumentTitle, onUpdate, removeDocumentTitle]);

	useEffect(() => {
		onUpdate();
	}, [onUpdate]);

	return null;
};

type DocumentTitleHelperProps = {
	title: string;
	contextArray: symbol[];
	setTitle: (arg1: string) => void;
	// eslint-disable-next-line jira/react/handler-naming
	addDocumentTitle: (arg1: symbol) => void;
	// eslint-disable-next-line jira/react/handler-naming
	removeDocumentTitle: (arg1: symbol) => void;
	TitleSubscriber: typeof DocumentTitleSubscriber;
};

// eslint-disable-next-line jira/react/no-class-components
export class DocumentTitleHelperOld extends Component<DocumentTitleHelperProps> {
	constructor(props: DocumentTitleHelperProps) {
		super(props);
		this.myself = Symbol('document title identifier');
		this.props.addDocumentTitle(this.myself);
	}

	componentDidMount() {
		this.updateTitle();
	}

	componentDidUpdate() {
		this.updateTitle();
	}

	componentWillUnmount() {
		this.props.removeDocumentTitle(this.myself);
		this.updateTitle();
	}

	myself: symbol;

	updateTitle() {
		if (checkDocumentTitle(this.myself, this.props.contextArray)) {
			this.props.setTitle(this.props.title);
		}
	}

	render() {
		return null;
	}
}

import type { ComponentType } from 'react';
import { ProjectRouteListener } from '@atlassian/jira-platform-route-analytics/src/ui/project-route-listener/index.tsx';
import {
	createLayout,
	withProjectContext,
	type Layout,
} from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { useProjectFavicon } from './services/use-project-favicon/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withAnyProjectContext = (Component: ComponentType<Record<any, any>>) =>
	withProjectContext(Component, {
		project: {
			style: 'any',
			type: 'any',
		},
	});

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const ProjectTrackers = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-project-trackers" */ './ui/trackers'),
	{
		ssr: false,
	},
);

const ProjectFavicon = () => {
	useProjectFavicon();

	return null;
};

const rightSidebars = () => [];

const globalComponents = [
	withAnyProjectContext(ProjectFavicon),
	withAnyProjectContext(ProjectTrackers),
	withAnyProjectContext(ProjectRouteListener),
];

export const projectLayout: Layout = createLayout({
	globalComponents,
	rightSidebars: rightSidebars(),
});

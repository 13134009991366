/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { AppSkeletonImage } from '../../../common/ui/AppSkeletonImage.tsx';
import imgSVG from './assets/virtual-agent-landing-app-skeleton.tpl.svg';

const customStyles = css({
	margin: '0 auto',
	maxWidth: '960px',
});

export const VirtualAgentLandingSkeleton = () => (
	<AppSkeletonImage src={imgSVG} css={customStyles} />
);

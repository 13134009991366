import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskNavigationResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskReportsSummaryRoute } from '@atlassian/jira-router-routes-servicedesk-reports-routes/src/servicedeskReportsSummaryRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { Redirect, type RouteContext, type Route } from '@atlassian/react-resource-router';

const returnToSummary = ({ match }: RouteContext) => <Redirect to={String(match.params['0'])} />;

export const servicedeskReportsSummaryRouteEntry: Route = createEntry(
	servicedeskReportsSummaryRoute,
	() => ({
		group: ROUTE_GROUPS_SERVICEDESK,
		component: returnToSummary,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},
		resources: [...getServiceDeskNavigationResources(), getConsolidationStateResource()],
		forPaint: [LazyAtlassianNavigation],
	}),
);

import type { Boards } from '../../model/index.tsx';

export const createRapidBoardUrl = (baseUrl: string, id: string): string =>
	`/secure/RapidBoard.jspa?rapidView=${id}&useStoredSettings=true`;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	response: {
		values: [];
	},
	baseUrl: string,
): Boards => {
	const boards = (response && response.values) || [];
	return boards.map((board) => {
		const { name, id } = board;
		const href = createRapidBoardUrl(baseUrl, id);

		return {
			name,
			id,
			href,
		};
	});
};

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	projectSettings: {
		id: 'navigation-project-action-menu.project-settings',
		defaultMessage: 'Project settings',
		description: 'The label for the link that navigates to the project settings',
	},
	moreActions: {
		id: 'navigation-project-action-menu.more-actions',
		defaultMessage: 'More actions',
		description:
			'Used as the alt text for an icon button that when used shows a popup menu of more options.',
	},
});

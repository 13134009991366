import type { Action } from '@atlassian/react-sweet-state';
import {
	type State,
	createTitleFromTemplate,
	type DocumentTitleContainerProps,
} from '../../utils.tsx';

/**
 * @deprecated Do not use - prefer updateTitle instead as updateTitle also includes the `checkDocumentTitle` check
 * which means updateTitle avoids consumers needing to listen to contextArray state. When contextArray is subscribed to
 * in the consumer, this can cause infinite re-renders
 */
export const setTitle =
	(title: string): Action<State, DocumentTitleContainerProps> =>
	({ setState, getState }, { titleSuffix }) => {
		const state = getState();
		const titleTemplate = titleSuffix ? `%s - ${titleSuffix}` : '%s - Jira';
		const newTitle = createTitleFromTemplate(title, titleTemplate);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.title = newTitle;

		setState({
			...state,
			title: newTitle,
		});
	};

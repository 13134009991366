import type { ResourceDependencies } from '@atlassian/react-resource-router/src/common/types';

export async function consumeDependency(dependencies: ResourceDependencies, key: string) {
	// There is an inflight request, the response is source of truth
	if (dependencies[key]?.loading) {
		return dependencies[key]?.promise;
	}
	if (dependencies[key]?.error) {
		// Our dependency errored out the last time it ran, we don't have the data
		throw dependencies[key]?.error;
	}
	// Last response is what we want
	return dependencies[key]?.data;
}

import React from 'react';
import { InvitePeopleModalContextProvider } from '@atlassian/invite-people/InvitePeopleModalContext';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './common/constants.tsx';
import { TeamsMenu } from './ui/index.tsx';

export const Teams = () => (
	<UFOSegment name="nav4-sidebar-teams">
		<JSErrorBoundary fallback="flag" id="teams" packageName={PACKAGE_NAME} teamName={TEAM_NAME}>
			<InvitePeopleModalContextProvider>
				<TeamsMenu />
			</InvitePeopleModalContextProvider>
		</JSErrorBoundary>
	</UFOSegment>
);

import React, { useState, useEffect } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useSpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/page-ready-state/index.tsx';
import type { NonCriticalProps, NonCriticalWithFallbackTimerProps } from './types.tsx';
/**
 * Provides a mechanism to delay the rendering of children until either a set timer expires or a readiness condition is met. Ideal for deferring non-essential content to streamline initial page loads.
 */
export const NonCriticalComponentWithTimerFallback = ({
	children,
	timerThreshold,
}: NonCriticalWithFallbackTimerProps) => {
	const [{ isReady }] = useSpaStatePageReady();
	const [isFirstRun, setFirstRun] = useState(true);
	const [hasThresholdTimerExpired, setHasThresholdTimerExpired] = useState(false);
	useEffect(() => {
		if (timerThreshold != null) {
			setTimeout(() => {
				setHasThresholdTimerExpired(true);
			}, timerThreshold);
		}
	}, [timerThreshold]);
	if (!isFirstRun || isReady || hasThresholdTimerExpired) {
		isFirstRun && setFirstRun(false);
		return <>{children}</>;
	}
	return null;
};
export const NonCriticalWithTimerFallback = ({
	id = 'non-critical-with-timer',
	teamName,
	...props
}: NonCriticalWithFallbackTimerProps) => (
	<JSErrorBoundary
		id={id}
		teamName={teamName}
		packageName="jiraPlatformNonCritical"
		fallback="unmount"
	>
		<NonCriticalComponentWithTimerFallback {...props} />
	</JSErrorBoundary>
);
/**
 * Delays rendering its children until the page is deemed ready, optimizing for a smoother initial load. Suitable for content that is not immediately necessary.
 */
const NonCriticalComponent = (props: NonCriticalProps) => {
	const [{ isReady }] = useSpaStatePageReady();
	const [isFirstRun, setFirstRun] = useState(true);
	if (!isFirstRun || isReady) {
		isFirstRun && setFirstRun(false);
		return <>{props.children}</>;
	}
	return null;
};
export const NonCritical = ({ id = 'non-critical', teamName, ...props }: NonCriticalProps) => (
	<JSErrorBoundary
		id={id}
		teamName={teamName}
		packageName="jiraPlatformNonCritical"
		fallback="unmount"
	>
		<NonCriticalComponent {...props} />
	</JSErrorBoundary>
);
export default NonCriticalComponent;

import React from 'react';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyOverviewPage = lazyForPaint<any>(() =>
	import(
		/* webpackChunkName: "async-jira-eoc-overview", jiraSpaEntry: "async-jira-eoc-overview" */ '@atlassian/jira-eoc-overview/src/ui/overview-page/index.tsx'
	).then((m) => m.OverviewPage),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyOverviewAllTeamsPage = lazyForPaint<any>(() =>
	import(
		/* webpackChunkName: "async-jira-eoc-all-teams", jiraSpaEntry: "async-jira-eoc-all-teams" */ '@atlassian/jira-eoc-overview/src/ui/all-teams-page/index.tsx'
	).then((m) => m.AllTeamsPage),
);

export const EocOverviewComponent = () => (
	<LazyPage Page={LazyOverviewPage} pageId="async-jira-eoc-overview" shouldShowSpinner={false} />
);

export const EocOverviewAllTeamsComponent = () => (
	<LazyPage
		Page={LazyOverviewAllTeamsPage}
		pageId="async-jira-eoc-overview-all-teams"
		shouldShowSpinner={false}
	/>
);

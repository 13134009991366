import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issueTypes: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings-nextgen.issue-types.issue-types',
		defaultMessage: 'Issue types',
		description: 'title for issue types navigation item',
	},
	addIssueType: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings-nextgen.issue-types.add-issue-type',
		defaultMessage: 'Add issue type',
		description: 'Button in navigation sidebar. Opens a modal dialog',
	},
	issueTypesIssueTermRefresh: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings-nextgen.issue-types.issue-types-issue-term-refresh',
		defaultMessage: 'Issue types',
		description: 'title for issue types navigation item',
	},
	addIssueTypeIssueTermRefresh: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings-nextgen.issue-types.add-issue-type-issue-term-refresh',
		defaultMessage: 'Add issue type',
		description: 'Button in navigation sidebar. Opens a modal dialog',
	},
});

/**
 * @generated SignedSource<<6617fdd64c56ea866d6faae390001a1f>>
 * @relayHash 8dfca21fd144cce7f622306a28f4dcfb
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID af5db09a1be2cb5d011bc64dd4e0b6384b2b99efcee2e68c006368aa8bce8161

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcCustomerServiceServiceHubsPageQuery } from './srcCustomerServiceServiceHubsPageQuery.graphql';

const node: PreloadableConcreteRequest<srcCustomerServiceServiceHubsPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "af5db09a1be2cb5d011bc64dd4e0b6384b2b99efcee2e68c006368aa8bce8161",
    "metadata": {},
    "name": "srcCustomerServiceServiceHubsPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

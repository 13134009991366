import { di } from 'react-magnetic-di';
import { LEGACY_ATLASSIAN_GDPR_COOKIE_KEY, getCookie } from '@atlassian/browser-storage-controls';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	type HookActionsFunction,
	type Action,
	createHook,
	createStore,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import type { State } from './types.tsx';

/**
 * TODO: code is duplicated so this needs clean up after preferences fetching is extracted from cookie banner life cycle.
 * Source: https://bitbucket.org/atlassian/atlassian-frontend/src/master/packages/policy-platform/browser-storage-controls/src/controllers/storage-preferences/get-preferences/index.ts
 */
export function unpackUserPreferencesCookie(userPreferencesCookie: string) {
	// Format 0000000
	// 000 (schema version 3 digits)
	// 0/1 (1 digit, FUNCTIONAL cookies disabled/enabled)
	// 0/1 (1 digit, ANALYTICS cookies disabled/enabled)
	// 0/1 (1 digit, MARKETING cookies disabled/enabled)
	// 0/1 (1 digit, UNKNOWN cookies disabled/enabled)
	if (userPreferencesCookie.length < 3) {
		// Wrong format, no version
		return;
	}
	const version = userPreferencesCookie[0] + userPreferencesCookie[1] + userPreferencesCookie[2];

	if (version !== '001') {
		// We support version 001 only
		return;
	}

	if (userPreferencesCookie.length !== 7) {
		// Wrong length for version 001
		return;
	}

	return {
		STRICTLY_NECESSARY: true, // always allowed
		FUNCTIONAL: userPreferencesCookie[3] === '1',
		ANALYTICS: userPreferencesCookie[4] === '1',
		MARKETING: userPreferencesCookie[5] === '1',
		UNKNOWN: userPreferencesCookie[6] === '1',
	};
}

export const getPreferences = () => {
	if (__SERVER__) {
		return undefined;
	}

	const packedPrefs = getCookie(LEGACY_ATLASSIAN_GDPR_COOKIE_KEY);

	if (!packedPrefs) {
		return undefined;
	}

	return unpackUserPreferencesCookie(packedPrefs);
};

export const isInIframe = () => {
	di(window);
	if (__SERVER__) {
		return false;
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	return window !== window.top;
};

export const getIsDismissedInitialState = () => {
	if (__SERVER__) {
		return false;
	}

	if (ff('platform.moonjelly.browser-storage-controls-v2')) {
		return true;
	}

	return getPreferences() !== undefined || isInIframe();
};

// Fetching banner preferences and height in initial state to prevent layout shifts.
export const initialState: State = {
	isDismissed: getIsDismissedInitialState(),
	height: 0,
};

export const actions = {
	onShow:
		(): Action<State> =>
		({ getState, setState }) => {
			setState({
				...getState(),
				isDismissed: false,
			});
		},
	onDismiss:
		(): Action<State> =>
		({ getState, setState }) => {
			setState({
				...getState(),
				isDismissed: true,
			});
		},
	setHeight:
		(height: number): Action<State> =>
		({ getState, setState }) => {
			setState({
				...getState(),
				height,
			});
		},
} as const;

type Actions = typeof actions;

const Store = createStore<State, Actions>({
	name: 'cookie-consent-banner',
	initialState,
	actions,
});

export const useCookieConsentBannerSettings = createHook(Store);
// @ts-expect-error - TS2322 - Type 'HookActionsFunction<BoundActions<State, { readonly onShow: () => Action<State>; readonly onDismiss: () => Action<State>; readonly setWidth: (width: number) => Action<State>; readonly setHeight: (height: number) => Action<...>; }>>' is not assignable to type 'HookActionsFunction<{ readonly onShow: () => Action<State>; readonly onDismiss: () => Action<State>; readonly setWidth: (width: number) => Action<State>; readonly setHeight: (height: number) => Action<...>; }>'.
export const useCookieConsentBannerSettingsActions: HookActionsFunction<Actions> =
	createActionsHook(Store);

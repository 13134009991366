export const API_GATEWAY_BASE_URL = '/gateway/api';

export function isStaging() {
	const hostname = window.location.hostname;

	return (
		hostname.includes('localhost') ||
		hostname.includes('.stg.internal.atlassian') ||
		hostname.includes('.stg.atlassian')
	);
}

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorFlagTitle: {
		id: 'jql-builder-advanced.error-flag-title',
		defaultMessage: "We couldn't edit the query",
		description:
			'Title of the error flag shown to customers when an error prevented the JQL editor from rendering.',
	},
	errorFlagDescription: {
		id: 'jql-builder-advanced.error-flag-description',
		defaultMessage: 'The JQL builder is unresponsive. Refresh the page to try again.',
		description:
			'Description of the error flag shown to customers when an error prevented the JQL editor from rendering.',
	},
	searchCurrentPage: {
		id: 'jql-builder-advanced.search-current-page',
		defaultMessage: 'Search on current page',
		description: 'Command in the command palette to trigger the page search',
	},
});

import React, { memo } from 'react';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-common/src/async.tsx';
import {
	setPerformanceMarkLoadStart,
	setPerformanceMarkLoadEnd,
} from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/performance-marks/index.tsx';
import { lazyAfterPaint, lazyForPaint } from '@atlassian/react-loosely-lazy';
import { useIsActiveRoute } from '@atlassian/react-resource-router';
import type ServicedeskSidebarType from './ui/index.tsx';

// NOTE: using lazyForPaint instead of lazyAfterPaint caused #hot-108329 (in 2024), commit 58fc7e35c54e8cd4d545456e33ce85f53c830a42
export const LazyAfterPaintServicedeskSidebar = lazyAfterPaint<typeof ServicedeskSidebarType>(
	() => {
		setPerformanceMarkLoadStart();

		return import(/* webpackChunkName: "async-sidebar-servicedesk" */ './index').then((bundle) => {
			setPerformanceMarkLoadEnd();
			return bundle;
		});
	},
);

const LazyForPaintServicedeskSidebar = lazyForPaint<typeof ServicedeskSidebarType>(() => {
	setPerformanceMarkLoadStart();

	// eslint-disable-next-line jira/import/webpack-magic-comment-entries
	return import(/* webpackChunkName: "async-sidebar-servicedesk" */ './index').then((bundle) => {
		setPerformanceMarkLoadEnd();
		return bundle;
	});
});

const AfterPaintServicedeskSidebar = memo(() => (
	<SidebarBoundary id="servicedeskProject">
		<LazyAfterPaintServicedeskSidebar />
	</SidebarBoundary>
));

const ForPaintServicedeskSidebar = memo(() => (
	<SidebarBoundary id="servicedeskProject">
		<LazyForPaintServicedeskSidebar />
	</SidebarBoundary>
));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ServicedeskSidebarLazyForPaintIssueRouteOnly = memo<Record<any, any>>(() => {
	const isIssueRoute = useIsActiveRoute('issue');
	return isIssueRoute ? <ForPaintServicedeskSidebar /> : <AfterPaintServicedeskSidebar />;
});

const ServicedeskSidebar = componentWithFG(
	'nav_sidebar_software_classic_lazyforpaint',
	ServicedeskSidebarLazyForPaintIssueRouteOnly,
	AfterPaintServicedeskSidebar,
);

ServicedeskSidebar.displayName = 'ServicedeskSidebar';
ForPaintServicedeskSidebar.displayName = 'ServicedeskSidebar';

export { ForPaintServicedeskSidebar };
export default ServicedeskSidebar;

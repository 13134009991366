import { useCallback, useMemo } from 'react';
import { di } from 'react-magnetic-di';
// A single instance for the entire SPA session
const history = {
	unique: [],
} as const;

export const useConnectItemClickHistory = () => {
	di(window);
	const addNew = useCallback((historyItem: string) => {
		// Here we want to store only unique items. For example, if the add-on updates Jira hash
		// and user clicks the same navigation link we consider it as a unique navigation action
		// so we can use this info later on to rerender the iframe

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (window.location.href.endsWith(historyItem) === false) {
			// @ts-expect-error - TS2339 - Property 'push' does not exist on type 'readonly []'.
			history.unique.push(historyItem);
		}
	}, []);
	return useMemo(() => ({ history, addNew }), [addNew]);
};
// For test purposes only
export const resetHistory = () => {
	// @ts-expect-error TS2540: Cannot assign to 'length' because it is a read-only property.
	history.unique.length = 0;
};

/**
 * @generated SignedSource<<99ae0070362b4c7355828f33b3c3e4f5>>
 * @relayHash 3622903d243ea547c8a7721ef5084385
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b71b3d5ec8a109e4b74f69fbea3bb5a57966bdfa516eafb76ec2ee9f1ea68151

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessBoardEmbedQuery } from './src_jiraBusinessBoardEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessBoardEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "b71b3d5ec8a109e4b74f69fbea3bb5a57966bdfa516eafb76ec2ee9f1ea68151",
    "metadata": {},
    "name": "src_jiraBusinessBoardEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

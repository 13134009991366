import { JastBuilder } from '@atlaskit/jql-ast';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { PACKAGE_NAME, TEAM_NAME, jqlAnonymizerVisitor } from '../../common/constants.tsx';
import type { TextSearchInputClauseType } from '../../common/types.tsx';
import {
	JqlClauseCollectingVisitor,
	type ClauseMap,
} from '../../utils/jql-clause-collecting-visitor.tsx';
import { UnsupportedRefinementError } from '../../utils/unsupported-refinement-error.tsx';
import type { Store, Props } from './types.tsx';

/**
 * getASTAndClausesFromJQL generate AST and extract all necessary data from it (check Store type for more info)
 */
export const getASTAndClausesFromJQL = ({
	jql,
	createAnalyticsEvent,
	onSwitchToJql,
}: Props): Store => {
	const ast = new JastBuilder().build(jql);

	let clauseMap: ClauseMap = {};
	let textSearchInputClause: TextSearchInputClauseType;

	if (ast.query) {
		try {
			({ clauseMap, textSearchInputClause } = new JqlClauseCollectingVisitor(false).visit(
				ast.query,
			));
		} catch (error) {
			if (error instanceof UnsupportedRefinementError) {
				fireErrorAnalytics({
					event: createAnalyticsEvent({}), // forces contextual attributes be included
					error,
					meta: {
						id: 'queryTooComplexInBasicMode',
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					attributes: {
						anonymizedJql: ast.query ? ast.query.accept(jqlAnonymizerVisitor) : undefined,
					},
				});
				onSwitchToJql && onSwitchToJql();
			} else {
				throw error;
			}
		}
	}

	return { ast, clauseMap, textSearchInputClause };
};

import React from 'react';
import type NotFoundErrorPageType from '@atlassian/jira-error-pages/src/ui/not-found-error-page/NotFoundErrorPage.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { notFoundEntrypoint } from '@atlassian/jira-spa-apps-error-pages-not-found/entrypoint.tsx';

export const LazyNotFoundErrorPage = lazyForPaint<typeof NotFoundErrorPageType>(
	() =>
		import(
			/* webpackChunkName: "async-error-pages-not-found", jiraSpaEntry: "async-error-pages-not-found" */ '@atlassian/jira-spa-apps-error-pages-not-found'
		),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<LazyPage Page={LazyNotFoundErrorPage} pageId="errors-404" shouldShowSpinner={false} />
);

export const notFoundPageEntrypoint = createPageEntryPoint({
	main: notFoundEntrypoint,
	topNavigationMenuId: MENU_ID.HOME,
});

import React, { lazy, Suspense } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { useIntl } from 'react-intl-next';

import Heading from '@atlaskit/heading';
import AlignLeft from '@atlaskit/icon/core/align-left';
import { Box, Stack } from '@atlaskit/primitives';
import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';
import { MenuList } from '@atlassian/navigation-system/side-nav/menu-list';
import { Divider } from '@atlassian/navigation-system/side-nav/menu-section';

import { ErrorState } from '../error-state';
import { LoadingState } from '../loading-state';
import { messages } from '../messages';

const VIEW_ALL_LINK = 'https://home.atlassian.com/recents';

const LazyGlobalRecentItems = lazy(
	() =>
		import(/* webpackChunkName: "@atlaskit-internal_global-recent--items" */ './GlobalRecentItems'),
);

export const GlobalRecentMenuContent = () => {
	const { formatMessage } = useIntl();

	return (
		<Stack>
			<Box paddingInline="space.075" paddingBlock="space.100">
				<Heading size="xsmall">{formatMessage(messages.flyoutMenuItemTriggerTitle)}</Heading>
			</Box>

			<ErrorBoundary fallback={<ErrorState />}>
				<Suspense fallback={<LoadingState />}>
					<LazyGlobalRecentItems />
				</Suspense>
			</ErrorBoundary>

			<Divider />

			<MenuList>
				<MenuLinkItem
					elemBefore={<AlignLeft label="" spacing="spacious" color="currentColor" />}
					href={VIEW_ALL_LINK}
				>
					{formatMessage(messages.viewAllRecentItems)}
				</MenuLinkItem>
			</MenuList>
		</Stack>
	);
};

export default GlobalRecentMenuContent;

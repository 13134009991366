/**
 * @generated SignedSource<<84251d4ba346cd559795e65ac44cfe5d>>
 * @relayHash 43393cc02930534ddf9cf699f0749855
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3d390937bda8063dcae36461c12793d74da18dc3e42bb51b5559ba5d159c4ee7

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraSummaryBusinessQuery } from './src_jiraSummaryBusinessQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraSummaryBusinessQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "3d390937bda8063dcae36461c12793d74da18dc3e42bb51b5559ba5d159c4ee7",
    "metadata": {},
    "name": "src_jiraSummaryBusinessQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';

const AsyncPendoAnalytics = lazyAfterPaint(() =>
	import(/* webpackChunkName: "async-pendo-analytics" */ './index').then(
		({ PendoAnalytics }) => PendoAnalytics,
	),
);

// This is a wrapper component that will catch any errors thrown by the lazy loaded component
// and report them to Sentry and Splunk
// We don't use JSErrorBoundary here because we don't want user to see a UI flag/page if
// there are any pendo issues
export const AsyncPendoAnalyticsWrapper = () => (
	<ErrorBoundary id="jiraCommonPendoAnalyticsReportErrors" packageName="jiraCommonPendoAnalytics">
		<ReportErrors
			teamName="martech"
			id="jiraCommonPendoAnalyticsReportErrors"
			packageName="jiraCommonPendoAnalytics"
		>
			<AsyncPendoAnalytics />
		</ReportErrors>
	</ErrorBoundary>
);

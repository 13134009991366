import React, { type ComponentType } from 'react';
import AppPermissionsCheck from '@atlassian/jira-app-permissions-check/src/AppPermissionsCheck.tsx';
import {
	coalesce,
	createLayout,
	type Layout,
} from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';

const withServiceProjectAccess = (Component: ComponentType<{}>) => {
	const ScopedComponent = () => (
		<AppPermissionsCheck hasServiceDeskAccess>
			<Component />
		</AppPermissionsCheck>
	);

	const name = coalesce(Component.displayName) ?? coalesce(Component.name) ?? 'Component';

	ScopedComponent.displayName = `withServiceProjectAccess(${name})`;

	return ScopedComponent;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const Onboarding = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "async-service-project-first-impressions-onboarding" */ './ui/onboarding/index.tsx'
		),
	{ ssr: false },
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const RightSidebars = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "async-service-project-first-impressions-right-sidebars" */ './ui/right-sidebars/RightSidebars.tsx'
		),
	{ ssr: false },
);

export const serviceProjectFirstImpressionsLayout: Layout = createLayout({
	globalComponents: [withServiceProjectAccess(Onboarding)],
	rightSidebars: [withServiceProjectAccess(RightSidebars)],
});

import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SANDBOX } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { bundlerSandboxRoute } from '@atlassian/jira-router-routes-sandbox-routes/src/bundlerSandboxRoute.tsx';
import type BundlerSandboxPageType from '@atlassian/jira-spa-apps-bundler-sandbox/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Route } from '@atlassian/react-resource-router';

const LazyBundlerSandboxPage = lazyForPaint<typeof BundlerSandboxPageType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-bundler-sandbox", jiraSpaEntry: "async-bundler-sandbox" */ '@atlassian/jira-spa-apps-bundler-sandbox'
		),
	),
);

const BundlerSandbox = () => (
	<LazyPage Page={LazyBundlerSandboxPage} pageId="bundler-sandbox" shouldShowSpinner />
);

export const bundlerSandboxRouteEntry: Route = createEntry(bundlerSandboxRoute, () => ({
	group: ROUTE_GROUPS_SANDBOX,
	component: componentWithFG('conditional_import_sandbox', BundlerSandbox, ErrorPagesNotFound),
	layout: globalLayout,
	resources: [...getNavigationResources()],
	forPaint: [LazyAtlassianNavigation, LazyBundlerSandboxPage],
	ufoName: 'bundler-sandbox',
}));

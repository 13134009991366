import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { AsyncHorizontalOverviewNav } from '@atlassian/jira-business-horizontal-nav/src/async.tsx';
import { ROUTE_GROUPS_CLASSIC_BUSINESS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async.tsx';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getOverviewViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { classicBusinessOverviewTimelineRoute } from '@atlassian/jira-router-routes-business-routes/src/classicBusinessOverviewTimelineRoute.tsx';
import OverviewTimelineView, {
	LazyOverviewTimelineView,
} from './ui/spa/business/overview-timeline/index.tsx';

export const classicBusinessOverviewTimelineRouteEntry = createEntry(
	classicBusinessOverviewTimelineRoute,
	() => ({
		group: ROUTE_GROUPS_CLASSIC_BUSINESS,
		component: OverviewTimelineView,
		layout: globalLayout,
		skeleton: ThemedLazySkeleton,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalOverviewNav,
		},

		resources: getOverviewViewResources(),
		forPaint: [LazyAtlassianNavigation, LazyOverviewTimelineView],
	}),
);

import React from 'react';
import Settings from '@atlaskit/icon/core/settings';
import { LinkItem, Section } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import { useOgTenantInfo } from '@atlassian/jira-eoc-tenant-info/src/controllers/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	BUTTON,
	DROPDOWN_ITEM,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import {
	FIXED_ITEM_BUTTON,
	FIXED_ITEM_DROPDOWN_ACTION,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { MEATBALL_DROPDOWN } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-id.tsx';
import {
	LEVEL_ONE,
	LEVEL_TWO,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { SECTION_ITEM_OPERATIONS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import { MENU_ID_OPERATIONS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import {
	type OnCloseProps,
	L1_MENU_ID,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { useStopPropagation } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-stop-propagation/index.tsx';
import { HideL1MenuItemSection } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/hide-l1-menu-item-action/index.tsx';
import { MoreNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/main.tsx';
import type { MoreNavMenuButtonMenuItemsProps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/submenu/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { SERVICE_DESK } from '@atlassian/jira-shared-types/src/application-key.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import messages from './messages.tsx';

export function ActionButtons({ onClose }: OnCloseProps) {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return (
		<MoreNavMenuButton
			onOpen={() => {
				fireUIAnalytics(createAnalyticsEvent({}), {
					action: 'clicked',
					actionSubject: BUTTON,
					actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_OPERATIONS],
					attributes: {
						componentType: FIXED_ITEM_BUTTON,
						level: LEVEL_ONE,
						itemId: MEATBALL_DROPDOWN,
					},
				});
			}}
			MenuItems={MoreMenuItems}
			onClose={onClose}
		/>
	);
}

function MoreMenuItems({ onClose }: MoreNavMenuButtonMenuItemsProps) {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { formatMessage } = useIntl();
	const onCloseWithStopPropagation = useStopPropagation(
		(event: Event | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
			onClose?.(event);

			fireUIAnalytics(createAnalyticsEvent({}), {
				action: 'clicked',
				actionSubject: DROPDOWN_ITEM,
				actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_OPERATIONS],
				attributes: {
					componentType: FIXED_ITEM_DROPDOWN_ACTION,
					level: LEVEL_TWO,
					itemId: 'generalConfiguration',
					parentItemId: SECTION_ITEM_OPERATIONS,
				},
			});
		},
	);

	const tenantContext = useTenantContext();
	const { isOpsAdmin } = useOgTenantInfo();

	const shouldShowGeneralConfiguration = fg('operations-tenant-info-endpoint-update')
		? (tenantContext.isAdmin || isOpsAdmin) && tenantContext.licensedProducts[SERVICE_DESK]
		: tenantContext.isAdmin && tenantContext.licensedProducts[SERVICE_DESK];
	return (
		<>
			{shouldShowGeneralConfiguration ? (
				<Section>
					<LinkItem
						iconBefore={<Settings label="" spacing="spacious" color={token('color.icon')} />}
						href="/jira/settings/products/ops/jira-service-management-audit-logs"
						onClick={onCloseWithStopPropagation}
					>
						{formatMessage(messages.generalConfiguration)}
					</LinkItem>
				</Section>
			) : null}
			<HideL1MenuItemSection
				menuId={L1_MENU_ID.OPERATIONS}
				hasSeparator={shouldShowGeneralConfiguration}
			/>
		</>
	);
}

import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type {
	OptionResponse,
	SelectOption,
} from '@atlassian/jira-jql-builder-basic-picker/src/common/types.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './constants.tsx';
import { type PropTypes, TextAreaPropTypes, PickerPropTypes } from './types.tsx';

export const generateOptions = (
	options: ReadonlyArray<SelectOption>,
	totalCount?: number | null,
	endCursor?: string,
): OptionResponse => ({
	options,
	totalCount: totalCount === undefined ? null : totalCount,
	endCursor,
});

export const generateErrorOptions = (error: Error) =>
	generateOptions([
		{
			optionType: 'error',
			error,
			onRetry: () => undefined,
		},
	]);

export const fireJqlBuilderBasicErrorAnalytics = (
	id: string,
	searchTemplate: string,
	error: Error,
) =>
	fireErrorAnalytics({
		meta: {
			id,
			packageName: PACKAGE_NAME,
			teamName: TEAM_NAME,
		},
		error,
		sendToPrivacyUnsafeSplunk: true,
		attributes: { searchTemplate },
	});

export const wrapWithQuotes = (text: string) => `"${text.replace(/"/g, '\\"')}"`;

// TODO: escapeBackslashes serves as a temporary patch to convert jqlTerm into a format suitable for creator API.
// EM-6563 should resolve this by updating creator API to accept the semantic JQL value for jqlTerm currently returned by hydrateJqlQuery.
export const escapeBackslashes = (text: string) => text.replace(/\\/g, '\\\\');

export const isPickerPropType = (propType: PropTypes): propType is PickerPropTypes =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	Object.values(PickerPropTypes as Record<string, unknown>).includes(propType);

export const isTextAreaPropType = (propType: PropTypes): propType is TextAreaPropTypes =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	Object.values(TextAreaPropTypes as Record<string, unknown>).includes(propType);

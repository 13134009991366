// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentType, type ComponentProps } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { NudgeBundledUsersXflow } from './ui/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const NudgeBundledUsersFlagContainer = lazyForPaint<
	ComponentType<
		JSX.LibraryManagedAttributes<
			typeof NudgeBundledUsersXflow,
			ComponentProps<typeof NudgeBundledUsersXflow>
		>
	>
>(
	() =>
		import(/* webpackChunkName: "async-nudge-bundled-users-flag" */ './ui/index').then(
			(module) => module.NudgeBundledUsersXflow,
		),
	{ ssr: false },
);

export const AsyncNudgeBundledUsersFlagContainer = () => (
	<JSErrorBoundary
		id="nudgeBundledUserFlag"
		packageName="nudgeBundledUsersToJoinConnie"
		fallback="unmount"
		teamName="confluenceOnboarding"
	>
		<Placeholder name="nudge-bundled-users-flag" fallback={null}>
			<NudgeBundledUsersFlagContainer />
		</Placeholder>
	</JSErrorBoundary>
);

/**
 * @generated SignedSource<<feb4ddb92be08c0d717024aa5a8194a8>>
 * @relayHash 368df621eebbab5cb9e1cf9ceded10d2
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4c922c068178abaa255d12a88a8254e5b236724d396e47df3f3461e152127536

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiCustomerDetailsPageQuery } from './uiCustomerDetailsPageQuery.graphql';

const node: PreloadableConcreteRequest<uiCustomerDetailsPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "4c922c068178abaa255d12a88a8254e5b236724d396e47df3f3461e152127536",
    "metadata": {},
    "name": "uiCustomerDetailsPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import React from 'react';
import { Text } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { EmptyContentMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/empty-content/index.tsx';
import messages from './messages.tsx';

export function PlansEmptyContent() {
	const { formatMessage } = useIntl();

	return (
		<EmptyContentMenuItem>
			<Text size="small" color="color.text.subtle">
				{formatMessage(messages.noPlans)}
			</Text>
		</EmptyContentMenuItem>
	);
}

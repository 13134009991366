import { createResource } from 'react-resource-router';

import { assert, AssertionError } from '@atlassian/eoc-common';

import { getSidebarRightsEndpoint, getTeamByIdEndpoint } from '../endpoints';

export const teamResource = createResource({
	type: 'teamResource',
	getKey: (routerContext) => {
		const teamId = routerContext.match.params?.teamId;

		return `team-dashboard/${teamId ?? ''}`;
	},
	getData: async (routerContext) => {
		const teamId = routerContext.match.params?.teamId;

		assert(
			!!teamId,
			new AssertionError('teamSidebarResource', 'teamId should be set to fetch team-dashboard'),
		);

		return getTeamByIdEndpoint(teamId).catch((e) => {
			e.skipSentry = true;
			throw e;
		});
	},
	maxAge: 300000, // refresh it after 5 minutes
	isBrowserOnly: true,
});

export const sidebarRightsResource = createResource({
	type: 'sidebarRightsResource',
	getKey: (routerContext) => {
		const teamId = routerContext.match.params?.teamId;
		return `team-dashboard/${teamId ?? ''}/sidebar-rights`;
	},
	getData: async (routerContext) => {
		const teamId = routerContext.match.params?.teamId;

		assert(
			!!teamId,
			new AssertionError('teamSidebarResource', 'teamId should be set to fetch sidebar rights'),
		);

		return getSidebarRightsEndpoint(teamId).catch((e) => {
			e.skipSentry = true;
			throw e;
		});
	},
	isBrowserOnly: true,
	maxAge: Infinity,
});

export const resources = [teamResource, sidebarRightsResource];

export type { Team } from '../../common/types';

import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const embeddedConfluencePageModalEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-embedded-confluence-page-modal" */ './src/ui/embedded-page-modal'
			),
	),
	getPreloadProps: () => ({}),
});

import { useCallback, useMemo } from 'react';
import {
	NAVIGATION_ITEM,
	BUTTON,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import {
	FIXED_ITEM_BUTTON,
	CUSTOM_ITEM,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { OVERVIEW } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-type.tsx';
import { LEVEL_ONE } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { GLOBAL } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/navigation-layer.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { OverviewParams } from '../types.tsx';

const OVERVIEWS_NAVIGATION_MENU_ITEM = 'overviewsNavigationMenuItem';
export const useOverviewsAnalyticsL1 = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const commonAttributes = useMemo(
		() => ({
			level: LEVEL_ONE,
			navigationLayer: GLOBAL,
		}),
		[],
	);

	// fixed item - create overview (+) button on overviews menu (top level)
	const sendCreateOverviewAnalyticsEvent = useCallback(
		({ overviewLimitReached }: { overviewLimitReached: boolean }) => {
			const event = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: BUTTON,
			});

			const attributes = {
				...commonAttributes,
				componentType: FIXED_ITEM_BUTTON,
				itemId: 'createOverview',
				overviewLimitReached,
			};
			fireUIAnalytics(event, OVERVIEWS_NAVIGATION_MENU_ITEM, attributes);
		},
		[commonAttributes, createAnalyticsEvent],
	);

	// custom item - overview menu item that displays overview name (can be expandable menu or a link)
	const sendOverviewMenuItemAnalyticsEvent = useCallback(
		({ overviewId }: OverviewParams) => {
			const event = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: NAVIGATION_ITEM,
			});

			const attributes = {
				...commonAttributes,
				componentType: CUSTOM_ITEM,
				itemType: OVERVIEW,
				itemId: overviewId,
			};
			fireUIAnalytics(event, OVERVIEWS_NAVIGATION_MENU_ITEM, attributes);
		},
		[commonAttributes, createAnalyticsEvent],
	);

	return {
		sendOverviewMenuItemAnalyticsEvent,
		sendCreateOverviewAnalyticsEvent,
	};
};

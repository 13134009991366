import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	manageSubscription: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-filters.common.filter-actions.manage-subscription',
		defaultMessage: 'Manage subscriptions',
		description:
			'Action in a contextual menu that allows the user to manage subscriptions for a Jira filter',
	},
	copyFilter: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-filters.common.filter-actions.copy-filter',
		defaultMessage: 'Copy filter',
		description: 'Action in a contextual menu that allows the user to copy a Jira filter',
	},
	copyFilterDialogTitle: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-filters.common.filter-actions.copy-filter-dialog-title',
		defaultMessage: 'Copy filter',
		description: 'Title of the dialog that allows the user to copy a Jira filter',
	},
	edit: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-filters.common.filter-actions.edit',
		defaultMessage: 'Edit filter',
		description: 'Action in a contextual menu that allows the user to edit a Jira filter',
	},
	changeOwner: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-filters.common.filter-actions.change-owner',
		defaultMessage: 'Change owner',
		description: "Action in a contextual menu that allows the user to change a Jira filter's owner",
	},
	delete: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-filters.common.filter-actions.delete',
		defaultMessage: 'Delete filter',
		description: 'Action in a contextual menu that allows the user to move a Jira filter to trash',
	},
	manageSubscriptionWithName: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-filters.common.filter-actions.manage-subscription-with-name',
		defaultMessage: 'Manage subscriptions for {name}',
		description:
			'Used as the alt text for the "manage subscription" menu button item. The {name} attribute refers to the name of the filter that the user wants to manage subscriptions for. For example, this could be a filter name like "blocked issues" and the full message would be "Manage Subscriptions for blocked issues".',
	},
	copyFilterWithName: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-filters.common.filter-actions.copy-filter-with-name',
		defaultMessage: 'Copy filter {name}',
		description:
			'Used as the alt text for the "copy filter" menu button item. The {name} attribute refers to the name of the filter that the user wants to copy. For example, this could be a filter name like "blocked issues" and the full message would be "Copy filter blocked issues".',
	},
	editWithName: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-filters.common.filter-actions.edit-with-name',
		defaultMessage: 'Edit filter {name}',
		description:
			'Used as the alt text for the "edit filter" menu button item. The {name} attribute refers to the name of the filter that the user wants to edit. For example, this could be a filter name like "blocked issues" and the full message would be "Edit filter blocked issues".',
	},
	changeOwnerWithName: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-filters.common.filter-actions.change-owner-with-name',
		defaultMessage: 'Change owner for {name}',
		description:
			'Used as the alt text for the "change owner" menu button item. The {name} attribute refers to the name of the filter that the user wants to change owners for. For example, this could be a filter name like "blocked issues" and the full message would be "Change owner for blocked issues".',
	},
	deleteWithName: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-filters.common.filter-actions.delete-with-name',
		defaultMessage: 'Delete filter {name}',
		description:
			'Used as the alt text for the "delete filter" menu button item. The {name} attribute refers to the name of the filter that the user wants to delete. For example, this could be a filter name like "blocked issues" and the full message would be "Delete filter blocked issues".',
	},
});

/**
 * @generated SignedSource<<24c44c1cc97a0ce676ed07f685505cfc>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4OverviewsForLanding$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4OverviewsContent">;
  readonly " $fragmentType": "Nav4OverviewsForLanding";
};
export type Nav4OverviewsForLanding$key = {
  readonly " $data"?: Nav4OverviewsForLanding$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4OverviewsForLanding">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "Nav4OverviewsForLanding",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "Nav4OverviewsContent"
    }
  ],
  "type": "Query"
};

(node as any).hash = "dca06bec8549dba3e1ff2c708224de6f";

export default node;

import { defineMessages } from '@atlassian/jira-intl/src';

export default defineMessages({
	errorFlagTitleNonFinal: {
		id: 'jet-plan-action-menu.services.set-favourite.error-flag-title-non-final',
		defaultMessage: "We couldn't save the changes",
		description: 'Title for the flag shown if the favourite update fails',
	},
	errorFlagDescriptionFailedAddNonFinal: {
		id: 'jet-plan-action-menu.services.set-favourite.error-flag-description-failed-add-non-final',
		defaultMessage: "We couldn't add {planTitle} to starred. Refresh and try again.",
		description: 'Description for the flag shown if adding plan to favourite fails',
	},
	errorFlagDescriptionFailedRemoveNonFinal: {
		id: 'jet-plan-action-menu.services.set-favourite.error-flag-description-failed-remove-non-final',
		defaultMessage: "We couldn't remove {planTitle} from starred. Refresh and try again.",
		description: 'Description for the flag shown if removing plan from favourite fails',
	},
});

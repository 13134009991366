import React, { useContext, type ReactElement } from 'react';
import { graphql, useFragment } from 'react-relay';
import { NAVIGATION_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import { SECTION_ITEM_APPS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_APPS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { NavItemsContext } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/controllers/context.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { Nav4ExpandableMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-expandable-menu-item/index.tsx';
import { getTestId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-test-id/index.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import { Nav4AppsContent } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-apps/src/common/ui/content/Nav4AppsContent.tsx';
import { ExploreMoreApps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-apps/src/common/ui/explore-more-apps/ExploreMoreApps.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { Nav4AppsForLanding$key } from '@atlassian/jira-relay/src/__generated__/Nav4AppsForLanding.graphql';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { ExpandableMenuItemContent, ExpandableMenuItemTrigger } from '@atlassian/navigation-system';

type Nav4AppsForLandingProps = {
	actionsOnHover: ReactElement;
	isExpanded: boolean;
	queryRef: Nav4AppsForLanding$key;
};

export function Nav4AppsForLanding(props: Nav4AppsForLandingProps) {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { moreMenuIds } = useContext(NavItemsContext);
	const { actionsOnHover, isExpanded, queryRef } = props;
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(L1_MENU_ID.APPS);
	const data = useFragment<Nav4AppsForLanding$key>(
		graphql`
			fragment Nav4AppsForLanding on JiraQuery {
				...Nav4AppsContent
			}
		`,
		queryRef,
	);
	const menuId = MENU_ID_APPS;

	return (
		<Nav4ExpandableMenuItem
			isExpanded={isExpanded}
			menuId={menuId}
			onExpansionToggle={(hasExpanded) => {
				if (hasExpanded) {
					const event = createAnalyticsEvent({
						action: 'clicked',
						actionSubject: NAVIGATION_ITEM,
					});
					const isInMore = moreMenuIds.includes(L1_MENU_ID.APPS);
					fireUIAnalytics(event, SECTION_ITEM_APPS, { isInMore });
				}
			}}
		>
			<ExpandableMenuItemTrigger
				actionsOnHover={actionsOnHover}
				// Prevent double-up of accessible name that occurs in icon + text situation.
				aria-label={formattedMessage}
				elemBefore={icon}
				testId={getTestId(menuId)}
			>
				{formattedMessage}
			</ExpandableMenuItemTrigger>
			<ExpandableMenuItemContent>
				<UFOSegment name="nav4-sidebar-apps-landing">
					{/* This component uses the fragment from the ancestor query. */}
					<Nav4AppsContent queryRef={data} />
					<ExploreMoreApps />
				</UFOSegment>
			</ExpandableMenuItemContent>
		</Nav4ExpandableMenuItem>
	);
}

import parameters from '@atlassian/jira-relay/src/__generated__/DashboardsContentViewQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = { cloudId: string };

export const asyncDashboardsContentViewQuery = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-nav4-dashboards-content-view-query" */ './DashboardsContentViewQuery'
		).then(({ DashboardsContentViewQuery }) => DashboardsContentViewQuery),
	),
	getPreloadProps: ({ cloudId }: EntryPointParams) => ({
		queries: {
			queryRef: {
				parameters,
				variables: {
					cloudId,
				},
			},
		},
	}),
});

import React, { forwardRef, type ForwardedRef, type ReactNode } from 'react';
import ArchiveBoxIcon from '@atlaskit/icon/core/archive-box';
import { ButtonItem } from '@atlaskit/menu';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import {
	ProjectArchiveTrigger,
	type Props,
} from '@atlassian/jira-project-archive-trigger/src/ui/index.tsx';

export const ArchiveTriggerButton = forwardRef(_ArchiveTriggerButton);

function _ArchiveTriggerButton(props: Props, ref: ForwardedRef<HTMLElement> | undefined) {
	return (
		<ProjectArchiveTrigger
			{...props}
			Component={({ children }: { children: ReactNode }) => (
				<ButtonItem
					ref={ref}
					iconBefore={<ArchiveBoxIcon label="" spacing="spacious" color={token('color.icon')} />}
					onClick={props.onClick}
				>
					<Text color="color.text">{children}</Text>
				</ButtonItem>
			)}
		/>
	);
}

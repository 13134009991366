import React, { useEffect } from 'react';
import { useIsExpanded } from '@atlassian/navigation-system';
import { useFetchActual } from '../../utils/use-fetch-actual/index.tsx';
import { SkeletonNavMenuItems } from '../skeleton-nav-menu-items/main.tsx';
import type { ExpandableMenuItemSubmenuProps } from './types.tsx';

export const ExpandableMenuItemSubmenu = (props: ExpandableMenuItemSubmenuProps) => {
	const {
		fetch: fetchMaybe,
		footer,
		isFetching: isFetchingMaybe,
		menuItems,
		skeletonCount,
	} = props;

	const { isFetching, fetch } = useFetchActual(isFetchingMaybe, fetchMaybe);
	const isExpanded = useIsExpanded();

	// Fetch on open to refresh.
	useEffect(
		() => {
			if (isExpanded && !isFetching) fetch();
		},
		// Don't include `isFetching` or this effect will go into an endless loop.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isExpanded, fetch],
	);

	return (
		<>
			{isFetching ? <SkeletonNavMenuItems count={skeletonCount} /> : menuItems}
			{footer}
		</>
	);
};

import { useCallback } from 'react';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759

type FlagProps = { title: string; description?: string };

export const useBoardCreateFlag = () => {
	const { showFlag } = useFlagService();

	const showSuccessFlag = useCallback(
		({ title, description }: FlagProps) => {
			showFlag({
				type: 'success',
				title,
				description,
				isAutoDismiss: true,
			});
		},
		[showFlag],
	);

	const showErrorFlag = useCallback(
		({ title, description }: FlagProps) => {
			showFlag({
				type: 'error',
				title,
				description,
				isAutoDismiss: true,
			});
		},
		[showFlag],
	);

	return { showSuccessFlag, showErrorFlag };
};

import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';

export const createUrl = (baseUrl: string, filterId: string): string =>
	`/rest/api/3/filter/${filterId}?expand=subscriptions`;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	baseUrl: string,
	filterId: string,
): Promise<{
	subscriptions: {
		items: [];
	};
}> => fetchJson(createUrl(baseUrl, filterId));

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	explainWhatProjectRestoreDoesPart1: {
		id: 'project-restore-modal.modal-message.explain-what-project-restore-does-part-1',
		defaultMessage:
			'The project along with its issues, Jira components, attachments, and versions will be restored.',
		description: 'Explanation for restoring a project',
	},
	jsmExplainWhatProjectRestoreDoes: {
		id: 'project-restore-modal.modal-message.jsm-explain-what-project-restore-does',
		defaultMessage:
			'Once restored, this service project will appear in directories and its issues will appear in search results. You may need to turn on email support for this service project to work as normal.',
		description: 'Explanation for restoring a project',
	},
	jsmLearnMoreAboutTrashAndRestore: {
		id: 'project-restore-modal.modal-message.jsm-learn-more-about-trash-and-restore',
		defaultMessage: 'Learn more about trash and restore.',
		description: 'Link to In project help (IPH) to learn more about trash and restore',
	},
	explainWhatProjectRestoreDoesPart1IssueTermRefresh: {
		id: 'project-restore-modal.modal-message.explain-what-project-restore-does-part-1-issue-term-refresh',
		defaultMessage:
			'The project along with its work items, Jira components, attachments, and versions will be restored',
		description: 'Explanation for restoring a project',
	},
	jsmExplainWhatProjectRestoreDoesIssueTermRefresh: {
		id: 'project-restore-modal.modal-message.jsm-explain-what-project-restore-does-issue-term-refresh',
		defaultMessage:
			'Once restored, this service project will appear in directories and its work items will appear in search results. You may need to turn on email support for this service project to work as normal.',
		description: 'Explanation for restoring a project',
	},
});

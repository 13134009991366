import React, { useCallback } from 'react';
import WarningIcon from '@atlaskit/icon/core/warning';
import AddIcon from '@atlaskit/icon/utility/add';
import { ModalTransition } from '@atlaskit/modal-dialog';
import Popup from '@atlaskit/popup';
import { Text, Inline, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import CreateOverviewModal from '@atlassian/jira-business-entity-overview/src/ui/create-overview-modal/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { FIXED_ITEM_BUTTON } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { LEVEL_ONE } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import { useOverviewsAnalyticsL1 } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/controllers/use-overviews-analytics-l1/index.tsx';
import { MENU_ID_OVERVIEWS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { CommonNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/common-nav-menu-button/main.tsx';
import { useOpenable } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-openable/index.tsx';
import { HideL1MenuItemSection } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/hide-l1-menu-item-action/index.tsx';
import { MoreNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export type SectionActionsProps = {
	totalCount: number;
};

export function SectionActions({ totalCount }: SectionActionsProps) {
	const { formatMessage } = useIntl();

	const { isOpen, onClose, onOpen, onToggle } = useOpenable();
	const { sendCreateOverviewAnalyticsEvent } = useOverviewsAnalyticsL1();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireOpenEvent = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_OVERVIEWS],
			attributes: {
				level: LEVEL_ONE,
				componentType: FIXED_ITEM_BUTTON,
				itemId: 'meatballDropdown',
			},
		});
	}, [createAnalyticsEvent]);

	const onCreateOverviewHandler = useCallback(() => {
		sendCreateOverviewAnalyticsEvent({ overviewLimitReached: false });
		onOpen();
	}, [onOpen, sendCreateOverviewAnalyticsEvent]);

	const onToggleOverviewHandler = useCallback(() => {
		sendCreateOverviewAnalyticsEvent({ overviewLimitReached: true });
		onToggle();
	}, [onToggle, sendCreateOverviewAnalyticsEvent]);

	return (
		<>
			{totalCount < 10 ? (
				<>
					<ModalTransition>{isOpen && <CreateOverviewModal onClose={onClose} />}</ModalTransition>
					<Tooltip content={formatMessage(messages.createOverview)}>
						<CommonNavMenuButton
							icon={AddIcon}
							label={formatMessage(messages.createOverview)}
							onClick={onCreateOverviewHandler}
						/>
					</Tooltip>
				</>
			) : (
				<Popup
					isOpen={isOpen}
					onClose={onClose}
					placement="right"
					content={() => (
						<Box padding="space.200">
							<Inline space="space.200" alignBlock="center">
								<WarningIcon spacing="none" label="" color={token('color.icon.warning')} />
								<Text>{formatMessage(messages.moreThanTenWarning)}</Text>
							</Inline>
						</Box>
					)}
					trigger={(triggerProps) => (
						<Tooltip content={formatMessage(messages.createOverview)}>
							<CommonNavMenuButton
								icon={AddIcon}
								label={formatMessage(messages.createOverview)}
								onClick={onToggleOverviewHandler}
								{...triggerProps}
							/>
						</Tooltip>
					)}
				/>
			)}

			<MoreNavMenuButton MenuItems={() => <MoreMenuItems />} onOpen={fireOpenEvent} />
		</>
	);
}

function MoreMenuItems() {
	return <HideL1MenuItemSection menuId={L1_MENU_ID.OVERVIEWS} />;
}

import { ROUTE_GROUPS_HELP_CENTER_SETTINGS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { formEditJirafieldsResource } from '@atlassian/jira-router-resources-service-desk-customer-service-forms/src/services/form-edit-jirafields-resource/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { helpCenterSettingsFormEditRoute } from '@atlassian/jira-router-routes-help-center-settings-routes/src/helpCenterSettingsFormEditRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { navigation } from './common/constants.tsx';
import { helpCenterSettingFormEditAppPageEntryPoint } from './ui/form-edit/index.tsx';

export const helpCenterSettingsFormEditRouteEntry: Route = createEntry(
	helpCenterSettingsFormEditRoute,
	() => ({
		group: ROUTE_GROUPS_HELP_CENTER_SETTINGS,
		perfMetricKey: 'help-center-settings.form-edit',
		layout: serviceProjectLayout,
		navigation,
		component: ErrorPagesNotFound,
		entryPoint() {
			return fg('csm-request-intake-m1') ? helpCenterSettingFormEditAppPageEntryPoint : undefined;
		},
		resources: [...getServiceDeskSettingsResources(), formEditJirafieldsResource],
	}),
);

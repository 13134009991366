import type { ConfigurableMenuId, JiraConfigurableNavigationItem } from '../../types.tsx';
import { isConfigurableMenuId } from '../is-configurable-menu-id/index.tsx';

export const getConfigurableMenuIds = (
	navItems: JiraConfigurableNavigationItem[],
	isVisible: boolean,
): ConfigurableMenuId[] =>
	navItems
		.filter((navItem) => navItem.isVisible === isVisible)
		.map(({ menuId }) => menuId)
		.filter(isConfigurableMenuId);

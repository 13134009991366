import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	recent: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-filters.common.content.recent',
		defaultMessage: 'Recent',
		description: 'Message for recent heading in the filters menu items',
	},
	starred: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-filters.common.content.starred',
		defaultMessage: 'Starred',
		description: 'Message for starred heading in the filters menu items',
	},
	viewAllStarred: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-filters.common.content.view-all-starred',
		defaultMessage: 'View all starred items',
		description: 'Message for view all starred link in the starred filters section',
	},
	defaultFilters: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-filters.common.content.default-filters',
		defaultMessage: 'Default filters',
		description: 'Collapsible navigation item that contains a list of default filters',
	},
});

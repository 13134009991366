import React from 'react';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import PerformanceMark from '@atlassian/jira-common-performance/src/set-performance-mark.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import {
	ModalBoundary,
	type ModalBoundaryProps,
} from '@atlassian/jira-modal/src/ui/modal-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { ADD_PEOPLE_LOAD_START_MARK_KEY } from './model/constants.tsx';
import type { Party, PartyId, Role } from './model/index.tsx';

export type Props = {
	isAdmin: boolean;
	isOpen: boolean;
	projectId: number;
	projectKey: string;
	projectType: ProjectType;
	analyticsPrefix: string;
	allowFlags: boolean;
	projectRoles?: Role[];
	partiesInProject?: Record<PartyId, Party>;
	defaultRoleId?: number;
	adminRoleId?: number;
	roleConfigurable?: boolean;
	accessLevel?: string;
	onStopPerfMetric?: () => void;
	onAddSuccess: (addedParties: Party[], role: Role) => void;
	onClose: () => void;
	onError: (errors: ReadonlyArray<string>) => void;
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Party, Role };

const LazyComponent = lazyForPaint(
	() => import(/* webpackChunkName: "async-project-add-people-dialog" */ './index'),
);

export const AsyncProjectAddPeopleDialogComponent = (props: Props) => (
	<JSErrorBoundary id="async-project-add-people-dialog" packageName="project-add-people">
		<Placeholder
			name="project-add-people-dialog"
			fallback={<PerformanceMark metricKey={ADD_PEOPLE_LOAD_START_MARK_KEY} />}
		>
			{/* @ts-expect-error - TS2322 - Type '{ isAdmin: boolean; isOpen: boolean; baseUrl: string; projectId: number; projectKey: string; projectType: ProjectType; analyticsPrefix: string; allowFlags: boolean; projectRoles?: Role[] | undefined; ... 8 more ...; onError: (errors: readonly string[]) => void; }' is not assignable to type 'Pick<Pick<ExternalProps, "baseUrl" | "isAdmin" | "onError" | "isOpen" | "onClose" | "projectType" | "projectKey" | "projectId" | "defaultRoleId" | "adminRoleId" | ... 8 more ... | "partiesInProject">, "baseUrl" | ... 10 more ... | "onAddSuccess">'. */}
			<LazyComponent {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

export default AsyncProjectAddPeopleDialogComponent;

export type AddPeopleModalBoundaryProps = Flow.Diff<
	ModalBoundaryProps,
	{
		id: string;
	}
>;

export const AddPeopleModalBoundary = ({ children, ...props }: AddPeopleModalBoundaryProps) => (
	<ModalBoundary id="add-people-modal" metricKey={ADD_PEOPLE_LOAD_START_MARK_KEY} {...props}>
		{children}
	</ModalBoundary>
);

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emojiButtonCaption: {
		id: 'polaris-lib-emoji-picker.emoji-button-caption',
		defaultMessage: 'Pick an emoji',
		description: 'Button caption for emoji button if none is selected',
	},
	removeEmoji: {
		id: 'polaris-lib-emoji-picker.remove-emoji',
		defaultMessage: 'Remove emoji',
		description: 'Button caption for button to remove the emoji selection',
	},
	editEmoji: {
		id: 'polaris-lib-emoji-picker.edit-emoji',
		defaultMessage: 'Edit emoji',
		description: 'Button caption for button to edit the selected emoji',
	},
	readOnlyField: {
		id: 'polaris-lib-emoji-picker.read-only-field',
		defaultMessage: 'This is a default field and it cannot be edited or deleted',
		description: 'Label for read only field',
	},
});

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import {
	ROUTE_GROUPS_SERVICEDESK,
	ROUTE_NAMES_SERVICEDESK_SERVICE_DETAILS_WITHOUT_PROJECT,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { serviceDeskServiceDetailsWithoutProjectRoute } from '@atlassian/jira-router-routes-servicedesk-services-routes/src/serviceDeskServiceDetailsWithoutProjectRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import ServicedeskServiceDetailsWithoutProjectContext, {
	LazyServicedeskServiceDetailsWithoutProjectContext,
} from './ui/service-details-without-project-context/index.tsx';

export const serviceDeskServiceDetailsWithoutProjectRouteEntry: Route = createEntry(
	serviceDeskServiceDetailsWithoutProjectRoute,
	() => ({
		group: ROUTE_GROUPS_SERVICEDESK,
		ufoName: 'service-management.service-details-without-project',
		component: ServicedeskServiceDetailsWithoutProjectContext,
		resources: [...getNavigationResources(), getConsolidationStateResource()],
		forPaint: [LazyAtlassianNavigation, LazyServicedeskServiceDetailsWithoutProjectContext],
		meta: {
			reporting: {
				id: ROUTE_NAMES_SERVICEDESK_SERVICE_DETAILS_WITHOUT_PROJECT,
				packageName: 'jiraSpaAppsServicedeskServiceDetailsWithoutProjectContext"',
				teamName: 'Falcons',
			},
		},
	}),
);

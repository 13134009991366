/**
 * @generated SignedSource<<d852b8e1fe9548a143c47fad36d41ef1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraAppType = "CONNECT" | "FORGE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type Nav4Apps_GlobalAppsFragment$data = {
  readonly globalAppNavigationItems?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly appType?: JiraAppType | null | undefined;
        readonly sections?: ReadonlyArray<{
          readonly links: ReadonlyArray<{
            readonly url: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        readonly url?: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "Nav4Apps_GlobalAppsFragment";
};
export type Nav4Apps_GlobalAppsFragment$key = {
  readonly " $data"?: Nav4Apps_GlobalAppsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Apps_GlobalAppsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "url"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "isAppsExpanded"
    }
  ],
  "kind": "Fragment",
  "name": "Nav4Apps_GlobalAppsFragment",
  "selections": [
    {
      "condition": "isAppsExpanded",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "cloudId",
              "variableName": "cloudId"
            }
          ],
          "concreteType": "JiraNavigationItemConnection",
          "kind": "LinkedField",
          "name": "globalAppNavigationItems",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraNavigationItemEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "appType"
                        },
                        (v0/*: any*/),
                        {
                          "concreteType": "JiraAppSection",
                          "kind": "LinkedField",
                          "name": "sections",
                          "plural": true,
                          "selections": [
                            {
                              "concreteType": "JiraAppNavigationItemNestedLink",
                              "kind": "LinkedField",
                              "name": "links",
                              "plural": true,
                              "selections": [
                                (v0/*: any*/)
                              ]
                            }
                          ]
                        }
                      ],
                      "type": "JiraAppNavigationItem"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraQuery"
};
})();

(node as any).hash = "cadf42461f2555d23492132472cd18b6";

export default node;

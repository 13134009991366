import React, { useMemo } from 'react';
import JSMAssetsSchemasIcon from '@atlassian/jira-icons/src/ui/jsm-assets-schemas/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ASSETS_SCHEMAS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-id.tsx';
import { MENU_ID_ASSETS_SCHEMAS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import type { NavSubmenuHeader } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import { useFormattedMessageAndIcon } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-formatted-message-and-icon/index.tsx';
import { getLandingPageUrl } from '@atlassian/jira-servicedesk-insight-urls/src/index.tsx';
import { useAssetsUIAnalytics } from '../../../common/use-assets-ui-analytics/index.tsx';
import messages from './messages.tsx';

export const HeaderSchemasMenuItems: NavSubmenuHeader = () => {
	const { formatMessage } = useIntl();
	const schemasLink = useMemo(() => getLandingPageUrl(), []);
	const { icon: formattedSchemasIcon } = useFormattedMessageAndIcon(
		messages.jsmAssetsSchemas,
		JSMAssetsSchemasIcon,
	);
	const { fireUINavigationItemAnalytics } = useAssetsUIAnalytics();

	return (
		<Nav4MenuLinkItem
			menuId={MENU_ID_ASSETS_SCHEMAS}
			elemBefore={formattedSchemasIcon}
			href={schemasLink}
			onClick={() => fireUINavigationItemAnalytics(ASSETS_SCHEMAS)}
		>
			{formatMessage(messages.jsmAssetsSchemas)}
		</Nav4MenuLinkItem>
	);
};

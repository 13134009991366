import React, { useCallback, useEffect, useMemo } from 'react';
import { graphql, useRefetchableFragment } from 'react-relay';
import { useDeleteChangeActions } from '@atlassian/jira-delete-filters-modal/src/controllers/delete-change-actions/index.tsx';
import type { ItemTypename } from '@atlassian/jira-favourite-change-provider/src/model/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	BUTTON,
	NAVIGATION_ITEM,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import {
	FIXED_ITEM,
	FIXED_ITEM_BUTTON,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { LEVEL_ONE } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import {
	SECTION_ITEM_RECENT,
	SECTION_ITEM_STARRED,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import {
	ENTITY_ID,
	MENU_ID_FILTERS,
	MENU_ID_FILTERS_VIEW_ALL_STARRED,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { useSidebarNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-context/index.tsx';
import { useRefetchWhenIsSelected } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/controller/use-refetch-when-is-selected/index.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import { useSubscribeAndUpdateFavorites } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/services/set-favorite/index.tsx';
import { useStableItems } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-stable-items/index.tsx';
import { Nav4FilterMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-filters/src/common/ui/filter-menu-item/Nav4FilterMenuItem.tsx';
import type { Nav4FiltersContent$key } from '@atlassian/jira-relay/src/__generated__/Nav4FiltersContent.graphql';
import QUERY, {
	type Nav4FiltersContentQuery,
} from '@atlassian/jira-relay/src/__generated__/Nav4FiltersContentQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import {
	ExpandableMenuItem,
	ExpandableMenuItemContent,
	ExpandableMenuItemTrigger,
	MenuList,
	MenuListItem,
	MenuSection,
	MenuSectionHeading,
} from '@atlassian/navigation-system';
import {
	getDefaulFilterAttributes,
	getRecentAndFavouriteFilterAttributes,
	useFilterActionsAnalytics,
} from '../../utils/analytics/index.tsx';
import messages from './messages.tsx';

const MAX_RECENT_FILTERS = 5;
export const MAX_FAVORITE_FILTERS = 15;
const VIEW_ALL_STARRED_HREF = '/jira/your-work?starred';

type DefaultFilterIds = { id: string };

const defaultFiltersRequiringLogin: DefaultFilterIds[] = [{ id: '-1' }, { id: '-2' }, { id: '-3' }];

type Nav4FiltersContentProps = {
	queryRef: Nav4FiltersContent$key;
};

const entityTypenames: ItemTypename[] = ['JiraCustomFilter', 'JiraSystemFilter'];

export function Nav4FiltersContent({ queryRef }: Nav4FiltersContentProps) {
	const cloudId = useCloudId();
	const { getStableItems } = useStableItems();
	const { isSelectedPath } = useSidebarNav4();

	// We intentionally request 15 starred and 20 recent filters.
	// Recent filters should exclude system/default filters.
	// Then, if all 15 starred filters were recently visited, we still show 5 recent filters as well.
	const [fragmentData, refetch] = useRefetchableFragment<
		Nav4FiltersContentQuery,
		Nav4FiltersContent$key
	>(
		graphql`
			fragment Nav4FiltersContent on Query
			@refetchable(queryName: "Nav4FiltersContentQuery")
			@argumentDefinitions(currentURL: { type: URL }) {
				jira @required(action: THROW) {
					favouriteFilters: favourites(
						cloudId: $cloudId
						filter: { type: FILTER, sort: { order: DESC } }
						first: 15
					) @required(action: THROW) {
						__id
						pageInfo {
							hasNextPage
						}
						edges {
							node @required(action: THROW) {
								... on JiraFilter {
									__typename
									filterId
									...Nav4FilterMenuItem
								}
							}
						}
					}
					recentFilters: recentItems(
						cloudId: $cloudId
						currentURL: $currentURL
						types: [FILTER]
						first: 20
					) @required(action: THROW) @optIn(to: "JiraRecentSearch") {
						__id
						edges {
							node @required(action: THROW) {
								... on JiraCustomFilter {
									filterId
									...Nav4FilterMenuItem
								}
							}
						}
					}
					systemFilters(cloudId: $cloudId) @required(action: THROW) {
						edges {
							node @required(action: THROW) {
								... on JiraSystemFilter {
									filterId
									...Nav4FilterMenuItem
								}
							}
						}
					}
				}
			}
		`,
		queryRef,
	);

	const {
		jira: { favouriteFilters, recentFilters, systemFilters },
	} = fragmentData;

	useRefetchWhenIsSelected({ cloudId, isSelectedPath, QUERY, refetch, menuId: MENU_ID_FILTERS });

	const refetchOnChange = useCallback(
		({ onComplete }: { onComplete: () => void }) => {
			refetch({ cloudId }, { fetchPolicy: 'store-and-network', onComplete });
		},
		[cloudId, refetch],
	);

	useSubscribeAndUpdateFavorites({
		entityTypename: entityTypenames,
		favouriteConnectionId: favouriteFilters.__id,
		recentConnectionId: recentFilters.__id,
		refetch: refetchOnChange,
	});

	const { subscribe, unsubscribe } = useDeleteChangeActions();

	// allows the sidebar to reflect filter changes when a filter is deleted from the filters directory page
	useEffect(() => {
		subscribe(refetchOnChange);
		return () => {
			unsubscribe(refetchOnChange);
		};
	}, [refetchOnChange, subscribe, unsubscribe]);

	const { formatMessage } = useIntl();
	const starred = formatMessage(messages.starred);
	const recent = formatMessage(messages.recent);
	const sendFilterAnalytics = useFilterActionsAnalytics();

	const favorites = useMemo(() => {
		const ids = favouriteFilters.edges?.map((edge) => edge?.node.filterId).filter(Boolean) ?? [];

		const list =
			favouriteFilters.edges?.filter(Boolean).map((edge) => {
				const isCustom = edge.node.__typename === 'JiraCustomFilter';
				const sendFavouriteFilterAnalytics = () =>
					sendFilterAnalytics(
						NAVIGATION_ITEM,
						getRecentAndFavouriteFilterAttributes({
							isCustom,
							filterId: edge.node.filterId,
							section: SECTION_ITEM_STARRED,
						}),
					);
				return (
					<Nav4FilterMenuItem
						key={edge.node.filterId}
						queryRef={edge.node}
						onRefetch={refetchOnChange}
						onClick={sendFavouriteFilterAnalytics}
					/>
				);
			}) ?? [];

		return {
			idSet: new Set(ids),
			list: list.slice(0, MAX_FAVORITE_FILTERS),
			hasItems: Boolean(list.length),
			hasMore: favouriteFilters.pageInfo?.hasNextPage || list.length > MAX_FAVORITE_FILTERS,
		};
	}, [favouriteFilters, refetchOnChange, sendFilterAnalytics]);

	const recentItems = useMemo(() => {
		const nodes =
			recentFilters.edges
				?.filter(Boolean)
				.filter((edge) => edge.node.filterId && !favorites.idSet.has(edge.node.filterId))
				.map((edge) => edge.node)
				.slice(0, MAX_RECENT_FILTERS) ?? [];

		const stableOrderedNodes = getStableItems({ nodes, idKey: 'filterId' });

		const list = stableOrderedNodes.map((node) => {
			const sendRecentFilterAnalytics = () =>
				sendFilterAnalytics(
					NAVIGATION_ITEM,
					getRecentAndFavouriteFilterAttributes({
						isCustom: true,
						filterId: node.filterId,
						section: SECTION_ITEM_RECENT,
					}),
				);
			return (
				<Nav4FilterMenuItem
					key={node.filterId}
					queryRef={node}
					onRefetch={refetchOnChange}
					onClick={sendRecentFilterAnalytics}
				/>
			);
		});

		return { list, hasItems: Boolean(list.length) };
	}, [recentFilters.edges, getStableItems, favorites.idSet, refetchOnChange, sendFilterAnalytics]);

	const isAnonymous = useIsAnonymous();

	const defaultItems = useMemo(() => {
		const filterIds: string[] = [];
		const list =
			systemFilters.edges
				?.filter((edge) => edge?.node?.filterId && !favorites.idSet.has(edge.node.filterId))
				?.filter(
					(edge) =>
						edge?.node?.filterId &&
						(!isAnonymous ||
							!defaultFiltersRequiringLogin.find((filter) => filter.id === edge.node.filterId)),
				)
				.map((edge) => {
					edge?.node && filterIds.push(edge?.node.filterId);
					return edge;
				})
				.map((edge) => {
					if (!edge?.node) {
						return null;
					}
					const sendDefaultFilterAnalytics = () =>
						sendFilterAnalytics(
							NAVIGATION_ITEM,
							getDefaulFilterAttributes({ filterId: edge.node.filterId }),
						);
					return edge?.node ? (
						<Nav4FilterMenuItem
							key={edge.node.filterId}
							queryRef={edge.node}
							onRefetch={refetchOnChange}
							onClick={sendDefaultFilterAnalytics}
						/>
					) : null;
				}) || [];

		return { list, hasItems: Boolean(list.length), filterIds };
	}, [systemFilters.edges, favorites.idSet, isAnonymous, refetchOnChange, sendFilterAnalytics]);

	const isDefaultFilterSelected = useMemo(
		() => defaultItems.filterIds.some((id) => isSelectedPath(ENTITY_ID.FILTER(id))),
		[defaultItems.filterIds, isSelectedPath],
	);

	const sendViewAllStarredAnalytics = useCallback(() => {
		sendFilterAnalytics(NAVIGATION_ITEM, {
			level: LEVEL_ONE,
			componentType: FIXED_ITEM,
			itemId: 'viewAllStarred',
		});
	}, [sendFilterAnalytics]);

	const sendDefaultFilterDropdownAnalytics = useCallback(() => {
		sendFilterAnalytics(BUTTON, {
			level: LEVEL_ONE,
			componentType: FIXED_ITEM_BUTTON,
			itemId: 'defaultFilters',
		});
	}, [sendFilterAnalytics]);

	return (
		<>
			{favorites.hasItems && (
				<>
					<MenuListItem>
						<MenuSection>
							<MenuSectionHeading>{starred}</MenuSectionHeading>
							<MenuList>{favorites.list}</MenuList>
						</MenuSection>
					</MenuListItem>
					{favouriteFilters.pageInfo?.hasNextPage && (
						<Nav4MenuLinkItem
							href={VIEW_ALL_STARRED_HREF}
							menuId={MENU_ID_FILTERS_VIEW_ALL_STARRED}
							onClick={sendViewAllStarredAnalytics}
						>
							{formatMessage(messages.viewAllStarred)}
						</Nav4MenuLinkItem>
					)}
				</>
			)}

			{recentItems.hasItems && (
				<MenuListItem>
					<MenuSection>
						<MenuSectionHeading>{recent}</MenuSectionHeading>
						<MenuList>{recentItems.list}</MenuList>
					</MenuSection>
				</MenuListItem>
			)}

			{defaultItems.hasItems && (
				<ExpandableMenuItem
					isDefaultExpanded
					isChildSelected={isDefaultFilterSelected}
					onExpansionToggle={(hasExpanded) => {
						if (hasExpanded) {
							sendDefaultFilterDropdownAnalytics();
						}
					}}
				>
					<ExpandableMenuItemTrigger>
						{formatMessage(messages.defaultFilters)}
					</ExpandableMenuItemTrigger>
					<ExpandableMenuItemContent>{defaultItems.list}</ExpandableMenuItemContent>
				</ExpandableMenuItem>
			)}
		</>
	);
}

import type { RouteResource } from 'react-resource-router';
import { conversationsResource, messagesResource } from './resources';

export const conversationRoute = {
	name: 'conversationReview',
	path: '/:conversationId?',
} as const;

export type AvailableRoutes = [typeof conversationRoute];

export const resources: Record<AvailableRoutes[number]['name'], RouteResource<any>[]> = {
	conversationReview: [conversationsResource, messagesResource],
};

import { ROUTE_NAMES_SERVICEDESK_SERVICE_DETAILS_WITHOUT_PROJECT } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';
import {
	SERVICES_ROUTE_SUFFIX,
	SERVICE_DESK_ROUTE_PREFIX_WITHOUT_PROJECT,
	SERVICE_HASHED_ARI,
} from './common/constants.tsx';

export const serviceDeskServiceDetailsWithoutProjectRoute: InvariantRoute = {
	name: ROUTE_NAMES_SERVICEDESK_SERVICE_DETAILS_WITHOUT_PROJECT,
	path: `${SERVICE_DESK_ROUTE_PREFIX_WITHOUT_PROJECT}${SERVICES_ROUTE_SUFFIX}${SERVICE_HASHED_ARI}`,
};

import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import parameters from '@atlassian/jira-relay/src/__generated__/src_jiraBusinessTimelineEmbedQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const timelineEmbedEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-business-timeline-embed-entrypoint" */ './src'),
	),
	getPreloadProps: ({ context, tenantContext: { cloudId } }: EntryPointRouteParams) => {
		const projectKey = context.match.params.projectKey;

		if (projectKey == null) {
			throw new Error('Project key missing');
		}

		return {
			queries: {
				timelineEmbedQuery: {
					options: {
						fetchPolicy: 'store-and-network' as const,
					},
					parameters,
					variables: {
						cloudId,
						projectKey,
					},
				},
			},
		};
	},
});

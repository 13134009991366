import { CONNECT_GENERAL_QUERY_PARAMS } from '@atlassian/jira-common-constants/src/connect-general-query-params.tsx';
import { ROUTE_NAMES_CONNECT_GENERAL } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

// Don't forget to update CONNECT_ROUTES_SET in @atlassian/jira-common-constants/src/spa-routes
export const connectGeneralRoute: InvariantRoute = {
	name: ROUTE_NAMES_CONNECT_GENERAL,
	path: '/plugins/servlet/ac/:addonKey/:moduleKey',
	query: CONNECT_GENERAL_QUERY_PARAMS,
};

/**
 * @generated SignedSource<<d35fb1dfec3ff05815dba111d3d199b4>>
 * @relayHash c078c46b462f9754eff5d478249e19ea
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID cd3e948c8fb13e046de09efe80872dad680fe76195ff44295aeb9c91f367879d

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessApprovalsQuery } from './src_jiraBusinessApprovalsQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessApprovalsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "cd3e948c8fb13e046de09efe80872dad680fe76195ff44295aeb9c91f367879d",
    "metadata": {},
    "name": "src_jiraBusinessApprovalsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { getDefaultOptions } from '@atlassian/jira-fetch/src/utils/fetch-default-options-with-strict-mode.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';

export const getUserPropertiesUrl = (accountId: AccountId, propertyKey: string): string =>
	`/rest/api/latest/user/properties/${propertyKey}?accountId=${encodeURIComponent(accountId)}`;

export const setUserProperties = async (
	accountId: AccountId,
	propertyKey: string,
	data: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
	const url = getUserPropertiesUrl(accountId, propertyKey);
	return fetch(url, {
		...getDefaultOptions(url),
		method: 'PUT',
		body: data,
	});
};

export const getUserProperty = <T,>(accountId: AccountId, propertyKey: string): Promise<T> => {
	const url = getUserPropertiesUrl(accountId, propertyKey);
	return fetch(url, getDefaultOptions(url))
		.then((res) => {
			if (res.status === 200) {
				return res.json();
			}

			return res.text().then((errResp) => {
				throw new FetchError(res.status, errResp);
			});
		})
		.then((data) => data.value);
};

import {
	SET_APP_PROPS,
	ASSOCIATED_ITEMS_RETRIEVED,
	GET_ASSOCIATED_ITEMS_FAILED,
	GET_ASSOCIATED_ITEMS,
	DELETE_FILTER,
	FILTER_DELETED,
	DELETE_FILTER_FAILED,
	type Action,
} from './actions.tsx';
import type { State } from './types.tsx';

export const defaultState: State = {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	appProps: undefined as any, // initiatized immediately
	boards: [],
	users: [],
	groups: [],
	isDataRetrieved: false,
	isDeleteInProgress: false,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = defaultState, action: Action): State => {
	switch (action.type) {
		case SET_APP_PROPS:
			return {
				...state,
				appProps: action.payload,
			};

		case DELETE_FILTER:
			return {
				...state,
				isDeleteInProgress: true,
			};

		case FILTER_DELETED:
		case DELETE_FILTER_FAILED:
			return {
				...state,
				isDeleteInProgress: false,
			};

		case ASSOCIATED_ITEMS_RETRIEVED: {
			const { boards, users, groups } = action.payload;
			return {
				...state,
				isDataRetrieved: true,
				...(boards ? { boards } : undefined),
				...(users ? { users } : undefined),
				...(groups ? { groups } : undefined),
			};
		}

		case GET_ASSOCIATED_ITEMS_FAILED:
		case GET_ASSOCIATED_ITEMS:
			return state;
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { jsmRepackagingBannerResource } from '@atlassian/jira-router-resources-jsm-repackaging-banner/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskIssueTypesResource } from '@atlassian/jira-router-resources-service-desk-issue-types/src/controllers/index.tsx';
import { servicedeskPortalGroupsResource } from '@atlassian/jira-router-resources-service-desk-portal-groups/src/controllers/index.tsx';
import { servicedeskPracticesResource } from '@atlassian/jira-router-resources-service-desk-practices/src/controllers/index.tsx';
import { featuresResource } from '@atlassian/jira-router-resources-service-project-features/src/services/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { projectSettingsServicedeskTicketTypesConfigRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-types-config-routes/src/projectSettingsServicedeskTicketTypesConfigRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import type { Route } from '@atlassian/react-resource-router/src/common/types';
import ServiceDeskTicketTypesConfig, {
	LazyProjectSettingsServiceDeskTicketTypesConfig,
} from './ui/service-desk-ticket-types-config/index.tsx';

export const projectSettingsServicedeskTicketTypesConfigRouteEntry: Route = createEntry(
	projectSettingsServicedeskTicketTypesConfigRoute,
	() => ({
		group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
		component: ServiceDeskTicketTypesConfig,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getServiceDeskSettingsResources(),
			projectContextResource,
			servicedeskPracticesResource,
			servicedeskIssueTypesResource,
			servicedeskPortalGroupsResource,
			getConsolidationStateResource(),
			jsmRepackagingBannerResource,
			resourceWithCondition2(() => fg('jsm_hr_request_type_groups'), featuresResource),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskTicketTypesConfig],
		meta: {
			reporting: {
				id: APP_NAMES.REQUEST_TYPES_CONFIG,
				packageName: toPackageName(APP_NAMES.REQUEST_TYPES_CONFIG),
				teamName: 'jsd-shield',
			},
		},
		perfMetricKey: 'jsm-request-types-configuration',
		ufoName: 'jsm-request-types-configuration',
	}),
);

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	failedToLoadIssueTypesTitle: {
		id: 'software-board-create.controllers.use-load-options-controller.failed-to-load-issue-types-title',
		defaultMessage: 'We can’t load issue types right now',
		description: 'Error title when issue types list fails to load',
	},
	failedToLoadIssueTypesDescription: {
		id: 'software-board-create.controllers.use-load-options-controller.failed-to-load-issue-types-description',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Error description when issue types list fails to load',
	},
	failedToLoadLabelsTitle: {
		id: 'software-board-create.controllers.use-load-options-controller.failed-to-load-labels-title',
		defaultMessage: 'We can’t load labels right now',
		description: 'Error title when labels list fails to load',
	},
	failedToLoadLabelsDescription: {
		id: 'software-board-create.controllers.use-load-options-controller.failed-to-load-labels-description',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Error description when labels list fails to load',
	},
	failedToLoadOptionsTitle: {
		id: 'software-board-create.controllers.use-load-options-controller.failed-to-load-options-title',
		defaultMessage: 'Something went wrong',
		description: 'Error title when the options list fails to load',
	},
	failedToLoadOptionsDescription: {
		id: 'software-board-create.controllers.use-load-options-controller.failed-to-load-options-description',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Error description when list options list fails to load',
	},
});

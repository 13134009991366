/**
 * @generated SignedSource<<58d051aceaf7144198328a9aa6c48dc2>>
 * @relayHash 0db8979af1a98bcc70ccffb86e535010
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a2ee613e496049f542ad423691c1955453d0e307763b1d389f0f5008b024eb83

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type AtlaskitAtlassianNavigationNav4UIQuery$variables = {
  cloudId: string;
};
export type AtlaskitAtlassianNavigationNav4UIQuery$data = {
  readonly jira: {
    readonly navigationUIState: {
      readonly isLeftSidebarCollapsed: boolean;
    } | null | undefined;
  };
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Profile">;
};
export type AtlaskitAtlassianNavigationNav4UIQuery = {
  response: AtlaskitAtlassianNavigationNav4UIQuery$data;
  variables: AtlaskitAtlassianNavigationNav4UIQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "isLeftSidebarCollapsed"
},
v3 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "AtlaskitAtlassianNavigationNav4UIQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "args": (v1/*: any*/),
              "concreteType": "JiraNavigationUIStateUserProperty",
              "kind": "LinkedField",
              "name": "navigationUIState",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": (v2/*: any*/),
                  "action": "THROW",
                  "path": "jira.navigationUIState.isLeftSidebarCollapsed"
                }
              ]
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      },
      {
        "kind": "FragmentSpread",
        "name": "Nav4Profile"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AtlaskitAtlassianNavigationNav4UIQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraNavigationUIStateUserProperty",
            "kind": "LinkedField",
            "name": "navigationUIState",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      },
      {
        "concreteType": "AuthenticationContext",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "__typename"
              },
              {
                "kind": "ScalarField",
                "name": "picture"
              },
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "a2ee613e496049f542ad423691c1955453d0e307763b1d389f0f5008b024eb83",
    "metadata": {},
    "name": "AtlaskitAtlassianNavigationNav4UIQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "53566dec28c1571bc52a10d224ea82eb";

export default node;

export const PACKAGE_NAME = 'jiraNavigationAppsSidebarNav4Recent';
export const TEAM_NAME = 'jira-cosmos';
export const RECENT_QUERY_LIMIT = 15;

export const PROJECT = 'PROJECT';
export const PLAN = 'PLAN';
export const FILTER = 'FILTER';
export const DASHBOARD = 'DASHBOARD';
export const BOARD = 'BOARD';
export const QUEUE = 'QUEUE';
export const ISSUE = 'ISSUE';

export type RecentItemType =
	| typeof PROJECT
	| typeof PLAN
	| typeof FILTER
	| typeof DASHBOARD
	| typeof BOARD
	| typeof QUEUE
	| typeof ISSUE;

export const SEARCH_RECENT_ITEMS = 'searchRecentItems';
export const VIEW_ALL_RECENT_ITEMS = 'viewAllRecentItems';

export type FixItemTypeId = typeof SEARCH_RECENT_ITEMS | typeof VIEW_ALL_RECENT_ITEMS;

export const TODAY = 'today';
export const YESTERDAY = 'yesterday';
export const THIS_WEEK = 'thisWeek';
export const THIS_MONTH = 'thisMonth';
export const MORE_THAN_A_MONTH = 'moreThanAMonth';

export type TimeRangeType =
	| typeof TODAY
	| typeof YESTERDAY
	| typeof THIS_WEEK
	| typeof THIS_MONTH
	| typeof MORE_THAN_A_MONTH;

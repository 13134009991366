import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issueTypeFieldLabel: {
		id: 'software-board-create.modal.form.source-value-picker.issue-type-field-label',
		defaultMessage: 'Issue type',
		description: 'Label for the issue types field',
	},
	teamFieldLabel: {
		id: 'software-board-create.modal.form.source-value-picker.team-field-label',
		defaultMessage: 'Team',
		description: 'Label for the team field',
	},
	labelsFieldLabel: {
		id: 'software-board-create.modal.form.source-value-picker.labels-field-label',
		defaultMessage: 'Label',
		description: 'Label for the labels field',
	},
	savedFilterLabel: {
		id: 'software-board-create.modal.form.source-value-picker.saved-filter-label',
		defaultMessage: 'Select a saved filter',
		description: 'Label for the saved filter picker',
	},
	sourceValueEmptyError: {
		id: 'software-board-create.modal.form.source-value-picker.source-value-empty-error',
		defaultMessage: 'Please select a value',
		description: 'Error message for when the source value is empty',
	},
});

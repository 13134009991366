/**
 * @generated SignedSource<<f3ebb1500de8062c306351fd3ba143ed>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4ForgeMenuItem$data = {
  readonly appId: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4AppMenuItem">;
  readonly " $fragmentType": "Nav4ForgeMenuItem";
};
export type Nav4ForgeMenuItem$key = {
  readonly " $data"?: Nav4ForgeMenuItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4ForgeMenuItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "Nav4ForgeMenuItem",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "appId"
    },
    {
      "kind": "FragmentSpread",
      "name": "Nav4AppMenuItem"
    }
  ],
  "type": "JiraAppNavigationItem"
};

(node as any).hash = "44fc7efec94eb8a16f192074436bd021";

export default node;

/**
 * @generated SignedSource<<ba25e929f5dfdf69f3b0c12db70188e4>>
 * @relayHash 1641e746417d5d8e84d535d433374a69
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a0e46b2cc269a997c08a8683eac01ea1c0551686a559e2075eee8b83c2b5f5bf

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessSharedCalendarQuery } from './src_jiraBusinessSharedCalendarQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessSharedCalendarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "a0e46b2cc269a997c08a8683eac01ea1c0551686a559e2075eee8b83c2b5f5bf",
    "metadata": {},
    "name": "src_jiraBusinessSharedCalendarQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';

export const ThemePickerSkeleton = () => (
	<Box xcss={skeletonStyles}>
		<Spinner size="medium" />
	</Box>
);

const skeletonStyles = xcss({
	width: '346px',
	height: '240px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
});

/**
 * @generated SignedSource<<84380005608f8ff1fb9b2828c558d83c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4Dashboards$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DashboardsForLanding">;
  readonly " $fragmentType": "Nav4Dashboards";
};
export type Nav4Dashboards$key = {
  readonly " $data"?: Nav4Dashboards$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Dashboards">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isDashboardsExpanded"
    }
  ],
  "kind": "Fragment",
  "name": "Nav4Dashboards",
  "selections": [
    {
      "condition": "isDashboardsExpanded",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "DashboardsForLanding"
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "2e119493f66f19ce0159f3d471966250";

export default node;

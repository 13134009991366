import React, { useCallback } from 'react';
import AppsIcon from '@atlaskit/icon/core/apps';
import { FIXED_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { LEVEL_ONE } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import {
	MENU_ID_APP_MARKETPLACE_DISCOVER,
	MENU_ID_APPS,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import { useFormattedMessageAndIcon } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-formatted-message-and-icon/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

const pathToNavigate = '/jira/marketplace/discover';

export function ExploreMoreApps() {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formattedMessage, icon } = useFormattedMessageAndIcon(messages.exploreMoreApps, AppsIcon);

	const onClick = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'clicked',
			actionSubject: 'navigationItem',
			actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_APPS],
			attributes: {
				level: LEVEL_ONE,
				componentType: FIXED_ITEM,
				itemId: 'exploreMoreApps',
			},
		});
	}, [createAnalyticsEvent]);

	return (
		<Nav4MenuLinkItem
			elemBefore={icon}
			href={pathToNavigate}
			menuId={MENU_ID_APP_MARKETPLACE_DISCOVER}
			onClick={onClick}
		>
			{formattedMessage}
		</Nav4MenuLinkItem>
	);
}

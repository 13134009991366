/**
 * @generated SignedSource<<a5b7624e85446f21721a2fddcb914f1b>>
 * @relayHash b5e0b37aec54800457a5fd3db7082421
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ea97cb51235400c363559b2d6f24507688902e7f5efb636e827ccc6c74dd445d

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { boardFilterRefinementQuery } from './boardFilterRefinementQuery.graphql';

const node: PreloadableConcreteRequest<boardFilterRefinementQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "ea97cb51235400c363559b2d6f24507688902e7f5efb636e827ccc6c74dd445d",
    "metadata": {},
    "name": "boardFilterRefinementQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import React, { useCallback, type FC, useMemo } from 'react';
import type { ContentProps } from '@atlaskit/popup';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { OnCloseProps, CustomTriggerComponentType } from '../../types.tsx';
import { projectActionMenuEntryPoint } from '../entrypoint.tsx';
import { MenuContainer } from '../menu-container/index.tsx';
import { Skeleton } from '../skeleton/index.tsx';

export type EntryPointContentProps = OnCloseProps & {
	projectKey: string;
	CustomTrigger?: CustomTriggerComponentType;
	onClick?: () => void;
	isCorePremiumUserSeat?: boolean;
	additionalMenuItems?: React.ReactNode;
};
export const EntryPointContent: FC<EntryPointContentProps> = ({
	CustomTrigger,
	onClick,
	additionalMenuItems,
	isCorePremiumUserSeat = false,
	projectKey,
}: EntryPointContentProps) => {
	const cloudId = useCloudId();

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		projectActionMenuEntryPoint,
		useMemo(
			() => ({
				cloudId,
				projectKey,
			}),
			[cloudId, projectKey],
		),
	);
	const buttonTriggerRef = useEntryPointButtonTrigger(entryPointActions);

	const content = useCallback(
		(contentProps: ContentProps) => (
			<JiraEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				id="project-actions-menu-popup-content"
				packageName="jira-navigation-apps-project-action-menu"
				runtimeProps={{ isCorePremiumUserSeat, additionalMenuItems, contentProps }}
				fallback={<Skeleton />}
			/>
		),
		[entryPointReferenceSubject, additionalMenuItems, isCorePremiumUserSeat],
	);

	return (
		<MenuContainer
			content={content}
			CustomTrigger={CustomTrigger}
			onClick={onClick}
			buttonTriggerRef={buttonTriggerRef}
		/>
	);
};

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { forgeCustomFieldTypeResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { fieldContextConfigPageResource } from '@atlassian/jira-router-resources-forge-field-context-config/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { forgeFieldContextualConfigurationRoute } from '@atlassian/jira-router-routes-forge-routes/src/forgeFieldContextualConfigurationRoute.tsx';
import type { Route } from '@atlassian/react-resource-router/src/common/types';
import ForgeFieldContextConfigPage, {
	LazyForgeFieldContextConfigPage,
} from './ui/field-context-config/index.tsx';

export const forgeFieldContextualConfigurationRouteEntry: Route = createEntry(
	forgeFieldContextualConfigurationRoute,
	() => ({
		component: ForgeFieldContextConfigPage,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},
		resources: [
			...getNavigationResources(),
			forgeCustomFieldTypeResource,
			fieldContextConfigPageResource,
			navigationSidebarGlobalResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyForgeFieldContextConfigPage],
	}),
);

import { fetch } from '@atlassian/eoc-fetch';
import { type PaginationEndpointParams } from '@atlassian/eoc-pagination';

import { type GlobalEscalationsResponse, type ListInfoResponse } from '../../../common/types';

export const getEscalations = ({ lastEvaluatedToken, queryKey }: PaginationEndpointParams) => {
	const [, filtersString] = queryKey;
	const filters = JSON.parse(filtersString);
	const params = new URLSearchParams({ ...filters, lastEvaluatedToken });

	return fetch<GlobalEscalationsResponse>(`/v1/escalations/search?${params.toString()}`);
};

export const getListInfo = () => {
	return fetch<ListInfoResponse>('/v1/escalations/list-info');
};

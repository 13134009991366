import React from 'react';
import { ExpandableMenuItemSubmenu } from '../../common/ui/expandable-menu-item-submenu/main.tsx';
import type { L1ExpandableMenuItemSubmenuProps } from './types.tsx';

export const L1ExpandableMenuItemSubmenu = (props: L1ExpandableMenuItemSubmenuProps) => {
	const { fetch, Footer, isFetching, MenuItems, skeletonCount } = props;
	const expandableMenuItems = <MenuItems />;
	const expandableFooter = Footer && <Footer />;

	return (
		<ExpandableMenuItemSubmenu
			fetch={fetch}
			footer={expandableFooter}
			isFetching={isFetching}
			menuItems={expandableMenuItems}
			skeletonCount={skeletonCount}
		/>
	);
};

import React, { useContext } from 'react';
import {
	NavItemsContext,
	PeekMenuIdContext,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/controllers/context.tsx';
import type { ConfigurableMenuItem$key } from '@atlassian/jira-relay/src/__generated__/ConfigurableMenuItem.graphql';
import { ConfigurableMenuItem } from '../../../../common/ui/configurable-menu-item/ConfigurableMenuItem.tsx';

type PeekMenuItemProps = {
	menuData: ConfigurableMenuItem$key;
};

export const PeekMenuItem = ({ menuData }: PeekMenuItemProps) => {
	const { moreMenuIds } = useContext(NavItemsContext);
	const { peekMenuId } = useContext(PeekMenuIdContext);

	return (
		!!peekMenuId &&
		moreMenuIds.includes(peekMenuId) && (
			<ConfigurableMenuItem isDefaultOpen menuData={menuData} menuId={peekMenuId} />
		)
	);
};

import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import { createJpdExperience } from '../../utils/experience/main.tsx';

const getView = createJpdExperience(
	new UFOExperience('jpd.view.get-view', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const updateView = createJpdExperience(
	new UFOExperience('jpd.view.update-view', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const deleteView = createJpdExperience(
	new UFOExperience('jpd.view.delete-view', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

/**
 * Since field updates can be frequent, makeGenericIssueFieldUpdate is a factory
 * to avoid conccurency problems as JPDExperience objects are singletons.
 */
const makeGenericIssueFieldUpdate = () =>
	createJpdExperience(
		new UFOExperience('jpd.issue-update.field', {
			type: ExperienceTypes.Experience,
			performanceType: ExperiencePerformanceTypes.InlineResult,
			timings: [
				{ key: 'internal_state', endMark: 'backend_request_start' },
				{ key: 'backend_request', startMark: 'backend_request_start' },
			],
		}),
	);

const updateIssueFields = createJpdExperience(
	new UFOExperience('jpd.issue-update.fields', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
		timings: [
			{ key: 'internal_state', endMark: 'backend_request_start' },
			{ key: 'backend_request', startMark: 'backend_request_start' },
		],
	}),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	getView,
	updateView,
	deleteView,
	makeGenericIssueFieldUpdate,
	updateIssueFields,
};

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	successTitle: {
		id: 'software-board-create.controllers.use-board-create-controller.success-title',
		defaultMessage: 'Board successfully created',
		description: 'Displayed as the title when a board is successfully created.',
	},
	errorTitle: {
		id: 'software-board-create.controllers.use-board-create-controller.error-title',
		defaultMessage: 'We couldn’t create the board',
		description: 'Displayed as the title when a board is failed to created.',
	},
	errorDescription: {
		id: 'software-board-create.controllers.use-board-create-controller.error-description',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Displayed as the description when a board is failed to created.',
	},
});

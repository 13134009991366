/**
 * @generated SignedSource<<e7dde88ec535c74c39ff5062aa7532e1>>
 * @relayHash c77745af565a57a962ad70781c9a53e7
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ae4216968660d9d5fa7dd9a725e821d8a0434d55113a2497e16f6ac279f1f54d

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessSummaryEmbedQuery } from './src_jiraBusinessSummaryEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessSummaryEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "ae4216968660d9d5fa7dd9a725e821d8a0434d55113a2497e16f6ac279f1f54d",
    "metadata": {},
    "name": "src_jiraBusinessSummaryEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import { BOARD_SOURCE_FIELDS, BOARD_SOURCE_FIELDS_JQLTERM } from '../../../../common/constants.tsx';
import { GenericPicker } from './generic-picker/index.tsx';
import messages from './messages.tsx';
import { SavedFilterPicker } from './saved-filter-picker/index.tsx';
import { TeamPicker } from './team-picker/index.tsx';
import type { BoardSourceFieldComponentMap } from './types.tsx';

export const boardSourceFieldComponentMap: BoardSourceFieldComponentMap = {
	[BOARD_SOURCE_FIELDS.ISSUE_TYPES]: {
		component: GenericPicker,
		label: messages.issueTypeFieldLabel,
		id: BOARD_SOURCE_FIELDS.ISSUE_TYPES,
		extraProps: {
			jqlTerm: BOARD_SOURCE_FIELDS_JQLTERM[BOARD_SOURCE_FIELDS.ISSUE_TYPES],
		},
	},
	[BOARD_SOURCE_FIELDS.TEAM]: {
		component: TeamPicker,
		label: messages.teamFieldLabel,
		id: BOARD_SOURCE_FIELDS.TEAM,
	},
	[BOARD_SOURCE_FIELDS.LABELS]: {
		component: GenericPicker,
		label: messages.labelsFieldLabel,
		id: BOARD_SOURCE_FIELDS.LABELS,
		extraProps: {
			jqlTerm: BOARD_SOURCE_FIELDS_JQLTERM[BOARD_SOURCE_FIELDS.LABELS],
		},
	},
	[BOARD_SOURCE_FIELDS.SAVED_FILTER]: {
		component: SavedFilterPicker,
		label: messages.savedFilterLabel,
		id: BOARD_SOURCE_FIELDS.SAVED_FILTER,
	},
};

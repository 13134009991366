import { fg } from '@atlassian/jira-feature-gating';
// eslint-disable-next-line jira/import-whitelist
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { createResource } from '@atlassian/react-resource-router';
import {
	RESOURCE_TYPE_INCIDENTS_PAGE_INITIAL_CHECKS,
	RESOURCE_TYPE_INCIDENTS_PAGE_CUSTOM_FIELD_ID,
	RESOURCE_TYPE_INCIDENT_EXPERIMENT_TRAITS,
} from '../common/constants.tsx';
import type {
	IncidentsPageInitialChecksResponse,
	IncidentsCustomFieldIdResponse,
	IncidentExperimentTraitsResourceData,
} from './types.tsx';

export const incidentsInitialPageChecksResource =
	createResource<IncidentsPageInitialChecksResponse>({
		type: RESOURCE_TYPE_INCIDENTS_PAGE_INITIAL_CHECKS,
		getKey: ({ query }) => query.projectKey,
		getDataLoader: () =>
			import(
				/* webpackChunkName: "async-resource-incidents-initial-page-check", jiraSpaEntry: "async-resource-incidents-initial-page-check" */ './get-data'
			),
	});

export const incidentsCustomFieldIdResource = createResource<IncidentsCustomFieldIdResponse>({
	type: RESOURCE_TYPE_INCIDENTS_PAGE_CUSTOM_FIELD_ID,
	getKey: ({ query }) => query.projectKey,
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-incidents-custom-field-id-check", jiraSpaEntry: "async-resource-incidents-custom-field-id-check" */ './get-custom-field-data'
		),
});

export const incidentExperimentTraitResource = createResource<IncidentExperimentTraitsResourceData>(
	{
		type: RESOURCE_TYPE_INCIDENT_EXPERIMENT_TRAITS,
		getKey: () => 'incident_experiment_traits',
		getDataLoader: () =>
			import(
				/* webpackChunkName: "async-resource-incident-experiment-traits", jiraSpaEntry: "async-resource-incident-experiment-traits" */ './get-incident-experiment-traits'
			),
		maxAge: Number.MAX_SAFE_INTEGER,
	},
);

export const incidentExperimentTraitResourceOnNav3Only = resourceWithCondition2(
	() =>
		!(
			getWillShowNav4() &&
			(fg('perf-push-big-components-remove-incidents-resource') ||
				fg('perf-push-big-components-nav-remove-resources'))
		),
	incidentExperimentTraitResource,
);

export const incidentExperimentTraitResourceOnNav3OnlyNonCriticalRoutes = resourceWithCondition2(
	() => !(getWillShowNav4() && fg('blu-5573-remove-incident-resouce')),
	incidentExperimentTraitResource,
);

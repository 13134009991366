/**
 * @generated SignedSource<<d103d7389206befab32513e4690206a4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4Overviews$data = {
  readonly jira: {
    readonly jwmOverviews: {
      readonly totalCount: number | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4OverviewsForLanding">;
  readonly " $fragmentType": "Nav4Overviews";
};
export type Nav4Overviews$key = {
  readonly " $data"?: Nav4Overviews$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Overviews">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "isOverviewsExpanded"
    }
  ],
  "kind": "Fragment",
  "name": "Nav4Overviews",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "cloudId",
              "variableName": "cloudId"
            }
          ],
          "concreteType": "JiraWorkManagementGiraOverviewConnection",
          "kind": "LinkedField",
          "name": "jwmOverviews",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "totalCount"
            }
          ]
        }
      ]
    },
    {
      "condition": "isOverviewsExpanded",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "Nav4OverviewsForLanding"
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "49456eee5b5999aee543e784e4db7323";

export default node;

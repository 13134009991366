import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	heading: {
		id: 'portfolio-3-onboarding.onboarding.spotlight-list-mode.heading',
		defaultMessage: 'More room for planning',
		description: 'The title for the spotlight',
	},
	description: {
		id: 'portfolio-3-onboarding.onboarding.spotlight-list-mode.description',
		defaultMessage:
			'Select <b>Timeline</b> to view the timeline bars and capacity information, or choose <b>List</b> to hide them and add as many fields as you want.',
		description: 'The content for the spotlight',
	},
});

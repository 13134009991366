import camelCase from 'lodash/camelCase';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import {
	ROUTE_NAMES_SOFTWARE_REPORTS_CYCLE_TIME,
	ROUTE_GROUPS_SOFTWARE,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticSoftwareProjectHorizontalNavResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/software-horizontal-navigation/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { devOpsProviderResource } from '@atlassian/jira-router-resources-devops-provider/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { softwareReportsCycleTimeRoute } from '@atlassian/jira-router-routes-next-gen-routes/src/softwareReportsCycleTimeRoute.tsx';
import { isDevopsFeatureDisabledInFedRamp } from '@atlassian/jira-software-devops-fedramp-utils/src/index.tsx';
import { softwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { getTMPReportsCommonResource } from './common/ui/resources/reports/index.tsx';
import {
	CycleTimeReportNextGen,
	LazyCycleTimeReport,
} from './ui/software/reports/cycle-time-report/index.tsx';

export const softwareReportsCycleTimeRouteEntry: Route = createEntry(
	softwareReportsCycleTimeRoute,
	() => ({
		group: ROUTE_GROUPS_SOFTWARE,
		component: componentWithCondition(
			isDevopsFeatureDisabledInFedRamp,
			ErrorPagesNotFound,
			CycleTimeReportNextGen,
		),
		layout: softwareProjectLayoutNoOnboarding,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
		},
		resources: [
			...getNavigationResources(),
			// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
			incidentExperimentTraitResource,
			projectContextResource,
			forgeProjectModuleResource,
			staticSidebarShortcutsResource,
			staticProjectSidebarNavigationResource,
			staticProjectSidebarConnectAddonsNavigationResource,
			staticSoftwareProjectHorizontalNavResource,
			// end of resources expanded from getNavigationSidebarProjectResource
			...getTMPReportsCommonResource(),
			devOpsProviderResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyCycleTimeReport],
		meta: {
			reporting: {
				id: ROUTE_NAMES_SOFTWARE_REPORTS_CYCLE_TIME,
				packageName: camelCase(ROUTE_NAMES_SOFTWARE_REPORTS_CYCLE_TIME),
				teamName: 'fusion-solaris',
			},
		},
	}),
);

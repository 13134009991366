import { resources as customRoleResources } from '@atlassian/eoc-custom-roles/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_EOC } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { eocCustomRolesRoute } from '@atlassian/jira-router-routes-eoc-custom-roles-routes/src/eocCustomRolesRoute.tsx';
import CustomRoles, { LazyCustomRoles } from './ui/spa/eoc/custom-roles/index.tsx';

export const eocCustomRolesRouteEntry = createEntry(eocCustomRolesRoute, () => ({
	group: ROUTE_GROUPS_EOC,
	component: CustomRoles,
	layout: globalSettingsLayout,

	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		onlyShowHorizontalOnNav4: true,
	},

	resources: [
		...getNavigationResources(),
		...customRoleResources,
		getConsolidationStateResource(),
		navigationSidebarGlobalResource,
	],

	forPaint: [LazyHorizontalOperationsConfigurationNav, LazyAtlassianNavigation, LazyCustomRoles],
}));

/**
 * @generated SignedSource<<33233bbde56426b21cb0cf7548a863c8>>
 * @relayHash 8658a1d9e0b503030f122af7546f7fa3
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b2740e61056af93841794a91099e9b96903f93d7fe50bb2475c769a63f9f5288

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentBrowseStandardFlowsQuery } from './srcVirtualAgentBrowseStandardFlowsQuery.graphql';

import checkForAiEnabledFF_provider from '@atlassian/jira-relay-provider/src/jsm-va-is-require-ai-enabled.relayprovider';

const node: PreloadableConcreteRequest<srcVirtualAgentBrowseStandardFlowsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "b2740e61056af93841794a91099e9b96903f93d7fe50bb2475c769a63f9f5288",
    "metadata": {},
    "name": "srcVirtualAgentBrowseStandardFlowsQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider": checkForAiEnabledFF_provider
    }
  }
};

export default node;

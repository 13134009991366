import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { insightObjectResource } from '@atlassian/jira-router-resources-servicedesk-cmdb-object/src/services/index.tsx';
import { servicedeskCmdbInsightObject4Route } from '@atlassian/jira-router-routes-servicedesk-cmdb-routes/src/servicedeskCmdbInsightObject4Route.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { sharedRouteProps } from './common/constants.tsx';
import { getCmdbResources } from './common/getCmdbResources.tsx';
import { InsightCmdbObjectViewV2, LazyServicedeskCmdbObjectV2 } from './ui/object/index.tsx';

export const servicedeskCmdbInsightObject4RouteEntry: Route = createEntry(
	servicedeskCmdbInsightObject4Route,
	() => ({
		...sharedRouteProps,
		component: InsightCmdbObjectViewV2,
		resources: [...getCmdbResources(), insightObjectResource],
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbObjectV2],
		perfMetricKey: 'service-management.insight-cmdb.object-view-page-load',
		ufoName: 'service-management.insight-cmdb.object-view-page-load',
	}),
);

/**
 * @generated SignedSource<<946c75fdcc5dbcc3456cfbbdd2ecca9e>>
 * @relayHash 981579b48e063d00b42c80653217556b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 776cc4058b84f9310cb7fb44a6d1d1b48d19c6215f4c1e298d0bf71cc8b16c23

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessTimelineEmbedQuery } from './src_jiraBusinessTimelineEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessTimelineEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "776cc4058b84f9310cb7fb44a6d1d1b48d19c6215f4c1e298d0bf71cc8b16c23",
    "metadata": {},
    "name": "src_jiraBusinessTimelineEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

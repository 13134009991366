import React from 'react';
import Badge from '@atlaskit/badge';
import Button from '@atlaskit/button/new';
import type { TriggerProps } from '@atlaskit/popup';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import SortIcon from './trigger-icon/index.tsx';

type Props = {
	buttonId: string;
	isSelected: boolean;
	onClick?: () => void;
	numberOfFields?: number;
	triggerProps?: TriggerProps;
};
/**
 * Renders the button that triggers open/close of the Order By Picker. Also rendered in the basic mode
 * loading screen when the Order By Picker is enabled.
 */
const OrderByButton = ({ onClick, numberOfFields, triggerProps, buttonId, isSelected }: Props) => {
	const { formatMessage } = useIntl();

	const label = numberOfFields
		? formatMessage(messages.labelNameWithBadge, { fieldCount: numberOfFields })
		: formatMessage(messages.name);

	return (
		<Button
			{...triggerProps}
			id={buttonId}
			isSelected={Boolean(numberOfFields) || isSelected}
			onClick={onClick}
			iconAfter={(...iconProps) => (
				<Box xcss={iconContainerStyles}>
					<SortIcon {...iconProps} size="small" label="" />
				</Box>
			)}
			aria-label={label}
			testId="jql-builder-basic.common.ui.order-by-trigger-button.button"
		>
			<Flex gap="space.050">
				<Box>{formatMessage(messages.name)}</Box>
				{Boolean(numberOfFields) && (
					<Box>
						<Badge appearance="primary">{numberOfFields}</Badge>
					</Box>
				)}
			</Flex>
		</Button>
	);
};

export default OrderByButton;

const iconContainerStyles = xcss({ paddingRight: 'space.050', paddingTop: 'space.025' });

import React, { type UIEvent } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import ButtonGroup from '@atlaskit/button/button-group';
import { ModalFooter } from '@atlaskit/modal-dialog';
import { Box, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useDeleteChangeActions } from '../../controllers/delete-change-actions/index.tsx';
import messages from './messages.tsx';

type FooterProps = {
	canDelete: boolean;
	deleteInProgress: boolean;
	onCancel: (arg1: undefined) => void;
	onDelete: (onComplete: (() => void) | undefined, arg1: UIEvent, arg2: UIAnalyticsEvent) => void;
	intl: IntlShape;
};

const Footer = ({
	intl: { formatMessage },
	canDelete,
	deleteInProgress,
	onCancel,
	onDelete,
}: FooterProps) => {
	const { publish } = useDeleteChangeActions();

	const onDeleteWithEvent = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		// additional args aside from event & analyticsEvent that you wish to pass down needs to be passed as the first argument
		onDelete(publish, event, analyticsEvent);
	};

	const onDeleteOld = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		onDelete(undefined, event, analyticsEvent);
	};

	return (
		<ModalFooter>
			<Container>
				{deleteInProgress && (
					<Box xcss={spinnerContainerStyles}>
						<Spinner size="small" />
					</Box>
				)}
				<ButtonGroup>
					<Button
						appearance="danger"
						type="submit"
						isDisabled={!canDelete}
						onClick={getWillShowNav4() ? onDeleteWithEvent : onDeleteOld}
					>
						{formatMessage(messages.delete)}
					</Button>
					<Button
						appearance="subtle-link"
						isDisabled={deleteInProgress}
						// @ts-expect-error - TS2322 - Type '(arg1: undefined) => void' is not assignable to type '() => void'
						onClick={onCancel}
					>
						{formatMessage(messages.cancelButtonCaption)}
					</Button>
				</ButtonGroup>
			</Container>
		</ModalFooter>
	);
};

Footer.defaultProps = {
	onDelete: noop,
	onCancel: noop,
	deleteInProgress: false,
	canDelete: true,
};

export default injectIntl(Footer);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
	alignItems: 'center',
	flexGrow: 1,
});

const spinnerContainerStyles = xcss({
	marginRight: 'space.200',
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	moreActions: {
		id: 'navigation-apps-sidebar-nav4-common.more-nav-menu-button.trigger.more-actions',
		defaultMessage: 'More actions',
		description:
			'Used as the alt text for an icon button that when used shows a popup menu of more options.',
	},
	moreActionsFor: {
		id: 'navigation-apps-sidebar-nav4-common.more-nav-menu-button.trigger.more-actions-for',
		defaultMessage: 'More actions for {name}',
		description:
			'Used as the alt text for an icon button that when used shows a popup menu of more options. The {name} attribute is the name of the entity the popup menu displays more options for. For example, this could be a project name like "Atlas", and the full message would be "More actions for Atlas".',
	},
});

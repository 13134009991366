import type { State } from './types.tsx';

export const selectChangeboarding = ({ changeboarding }: State) => ({
	visible: changeboarding?.stage === 'READY',
});

export const selectOnboardingTour = ({ onboarding }: State) => ({
	visible: onboarding?.stage === 'READY',
	closed: onboarding?.stage === 'CLOSED',
});

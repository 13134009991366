import React from 'react';
import { styled } from '@compiled/react';
import { SkeletonItem } from '@atlaskit/side-navigation';
import { N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { StarredFiltersContainer } from '../../common/ui/starred-filters-container/index.tsx';
import { StarredFiltersErrorFallback } from '../../common/ui/starred-filters-error-fallback/index.tsx';
import { useDefaultPinFilters } from '../../controllers/pin-filters/index.tsx';
import { DefaultFilters } from './default-filters/index.tsx';
import { FiltersFooter } from './filters-footer/index.tsx';
import type { IssuesProps } from './types.tsx';

export const Issues = ({ projectKey, pinUrl, titleHeaderText }: IssuesProps) => {
	const { data: features } = useDefaultPinFilters();

	if (features === null) return <SkeletonItem />;

	const hasFilters = features.hasAllIssues || features.hasMyOpenIssues;

	return (
		<>
			{/* TODO remove StyleOverrideWrapper once @atlaskit/section design gets updated */}
			<CompiledWrapper renderDividerBefore>
				<DefaultFilters
					pinUrl={pinUrl}
					projectKey={projectKey}
					features={features}
					titleHeaderText={titleHeaderText}
				/>
			</CompiledWrapper>
			<JSErrorBoundary
				packageName="jira-navigation-apps-sidebar-common"
				id="issues.starred-filters-section"
				fallback={() => <StarredFiltersErrorFallback />}
			>
				{/* TODO remove StyleOverrideWrapper once @atlaskit/section design gets updated */}
				<CompiledWrapper renderDividerAfter>
					<StarredFiltersContainer pinUrl={pinUrl} projectKey={projectKey} />
				</CompiledWrapper>
			</JSErrorBoundary>
			{/* TODO remove StyleOverrideWrapper once @atlaskit/section design gets updated */}
			<CompiledWrapper renderDividerBefore>
				<FiltersFooter hasFilters={hasFilters} />
			</CompiledWrapper>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CompiledWrapper = styled.div<{
	renderDividerBefore?: boolean;
	renderDividerAfter?: boolean;
}>(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		"&&& > div > div[data-ds--menu--heading-item='true']": {
			paddingLeft: token('space.075', '6px'),
			color: token('color.text', N800),
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.renderDividerBefore && {
			'> div > div::before': {
				height: '12px',
			},
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.renderDividerAfter && {
			'> div > div::after': {
				height: '12px',
			},
		},
);

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { AsyncHorizontalOverviewNav } from '@atlassian/jira-business-horizontal-nav/src/async.tsx';
import { businessProjectLayout } from '@atlassian/jira-business-project-layout/src/index.tsx';
import { ROUTE_GROUPS_CLASSIC_BUSINESS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getOverviewViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { jiraBusinessOverviewRedirectRoute } from '@atlassian/jira-router-routes-business-redirect-routes/src/jiraBusinessOverviewRedirectRoute.tsx';
import OverviewRedirect from './ui/overview-redirect/index.tsx';

export const jiraBusinessOverviewRedirectRouteEntry = createEntry(
	jiraBusinessOverviewRedirectRoute,
	() => ({
		group: ROUTE_GROUPS_CLASSIC_BUSINESS,
		isRedirect: true,
		component: OverviewRedirect,
		layout: businessProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			// delete when cleaning up getWillShowNav4
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalOverviewNav,
		},

		resources: getOverviewViewResources(),
		forPaint: [LazyAtlassianNavigation],
	}),
);

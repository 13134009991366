/**
 * @generated SignedSource<<e3cce317c9d4d64ec51601d1438d8020>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4FiltersForLanding$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4FiltersContent">;
  readonly " $fragmentType": "Nav4FiltersForLanding";
};
export type Nav4FiltersForLanding$key = {
  readonly " $data"?: Nav4FiltersForLanding$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4FiltersForLanding">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "Nav4FiltersForLanding",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "Nav4FiltersContent"
    }
  ],
  "type": "Query"
};

(node as any).hash = "6171665054b4de86e6a0c40316984aba";

export default node;

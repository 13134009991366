import React from 'react';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { smartRepliesOptInStatusResources } from '@atlassian/jira-issue-smart-replies-preferences/src/index.tsx';
import { EmbedModeProvider } from '@atlassian/jira-issue-view-embed-mode/src/services/provider/index.tsx';
import {
	useProjectKey,
	useProjectType,
	useProjectId,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
// eslint-disable-next-line camelcase
import { DEPRECATED_DO_NOT_USE_projectDetailsResource } from '@atlassian/jira-router-resources-business-project-details/src/index.tsx';
import { issueMediaReadPermissionResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-media-read-permissions/index.tsx';
import { issueMediaUploadPermissionResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-media-upload-permissions/index.tsx';
import { issueMediaUserAuthResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-media-user-auth/index.tsx';
import { queuesNavigationItems } from '@atlassian/jira-router-resources-service-desk-queues/src/services/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import type IssuePageType from '@atlassian/jira-spa-apps-issue/src/index.tsx';
import { markAsBentoBundleReady } from '@atlassian/jira-spa/src/services/mark-bento-bundle-as-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { Redirect, usePathParam } from '@atlassian/react-resource-router';

export const conditionalSmartRepliesOptInStatusResources = resourceWithCondition2(
	() => !fg('smart-replies-system-setting') && fg('smart_replies_m1_jira_labs'),
	smartRepliesOptInStatusResources,
);

export const getUnusedRouteResources = () =>
	fg('issue_view_remove_unused_route_resources')
		? []
		: [
				issueMediaUserAuthResource,
				issueMediaReadPermissionResource,
				issueMediaUploadPermissionResource,
			];

export const getUnusedEmbedRouteResources = () =>
	fg('issue_view_remove_unused_route_resources')
		? // eslint-disable-next-line camelcase
			[DEPRECATED_DO_NOT_USE_projectDetailsResource]
		: [
				...getBusinessProjectResources(),
				queuesNavigationItems,
				issueMediaUserAuthResource,
				issueMediaReadPermissionResource,
				issueMediaUploadPermissionResource,
			];

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyIssuePage = lazyForPaint<typeof IssuePageType>(
	() =>
		markAsBentoBundleReady(
			import(
				/* webpackChunkName: "async-issue-app--full-page", jiraSpaEntry: "spa-issue" */ '@atlassian/jira-spa-apps-issue'
			),
		),
	{ ssr: false },
);

export const IssueComponent = () => {
	const { data } = useProjectContext();
	const [issueKey] = usePathParam('issueKey');
	const pageId = 'spa-issue';

	const [projectKey, issueSuffix] = issueKey !== undefined ? issueKey.split('-') : [];
	let actualIssueKey = issueKey;
	if (data?.projectKey && data.projectKey !== projectKey) {
		actualIssueKey = `${data.projectKey}-${issueSuffix}`;
	}

	// special handling for product discovery (=== Polaris) project issues. During the experimental phase of
	// the polaris idea view (product-specific issue view), we will redirect to a polaris-specific issue view
	// page here instead of bento.
	// this will only happen for issues of this specific project type, and only if the switchover FF is enabled
	if (
		!__SERVER__ &&
		data?.projectType === PRODUCT_DISCOVERY_PROJECT &&
		data?.projectKey !== undefined &&
		actualIssueKey !== undefined
	) {
		return (
			<Redirect
				to={`/jira/polaris/projects/${data.projectKey}/ideas?selectedIssue=${actualIssueKey}`}
			/>
		);
	}

	return <LazyPage Page={LazyIssuePage} pageId={pageId} shouldShowSpinner={false} />;
};

export const IssueEmbedComponent = () => {
	const [issueKey] = usePathParam('issueKey');
	const projectKey = useProjectKey(issueKey || '');
	const projectType = useProjectType(projectKey);
	const projectId = useProjectId(projectKey);

	// Special handling for product discovery (=== Polaris) project issues in embed mode.
	// We have to redirect to Polaris issue embed view instead of loading Jira issue view.
	if (
		projectType === PRODUCT_DISCOVERY_PROJECT &&
		projectKey !== undefined &&
		projectId !== undefined &&
		issueKey !== undefined
	) {
		return (
			<Redirect
				to={`/secure/JiraProductDiscoveryEmbedded.jspa?projectId=${projectId}&projectKey=${projectKey}&issueKey=${issueKey}`}
			/>
		);
	}

	return (
		<EmbedModeProvider>
			<IssueComponent />
		</EmbedModeProvider>
	);
};

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_GLOBAL_SETTINGS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { productsJswStorageManagementResource } from '@atlassian/jira-router-resources-products-jsw-storage-management/src/index.tsx';
import { productsJswConfigurationStorageManagementRoute } from '@atlassian/jira-router-routes-products-jsw-configuration-routes/src/productsJswConfigurationStorageManagementRoute.tsx';
import ProductsJswStorageManagement, {
	LazyProductsJswStorageManagement,
} from './ui/products-jsw-storage-management/index.tsx';

export const productsJswStorageManagementRouteEntry = createEntry(
	productsJswConfigurationStorageManagementRoute,
	() => ({
		group: ROUTE_GROUPS_GLOBAL_SETTINGS,
		ufoName: 'admin.jsw-configuration.storage-management',
		component: ProductsJswStorageManagement,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProductsSettingResource(),
			productsJswStorageManagementResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyProductsJswStorageManagement],
	}),
);

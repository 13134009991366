import React from 'react';
import { OnboardingNudgeErrorBoundary } from '@atlassian/jira-onboarding-quickstart-core/src/common/ui/onboarding-nudge-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { BACKGROUND_COLOUR_NUDGE_ID } from './common/constants.tsx';
import type { WrapperProps } from './common/types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const BackgroundColourNudgeLazy = lazyAfterPaint(
	() =>
		import(/* webpackChunkName: "async-background-colour-onboarding-nudge" */ './index').then(
			(exportedModule) => exportedModule.BackgroundColourNudge,
		),
	{
		ssr: false,
	},
);

export const BackgroundColourNudgeAsync = ({ children }: WrapperProps) => (
	<OnboardingNudgeErrorBoundary
		id={BACKGROUND_COLOUR_NUDGE_ID}
		fallback={children}
		attributes={{
			nudgeId: BACKGROUND_COLOUR_NUDGE_ID,
		}}
	>
		<Placeholder name="background-colour-onboarding-nudge" fallback={children}>
			<BackgroundColourNudgeLazy>{children}</BackgroundColourNudgeLazy>
		</Placeholder>
	</OnboardingNudgeErrorBoundary>
);

BackgroundColourNudgeAsync.displayName = 'BackgroundColourNudgeAsync';

import React, { type ReactElement } from 'react';
import { graphql, useFragment } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import { SECTION_ITEM_PLANS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_PLANS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { Nav4ExpandableMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-expandable-menu-item/index.tsx';
import { getTestId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-test-id/index.tsx';
import { useSectionItemAnalytics } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-section-item-analytics/index.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import { Nav4PlansFooter } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-plans/src/ui/plans/common/footer/Nav4PlansFooter.tsx';
import { PlansList } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-plans/src/ui/plans/common/plans-list/PlansList.tsx';
import { PlansListOld } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-plans/src/ui/plans/common/plans-list/PlansListOld.tsx';
import type { Nav4PlansForLanding$key } from '@atlassian/jira-relay/src/__generated__/Nav4PlansForLanding.graphql';
import { ExpandableMenuItemContent, ExpandableMenuItemTrigger } from '@atlassian/navigation-system';

type Nav4PlansForLandingProps = {
	actionsOnHover: ReactElement;
	isExpanded: boolean;
	queryRef: Nav4PlansForLanding$key;
};

export function Nav4PlansForLanding(props: Nav4PlansForLandingProps) {
	const { actionsOnHover, isExpanded, queryRef } = props;
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(L1_MENU_ID.PLANS);
	const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();

	const data = useFragment(
		graphql`
			fragment Nav4PlansForLanding on Query {
				...PlansList
				...PlansListOld
			}
		`,
		queryRef,
	);

	const menuId = MENU_ID_PLANS;

	return (
		<Nav4ExpandableMenuItem
			isExpanded={isExpanded}
			menuId={menuId}
			onExpansionToggle={(hasExpanded) => {
				if (hasExpanded) {
					sendSectionItemAnalyticsEvent({
						menuId: L1_MENU_ID.PLANS,
						sectionItem: SECTION_ITEM_PLANS,
					});
				}
			}}
		>
			<ExpandableMenuItemTrigger
				actionsOnHover={actionsOnHover}
				elemBefore={icon}
				testId={getTestId(menuId)}
			>
				{formattedMessage}
			</ExpandableMenuItemTrigger>
			<ExpandableMenuItemContent>
				{/* this component uses the fragment from the ancestor query */}
				{fg('plans_-_avoid_refetching_plans_nav_on_query_change') ? (
					<PlansList fragmentRef={data} />
				) : (
					<PlansListOld fragmentRef={data} />
				)}
				<Nav4PlansFooter />
			</ExpandableMenuItemContent>
		</Nav4ExpandableMenuItem>
	);
}

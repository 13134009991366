import type { PreloadFetchPolicy } from 'react-relay';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import parameters from '@atlassian/jira-relay/src/__generated__/src_jiraBusinessAttachmentsQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const attachmentsEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() => import(/* webpackChunkName: "async-graph" */ './src')),
	getPreloadProps: ({ context, tenantContext }: EntryPointRouteParams) => {
		// This entry point should only load in this route
		// once overview is supported by backend, we should have a different entry point for it
		const { projectKey } = context.match.params;
		if (!projectKey) {
			throw new Error('projectKey is required');
		}

		return {
			queries: {
				jwmAttachmentsQuery: {
					parameters,
					variables: {
						cloudId: tenantContext.cloudId,
						projectKey,
						projectKeys: [projectKey],
						filters: {
							fileName: '',
							authorIds: [],
							mimeTypes: [],
							dateRange: {
								before: undefined,
								after: undefined,
							},
						},
						durationInSeconds: 900,
						maxTokenLength: 65534,
						attachmentCount: 100,
					},
					options: {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						fetchPolicy: 'network-only' as PreloadFetchPolicy,
					},
				},
			},
			entryPoints: {},
			extraProps: {},
		};
	},
});

import React from 'react';
import ButtonGroup from '@atlaskit/button/button-group';
import Button from '@atlaskit/button/new';

import type { ModalAttributes } from '@atlaskit/modal-dialog';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export type FormActionsProps = {
	submitting: boolean;
	onClose: ModalAttributes['onClose'];
};

export const FormActions = ({ onClose, submitting }: FormActionsProps) => {
	const { formatMessage } = useIntl();
	return (
		<Box xcss={buttonGroupContainerStyles}>
			<ButtonGroup>
				<Button
					appearance="subtle"
					isDisabled={submitting}
					onClick={onClose}
					testId="software-board-create.ui.modal.form.actions.board-create-cancel-button"
				>
					{formatMessage(messages.modalCancelButton)}
				</Button>
				<Button
					appearance="primary"
					type="submit"
					isLoading={submitting}
					testId="software-board-create.ui.modal.form.actions.board-create-confirm-button"
				>
					{formatMessage(messages.modalConfirmButton)}
				</Button>
			</ButtonGroup>
		</Box>
	);
};

const buttonGroupContainerStyles = xcss({
	paddingBottom: 'space.100',
});

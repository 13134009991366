type SelectionTags = {
	label: string;
	value: string;
};

export const accuracyTags = [
	{
		label: 'Accurate',
		value: 'accurate',
	},
	{
		label: 'Not accurate',
		value: 'not-accurate',
	},
] satisfies SelectionTags[];

export const accuracyTagsGroup = {
	label: 'Accuracy',
	options: accuracyTags,
};

export const languageTags = [
	{
		label: 'Easy to understand',
		value: 'easy-to-understand',
	},
	{
		label: 'Hard to understand',
		value: 'hard-to-understand',
	},
	{
		label: 'Concise',
		value: 'concise',
	},
	{
		label: 'Verbose',
		value: 'verbose',
	},
	{
		label: 'Grammatical errors',
		value: 'grammatical-errors',
	},
	{
		label: 'Formatting errors',
		value: 'formatting-errors',
	},
	{
		label: 'Incorrect tone',
		value: 'incorrect-tone',
	},
] satisfies SelectionTags[];

export const languageTagsGroup = {
	label: 'Language',
	options: languageTags,
};

export const problemTags = [
	{
		label: 'Vague question',
		value: 'vaque-question',
	},
	{
		label: 'Irrelevant answer',
		value: 'irrelevant-answer',
	},
	{
		label: 'Human required',
		value: 'human-required',
	},
	{
		label: 'Content not retrieved',
		value: 'content-not-retrieved',
	},
	{
		label: 'Missing links',
		value: 'missing-links',
	},
	{
		label: 'Incorrect source',
		value: 'incorrect-source',
	},
] satisfies SelectionTags[];

export const problemTagsGroup = {
	label: 'Problems',
	options: problemTags,
};

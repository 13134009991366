import type { EventAttributes } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/types.tsx';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { ACTION_TAKEN_REMOVE, ACTION_TAKEN_ADD } from './constants.tsx';

export const getActionTaken = (
	oldValId: EventAttributes['oldValId'],
	newValId: EventAttributes['newValId'],
): EventAttributes['actionTaken'] => {
	if (oldValId !== newValId) {
		if (newValId === null) return ACTION_TAKEN_REMOVE;
		if (oldValId === null || oldValId === undefined) return ACTION_TAKEN_ADD;
	}
	return undefined;
};

export const getContextAssigneeId = (context: UIAnalyticsEvent['context']) => {
	for (const item of context) {
		if (item?.attributes?.assigneeId) {
			return item.attributes.assigneeId;
		}
	}
};

import React, { memo, useCallback, useEffect } from 'react';
import type { ApolloError } from 'apollo-client';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { ExperienceAttributes } from '@atlassian/jira-experience-tracker/src/common/types.tsx';
import { useExperienceFail as useExperienceFailPlatform } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { ExperienceSuccess as PlatformExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';

export type Attributes = {
	readonly [key: string]: string | number | boolean | null;
};

export const useExperienceFail = ({
	experience,
	experienceId,
	analyticsSource,
	teamName,
}: {
	experience: string;
	experienceId?: string;
	analyticsSource: string;
	teamName: string;
}) => {
	const onExperienceFail = useExperienceFailPlatform({
		experience,
	});

	const onFail = useCallback(
		(
			location: string,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			error: (Error | ApolloError) & { networkError?: any },
			extraAttributes?: Attributes,
		) => {
			const relevantError = error?.networkError ?? error;

			if (
				relevantError instanceof ValidationError &&
				relevantError.message === '' &&
				relevantError.errors.length
			) {
				relevantError.message = relevantError.errors[0].error;
			}

			extraAttributes !== undefined
				? onExperienceFail(location, relevantError, extraAttributes)
				: onExperienceFail(location, relevantError);

			fireErrorAnalytics({
				meta: {
					id: `experience-tracker-${experienceId ?? experience}`,
					packageName: analyticsSource,
					teamName,
				},
				attributes: {
					location,
				},
				error: relevantError,
				sendToPrivacyUnsafeSplunk: true,
			});
		},
		[onExperienceFail, experienceId, experience, analyticsSource, teamName],
	);

	return onFail;
};

type ExperienceFailedProps = {
	experience: {
		experience: string;
		experienceId?: string;
		analyticsSource: string;
		teamName: string;
	};
	error: Error | ApolloError;
	attributes?: ExperienceAttributes;
};

export const ExperienceFailed = memo<ExperienceFailedProps>(
	({ experience, error, attributes }: ExperienceFailedProps) => {
		const onFail = useExperienceFail(experience);

		useEffect(() => {
			onFail(experience.experience, error, attributes);
		}, [attributes, error, experience, onFail]);

		return null;
	},
);

type ExperienceSuccessProps = {
	experience: {
		experience: string;
		experienceId?: string;
		analyticsSource: string;
		teamName: string;
	};
	attributes?: ExperienceAttributes;
};

export const ExperienceSuccess = memo<ExperienceSuccessProps>(
	({ experience, attributes }: ExperienceSuccessProps) => (
		<PlatformExperienceSuccess
			experience={experience.experience}
			attributes={{
				...(experience.experienceId == null
					? undefined
					: { experienceId: experience.experienceId }),
				...attributes,
			}}
		/>
	),
);

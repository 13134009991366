import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	spotlightCloseLabel: {
		id: 'portfolio-3-onboarding.onboarding.spotlight-close-label',
		defaultMessage: 'Close',
		description: 'Label for icon button. Display on the top right corner on the spotlight',
	},
	modalHeadingPlansSpork: {
		id: 'portfolio-3-onboarding.onboarding.modal-heading-plans-spork',
		defaultMessage: 'Welcome to Plans',
		description: 'The onboarding modal title',
	},
	modalContentParagraphPlansSpork: {
		id: 'portfolio-3-onboarding.onboarding.modal-content-paragraph-plans-spork',
		defaultMessage:
			"Track dependencies from multiple teams, monitor team's capacity, and view the status of work in real time so you can see everything happening across Jira.",
		description: 'The onboarding modal first paragraph',
	},
	modalTakeTour: {
		id: 'portfolio-3-onboarding.onboarding.modal-take-tour',
		defaultMessage: 'Take tour',
		description: 'The label for the onboarding modal Take tour button',
	},
	modalSkip: {
		id: 'portfolio-3-onboarding.onboarding.modal-skip',
		defaultMessage: 'Skip',
		description: 'The label for the onboarding modal Skip button',
	},
	spotlightNext: {
		id: 'portfolio-3-onboarding.onboarding.spotlight-next',
		defaultMessage: 'Next',
		description: 'The Next button label for spotlight card.',
	},
	spotlightLearnMore: {
		id: 'portfolio-3-onboarding.onboarding.spotlight-learn-more',
		defaultMessage: 'Learn more',
		description: 'The Learn more button label for spotlight card.',
	},
});

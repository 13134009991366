import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { arjSettingsFinancialYearResource } from '@atlassian/jira-router-resources-advanced-roadmaps-settings/src/services/financial-year/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { arjSettingsFinancialYearPageRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-spa-routes/src/arjSettingsFinancialYearPageRoute.tsx';
import {
	ArjSettingsFinancialYear,
	LazyArjSettingsFinancialYear,
} from './ui/settings/financial-year/index.tsx';

export const arjSettingsFinancialYearPageRouteEntry = createEntry(
	arjSettingsFinancialYearPageRoute,
	() => ({
		group: ROUTE_GROUPS_SOFTWARE,
		component: ArjSettingsFinancialYear,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},
		resources: [
			...getNavigationResources(),
			navigationSidebarGlobalResource,
			arjSettingsFinancialYearResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyArjSettingsFinancialYear],
	}),
);

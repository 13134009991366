import groupBy from 'lodash/groupBy';
// eslint-disable-next-line jira/import-whitelist
import { addSpanToAll } from '@atlaskit/react-ufo/interaction-metrics';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';

import type { CategorizedGroupData, PriorityGroup, QueueId } from '../../types.tsx';

type GroupResponse = PriorityGroup & {
	queueIds: QueueId[];
	category: string;
};

type GroupFetchResponse = {
	results: GroupResponse[];
};

const fetchGroupsByKey = async (projectKey: string, cloudId: string) => {
	return fetchJson(
		`/gateway/api/jsm/queues/cloudid/${cloudId}/api/v1/groups?projectIdOrKey=${projectKey}`,
	);
};

const fetchGroupsById = async (projectId: number, cloudId: string) => {
	return fetchJson(
		`/gateway/api/jsm/queues/cloudid/${cloudId}/api/v1/groups?projectId=${projectId}`,
	);
};

export const getPriorityGroupDataByProjectId = async (
	projectId: number | undefined,
	projectKey: string,
	cloudId: string,
) => {
	const fetchStartTime = performance.now();
	let data: GroupFetchResponse;
	if (projectId) {
		data = await fetchGroupsById(projectId, cloudId);
		addSpanToAll(
			'fetch',
			'fetchPriorityGroupsByProjectId',
			[{ name: 'network' }],
			fetchStartTime,
			performance.now(),
		);
	} else {
		fireErrorAnalytics({
			meta: {
				id: 'fetchPriorityGroupsProjectIdUndefined',
				packageName: 'jiraRouterResourcesServiceDeskQueues',
				teamName: 'jsm-flash',
			},
			sendToPrivacyUnsafeSplunk: true,
		});
		data = await fetchGroupsByKey(projectKey, cloudId);
		addSpanToAll(
			'fetch',
			'fetchPriorityGroups',
			[{ name: 'network' }],
			fetchStartTime,
			performance.now(),
		);
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const processedData = groupBy(
		data.results.map((group) => ({ ...group, queues: group.queueIds || [] })),
		'category',
	) as any; // eslint-disable-line @typescript-eslint/no-explicit-any

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return processedData as CategorizedGroupData;
};

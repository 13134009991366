import React, { memo, useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import { di } from 'react-magnetic-di';
import Button from '@atlaskit/button';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { token } from '@atlaskit/tokens';
import { getOverviewUrl } from '@atlassian/jira-business-entity-common/src/utils/entity-url/index.tsx';
import CreateOverviewModal from '@atlassian/jira-business-entity-overview/src/ui/create-overview-modal/index.tsx';
import { AsyncMigrationMessage as MigrationMessage } from '@atlassian/jira-business-onboarding-plans/src/ui/migration-message/async.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useTriggerOverviewChangeboarding } from '@atlassian/jira-router-resources-business-navigation/src/index.tsx';
import type { NavigationOverview } from '@atlassian/jira-router-resources-business-navigation/src/types.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import { OVERVIEW_MAX_LIMIT } from '../../../common/constants/index.tsx';
import SectionHeader from '../../../common/ui/section-header/index.tsx';
import CreateOverview from './create-overview/index.tsx';
import messages from './messages.tsx';
import OverviewLink from './overview-link/index.tsx';

type Props = {
	overviews: NavigationOverview[];
	hasError: boolean;
};

const Overviews = ({ overviews, hasError }: Props) => {
	di(window);
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const refresh = useCallback(() => typeof window !== 'undefined' && window.location.reload(), []);

	const [{ match, route }] = useRouter();
	const routeOverviewId = match.params?.overviewId;

	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const openCreateModal = useCallback(() => {
		setIsCreateModalOpen(true);
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'createOverview');
	}, [createAnalyticsEvent]);
	const closeCreateModal = useCallback(() => setIsCreateModalOpen(false), []);
	const showOverviewChangeboarding = useTriggerOverviewChangeboarding();

	if (showOverviewChangeboarding) {
		return (
			<>
				<SectionHeader title={formatMessage(messages.overviewsHeader)}>
					<></>
				</SectionHeader>
				<MigrationMessage />
			</>
		);
	}

	let content;

	if (hasError) {
		content = (
			<ErrorWrapper data-testid="navigation-apps-sidebar-core.ui.multi-project-menu.overviews.overviews-error">
				<ErrorMessage>{formatMessage(messages.errorMessage)}</ErrorMessage>

				<Button appearance="link" onClick={refresh}>
					{formatMessage(messages.refreshPage)}
				</Button>
			</ErrorWrapper>
		);
	} else if (overviews.length === 0) {
		content = (
			<EmptyState data-testid="navigation-apps-sidebar-core.ui.multi-project-menu.overviews.empty-state">
				<Button appearance="subtle-link" onClick={openCreateModal}>
					{formatMessage(messages.createOverview)}
				</Button>
			</EmptyState>
		);
	} else {
		content = (
			<List data-testid="navigation-apps-sidebar-core.ui.multi-project-menu.overviews.overviews-list">
				{overviews.map((overview) => (
					<li key={overview.id}>
						{routeOverviewId === overview.id ? (
							<OverviewLink overview={overview} to={getOverviewUrl(overview)} isActive />
						) : (
							<OverviewLink overview={overview} to={getOverviewUrl(overview, route)} />
						)}
					</li>
				))}
			</List>
		);
	}

	const hasReachedLimit = overviews?.length >= OVERVIEW_MAX_LIMIT;

	return (
		<>
			<SectionHeader title={formatMessage(messages.overviewsHeader)}>
				<CreateOverview onClick={openCreateModal} hasReachedLimit={hasReachedLimit} />
			</SectionHeader>

			{content}

			<ModalTransition>
				{isCreateModalOpen && <CreateOverviewModal onClose={closeCreateModal} />}
			</ModalTransition>
		</>
	);
};

export default memo<Props>(Overviews);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyState = styled.div({
	display: 'flex',
	justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const List = styled.ol({
	margin: 0,
	padding: 0,
	listStyle: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorMessage = styled.p({
	margin: `${token('space.100', '8px')} ${token('space.100', '8px')} 0`,
	textAlign: 'center',
});

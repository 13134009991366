import { useMemo } from 'react';
import urlParse from 'url-parse';
import { matchUrlExact } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/url-matchers/index.tsx';
import type { Nav4Apps_GlobalAppsFragment$data } from '@atlassian/jira-relay/src/__generated__/Nav4Apps_GlobalAppsFragment.graphql';
import { useRouter } from '@atlassian/react-resource-router';
import { canHaveSubpages } from '../../common/utils/connect-magic/index.tsx';

export type UseIsGlobalConnectAppRouteParams = {
	data: Omit<Nav4Apps_GlobalAppsFragment$data, ' $fragmentType'>;
};

export const useIsGlobalConnectAppRoute = ({ data }: UseIsGlobalConnectAppRouteParams) => {
	const [{ location, query }] = useRouter();
	const locations = useMemo(
		() =>
			(data?.globalAppNavigationItems?.edges || [])
				.filter((edge) => edge?.node?.appType === 'CONNECT')
				.flatMap((edge) =>
					(
						(edge?.node && canHaveSubpages(edge?.node) && edge?.node?.sections) || [
							{ links: [{ url: edge?.node?.url }] },
						]
					).flatMap((section) =>
						(section?.links || []).map((link) => urlParse(link?.url || '/', true)),
					),
				),
		[data],
	);

	return useMemo(() => {
		const matcher = matchUrlExact();
		const currentLocation = { ...location, query };
		// @ts-expect-error - Property 'search' is missing in type 'URLParse' but required in type 'MatcherLocation'.
		return locations.some((loc) => matcher(currentLocation, loc));
	}, [locations, location, query]);
};

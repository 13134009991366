import { resources as roleBasedNotificationsResources } from '@atlassian/eoc-role-based-notifications/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_EOC } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { roleBasedNotificationsRoute } from '@atlassian/jira-router-routes-eoc-role-based-notifications-routes/src/roleBasedNotificationsRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import {
	LazyRoleBasedNotifications,
	RoleBasedNotificationsComponent,
} from './ui/RoleBasedNotificationsComponent.tsx';

export const roleBasedNotificationsRouteEntry: Route = createEntry(
	roleBasedNotificationsRoute,
	() => ({
		group: ROUTE_GROUPS_EOC,
		component: RoleBasedNotificationsComponent,
		layout: globalSettingsLayout,
		resources: [
			...getNavigationResources(),
			roleBasedNotificationsResources[0],
			getConsolidationStateResource(),
			navigationSidebarGlobalResource,
		],
		navigation: {
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
			horizontal: AsyncHorizontalOperationsConfigurationNav,
			onlyShowHorizontalOnNav4: true,
		},
		forPaint: [
			LazyHorizontalOperationsConfigurationNav,
			LazyAtlassianNavigation,
			LazyRoleBasedNotifications,
		],
	}),
);

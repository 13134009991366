import parameters from '@atlassian/jira-relay/src/__generated__/Nav4FiltersContentViewQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = { cloudId: string };

export const asyncFiltersContentViewQuery = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-nav4-filters-content-view-query" */ './Nav4FiltersContentViewQuery'
		).then(({ Nav4FiltersContentViewQuery }) => Nav4FiltersContentViewQuery),
	),
	getPreloadProps: ({ cloudId }: EntryPointParams) => ({
		queries: {
			filters: {
				parameters,
				variables: {
					cloudId,
				},
			},
		},
	}),
});

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskServiceModalNewRoute } from '@atlassian/jira-router-routes-servicedesk-services-routes/src/servicedeskServiceModalNewRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import ServiceCreateModalWithoutProjectContext, {
	LazyServiceCreateModalWithProjectContext,
} from './ui/service-create-modal-without-project-context/index.tsx';

export const servicedeskServiceModalNewRouteEntry: Route = createEntry(
	servicedeskServiceModalNewRoute,
	() => ({
		group: ROUTE_GROUPS_SERVICEDESK,
		component: ServiceCreateModalWithoutProjectContext,
		layout: serviceProjectLayout,
		resources: [...getServiceDeskNavigationResources(), ...getServicedeskPageContextResources()],
		forPaint: [LazyAtlassianNavigation, LazyServiceCreateModalWithProjectContext],
	}),
);

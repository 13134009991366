/**
 * @generated SignedSource<<fdb08cf0aafa260f088ff7d6988a26ff>>
 * @relayHash c41a455d3fe07bee46fd358ba122e461
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ad1b0d9d182a46ab37286f92d3ce29ce06821b61a8e30066a34f05c56ce72317

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentViewStandardFlowQuery } from './srcVirtualAgentViewStandardFlowQuery.graphql';

import checkForAiEnabledFF_provider from '@atlassian/jira-relay-provider/src/jsm-va-is-require-ai-enabled.relayprovider';

const node: PreloadableConcreteRequest<srcVirtualAgentViewStandardFlowQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "ad1b0d9d182a46ab37286f92d3ce29ce06821b61a8e30066a34f05c56ce72317",
    "metadata": {},
    "name": "srcVirtualAgentViewStandardFlowQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider": checkForAiEnabledFF_provider
    }
  }
};

export default node;

import React, { type ReactElement } from 'react';
import { SECTION_ITEM_ROADMAPS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_ROADMAPS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { Nav4ExpandableMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-expandable-menu-item/index.tsx';
import { getTestId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-test-id/index.tsx';
import { useSectionItemAnalytics } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-section-item-analytics/index.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import { ExpandableMenuItemContent, ExpandableMenuItemTrigger } from '@atlassian/navigation-system';
import { Footer } from '../../../common/ui/footer/index.tsx';

export type RoadmapsDefaultProps = {
	actionsOnHover: ReactElement;
	isExpanded: boolean;
};

export function RoadmapsDefault(props: RoadmapsDefaultProps) {
	const { actionsOnHover, isExpanded } = props;
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(L1_MENU_ID.ROADMAPS);
	const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();

	const menuId = MENU_ID_ROADMAPS;

	return (
		<Nav4ExpandableMenuItem
			isExpanded={isExpanded}
			menuId={menuId}
			onExpansionToggle={(hasExpanded) => {
				if (hasExpanded) {
					sendSectionItemAnalyticsEvent({
						menuId: L1_MENU_ID.ROADMAPS,
						sectionItem: SECTION_ITEM_ROADMAPS,
					});
				}
			}}
		>
			<ExpandableMenuItemTrigger
				interactionName="nav4-sidebar-roadmaps-default"
				actionsOnHover={actionsOnHover}
				aria-label={formattedMessage}
				elemBefore={icon}
				testId={getTestId(menuId)}
			>
				{formattedMessage}
			</ExpandableMenuItemTrigger>
			<ExpandableMenuItemContent>
				{/* TODO follow up: <SelectedRoadmapItem /> */}
				<Footer />
			</ExpandableMenuItemContent>
		</Nav4ExpandableMenuItem>
	);
}

import React, { type FC, type MouseEvent } from 'react';
import { MenuGroup } from '@atlaskit/menu';
import type { ContentProps } from '@atlaskit/popup';
import { Box } from '@atlaskit/primitives';
import { MENU_GROUP_MIN_WIDTH } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/constants/index.tsx';
import type { MenuItemsComponentType } from '../../types.tsx';

type MenuContentProps = ContentProps & {
	MenuItems: MenuItemsComponentType;
};

const stopEventPropagation = (event: MouseEvent<HTMLDivElement>) => event.stopPropagation();

export const MenuContent: FC<MenuContentProps> = ({ MenuItems, onClose }) => (
	<Box onMouseEnter={stopEventPropagation} onMouseLeave={stopEventPropagation}>
		<MenuGroup minWidth={MENU_GROUP_MIN_WIDTH}>
			<MenuItems onClose={onClose} />
		</MenuGroup>
	</Box>
);

import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	showDescription: {
		id: 'jql-builder-basic.common.field-description-popup.show-description',
		defaultMessage: 'Show the description for this field',
		description:
			'tooltip text explaining the field description will be shown when interacting with the wrapped field description popup button',
	},
	hideDescription: {
		id: 'jql-builder-basic.common.field-description-popup.hide-description',
		defaultMessage: 'Hide the description for this field',
		description: 'inform users that they can close the pop up',
	},
});

export default messages;

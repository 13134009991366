import React from 'react';
import PremiumFeatureGate from '@atlassian/jira-atlassian-navigation/src/ui/plans/menu/premium-feature-gate/index.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import {
	FlyoutMenuItem,
	FlyoutMenuItemContent,
	FlyoutMenuItemTrigger,
} from '@atlassian/navigation-system';

export const Nav4PlansPremiumUpsellFeatureGate = () => {
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(L1_MENU_ID.PLANS);

	return (
		<FlyoutMenuItem>
			<FlyoutMenuItemTrigger iconBefore={icon}>{formattedMessage}</FlyoutMenuItemTrigger>
			<FlyoutMenuItemContent>
				<PremiumFeatureGate />
			</FlyoutMenuItemContent>
		</FlyoutMenuItem>
	);
};

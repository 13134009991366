import { VALIDATION_ERROR_EMPTY } from '../../../common/constants.tsx';
import type { BoardCreateFormValues } from '../../../common/types.tsx';
import type { BoardCreateValidationErrors, BoardCreateFormInitialValues } from '../types.tsx';

const validateBoardName = (value: BoardCreateFormValues['boardName'] | undefined) => {
	return !value || value.trim().length === 0 ? VALIDATION_ERROR_EMPTY : undefined;
};

const validateSourceType = (value: BoardCreateFormValues['sourceType'] | undefined) => {
	return value === undefined ? VALIDATION_ERROR_EMPTY : undefined;
};

const validateSourceValue = (value: BoardCreateFormValues['sourceValue']) => {
	if ((Array.isArray(value) && !value.length) || (!Array.isArray(value) && !value)) {
		return VALIDATION_ERROR_EMPTY;
	}
};

export const validateFormData = (
	data: BoardCreateFormInitialValues | BoardCreateFormValues,
): BoardCreateValidationErrors => ({
	boardName: validateBoardName(data.boardName),
	sourceType: validateSourceType(data.sourceType),
	sourceValue: data.sourceType ? validateSourceValue(data.sourceValue) : undefined,
});

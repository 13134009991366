import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context/src/index.tsx';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';

export const canFetchForgeModules = (): boolean => {
	const { isAnonymous, cloudId } = getTenantContext_DEPRECATED_DO_NOT_USE();

	/**
	 * SMT-1142
	 * A tenant named `bob` is most likely a local/ci instance started with `./jmake run ci`.
	 * It wasn't possible to find the hostname in a reasonable timeframe so this is the most feasible solution.
	 * In case more tenants like this spawn in the future we'd need to mark these instances instead and block here.
	 */
	if (String(cloudId) === 'bob') return false;

	// There is no anonymous access for now
	return !isAnonymous;
};

export const canFetchForgeModulesV2 = ({
	isAnonymous,
	cloudId,
}: {
	isAnonymous: boolean;
	cloudId: CloudId;
}): boolean => {
	/**
	 * SMT-1142
	 * A tenant named `bob` is most likely a local/ci instance started with `./jmake run ci`.
	 * It wasn't possible to find the hostname in a reasonable timeframe so this is the most feasible solution.
	 * In case more tenants like this spawn in the future we'd need to mark these instances instead and block here.
	 */
	if (String(cloudId) === 'bob') return false;

	// There is no anonymous access for now
	return !isAnonymous;
};

import { useCallback } from 'react';
import type { GetAutocompleteSuggestions } from '@atlaskit/jql-editor-autocomplete-rest';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';

export const useAutocompleteSuggestions = (): GetAutocompleteSuggestions => {
	const baseUrl = '';
	return useCallback(
		(url: string) =>
			fetchJson(baseUrl + url).catch((error) => {
				fireErrorAnalytics({
					error,
					meta: {
						id: 'getAutocompleteSuggestions',
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
				});
				throw error;
			}),
		[baseUrl],
	);
};

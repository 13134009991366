import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import { createJpdExperience } from '../../utils/experience/main.tsx';

const changeViewName = createJpdExperience(
	new UFOExperience('jpd.nav-bar.change-view-name', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const changeViewRank = createJpdExperience(
	new UFOExperience('jpd.nav-bar.change-view-rank', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const createView = createJpdExperience(
	new UFOExperience('jpd.nav-bar.create-view', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	changeViewName,
	changeViewRank,
	createView,
};

import { useCallback, useMemo } from 'react';
import {
	NAVIGATION_ITEM,
	BUTTON,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import {
	FIXED_ITEM,
	FIXED_ITEM_BUTTON,
	CUSTOM_ITEM,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { MEATBALL_DROPDOWN } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-id.tsx';
import { PROJECT } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-type.tsx';
import { LEVEL_ONE } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { GLOBAL } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/navigation-layer.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { ProjectParams } from '../types.tsx';
import { getSectionByStarredOrRecent } from '../utils.tsx';

export const useProjectsAnalyticsL1 = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const commonAttributes = useMemo(
		() => ({
			level: LEVEL_ONE,
			navigationLayer: GLOBAL,
		}),
		[],
	);

	// fixed item - view all projects link
	const sendViewAllProjectsAnalyticsEvent = useCallback(() => {
		const event = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: NAVIGATION_ITEM,
		});

		const attributes = {
			...commonAttributes,
			componentType: FIXED_ITEM,
			itemId: 'viewAllProjects',
		};
		fireUIAnalytics(event, 'projectsNavigationMenuItem', attributes);
	}, [commonAttributes, createAnalyticsEvent]);

	// fixed item - meatball icon button on projects menu (top level)
	const sendMeatBallDropDownAnalyticsEvent = useCallback(() => {
		const event = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: BUTTON,
		});

		const attributes = {
			...commonAttributes,
			componentType: FIXED_ITEM_BUTTON,
			itemId: MEATBALL_DROPDOWN,
		};
		fireUIAnalytics(event, 'projectsNavigationMenuItem', attributes);
	}, [commonAttributes, createAnalyticsEvent]);

	// fixed item - create project (+) button on projects menu (top level)
	const sendCreateProjectAnalyticsEvent = useCallback(() => {
		const event = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: BUTTON,
		});

		const attributes = {
			...commonAttributes,
			componentType: FIXED_ITEM_BUTTON,
			itemId: 'createProject',
		};
		fireUIAnalytics(event, 'projectsNavigationMenuItem', attributes);
	}, [commonAttributes, createAnalyticsEvent]);

	// custom item - project menu item that displays project name (can be expandable menu or a link)
	const sendProjectMenuItemAnalyticsEvent = useCallback(
		({ starred, recent, projectId, projectType }: ProjectParams) => {
			const event = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: NAVIGATION_ITEM,
			});

			const attributes = {
				...commonAttributes,
				componentType: CUSTOM_ITEM,
				itemType: PROJECT,
				itemId: projectId,
				projectType,
				section: getSectionByStarredOrRecent({ starred, recent }),
			};
			fireUIAnalytics(event, 'projectsNavigationMenuItem', attributes);
		},
		[commonAttributes, createAnalyticsEvent],
	);

	return {
		sendViewAllProjectsAnalyticsEvent,
		sendMeatBallDropDownAnalyticsEvent,
		sendCreateProjectAnalyticsEvent,
		sendProjectMenuItemAnalyticsEvent,
	};
};

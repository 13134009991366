import { fg } from '@atlassian/jira-feature-gating';
import { getDefaultOptions } from '@atlassian/jira-fetch/src/utils/fetch-default-options.tsx';
import {
	performGetRequest as jiraPerformGetRequest,
	performPostRequest as jiraPerformPostRequest,
	performPutRequest as jiraPerformPutRequest,
	performDeleteRequest as jiraPerformDeleteRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';

export const cmdbRequestSourceHeader: Record<string, string> = {
	'X-Request-Source': 'cmdb-frontend',
};

export const performCmdbGetRequest: typeof jiraPerformGetRequest = (url, options) =>
	jiraPerformGetRequest(url, mergeOptions(url, options));

export const performCmdbPostRequest: typeof jiraPerformPostRequest = (url, options) =>
	jiraPerformPostRequest(url, mergeOptions(url, options));

export const performCmdbPutRequest: typeof jiraPerformPutRequest = (url, options) =>
	jiraPerformPutRequest(url, mergeOptions(url, options));

export const performCmdbDeleteRequest: typeof jiraPerformDeleteRequest = (url, options) =>
	jiraPerformDeleteRequest(url, mergeOptions(url, options));

export const mergeOptions = (url: string, options: RequestInit | undefined) => {
	const defaultOptions = getDefaultOptions(url);

	return fg('fly-2529-cmdb-client-send-request-source-header')
		? {
				...defaultOptions,
				...options,
				headers: {
					...defaultOptions.headers,
					...options?.headers,
					...cmdbRequestSourceHeader,
				},
			}
		: options;
};

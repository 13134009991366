import React from 'react';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { fg } from '@atlassian/jira-feature-gating';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { Nav4Apps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-apps/src/index.tsx';
import { Nav4Assets } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-assets/src/ui/index.tsx';
import { CustomerService } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-customer-service/src/index.tsx';
import { Nav4Dashboards } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-dashboards/src/index.tsx';
import { Nav4Filters } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-filters/src/index.tsx';
import { Nav4Operations } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-operations/src/ui/Nav4Operations.tsx';
import { useShouldRenderOverviews } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-overviews/src/controllers/index.tsx';
import { Nav4Overviews } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-overviews/src/index.tsx';
import { Nav4Plans } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-plans/src/index.tsx';
import { Nav4Projects } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-core/src/index.tsx';
import { Recent } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-recent/src/index.tsx';
import { Nav4Roadmaps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-roadmaps/src/index.tsx';
import { Starred } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-starred/src/index.tsx';
import { Teams } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-teams/src/index.tsx';
import type { ConfigurableMenuItem$key } from '@atlassian/jira-relay/src/__generated__/ConfigurableMenuItem.graphql';
import { USER_NAVIGATION_ITEM_LINKS } from '../../constants/index.tsx';
import { Stub } from '../../constants/stub/main.tsx';

export type ConfigurablesProps = {
	isDefaultOpen?: boolean;
	menuData: ConfigurableMenuItem$key;
	menuId: string;
};

export function ConfigurableMenuItem({
	isDefaultOpen = false,
	menuData,
	menuId,
}: ConfigurablesProps) {
	const data = useFragment<ConfigurableMenuItem$key>(
		graphql`
			fragment ConfigurableMenuItem on Query {
				...Nav4Projects
				...Nav4Filters
				...Nav4Plans
				...Nav4Apps
				...Nav4Dashboards
				...Nav4Operations
				...Nav4Overviews
			}
		`,
		menuData,
	);

	const shouldRenderOverviews = useShouldRenderOverviews();

	switch (menuId) {
		case L1_MENU_ID.APPS:
			return <Nav4Apps isExpanded={isDefaultOpen} queryRef={data} />;
		case L1_MENU_ID.ASSETS:
			return <Nav4Assets isExpanded={isDefaultOpen} />;
		case L1_MENU_ID.DASHBOARDS:
			return <Nav4Dashboards isExpanded={isDefaultOpen} queryRef={data} />;
		case L1_MENU_ID.FILTERS:
			return <Nav4Filters isExpanded={isDefaultOpen} queryRef={data} />;
		case L1_MENU_ID.GOALS:
			return <Stub menuId={L1_MENU_ID.GOALS} href={USER_NAVIGATION_ITEM_LINKS[L1_MENU_ID.GOALS]} />;
		case L1_MENU_ID.OPERATIONS:
			return <Nav4Operations isExpanded={isDefaultOpen} queryRef={data} />;
		case L1_MENU_ID.PLANS:
			return <Nav4Plans isExpanded={isDefaultOpen} queryRef={data} />;
		case L1_MENU_ID.OVERVIEWS:
			return shouldRenderOverviews && fg('blu-2858-sidebar-menu-overviews') ? (
				<Nav4Overviews isExpanded={isDefaultOpen} queryRef={data} />
			) : null;
		case L1_MENU_ID.PROJECTS:
			return <Nav4Projects isExpanded={isDefaultOpen} queryRef={data} />;
		case L1_MENU_ID.RECENT:
			return <Recent isDefaultOpen={isDefaultOpen} />;
		case L1_MENU_ID.ROADMAPS:
			return <Nav4Roadmaps isExpanded={isDefaultOpen} />;
		case L1_MENU_ID.STARRED:
			return <Starred isDefaultOpen={isDefaultOpen} />;
		case L1_MENU_ID.TEAMS:
			return <Teams />;
		case L1_MENU_ID.CUSTOMER_SERVICE:
			return fg('jcs_master_flag') ? <CustomerService /> : null;
		default:
			return null;
	}
}

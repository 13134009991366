import React from 'react';
import type DashboardsDirectoryType from '@atlassian/jira-spa-apps-directories-dashboards/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyDashboardsDirectory = lazyForPaint<typeof DashboardsDirectoryType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-dashboards-directory", jiraSpaEntry: "async-dashboards-directory" */ '@atlassian/jira-spa-apps-directories-dashboards'
		),
	),
);

export const DashboardsDirectoryComponent = () => (
	<LazyPage
		Page={LazyDashboardsDirectory}
		pageId="dashboards-directory"
		shouldShowSpinner={false}
	/>
);

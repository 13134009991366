export const entityKeys = {
	confluencePage: 'confluence-page',
	confluenceWhiteboard: 'confluence-whiteboard',
} as const;

export const ContentTypes = {
	PAGE: 'page',
	WHITEBOARD: 'whiteboard',
} as const;

export enum Fields {
	Title = 'title',
	Site = 'site',
	SpacePage = 'space_or_page',
}

export const testIds = {
	form: 'link-create-confluence-form',
	title: 'link-create-confluence-title',
	site: 'link-create-confluence-site-picker',
	space: 'link-create-confluence-space-picker',
	error: 'link-create-confluence-form-error',
};

export const CONFLUENCE_EVENT_NAME = {
	EDIT_PAGE: 'edit-page',
	EDIT_PAGE_LOAD: 'edit-page/load',
	EDIT_PAGE_PUBLISH: 'edit-page/publish',
	EDIT_PAGE_CLOSE: 'edit-page/close',
};

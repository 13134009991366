/**
 * @generated SignedSource<<f245c96814deb8f505d2b497ec43b86d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4ConnectMenuItem$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4AppMenuItem">;
  readonly " $fragmentType": "Nav4ConnectMenuItem";
};
export type Nav4ConnectMenuItem$key = {
  readonly " $data"?: Nav4ConnectMenuItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4ConnectMenuItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "Nav4ConnectMenuItem",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "Nav4AppMenuItem"
    }
  ],
  "type": "JiraAppNavigationItem"
};

(node as any).hash = "76baf08d707245f4a5aadd384fd44056";

export default node;

/**
 * @generated SignedSource<<5c922eff48d03ae2dcfc19a0d7a29215>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectStatus = "ACTIVE" | "ARCHIVED" | "DELETED" | "%future added value";
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type ui_navigationProjectActionMenu_ProjectActionMenu$data = {
  readonly __id: string;
  readonly canEditProjectConfig: {
    readonly canPerform: boolean;
  } | null | undefined;
  readonly canViewProjectConfig: {
    readonly canPerform: boolean;
  } | null | undefined;
  readonly id: string;
  readonly isFavourite: boolean | null | undefined;
  readonly projectId: string;
  readonly projectKey: string;
  readonly projectStyle: JiraProjectStyle | null | undefined;
  readonly projectType: JiraProjectType;
  readonly status: JiraProjectStatus | null | undefined;
  readonly webUrl: AGG$URL;
  readonly " $fragmentType": "ui_navigationProjectActionMenu_ProjectActionMenu";
};
export type ui_navigationProjectActionMenu_ProjectActionMenu$key = {
  readonly " $data"?: ui_navigationProjectActionMenu_ProjectActionMenu$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_navigationProjectActionMenu_ProjectActionMenu">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "name": "canPerform"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_navigationProjectActionMenu_ProjectActionMenu",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "projectId"
      },
      "action": "THROW",
      "path": "projectId"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "projectKey",
        "kind": "ScalarField",
        "name": "key"
      },
      "action": "THROW",
      "path": "projectKey"
    },
    {
      "kind": "ScalarField",
      "name": "isFavourite"
    },
    {
      "kind": "ScalarField",
      "name": "projectStyle"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "projectType"
      },
      "action": "THROW",
      "path": "projectType"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "webUrl"
      },
      "action": "THROW",
      "path": "webUrl"
    },
    {
      "kind": "ScalarField",
      "name": "status"
    },
    {
      "alias": "canEditProjectConfig",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "EDIT_PROJECT_CONFIG"
        }
      ],
      "concreteType": "JiraProjectAction",
      "kind": "LinkedField",
      "name": "action",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
    },
    {
      "alias": "canViewProjectConfig",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "VIEW_PROJECT_CONFIG"
        }
      ],
      "concreteType": "JiraProjectAction",
      "kind": "LinkedField",
      "name": "action",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "action(type:\"VIEW_PROJECT_CONFIG\")"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    }
  ],
  "type": "JiraProject"
};
})();

(node as any).hash = "1eaad261749ca59fb9d45c192a018c0a";

export default node;

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { servicedeskCmdbInsightObjectTypeConfigurationRoute } from '@atlassian/jira-router-routes-servicedesk-cmdb-routes/src/servicedeskCmdbInsightObjectTypeConfigurationRoute.tsx';
import {
	toPackageName,
	APP_NAMES,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { sharedRouteProps } from './common/constants.tsx';
import { getCmdbResources } from './common/getCmdbResources.tsx';
import ConfigureObjectType, {
	LazyServicedeskCmdbConfigureObjectType,
} from './ui/configure-object-type/index.tsx';

export const servicedeskCmdbInsightObjectTypeConfigurationRouteEntry: Route = createEntry(
	servicedeskCmdbInsightObjectTypeConfigurationRoute,
	() => ({
		...sharedRouteProps,
		component: ConfigureObjectType,
		resources: getCmdbResources(),
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbConfigureObjectType],
		meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_OBJECT_TYPE_CONFIGURATION,
				packageName: toPackageName(APP_NAMES.INSIGHT_OBJECT_TYPE_CONFIGURATION),
				teamName: 'falcons',
			},
		},
	}),
);

import { useEffect, useState, useCallback } from 'react';
import shuffle from 'lodash/shuffle';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { AppRecommendations } from '../common/types.tsx';
import { recommendations } from '../ui/index.tsx';
import { useRecommendationContext } from './useRecommendationContext.tsx';

export const useSelectedRecommendation = ({
	appRecommendations,
}: {
	appRecommendations: AppRecommendations;
}) => {
	const [state, setState] = useState<{
		status: 'loading' | 'success' | 'error';
		recommendationKey?: string | null;
		error?: Error | null;
	}>({ status: 'loading', recommendationKey: null, error: null });

	const recommendationContext = useRecommendationContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const dismissRecommendation = useCallback(() => {
		setState({
			status: 'success',
			recommendationKey: null,
		});
	}, []);

	useEffect(() => {
		const getSelectedRecommendation = async () => {
			try {
				if (!recommendationContext) {
					setState({
						status: 'success',
						recommendationKey: null,
					});
					return;
				}
				const shuffledRecommendations = shuffle(recommendations);

				const recommendationStatus = await Promise.all(
					shuffledRecommendations.map(async (recommendation) => {
						let isEnabled = false;
						try {
							isEnabled = await recommendation.shouldRender(
								recommendationContext,
								createAnalyticsEvent({}),
								appRecommendations,
							);
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
						} catch (error: any) {
							recommendation.onLoadError(error);
						}
						return {
							key: recommendation.key,
							isEnabled,
						};
					}),
				);

				const selectedRecommendation = recommendationStatus.find((result) => result.isEnabled);

				setState({
					status: 'success',
					recommendationKey: selectedRecommendation?.key || null,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setState({
					status: 'error',
					error,
				});
			}
		};
		getSelectedRecommendation();
	}, [appRecommendations, createAnalyticsEvent, recommendationContext]);

	return {
		selectedRecommendationState: state,
		dismissRecommendation,
	};
};

import { fg } from '@atlassian/jira-feature-gating';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useApplicationPermissions } from '@atlassian/jira-tenant-context-controller/src/components/application-permissions/index.tsx';

export const useShowCustomerService = (): boolean => {
	const isAdmin = useIsAdmin();
	const { hasServiceDeskAccess } = useApplicationPermissions();

	return fg('jcs_master_flag') && (isAdmin || hasServiceDeskAccess);
};

/**
 * @generated SignedSource<<4b252365ea6f4033de11ad3ac85b26fb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type DashboardMenuItem$data = {
  readonly dashboardId: AGG$Long;
  readonly favouriteValue: {
    readonly isFavourite: boolean | null | undefined;
  } | null | undefined;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardPopupMenu">;
  readonly " $fragmentType": "DashboardMenuItem";
};
export type DashboardMenuItem$key = {
  readonly " $data"?: DashboardMenuItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardMenuItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "DashboardMenuItem",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "title"
      },
      "action": "THROW",
      "path": "title"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "dashboardId"
      },
      "action": "THROW",
      "path": "dashboardId"
    },
    {
      "concreteType": "JiraFavouriteValue",
      "kind": "LinkedField",
      "name": "favouriteValue",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isFavourite"
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "DashboardPopupMenu"
    }
  ],
  "type": "JiraDashboard"
};

(node as any).hash = "e30b21c7949d8052bd6833da91711e25";

export default node;

import React, { useEffect } from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import AKModalDialog, { type ModalDialogProps } from '@atlaskit/modal-dialog';
import { useModalDialogActions, useReturnFocusTo } from '../../controllers/index.tsx';

export const ModalDialog = ({ shouldReturnFocus, ...rest }: ModalDialogProps) => {
	const returnFocusTarget = useReturnFocusTo();
	const { setReturnFocusTo } = useModalDialogActions();

	useEffect(
		() => () => {
			if (returnFocusTarget?.current) {
				!shouldReturnFocus && returnFocusTarget.current.focus();
				setReturnFocusTo(null);
			}
		},
		[returnFocusTarget, setReturnFocusTo, shouldReturnFocus],
	);

	/**
	 *  when shouldReturnFocus is defined,
	 * 	we won't handle passing focus back to this element
	 *	we will ignore the returnFocusTarget
	 */
	const returnFocus = shouldReturnFocus ?? !returnFocusTarget;

	return <AKModalDialog shouldReturnFocus={returnFocus} {...rest} />;
};

import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const CustomisableSidebarModalEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-jira-navigation-apps-sidebar-nav4-customise-sidebar-modal" */ './src/index'
		).then(({ CustomisableSidebarModal }) => CustomisableSidebarModal),
	),
	getPreloadProps: (props) => ({
		queries: {}, // Need to specify `queries` as it is a mandatory prop
		props,
	}),
});

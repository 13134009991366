import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';
import { createJpdExperience } from '../../utils/experience/main.tsx';

const loadSharingConfig = createJpdExperience(
	new UFOExperience('jpd.product-access.load-publish-config', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const updateSharingConfig = createJpdExperience(
	new UFOExperience('jpd.product-access.update-publish-config', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	loadSharingConfig,
	updateSharingConfig,
};

import type { PeriscopePage as SoftwarePeriscopeType } from '@atlassian/jira-spa-apps-software-periscope/src/ui/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyPeriscope = lazyForPaint<typeof SoftwarePeriscopeType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-periscope", jiraSpaEntry: "async-periscope" */ '@atlassian/jira-spa-apps-software-periscope'
		),
	),
);

import React, { type ReactElement } from 'react';
import { graphql, useFragment } from 'react-relay';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { SECTION_ITEM_OVERVIEW } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_OVERVIEWS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { Nav4ExpandableMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-expandable-menu-item/index.tsx';
import { getTestId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-test-id/index.tsx';
import { useSectionItemAnalytics } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-section-item-analytics/index.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import type { Nav4OverviewsForLanding$key } from '@atlassian/jira-relay/src/__generated__/Nav4OverviewsForLanding.graphql.ts';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { ExpandableMenuItemContent, ExpandableMenuItemTrigger } from '@atlassian/navigation-system';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import { Nav4OverviewsContent } from '../content/Nav4OverviewsContent.tsx';

type Nav4OverviewsForLandingProps = {
	isExpanded: boolean;
	queryRef: Nav4OverviewsForLanding$key;
	actionsOnHover: ReactElement;
};

export function Nav4OverviewsForLanding({
	isExpanded,
	queryRef,
	actionsOnHover,
}: Nav4OverviewsForLandingProps) {
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(L1_MENU_ID.OVERVIEWS);
	const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();

	const data = useFragment<Nav4OverviewsForLanding$key>(
		graphql`
			fragment Nav4OverviewsForLanding on Query {
				...Nav4OverviewsContent
			}
		`,
		queryRef,
	);

	return (
		<Nav4ExpandableMenuItem
			onExpansionToggle={(hasExpanded) => {
				if (hasExpanded) {
					sendSectionItemAnalyticsEvent({
						menuId: L1_MENU_ID.OVERVIEWS,
						sectionItem: SECTION_ITEM_OVERVIEW,
					});
				}
			}}
			isExpanded={isExpanded}
			menuId={MENU_ID_OVERVIEWS}
		>
			<ExpandableMenuItemTrigger
				interactionName="nav4-sidebar-overviews-landing"
				actionsOnHover={actionsOnHover}
				// Prevent double-up of accessible name that occurs in icon+text situation.
				aria-label={formattedMessage}
				elemBefore={icon}
				// ref={triggerRef}
				testId={getTestId(MENU_ID_OVERVIEWS)}
			>
				{formattedMessage}
			</ExpandableMenuItemTrigger>
			<ExpandableMenuItemContent>
				<UFOSegment name="nav4-sidebar-overviews-landing">
					<JSErrorBoundary
						id="nav4-sidebar-overviews-landing"
						packageName={PACKAGE_NAME}
						teamName={TEAM_NAME}
						fallback="flag"
					>
						<Nav4OverviewsContent queryRef={data} />
					</JSErrorBoundary>
				</UFOSegment>
			</ExpandableMenuItemContent>
		</Nav4ExpandableMenuItem>
	);
}

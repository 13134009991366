import {
	ROUTE_NAMES_EOC_AUTOMATIONS,
	ROUTE_NAMES_EOC_CUSTOM_ROLES,
	ROUTE_NAMES_EOC_GLOBAL_ALERTS,
	ROUTE_NAMES_EOC_GLOBAL_ALERT_DETAIL,
	ROUTE_NAMES_EOC_GLOBAL_ESCALATIONS,
	ROUTE_NAMES_EOC_GLOBAL_GROUP_DETAIL,
	ROUTE_NAMES_EOC_GLOBAL_REPORTS,
	ROUTE_NAMES_EOC_HEARTBEATS,
	ROUTE_NAMES_EOC_MAINTENANCE,
	ROUTE_NAMES_EOC_ON_CALL,
	ROUTE_NAMES_EOC_ON_CALL_REDIRECT,
	ROUTE_NAMES_EOC_OVERVIEW,
	ROUTE_NAMES_EOC_PERMISSIONS,
	ROUTE_NAMES_EOC_REPORTS,
	ROUTE_NAMES_EOC_REPORTS_TEAM,
	ROUTE_NAMES_EOC_REPORTS_UNSUBSCRIBE,
	ROUTE_NAMES_EOC_SCHEDULES,
	ROUTE_NAMES_EOC_TEAM_ALERTS,
	ROUTE_NAMES_EOC_TEAM_ALERT_DETAILS,
	ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING,
	ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING_CREATE,
	ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING_DETAIL,
	ROUTE_NAMES_EOC_TEAM_GROUP_DETAIL,
	ROUTE_NAMES_EOC_TEAM_INTEGRATIONS,
	ROUTE_NAMES_EOC_TEAM_INTEGRATION_CREATE,
	ROUTE_NAMES_EOC_TEAM_INTEGRATION_DETAIL,
	ROUTE_NAMES_EOC_TEAM_INTEGRATION_LIST,
	ROUTE_NAMES_EOC_TEAM_INTEGRATION_LOG,
	ROUTE_NAMES_EOC_TEAM_POLICIES,
	ROUTE_NAMES_EOC_WHO_IS_ON_CALL,
	ROUTE_NAMES_EOC_OVERVIEW_ALL_TEAMS,
	ROUTE_NAMES_EOC_GLOBAL_REPORT_DASHBOARD,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { RouterContext } from '@atlassian/react-resource-router';
import {
	ENTITY_ID,
	MENU_ID_OPERATIONS,
	MENU_ID_OPS_ALERTS,
	MENU_ID_OPS_ON_CALL,
	MENU_ID_OPS_OVERVIEW,
	MENU_ID_OPS_REPORTS,
} from '../../../../constants.tsx';
import type { SelectedPath } from '../../../../types.tsx';

const routes = new Set<string>([
	ROUTE_NAMES_EOC_AUTOMATIONS,
	ROUTE_NAMES_EOC_CUSTOM_ROLES,
	ROUTE_NAMES_EOC_GLOBAL_ALERTS,
	ROUTE_NAMES_EOC_GLOBAL_ALERT_DETAIL,
	ROUTE_NAMES_EOC_GLOBAL_GROUP_DETAIL,
	ROUTE_NAMES_EOC_GLOBAL_REPORTS,
	ROUTE_NAMES_EOC_GLOBAL_REPORT_DASHBOARD,
	ROUTE_NAMES_EOC_HEARTBEATS,
	ROUTE_NAMES_EOC_MAINTENANCE,
	ROUTE_NAMES_EOC_ON_CALL,
	ROUTE_NAMES_EOC_ON_CALL_REDIRECT,
	ROUTE_NAMES_EOC_OVERVIEW,
	ROUTE_NAMES_EOC_OVERVIEW_ALL_TEAMS,
	ROUTE_NAMES_EOC_PERMISSIONS,
	ROUTE_NAMES_EOC_REPORTS,
	ROUTE_NAMES_EOC_REPORTS_TEAM,
	ROUTE_NAMES_EOC_REPORTS_UNSUBSCRIBE,
	ROUTE_NAMES_EOC_SCHEDULES,
	ROUTE_NAMES_EOC_TEAM_ALERTS,
	ROUTE_NAMES_EOC_TEAM_ALERT_DETAILS,
	ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING,
	ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING_CREATE,
	ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING_DETAIL,
	ROUTE_NAMES_EOC_TEAM_GROUP_DETAIL,
	ROUTE_NAMES_EOC_TEAM_INTEGRATIONS,
	ROUTE_NAMES_EOC_TEAM_INTEGRATION_CREATE,
	ROUTE_NAMES_EOC_TEAM_INTEGRATION_DETAIL,
	ROUTE_NAMES_EOC_TEAM_INTEGRATION_LIST,
	ROUTE_NAMES_EOC_TEAM_INTEGRATION_LOG,
	ROUTE_NAMES_EOC_TEAM_POLICIES,
	ROUTE_NAMES_EOC_WHO_IS_ON_CALL,
	ROUTE_NAMES_EOC_GLOBAL_ESCALATIONS,
]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;
	const { query } = routeContext;
	const path: string[] = [MENU_ID_OPERATIONS];

	switch (routeName) {
		case ROUTE_NAMES_EOC_WHO_IS_ON_CALL:
			path.push(MENU_ID_OPS_ON_CALL);
			break;

		case ROUTE_NAMES_EOC_GLOBAL_ALERTS:
		case ROUTE_NAMES_EOC_GLOBAL_ALERT_DETAIL: {
			path.push(MENU_ID_OPS_ALERTS);
			const { query: alarmQueue = '', view } = query;
			view && path.push(ENTITY_ID.ALARM_QUEUE(alarmQueue));
			break;
		}

		case ROUTE_NAMES_EOC_GLOBAL_REPORTS:
		case ROUTE_NAMES_EOC_GLOBAL_REPORT_DASHBOARD:
		case ROUTE_NAMES_EOC_REPORTS_UNSUBSCRIBE:
			path.push(MENU_ID_OPS_REPORTS);
			break;

		case ROUTE_NAMES_EOC_AUTOMATIONS:
		case ROUTE_NAMES_EOC_GLOBAL_GROUP_DETAIL: // This might be wrong, but I can't see the feature.
		case ROUTE_NAMES_EOC_HEARTBEATS:
		case ROUTE_NAMES_EOC_MAINTENANCE:
		case ROUTE_NAMES_EOC_PERMISSIONS:
		case ROUTE_NAMES_EOC_ON_CALL:
		case ROUTE_NAMES_EOC_ON_CALL_REDIRECT:
		case ROUTE_NAMES_EOC_OVERVIEW:
		case ROUTE_NAMES_EOC_OVERVIEW_ALL_TEAMS:
		case ROUTE_NAMES_EOC_REPORTS:
		case ROUTE_NAMES_EOC_REPORTS_TEAM:
		case ROUTE_NAMES_EOC_SCHEDULES:
		case ROUTE_NAMES_EOC_TEAM_GROUP_DETAIL:
		case ROUTE_NAMES_EOC_TEAM_ALERTS:
		case ROUTE_NAMES_EOC_TEAM_ALERT_DETAILS:
		case ROUTE_NAMES_EOC_TEAM_POLICIES:
		case ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING:
		case ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING_CREATE:
		case ROUTE_NAMES_EOC_TEAM_ALERT_ISSUE_SYNCING_DETAIL:
		case ROUTE_NAMES_EOC_TEAM_INTEGRATIONS:
		case ROUTE_NAMES_EOC_TEAM_INTEGRATION_CREATE:
		case ROUTE_NAMES_EOC_TEAM_INTEGRATION_DETAIL:
		case ROUTE_NAMES_EOC_TEAM_INTEGRATION_LIST:
		case ROUTE_NAMES_EOC_TEAM_INTEGRATION_LOG:
			path.push(MENU_ID_OPS_OVERVIEW);
			break;

		default: {
			break;
		}
	}

	return path;
}

export const operationsConverter = { routes, convert };

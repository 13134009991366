import React from 'react';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { LazyIntegrationList } from './async.tsx';

export const IntegrationList = () => (
	<LazyPage
		Page={LazyIntegrationList}
		pageId="async-jira-eoc-integration-automation"
		shouldShowSpinner
	/>
);

import { createResource, useResource } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { fetchStorageDetails } from './services/storage-details/index.tsx';
import type { StorageDetailsResource } from './types.tsx';

// delete this package when cleaning up fun-1376_clean_up_horizontal_nav_banner

const RESOURCE_TYPE_KEY = 'RESOURCE_TYPE_BUSINESS_INSTANCE_STORAGE';

const storageDetailsResourceInternal = createResource<StorageDetailsResource>({
	type: RESOURCE_TYPE_KEY,
	getKey: () => RESOURCE_TYPE_KEY,
	getData: async (_, { tenantContext: { appEditions } }) => fetchStorageDetails(appEditions),
	maxAge: Infinity,
	isBrowserOnly: true, // not loaded from SSR to not delay TTI
});

/**
 * @deprecated do not use. To be deleted with fun-1376_clean_up_horizontal_nav_banner clean up
 */
export const storageDetailsResource = resourceWithCondition2(
	() => !fg('fun-1376_clean_up_horizontal_nav_banner'),
	storageDetailsResourceInternal,
);

/**
 * @deprecated do not use. To be deleted with fun-1376_clean_up_horizontal_nav_banner clean up
 */
export const useStorageDetails = () => useResource<StorageDetailsResource>(storageDetailsResource);

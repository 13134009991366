import React, { type ComponentType, type ReactElement } from 'react';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';

type RedirectFunction = (page: string, plan?: { planId: string; scenarioId: string }) => void;

type RedirectProps = {
	redirectToSpaPlanPage: RedirectFunction;
};

export const useRedirectToSpaPlanPage = (): RedirectFunction => {
	const [{ match, location }, { replace, push }] = useRouter();
	return (page: string, plan?: { planId: string; scenarioId: string }) => {
		if (getWillShowNav4() && plan) {
			const newUrl = `/jira/plans/${plan.planId}/scenarios/${plan.scenarioId}${page}`;
			if (newUrl === location.pathname) {
				// short-circuit if the redirect url is the same
				return;
			}
			push(newUrl);
		} else {
			if (!match.params?.planId || !match.params?.scenarioId) return;

			const newUrl = `/jira/plans/${match.params.planId}/scenarios/${match.params.scenarioId}${page}`;

			if (newUrl === location.pathname) {
				// short-circuit if the redirect url is the same
				return;
			}
			replace(newUrl);
		}
	};
};

const withRedirectToSpaPlanPage =
	<P extends object>(
		WrappedComponent: ComponentType<P>,
	): ((props: P) => ReactElement<P & RedirectProps>) =>
	(props: P) => {
		const redirectToSpaPlanPage = useRedirectToSpaPlanPage();
		return <WrappedComponent {...props} redirectToSpaPlanPage={redirectToSpaPlanPage} />;
	};

export default withRedirectToSpaPlanPage;

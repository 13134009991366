import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { JSMOperationsHorizontalNav } from './ui/index.tsx';

export const LazyJSMOperationsHorizontalNav = lazyForPaint<typeof JSMOperationsHorizontalNav>(() =>
	import(/* webpackChunkName: "jira-operations-horizontal-nav" */ './ui').then(
		({ JSMOperationsHorizontalNav }) => JSMOperationsHorizontalNav,
	),
);

export const AsyncJSMOperationsHorizontalNav = () => (
	<Placeholder name="horizontal-operations-nav" fallback={null}>
		<JSErrorBoundary
			id="asyncHorizontalOperationsNav"
			packageName="jiraHorizontalNavJsmOperations"
			fallback="flag"
			teamName="alexstrasza"
		>
			<LazyJSMOperationsHorizontalNav />
		</JSErrorBoundary>
	</Placeholder>
);

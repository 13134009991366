import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import { createJpdExperience } from '../../utils/experience/main.tsx';

const pageSegmentLoad = createJpdExperience(
	new UFOExperience('jpd.idea-view-bento-sidebar', {
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	}),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	pageSegmentLoad,
};

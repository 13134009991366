import { L1_MENU_ID, type ConfigurableMenuId } from '../../../common/types.tsx';
import messages from '../messages.tsx';

// a function that is used to extract a11y label with context
export const getA11YLabel = (menuId: ConfigurableMenuId) => {
	switch (menuId) {
		case L1_MENU_ID.APPS:
			return messages.a11YHideAppsFromSidebar;
		case L1_MENU_ID.GOALS:
			return messages.a11YHideGoalsFromSidebar;
		case L1_MENU_ID.ROADMAPS:
			return messages.a11YHideRoadmapsFromSidebar;
		case L1_MENU_ID.PLANS:
			return messages.a11YHidePlansFromSidebar;
		case L1_MENU_ID.PROJECTS:
			return messages.a11YHideProjectsFromSidebar;
		case L1_MENU_ID.FILTERS:
			return messages.a11YHideFiltersFromSidebar;
		case L1_MENU_ID.DASHBOARDS:
			return messages.a11YHideDashboardsFromSidebar;
		case L1_MENU_ID.OVERVIEWS:
			return messages.a11YHideOverviewsFromSidebar;
		case L1_MENU_ID.OPERATIONS:
			return messages.a11YHideOperationsFromSidebar;
		case L1_MENU_ID.ASSETS:
			return messages.a11YHideAssetsFromSidebar;
		case L1_MENU_ID.TEAMS:
			return messages.a11YHideTeamsFromSidebar;
		default:
			return messages.hideFromSidebar;
	}
};

// a function that is used to extract a11y alert (corresponding the aria live region)
// when an action takes place to hide a menu item
export const getA11YAlert = (menuId: ConfigurableMenuId) => {
	switch (menuId) {
		case L1_MENU_ID.APPS:
			return messages.a11YAlertHideAppsFromSidebar;
		case L1_MENU_ID.GOALS:
			return messages.a11YAlertHideGoalsFromSidebar;
		case L1_MENU_ID.ROADMAPS:
			return messages.a11YAlertHideRoadmapsFromSidebar;
		case L1_MENU_ID.PLANS:
			return messages.a11YAlertHidePlansFromSidebar;
		case L1_MENU_ID.PROJECTS:
			return messages.a11YAlertHideProjectsFromSidebar;
		case L1_MENU_ID.FILTERS:
			return messages.a11YAlertHideFiltersFromSidebar;
		case L1_MENU_ID.DASHBOARDS:
			return messages.a11YAlertHideDashboardsFromSidebar;
		case L1_MENU_ID.OVERVIEWS:
			return messages.a11YAlertHideOverviewsFromSidebar;
		case L1_MENU_ID.OPERATIONS:
			return messages.a11YAlertHideOperationsFromSidebar;
		case L1_MENU_ID.ASSETS:
			return messages.a11YAlertHideAssetsFromSidebar;
		case L1_MENU_ID.TEAMS:
			return messages.a11YAlertHideTeamsFromSidebar;
		default:
			return messages.a11YAlertHideDefaultFromSidebar;
	}
};

export const getNavItemHiddenFromSideBarFlagTitle = (menuId: ConfigurableMenuId) => {
	switch (menuId) {
		case L1_MENU_ID.APPS:
			return messages.appsHiddenFromSidebar;
		case L1_MENU_ID.GOALS:
			return messages.goalsHiddenFromSidebar;
		case L1_MENU_ID.ROADMAPS:
			return messages.roadmapsHiddenFromSidebar;
		case L1_MENU_ID.PLANS:
			return messages.plansHiddenFromSidebar;
		case L1_MENU_ID.PROJECTS:
			return messages.projectsHiddenFromSidebar;
		case L1_MENU_ID.FILTERS:
			return messages.filtersMovedBackToSidebar;
		case L1_MENU_ID.DASHBOARDS:
			return messages.dashboardsHiddenFromSidebar;
		case L1_MENU_ID.OVERVIEWS:
			return messages.overviewsHiddenFromSidebar;
		case L1_MENU_ID.OPERATIONS:
			return messages.operationsHiddenFromSidebar;
		case L1_MENU_ID.ASSETS:
			return messages.assetsHiddenFromSidebar;
		case L1_MENU_ID.TEAMS:
			return messages.teamsHiddenFromSidebar;
		default:
			return messages.defaultHiddenFromSidebar;
	}
};

export const getNavItemRestoredFromSideBarFlagTitle = (menuId: ConfigurableMenuId) => {
	switch (menuId) {
		case L1_MENU_ID.APPS:
			return messages.appsMovedBackToSidebar;
		case L1_MENU_ID.GOALS:
			return messages.goalsMovedBackToSidebar;
		case L1_MENU_ID.ROADMAPS:
			return messages.roadmapsMovedBackToSidebar;
		case L1_MENU_ID.PLANS:
			return messages.plansMovedBackToSidebar;
		case L1_MENU_ID.PROJECTS:
			return messages.projectsMovedBackToSidebar;
		case L1_MENU_ID.FILTERS:
			return messages.filtersMovedBackToSidebar;
		case L1_MENU_ID.DASHBOARDS:
			return messages.dashboardsMovedBackToSidebar;
		case L1_MENU_ID.OVERVIEWS:
			return messages.overviewsMovedBackToSidebar;
		case L1_MENU_ID.OPERATIONS:
			return messages.operationsMovedBackToSidebar;
		case L1_MENU_ID.ASSETS:
			return messages.assetsMovedBackToSidebar;
		case L1_MENU_ID.TEAMS:
			return messages.teamsMovedBackToSidebar;
		default:
			return messages.defaultMovedBackToSidebar;
	}
};

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorTitle: {
		id: 'polaris-lib-errors.controllers.error-title',
		defaultMessage: 'Something went wrong.',
		description: 'Flag title to communicate an error.',
	},
	permissionErrorDescription: {
		id: 'polaris-lib-errors.controllers.permission-error-description',
		defaultMessage: 'You may not have the permissions required to perform this operation.',
		description: 'Flag title to communicate an error.',
	},
	configErrorTitle: {
		id: 'polaris-lib-errors.controllers.config-error-title',
		defaultMessage: "We couldn't save that.",
		description:
			'The title shown when a configuration update fails due to data limits on the server.',
	},
	configErrorDescription: {
		id: 'polaris-lib-errors.controllers.config-error-description',
		defaultMessage:
			'This usually happens because the configuration data on this field is too big. Contact support for more details',
		description:
			'The description shown when a configuration update fails due to data limits on the server.',
	},
	serverLoadFailedDescription: {
		id: 'polaris-lib-errors.controllers.server-load-failed-description',
		defaultMessage:
			'We had difficulties to fetch some data from the server. Please refresh the page.',
		description: 'Flag description for an error occurring while loading data from the server',
	},
	serverUpdateFailedDescription: {
		id: 'polaris-lib-errors.controllers.server-update-failed-description',
		defaultMessage:
			'We had some difficulties to save some data to the server. Please try again or refresh the page.',
		description: 'Flag description for an error occurring while updating data on the server',
	},
	errorDescription: {
		id: 'polaris-lib-errors.controllers.error-description',
		defaultMessage:
			'We failed to execute your last operation. Our team has been notified. Please refresh the page to try again.',
		description: 'Flag description for a client code error.',
	},
	workflowLoadingFailedDescription: {
		id: 'polaris-lib-errors.controllers.workflow-loading-failed-description',
		defaultMessage:
			'We had some difficulties to load some workflow data from the server. Please refresh the page.',
		description: 'Flag description for an error occurring while loading the project workflow.',
	},
	errorPossibleAccessLoss: {
		id: 'polaris-lib-errors.controllers.error-possible-access-loss',
		defaultMessage: 'You may have lost access to the project or site. Please refresh the page.',
		description:
			'Flag description for situation where the user may have lost access to the site or the project',
	},
	cyclicFormulaFieldDescription: {
		id: 'polaris-lib-errors.controllers.cyclic-formula-field-description',
		defaultMessage:
			'We cannot calculate the value of some fields because they contain a cyclic formula. Please edit the field configurations and refresh: {fieldLabels}',
		description: 'Description to show to the user if a cyclic formula field is detected',
	},
	entityLimitErrorTitle: {
		id: 'polaris-lib-errors.controllers.entity-limit-error-title',
		defaultMessage: 'You’ve hit a limit',
		description: 'Flag title to communicate an entity limit error.',
	},
});

import type {
	CalendarIssueEventFields,
	CalendarScopePlan,
	CalendarViewRange,
	CalendarWeekStart,
	CalendarColorBy,
} from '@atlassian/jira-calendar/src/controllers/calendar-store/types.tsx';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import parameters from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery$parameters';
import type {
	JiraViewScopeInput,
	JiraCalendarViewConfigurationInput,
	JiraCalendarIssuesInput,
	JiraCalendarVersionsInput,
	JiraCalendarSprintsInput,
	JiraCalendarMode,
	JiraCalendarWeekStart,
	JiraCalendarCrossProjectVersionsInput,
} from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import type { RouterContext } from '@atlassian/react-resource-router';
import type { CalendarSettingsQueryParams } from './types.tsx';

const ROUTE_NAMES_SOFTWARE_PLAN_CALENDAR_EMBED = 'software-plan-calendar-embed';

type CalendarViewSettings = {
	weekStartsOn: CalendarWeekStart;
};

const SETTINGS_STORAGE_KEY = 'plans_calendar_view_settings';
function getViewSettings(key: string): CalendarViewSettings | undefined {
	try {
		const serializedValue = globalThis.localStorage?.getItem(key);

		return serializedValue !== null
			? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(JSON.parse(serializedValue) as CalendarViewSettings)
			: undefined;
	} catch {
		return undefined;
	}
}

function toPlanScenarioAri({
	siteId,
	activationId,
	planId,
	scenarioId,
}: {
	siteId: string;
	activationId: string;
	planId: string;
	scenarioId: string;
}) {
	return `ari:cloud:jira:${siteId}:plan-scenario/activation/${activationId}/${planId}/scenario/${scenarioId}`;
}

function getStorageKeyFromScope(context: RouterContext): string {
	const {
		match: {
			params: { planId, scenarioId },
		},
	} = context;
	const LOCALSTORAGE_KEY_PREFIX = 'persist:calendar';
	return `${LOCALSTORAGE_KEY_PREFIX}/plan/${planId}/scenario/${scenarioId}`;
}

function getStorageValueFromScope(key: string): string {
	const storedData = globalThis.localStorage?.getItem(key) || '';
	return storedData;
}

function toMidnightUTCString(date: Date) {
	const utcMilliseconds = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
	return new Date(utcMilliseconds).toISOString();
}

function toDate(date: string | null | undefined): Date {
	if (!date) {
		return new Date();
	}
	try {
		const _date = new Date(date);
		return new Date(_date.getFullYear(), _date.getMonth(), _date.getDate());
	} catch (e) {
		return new Date();
	}
}

function createScope({ context, tenantContext }: EntryPointRouteParams): CalendarScopePlan {
	const {
		match: {
			params: { planId, scenarioId },
		},
	} = context;
	const { cloudId, activationId } = tenantContext;

	const planScenarioAri = toPlanScenarioAri({
		siteId: cloudId,
		activationId,
		planId: String(planId),
		scenarioId: String(scenarioId),
	});

	return {
		type: 'plan',
		ids: [planScenarioAri],
	};
}

function buildScopeInput(scope: CalendarScopePlan): JiraViewScopeInput {
	return {
		ids: scope.ids,
	};
}

function buildViewId(scope: CalendarScopePlan): string {
	// plan scenario ARI is the view id
	return scope.ids?.[0] ?? '';
}

function toViewMode(mode: string): JiraCalendarMode {
	switch (mode) {
		case 'week':
			return 'WEEK';
		case 'day':
			return 'DAY';
		default:
			return 'MONTH';
	}
}

function toWeekStart(weekStart: string): JiraCalendarWeekStart {
	switch (weekStart) {
		case 'monday':
			return 'MONDAY';
		case 'saturday':
			return 'SATURDAY';
		default:
			return 'SUNDAY';
	}
}

function toCalendarWeekStart(weekStart: string): CalendarWeekStart {
	switch (weekStart) {
		case 'sunday':
			return 'sunday';
		case 'saturday':
			return 'saturday';
		default:
			return 'monday';
	}
}

function toCalendarColorBy(colorBy: string): CalendarColorBy {
	switch (colorBy) {
		case 'none':
			return 'none';
		case 'status':
			return 'status';
		default:
			if (expVal('plan_calendar_default_colorby_setting', 'isEnabled', false)) {
				return 'none';
			}
			return 'status';
	}
}

function buildConfigurationInput({
	selectedDate,
	viewRange,
	weekStartsOn,
	startDateField,
	endDateField,
	viewId,
}: {
	selectedDate: Date;
	viewRange: CalendarViewRange;
	weekStartsOn: CalendarWeekStart;
	startDateField: string;
	endDateField: string;
	viewId: string;
}): JiraCalendarViewConfigurationInput {
	return {
		date: toMidnightUTCString(selectedDate),
		mode: toViewMode(viewRange),
		weekStart: toWeekStart(weekStartsOn),
		...(fg('jira_calendar_load_more_pagination') ? { startDateField, endDateField } : {}),
		viewId,
	};
}

function buildIssuesSearchInput(
	storageKey: string,
	// remove optional attribute when cleaning up fg smart_links_for_plans
	filter?: string,
	isEmbed?: boolean,
): JiraCalendarIssuesInput {
	if (fg('smart_links_for_plans')) {
		return {
			additionalFilterQuery:
				isEmbed === true ? filter ?? '' : filter || getStorageValueFromScope(storageKey),
		};
	}
	return {
		additionalFilterQuery: getStorageValueFromScope(storageKey),
	};
}

function buildUnscheduledIssuesSearchInput(): JiraCalendarIssuesInput {
	return { additionalFilterQuery: '' };
}

function buildVersionsSearchInput(): JiraCalendarVersionsInput {
	return { versionStatuses: ['RELEASED', 'UNRELEASED'] };
}

function buildCalendarCrossProjectVersionsInput(): JiraCalendarCrossProjectVersionsInput {
	return { statuses: ['RELEASED', 'UNRELEASED'] };
}

function buildSprintsSearchInput(): JiraCalendarSprintsInput {
	return { sprintStates: ['ACTIVE', 'FUTURE', 'CLOSED'] };
}

function buildSettingsParams(
	hideWeekends: string,
	hideSprints: string,
	hideVersions: string,
	hideIssues: string,
	hideIssueKey: string,
	weekStartsOnQueryParam: string,
	colorBy: string,
): CalendarSettingsQueryParams | undefined {
	if (
		hideWeekends ||
		hideSprints ||
		hideVersions ||
		hideIssues ||
		hideIssueKey ||
		weekStartsOnQueryParam ||
		colorBy
	) {
		return {
			hideWeekends: hideWeekends === 'true' || undefined,
			hideSprints: hideSprints === 'true' || undefined,
			hideVersions: hideVersions === 'true' || undefined,
			hideIssues: hideIssues === 'true' || undefined,
			hideIssueKey: hideIssueKey === 'true' || undefined,
			weekStartsOn: toCalendarWeekStart(weekStartsOnQueryParam),
			colorBy: toCalendarColorBy(colorBy),
		};
	}
	return undefined;
}

export const calendarEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-plan-calendar-entrypoint" */ './main'),
	),
	getPreloadProps: (entryPointRouteParams: EntryPointRouteParams) => {
		const { context, tenantContext } = entryPointRouteParams;
		const { cloudId } = tenantContext;

		const scope = createScope(entryPointRouteParams);

		const scopeInput = buildScopeInput(scope);

		const {
			date,
			filter,
			hideWeekends,
			hideSprints,
			hideVersions,
			hideIssues,
			hideIssueKey,
			weekStartsOn: weekStartsOnQueryParam,
			colorBy: colorByQueryParam,
		} = context.query;
		const selectedDate = toDate(date);
		const hasFilterQueryParam = fg('smart_links_for_plans') ? Boolean(filter) : false;
		const routeName = fg('smart_links_for_plans') ? context.route.name : '';
		const settingsQueryParams = fg('smart_links_for_plans')
			? buildSettingsParams(
					hideWeekends,
					hideSprints,
					hideVersions,
					hideIssues,
					hideIssueKey,
					weekStartsOnQueryParam,
					colorByQueryParam,
				)
			: {};
		const isEmbed = fg('smart_links_for_plans')
			? routeName === ROUTE_NAMES_SOFTWARE_PLAN_CALENDAR_EMBED
			: false;
		const settings = fg('jira_calendar_load_more_pagination')
			? getViewSettings(SETTINGS_STORAGE_KEY)
			: undefined;

		const viewRange: CalendarViewRange = 'month';
		const weekStartsOn = fg('jira_calendar_load_more_pagination')
			? settingsQueryParams?.weekStartsOn ?? settings?.weekStartsOn ?? 'monday'
			: 'monday';
		const startDateField = 'startdate';
		const endDateField = 'duedate';
		const issueEventFields: CalendarIssueEventFields = {
			startDateField,
			endDateField,
		};

		const storageKey = getStorageKeyFromScope(context);
		const viewId = buildViewId(scope);
		const filterQuery = fg('smart_links_for_plans')
			? buildIssuesSearchInput(storageKey, filter, isEmbed)
			: buildIssuesSearchInput(storageKey);

		return {
			queries: {
				calendarData: {
					options: {
						fetchPolicy: 'network-only' as const,
					},
					parameters,
					variables: {
						cloudId,
						scopeInput,
						configurationInput: buildConfigurationInput({
							selectedDate,
							viewRange,
							weekStartsOn,
							startDateField,
							endDateField,
							viewId,
						}),
						issuesPageSize: 50,
						issuesSearchInput: filterQuery,
						unscheduledIssuesSearchInput: buildUnscheduledIssuesSearchInput(),
						versionsSearchInput: buildVersionsSearchInput(),
						sprintsSearchInput: buildSprintsSearchInput(),
						crossProjectVersionsSearchInput: buildCalendarCrossProjectVersionsInput(),
						viewId,
						skipSprintSearch: false,
						skipPanelIssueSearch: true,
						skipCrossProjectVersionSearch: false,
						schedulePermissionsEnabled: fg('calendar_schedule_issue_permissions_check'),
						pageLoadMoreEnabled: fg('jira_calendar_load_more_pagination'),
						skipVersionsV1Search: fg('plan-calendar-versions-v2-adoption'), // skipVersionSearch || fg_on,
						skipVersionsV2Search: !fg('plan-calendar-versions-v2-adoption'), // skipVersionSearch || fg_off
					},
				},
			},
			entryPoints: {},
			extraProps: {
				scope,
				selectedDate,
				viewRange,
				weekStartsOn,
				issueEventFields,
				storageKey,
				hasFilterQueryParam,
				routeName,
				settingsQueryParams,
				filterQuery: fg('smart_links_for_plans') ? filterQuery.additionalFilterQuery : undefined,
			},
		};
	},
});

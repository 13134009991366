type isPlanAndScenarioMatchingUrlParams = {
	urlPlanId: string | null | typeof undefined;
	urlScenarioId: string | null | typeof undefined;
	planId: number;
	scenarioId: number;
};

export const isPlanAndScenarioMatchingUrl = ({
	urlPlanId,
	urlScenarioId,
	planId,
	scenarioId,
}: isPlanAndScenarioMatchingUrlParams): boolean => {
	if (!urlPlanId || !urlScenarioId) {
		return false;
	}

	return Number(urlPlanId) === planId && Number(urlScenarioId) === scenarioId;
};

import mergeWith from 'lodash/mergeWith';
import {
	AbstractJastVisitor,
	COMPOUND_OPERATOR_AND,
	COMPOUND_OPERATOR_OR,
	type CompoundClause,
	type TerminalClause,
} from '@atlaskit/jql-ast';
import { getTextSearchInputFieldData, getTextSearchInputField } from '../common/constants.tsx';
import {
	type FieldDataMap,
	PickerTypes,
	type TextSearchInputClauseType,
	type FieldData,
} from '../common/types.tsx';
import { getPickerType } from '../controllers/picker-type/index.tsx';
import { UnsupportedRefinementError } from './unsupported-refinement-error.tsx';

// Map of field keys to their respective clauses in the Jast
export type ClauseMap = {
	[x: string]: (TerminalClause | CompoundClause)[];
};

export type Result = {
	clauseMap: ClauseMap;
	textSearchInputClause?: TextSearchInputClauseType;
};

export class JqlClauseCollectingVisitor extends AbstractJastVisitor<Result> {
	shouldValidateOperators: boolean;

	excludedFields: string[];

	fieldsData: FieldDataMap | undefined;

	simplifiedOperators: boolean;

	/**
	 *
	 * @param shouldValidateOperators {boolean} - When true, when visiting a terminal clause, will
	 * compare the clause's operator to the allowed operators for the matching field in the
	 * `fieldsData` arg
	 * @param excludedFields
	 * @param fieldsData {FieldDataMap} - This is data transformed from the hydration query. We
	 * assume all of the fields are 1:1 with those in the AST since the same JQL query was used when
	 * calling hydration query
	 * @param simplifiedOperators
	 */
	constructor(
		shouldValidateOperators = true,
		excludedFields: string[] = [],
		fieldsData?: FieldDataMap,
		simplifiedOperators = false,
	) {
		super();

		this.shouldValidateOperators = shouldValidateOperators;
		this.excludedFields = excludedFields;
		this.fieldsData = fieldsData;
		this.simplifiedOperators = simplifiedOperators;
	}

	visitNotClause(): Result {
		throw new UnsupportedRefinementError(
			'JqlClauseCollectingVisitor visited an unsupported node while traversing the AST',
		);
	}

	visitCompoundClause = (compoundClause: CompoundClause): Result => {
		const operator = compoundClause.operator.value;

		if (operator === COMPOUND_OPERATOR_AND) {
			return compoundClause.clauses.reduce<Result>(
				(result, clause) => this.aggregateResult(clause.accept(this), result),
				{ clauseMap: {} },
			);
		}

		if (operator === COMPOUND_OPERATOR_OR) {
			// The only supported OR clause is for the text search refinement. Downstream validators are responsible for
			// checking if the compound clause is valid for text search.
			// If future refinements emerge that also use OR clauses then this logic would need to change to perform some
			// type of shape matching against the clause to find the correct refinement key.
			return {
				clauseMap: {},
				textSearchInputClause: compoundClause,
			};
		}

		throw new UnsupportedRefinementError(
			`Compound clauses using the operator '${operator}' is not supported`,
		);
	};

	visitTerminalClause = (terminalClause: TerminalClause): Result => {
		const fieldName = terminalClause.field.value.toLowerCase();

		if (this.shouldValidateOperators) {
			if (this.excludedFields.includes(fieldName)) {
				throw new UnsupportedRefinementError(
					`Field with name '${fieldName}' of type ${terminalClause.clauseType} is not supported`,
				);
			}

			const operator = terminalClause.operator?.value ?? '';
			if (this.fieldsData) {
				// handling text is an edge case when nin-text-fields-new-text-search-field is off
				// we used to fallback to consts for text, project, status, type, assignee but that
				// is no longer the case since making irremovable fields configurable. Instead we
				// expect each field has hydration data instead.
				// the `text` field is known to not have hydration data when nin-text-fields-new-text-search-field
				// is disabled and so for this specific case we fall back to the TEXT const.
				// This is just temporary until nin-text-fields-new-text-search-field is cleaned up
				const textSearchInputData = getTextSearchInputFieldData();
				const fieldData: FieldData =
					fieldName === textSearchInputData.jqlTerm
						? textSearchInputData
						: this.fieldsData[fieldName];

				const { allowedOperators, pickerType } = getPickerType({
					searchTemplate: fieldData.searchTemplate,
					fieldType: fieldData.fieldType,
					operators: fieldData.operators,
					simplifiedOperators: this.simplifiedOperators,
				});

				if (pickerType !== PickerTypes.Unsupported && allowedOperators) {
					const isAllowedOperator = allowedOperators
						.map((allowedOperator) => allowedOperator.value.toLowerCase())
						.includes(operator.toLowerCase());

					if (!isAllowedOperator) {
						throw new UnsupportedRefinementError(
							`Field with value '${fieldName}' and searchTemplate '${fieldData.searchTemplate}' of type ${terminalClause.clauseType} is using an unsupported operator [${operator}]`,
						);
					}
				}
			} else {
				throw new UnsupportedRefinementError('Field data should be defined to validate operators');
			}
		}

		if (fieldName === getTextSearchInputField()) {
			return {
				clauseMap: {},
				textSearchInputClause: terminalClause,
			};
		}

		return {
			clauseMap: {
				[terminalClause.field.value.toLowerCase()]: [terminalClause],
			},
		};
	};

	protected defaultResult(): Result {
		return { clauseMap: {} };
	}

	/**
	 * @param aggregate new ClauseMap to add into nextResult
	 * @param nextResult ClauseMap with previous results
	 * @returns ClauseMap with the merged results adding the aggregate at the end
	 */
	protected aggregateResult(aggregate: Result, nextResult: Result): Result {
		if (nextResult.textSearchInputClause && aggregate.textSearchInputClause) {
			throw new UnsupportedRefinementError('Too many text search input clause clauses');
		}

		// First parameter is the object where we want to add the values and the second the new values to merge into the first object.
		return {
			clauseMap: mergeWith(nextResult.clauseMap, aggregate.clauseMap, (destValue, srcValue) =>
				srcValue.concat(destValue ?? []),
			),
			textSearchInputClause:
				aggregate.textSearchInputClause === undefined
					? nextResult.textSearchInputClause
					: aggregate.textSearchInputClause,
		};
	}
}

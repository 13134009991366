import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import type { BannerAnnouncement as BannerAnnouncementType } from './banner/BannerAnnouncement.tsx';

const BannerAnnouncement = lazyAfterPaint<typeof BannerAnnouncementType>(
	() =>
		import(
			/* webpackChunkName: "async-platform-ui-banners-announcement-v2" */ './banner-v2/BannerAnnouncement.tsx'
		).then((module) => module.BannerAnnouncement),
	{ ssr: true },
);

export const BannerAnnouncementAsync = () => (
	<Placeholder name="async-platform-ui-banners-announcement" fallback={null}>
		<BannerAnnouncement />
	</Placeholder>
);

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { AsyncKbNav } from '@atlassian/jira-horizontal-nav-jsm-knowledge-base/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskNavigationResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { knowledgeHubSearchRoute } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-routes/src/knowledgeHubSearchRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { getKnowledgeHubResources } from './common/getKnowledgeHubResources.tsx';
import ServiceDeskKnowledgeHubViewArticle, {
	LazyServicedeskKnowledgeHubViewArticle,
} from './ui/view-article/index.tsx';

export const knowledgeHubSearchRouteEntry: Route = createEntry(knowledgeHubSearchRoute, () => ({
	group: ROUTE_GROUPS_SERVICEDESK,
	component: ServiceDeskKnowledgeHubViewArticle,
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
		horizontal: AsyncKbNav,
		onlyShowHorizontalOnNav4: true,
	},
	resources: [
		...getServiceDeskNavigationResources(),
		...getKnowledgeHubResources(),
		getConsolidationStateResource(),
	],
	forPaint: [LazyAtlassianNavigation, LazyServicedeskKnowledgeHubViewArticle],
	meta: {
		reporting: {
			id: APP_NAMES.KB_VIEW_ARTICLE,
			packageName: toPackageName(APP_NAMES.KB_VIEW_ARTICLE),
			teamName: 'jsm-nebula',
		},
	},
}));

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createBoardLabel: {
		id: 'software-board-create.flyout-menu.flyout-menu-content.dropdown-item-container.create-board-label',
		defaultMessage: 'Create a {boardType} board',
		description:
			"The aria-label for the dropdown item trigger that opens the board creation modal. The {boardType} is either 'scrum' or 'kanban'.",
	},
});

import React, { useCallback, useContext, useState } from 'react';
import AddIcon from '@atlaskit/icon/utility/add';
import { MenuGroup } from '@atlaskit/menu';
import Tooltip from '@atlaskit/tooltip';
import { MODAL_ID } from '@atlassian/jira-atlassian-navigation/src/common/constants.tsx';
import { ROUTE_NAMES_DASHBOARD_EDIT } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type {
	DashboardOperationData,
	DashboardOperationResult,
} from '@atlassian/jira-common-directory-v2/src/model/dashboard-operations/index.tsx';
import CreateDashboardModal from '@atlassian/jira-create-dashboard-modal/src/async.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { CommonNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/common-nav-menu-button/main.tsx';
import { HideL1MenuItemSection } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/hide-l1-menu-item-action/index.tsx';
import { MoreNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/main.tsx';
import { useRouteByName } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller/src/index.tsx';
import { useRouterActions, generatePath } from '@atlassian/react-resource-router';
import { CREATE_DASHBOARD, MEATBALL_DROPDOWN } from '../../constants.tsx';
import { AnalyticContext } from '../../controllers/analytic-context/index.tsx';
import messages from './messages.tsx';

export function ActionButtons() {
	const { formatMessage } = useIntl();
	const [, { off: modalClose }] = useSwitchModals(MODAL_ID.DASHBOARD_CREATE);
	const [isOpen, setIsOpen] = useState(false);
	const { path } = useRouteByName(ROUTE_NAMES_DASHBOARD_EDIT) ?? {};
	const { push } = useRouterActions();
	const onSuccess = useCallback(
		(_: DashboardOperationData, { id }: DashboardOperationResult) => {
			path && push(generatePath(path, { dashboardId: id }));
			setIsOpen(false);
			modalClose();
		},
		[modalClose, path, push],
	);

	const { fireUIAnalyticForFixButton } = useContext(AnalyticContext);

	return (
		<>
			{isOpen && (
				<CreateDashboardModal
					onSuccess={onSuccess}
					onCancel={() => {
						setIsOpen(false);
						modalClose();
					}}
				/>
			)}

			<Tooltip content={formatMessage(messages.createDashboard)}>
				<CommonNavMenuButton
					icon={AddIcon}
					label={formatMessage(messages.createDashboard)}
					onClick={() => {
						fireUIAnalyticForFixButton({ itemId: CREATE_DASHBOARD });
						setIsOpen(true);
					}}
				/>
			</Tooltip>
			<MoreNavMenuButton
				onOpen={() => fireUIAnalyticForFixButton({ itemId: MEATBALL_DROPDOWN })}
				MenuItems={() => (
					<MenuGroup>
						<HideL1MenuItemSection menuId={L1_MENU_ID.DASHBOARDS} hasSeparator={false} />
					</MenuGroup>
				)}
			/>
		</>
	);
}

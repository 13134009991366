import React, { useState, useMemo, useCallback, forwardRef, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import SettingsIcon from '@atlaskit/icon/core/settings';
import ShowMoreIcon from '@atlaskit/icon/core/show-more-horizontal';
import { MenuGroup, Section, LinkItem } from '@atlaskit/menu';
import type { TriggerProps } from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import {
	type JiraProjectType,
	JIRA_PROJECT_TYPE_PRODUCT_DISCOVERY_PROJECT,
	JIRA_PROJECT_TYPE_SERVICE_DESK_PROJECT,
	convertToProjectType,
	TEAM_MANAGED_PROJECT,
	JIRA_PROJECT_TYPE_SOFTWARE_PROJECT,
	JIRA_PROJECT_TYPE_CORE_PROJECT,
	JIRA_PROJECT_TYPE_CUSTOMER_SERVICE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { ModalEntryPointContainer } from '@atlassian/jira-entry-point-modal-container/src/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useUpdateProjectsInRelayStoreContext } from '@atlassian/jira-navigation-apps-common/src/controllers/update-project-context/index.tsx';
import {
	ADD_PEOPLE,
	ADD_TO_STARRED,
	ARCHIVE_PROJECT,
	DELETE_PROJECT,
	PROJECT_SETTINGS,
	REMOVE_FROM_STARRED,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-id.tsx';
import { useProjectsAnalyticsL3 } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/controllers/use-projects-analytics-l3/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { FavoriteToggleButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/favorite-toggle-button/index.tsx';
import { removeBaseUrl } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/build-project-url/index.tsx';
import { useShouldShowArchiveProjectTrigger } from '@atlassian/jira-project-archive-trigger/src/ui/index.tsx';
import { shouldShowTrashProjectTrigger } from '@atlassian/jira-project-restore-utils/src/utils/show-trash-trigger/index.tsx';
import { getNextgenSettingsUrl } from '@atlassian/jira-project-settings-apps-common/src/urls.tsx';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { ProjectTypeTile } from '@atlassian/jira-project-type-tile/src/index.tsx';
import type { ui_navigationProjectActionMenu_ProjectActionMenu$key } from '@atlassian/jira-relay/src/__generated__/ui_navigationProjectActionMenu_ProjectActionMenu.graphql';
import { projectSettingsServicedeskDetailsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskDetailsRoute.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { generatePath } from '@atlassian/react-resource-router';
import { ProjectRestoreContext } from '../common/context/project-restore-context.tsx';
import type { ProjectPopupEntryPoint, CustomTriggerComponentType } from '../types.tsx';
import { AddPeopleButton } from './add-people-button/index.tsx';
import { ArchiveProject } from './archive-project/index.tsx';
import { DeleteProject } from './delete-project/index.tsx';
import { EntryPointContent } from './entrypoint-content/index.tsx';
import { MenuContainerOld } from './menu-container/index.tsx';
import messages from './messages.tsx';
import { SaveProjectAsTemplate } from './save-project-as-template/index.tsx';

// Remove this component when `use-relay-entrypoints-for-project-actions-menu` is cleaned up
type Props = {
	projectFragment: ui_navigationProjectActionMenu_ProjectActionMenu$key;
	isCorePremiumUserSeat?: boolean;
	additionalMenuItems?: React.ReactNode;
	withThemedTrigger?: boolean;
	onClick?: () => void;
	starred?: boolean;
	recent?: boolean;
};

const MENU_TEST_ID = 'navigation-common.ui.project-popup-menu.menu-group';

const CustomTrigger: CustomTriggerComponentType = forwardRef<HTMLButtonElement, TriggerProps>(
	({ ...props }, ref) => {
		const { formatMessage } = useIntl();
		return (
			<ThemedButton
				{...props}
				ref={ref}
				appearance="subtle"
				iconBefore={
					<ShowMoreIcon
						label={formatMessage(messages.moreActions)}
						color="currentColor"
						spacing="spacious"
					/>
				}
				testId="navigation-project-action-menu.ui.themed-button"
			/>
		);
	},
);

// Remove this component when `use-relay-entrypoints-for-project-actions-menu` is cleaned up
// @deprecated use `ProjectActionMenuNew` instead
export const ProjectActionMenu = ({
	projectFragment,
	additionalMenuItems,
	isCorePremiumUserSeat = false,
	withThemedTrigger = false,
	onClick,
}: Props) => {
	const { formatMessage } = useIntl();
	const [activeEntryPoint, setActiveEntryPoint] = useState<ProjectPopupEntryPoint | null>(null);
	const { sendMeatBallDropDownOnProjectsMenuItemAnalyticsEvent } = useProjectsAnalyticsL3();
	const setProjectToRestore = useContext(ProjectRestoreContext);
	const project = useFragment<ui_navigationProjectActionMenu_ProjectActionMenu$key>(
		graphql`
			fragment ui_navigationProjectActionMenu_ProjectActionMenu on JiraProject {
				__id
				id @required(action: THROW)
				projectId @required(action: THROW)
				projectKey: key @required(action: THROW)
				isFavourite
				projectStyle
				projectType @required(action: THROW)
				webUrl @required(action: THROW)
				status
				canEditProjectConfig: action(type: EDIT_PROJECT_CONFIG) {
					canPerform
				}
				canViewProjectConfig: action(type: VIEW_PROJECT_CONFIG) {
					canPerform
				}
			}
		`,
		projectFragment,
	);

	// safely default to false in case of GraphQL issue
	const isProjectAdmin = project?.canEditProjectConfig?.canPerform ?? false;
	const canViewProjectConfig = project?.canViewProjectConfig?.canPerform ?? false;
	const hasProjectSettings = ff('olympus-da-157-enable-project-settings-navigation_zxaq5')
		? isProjectAdmin || canViewProjectConfig
		: isProjectAdmin;

	const { deleteProject, archiveProject } = useUpdateProjectsInRelayStoreContext() || {};
	const isAdmin = useIsAdmin();

	// We need to check on whether or not the project is already archived because this impacts the available actions.
	// We still show archived projects in the sidebar, but it is not possible to archive and ALREADY archived project
	// and it is NOT possible to trash / delete an already archived project
	const isArchived = project?.status === 'ARCHIVED';

	const settingsUrl = useMemo(() => {
		switch (project.projectType) {
			case JIRA_PROJECT_TYPE_SERVICE_DESK_PROJECT:
				return generatePath(projectSettingsServicedeskDetailsRoute.path, {
					projectKey: project.projectKey,
				});
			case JIRA_PROJECT_TYPE_PRODUCT_DISCOVERY_PROJECT:
				return `/jira/polaris/projects/${project.projectKey}/ideas/settings/details`;

			case JIRA_PROJECT_TYPE_SOFTWARE_PROJECT:
			case JIRA_PROJECT_TYPE_CORE_PROJECT:
			case JIRA_PROJECT_TYPE_CUSTOMER_SERVICE_PROJECT:
				// Note: getNextgenSettingsUrl works for TMP and CMP projects
				return getNextgenSettingsUrl(convertToProjectType(project.projectType), project.projectKey);

			default:
				return project.webUrl ? `${removeBaseUrl(project.webUrl)}/settings` : undefined;
		}
	}, [project.projectType, project.webUrl, project.projectKey]);

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const projectType: JiraProjectType = project.projectType as JiraProjectType;

	const isSimplified = project.projectStyle === TEAM_MANAGED_PROJECT;
	const canAdministerProject = isProjectAdmin;
	const shouldShowArchiveProject = useShouldShowArchiveProjectTrigger({
		isAdmin,
		isSimplified,
		canAdministerProject,
		projectType: convertToProjectType(projectType),
		isCorePremiumUserSeat,
	});

	const shouldShowDeleteProject = shouldShowTrashProjectTrigger({
		isAdmin,
		isSimplified,
		canAdministerProject,
		projectType: convertToProjectType(projectType),
	});

	const shouldShowSaveAsTemplate =
		project.projectType === JIRA_PROJECT_TYPE_SOFTWARE_PROJECT &&
		isAdmin &&
		!isSimplified &&
		fg('jira_custom_templates_ux');

	const MenuItems = useCallback(
		() => (
			<MenuGroup testId={MENU_TEST_ID}>
				<Section isList>
					<FavoriteToggleButton
						onClick={() =>
							sendMeatBallDropDownOnProjectsMenuItemAnalyticsEvent({
								starred: project.isFavourite ?? false,
								recent: !project.isFavourite,
								projectId: project.projectId,
								itemId: project.isFavourite ? REMOVE_FROM_STARRED : ADD_TO_STARRED,
								projectType: project.projectType,
							})
						}
						entityId={project.id}
						defaultIsStarred={Boolean(project.isFavourite)}
						entityTypeName="JiraProject"
					/>
					{(isAdmin || isProjectAdmin) &&
						// JPD has custom project roles and permissions and JPD needs to implement custom dialog for adding people
						project.projectType !== JIRA_PROJECT_TYPE_PRODUCT_DISCOVERY_PROJECT && (
							<AddPeopleButton
								onClick={() => {
									sendMeatBallDropDownOnProjectsMenuItemAnalyticsEvent({
										starred: project.isFavourite ?? false,
										recent: !project.isFavourite,
										projectId: project.projectId,
										itemId: ADD_PEOPLE,
										projectType: project.projectType,
									});
								}}
								isAdmin={isAdmin}
								projectId={Number(project.projectId)}
								projectKey={project.projectKey}
								projectType={projectType}
							/>
						)}

					{shouldShowSaveAsTemplate && <SaveProjectAsTemplate />}

					{!isArchived && (isAdmin || hasProjectSettings) && settingsUrl && (
						<LinkItem
							onClick={() => {
								sendMeatBallDropDownOnProjectsMenuItemAnalyticsEvent({
									starred: project.isFavourite ?? false,
									recent: !project.isFavourite,
									projectId: project.projectId,
									itemId: PROJECT_SETTINGS,
									projectType: project.projectType,
								});
							}}
							href={settingsUrl}
							iconBefore={<SettingsIcon label="" spacing="spacious" color={token('color.icon')} />}
						>
							{formatMessage(messages.projectSettings)}
						</LinkItem>
					)}
					{additionalMenuItems}
				</Section>
				{!isArchived &&
					(isAdmin || isProjectAdmin) &&
					(shouldShowArchiveProject || shouldShowDeleteProject) && (
						<Section isList hasSeparator>
							<ArchiveProject
								onClick={() => {
									sendMeatBallDropDownOnProjectsMenuItemAnalyticsEvent({
										starred: project.isFavourite ?? false,
										recent: !project.isFavourite,
										projectId: project.projectId,
										itemId: ARCHIVE_PROJECT,
										projectType: project.projectType,
									});
								}}
								projectId={project.id}
								jiraProjectStyle={project.projectStyle}
								jiraProjectType={projectType}
								isAdmin={isAdmin}
								canAdministerProject={isProjectAdmin}
								setActiveEntryPoint={setActiveEntryPoint}
								setProjectToRestore={setProjectToRestore}
								isCorePremiumUserSeat={isCorePremiumUserSeat}
								onArchiveSuccess={() => archiveProject?.(project.__id)}
							/>
							<DeleteProject
								onClick={() => {
									sendMeatBallDropDownOnProjectsMenuItemAnalyticsEvent({
										starred: project.isFavourite ?? false,
										recent: !project.isFavourite,
										projectId: project.projectId,
										itemId: DELETE_PROJECT,
										projectType: project.projectType,
									});
								}}
								projectId={project.id}
								jiraProjectStyle={project?.projectStyle}
								jiraProjectType={projectType}
								isAdmin={isAdmin}
								canAdministerProject={isProjectAdmin}
								setActiveEntryPoint={setActiveEntryPoint}
								setProjectToRestore={setProjectToRestore}
								onDeleteSuccess={() => deleteProject?.(project.__id)}
							/>
						</Section>
					)}
				<ProjectTypeTile
					// @ts-expect-error TS2322: Type '%future added value' is not assignable to type JiraProjectType
					projectType={project.projectType}
					// @ts-expect-error TS2322: Type '%future added value' is not assignable to type JiraProjectStyle
					projectStyle={project?.projectStyle}
				/>
			</MenuGroup>
		),
		[
			project.id,
			project.isFavourite,
			project.projectType,
			project.projectId,
			project.projectKey,
			project.projectStyle,
			project.__id,
			isAdmin,
			isProjectAdmin,
			projectType,
			isArchived,
			hasProjectSettings,
			settingsUrl,
			formatMessage,
			additionalMenuItems,
			setProjectToRestore,
			isCorePremiumUserSeat,
			sendMeatBallDropDownOnProjectsMenuItemAnalyticsEvent,
			shouldShowArchiveProject,
			shouldShowDeleteProject,
			shouldShowSaveAsTemplate,
			archiveProject,
			deleteProject,
		],
	);

	return (
		<>
			<MenuContainerOld
				MenuItems={MenuItems}
				onClick={onClick}
				CustomTrigger={withThemedTrigger && getWillShowNav4() ? CustomTrigger : undefined}
			/>
			{activeEntryPoint !== null && (
				<ModalEntryPointContainer
					{...activeEntryPoint}
					id={project.id}
					packageName="jira-navigation-apps-project-action-menu"
				/>
			)}
		</>
	);
};

// Rename this to Props when `use-relay-entrypoints-for-project-actions-menu` is cleaned up

type PropsNew = {
	projectKey: string;
	isCorePremiumUserSeat?: boolean;
	additionalMenuItems?: React.ReactNode;
	withThemedTrigger?: boolean;
	onClick?: () => void;
	starred?: boolean;
	recent?: boolean;
};

// Rename this to ProjectActionMenu when `use-relay-entrypoints-for-project-actions-menu` is cleaned up
export const ProjectActionMenuNew = ({
	additionalMenuItems,
	isCorePremiumUserSeat = false,
	withThemedTrigger = false,
	projectKey,
	onClick,
}: PropsNew) => {
	return (
		<EntryPointContent
			additionalMenuItems={additionalMenuItems}
			onClick={onClick}
			isCorePremiumUserSeat={isCorePremiumUserSeat}
			CustomTrigger={withThemedTrigger && getWillShowNav4() ? CustomTrigger : undefined}
			projectKey={projectKey}
		/>
	);
};

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout/src/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { newIssueViewLockInStatusResource } from '@atlassian/jira-router-resources-classic-projects/src/services/new-issue-view-lock-in-status/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskReportsSatisfactionIssueRoute } from '@atlassian/jira-router-routes-servicedesk-reports-routes/src/servicedeskReportsSatisfactionIssueRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import ReportsIssueView, { LazyServicedeskReportsIssueView } from './ui/issue-view/index.tsx';

export const servicedeskReportsSatisfactionIssueRouteEntry: Route = createEntry(
	servicedeskReportsSatisfactionIssueRoute,
	() => ({
		group: ROUTE_GROUPS_SERVICEDESK,
		component: ReportsIssueView,
		layout: composeLayouts(serviceProjectLayout, embeddedIssuePreloadLayoutBuilder()),
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},
		resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			newIssueViewLockInStatusResource,
			getConsolidationStateResource(),
		],
		afterPaint: [LazyIssueApp],
		forPaint: [LazyAtlassianNavigation, LazyServicedeskReportsIssueView],
		ufoName: 'service-management.reports.issue',
	}),
);

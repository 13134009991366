import { useMemo } from 'react';
import type { DataState } from '@atlassian/jira-common-services/src/types.tsx';
// eslint-disable-next-line jira/import-whitelist
import { useResourceWithCustomRouterContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/use-resource-with-custom-router-context/index.tsx';
import { getProjectKeyOrId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-or-id/index.tsx';
import { createResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_SERVICEDESK_IS_ITSM_PROJECT } from '../constants.tsx';

const getDataLoader = () =>
	import(
		/* webpackChunkName: "async-resource-servicedesk-is-itsm-project", jiraSpaEntry: "async-resource-servicedesk-is-itsm-project" */ './get-data'
	);

const ONE_DAY_IN_MILLIS = 24 * 60 * 60 * 1000;

export const servicedeskIsItsmProjectResource = createResource<boolean>({
	type: RESOURCE_TYPE_SERVICEDESK_IS_ITSM_PROJECT,
	getKey: ({ match }) => getProjectKeyOrId(match),
	getDataLoader,
	maxAge: ONE_DAY_IN_MILLIS,
});

export const useIsItsmTemplateProject = (projectKey?: string): DataState<boolean> => {
	const { data, loading, error } = useResourceWithCustomRouterContext(
		servicedeskIsItsmProjectResource,
		projectKey ? { matchParams: { projectKey } } : undefined,
		{
			shouldPrefetch: true,
		},
	);

	return useMemo(
		() => ({
			data,
			loading,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			error: error as Error,
		}),
		[data, loading, error],
	);
};

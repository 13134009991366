import camelCase from 'lodash/camelCase';
import {
	ROUTE_NAMES_SOFTWARE_INCIDENTS_CLASSIC,
	ROUTE_GROUPS_CLASSIC_SOFTWARE,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticSoftwareProjectHorizontalNavResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/software-horizontal-navigation/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import {
	incidentExperimentTraitResource,
	incidentsInitialPageChecksResource,
	incidentsCustomFieldIdResource,
} from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { softwareIncidentsClassicRoute } from '@atlassian/jira-router-routes-incidents-routes/src/softwareIncidentsClassicRoute.tsx';
import { IncidentsPageSkeleton } from '@atlassian/jira-skeletons/src/ui/incidents-page/IncidentsPageSkeleton.tsx';
import { AsyncHorizontalNavJiraSoftware as AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { classicSoftwareProjectLayout } from '@atlassian/jira-software-project-layout/src/index.tsx';
import { entryPoint } from './common/entryPoint.tsx';
import { entryPointDeprecated } from './common/entryPointDeprecated.tsx';

export const softwareIncidentsClassicRouteEntry = createEntry(
	softwareIncidentsClassicRoute,
	() => ({
		group: ROUTE_GROUPS_CLASSIC_SOFTWARE,
		entryPoint() {
			return fg('corex_operandi_incidents_page_in_jira') ? entryPoint : entryPointDeprecated;
		},
		layout: classicSoftwareProjectLayout,
		skeleton: IncidentsPageSkeleton,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			horizontal: AsyncHorizontalNavJira,
			onlyShowHorizontalOnNav4: true,
		},
		resources: [
			...getNavigationResources(),
			// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
			incidentExperimentTraitResource,
			projectContextResource,
			forgeProjectModuleResource,
			staticSidebarShortcutsResource,
			staticProjectSidebarNavigationResource,
			staticProjectSidebarConnectAddonsNavigationResource,
			staticSoftwareProjectHorizontalNavResource,
			// end of resources expanded from getNavigationSidebarProjectResource
			projectContextResource,
			incidentsInitialPageChecksResource,
			incidentsCustomFieldIdResource,
		],
		meta: {
			reporting: {
				id: ROUTE_NAMES_SOFTWARE_INCIDENTS_CLASSIC,
				packageName: camelCase(ROUTE_NAMES_SOFTWARE_INCIDENTS_CLASSIC),
				teamName: 'operandi',
			},
		},
		ufoName: 'software-incidents',
	}),
);

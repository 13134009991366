import React from 'react';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyMaintenance = lazyForPaint<any>(
	() =>
		import(
			/* webpackChunkName: "async-jira-eoc-maintenance", jiraSpaEntry: "async-jira-eoc-maintenance" */ '@atlassian/jira-eoc-maintenance'
		),
);

export const MaintenanceComponent = () => (
	<LazyPage Page={LazyMaintenance} pageId="async-jira-eoc-maintenance" shouldShowSpinner={false} />
);

import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { PersonalizationApiTrait } from '../../../../common/types.tsx';

export const fetchUserTraits = async (
	cloudId: string,
	accountId: string,
): Promise<PersonalizationApiTrait[]> => {
	try {
		const url = `/gateway/api/tap-delivery/api/v3/personalization/site/${cloudId}/user/${accountId}`;
		const response = await performGetRequest(url);
		if (response?.attributes) {
			return response.attributes;
		}
		throw Error('response is not defined or null');
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		// TODO:  error analytics
	}
	return [];
};

export const fetchSiteTraits = async (cloudId: string): Promise<PersonalizationApiTrait[]> => {
	try {
		const url = `/gateway/api/tap-delivery/api/v3/personalization/site/${cloudId}`;
		const response = await performGetRequest(url);
		if (response?.attributes) {
			return response.attributes;
		}
		throw Error('response is not defined or null');
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		// TODO:  error analytics
	}
	return [];
};

import React, { useCallback } from 'react';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useRightSidebarController } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller/index.tsx';
import { createStore, createHook, createContainer } from '@atlassian/react-sweet-state';
import { EMBEDDED_CONFLUENCE_SIDEPANEL_CONTAINER_ID } from '../../constants.tsx';
import { actions, initialState } from './actions.tsx';
import {
	DisplayTypes,
	type OpenSidePanelCoreParams,
	type EmbeddedConfluenceState,
	type UseEmbeddedConfluenceSidePanelType,
} from './types.tsx';
import { getDisplayInformation } from './utils.tsx';

export type Actions = typeof actions;

/**
 * Container for {@link useEmbeddedConfluenceSidePanel}. Any component that uses this hook
 * must be wrapped in this container.
 */
const Container = createContainer();

const DefaultStore = createStore<EmbeddedConfluenceState, Actions>({
	actions,
	containedBy: Container,
	initialState,
	name: EMBEDDED_CONFLUENCE_SIDEPANEL_CONTAINER_ID,
});
const NonContainerStore = createStore<EmbeddedConfluenceState, Actions>({
	actions,
	initialState,
	name: EMBEDDED_CONFLUENCE_SIDEPANEL_CONTAINER_ID,
});

export const EmbeddedConfluenceSidepanelContainer = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const issueKey = useIssueKey();

	/*
	In order for each usage of the store to manage the same state irregardless of
	their location in the component tree, we need to wrap components in a container
	with the same scope value. This is why we use the issueKey in the scope value.
	see https://atlassian.github.io/react-sweet-state/#/advanced/container?id=scoped-data-sharing
	for more information about scoped data sharing.

	*/
	return <Container scope={`embedded-confluence-sidepanel-${issueKey}`}>{children}</Container>;
};

const defaultStoreHook = createHook(DefaultStore);
const nonContainerStoreHook = createHook(NonContainerStore);

export const useEmbeddedConfluenceSidePanel: UseEmbeddedConfluenceSidePanelType = (
	embeddedConfluenceSource?: string,
) => {
	/*
		The condition below is a temporary workaround for the Side Panel experiment to avoid
		throwing an error when the Store is not contained by a container. This logic should be removed
		once the experiment has been productionized: https://console.statsig.com/LqivKg6ADZZaGczRfBKfX/gates/jira_issue_view_side_by_side_modeless_ep
	*/
	const [embedConfluenceSidepanelConfig] = UNSAFE_noExposureExp(
		'jira_issue_view_side_by_side_modeless_ep_exp',
	);
	const isInExperiment =
		embedConfluenceSidepanelConfig.get('cohort', 'not-enrolled') === 'experiment';
	const [
		state,
		{
			closeSidePanel: closeSidePanelAction,
			openSidePanel: openSidePanelAction,
			setIsLoadingForDeferSpaceSelection,
			setDeferredSpaceData,
			toggleExpandedState,
			setIsDraftState,
			setConfluenceData,
		},
	] = isInExperiment ? defaultStoreHook() : nonContainerStoreHook();
	const { closeRightSidebar, openRightSidebar } = useRightSidebarController();

	const openSidePanel = useCallback(
		({
			contentId,
			contentType,
			data,
			hostname,
			isDraft,
			isLive,
			onLinkPage,
			onPublish,
			openInModal,
			mode,
			url,
			title,
		}: OpenSidePanelCoreParams) => {
			const { initialWidth, displayType } = getDisplayInformation();
			const shouldOpenPanel = displayType === DisplayTypes.PANEL;

			if (shouldOpenPanel) {
				openSidePanelAction({
					contentId,
					contentType,
					embeddedConfluenceSource,
					data,
					displayType,
					initialWidth,
					hostname,
					isDraft,
					isLive,
					mode,
					onLinkPage,
					onPublish,
					shouldOpenModal: !shouldOpenPanel,
					url,
					title,
				});
				openRightSidebar(EMBEDDED_CONFLUENCE_SIDEPANEL_CONTAINER_ID, initialWidth, false, true);
			} else {
				openInModal();
			}
		},
		[embeddedConfluenceSource, openRightSidebar, openSidePanelAction],
	);

	const closeSidePanel = useCallback(() => {
		closeRightSidebar(EMBEDDED_CONFLUENCE_SIDEPANEL_CONTAINER_ID);
		closeSidePanelAction();
	}, [closeRightSidebar, closeSidePanelAction]);

	return [
		state,
		{
			openSidePanel,
			closeSidePanel,
			setDeferredSpaceData,
			setIsLoadingForDeferSpaceSelection,
			toggleExpandedState,
			setIsDraftState,
			setConfluenceData,
		},
	];
};

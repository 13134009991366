/**
 * @generated SignedSource<<ba1202ae9d9ddd800692f7f3fae92a37>>
 * @relayHash 4380d03be0e718d60f165b220843c5da
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6fd554c3bacb4e9dee815384c9fca26095e32f9695052103f2c322b574935e15

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraSoftwareListQuery } from './src_jiraSoftwareListQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraSoftwareListQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "6fd554c3bacb4e9dee815384c9fca26095e32f9695052103f2c322b574935e15",
    "metadata": {},
    "name": "src_jiraSoftwareListQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { PACKAGE_NAME, PACKAGE_TEAM_NAME } from '../../common/constants.tsx';
import type { Attribute, SessionStorage } from './types.tsx';
import { crossProjectBoardTraitSessionStorageProvider } from './utils.tsx';

const CROSS_PROJECT_BOARD_TO_PLAN_UPSELL_TRAIT = 'segment_jsw_cross_project_board_trait_siteuser';

const TAP_DELIVERY_PROXY_ENDPOINT = '/gateway/api/tap-delivery/api/v3/personalization';

const ONE_DAY = 24 * 60 * 60 * 1000;

export const fetchPersonalization = async (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	siteId: string,
	userId: string,
): Promise<{ attributes: Attribute[] }> => {
	try {
		const url = `${TAP_DELIVERY_PROXY_ENDPOINT}/site/${siteId}/user/${userId}`;
		const result = await performGetRequest(url);

		const analyticsEvent = createAnalyticsEvent({});
		fireOperationalAnalytics(analyticsEvent, 'getJSWCrossProjectBoardTrait personalizationApi', {
			synthetic: fg('is_synthetic_tenant'),
		});
		return result;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			error,
			meta: {
				id: 'quickstartApiError',
				packageName: PACKAGE_NAME,
				teamName: PACKAGE_TEAM_NAME,
			},
			attributes: {
				error: error.message,
				endpointSource: 'getJSWCrossProjectBoardTrait',
				synthetic: fg('is_synthetic_tenant'),
			},
			sendToPrivacyUnsafeSplunk: true,
		});
		return { attributes: [] };
	}
};

export const getCrossProjectBoardTrait = async (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	siteId: string,
	userId: string,
): Promise<Attribute | undefined> => {
	const { get, persist } = crossProjectBoardTraitSessionStorageProvider();

	// Check session storage
	const sessionStorage: SessionStorage = get();
	if (
		sessionStorage &&
		userId === sessionStorage.userId &&
		siteId === sessionStorage.siteId &&
		Date.now() - sessionStorage.date < ONE_DAY
	) {
		const analyticsEvent = createAnalyticsEvent({});
		fireOperationalAnalytics(analyticsEvent, 'getJSWCrossProjectBoardTrait sessionStorage', {
			synthetic: fg('is_synthetic_tenant'),
		});

		return sessionStorage.attribute;
	}

	// Fetch from personalization
	const personalization = await fetchPersonalization(createAnalyticsEvent, siteId, userId);
	const attribute: Attribute | undefined = personalization.attributes.find(
		(att) => att.name === CROSS_PROJECT_BOARD_TO_PLAN_UPSELL_TRAIT,
	);
	if (attribute !== undefined) {
		persist({ attribute, date: Date.now(), userId, siteId });
	}
	return attribute;
};

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { getServicedeskPageContextResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskCmdbAssetsDefaultRedirectRoute } from '@atlassian/jira-router-routes-servicedesk-cmdb-routes/src/servicedeskCmdbAssetsDefaultRedirectRoute.tsx';
import {
	toPackageName,
	APP_NAMES,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import { workspaceResource } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/services/resources/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { sharedRouteProps } from './common/constants.tsx';
import {
	AssetsReportingDefaultRedirect,
	AssetsReportsNav4HomePage,
	LazyServicedeskCmdbDefaultReportsRedirect,
	LazyServicedeskCmdbAssetsReportsNav4HomePage,
} from './ui/reports/index.tsx';

export const servicedeskCmdbAssetsReportsRouteEntry: Route = createEntry(
	servicedeskCmdbAssetsDefaultRedirectRoute,
	() => ({
		...sharedRouteProps,
		component: componentWithCondition(
			getWillShowNav4,
			AssetsReportsNav4HomePage,
			AssetsReportingDefaultRedirect,
		),
		resources: [
			...getNavigationResources(),
			...getServicedeskPageContextResources(),
			workspaceResource,
		],
		forPaint: [
			LazyAtlassianNavigation,
			LazyServicedeskCmdbDefaultReportsRedirect,
			LazyServicedeskCmdbAssetsReportsNav4HomePage,
		],
		meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_ASSETS_REPORTS_DEFAULT_REDIRECT,
				packageName: toPackageName(APP_NAMES.INSIGHT_ASSETS_REPORTS_DEFAULT_REDIRECT),
				teamName: 'ITSM SAGA',
			},
		},
	}),
);

/**
 * @generated SignedSource<<4c21586a3492cb3ed09327f8fd2de9ba>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4ProjectsForLanding$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4ProjectsContent">;
  readonly " $fragmentType": "Nav4ProjectsForLanding";
};
export type Nav4ProjectsForLanding$key = {
  readonly " $data"?: Nav4ProjectsForLanding$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4ProjectsForLanding">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "Nav4ProjectsForLanding",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "Nav4ProjectsContent"
    }
  ],
  "type": "Query"
};

(node as any).hash = "4d3bdf6d5d5d912aac0901d592694d15";

export default node;

import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { serverRenderedRightSidebar } from '@atlassian/jira-growth-recommendations-in-project-list/src/entrypoint.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { projectsDirectoryEntryPoint } from '@atlassian/jira-spa-apps-directories-projects-v3/entrypoint.tsx';

export const ProjectsDirectoryPageEntryPoint = createPageEntryPoint({
	main: projectsDirectoryEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	rightSidebar: serverRenderedRightSidebar,
});

import {
	type TerminalClause,
	JastBuilder,
	type Clause,
	CLAUSE_TYPE_TERMINAL,
} from '@atlaskit/jql-ast';
import {
	CMDB_FIELD_SEARCH_TEMPLATE,
	CASCADE_SELECT_FIELD_SEARCH_TEMPLATE,
	dateSearchTemplates,
	NUMBER_RANGE_FIELD_SEARCH_TEMPLATE,
	NUMBER_RANGE_PICKER_ALLOWED_OPERATORS,
} from '../../common/constants.tsx';
import type { HydrationValues } from '../../common/types.tsx';
import TextFieldType from '../../controllers/field-type/text/index.tsx';
import { findUnambiguousCascadeFieldValue } from '../get-cascade-field-value/index.tsx';
import { isCmdbClauseTooComplexForBasicMode } from '../is-cmdb-clause-too-complex.tsx';
import { JqlClauseCollectingVisitor } from '../jql-clause-collecting-visitor.tsx';
import { getUnsupportedOperators } from './constants.tsx';
import type { IsQueryTooComplexType } from './type.tsx';

const haveKnownUnsupportedOperators = (clauses: Clause[]): boolean =>
	clauses.some(
		(clause) =>
			clause.clauseType === CLAUSE_TYPE_TERMINAL &&
			clause.operator &&
			getUnsupportedOperators().includes(clause.operator.value),
	);

const isClauseTooComplex = ({
	clauses,
	searchTemplate,
	hydratedValues,
}: {
	clauses: Clause[];
	searchTemplate: string | undefined;
	hydratedValues: HydrationValues;
}) => {
	// Date
	// Or if we don't know the value while not hydrated and can show basic picker
	if (!searchTemplate || dateSearchTemplates.has(searchTemplate)) {
		return clauses.length > 2 || haveKnownUnsupportedOperators(clauses);
	}

	// Cascading Select
	if (searchTemplate === CASCADE_SELECT_FIELD_SEARCH_TEMPLATE) {
		const unambiguousCascadeFieldValue = findUnambiguousCascadeFieldValue({
			clause: clauses[0],
			hydratedValues,
		});
		return clauses.length !== 1 || unambiguousCascadeFieldValue === undefined;
	}

	// Number Range & Work Ratio
	if (searchTemplate === NUMBER_RANGE_FIELD_SEARCH_TEMPLATE || searchTemplate === 'workratio') {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const operatorValues = (clauses as TerminalClause[]).map((clause) =>
			clause?.operator?.value.toString(),
		);

		return (
			clauses.length > 2 ||
			(clauses.length === 2 &&
				!NUMBER_RANGE_PICKER_ALLOWED_OPERATORS.every((op) => operatorValues.includes(op)))
		);
	}

	// CMDB/AQL
	if (searchTemplate === CMDB_FIELD_SEARCH_TEMPLATE) {
		return clauses.length !== 1 || isCmdbClauseTooComplexForBasicMode(clauses[0]);
	}

	// Default
	return clauses.length > 1;
};

export const isQueryTooComplex = ({
	jql,
	excludedFields = [],
	fieldsData,
	shouldValidateOperators = true,
	simplifiedOperators = false,
	hideTextSearchInput = false,
}: IsQueryTooComplexType): boolean => {
	const jast = new JastBuilder().build(jql);
	if (jast.errors.length) {
		return true;
	}

	try {
		const jqlClauseCollectingVisitor = new JqlClauseCollectingVisitor(
			shouldValidateOperators,
			excludedFields,
			fieldsData,
			simplifiedOperators,
		);
		const result = jast.query && jast.query.accept(jqlClauseCollectingVisitor);

		if (!result) {
			return false;
		}

		return (
			// Check if text search input clause is hidden
			(result.textSearchInputClause && hideTextSearchInput) ||
			// Check if text search input clause is invalid
			!TextFieldType.validate(result.textSearchInputClause) ||
			// Check if any clause is too complex
			Object.keys(result.clauseMap).some((key: string) => {
				const clauses = result.clauseMap[key];
				const searchTemplate = fieldsData?.[key]?.searchTemplate;
				return isClauseTooComplex({
					clauses,
					searchTemplate,
					hydratedValues: fieldsData?.[key]?.hydratedValues,
				});
			})
		);
	} catch (error: unknown) {
		return true;
	}
};

import { useCallback, useMemo } from 'react';
import {
	DROPDOWN_ITEM,
	BUTTON,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import {
	CUSTOM_ITEM_DROPDOWN_ACTION,
	CUSTOM_ITEM_BUTTON,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { MEATBALL_DROPDOWN } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-id.tsx';
import {
	PROJECT,
	BOARD,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-type.tsx';
import { LEVEL_THREE } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type {
	BoardMeatballDropdownMenuItemParams,
	ProjectMeatballDropdownMenuItemParams,
} from '../types.tsx';
import { getSectionByStarredOrRecent } from '../utils.tsx';

export const useProjectsAnalyticsL3 = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const commonAttributes = useMemo(
		() => ({
			level: LEVEL_THREE,
		}),
		[],
	);

	// actions inside meatball menu on projects
	const sendMeatBallDropDownOnProjectsMenuItemAnalyticsEvent = useCallback(
		({
			itemId,
			recent,
			starred,
			projectId,
			projectType,
		}: ProjectMeatballDropdownMenuItemParams) => {
			const event = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: DROPDOWN_ITEM,
			});

			const attributes = {
				...commonAttributes,
				componentType: CUSTOM_ITEM_DROPDOWN_ACTION,
				itemId,
				parentItemId: projectId,
				parentItemType: PROJECT,
				projectType,
				section: getSectionByStarredOrRecent({ starred, recent }),
			};
			fireUIAnalytics(event, 'projectsNavigationMenuItem', attributes);
		},
		[commonAttributes, createAnalyticsEvent],
	);

	// meatball menu on boards
	const sendMeatBallDropDownOnBoardsAnalyticsEvent = useCallback(
		({ recent, starred, boardId, projectId, projectType }: BoardMeatballDropdownMenuItemParams) => {
			const event = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: BUTTON,
			});

			const attributes = {
				...commonAttributes,
				componentType: CUSTOM_ITEM_BUTTON,
				itemId: MEATBALL_DROPDOWN,
				projectId,
				parentItemId: boardId,
				parentItemType: BOARD,
				projectType,
				section: getSectionByStarredOrRecent({ starred, recent }),
			};
			fireUIAnalytics(event, 'projectsNavigationMenuItem', attributes);
		},
		[commonAttributes, createAnalyticsEvent],
	);

	return {
		sendMeatBallDropDownOnProjectsMenuItemAnalyticsEvent,
		sendMeatBallDropDownOnBoardsAnalyticsEvent,
	};
};

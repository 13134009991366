import {
	type OperatorValue,
	type Operator,
	OPERATOR_EQUALS,
	OPERATOR_NOT_EQUALS,
	OPERATOR_IN,
	OPERATOR_NOT_IN,
	OPERATOR_LIKE,
	OPERATOR_LT_EQUALS,
	OPERATOR_GT_EQUALS,
	creators,
	OPERATOR_IS,
	OPERATOR_IS_NOT,
} from '@atlaskit/jql-ast';
import { NUMBER_RANGE_PICKER_ALLOWED_OPERATORS } from '../../../common/constants.tsx';
import {
	type PropTypes,
	PickerTypes,
	TextAreaPropTypes,
	GenericPropTypes,
} from '../../../common/types.tsx';
import { isTextAreaPropType } from '../../../common/utils.tsx';

const SIMPLIFIED_PICKER_ALLOWED_OPERATORS = [
	creators.operator(OPERATOR_EQUALS),
	creators.operator(OPERATOR_IN),
];

const PICKER_ALLOWED_OPERATORS = [
	creators.operator(OPERATOR_EQUALS),
	creators.operator(OPERATOR_NOT_EQUALS),
	creators.operator(OPERATOR_IN),
	creators.operator(OPERATOR_NOT_IN),
	creators.operator(OPERATOR_IS),
	creators.operator(OPERATOR_IS_NOT),
];

const TEXT_PICKER_ALLOWED_OPERATORS = [creators.operator(OPERATOR_LIKE)];

export const getTextAreaAllowedOperators = (propType: PropTypes): Operator[] => {
	if (!isTextAreaPropType(propType)) {
		throw new Error(`Unsupported TextAreaPropType: ${propType}`);
	}

	switch (propType) {
		case TextAreaPropTypes.Number:
		case TextAreaPropTypes.ExactText:
			return [creators.operator(OPERATOR_EQUALS)];
		case TextAreaPropTypes.Text:
			return [creators.operator(OPERATOR_LIKE)];
		case TextAreaPropTypes.Aql:
			return [creators.operator(OPERATOR_IN)];
		default:
			throw new Error(`Unsupported TextAreaPropType: ${propType}`);
	}
};

const DATE_PICKER_ALLOWED_OPERATORS = [
	creators.operator(OPERATOR_LT_EQUALS),
	creators.operator(OPERATOR_GT_EQUALS),
];

const CASCADE_SELECT_PICKER_ALLOWED_OPERATORS = [
	creators.operator(OPERATOR_IN),
	creators.operator(OPERATOR_EQUALS),
];

const BOOLEAN_PICKER_ALLOWED_OPERATORS = [creators.operator(OPERATOR_EQUALS)];

const getPickerOperators = (fieldOperators: OperatorValue[]): Operator[] =>
	PICKER_ALLOWED_OPERATORS.filter(
		(allowedOperator) =>
			fieldOperators.findIndex((fieldOperator) => allowedOperator.value === fieldOperator) >= 0,
	);

export const getAllowedOperators = ({
	pickerType,
	fieldOperators = [],
	propType = GenericPropTypes.NoProp,
	simplifiedOperators,
}: {
	pickerType: PickerTypes;
	fieldOperators?: OperatorValue[];
	propType: PropTypes;
	simplifiedOperators: boolean;
}): Operator[] => {
	switch (pickerType) {
		case PickerTypes.Text:
			return TEXT_PICKER_ALLOWED_OPERATORS;
		case PickerTypes.TextArea:
			return getTextAreaAllowedOperators(propType);
		case PickerTypes.DateTime:
		case PickerTypes.DueDate:
		case PickerTypes.DateTimeRange:
			return DATE_PICKER_ALLOWED_OPERATORS;
		case PickerTypes.Picker:
			return !simplifiedOperators
				? getPickerOperators(fieldOperators)
				: SIMPLIFIED_PICKER_ALLOWED_OPERATORS;
		case PickerTypes.CascadeSelect:
			return CASCADE_SELECT_PICKER_ALLOWED_OPERATORS;
		case PickerTypes.NumberRange:
			return NUMBER_RANGE_PICKER_ALLOWED_OPERATORS.map((op) => creators.operator(op));
		case PickerTypes.BooleanType:
			return BOOLEAN_PICKER_ALLOWED_OPERATORS;
		default:
			return [];
	}
};

import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import {
	getFilter,
	getAssociatedBoards,
	getAssociatedUsers,
	getAssociatedGroups,
} from '../../state/selectors.tsx';
import type { State } from '../../state/types.tsx';
import Content from './view.tsx';

const mapStateToProps = (state: State) => ({
	filter: getFilter(state),
	boards: getAssociatedBoards(state),
	users: getAssociatedUsers(state),
	groups: getAssociatedGroups(state),
});

export default connect(mapStateToProps, {})(Content);

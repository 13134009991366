const PROJECT_FRAGMENT = `
fragment ProjectFragment on JiraProject {
  key
  projectId
  avatar {
    large
  }
  name
  projectType
  status
  simplified
  favorite
  assignIssues: action(type: ASSIGN_ISSUES) {
    canPerform
  }
  administerProject: action(type: EDIT_PROJECT_CONFIG) {
    canPerform
  }
  createIssues: action(type: CREATE_ISSUES) {
    canPerform
  }
  deleteIssues: action(type: DELETE_ISSUES) {
    canPerform
  }
  editIssues: action(type: EDIT_ISSUES) {
    canPerform
  }
  linkIssues: action(type: LINK_ISSUES) {
    canPerform
  }
  scheduleIssues: action(type: SCHEDULE_ISSUES) {
    canPerform
  }
  transitionIssues: action(type: TRANSITION_ISSUES) {
    canPerform
  }
  viewIssues: action(type: VIEW_ISSUES) {
    canPerform
  }
}
`;

export const QUERY = `
query JiraBusinessProjectDetails($cloudId: ID!, $projectIdOrKey: String!, $usingProjectKey: Boolean!, $projectId: ID!) {
  jira {
    mediaClientId(cloudId: $cloudId)
    mediaExternalEndpointUrl(cloudId: $cloudId)
  }
  jwmSidebar(cloudId: $cloudId, projectIdOrKey: $projectIdOrKey) {
    jiraProjectByKey(cloudId: $cloudId, key: $projectIdOrKey) @include(if: $usingProjectKey) {
      ...ProjectFragment
    }
    jiraProjectById(id: $projectId) @skip(if: $usingProjectKey) {
      ...ProjectFragment
    }
    theme: jwmActiveBackgroundByProject(projectIdOrKey: $projectIdOrKey) {
      __typename
      ... on QueryError {
        identifier
        message
        extensions {
          statusCode
          errorType
        }
      }
      ... on JiraWorkManagementBackground {
        ... on JiraWorkManagementColorBackground {
          colorValue
        }
        ... on JiraWorkManagementGradientBackground {
          gradientValue
        }
        ... on JiraWorkManagementMediaBackground {
          customBackground {
            id
            mediaApiFileId
            mediaReadToken (durationInSeconds: 600)
            sourceType
            sourceIdentifier
            altText
          }
        }
      }
    }
  }
}
${PROJECT_FRAGMENT}
`;

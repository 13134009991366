import React, { useCallback, useState } from 'react';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	MenuListItem,
	MenuList,
	MenuSection,
	MenuSectionHeading,
} from '@atlassian/navigation-system';
import type { AppRecommendations } from '../common/types.tsx';
import { useSelectedRecommendation } from '../controllers/useSelectedRecommendation.tsx';
import { useSelectedRecommendationFG } from '../controllers/useSelectedRecommendationFG.tsx';

import messages from '../messages.tsx';
import { recommendations, recommendationsFG } from './index.tsx';

type RecommendationProps = {
	appRecommendations: AppRecommendations;
};

const RecommendationsWithoutFG = ({ appRecommendations }: RecommendationProps) => {
	const { formatMessage } = useIntl();
	const { selectedRecommendationState, dismissRecommendation } = useSelectedRecommendation({
		appRecommendations,
	});

	if (selectedRecommendationState?.status !== 'success') return null;

	const selectedRecommendation = recommendations.find(
		(recommendation) => recommendation.key === selectedRecommendationState.recommendationKey,
	);

	return (
		selectedRecommendation && (
			<>
				<MenuListItem>
					<MenuSection>
						<MenuSectionHeading>{formatMessage(messages.sectionTitle)}</MenuSectionHeading>
						<MenuList>
							<selectedRecommendation.Component
								recommendationKey={selectedRecommendation.key}
								dismissRecommendation={dismissRecommendation}
							/>
						</MenuList>
					</MenuSection>
				</MenuListItem>
			</>
		)
	);
};

const RecommendationsWithFG = ({ appRecommendations }: RecommendationProps) => {
	const [isDismissed, setIsDismissed] = useState(false);
	const { formatMessage } = useIntl();
	const selectedRecommendationState = useSelectedRecommendationFG({
		appRecommendations,
	});
	const onDismissRecommendation = useCallback(() => {
		setIsDismissed(true);
	}, []);

	if (!selectedRecommendationState?.recommendationKey) return null;

	const selectedRecommendation = recommendationsFG.find(
		(recommendation) => recommendation.key === selectedRecommendationState.recommendationKey,
	);

	return (
		selectedRecommendation &&
		!isDismissed && (
			<>
				<MenuListItem>
					<MenuSection>
						<MenuSectionHeading>{formatMessage(messages.sectionTitle)}</MenuSectionHeading>
						<MenuList>
							<selectedRecommendation.Component
								recommendationKey={selectedRecommendation.key}
								dismissRecommendation={onDismissRecommendation}
							/>
						</MenuList>
					</MenuSection>
				</MenuListItem>
			</>
		)
	);
};

export const Recommendations = componentWithFG(
	'hot-113350-move-resources-to-rrr',
	RecommendationsWithFG,
	RecommendationsWithoutFG,
);

/**
 * @generated SignedSource<<e63794b71703cd49bad4df609fca2c0a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type DashboardsForLanding$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DashboardsContent">;
  readonly " $fragmentType": "DashboardsForLanding";
};
export type DashboardsForLanding$key = {
  readonly " $data"?: DashboardsForLanding$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardsForLanding">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "DashboardsForLanding",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "DashboardsContent"
    }
  ],
  "type": "Query"
};

(node as any).hash = "dd61fd6f70c88177a5fb6eaf5e8295b9";

export default node;

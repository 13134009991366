import {
	ROUTE_NAMES_BROWSE_PROJECT_REDIRECT,
	ROUTE_NAMES_CLASSIC_BUSINESS_ONBOARDING_GET_STARTED,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_APPROVALS,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_ATTACHMENTS,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_BOARD,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_CALENDAR,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_BUILDER,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_LIST,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SHORTCUT,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SUMMARY,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_TIMELINE,
	ROUTE_NAMES_CLASSIC_BUSINESS_REDIRECT,
	ROUTE_NAMES_FORGE_PROJECT,
	ROUTE_NAMES_FORGE_SERVICEDESK_QUEUE,
	ROUTE_NAMES_KNOWLEDGE_HUB_ALL_ARTICLES,
	ROUTE_NAMES_KNOWLEDGE_HUB_ARTICLE_EMBED_EDITOR,
	ROUTE_NAMES_KNOWLEDGE_HUB_ARTICLE_IN_CATEGORY,
	ROUTE_NAMES_KNOWLEDGE_HUB_BROWSE_ALL_ARTICLES,
	ROUTE_NAMES_KNOWLEDGE_HUB_BROWSE_ALL_CATEGORIES,
	ROUTE_NAMES_KNOWLEDGE_HUB_BROWSE_VIEW_ARTICLE,
	ROUTE_NAMES_KNOWLEDGE_HUB_CATEGORY,
	ROUTE_NAMES_KNOWLEDGE_HUB_CATEGORY_PAGE,
	ROUTE_NAMES_KNOWLEDGE_HUB_DRAFTS,
	ROUTE_NAMES_KNOWLEDGE_HUB_SUGGESTED_TOPICS,
	ROUTE_NAMES_KNOWLEDGE_HUB_LANDING,
	ROUTE_NAMES_KNOWLEDGE_HUB_SEARCH,
	ROUTE_NAMES_KNOWLEDGE_HUB_SETUP,
	ROUTE_NAMES_LEGACY_ALL_REPORTS,
	ROUTE_NAMES_NOTIFICATION_LOGS,
	ROUTE_NAMES_OPSGENIE_SCHEDULE,
	ROUTE_NAMES_POLARIS_IDEAS,
	ROUTE_NAMES_POLARIS_ONBOARDING,
	ROUTE_NAMES_PROJECT_COMPONENTS,
	ROUTE_NAMES_PROJECT_NOT_FOUND,
	ROUTE_NAMES_PROJECT_PAGES,
	ROUTE_NAMES_PROJECT_VERSIONS,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CORE,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CLASSIC,
	ROUTE_NAMES_PROJECTS_DIRECTORY,
	ROUTE_NAMES_RAPIDBOARD_BACKLOG,
	ROUTE_NAMES_RAPIDBOARD_BOARD,
	ROUTE_NAMES_RAPIDBOARD_REPORT,
	ROUTE_NAMES_SERVICEDESK_ATLASSIAN_ANALYTICS,
	ROUTE_NAMES_SERVICEDESK_BOARDS,
	ROUTE_NAMES_SERVICEDESK_CALENDAR,
	ROUTE_NAMES_SERVICEDESK_CHANGE_CALENDAR,
	ROUTE_NAMES_SERVICEDESK_CONNECT_PROJECT,
	ROUTE_NAMES_SERVICEDESK_CUSTOMERS_V2,
	ROUTE_NAMES_SERVICEDESK_CUSTOMER_DETAILS,
	ROUTE_NAMES_SERVICEDESK_CUSTOMER_SERVICE_ESCALATIONS,
	ROUTE_NAMES_SERVICEDESK_CUSTOMER_SERVICE_PRODUCTS,
	ROUTE_NAMES_SERVICEDESK_GETTING_STARTED_PAGE,
	ROUTE_NAMES_SERVICEDESK_ISSUE,
	ROUTE_NAMES_SERVICEDESK_ISSUES,
	ROUTE_NAMES_SERVICEDESK_KB_REQUESTS_REPORTS,
	ROUTE_NAMES_SERVICEDESK_KB_REQUESTS_REPORTS_ISSUE,
	ROUTE_NAMES_SERVICEDESK_KB_REQUESTS_REPORTS_TIME_SCALE,
	ROUTE_NAMES_SERVICEDESK_ONCALL_SCHEDULE,
	ROUTE_NAMES_SERVICEDESK_ORGANIZATIONS,
	ROUTE_NAMES_SERVICEDESK_ORGANIZATION_DETAILS,
	ROUTE_NAMES_SERVICEDESK_ORGANIZATION_V2,
	ROUTE_NAMES_SERVICEDESK_PRACTICE_CUSTOM_AND_ISSUE,
	ROUTE_NAMES_SERVICEDESK_PRACTICE_EDIT,
	ROUTE_NAMES_SERVICEDESK_PRACTICE_ISSUE,
	ROUTE_NAMES_SERVICEDESK_PRACTICE_LANDING,
	ROUTE_NAMES_SERVICEDESK_PRACTICE_MANAGE_VIEW,
	ROUTE_NAMES_SERVICEDESK_PRACTICE_NEW,
	ROUTE_NAMES_SERVICEDESK_QUEUES_ADDON,
	ROUTE_NAMES_SERVICEDESK_QUEUES_CUSTOM_AND_ISSUE,
	ROUTE_NAMES_SERVICEDESK_QUEUES_EDIT,
	ROUTE_NAMES_SERVICEDESK_QUEUES_ISSUE,
	ROUTE_NAMES_SERVICEDESK_QUEUES_MANAGE_VIEW,
	ROUTE_NAMES_SERVICEDESK_QUEUES_NEW,
	ROUTE_NAMES_SERVICEDESK_REPORTS,
	ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM,
	ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM_CREATE,
	ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM_EDIT,
	ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM_ISSUE,
	ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM_TIME_SCALE,
	ROUTE_NAMES_SERVICEDESK_REPORTS_SATISFACTION,
	ROUTE_NAMES_SERVICEDESK_REPORTS_SATISFACTION_ISSUE,
	ROUTE_NAMES_SERVICEDESK_REPORTS_SATISFACTION_TIME_SCALE,
	ROUTE_NAMES_SERVICEDESK_REPORTS_TYPE,
	ROUTE_NAMES_SERVICEDESK_REPORTS_WORKLOAD,
	ROUTE_NAMES_SERVICEDESK_SERVICES,
	ROUTE_NAMES_SERVICEDESK_SERVICE_DETAILS,
	ROUTE_NAMES_SOFTWARE_BACKLOG,
	ROUTE_NAMES_SOFTWARE_BOARDS,
	ROUTE_NAMES_SOFTWARE_CALENDAR,
	ROUTE_NAMES_SOFTWARE_CALENDAR_CLASSIC,
	ROUTE_NAMES_SOFTWARE_CLASSIC_PROFORMA_ISSUE_FORMS_DIRECT,
	ROUTE_NAMES_SOFTWARE_CODE,
	ROUTE_NAMES_SOFTWARE_CODE_CLASSIC,
	ROUTE_NAMES_SOFTWARE_DEPLOYMENTS,
	ROUTE_NAMES_SOFTWARE_DEPLOYMENTS_CLASSIC,
	ROUTE_NAMES_SOFTWARE_FORM,
	ROUTE_NAMES_SOFTWARE_FORM_CLASSIC,
	ROUTE_NAMES_SOFTWARE_FORM_BUILDER,
	ROUTE_NAMES_SOFTWARE_FORM_BUILDER_CLASSIC,
	ROUTE_NAMES_SOFTWARE_FORM_SUBMIT,
	ROUTE_NAMES_SOFTWARE_FORM_SUBMIT_CLASSIC,
	ROUTE_NAMES_SOFTWARE_FORM_SUBMIT_EMBED,
	ROUTE_NAMES_SOFTWARE_FORM_SUBMIT_CLASSIC_EMBED,
	ROUTE_NAMES_SOFTWARE_GOALS,
	ROUTE_NAMES_SOFTWARE_GOALS_CLASSIC,
	ROUTE_NAMES_SOFTWARE_INCIDENTS,
	ROUTE_NAMES_SOFTWARE_INCIDENTS_CLASSIC,
	ROUTE_NAMES_SOFTWARE_ISSUE_NAVIGATOR,
	ROUTE_NAMES_SOFTWARE_LIST,
	ROUTE_NAMES_SOFTWARE_LIST_CLASSIC,
	ROUTE_NAMES_SOFTWARE_ONBOARDING_GET_STARTED,
	ROUTE_NAMES_SOFTWARE_PERISCOPE,
	ROUTE_NAMES_SOFTWARE_PERISCOPE_CLASSIC,
	ROUTE_NAMES_SOFTWARE_REPORTS,
	ROUTE_NAMES_SOFTWARE_REPORTS_BURNDOWN,
	ROUTE_NAMES_SOFTWARE_REPORTS_BURNUP,
	ROUTE_NAMES_SOFTWARE_REPORTS_CUMULATIVE,
	ROUTE_NAMES_SOFTWARE_REPORTS_CYCLE_TIME,
	ROUTE_NAMES_SOFTWARE_REPORTS_CYCLE_TIME_CLASSIC,
	ROUTE_NAMES_SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY,
	ROUTE_NAMES_SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY_CLASSIC,
	ROUTE_NAMES_SOFTWARE_REPORTS_VELOCITY,
	ROUTE_NAMES_SOFTWARE_SECURITY,
	ROUTE_NAMES_SOFTWARE_SECURITY_CLASSIC,
	ROUTE_NAMES_SOFTWARE_SUMMARY,
	ROUTE_NAMES_SOFTWARE_SUMMARY_CLASSIC,
	ROUTE_NAMES_SOFTWARE_TIMELINE,
	ROUTE_NAMES_SOFTWARE_TIMELINE_CLASSIC,
	ROUTE_NAMES_SOFTWARE_VERSION_DETAIL,
	ROUTE_NAMES_SOFTWARE_VERSION_DETAIL_NO_TAB_PATH,
	ROUTE_NAMES_SOFTWARE_REPORTS_CONTROL,
	ROUTE_NAMES_SERVICEDESK_PROJECT_SUMMARY,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { RouterContext } from '@atlassian/react-resource-router';
import {
	ENTITY_ID,
	MENU_ID_PROJECTS,
	MENU_ID_PROJECTS_VIEW_ALL,
	MENU_ID_PROJECT_APPS,
} from '../../../../constants.tsx';
import type { SelectedPath } from '../../../../types.tsx';

const routes = new Set<string>([
	ROUTE_NAMES_BROWSE_PROJECT_REDIRECT,
	ROUTE_NAMES_LEGACY_ALL_REPORTS,
	ROUTE_NAMES_PROJECTS_DIRECTORY,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CORE,
	ROUTE_NAMES_PROJECT_COMPONENTS,
	ROUTE_NAMES_PROJECT_NOT_FOUND,
	ROUTE_NAMES_PROJECT_VERSIONS,
	ROUTE_NAMES_SOFTWARE_VERSION_DETAIL,
	ROUTE_NAMES_SOFTWARE_VERSION_DETAIL_NO_TAB_PATH,

	// Apps
	ROUTE_NAMES_FORGE_PROJECT,
	ROUTE_NAMES_FORGE_SERVICEDESK_QUEUE,

	// JPD
	ROUTE_NAMES_POLARIS_IDEAS,
	ROUTE_NAMES_POLARIS_ONBOARDING,

	// JSW
	ROUTE_NAMES_OPSGENIE_SCHEDULE,
	ROUTE_NAMES_PROJECT_ISSUE_NAVIGATOR_CLASSIC,
	ROUTE_NAMES_PROJECT_PAGES,
	ROUTE_NAMES_RAPIDBOARD_BACKLOG,
	ROUTE_NAMES_RAPIDBOARD_BOARD,
	ROUTE_NAMES_RAPIDBOARD_REPORT,
	ROUTE_NAMES_SOFTWARE_BACKLOG,
	ROUTE_NAMES_SOFTWARE_BOARDS,
	ROUTE_NAMES_SOFTWARE_CALENDAR,
	ROUTE_NAMES_SOFTWARE_CALENDAR_CLASSIC,
	ROUTE_NAMES_SOFTWARE_CLASSIC_PROFORMA_ISSUE_FORMS_DIRECT,
	ROUTE_NAMES_SOFTWARE_CODE,
	ROUTE_NAMES_SOFTWARE_CODE_CLASSIC,
	ROUTE_NAMES_SOFTWARE_DEPLOYMENTS,
	ROUTE_NAMES_SOFTWARE_DEPLOYMENTS_CLASSIC,
	ROUTE_NAMES_SOFTWARE_FORM,
	ROUTE_NAMES_SOFTWARE_FORM_CLASSIC,
	ROUTE_NAMES_SOFTWARE_FORM_BUILDER,
	ROUTE_NAMES_SOFTWARE_FORM_BUILDER_CLASSIC,
	ROUTE_NAMES_SOFTWARE_FORM_SUBMIT,
	ROUTE_NAMES_SOFTWARE_FORM_SUBMIT_CLASSIC,
	ROUTE_NAMES_SOFTWARE_FORM_SUBMIT_EMBED,
	ROUTE_NAMES_SOFTWARE_FORM_SUBMIT_CLASSIC_EMBED,
	ROUTE_NAMES_SOFTWARE_GOALS,
	ROUTE_NAMES_SOFTWARE_GOALS_CLASSIC,
	ROUTE_NAMES_SOFTWARE_INCIDENTS,
	ROUTE_NAMES_SOFTWARE_INCIDENTS_CLASSIC,
	ROUTE_NAMES_SOFTWARE_ISSUE_NAVIGATOR,
	ROUTE_NAMES_SOFTWARE_LIST,
	ROUTE_NAMES_SOFTWARE_LIST_CLASSIC,
	ROUTE_NAMES_SOFTWARE_PERISCOPE,
	ROUTE_NAMES_SOFTWARE_PERISCOPE_CLASSIC,
	ROUTE_NAMES_SOFTWARE_REPORTS,
	ROUTE_NAMES_SOFTWARE_REPORTS_BURNDOWN,
	ROUTE_NAMES_SOFTWARE_REPORTS_BURNUP,
	ROUTE_NAMES_SOFTWARE_REPORTS_CONTROL,
	ROUTE_NAMES_SOFTWARE_REPORTS_CUMULATIVE,
	ROUTE_NAMES_SOFTWARE_REPORTS_CYCLE_TIME,
	ROUTE_NAMES_SOFTWARE_REPORTS_CYCLE_TIME_CLASSIC,
	ROUTE_NAMES_SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY,
	ROUTE_NAMES_SOFTWARE_REPORTS_DEPLOYMENT_FREQUENCY_CLASSIC,
	ROUTE_NAMES_SOFTWARE_REPORTS_VELOCITY,
	ROUTE_NAMES_SOFTWARE_SECURITY,
	ROUTE_NAMES_SOFTWARE_SECURITY_CLASSIC,
	ROUTE_NAMES_SOFTWARE_SUMMARY,
	ROUTE_NAMES_SOFTWARE_SUMMARY_CLASSIC,
	ROUTE_NAMES_SOFTWARE_TIMELINE,
	ROUTE_NAMES_SOFTWARE_TIMELINE_CLASSIC,
	ROUTE_NAMES_SOFTWARE_ONBOARDING_GET_STARTED,

	// JSM
	ROUTE_NAMES_KNOWLEDGE_HUB_ALL_ARTICLES,
	ROUTE_NAMES_KNOWLEDGE_HUB_ARTICLE_EMBED_EDITOR,
	ROUTE_NAMES_KNOWLEDGE_HUB_ARTICLE_IN_CATEGORY,
	ROUTE_NAMES_KNOWLEDGE_HUB_BROWSE_ALL_ARTICLES,
	ROUTE_NAMES_KNOWLEDGE_HUB_BROWSE_ALL_CATEGORIES,
	ROUTE_NAMES_KNOWLEDGE_HUB_BROWSE_VIEW_ARTICLE,
	ROUTE_NAMES_KNOWLEDGE_HUB_CATEGORY,
	ROUTE_NAMES_KNOWLEDGE_HUB_CATEGORY_PAGE,
	ROUTE_NAMES_KNOWLEDGE_HUB_DRAFTS,
	ROUTE_NAMES_KNOWLEDGE_HUB_SUGGESTED_TOPICS,
	ROUTE_NAMES_KNOWLEDGE_HUB_LANDING,
	ROUTE_NAMES_KNOWLEDGE_HUB_SEARCH,
	ROUTE_NAMES_KNOWLEDGE_HUB_SETUP,
	ROUTE_NAMES_NOTIFICATION_LOGS,
	ROUTE_NAMES_SERVICEDESK_ATLASSIAN_ANALYTICS,
	ROUTE_NAMES_SERVICEDESK_BOARDS,
	ROUTE_NAMES_SERVICEDESK_CALENDAR,
	ROUTE_NAMES_SERVICEDESK_PROJECT_SUMMARY,
	ROUTE_NAMES_SERVICEDESK_CHANGE_CALENDAR,
	ROUTE_NAMES_SERVICEDESK_CONNECT_PROJECT,
	ROUTE_NAMES_SERVICEDESK_CUSTOMERS_V2,
	ROUTE_NAMES_SERVICEDESK_CUSTOMER_DETAILS,
	ROUTE_NAMES_SERVICEDESK_CUSTOMER_SERVICE_ESCALATIONS,
	ROUTE_NAMES_SERVICEDESK_CUSTOMER_SERVICE_PRODUCTS,
	ROUTE_NAMES_SERVICEDESK_GETTING_STARTED_PAGE,
	ROUTE_NAMES_SERVICEDESK_ISSUE,
	ROUTE_NAMES_SERVICEDESK_ISSUES,
	ROUTE_NAMES_SERVICEDESK_KB_REQUESTS_REPORTS,
	ROUTE_NAMES_SERVICEDESK_KB_REQUESTS_REPORTS_ISSUE,
	ROUTE_NAMES_SERVICEDESK_KB_REQUESTS_REPORTS_TIME_SCALE,
	ROUTE_NAMES_SERVICEDESK_ONCALL_SCHEDULE,
	ROUTE_NAMES_SERVICEDESK_ORGANIZATIONS,
	ROUTE_NAMES_SERVICEDESK_ORGANIZATION_DETAILS,
	ROUTE_NAMES_SERVICEDESK_ORGANIZATION_V2,
	ROUTE_NAMES_SERVICEDESK_PRACTICE_CUSTOM_AND_ISSUE,
	ROUTE_NAMES_SERVICEDESK_PRACTICE_EDIT,
	ROUTE_NAMES_SERVICEDESK_PRACTICE_ISSUE,
	ROUTE_NAMES_SERVICEDESK_PRACTICE_LANDING,
	ROUTE_NAMES_SERVICEDESK_PRACTICE_MANAGE_VIEW,
	ROUTE_NAMES_SERVICEDESK_PRACTICE_NEW,
	ROUTE_NAMES_SERVICEDESK_QUEUES_ADDON,
	ROUTE_NAMES_SERVICEDESK_QUEUES_CUSTOM_AND_ISSUE,
	ROUTE_NAMES_SERVICEDESK_QUEUES_EDIT,
	ROUTE_NAMES_SERVICEDESK_QUEUES_ISSUE,
	ROUTE_NAMES_SERVICEDESK_QUEUES_MANAGE_VIEW,
	ROUTE_NAMES_SERVICEDESK_QUEUES_NEW,
	ROUTE_NAMES_SERVICEDESK_REPORTS,
	ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM,
	ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM_CREATE,
	ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM_EDIT,
	ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM_ISSUE,
	ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM_TIME_SCALE,
	ROUTE_NAMES_SERVICEDESK_REPORTS_SATISFACTION,
	ROUTE_NAMES_SERVICEDESK_REPORTS_SATISFACTION_ISSUE,
	ROUTE_NAMES_SERVICEDESK_REPORTS_SATISFACTION_TIME_SCALE,
	ROUTE_NAMES_SERVICEDESK_REPORTS_TYPE,
	ROUTE_NAMES_SERVICEDESK_REPORTS_WORKLOAD,
	ROUTE_NAMES_SERVICEDESK_SERVICES,
	ROUTE_NAMES_SERVICEDESK_SERVICE_DETAILS,

	// JWM
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_APPROVALS,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_ATTACHMENTS,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_BOARD,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_CALENDAR,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_BUILDER,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_FORM_SUBMIT,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_LIST,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SHORTCUT,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_SUMMARY,
	ROUTE_NAMES_CLASSIC_BUSINESS_PROJECT_TIMELINE,
	ROUTE_NAMES_CLASSIC_BUSINESS_REDIRECT,
	ROUTE_NAMES_CLASSIC_BUSINESS_ONBOARDING_GET_STARTED,
]);

function convert(routeContext: RouterContext): SelectedPath {
	const routeName = routeContext.route.name;
	const { match, query } = routeContext;
	const path: string[] = [MENU_ID_PROJECTS];

	switch (routeName) {
		case ROUTE_NAMES_POLARIS_ONBOARDING:
			break;

		case ROUTE_NAMES_PROJECTS_DIRECTORY:
			path.push(MENU_ID_PROJECTS_VIEW_ALL);
			break;

		case ROUTE_NAMES_FORGE_PROJECT:
		case ROUTE_NAMES_FORGE_SERVICEDESK_QUEUE: {
			const { projectKey, appId } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			path.push(MENU_ID_PROJECT_APPS);
			appId && path.push(ENTITY_ID.APP(appId));
			break;
		}

		case ROUTE_NAMES_KNOWLEDGE_HUB_ALL_ARTICLES:
		case ROUTE_NAMES_KNOWLEDGE_HUB_ARTICLE_EMBED_EDITOR:
		case ROUTE_NAMES_KNOWLEDGE_HUB_ARTICLE_IN_CATEGORY:
		case ROUTE_NAMES_KNOWLEDGE_HUB_BROWSE_ALL_ARTICLES:
		case ROUTE_NAMES_KNOWLEDGE_HUB_BROWSE_ALL_CATEGORIES:
		case ROUTE_NAMES_KNOWLEDGE_HUB_BROWSE_VIEW_ARTICLE:
		case ROUTE_NAMES_KNOWLEDGE_HUB_CATEGORY:
		case ROUTE_NAMES_KNOWLEDGE_HUB_CATEGORY_PAGE:
		case ROUTE_NAMES_KNOWLEDGE_HUB_DRAFTS:
		case ROUTE_NAMES_KNOWLEDGE_HUB_SUGGESTED_TOPICS:
		case ROUTE_NAMES_KNOWLEDGE_HUB_LANDING:
		case ROUTE_NAMES_KNOWLEDGE_HUB_SEARCH:
		case ROUTE_NAMES_KNOWLEDGE_HUB_SETUP: {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_KNOWLEDGE_BASE(projectKey));
			break;
		}

		case ROUTE_NAMES_SERVICEDESK_GETTING_STARTED_PAGE: {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_JSM_GETTING_STARTED(projectKey));
			break;
		}

		case ROUTE_NAMES_NOTIFICATION_LOGS: {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_NOTIFICATION_LOGS(projectKey));
			break;
		}

		case ROUTE_NAMES_SERVICEDESK_PROJECT_SUMMARY: {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_SUMMARY(projectKey));
			break;
		}

		case ROUTE_NAMES_SERVICEDESK_BOARDS: {
			const { projectKey, boardId } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_VIEWS(projectKey));
			boardId && path.push(ENTITY_ID.BOARD(boardId));
			break;
		}
		case ROUTE_NAMES_SERVICEDESK_CALENDAR: {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_VIEWS(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_CALENDAR(projectKey));
			break;
		}

		case ROUTE_NAMES_SERVICEDESK_CHANGE_CALENDAR: {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_CHANGE_CALENDAR(projectKey));
			break;
		}

		case ROUTE_NAMES_SERVICEDESK_ORGANIZATIONS:
		case ROUTE_NAMES_SERVICEDESK_ORGANIZATION_DETAILS:
		case ROUTE_NAMES_SERVICEDESK_ORGANIZATION_V2:
		case ROUTE_NAMES_SERVICEDESK_CUSTOMERS_V2:
		case ROUTE_NAMES_SERVICEDESK_CUSTOMER_DETAILS:
		case ROUTE_NAMES_SERVICEDESK_CUSTOMER_SERVICE_PRODUCTS: {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_DIRECTORY(projectKey));
			break;
		}

		case ROUTE_NAMES_SERVICEDESK_CUSTOMER_SERVICE_ESCALATIONS: {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_ESCALATIONS(projectKey));
			break;
		}

		case ROUTE_NAMES_SERVICEDESK_KB_REQUESTS_REPORTS:
		case ROUTE_NAMES_SERVICEDESK_KB_REQUESTS_REPORTS_ISSUE:
		case ROUTE_NAMES_SERVICEDESK_KB_REQUESTS_REPORTS_TIME_SCALE:
		case ROUTE_NAMES_SERVICEDESK_REPORTS:
		case ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM:
		case ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM_CREATE:
		case ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM_EDIT:
		case ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM_ISSUE:
		case ROUTE_NAMES_SERVICEDESK_REPORTS_CUSTOM_TIME_SCALE:
		case ROUTE_NAMES_SERVICEDESK_REPORTS_SATISFACTION:
		case ROUTE_NAMES_SERVICEDESK_REPORTS_SATISFACTION_ISSUE:
		case ROUTE_NAMES_SERVICEDESK_REPORTS_SATISFACTION_TIME_SCALE:
		case ROUTE_NAMES_SERVICEDESK_REPORTS_TYPE:
		case ROUTE_NAMES_SERVICEDESK_REPORTS_WORKLOAD: {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_REPORTS(projectKey));
			break;
		}

		case ROUTE_NAMES_SERVICEDESK_ONCALL_SCHEDULE: {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_ONCALL_SCHEDULE(projectKey));
			break;
		}

		case ROUTE_NAMES_SERVICEDESK_ISSUES: {
			const { projectKey } = match.params;
			const filterId = query.filter || query.filterId;

			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));

			if (projectKey && filterId) {
				path.push(ENTITY_ID.PROJECT_FILTERS(projectKey));
				path.push(ENTITY_ID.PROJECT_ISSUE_FILTER(projectKey, filterId));
			}

			break;
		}

		case ROUTE_NAMES_SERVICEDESK_PRACTICE_CUSTOM_AND_ISSUE:
		case ROUTE_NAMES_SERVICEDESK_PRACTICE_EDIT:
		case ROUTE_NAMES_SERVICEDESK_PRACTICE_ISSUE:
		case ROUTE_NAMES_SERVICEDESK_PRACTICE_LANDING:
		case ROUTE_NAMES_SERVICEDESK_PRACTICE_MANAGE_VIEW:
		case ROUTE_NAMES_SERVICEDESK_PRACTICE_NEW: {
			const { projectKey, practiceType, queueId } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));

			projectKey &&
				practiceType &&
				path.push(ENTITY_ID.PROJECT_PRACTICE_QUEUE(projectKey, practiceType));
			queueId && path.push(ENTITY_ID.QUEUE(queueId));
			break;
		}

		case ROUTE_NAMES_SERVICEDESK_QUEUES_ADDON:
		case ROUTE_NAMES_SERVICEDESK_QUEUES_CUSTOM_AND_ISSUE:
		case ROUTE_NAMES_SERVICEDESK_QUEUES_EDIT:
		case ROUTE_NAMES_SERVICEDESK_QUEUES_ISSUE:
		case ROUTE_NAMES_SERVICEDESK_QUEUES_MANAGE_VIEW:
		case ROUTE_NAMES_SERVICEDESK_QUEUES_NEW: {
			const { projectKey, queueId } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_QUEUES(projectKey));
			queueId && path.push(ENTITY_ID.QUEUE(queueId));
			break;
		}

		case ROUTE_NAMES_SERVICEDESK_SERVICES:
		case ROUTE_NAMES_SERVICEDESK_SERVICE_DETAILS: {
			const { projectKey } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			projectKey && path.push(ENTITY_ID.PROJECT_SERVICES(projectKey));
			break;
		}

		default: {
			const { projectKey, boardId, formId } = match.params;
			projectKey && path.push(ENTITY_ID.PROJECT(projectKey));
			boardId && path.push(ENTITY_ID.BOARD(boardId));
			formId && path.push(ENTITY_ID.FORM(formId));
		}
	}

	return path;
}

export const projectsConverter = { routes, convert };

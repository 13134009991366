import React, { useCallback, type MouseEvent } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useConnectItemClickHistory } from '@atlassian/jira-connect-item-click-history-controller/src/index.tsx';
import {
	withConnectHost,
	isConnectDialogWebItem,
} from '@atlassian/jira-connect-utils/src/common/utils/ecosystem-connect-host.tsx';
import type { ACJSHost } from '@atlassian/jira-connect/src/types.tsx';
import { Nav4AppMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-apps/src/common/ui/app-menu-item/Nav4AppMenuItem.tsx';
import type { Nav4AppMenuItem$data } from '@atlassian/jira-relay/src/__generated__/Nav4AppMenuItem.graphql';
import type { Nav4ConnectMenuItem$key } from '@atlassian/jira-relay/src/__generated__/Nav4ConnectMenuItem.graphql';
import { canHaveSubpages } from '../../utils/connect-magic/index.tsx';

type Nav4ConnectMenuItemProps = {
	queryRef: Nav4ConnectMenuItem$key;
};

export function Nav4ConnectMenuItem({ queryRef }: Nav4ConnectMenuItemProps) {
	const data = useFragment<Nav4ConnectMenuItem$key>(
		graphql`
			fragment Nav4ConnectMenuItem on JiraAppNavigationItem {
				...Nav4AppMenuItem
			}
		`,
		queryRef,
	);

	const { addNew } = useConnectItemClickHistory();
	const onClick = useCallback(
		(e: MouseEvent, target: HTMLAnchorElement | null, styleClass: string, url: string | null) => {
			if (url == null) return;
			addNew(url);
			if (target == null) return;
			withConnectHost((connectHost: ACJSHost) => {
				connectHost.triggerWebItem(target, styleClass, url);
			});
			if (isConnectDialogWebItem(styleClass)) {
				e.preventDefault();
			}
		},
		[addNew],
	);

	return <Nav4AppMenuItem queryRef={data} onData={transformData} onClick={onClick} />;
}

/**
 * Data transformer for Connect.
 * We are going to convert multi-page Connect apps into single-page ones in case their subpages don't contain a url that matches the root one.
 * For more details, see {@link canHaveSubpages}.
 */
function transformData<T extends Nav4AppMenuItem$data>(data: T) {
	return canHaveSubpages(data) ? data : { ...data, sections: null };
}

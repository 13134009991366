import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';

export const getIdeasCountForCollection = async (jql: string): Promise<number> =>
	performPostRequest('/rest/polaris/issues/searchCountForJql', {
		body: JSON.stringify({
			jql,
			archived: 'ACTIVE_ONLY',
		}),
	})
		.then((response) => response.count)
		.catch((error) => {
			fireErrorAnalytics(
				createErrorAnalytics('polaris.error.getIdeasCountForCollectionFailed', error),
			);
			throw error;
		});

import React from 'react';
import { LoadingStateAnimationWrapper } from '../../loading-state/styled.tsx';
import OrderByButton from '../../order-by-trigger-button/index.tsx';

const ORDER_BY_LOADING_BUTTON_ID = 'jql-builder-basic-order-by-trigger-loading';

type Props = {
	orderByFieldsCount: number;
};

const OrderByPickerLoading = ({ orderByFieldsCount }: Props) =>
	orderByFieldsCount > 0 ? (
		<LoadingStateAnimationWrapper>
			<OrderByButton
				numberOfFields={orderByFieldsCount}
				buttonId={ORDER_BY_LOADING_BUTTON_ID}
				isSelected
			/>
		</LoadingStateAnimationWrapper>
	) : (
		<OrderByButton
			numberOfFields={orderByFieldsCount}
			buttonId={ORDER_BY_LOADING_BUTTON_ID}
			isSelected={false}
		/>
	);

export default OrderByPickerLoading;

import React from 'react';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { LazyAlertIssueSyncDetail } from './async.tsx';

export const AlertIssueSyncDetail = () => (
	<LazyPage
		Page={LazyAlertIssueSyncDetail}
		pageId="async-jira-eoc-alert-issue-syncing"
		shouldShowSpinner
	/>
);

import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { projectSettingsServicedeskInternalNotificationsNextGenRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskInternalNotificationsNextGenRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { LazyInternalNotificationSettingsPage } from '@atlassian/jira-servicedesk-settings-notification-internal/src/ui/async.tsx';
import type { Route } from '@atlassian/react-resource-router/src/common/types.tsx';
import ServiceDeskInternalNotifications, {
	LazyProjectSettingsServiceDeskInternalNotifications,
} from './ui/service-desk-internal-notifications/index.tsx';

export const projectSettingsServicedeskInternalNotificationsNextGenRouteEntry: Route = createEntry(
	projectSettingsServicedeskInternalNotificationsNextGenRoute,
	() => ({
		group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
		ufoName: 'service-management.internal-notifications',
		component: ServiceDeskInternalNotifications,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
		forPaint: [
			LazyProjectSettingsServiceDeskInternalNotifications,
			LazyInternalNotificationSettingsPage,
		],
	}),
);

/**
 * @generated SignedSource<<a56f335c6db6080b2fd1d9cf8e744774>>
 * @relayHash 451352e776c1dc402b6ff2a9a47c5d17
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e06c33c3891356ae39e4cd512b10ff6b80ffd88a8d71108c98cba151c0b9cd55

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraSoftwareFormQuery } from './src_jiraSoftwareFormQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraSoftwareFormQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "e06c33c3891356ae39e4cd512b10ff6b80ffd88a8d71108c98cba151c0b9cd55",
    "metadata": {},
    "name": "src_jiraSoftwareFormQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

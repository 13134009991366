import React, {
	type RefCallback,
	type ReactNode,
	useCallback,
	memo,
	useMemo,
	useState,
} from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import EditorBackgroundColorIcon from '@atlaskit/icon/glyph/editor/background-color';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import Tooltip from '@atlaskit/tooltip';
import { JiraProjectAri } from '@atlassian/ari/jira';
import {
	ExperienceFailed as ExperienceFailedOld,
	useExperienceStart as useExperienceStartOld,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagsService, type Flag } from '@atlassian/jira-flags';
import { useIntl, defineMessages } from '@atlassian/jira-intl';
import { QuickstartNudge } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/quickstart-nudge/main.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import {
	ContextualAnalyticsData,
	MODAL,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { BackgroundColourNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next-jwm-background-colour/src/async.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	VIEW_THEME_PICKER_EXPERIENCE,
	VIEW_THEME_PICKER_EXPERIENCE_OLD,
} from '../../common/constants.tsx';
import { ExperienceFailed } from '../../common/utils.tsx';
import { THEME_PICKER_ELEMENT_ID } from '../../constants.tsx';
import { ThemePickerButtonItem } from '../theme-picker-button-item/ThemePickerButtonItem.tsx';
import { ThemedButton } from '../themed-button/ThemedButton.tsx';
import { backgroundPickerPopupContentEntryPoint } from './popup-content/entrypoint.tsx';
import { ThemePickerSkeleton } from './ThemePickerSkeleton.tsx';
import type { Props } from './types.tsx';

const SOURCE_NAME = 'projectThemePicker';
const SOURCE_NAME_OLD = 'businessProjectThemePicker';

const messages = defineMessages({
	backgroundTitle: {
		id: 'business-theme-components.theme-picker.background-title',
		defaultMessage: 'Project background',
		description: 'Title of the theme picker used to set a background for business projects',
	},
	buttonTitle: {
		id: 'business-theme-components.theme-picker.button-title',
		defaultMessage: 'Set project background',
		description: 'Title of the theme picker button used to set a background for business projects',
	},
	errorFlagTitle: {
		id: 'business-theme-components.theme-picker.error-flag-title',
		defaultMessage: "Something's gone wrong",
		description:
			'Title of the error flag when opening the theme picker popup fails indicating something went wrong',
	},
	errorFlagDescription: {
		id: 'business-theme-components.theme-picker.error-flag-description',
		defaultMessage:
			'Our team has been notified. If the problem persists, please contact Atlassian Support.',
		description: 'Description of the error flag when opening the theme picker popup fails',
	},
});

export const POPUP_ENTRYPOINT_ERROR_FLAG: Flag = {
	id: 'open-theme-picker-error',
	type: 'error',
	title: messages.errorFlagTitle,
	description: messages.errorFlagDescription,
	isAutoDismiss: true,
};

export const ThemePicker = memo<Props>(({ isOpen, onOpenChange, projectId }: Props) => {
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();
	const route = useCurrentRoute();
	const projectAri = JiraProjectAri.create({
		siteId: cloudId,
		projectId: String(projectId),
	}).toString();
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		backgroundPickerPopupContentEntryPoint,
		useMemo(() => ({ cloudId, projectAri }), [cloudId, projectAri]),
	);
	const startExperienceOld = useExperienceStartOld(VIEW_THEME_PICKER_EXPERIENCE_OLD);
	const startExperience = useExperienceStart(VIEW_THEME_PICKER_EXPERIENCE);

	const { showFlag } = useFlagsService();
	const [flagShown, setFlagShown] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isCustomNavEnabled = getWillShowNav4();

	const [openState, setOpenState] = useState(false);
	const isThemePickerOpen = isOpen && !isCustomNavEnabled ? isOpen : openState;
	const setIsThemePickerOpen = onOpenChange && !isCustomNavEnabled ? onOpenChange : setOpenState;

	const toggleThemePicker = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(_: any, analyticsEvent?: UIAnalyticsEvent) => {
			// If we are about to open the theme picker, start the experience
			if (!isThemePickerOpen)
				fg('jira_simple_theming_platformisation') ? startExperience() : startExperienceOld();
			setIsThemePickerOpen((oldValue) => !oldValue);
			fireUIAnalytics(
				analyticsEvent ?? createAnalyticsEvent({}),
				'button clicked',
				'themePickerButton',
			);
			setFlagShown(false);
		},
		[
			isThemePickerOpen,
			startExperience,
			startExperienceOld,
			setIsThemePickerOpen,
			createAnalyticsEvent,
		],
	);

	const renderNudgeWrapper = useCallback(
		(children: ReactNode) => <BackgroundColourNudgeAsync>{children}</BackgroundColourNudgeAsync>,
		[],
	);

	const buttonTrigger = isCustomNavEnabled
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useEntryPointButtonTrigger(entryPointActions, !isThemePickerOpen)
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useEntryPointButtonTrigger(entryPointActions);

	const runtimeProps = useMemo(
		() => ({
			onOpenChange: setIsThemePickerOpen,
		}),
		[setIsThemePickerOpen],
	);

	const trigger = useCallback(
		(triggerProps: TriggerProps | { ref: RefCallback<HTMLElement> }) => (
			<Tooltip content={formatMessage(messages.buttonTitle)} hideTooltipOnClick position="top">
				{isCustomNavEnabled ? (
					<ThemePickerButtonItem
						{...triggerProps}
						isSelected={isThemePickerOpen}
						testId="project-theme-components.ui.theme-picker.trigger"
						ref={(element: HTMLElement) => {
							buttonTrigger(element);
							if (typeof triggerProps.ref === 'function') {
								triggerProps.ref(element);
							}
						}}
						onClick={toggleThemePicker}
					/>
				) : (
					<RoundButton
						{...triggerProps}
						testId="project-theme-components.ui.theme-picker.button"
						isSelected={isThemePickerOpen}
						ref={(element) => {
							buttonTrigger(element);
							if (typeof triggerProps.ref === 'function') {
								triggerProps.ref(element);
							}
						}}
						onClick={toggleThemePicker}
						appearance="subtle"
						iconBefore={
							<EditorBackgroundColorIcon
								label={formatMessage(messages.buttonTitle)}
								size="medium"
							/>
						}
					/>
				)}
			</Tooltip>
		),
		[buttonTrigger, formatMessage, isThemePickerOpen, toggleThemePicker, isCustomNavEnabled],
	);

	const errorFallback = useCallback(
		({ error }: { error: Error }) => {
			if (!flagShown) {
				showFlag(POPUP_ENTRYPOINT_ERROR_FLAG);
				setFlagShown(true);
			}

			return fg('jira_simple_theming_platformisation') ? (
				<ExperienceFailed experience={VIEW_THEME_PICKER_EXPERIENCE} error={error} />
			) : (
				<ExperienceFailedOld experience={VIEW_THEME_PICKER_EXPERIENCE_OLD} error={error} />
			);
		},
		[flagShown, showFlag],
	);

	const content = useCallback(
		() => (
			<JiraEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				id="background-picker-popup-content"
				packageName="@atlassian/jira-project-theme-components"
				fallback={<ThemePickerSkeleton />}
				errorFallback={errorFallback}
				runtimeProps={runtimeProps}
			/>
		),
		[errorFallback, entryPointReferenceSubject, runtimeProps],
	);

	const onCloseHandler = useCallback(() => setIsThemePickerOpen(false), [setIsThemePickerOpen]);
	return (
		<QuickstartNudge renderWrapper={renderNudgeWrapper}>
			<ContextualAnalyticsData
				sourceName={fg('jira_simple_theming_platformisation') ? SOURCE_NAME : SOURCE_NAME_OLD}
				sourceType={MODAL}
			>
				<Popup
					isOpen={isThemePickerOpen}
					onClose={onCloseHandler}
					shouldRenderToParent={
						route.name.includes('legacy') && getWillShowNav4() ? false : isCustomNavEnabled
					}
					content={content}
					trigger={trigger}
					placement={isCustomNavEnabled ? 'right-start' : 'bottom-start'}
					id={THEME_PICKER_ELEMENT_ID}
				/>
			</ContextualAnalyticsData>
		</QuickstartNudge>
	);
});

/* Seems like the only way to get a rounded button. Because atlaskit button already has a border radius !important is required */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RoundButton = styled(ThemedButton)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	borderRadius: '100% !important',
});

import { ROUTE_NAMES_SERVICEDESK_REPORTS_SATISFACTION_TIME_SCALE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';
import {
	SERVICE_DESK_ROUTE_PREFIX,
	SATISFACTION_ROUTE_SUFFIX,
	TIME_SCALE,
} from './common/constants.tsx';

export const servicedeskReportsSatisfactionTimeScaleRoute: InvariantRoute = {
	name: ROUTE_NAMES_SERVICEDESK_REPORTS_SATISFACTION_TIME_SCALE,
	path: `${SERVICE_DESK_ROUTE_PREFIX}${SATISFACTION_ROUTE_SUFFIX}${TIME_SCALE}`,
};

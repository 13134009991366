import React, { type ComponentType, type FC } from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import type { Props } from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const UpdateFilterDialog: ComponentType<Props> = lazyAfterPaint(
	() =>
		import(/* webpackChunkName: "async-update-filter-dialog" */ './index').then((module) => ({
			default: module.OldUpdateFilterDialog,
		})),
	{ ssr: false },
);

export const AsyncUpdateFilterDialog: FC<Props> = (props: Props) => (
	<Placeholder name="async-update-filter-dialog" fallback={<></>}>
		<UpdateFilterDialog {...props} />
	</Placeholder>
);

import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { ROUTE_GROUPS_CLASSIC_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { AsyncHorizontalNavJiraSoftware as AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { staticSoftwareProjectHorizontalNavResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/software-horizontal-navigation/index.tsx';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { softwareCodeClassicRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/softwareCodeClassicRoute.tsx';
import { codePageResource } from '@atlassian/jira-router-resources-software-code/src/services/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { isDevopsFeatureDisabledInFedRamp } from '@atlassian/jira-software-devops-fedramp-utils/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { classicSoftwareProjectLayout } from '@atlassian/jira-software-project-layout/src/index.tsx';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async.tsx';
import { CodePageClassic, LazyCode } from './ui/software/code/index.tsx';

export const softwareCodeClassicRouteEntry = createEntry(softwareCodeClassicRoute, () => ({
	group: ROUTE_GROUPS_CLASSIC_SOFTWARE,
	resources: [
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResource,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		staticSoftwareProjectHorizontalNavResource,
		// end of resources expanded from getNavigationSidebarProjectResource
		codePageResource,
	],

	component: componentWithCondition(
		isDevopsFeatureDisabledInFedRamp,
		ErrorPagesNotFound,
		CodePageClassic,
	),

	layout: classicSoftwareProjectLayout,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarSoftwareClassic,
		horizontal: AsyncHorizontalNavJira,
		onlyShowHorizontalOnNav4: true,
	},

	forPaint: [LazyAtlassianNavigation, LazyCode],
}));

import {
	AbstractJastVisitor,
	COMPOUND_OPERATOR_OR,
	OPERATOR_EQUALS,
	type CompoundClause,
	type TerminalClause,
	OPERATOR_LIKE,
	OPERAND_TYPE_VALUE,
	CLAUSE_TYPE_TERMINAL,
	type Clause,
} from '@atlaskit/jql-ast';
import { getTextSearchInputField } from '../../../common/constants.tsx';
import { InvalidTextSearchInputError } from './invalid-text-search-input-error.tsx';

type TextSearchInputData = string | undefined;

const areTerminalClauses = (clauses: Clause[]): clauses is TerminalClause[] => {
	for (const clause of clauses) {
		if (clause.clauseType !== CLAUSE_TYPE_TERMINAL) {
			return false;
		}
	}

	return true;
};

const ISSUE_KEY_REGEXP = new RegExp('^[A-Z][A-Z0-9]+-[0-9]+$', 'i');
export const isIssueKeyValue = (value: TextSearchInputData) =>
	value !== undefined && value !== null && ISSUE_KEY_REGEXP.test(value);

/**
 * Visitor to validate search input
 * If search input is valid, it will return the value of the search input
 * Otherwise, it will throw an error
 */
export class TextSearchInputVisitor extends AbstractJastVisitor<TextSearchInputData> {
	// remove when cleanup nin-text-fields-new-text-search-field fg
	private isTextFieldsEnabled: boolean;

	constructor({ isTextFieldsEnabled }: { isTextFieldsEnabled: boolean }) {
		super();

		this.isTextFieldsEnabled = isTextFieldsEnabled;
	}

	protected defaultResult(): TextSearchInputData {
		throw new InvalidTextSearchInputError('Invalid search input clause');
	}

	visitCompoundClause = (compoundClause: CompoundClause): TextSearchInputData => {
		// Only allow one compound clause with 1 or 2 terminal clauses using OR operator
		if (
			compoundClause.clauses.length > 2 ||
			compoundClause.clauses.length < 1 ||
			compoundClause.operator.value !== COMPOUND_OPERATOR_OR ||
			!areTerminalClauses(compoundClause.clauses)
		) {
			throw new InvalidTextSearchInputError('Invalid search input clause');
		}

		const valueMap: { [key: string]: boolean } = {};

		const value = compoundClause.clauses.reduce<TextSearchInputData>((result, terminalClause) => {
			const clauseFieldName = terminalClause.field.value.toLowerCase();

			// Use same fieldName for key and issuekey (they are aliases)
			const fieldName = clauseFieldName === 'key' ? 'issuekey' : clauseFieldName;

			// Check if there are duplicated fields
			if (valueMap[fieldName]) {
				throw new InvalidTextSearchInputError(`Duplicated field ${fieldName}`);
			} else {
				valueMap[fieldName] = true;
			}

			return this.aggregateResult(terminalClause.accept(this), result);
		}, undefined);

		// Check if all required fields are present
		if (
			!valueMap[getTextSearchInputField()] ||
			(valueMap.issuekey !== undefined && !valueMap.issuekey)
		) {
			throw new InvalidTextSearchInputError('Missing required field in search input');
		}

		return value;
	};

	visitTerminalClause = (terminalClause: TerminalClause): TextSearchInputData => {
		const fieldName = terminalClause.field.value.toLowerCase();

		// Only allow terminal clause with value operand
		if (terminalClause.operand?.operandType !== OPERAND_TYPE_VALUE) {
			throw new InvalidTextSearchInputError(
				`Invalid operandType ${terminalClause.operand?.operandType}`,
			);
		}

		if (fieldName === getTextSearchInputField()) {
			if (terminalClause.operator?.value !== OPERATOR_LIKE) {
				throw new InvalidTextSearchInputError(`Invalid operator in ${fieldName}`);
			}

			return terminalClause.operand.value;
		}

		// Issue key check
		if (fieldName === 'issuekey' || fieldName === 'key') {
			if (terminalClause.operator?.value !== OPERATOR_EQUALS) {
				throw new InvalidTextSearchInputError(`Invalid operator in ${fieldName}`);
			}

			if (!isIssueKeyValue(terminalClause.operand.value)) {
				throw new InvalidTextSearchInputError(`Invalid value in ${fieldName}`);
			}

			return terminalClause.operand.value;
		}

		throw new InvalidTextSearchInputError(`Invalid field ${fieldName}`);
	};

	aggregateResult = (
		aggregate: TextSearchInputData,
		nextResult: TextSearchInputData,
	): TextSearchInputData => {
		// We only allow using same value in all terminal clauses
		if (nextResult !== undefined && aggregate !== nextResult) {
			throw new InvalidTextSearchInputError('Mismatched values in search input');
		}

		return aggregate;
	};
}

import { useCallback, useState } from 'react';
import type { AsyncAction } from '../../types.tsx';

// Replaces optional `isFetching` and `fetch` parameters with actual ones.
// The returned `fetch` has its own internal `isFetching`.
// The returned `isFetching` reflects both the optional one and the internal one.
// In this way the hook provides accurate state regardless of which parameters are defined.
export const useFetchActual = (isFetchingMaybe?: boolean, fetchMaybe?: AsyncAction) => {
	const [isFetching, setIsFetching] = useState(false);

	const fetch = useCallback(async () => {
		if (!fetchMaybe) return;
		setIsFetching(true);
		try {
			await fetchMaybe();
		} finally {
			setIsFetching(false);
		}
	}, [fetchMaybe, setIsFetching]);

	return { isFetching: !!isFetchingMaybe || isFetching, fetch };
};

import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	flyoutMenuItemTriggerTitle: {
		id: 'navigation.global-recent.flyout-menu-item-trigger-title.non-final',
		description: 'Label of the control that triggers the flyout menu.',
		defaultMessage: 'Recent',
	},
	viewAllRecentItems: {
		id: 'navigation.global-recent.view-all-recent-items.non-final',
		description: 'Label of the control that links to a page with all of the recent items.',
		defaultMessage: 'View all recent items',
	},
	menuSectionHeadingToday: {
		id: 'navigation.global-recent.menu-section-heading-today.non-final',
		description: "Heading of the section rendering today's items.",
		defaultMessage: 'Today',
	},
	menuSectionHeadingYesterday: {
		id: 'navigation.global-recent.menu-section-heading-yesterday.non-final',
		description: "Heading of the section rendering yesterday's items.",
		defaultMessage: 'Yesterday',
	},
	menuSectionHeadingThisWeek: {
		id: 'navigation.global-recent.menu-section-heading-this-week.non-final',
		description: 'Heading of the section rendering items for the current week.',
		defaultMessage: 'This week',
	},
	menuSectionHeadingThisMonth: {
		id: 'navigation.global-recent.menu-section-heading-this-month.non-final',
		description: 'Heading of the section rendering items for the current month.',
		defaultMessage: 'This Month',
	},
	menuSectionHeadingMoreThanAMonthAgo: {
		id: 'navigation.global-recent.menu-section-heading-this-month.non-final',
		description: 'Heading of the section rendering items older than a month ago.',
		defaultMessage: 'More than a month ago',
	},
	errorStateHeading: {
		id: 'navigation.global-recent.error-state-heading.non-final',
		description: "Heading of the error state UI indicating we couldn't load the data.",
		defaultMessage: "Can't load items",
	},
	errorStateDescription: {
		id: 'navigation.global-recent.error-state-description.non-final',
		description: "Description of the error state UI indicating we couldn't load the data.",
		defaultMessage: "We're having trouble loading your data. Please try again later.",
	},
	emptyStateHeading: {
		id: 'navigation.global-recent.empty-state-heading.non-final',
		description: "Heading of the empty state UI indicating there's no content available.",
		defaultMessage: 'Nothing to see here!',
	},
	emptyStateDescription: {
		id: 'navigation.global-recent.empty-state-description.non-final',
		description: "Description of the empty state UI indicating there's no content available.",
		defaultMessage: "Once you visit, edit, or create items, they'll show up here.",
	},
	loadingStateVisuallyHiddenLabel: {
		id: 'navigation.global-recent.loading-state-visually-hidden-label.non-final',
		description: 'Label of the loading state UI only available to screen reader users.',
		defaultMessage: 'Loading recent content',
	},
	confluenceDraftDefaultTitle: {
		id: 'navigation.global-recent.confluence-item-draft-title.non-final',
		description: 'Default title for draft pages and blog posts in Confluence.',
		defaultMessage: 'Untitled',
	},
	confluenceDraftLabel: {
		id: 'navigation.global-recent.confluence-item-draft-label.non-final',
		description: 'Label of the lozenge indicating a Confluence item is a draft.',
		defaultMessage: 'Draft',
	},
});

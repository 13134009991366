import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { MENU_ID_PROJECTS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { useSidebarNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-context/index.tsx';
import { ActionButtons } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-core/src/common/ui/action-buttons/ActionButtons.tsx';
import { Nav4ProjectsDefault } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-core/src/ui/projects/default/Nav4ProjectsDefault.tsx';
import { Nav4ProjectsForLanding } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-core/src/ui/projects/for-landing/Nav4ProjectsForLanding.tsx';
import type { Nav4Projects$key } from '@atlassian/jira-relay/src/__generated__/Nav4Projects.graphql';

export type Nav4ProjectsProps = {
	isExpanded: boolean;
	queryRef: Nav4Projects$key;
};

export function Nav4Projects({ isExpanded, queryRef }: Nav4ProjectsProps) {
	const data = useFragment<Nav4Projects$key>(
		graphql`
			fragment Nav4Projects on Query {
				...Nav4ProjectsForLanding @include(if: $isProjectsExpanded)
				jira {
					canCreateProject: canPerform(type: CREATE_PROJECT, cloudId: $cloudId)
						@optIn(to: "JiraAction")
				}
			}
		`,
		queryRef,
	);

	const { isInitialSelectedPath } = useSidebarNav4();

	const canCreateProject = !!data?.jira?.canCreateProject;
	const actionButtons = <ActionButtons canCreateProject={canCreateProject} />;

	return isInitialSelectedPath(MENU_ID_PROJECTS) ? (
		<Nav4ProjectsForLanding
			actionsOnHover={actionButtons}
			isExpanded={isExpanded}
			queryRef={data}
		/>
	) : (
		<Nav4ProjectsDefault actionsOnHover={actionButtons} isExpanded={isExpanded} />
	);
}

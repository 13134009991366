import React from 'react';
import { ROUTE_GROUPS_PERSONAL_SETTINGS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getNavigationForgePersonalSettingsPageResources } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import AsyncPersonalSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-personal-settings/src/async.tsx';
import { getNotificationsPersonalSettingsPath } from '@atlassian/jira-personal-settings-paths/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { notificationsPersonalSettingsRoute } from '@atlassian/jira-router-routes-personal-settings-routes/src/notificationsPersonalSettingsRoute.tsx';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/ScreenRedirect.tsx';
import { PersonalSettingsNotificationsAppSkeleton } from '@atlassian/jira-skeletons/src/ui/personal-settings-notifications/PersonalSettingsNotificationsAppSkeleton.tsx';
import { personalSettingsNotificationsEntryPoint } from '@atlassian/jira-spa-apps-personal-settings-notifications/entrypoint.tsx';
import type { Route } from '@atlassian/react-resource-router';

const personalSettingsNotificationsEntryPointPage = createPageEntryPoint({
	main: personalSettingsNotificationsEntryPoint,
	topNavigationMenuId: MENU_ID.NOTIFICATIONS,
});

export const notificationsPersonalSettingsRouteEntry: Route = createEntry(
	notificationsPersonalSettingsRoute,
	() => ({
		group: ROUTE_GROUPS_PERSONAL_SETTINGS,
		skeleton: PersonalSettingsNotificationsAppSkeleton,
		component: () => <ScreenRedirect to={getNotificationsPersonalSettingsPath()} />,
		layout: globalLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.PERSONAL_SETTINGS,
			menuId: MENU_ID.PROFILE,
			sidebar: AsyncPersonalSettingsSidebar,
		},
		resources: [
			...getNavigationResources(),
			getConsolidationStateResource(),
			...getNavigationForgePersonalSettingsPageResources(),
		],
		pageId: 'personal-settings-notifications',
		entryPoint() {
			return personalSettingsNotificationsEntryPointPage;
		},
	}),
);

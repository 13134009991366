import React from 'react';
import { SkeletonItem, MenuGroup, Section } from '@atlaskit/menu';
import { Box, xcss, Flex } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { MENU_GROUP_MIN_WIDTH } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/constants/index.tsx';

export const Skeleton = () => (
	<Box xcss={wrapperStyles}>
		<MenuGroup minWidth={MENU_GROUP_MIN_WIDTH}>
			<Section isList>
				<SkeletonItem hasIcon />
				<SkeletonItem hasIcon />
				<SkeletonItem hasIcon />
			</Section>
			<Section isList hasSeparator>
				<SkeletonItem hasIcon />
			</Section>
			<Flex>
				<Box xcss={projectTileSkeleton} />
			</Flex>
		</MenuGroup>
	</Box>
);

const wrapperStyles = xcss({
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	inlineSize: `${MENU_GROUP_MIN_WIDTH}px`,
});

const projectTileSkeleton = xcss({
	backgroundColor: 'color.skeleton',
	height: token('space.600'),
	flex: 1,
});

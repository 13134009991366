/**
 * @generated SignedSource<<dedde06316e2afe5acc862762a93f4dd>>
 * @relayHash cb914e8d2219b7f49741872ae7f6d037
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a770411d52534005c4d94e2f61c74a98ed117522669e7af7701400ccbf2323f3

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentViewIntentQuery } from './srcVirtualAgentViewIntentQuery.graphql';

import checkForAiEnabledFF_provider from '@atlassian/jira-relay-provider/src/jsm-va-is-require-ai-enabled.relayprovider';

const node: PreloadableConcreteRequest<srcVirtualAgentViewIntentQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "a770411d52534005c4d94e2f61c74a98ed117522669e7af7701400ccbf2323f3",
    "metadata": {},
    "name": "srcVirtualAgentViewIntentQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider": checkForAiEnabledFF_provider
    }
  }
};

export default node;

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServicedeskPageContextResources,
	getServiceDeskSettingsResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { projectSettingsServicedeskKbRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-knowledge-base-routes/src/projectSettingsServicedeskKbRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import {
	ServiceDeskKnowledgeBaseV2,
	LazyProjectSettingsServiceDeskKnowledgeBaseV2,
} from './ui/service-desk-knowledge-base/index.tsx';

export const projectSettingsServicedeskKbRouteEntry: Route = createEntry(
	projectSettingsServicedeskKbRoute,
	() => ({
		group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
		perfMetricKey: 'service-management.kb-settings',
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

		component: ServiceDeskKnowledgeBaseV2,
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskKnowledgeBaseV2],

		resources: [
			...getServiceDeskSettingsResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],
	}),
);

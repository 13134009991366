import { useCallback, useState } from 'react';
import noop from 'lodash/noop';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { useOnboardingTour } from '../../controllers/index.tsx';
import type { Props } from './types.tsx';

export type Step = 'inactive' | 'spotlight' | 'modal' | 'flag' | 'finished';
export const useStep = () => {
	const [step, setStep] = useState<Step>('inactive');

	const start = useCallback(() => setStep('modal'), []);

	const finish = useCallback(() => setStep('finished'), []);

	const skipToFlag = useCallback(() => setStep('flag'), []);

	const next = useCallback(
		() =>
			setStep((current) => {
				switch (current) {
					case 'modal':
						return 'spotlight';

					case 'spotlight': {
						return 'flag';
					}

					case 'flag':
					default:
						return 'finished';
				}
			}),
		[],
	);

	return [step, { next, start, finish, skip: skipToFlag }] as const;
};

const useStoreController = () => {
	const [{ visible }, { initOnboarding, closeOnboarding }] = useOnboardingTour();
	return [{ visible }, { init: initOnboarding, close: closeOnboarding }] as const;
};

const usePropsController = (props: Props) => {
	if (!('visible' in props)) {
		return undefined;
	}

	const { visible, onClose } = props;
	return [{ visible }, { init: noop, close: onClose }] as const;
};

/** Uses 'visible' and 'onClose' on props if they are provided, otherwise use the store state/actions */
export const useController = (props: Props) => {
	const storeController = useStoreController();
	const propsController = usePropsController(props);

	return propsController ?? storeController;
};

// eslint-disable-next-line jira/ff/inline-usage
export const isCrossTeamPlanningTemplateExperimentEnabled = () =>
	expValEquals('second_premium_template_experiment', 'cohort', 'variation');

/**
 * @generated SignedSource<<8635ace8fb0ce81b746abefadc451af9>>
 * @relayHash 156604a8863f962926965bde79b587c9
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b0598bd12791c820d9fd37010fdcfaa71a2efec5a91d1ad262f20eabce79217d

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4AppsContentViewQuery$variables = {
  cloudId: string;
};
export type Nav4AppsContentViewQuery$data = {
  readonly jira: {
    readonly " $fragmentSpreads": FragmentRefs<"Nav4AppsContent">;
  };
};
export type Nav4AppsContentViewQuery = {
  response: Nav4AppsContentViewQuery$data;
  variables: Nav4AppsContentViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  }
],
v1 = {
  "kind": "ScalarField",
  "name": "label"
},
v2 = {
  "kind": "ScalarField",
  "name": "styleClass"
},
v3 = {
  "kind": "ScalarField",
  "name": "url"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "Nav4AppsContentViewQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "Nav4AppsContent"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Nav4AppsContentViewQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "cloudId",
                "variableName": "cloudId"
              }
            ],
            "concreteType": "JiraNavigationItemConnection",
            "kind": "LinkedField",
            "name": "globalAppNavigationItems",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraNavigationItemEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "id"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v1/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "appId"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "appType"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "envLabel"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "iconUrl"
                          },
                          (v2/*: any*/),
                          {
                            "concreteType": "JiraAppSection",
                            "kind": "LinkedField",
                            "name": "sections",
                            "plural": true,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "concreteType": "JiraAppNavigationItemNestedLink",
                                "kind": "LinkedField",
                                "name": "links",
                                "plural": true,
                                "selections": [
                                  (v1/*: any*/),
                                  (v3/*: any*/),
                                  (v2/*: any*/)
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "settingsUrl"
                          },
                          (v3/*: any*/)
                        ],
                        "type": "JiraAppNavigationItem"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "b0598bd12791c820d9fd37010fdcfaa71a2efec5a91d1ad262f20eabce79217d",
    "metadata": {},
    "name": "Nav4AppsContentViewQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "157a8cfe104b1d671cc09c5f8aee481a";

export default node;

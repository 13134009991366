/* eslint-disable complexity */
import React from 'react';
import { Section, SkeletonItem } from '@atlaskit/side-navigation';
import { getBaseAutomationUrl } from '@atlassian/jira-automation-menu/src/common/utils.tsx';
import { type Project, CORE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_DETAILS,
	ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_CORE_PROFORMA_FORMS,
	ROUTE_NAMES_PROJECT_SETTINGS_VERSIONS,
	ROUTE_NAMES_PROJECT_SETTINGS_COMPONENTS,
	ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_COLLECTORS,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { PROJECT_SETTINGS_PAGE_MODULE } from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import { useIntl, type IntlShape } from '@atlassian/jira-intl';
import { EngagementSpotlight } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/engagement-spotlight/main.tsx';
import { ExpandableSubmenuItem } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/expandable-submenu-item/index.tsx';
import { FireUiAnalytics } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/fire-ui-analytics/index.tsx';
import { MenuItem } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/menu-item/main.tsx';
import { SpotlightTarget } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/spotlight-target/index.tsx';
import { TitleHeader } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/title-header/main.tsx';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project/index.tsx';
import { NotificationsSubMenu } from '@atlassian/jira-navigation-apps-sidebar-common/src/ui/notifications-sub-menu/index.tsx';
import { withThrowOnError } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/throw-when-no-data-and-error/index.tsx';
import { matchUrlPathname } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/url-matchers/index.tsx';
import EcosystemMenu from '@atlassian/jira-navigation-apps-sidebar-ecosystem/src/ui/menu/index.tsx';
import {
	useNewIssueViewLockedInStatus,
	DEFAULT_TO_OLD_ISSUE_VIEW,
} from '@atlassian/jira-router-resources-new-issue-view-transition-lock-in-status/src/services/index.tsx';
import { toProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useDlpEntitlement } from '@atlassian/jira-tenant-context-controller/src/components/dlp-entitlement/index.tsx';
import { useSettingFeatures } from '../../../../../../controllers/features/index.tsx';
import messages from './messages.tsx';
import type { SettingsProps } from './types.tsx';

export const useThrowProject = withThrowOnError(useProject);
export const useThrowSettingFeatures = withThrowOnError(useSettingFeatures);
export const useThrowNewIssueViewLockedInStatus = withThrowOnError(useNewIssueViewLockedInStatus);

interface DisplayNotificationsOptionsProps {
	hasNotifications: boolean;
	formatMessage: IntlShape['formatMessage'];
	project: Project;
}

const DisplayNotificationsOptions = ({
	hasNotifications,
	formatMessage,
	project,
}: DisplayNotificationsOptionsProps) => {
	if (!hasNotifications) {
		return <></>;
	}

	return (
		<ExpandableSubmenuItem
			analytics={{ itemId: 'notifications' }}
			menuItemLabel={formatMessage(messages.notifications)}
			forceExpand
			submenuId="core-notifications-settings"
		>
			<NotificationsSubMenu
				settingsUrl={`/plugins/servlet/project-config/${project.key}/notifications`}
				issueLogsUrl={`/jira/core/projects/${project.key}/settings/notifications/issue-logs?projectId=${project.id}`}
			/>
		</ExpandableSubmenuItem>
	);
};

type IssueLayoutOptionProps = {
	isNewIssueViewLockedIn: boolean;
	hasIssueLayout: boolean;
	formatMessage: IntlShape['formatMessage'];
	project: Project;
};

// will be cleaned with FF cleanup
const IssueLayoutOption = ({
	isNewIssueViewLockedIn,
	hasIssueLayout,
	formatMessage,
	project,
}: IssueLayoutOptionProps) => {
	if (ff('olympus-da-157-enable-project-settings-navigation_zxaq5')) {
		return (
			hasIssueLayout &&
			isNewIssueViewLockedIn && (
				<MenuItem
					selectedOn={matchUrlPathname()}
					analytics={{ itemId: 'issue-layout' }}
					href={`/plugins/servlet/project-config/${project.key}/issuelayout`}
				>
					{formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.issueLayoutIssueTermRefresh
							: messages.issueLayout,
					)}
				</MenuItem>
			)
		);
	}

	return (
		isNewIssueViewLockedIn && (
			<MenuItem
				selectedOn={matchUrlPathname()}
				analytics={{ itemId: 'issue-layout' }}
				href={`/plugins/servlet/project-config/${project.key}/issuelayout`}
			>
				{formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.issueLayoutIssueTermRefresh
						: messages.issueLayout,
				)}
			</MenuItem>
		)
	);
};

export const Settings = ({ ecosystemItems }: SettingsProps) => {
	const { data: features } = useThrowSettingFeatures();
	const { data: status } = useThrowNewIssueViewLockedInStatus();
	const isNewIssueViewLockedIn =
		status?.newIssueViewTransitionLockingStatus !== DEFAULT_TO_OLD_ISSUE_VIEW;
	const { formatMessage } = useIntl();
	const { data: project } = useThrowProject();
	const hasDlpEnabled = useDlpEntitlement();

	if (features == null || project == null) {
		return <SkeletonItem />;
	}

	return (
		<>
			<TitleHeader>{formatMessage(messages.projectSettings)}</TitleHeader>
			<Section>
				{features.hasDetails && (
					<MenuItem
						analytics={{ itemId: 'details' }}
						selectedOn={[ROUTE_NAMES_PROJECT_SETTINGS_CORE_DETAILS]}
						href={`/jira/core/projects/${project.key}/settings/details`}
					>
						{formatMessage(messages.details)}
					</MenuItem>
				)}
				{features.hasPeople && (
					<MenuItem
						analytics={{ itemId: 'people' }}
						selectedOn={matchUrlPathname()}
						href={`/plugins/servlet/project-config/${project.key}/people`}
					>
						{formatMessage(messages.people)}
					</MenuItem>
				)}
				{features.hasAutomation && (
					<>
						<SpotlightTarget name="spotlight-sidebar-project-automation">
							<MenuItem
								analytics={{ itemId: 'automation' }}
								selectedOn={matchUrlPathname()}
								href={getBaseAutomationUrl({
									isSimplified: project.isSimplified,
									projectKey: toProjectKey(project.key),
									projectType: CORE_PROJECT,
								})}
							>
								{formatMessage(messages.automation)}
							</MenuItem>
						</SpotlightTarget>
						<EngagementSpotlight engagementId="spotlight-sidebar-project-automation" />
						<FireUiAnalytics actionSubject="automationNavigationItem" action="viewed" />
					</>
				)}
				{features.hasSummary && (
					<MenuItem
						analytics={{ itemId: 'summary' }}
						selectedOn={matchUrlPathname()}
						href={`/plugins/servlet/project-config/${project.key}/summary`}
					>
						{formatMessage(messages.summary)}
					</MenuItem>
				)}
			</Section>
			<Section
				hasSeparator={
					features.hasIssueConfigSection ||
					isNewIssueViewLockedIn ||
					fg('proforma_mvp_jwm_project_settings')
				}
			>
				{features.hasIssueTypes && (
					<MenuItem
						selectedOn={matchUrlPathname()}
						analytics={{ itemId: 'issue-types' }}
						href={`/plugins/servlet/project-config/${project.key}/issuetypes`}
					>
						{formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.issueTypesIssueTermRefresh
								: messages.issueTypes,
						)}
					</MenuItem>
				)}
				<IssueLayoutOption
					isNewIssueViewLockedIn={isNewIssueViewLockedIn}
					hasIssueLayout={features.hasIssueLayout}
					formatMessage={formatMessage}
					project={project}
				/>
				{fg('proforma_mvp_jwm_project_settings') && (
					<MenuItem
						analytics={{ itemId: 'proforma-forms' }}
						href={`/jira/core/projects/${project.key}/settings/forms`}
						selectedOn={[ROUTE_NAMES_CLASSIC_PROJECT_SETTINGS_CORE_PROFORMA_FORMS]}
					>
						{formatMessage(messages.forms)}
					</MenuItem>
				)}
			</Section>
			<Section hasSeparator={features.hasProjectGroupTwoSection}>
				{features.hasWorkflows && (
					<MenuItem
						selectedOn={matchUrlPathname()}
						analytics={{ itemId: 'workflows' }}
						href={`/plugins/servlet/project-config/${project.key}/workflows`}
					>
						{formatMessage(messages.workflows)}
					</MenuItem>
				)}
				{features.hasScreens && (
					<MenuItem
						selectedOn={matchUrlPathname()}
						analytics={{ itemId: 'screens' }}
						href={`/plugins/servlet/project-config/${project.key}/screens`}
					>
						{formatMessage(messages.screens)}
					</MenuItem>
				)}
				{features.hasSystemFieldsConfiguration && (
					<MenuItem
						selectedOn={matchUrlPathname()}
						analytics={{ itemId: 'fields' }}
						href={`/plugins/servlet/project-config/${project.key}/fields`}
					>
						{formatMessage(messages.fields)}
					</MenuItem>
				)}
			</Section>
			<Section hasSeparator={features.hasProjectGroupThreeSection}>
				{features.hasVersions && (
					<MenuItem
						selectedOn={[ROUTE_NAMES_PROJECT_SETTINGS_VERSIONS]}
						analytics={{ itemId: 'versions' }}
						href={`/plugins/servlet/project-config/${project.key}/administer-versions`}
					>
						{formatMessage(messages.versions)}
					</MenuItem>
				)}
				{features.hasComponents && (
					<MenuItem
						selectedOn={[ROUTE_NAMES_PROJECT_SETTINGS_COMPONENTS]}
						analytics={{ itemId: 'components' }}
						href={`/plugins/servlet/project-config/${project.key}/administer-components`}
					>
						{formatMessage(messages.components)}
					</MenuItem>
				)}
			</Section>
			<Section hasSeparator={features.hasProjectGroupFourSection}>
				{features.hasPermissions && (
					<MenuItem
						selectedOn={matchUrlPathname()}
						analytics={{ itemId: 'permissions' }}
						href={`/plugins/servlet/project-config/${project.key}/permissions`}
					>
						{formatMessage(messages.permissions)}
					</MenuItem>
				)}
				{features.hasIssueSecurity && (
					<MenuItem
						selectedOn={matchUrlPathname()}
						analytics={{ itemId: 'issue-security' }}
						href={`/plugins/servlet/project-config/${project.key}/issuesecurity`}
					>
						{formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.issueSecurityIssueTermRefresh
								: messages.issueSecurity,
						)}
					</MenuItem>
				)}
				<DisplayNotificationsOptions
					hasNotifications={features.hasNotifications}
					formatMessage={formatMessage}
					project={project}
				/>
				{features.hasDevtools && (
					<MenuItem
						selectedOn={matchUrlPathname()}
						analytics={{ itemId: 'devtools' }}
						href={`/secure/ConfigureDevStatus.jspa?projectKey=${project.key}`}
					>
						{formatMessage(messages.devtools)}
					</MenuItem>
				)}
			</Section>
			<Section hasSeparator={features.hasgetIssueCollectorSection}>
				{features.hasIssueCollectors && (
					<MenuItem
						selectedOn={[ROUTE_NAMES_LEGACY_PROJECT_SETTINGS_COLLECTORS]}
						analytics={{ itemId: 'issue-collectors' }}
						href={`/secure/ViewCollectors!default.jspa?projectKey=${project.key}`}
					>
						{formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.issueCollectorsIssueTermRefresh
								: messages.issueCollectors,
						)}
					</MenuItem>
				)}
			</Section>
			<EcosystemMenu
				hasSeparator
				items={ecosystemItems}
				forgeModule={PROJECT_SETTINGS_PAGE_MODULE}
				overrides={{
					BackMenuItem: {
						href: `/plugins/servlet/project-config/${project.key}`,
						title: formatMessage(messages.itemBack),
					},
				}}
			/>
			{hasDlpEnabled && (
				<Section hasSeparator>
					<MenuItem
						analytics={{ itemId: 'data-classifications' }}
						selectedOn={matchUrlPathname()}
						href={`/plugins/servlet/project-config/${project.key}/data-classifications`}
					>
						{formatMessage(messages.dataClassificationsNonFinal)}
					</MenuItem>
				</Section>
			)}
		</>
	);
};

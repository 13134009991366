import memoize from 'lodash/memoize';
import { fg } from '@atlassian/jira-feature-gating';
import { getNav4Rollout } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-nav4-rollout/index.tsx';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';

export const sendNavExposure = memoize(_sendNavExposure, (p) => p.componentName);

type SendNavExposureParams = {
	componentName: string;
};

/**
 * Note: The componentName is being used as the actionSubject.
 *       When a new navigation-version is released, it will require registering
 *       a new GASv3 event and a new trait. THIS IS BY DESIGN, as the creation
 *       of new navigation systems is infrequent (every 2-3 years).
 */
function _sendNavExposure(params: SendNavExposureParams) {
	const { componentName } = params;

	if (fg('jira_mobile_opt_out_nav4_trait')) {
		const nav4RolloutStage = getNav4Rollout().stage;
		/**
		 * Prevent mobile nav4 views from registering the trait as having seen Nav4
		 * This is because mobile users have only seen a small subset of Nav4
		 */
		if (nav4RolloutStage === 'mobile') {
			// Return the component name to help memoize do it's job
			return componentName;
		}
	}
	// This analytics event updates it's corresponding TAP trait,
	// once the GAS event is registered, and then the TAP trait is registered.
	getAnalyticsWebClientPromise().then((client) => {
		client.getInstance().sendTrackEvent({
			actionSubject: componentName,
			action: 'viewed',
			source: 'navExposure',
			attributes: {}, // <--- This is a required prop for the event to be sent.
		});
	});
	// Return the component name to help memoize do it's job
	return componentName;
}

import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import { createJpdExperience } from '../../utils/experience/main.tsx';

const updateViewDescription = createJpdExperience(
	new UFOExperience('jpd.header-view.update-view-description', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const viewGroupBy = createJpdExperience(
	new UFOExperience('jpd.header-view.view-group-by', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const viewSort = createJpdExperience(
	new UFOExperience('jpd.header-view.view-sort', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const viewFilter = createJpdExperience(
	new UFOExperience('jpd.header-view.view-filter', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	updateViewDescription,
	viewGroupBy,
	viewSort,
	viewFilter,
};

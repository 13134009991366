import React from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type CsatSettingsPageType from './index.tsx';

export const LoadingComponent = () => (
	<SpinnerContainer>
		<Spinner size="large" />
	</SpinnerContainer>
);

export const LazyCsatSettingsPage = lazyForPaint<typeof CsatSettingsPageType>(
	() => import(/* webpackChunkName: "async-csat-settings-page" */ './index'),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const SpinnerContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 15}px`,
});

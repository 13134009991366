import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { ROUTE_GROUPS_CLASSIC_BUSINESS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { classicBusinessProjectFormSubmitRoute } from '@atlassian/jira-router-routes-business-routes/src/classicBusinessProjectFormSubmitRoute.tsx';
import { formSubmitPageEntrypoint } from './ui/spa/business/form-submit/index.tsx';

export const classicBusinessProjectFormSubmitRouteEntry = createEntry(
	classicBusinessProjectFormSubmitRoute,
	() => ({
		group: ROUTE_GROUPS_CLASSIC_BUSINESS,
		ufoName: 'jwm.form-submit-view',
		layout: chromelessLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalNavJira,
		},

		resources: getProjectViewResources(),

		entryPoint: () => formSubmitPageEntrypoint,
	}),
);

/**
 * Most of the code here is duplicated from `@atlassian/portfolio-3-summary-page`. We do not want to depend on all
 * the code from that package, but we want to parse and validate the date range before sending to the backend.
 */
import {
	CUSTOM_TIME_RANGE_TYPES,
	DATE_UNITS,
	type CustomTimeRangeType,
	type DateUnit,
	type CustomDateRange,
} from './types.tsx';

export const DATE_UNIT_MAX_VALUES = {
	[DATE_UNITS.DAYS]: 10 * 365,
	[DATE_UNITS.WEEKS]: 10 * 52,
	[DATE_UNITS.MONTHS]: 10 * 12,
};

export const parseDateRangeType = (type?: string): CustomTimeRangeType | undefined => {
	switch (type) {
		case CUSTOM_TIME_RANGE_TYPES.FIXED:
		case CUSTOM_TIME_RANGE_TYPES.RELATIVE:
			return type;
		default:
			return undefined;
	}
};

export const parseNumber = (value?: string): number | undefined => {
	if (value === undefined) return undefined;

	const parsed = parseInt(value, 10);

	return Number.isNaN(parsed) ? undefined : parsed;
};

export const parseDateUnit = (unit?: string): DateUnit | undefined => {
	switch (unit) {
		case DATE_UNITS.DAYS:
		case DATE_UNITS.WEEKS:
		case DATE_UNITS.MONTHS:
			return unit;
		default:
			return undefined;
	}
};

export const parseDateRange = (query: Record<string, string>): CustomDateRange => {
	const { fromDate, toDate, toNow, fromNow, toNowUnit, fromNowUnit, dateRangeType } = query;

	return {
		typeOfCustomDateRange: parseDateRangeType(dateRangeType),
		fromDate: parseNumber(fromDate),
		toDate: parseNumber(toDate),
		fromNowUnitCount: parseNumber(fromNow),
		toNowUnitCount: parseNumber(toNow),
		fromNowUnit: parseDateUnit(fromNowUnit),
		toNowUnit: parseDateUnit(toNowUnit),
	};
};

export const isValidRelativeUnits = (count?: number, unit?: DateUnit) => {
	if (count === undefined || unit === undefined) return false;

	return count >= 0 && count <= DATE_UNIT_MAX_VALUES[unit];
};

export const isValidDateTimestamp = (timestamp?: number) => {
	if (timestamp === undefined) return false;

	const date = new Date(timestamp);

	return !Number.isNaN(date.getTime());
};

export const validateDateRange = (dateRange: CustomDateRange): CustomDateRange | undefined => {
	const {
		fromDate,
		toDate,
		toNowUnitCount,
		toNowUnit,
		fromNowUnitCount,
		fromNowUnit,
		typeOfCustomDateRange,
	} = dateRange;

	if (
		typeOfCustomDateRange === CUSTOM_TIME_RANGE_TYPES.RELATIVE &&
		isValidRelativeUnits(toNowUnitCount, toNowUnit) &&
		isValidRelativeUnits(fromNowUnitCount, fromNowUnit)
	)
		return {
			toNowUnitCount,
			toNowUnit,
			fromNowUnitCount,
			fromNowUnit,
			typeOfCustomDateRange,
		};

	if (
		typeOfCustomDateRange === CUSTOM_TIME_RANGE_TYPES.FIXED &&
		isValidDateTimestamp(fromDate) &&
		isValidDateTimestamp(toDate)
	)
		return {
			fromDate,
			toDate,
			typeOfCustomDateRange,
		};

	return undefined;
};

import { fg } from '@atlassian/jira-feature-gating';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { getServicedeskPageContextResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { workspaceResource } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/services/resources/index.tsx';

export function getCmdbResources() {
	const servicedeskPageContextResources = getServicedeskPageContextResources().map((resource) =>
		resourceWithCondition2(() => fg('add_service_desk_page_context_resources'), resource),
	);
	return [...getNavigationResources(), ...servicedeskPageContextResources, workspaceResource];
}

import type ProjectDirectoryType from '@atlassian/jira-spa-apps-directories-projects-v3/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyProjectsDirectory = lazyForPaint<typeof ProjectDirectoryType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-projects-directory-v3", jiraSpaEntry: "async-projects-directory-v3" */ '@atlassian/jira-spa-apps-directories-projects-v3'
		),
	),
);

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	label: {
		id: 'jql-builder.search-mode-switcher.label',
		defaultMessage: 'Select search mode',
		description:
			'The label which is attached to the mode switcher for selecting between basic and advanced mode. This is not visible in the UI, but is read aloud by assistive technology like screen-readers.',
	},
	basicModeDisabledTooltip: {
		id: 'jql-builder.search-mode-switcher.basic-mode-disabled-tooltip',
		defaultMessage: "You can't switch to basic as the query is too complex or is invalid",
		description:
			'Tooltip content to display when a the switch to the basic mode button is disabled.',
	},
	basicMode: {
		id: 'jql-builder.search-mode-switcher.basic-mode',
		defaultMessage: 'Basic',
		description: 'Button text to display when a user can switch to the basic search mode.',
	},
	advancedMode: {
		id: 'jql-builder.search-mode-switcher.advanced-mode',
		defaultMessage: 'JQL',
		description: 'Button text to display when a user can switch to the advanced search mode.',
	},
});

import { di } from 'react-magnetic-di';
import type { CreatePayload } from '@atlaskit/link-create';
import type { SpaceInfo } from '../controllers/types.tsx';
import { getHostnameFromUrl } from '../controllers/utils/getHostnameFromUrl.tsx';
import { isCustomDomain } from '../controllers/utils/isCustomDomain.tsx';
import type { CreateConfluenceDraftV2Response } from '../services/confluence-rest-api/types.tsx';

export const getDraftShareId = (uri?: string) => {
	if (!uri || uri === '') return '';
	const matches = uri.match(/draftShareId=([^&])*/g);

	return matches ? matches[0].split('=')[1] : '';
};

export const convertUrlSpaceIdToSpaceKey = ({
	url,
	spaceId,
	spaceKey,
}: {
	url?: string;
	spaceId?: string;
	spaceKey?: string;
}) => {
	if (!(url && spaceId && spaceKey)) return '';
	return url.replace(`spaces/${spaceId}`, `spaces/${spaceKey}`);
};

export const createActivePageUrl = (createPayload: CreatePayload) => {
	const { data, url } = createPayload;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const links = data?.links as Record<string, string>;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const spaceKey = data?.spaceKey as string;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const spaceId = data?.spaceId as string;

	const linkableUrl = convertUrlSpaceIdToSpaceKey({
		url,
		spaceId,
		spaceKey,
	});

	if (data?.subType === 'live') {
		return `${linkableUrl}`;
	}
	return `${linkableUrl}?draftShareId=${getDraftShareId(links?.editui)}`;
};

export const getURLFromConfluencePageResponse = ({
	id,
	spaceKey,
	hostname,
}: {
	id: string;
	spaceKey: string;
	hostname: string;
}) => `${hostname}/wiki/spaces/${spaceKey}/pages/edit-v2/${id}/`;

const isUrlCustomDomain = (parentHost: string, url: string) => {
	const hostname = getHostnameFromUrl(url);

	return isCustomDomain(parentHost) || (hostname && isCustomDomain(hostname));
};

export const setHostnameForContent = (url: string, hostname?: string) => {
	di(window);
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const parentHost = window?.location?.origin || '';
	const contentHost = hostname ?? getHostnameFromUrl(url);

	return isUrlCustomDomain(parentHost, url) ? getHostnameFromUrl(parentHost) : contentHost;
};

export const constructUrlIfCustomDomain = ({
	id,
	originalDomain,
	originalUrl,
	spaceKey,
	systemConfluenceAppLinkUrl,
}: {
	id: string;
	originalDomain: string;
	originalUrl: string;
	spaceKey: string;
	systemConfluenceAppLinkUrl: string;
}) => {
	di(window);
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const currentHostname = window?.location?.origin || '';
	const hostnameFromUrl = getHostnameFromUrl(originalDomain);
	const systemHostName = getHostnameFromUrl(systemConfluenceAppLinkUrl);

	/*
		If content is not on the same tenant, and custom domain is enabled, we need to update the hostname
		to reflect the parentProduct's hostname
	*/
	if (systemHostName !== hostnameFromUrl && isUrlCustomDomain(currentHostname, originalDomain)) {
		return getURLFromConfluencePageResponse({
			id,
			spaceKey,
			hostname: currentHostname,
		});
	}

	return originalUrl;
};

export const createLinkCreatePayload = (
	createResponse: CreateConfluenceDraftV2Response,
	url: string,
	space: SpaceInfo,
	cloudId: string,
): CreatePayload => ({
	url,
	ari: `ari:cloud:confluence:${cloudId}:page/${createResponse.id}`,
	objectId: createResponse.id,
	objectType: 'page',
	data: {
		spaceId: space.spaceId,
		spaceKey: space.spaceKey,
		spaceName: space.spaceName,
		status: createResponse.status,
		subType: createResponse.subType,
		links: createResponse._links,
	},
});

import React from 'react';
import type { ARTICLE_TYPE } from '@atlaskit/help';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { FormattedMessage } from '@atlassian/jira-intl';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { Flag } from '../../../common/ui/flag/index.tsx';
import { messages } from './messages.tsx';

interface Props {
	onDismiss: () => void;
	openInProductHelp: (id: string, type?: keyof typeof ARTICLE_TYPE) => void;
}

export const FinishFlag = ({ onDismiss, openInProductHelp }: Props) => (
	<>
		<FireUiAnalytics
			actionSubject="flag"
			action="viewed"
			actionSubjectId="finishedAdvancedRoadmapsOnboarding"
		/>
		<Flag
			isAutoDismissed
			icon={<CheckCircleIcon label="" primaryColor={token('color.icon.success', colors.G400)} />}
			onDismissed={onDismiss}
			title={<FormattedMessage {...messages.flagTitle} />}
			description={<FormattedMessage {...messages.plansFlagContent} />}
			actions={[
				{
					content: <FormattedMessage {...messages.flagClose} />,
					onClick: onDismiss,
				},
				{
					content: <FormattedMessage {...messages.flagViewDocumentation} />,
					onClick: () => openInProductHelp(''),
				},
			]}
		/>
	</>
);

import React, { memo, useCallback } from 'react';
import { styled } from '@compiled/react';
import StarFilledIcon from '@atlaskit/icon/core/migration/star-starred--star-filled';
import StarIcon from '@atlaskit/icon/core/migration/star-unstarred--star';
import { Box } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { NavigationProject } from '@atlassian/jira-router-resources-business-navigation/src/types.tsx';
import messages from './messages.tsx';

type Props = {
	project: NavigationProject;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onToggle: (favorite: boolean) => any;
};

const FavoriteButton = ({ project, onToggle }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const label = project.isFavorite ? formatMessage(messages.unstar) : formatMessage(messages.star);

	const onClick = useCallback(() => {
		const star = !project.isFavorite;

		onToggle(star);

		fireUIAnalytics(createAnalyticsEvent({}), 'project starred', 'sidebar', {
			projectId: project.id,
			star,
		});
	}, [project, onToggle, createAnalyticsEvent]);

	return (
		<Box padding="space.025">
			<Tooltip content={label} hideTooltipOnClick>
				<Button
					isFavorite={project.isFavorite ?? false}
					onClick={onClick}
					data-testid="navigation-apps-sidebar-core.common.ui.favorite-button"
				>
					{project.isFavorite ? (
						<StarFilledIcon color="currentColor" label={label} />
					) : (
						<StarIcon color="currentColor" label={label} />
					)}
				</Button>
			</Tooltip>
		</Box>
	);
};

export default memo(FavoriteButton);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Button = styled.button<{ isFavorite: boolean }>({
	display: 'flex',
	alignItems: 'center',
	padding: 0,
	border: 'none',
	background: 'none',
	cursor: 'pointer',
	borderRadius: token('border.radius', '3px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: ({ isFavorite }) => (isFavorite ? colors.Y300 : token('color.text.subtle', colors.N500)),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&active, &:focus:not(:focus-visible)': {
		boxShadow: 'none',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:hover, &active, &:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: ({ isFavorite }) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			isFavorite ? colors.Y200 : token('color.text.subtlest', colors.N200),
	},
});

import React, { type ReactNode } from 'react';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { fg } from '@atlassian/jira-feature-gating';

type SpotlightTargetMaybeProps = {
	children: ReactNode;
	spotlight?: string;
};

export function OnboardingSpotlightMaybe({ children, spotlight }: SpotlightTargetMaybeProps) {
	if (!fg('blu-2735_nav4_onboarding')) {
		return <>{children}</>;
	}

	return spotlight ? (
		<SpotlightTarget name={spotlight}>{children}</SpotlightTarget>
	) : (
		<>{children}</>
	);
}

/**
 * @generated SignedSource<<82d4082dbf559e3b5917e50a23b18d12>>
 * @relayHash 16b75172b263b3083574f39046443e9e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 29684e66765adebbd7d6e69bd5c6b96f7397cf43fe3f10efb2af8334d7710102

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessListQuery } from './src_jiraBusinessListQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessListQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "29684e66765adebbd7d6e69bd5c6b96f7397cf43fe3f10efb2af8334d7710102",
    "metadata": {},
    "name": "src_jiraBusinessListQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

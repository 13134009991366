import React from 'react';

import Placeholder from '@atlaskit/react-ufo/placeholder';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

import type { HorizontalNav } from './main.tsx';

const LazyHorizontalSupportCenterNav = lazyForPaint<typeof HorizontalNav>(() =>
	import(/* webpackChunkName: "async-support-center-horizontal-nav" */ './main.tsx').then(
		({ HorizontalNav }) => HorizontalNav,
	),
);

export const AsyncSupportCenterHorizontalNav = () => {
	return (
		<Placeholder name="support-center-settings-nav" fallback={null}>
			<LazyHorizontalSupportCenterNav />
		</Placeholder>
	);
};

import { useEffect } from 'react';
import type { UseResourceReturn } from '../types.tsx';

/**
 * Calls refresh() for the provided `resource`, to trigger the (initial) data fetch.
 * @param resource
 * @param shouldPrefetch - indicates if the resource should be prefetched. If `true`, `refresh()` will be called once when the component is mounted.
 */
export function usePrefetchableResource<TResource>(
	resource: UseResourceReturn<TResource>,
	shouldPrefetch: boolean,
) {
	useEffect(() => {
		if (!shouldPrefetch) {
			return;
		}

		// "false" is a valid value for data here. We only want to trigger a refresh if data is null or undefined.
		if (
			(resource.data === null || resource.data === undefined) &&
			!resource.loading &&
			!resource.error
		) {
			resource.refresh();
		}
		// we only need this to happen only once at initial time. In addition, some resources return `data` = null when user does not have permissions etc., which makes all `data`, `loading` and `error` have falsy values.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}

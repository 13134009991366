import React from 'react';
import { Redirect, type RouteContext } from '@atlassian/react-resource-router';

export const VersionDetailPageLegacyRedirect = ({
	match: {
		params: { projectKey, versionId, tab },
	},
}: RouteContext) => {
	// This should never happen as if it wasn't matched, this won't be rendered unless someone re-used this component in a wrong route.
	if (projectKey == null || versionId == null) {
		throw Error('projectKey or versionId not found in match object of RouteContext');
	}
	return (
		<Redirect
			to={`/projects/${projectKey}/versions/${versionId}/tab/${
				tab != null ? tab : 'release-report-all-issues'
			}`}
		/>
	);
};

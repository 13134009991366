import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarAssetsReports from '@atlassian/jira-navigation-apps-sidebar-assets-reports/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { getServicedeskPageContextResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { servicedeskCmdbAssetsReportsRoute } from '@atlassian/jira-router-routes-servicedesk-cmdb-routes/src/servicedeskCmdbAssetsReportsRoute.tsx';
import {
	toPackageName,
	APP_NAMES,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import { workspaceResource } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/services/resources/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { sharedRouteProps } from './common/constants.tsx';
import { AssetsReports, LazyServicedeskCmdbAssetsReports } from './ui/reports/index.tsx';

export const servicedeskCmdbAssetsReportsTemplateRouteEntry: Route = createEntry(
	servicedeskCmdbAssetsReportsRoute,
	() => ({
		...sharedRouteProps,
		component: componentWithFF('jsm-assets-reporting_seqk0', AssetsReports, ErrorPagesNotFound),
		resources: [
			...getNavigationResources(),
			...getServicedeskPageContextResources(),
			workspaceResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbAssetsReports],
		navigation: {
			menuId: MENU_ID.PEOPLE,
			sidebar: AsyncSidebarAssetsReports,
		},
		meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_ASSETS_REPORTS,
				packageName: toPackageName(APP_NAMES.INSIGHT_ASSETS_REPORTS),
				teamName: 'ITSM SAGA',
			},
		},
		perfMetricKey: 'service-management.insight-cmdb.load-assets-reports-template',
	}),
);

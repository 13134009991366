import React from 'react';
import type OnboardingGetStartedPageType from '@atlassian/jira-spa-apps-onboarding-get-started/src/index.tsx';
import type SoftwareOnboardingPageType from '@atlassian/jira-spa-apps-software-onboarding/src/index.server.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazySoftwareOnboardingPage = lazyForPaint<typeof SoftwareOnboardingPageType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-software-onboarding", jiraSpaEntry: "async-software-onboarding" */ '@atlassian/jira-spa-apps-software-onboarding'
		),
	),
);

export const LazyOnboardingGetStartedPage = lazyForPaint<typeof OnboardingGetStartedPageType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-onboarding-get-started", jiraSpaEntry: "async-onboarding-get-started" */ '@atlassian/jira-spa-apps-onboarding-get-started'
		),
	),
);

export const SoftwareOnboardingPage = () => (
	<LazyPage
		Page={LazySoftwareOnboardingPage}
		pageId="software-onboarding"
		shouldShowSpinner={false}
	/>
);

export const OnboardingGetStartedPage = () => (
	<LazyPage
		Page={LazyOnboardingGetStartedPage}
		pageId="onboarding-get-started"
		shouldShowSpinner={false}
	/>
);

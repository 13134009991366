import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { ROUTE_GROUPS_CLASSIC_BUSINESS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { classicBusinessProjectCalendarEmbedRoute } from '@atlassian/jira-router-routes-business-routes/src/classicBusinessProjectCalendarEmbedRoute.tsx';
import {
	calendarEmbedPageEntrypoint,
	legacyCalendarEmbedPageEntrypoint,
} from './ui/spa/business/calendar-embed/index.tsx';

export const classicBusinessProjectCalendarEmbedRouteEntry = createEntry(
	classicBusinessProjectCalendarEmbedRoute,
	() => ({
		group: ROUTE_GROUPS_CLASSIC_BUSINESS,
		ufoName: 'jwm.calendar-embed-view',

		entryPoint() {
			return expValEquals('business_projects_shared_calendar', 'cohort', 'variation')
				? calendarEmbedPageEntrypoint
				: legacyCalendarEmbedPageEntrypoint;
		},

		layout: chromelessLayout,
		skeleton: ThemedLazySkeleton,
		resources: getProjectViewResources(),
	}),
);

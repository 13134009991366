// @generated <<SignedSource::*O*zOeWoEQle#+L!plEphiEmie@IsG>>
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';

export default {
	get(): boolean {
		return (
			fg('relay-migration-issue-fields-multi-user-fg') ||
			ff('relay-migration-issue-fields-participants')
		);
	},
};

import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import { createJpdExperience } from '../../utils/experience/main.tsx';

const pageLoad = createJpdExperience(
	new UFOExperience('jpd.collections', {
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageLoad,
	}),
);

const create = createJpdExperience(
	new UFOExperience('jpd.collections.create', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadCollections = createJpdExperience(
	new UFOExperience('jpd.collections.load-collections', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadViewCount = createJpdExperience(
	new UFOExperience('jpd.collections.load-view-count', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadUsers = createJpdExperience(
	new UFOExperience('jpd.collections.load-users', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadProjects = createJpdExperience(
	new UFOExperience('jpd.collections.load-projects', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const createCollection = createJpdExperience(
	new UFOExperience('jpd.collections.create-collection', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const copyCollection = createJpdExperience(
	new UFOExperience('jpd.collections.copy-collection', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const deleteCollection = createJpdExperience(
	new UFOExperience('jpd.collections.delete-collection', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const updateCollection = createJpdExperience(
	new UFOExperience('jpd.collections.update-collection', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadCollection = createJpdExperience(
	new UFOExperience('jpd.collections.load-collection', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadCollectionViews = createJpdExperience(
	new UFOExperience('jpd.collections.load-collection-views', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadCollectionDescription = createJpdExperience(
	new UFOExperience('jpd.collections.load-collection-description', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const updateCollectionDescription = createJpdExperience(
	new UFOExperience('jpd.collections.update-collection-description', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const createView = createJpdExperience(
	new UFOExperience('jpd.collections.create-view', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const deleteView = createJpdExperience(
	new UFOExperience('jpd.collections.delete-view', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	pageLoad,
	create,
	loadCollections,
	loadViewCount,
	loadUsers,
	loadProjects,
	createCollection,
	copyCollection,
	loadCollection,
	loadCollectionViews,
	deleteCollection,
	updateCollection,
	loadCollectionDescription,
	updateCollectionDescription,
	createView,
	deleteView,
};

/** @jsx jsx */
import type { PropsWithChildren } from 'react';
import { jsx } from '@compiled/react';
import { cssMap } from '@atlaskit/css';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { MenuListItem } from '@atlassian/navigation-system';

const styles = cssMap({
	box: {
		marginLeft: token('space.050'),
	},
});

export function EmptyContentMenuItem({ children }: PropsWithChildren) {
	return (
		<MenuListItem>
			<Box paddingInline="space.050" xcss={styles.box}>
				{children}
			</Box>
		</MenuListItem>
	);
}

import parameters from '@atlassian/jira-relay/src/__generated__/Nav4ProjectsContentViewQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = { cloudId: string; simplifyBoardQuery: boolean };

export const asyncNav4ProjectsContentViewQuery = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-nav4-projects-content-view-query" */ './Nav4ProjectsContentViewQuery'
		).then(({ Nav4ProjectsContentViewQuery }) => Nav4ProjectsContentViewQuery),
	),
	getPreloadProps: ({ cloudId, simplifyBoardQuery }: EntryPointParams) => ({
		queries: {
			projects: {
				parameters,
				variables: {
					cloudId,
					simplifyBoardQuery,
				},
			},
		},
	}),
});

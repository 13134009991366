import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	error: {
		id: 'jql-builder-basic.common.field-description-popup-common.error',
		defaultMessage:
			'We cannot show the field description at this time. Please refresh to try again.',
		description:
			'inform user there was a problem loading the custom field description and that it cannot be shown. Instruct them to try again later',
	},
});

export default messages;

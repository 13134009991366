import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { servicedeskCmdbInsightObjectSchemaConfigurationRoute } from '@atlassian/jira-router-routes-servicedesk-cmdb-routes/src/servicedeskCmdbInsightObjectSchemaConfigurationRoute.tsx';
import {
	toPackageName,
	APP_NAMES,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { sharedRouteProps } from './common/constants.tsx';
import { getCmdbResources } from './common/getCmdbResources.tsx';
import ObjectSchemaConfiguration, {
	LazyServicedeskCmdbObjectSchemaConfiguration,
} from './ui/object-schema-configuration/index.tsx';

export const servicedeskCmdbInsightObjectSchemaConfigurationRouteEntry: Route = createEntry(
	servicedeskCmdbInsightObjectSchemaConfigurationRoute,
	() => ({
		...sharedRouteProps,
		component: ObjectSchemaConfiguration,
		resources: getCmdbResources(),
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbObjectSchemaConfiguration],
		perfMetricKey: 'service-management.insight-cmdb.interact-with-object-schema-config',
		meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_GLOBAL_CONFIGURATION,
				packageName: toPackageName(APP_NAMES.INSIGHT_OBJECT_SCHEMA_CONFIGURATION),
				teamName: 'krispy-krew',
			},
		},
	}),
);

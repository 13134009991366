import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	archived: {
		id: 'jet-plan-action-menu.archive-modal.archived',
		defaultMessage: 'Plan archived successfully',
		description:
			'Message to be shown in a success flag upon completing the archive plan operation.',
	},
	restored: {
		id: 'jet-plan-action-menu.archive-modal.restored',
		defaultMessage: 'Plan restored successfully',
		description:
			'Message to be shown in a success flag upon completing the restore plan operation.',
	},
	archiveErrorTitle: {
		id: 'jet-plan-action-menu.archive-modal.archive-error-title',
		defaultMessage: "We couldn't archive your plan",
		description: 'Message to be shown in an error flag if the archive plan operation fails.',
	},
	archiveErrorDescription: {
		id: 'jet-plan-action-menu.archive-modal.archive-error-description',
		defaultMessage: "We couldn't archive {planTitle}.\nRefresh the page and try again.",
		description: 'Message to be shown in an error flag if the archive plan operation fails.',
	},
	restoreErrorTitle: {
		id: 'jet-plan-action-menu.archive-modal.restore-error-title',
		defaultMessage: "We couldn't restore your plan",
		description: 'Message to be shown in an error flag if the restore plan operation fails.',
	},
	restoreErrorDescription: {
		id: 'jet-plan-action-menu.archive-modal.restore-error-description',
		defaultMessage: "We couldn't restore {planTitle}.\nRefresh the page and try again.",
		description: 'Message to be shown in an error flag if the restore plan operation fails.',
	},
});

/**
 * Based on custom icon example:
 * https://atlassian.design/components/icon/custom-icon/examples
 */

import React from 'react';
import Icon from '@atlaskit/icon';
import type { CustomGlyphProps, IconProps } from '@atlaskit/icon/types';

const JSMAssetsDataManagerGlyph = (props: CustomGlyphProps) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		data-testid={props['data-testid']}
		aria-label={props['aria-label']}
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7.5 6.5C7.5 7.05228 7.05228 7.5 6.5 7.5C5.94772 7.5 5.5 7.05228 5.5 6.5C5.5 5.94772 5.94772 5.5 6.5 5.5C7.05228 5.5 7.5 5.94772 7.5 6.5ZM8.88555 7.25C8.56698 8.26428 7.61941 9 6.5 9C5.11929 9 4 7.88071 4 6.5C4 5.11929 5.11929 4 6.5 4C7.61941 4 8.56698 4.73572 8.88555 5.75H10.5523C11.697 5.75 12.625 6.67797 12.625 7.82267V8.5V11.25H14.5H15.1145C15.433 10.2357 16.3806 9.5 17.5 9.5C18.8807 9.5 20 10.6193 20 12C20 13.3807 18.8807 14.5 17.5 14.5C16.3806 14.5 15.433 13.7643 15.1145 12.75H14.5H12.625V15.5V16.1773C12.625 17.322 11.697 18.25 10.5523 18.25H8.88555C8.56698 19.2643 7.61941 20 6.5 20C5.11929 20 4 18.8807 4 17.5C4 16.1193 5.11929 15 6.5 15C7.61941 15 8.56698 15.7357 8.88555 16.75H10.5523C10.8686 16.75 11.125 16.4936 11.125 16.1773V15.5V12.75V12V11.25V8.5V7.82267C11.125 7.50639 10.8686 7.25 10.5523 7.25H8.88555ZM6.5 18.5C7.05228 18.5 7.5 18.0523 7.5 17.5C7.5 16.9477 7.05228 16.5 6.5 16.5C5.94772 16.5 5.5 16.9477 5.5 17.5C5.5 18.0523 5.94772 18.5 6.5 18.5ZM18.5 12C18.5 12.5523 18.0523 13 17.5 13C16.9477 13 16.5 12.5523 16.5 12C16.5 11.4477 16.9477 11 17.5 11C18.0523 11 18.5 11.4477 18.5 12Z"
			fill="currentColor"
		/>
	</svg>
);

const JSMAssetsDataManagerIcon = (props: IconProps) => (
	<Icon {...props} glyph={JSMAssetsDataManagerGlyph} />
);

export default JSMAssetsDataManagerIcon;

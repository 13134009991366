/**
 * @generated SignedSource<<6139076109a0e91490e1fa3e8272c1fa>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4FilterMenuItem$data = {
  readonly filterId: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4FilterActions">;
  readonly " $fragmentType": "Nav4FilterMenuItem";
};
export type Nav4FilterMenuItem$key = {
  readonly " $data"?: Nav4FilterMenuItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4FilterMenuItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "Nav4FilterMenuItem",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "filterId"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "FragmentSpread",
      "name": "Nav4FilterActions"
    }
  ],
  "type": "JiraFilter",
  "abstractKey": "__isJiraFilter"
};

(node as any).hash = "db4e5c3fb6fe1fa05a10eea572e428b4";

export default node;

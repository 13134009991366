import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { MENU_ID_DASHBOARDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { useSidebarNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-context/index.tsx';
import { ActionButtons } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-dashboards/src/common/ui/action-buttons/ActionButtons.tsx';
import { DashboardsForLanding } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-dashboards/src/ui/dashboards/for-landing/DashboardsForLanding.tsx';
import type { Nav4Dashboards$key } from '@atlassian/jira-relay/src/__generated__/Nav4Dashboards.graphql';
import { AnalyticContextProvider } from '../../common/controllers/analytic-context/index.tsx';
import { DashboardsDefault } from './default/index.tsx';

export type Nav4DashboardsProps = {
	isExpanded: boolean;
	queryRef: Nav4Dashboards$key;
};

export function Nav4DashboardsContainer(props: Nav4DashboardsProps) {
	return (
		<AnalyticContextProvider>
			<Nav4Dashboards {...props} />
		</AnalyticContextProvider>
	);
}

export function Nav4Dashboards({ isExpanded, queryRef }: Nav4DashboardsProps) {
	const data = useFragment<Nav4Dashboards$key>(
		graphql`
			fragment Nav4Dashboards on Query {
				...DashboardsForLanding @include(if: $isDashboardsExpanded)
			}
		`,
		queryRef,
	);
	const { isInitialSelectedPath } = useSidebarNav4();
	const actionButtons = useMemo(() => <ActionButtons />, []);

	return isInitialSelectedPath(MENU_ID_DASHBOARDS) ? (
		<DashboardsForLanding actionsOnHover={actionButtons} isExpanded={isExpanded} queryRef={data} />
	) : (
		<DashboardsDefault actionsOnHover={actionButtons} isExpanded={isExpanded} />
	);
}

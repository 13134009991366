import React from 'react';

import { useIntl } from 'react-intl-next';

import { SkeletonItem } from '@atlaskit/menu';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { MenuListItem } from '@atlassian/navigation-system/side-nav/menu-list-item';

import { messages } from '../messages';

export const LoadingState = () => {
	const { formatMessage } = useIntl();
	const items = Array.from({ length: 5 }, (_, index) => (
		<MenuListItem key={index}>
			<SkeletonItem isShimmering width="100%" />
		</MenuListItem>
	));

	return (
		<>
			<VisuallyHidden>{formatMessage(messages.loadingStateVisuallyHiddenLabel)}</VisuallyHidden>
			{items}
		</>
	);
};

import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './common/constants.tsx';
import {
	type Nav4OverviewsProps,
	Nav4Overviews as Nav4OverviewsWithoutErrorBoundary,
} from './ui/Nav4Overviews.tsx';

export function Nav4Overviews(props: Nav4OverviewsProps) {
	return (
		<UFOSegment name="nav4-sidebar-overviews">
			<JSErrorBoundary
				fallback="flag"
				id="overviews-section"
				packageName={PACKAGE_NAME}
				teamName={TEAM_NAME}
			>
				<Nav4OverviewsWithoutErrorBoundary {...props} />
			</JSErrorBoundary>
		</UFOSegment>
	);
}

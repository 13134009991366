/** @jsx jsx */
import React from 'react';
import { jsx } from '@compiled/react';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, { type CustomTriggerProps } from '@atlaskit/dropdown-menu';
import AddIcon from '@atlaskit/icon/core/add';
import { AsyncFlyoutMenuContent } from './flyout-menu-content/async.tsx';
import type { BoardCreateFlyoutMenuProps } from './types.tsx';

export const DefaultTrigger = ({ triggerRef, ...props }: CustomTriggerProps) => {
	return (
		<IconButton
			{...props}
			icon={AddIcon}
			label="create board"
			ref={triggerRef}
			testId="software-board-create.ui.flyout-menu.trigger"
		/>
	);
};

export const BoardCreateFlyoutMenu = ({
	renderTrigger: Trigger = DefaultTrigger,
	...props
}: BoardCreateFlyoutMenuProps) => (
	<DropdownMenu<HTMLButtonElement> trigger={Trigger} shouldRenderToParent>
		<AsyncFlyoutMenuContent {...props} />
	</DropdownMenu>
);

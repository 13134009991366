import { metrics } from '@atlassian/browser-metrics';
import performance from '@atlassian/jira-common-performance/src/performance.tsx';
import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';
import { PERFORMANCE_KEYS } from '../../../constants.tsx';
import type {
	InteractionMetric,
	PageSegmentLoadMetric,
	PerformanceMetric,
	Timing,
} from './types.tsx';

export const forgeModulesConcurrentFetchTime = metrics.concurrent.custom({
	key: PERFORMANCE_KEYS.FORGE_MODULES.FETCH_TIME,
});

export const forgeModulesConcurrentLoadTime = metrics.concurrent.custom({
	key: PERFORMANCE_KEYS.FORGE_MODULES.LOAD_TIME,
});

export const realTimeIssueEventInvokeTime = new UFOExperience(
	PERFORMANCE_KEYS.REAL_TIME_ISSUE_EVENTS.INVOKE_TIME,
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	},
);

export async function measureInitialPageLoadAsyncResourcesTiming<T>(
	startMark: string,
	endMark: string,
	fn: () => Promise<T>,
): Promise<T> {
	if (
		__SERVER__ ||
		performance.getEntriesByName(startMark).length > 0 ||
		performance.getEntriesByName(endMark).length > 0
	) {
		return fn();
	}

	performance.mark(startMark);
	const result = await fn();
	performance.mark(endMark);
	return result;
}

export const markAdditionalTimingsFromWindowPerformance = (
	metric: PerformanceMetric,
	additionalTimings: Timing[],
) => {
	additionalTimings
		.flatMap(({ startMark, endMark, cleanStart, cleanEnd }) => [
			...performance.getEntriesByName(startMark).map((entry) => ({
				name: entry.name,
				startTime: entry.startTime,
				cleanStart,
				cleanEnd: undefined,
			})),
			...performance.getEntriesByName(endMark).map((entry) => ({
				name: entry.name,
				startTime: entry.startTime,
				cleanStart: undefined,
				cleanEnd,
			})),
		])
		.forEach(({ name, startTime, cleanStart, cleanEnd }) => {
			metric.mark(name, startTime);

			if (cleanStart || cleanEnd) {
				performance.clearMarks(name);
			}
		});
};

export const customFieldPageSegmentLoadMetric = (
	additionalTimings: Timing[] = [],
): PageSegmentLoadMetric =>
	metrics.pageSegmentLoad({
		key: 'forge-custom-field',
		timings: [
			...additionalTimings,
			{
				key: 'tti',
				startMark: PERFORMANCE_KEYS.MARKS.TTI_START,
				endMark: PERFORMANCE_KEYS.MARKS.TTI_END,
			},
		],
	});

export const customFieldInteractionMetric = (additionalTimings: Timing[] = []): InteractionMetric =>
	metrics.interaction({
		key: 'forge-custom-field',
		timings: [...additionalTimings],
	});

import React, { type MouseEvent, type KeyboardEvent } from 'react';
import { styled } from '@compiled/react';
import CrossIcon from '@atlaskit/icon/utility/migration/cross--editor-close';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

type ClearButtonProps = {
	label: string;
	onClick: (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => void;
};

export const ClearButton = ({ label, onClick }: ClearButtonProps) => (
	<StyledClearButton
		// @ts-expect-error ts(2322) Type 'string' is not assignable to type 'number'.
		tabIndex="0"
		role="button"
		onClick={onClick}
		onKeyPress={onClick}
		data-testid="jql-editor-basic.common.ui.clear-button" // eslint-disable-line jira/integration/test-id-by-folder-structure
	>
		<CrossIcon color="currentColor" label={label} />
	</StyledClearButton>
);

export default ClearButton;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledClearButton = styled.span({
	display: 'inline-flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N50),
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text', colors.N70),
	},
	'&:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text', colors.N70),
		/*
		 * Keyboard focus handling
		 * Note - use of box-shadow instead of outline is based on
		 * Atlaskit button implementation
		 */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		boxShadow: `0 0 0 2px ${token('color.border.focused', colors.B200)}`,
		outline: 'none',
		borderRadius: '3px',
	},
});

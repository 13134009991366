import messages from './messages.tsx';

export const ERROR_REPORTING_PACKAGE = 'jiraPortfolio3CreateSamplePlan';
export const ERROR_REPORTING_TEAM = 'advanced-roadmaps';
export const CONTACT_SUPPORT_URL = 'https://support.atlassian.com/contact';
export const LEARN_MORE_URL =
	'https://support.atlassian.com/jira-software-cloud/docs/view-a-sample-advanced-roadmaps-plan/';
export const MAX_PLAN_NAME_LENGTH = 255;
export const CREATE_SUCCESS_FLAG_ID = 'samplePlanCreatedSuccessfully';
export const CREATE_ERROR_FLAG_ID = 'samplePlanError';

export const REQUIRED_FIELDS_CONFIGURED_ON_ISSUE = 'REQUIRED_FIELDS_CONFIGURED_ON_ISSUE' as const;
export const EMPTY_ISSUE_LINKS = 'EMPTY_ISSUE_LINKS' as const;
export const NO_BROWSE_PROJECTS_PERMISSION = 'NO_BROWSE_PROJECTS_PERMISSION' as const;
export const NO_CREATE_ISSUES_PERMISSION = 'NO_CREATE_ISSUES_PERMISSION' as const;
export const NO_TRANSITION_ISSUES_PERMISSION = 'NO_TRANSITION_ISSUES_PERMISSION' as const;
export const NO_EDIT_ISSUES_PERMISSION = 'NO_EDIT_ISSUES_PERMISSION' as const;
export const WATCHING_DISABLED = 'WATCHING_DISABLED' as const;
export const UNKNOWN_EXCEPTION = 'UNKNOWN_EXCEPTION' as const;

export const USER_SETTINGS_ERRORS = [
	REQUIRED_FIELDS_CONFIGURED_ON_ISSUE,
	EMPTY_ISSUE_LINKS,
	NO_BROWSE_PROJECTS_PERMISSION,
	NO_CREATE_ISSUES_PERMISSION,
	NO_TRANSITION_ISSUES_PERMISSION,
	NO_EDIT_ISSUES_PERMISSION,
	WATCHING_DISABLED,
];

export const ERROR_CODE_TITLE_MAPPING = {
	[REQUIRED_FIELDS_CONFIGURED_ON_ISSUE]: messages.configurationErrorTitle,
	[EMPTY_ISSUE_LINKS]: messages.configurationErrorTitle,
	[NO_BROWSE_PROJECTS_PERMISSION]: messages.permissionErrorTitle,
	[NO_CREATE_ISSUES_PERMISSION]: messages.permissionErrorTitle,
	[NO_TRANSITION_ISSUES_PERMISSION]: messages.permissionErrorTitle,
	[NO_EDIT_ISSUES_PERMISSION]: messages.permissionErrorTitle,
	[WATCHING_DISABLED]: messages.configurationErrorTitle,
	[UNKNOWN_EXCEPTION]: messages.tryAgainErrorTitle,
};

export const ERROR_CODE_DESCRIPTION_MAPPING = {
	[REQUIRED_FIELDS_CONFIGURED_ON_ISSUE]: messages.requiredFieldsConfiguredOnIssueError,
	[EMPTY_ISSUE_LINKS]: messages.emptyIssueLinksError,
	[NO_BROWSE_PROJECTS_PERMISSION]: messages.noBrowseProjectsPermissionError,
	[NO_CREATE_ISSUES_PERMISSION]: messages.noCreateIssuesPermissionError,
	[NO_TRANSITION_ISSUES_PERMISSION]: messages.noTransitionIssuesPermissionError,
	[NO_EDIT_ISSUES_PERMISSION]: messages.noEditIssuesPermissionError,
	[WATCHING_DISABLED]: messages.watchingDisabledError,
	[UNKNOWN_EXCEPTION]: messages.unknownExceptionError,
} as const;

export const ERROR_CODE_DESCRIPTION_MAPPING_ISSUE_TERM_REFRESH = {
	[REQUIRED_FIELDS_CONFIGURED_ON_ISSUE]:
		messages.requiredFieldsConfiguredOnIssueErrorIssueTermRefresh,
	[EMPTY_ISSUE_LINKS]: messages.emptyIssueLinksErrorIssueTermRefresh,
	[NO_BROWSE_PROJECTS_PERMISSION]: messages.noBrowseProjectsPermissionError,
	[NO_CREATE_ISSUES_PERMISSION]: messages.noCreateIssuesPermissionErrorIssueTermRefresh,
	[NO_TRANSITION_ISSUES_PERMISSION]: messages.noTransitionIssuesPermissionErrorIssueTermRefresh,
	[NO_EDIT_ISSUES_PERMISSION]: messages.noEditIssuesPermissionErrorIssueTermRefresh,
	[WATCHING_DISABLED]: messages.watchingDisabledErrorIssueTermRefresh,
	[UNKNOWN_EXCEPTION]: messages.unknownExceptionError,
} as const;

export const ERROR_LINK_MAPPING = {
	[REQUIRED_FIELDS_CONFIGURED_ON_ISSUE]: { text: messages.learnMore, href: LEARN_MORE_URL },
	[EMPTY_ISSUE_LINKS]: { text: messages.learnMore, href: LEARN_MORE_URL },
	[NO_BROWSE_PROJECTS_PERMISSION]: { text: messages.learnMore, href: LEARN_MORE_URL },
	[NO_CREATE_ISSUES_PERMISSION]: { text: messages.learnMore, href: LEARN_MORE_URL },
	[NO_TRANSITION_ISSUES_PERMISSION]: { text: messages.learnMore, href: LEARN_MORE_URL },
	[NO_EDIT_ISSUES_PERMISSION]: { text: messages.learnMore, href: LEARN_MORE_URL },
	[WATCHING_DISABLED]: { text: messages.learnMore, href: LEARN_MORE_URL },
	[UNKNOWN_EXCEPTION]: { text: messages.contactSupport, href: CONTACT_SUPPORT_URL },
};

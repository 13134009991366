import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { slaMetricsDataResource } from '@atlassian/jira-router-resources-service-desk-sla-settings/src/index.tsx';
import { servicedeskSlaSettingsRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-sla-settings-routes/src/servicedeskSlaSettingsRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import {
	ServiceDeskSlaSettings,
	LazyProjectSettingsServiceDeskSlaSettings,
} from './ui/service-desk-sla-settings/index.tsx';

export const servicedeskSlaSettingsRouteEntry: Route = createEntry(
	servicedeskSlaSettingsRoute,
	() => ({
		group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},

		meta: {
			reporting: {
				id: APP_NAMES.SLA_SETTINGS,
				packageName: toPackageName(APP_NAMES.SLA_SETTINGS),
				teamName: 'bumblebee',
			},
		},

		perfMetricKey: 'jsm-sla-settings',
		component: ServiceDeskSlaSettings,

		resources: [
			...getServiceDeskSettingsResources(),
			projectContextResource,
			slaMetricsDataResource,
			getConsolidationStateResource(),
		],

		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskSlaSettings],
	}),
);

// eslint-disable-next-line jira/import-whitelist
import { expVal } from '@atlassian/jira-feature-experiments';
import { createResource } from '@atlassian/react-resource-router';
import { RESOURCE_TYPE_RAPIDBOARDS_BOARD_CONFIG } from '../../constants.tsx';

const getMaxAge = () =>
	__SERVER__ ? 0 : expVal('jsw-classic-board-api-calls-config-cache-time', 'value', 0) * 1000;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const boardConfigApiResource = createResource<any>({
	type: RESOURCE_TYPE_RAPIDBOARDS_BOARD_CONFIG,
	getKey: ({ match, query }) => {
		const boardId = match.params.boardId != null ? match.params.boardId : query.rapidView;
		return `rapidViewConfig-${boardId}`; // Should Also have a mode but for now lets use only id
	},
	getDataLoader: () =>
		import(
			/* webpackChunkName: "async-resource-rapidboards-config-api", jiraSpaEntry: "async-resource-rapidboards-config-api" */ './get-data'
		),
	maxAge: getMaxAge(),
	isBrowserOnly: true,
});

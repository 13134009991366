import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { MENU_ID_FILTERS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { useSidebarNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-context/index.tsx';
import { Nav4FiltersForLanding } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-filters/src/ui/filters/for-landing/Nav4FiltersForLanding.tsx';
import type { Nav4Filters$key } from '@atlassian/jira-relay/src/__generated__/Nav4Filters.graphql';
import { ActionButtons } from '../../common/ui/action-buttons/index.tsx';
import { Nav4FiltersDefault } from './default/Nav4FiltersDefault.tsx';

export type Nav4FiltersProps = {
	isExpanded: boolean;
	queryRef: Nav4Filters$key;
};

export function Nav4Filters({ isExpanded, queryRef }: Nav4FiltersProps) {
	const data = useFragment<Nav4Filters$key>(
		graphql`
			fragment Nav4Filters on Query {
				...Nav4FiltersForLanding @include(if: $isFiltersExpanded)
			}
		`,
		queryRef,
	);
	const { isInitialSelectedPath } = useSidebarNav4();
	const actionButtons = useMemo(() => <ActionButtons />, []);

	return isInitialSelectedPath(MENU_ID_FILTERS) ? (
		<Nav4FiltersForLanding actionsOnHover={actionButtons} isExpanded={isExpanded} queryRef={data} />
	) : (
		<Nav4FiltersDefault actionsOnHover={actionButtons} isExpanded={isExpanded} />
	);
}

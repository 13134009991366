import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addToStarred: {
		id: 'navigation-apps-sidebar-nav4-common.favorite-toggle-button.add-to-starred',
		defaultMessage: 'Add to starred',
		description: 'Used as the menu-title for the Add to Starred menu button item',
	},
	removeFromStarred: {
		id: 'navigation-apps-sidebar-nav4-common.favorite-toggle-button.remove-from-starred',
		defaultMessage: 'Remove from starred',
		description: 'Used as the menu-title for the Remove from Starred menu button item',
	},
	addToStarredWithName: {
		id: 'navigation-apps-sidebar-nav4-common.favorite-toggle-button.add-to-starred-with-name',
		defaultMessage: 'Add "{name}" to starred',
		description:
			'Used as the alt text for the "add to starred" menu button item. The {name} attribute refers to the name of the entity that should be added to starred. For example, this could be a filter name like "blocked issues" and the full message would be "Add blocked issues to starred".',
	},
	removeFromStarredWithName: {
		id: 'navigation-apps-sidebar-nav4-common.favorite-toggle-button.remove-from-starred-with-name',
		defaultMessage: 'Remove "{name}" from starred',
		description:
			'Used as the alt text for the "remove from starred" menu button item. The {name} attribute refers to the name of the entity that should be remove from starred. For example, this could be a filter name like "blocked issues" and the full message would be "Remove blocked issues from starred".',
	},
});

import React from 'react';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { LazyAlertIssueSync } from './async.tsx';

export const AlertIssueSync = () => (
	<LazyPage
		Page={LazyAlertIssueSync}
		pageId="async-jira-eoc-alert-issue-syncing"
		shouldShowSpinner
	/>
);

import { useCallback, useState } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import messages from '../../common/messages.tsx';
import type { GenericPickerOption } from '../../common/types.tsx';
import { fireBoardCreateErrorAnalytics } from '../../common/utils.tsx';
import { useSavedFiltersFetch } from '../../services/use-saved-filters-fetch/index.tsx';
import { useBoardCreateFlag } from '../use-board-create-flag/index.tsx';

export const useIsLoading = () => useState(false);

export const useLoadSavedFiltersController = () => {
	const savedFiltersFetch = useSavedFiltersFetch();
	const [isLoading, setIsLoading] = useIsLoading();
	const { showErrorFlag } = useBoardCreateFlag();
	const { formatMessage } = useIntl();

	const loadOptions: (searchString: string) => Promise<GenericPickerOption[] | undefined> =
		useCallback(
			async (searchString: string) => {
				setIsLoading(true);
				return savedFiltersFetch(searchString)
					.catch((error) => {
						showErrorFlag({
							title: formatMessage(messages.loadSavedFiltersErrorTitle),
							description: formatMessage(messages.loadSavedFiltersErrorDescription),
						});
						fireBoardCreateErrorAnalytics({
							error: new Error(`Failed to fetch saved filters: ${error}`),
							id: 'fetchSavedFilters',
						});
						return [];
					})
					.finally(() => {
						setIsLoading(false);
					});
			},
			[formatMessage, savedFiltersFetch, setIsLoading, showErrorFlag],
		);

	return {
		loadOptions,
		isLoading,
	};
};

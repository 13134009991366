import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { softwareAdvancedRoadmapsRedirect1Route } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/softwareAdvancedRoadmapsRedirect1Route.tsx';
import type { Route } from '@atlassian/react-resource-router';
import RedirectLegacyReportPage from './common/ui/redirect/report/index.tsx';
import { LazyPlanTimeline } from './ui/plan-timeline/index.tsx';

export const legacyPlanReportPageRedirectRouteEntry: Route = createEntry(
	softwareAdvancedRoadmapsRedirect1Route,
	() => ({
		group: ROUTE_GROUPS_SOFTWARE,
		component: RedirectLegacyReportPage,
		isRedirect: true,
		layout: globalLayout,
		resources: [...getNavigationResources()],

		navigation: {
			menuId: MENU_ID.PLANS,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanTimeline],
	}),
);

import { useCallback } from 'react';
import { graphql, fetchQuery, useRelayEnvironment } from 'react-relay';
import type {
	useSavedFiltersFetchQuery,
	useSavedFiltersFetchQuery$data,
} from '@atlassian/jira-relay/src/__generated__/useSavedFiltersFetchQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { GenericPickerOption } from '../../common/types.tsx';

export const useSavedFiltersFetch = () => {
	const cloudId = useCloudId();
	const environment = useRelayEnvironment();

	return useCallback(
		async (searchString: string) =>
			fetchQuery<useSavedFiltersFetchQuery>(
				environment,
				graphql`
					query useSavedFiltersFetchQuery($cloudId: ID!, $searchString: String!) {
						jira {
							savedFilters(cloudId: $cloudId, keyword: $searchString, first: 20)
								@required(action: THROW)
								@optIn(to: "JiraFilter") {
								edges {
									node @required(action: THROW) {
										... on JiraCustomFilter {
											filterId
											name
										}
									}
								}
							}
						}
					}
				`,
				{
					cloudId,
					searchString,
				},
			)
				.toPromise()
				.then((response: useSavedFiltersFetchQuery$data | undefined) => {
					if (response?.jira?.savedFilters?.edges) {
						const data: GenericPickerOption[] =
							response.jira.savedFilters.edges.map((edge) => {
								return {
									label: edge?.node?.name || '',
									value: edge?.node?.filterId || '',
								};
							}) || [];
						return data;
					}

					throw new Error('No response');
				}),
		[cloudId, environment],
	);
};

import React from 'react';
import queryString from 'query-string';
import { useProjectKeyByProjectResource } from '@atlassian/jira-router-resources-project-context/src/utils/get-project-key-by-project-resource/index.tsx';
import { type RouteContext, Redirect } from '@atlassian/react-resource-router';

export const BoardSettingsRedirectToCSM = ({ match }: RouteContext) => (
	<Redirect
		to={`/jira/software/projects/${String(match.params.projectKey)}/settings/boards/${String(
			match.params.boardId,
		)}/columns`}
		push={false}
	/>
);

export const RoadmapSettingsRedirectToTimelineSettings = ({ match }: RouteContext) => (
	<Redirect
		to={`/jira/software/projects/${String(match.params.projectKey)}/settings/boards/${String(
			match.params.boardId,
		)}/timeline`}
		push={false}
	/>
);

export const SpaReleaseNoteRedirectToVersionDetails = ({ query }: RouteContext) => {
	const { version: versionId } = query;
	const { loading, error, projectKey } = useProjectKeyByProjectResource();

	if (loading === false && error) {
		return null;
	}

	if (loading) {
		return null;
	}

	return (
		<Redirect
			to={{
				pathname: `/projects/${projectKey}/versions/${versionId}/tab/release-report-all-issues`,
				hash: '',
				search: queryString.stringify({ isReleaseNoteModalOpen: true }),
			}}
		/>
	);
};

export const LegacyFrontendReleaseNoteRedirect = ({ query }: RouteContext) => (
	<Redirect
		to={{
			pathname: '/secure/ReleaseNote.jspa',
			hash: '',
			search: queryString.stringify({ ...query, isRedirectedFromLegacy: true }),
		}}
	/>
);

const ufoNamesMap: Record<string, string> = {
	'project-settings-software-issuetypes': 'project-settings.issue-type.jsw-tmp',
	'project-settings-servicedesk-issuetypes': 'project-settings.issue-type.jsm-tmp',
};

export const getUFOName = (name: string) => ufoNamesMap[name] || name;

/**
 * @generated SignedSource<<2407f0cf407178ba1548bc4634c4f85a>>
 * @relayHash 38314e3992c2b8038d9ae09227d1dd63
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 52091311de7c62539bcc2801cb050c011654c1915f33e47a1173ac2d96bca8c2

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessCalendarQuery } from './src_jiraBusinessCalendarQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessCalendarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "52091311de7c62539bcc2801cb050c011654c1915f33e47a1173ac2d96bca8c2",
    "metadata": {},
    "name": "src_jiraBusinessCalendarQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import type { CSSFn } from '@atlaskit/menu';
import type { BoardType } from '@atlassian/jira-shared-types/src/rest/jira/board.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { SidebarConfigData } from '@atlassian/jira-navigation-types/src/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { BoardType } from '@atlassian/jira-shared-types/src/rest/jira/board.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { NavLink } from '@atlassian/jira-navigation-types/src/types.tsx';

export type Board = {
	id: number;
	name: string;
	type: BoardType;
	isProjectBoard: boolean;
};

export type NavigationTheme = {
	navigation?: {
		item?: {
			background?: {
				default?: string;
				hover?: string;
				active?: string;
				disabled?: string;
			};
			color?: {
				default?: string;
				hover?: string;
				active?: string;
				disabled?: string;
			};
			opacity?: string;
		};
		icon?: {
			primary?: {
				color?: {
					default?: string;
					hover?: string;
					active?: string;
					disabled?: string;
				};
			};
			secondary?: {
				color?: {
					default?: string;
					hover?: string;
					active?: string;
					disabled?: string;
				};
			};
		};
		separator?: {
			color?: string;
		};
	};
};

/** CSSObject from @emotion/react (but imports from @emotion are ratcheted)
 * This type is needed for the legacy UI theming.
 * This is the return type of "CSSFn".
 * We intentionally redefine this type instead of using ReturnType<CSSFn>
 * bc we don't use all the features, thus we get a stronger typing.
 *
 * @see jira/node_modules/@emotion/serialize/types/index.d.ts "CSSObject"
 */
export interface LegacyEmotionCssObject {
	[cssProp: string]: LegacyEmotionCssObject | string | number | undefined;
	// known specific props
	div?: LegacyEmotionCssObject;
	'[data-item-elem-before]'?: LegacyEmotionCssObject;
	'[data-item-title]'?: LegacyEmotionCssObject;
	'[data-item-description]'?: LegacyEmotionCssObject;
}

/** REAL CSSFn type.
 * the type defined in @atlaskit/menu doesn't match the actual usage in code and the comments.
 * This type will likely never be fixed in @atlaskit/menu since CSSFn is legacy.
 * This should be the real type according to the comments.
 *
 * @see jira/node_modules/@atlaskit/menu/dist/types/types.d.ts "CSSFn<TState> (currentState: TState): CSSObject | CSSObject[];
 */
type RealCSSFn<State> = (stateAndStyles: LegacyEmotionCssObject & State) => LegacyEmotionCssObject;

export function castRealCSSFnToWronglyTypedCSSFn<State>(fn: RealCSSFn<State>): CSSFn<State> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
	return fn as any;
}

// useful for test
export function castWronglyTypedCSSFnToRealCSSFn<State>(
	fn: CSSFn<State>,
): RealCSSFn<LegacyEmotionCssObject> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
	return fn as any;
}

/**
 * @generated SignedSource<<df7692163403181d00220f73a58855fc>>
 * @relayHash 2030fb13e20a6b1242bcbe6b0b22f74b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 100c7db672b19d2b2551c4375145f7b97031fc11294e0dc0615a428d1f472fc3

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ui_jiraCalendarQuery } from './ui_jiraCalendarQuery.graphql';

const node: PreloadableConcreteRequest<ui_jiraCalendarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "100c7db672b19d2b2551c4375145f7b97031fc11294e0dc0615a428d1f472fc3",
    "metadata": {},
    "name": "ui_jiraCalendarQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import React, { memo } from 'react';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-common/src/async.tsx';
import {
	setPerformanceMarkLoadStart,
	setPerformanceMarkLoadEnd,
} from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/performance-marks/index.tsx';
import { lazyAfterPaint, lazyForPaint } from '@atlassian/react-loosely-lazy';
import { useIsActiveRoute } from '@atlassian/react-resource-router';
import type { SidebarClassicSoftware as ClassicSoftwareProjectSidebarType } from './ui/index.tsx';

const LazyAfterPaintClassicSoftwareProjectSidebar = lazyAfterPaint<
	typeof ClassicSoftwareProjectSidebarType
>(() => {
	setPerformanceMarkLoadStart();

	return import(/* webpackChunkName: "async-sidebar-software-classic" */ './index').then(
		(bundle) => {
			setPerformanceMarkLoadEnd();
			return bundle;
		},
	);
});

const LazyForPaintClassicSoftwareProjectSidebar = lazyForPaint<
	typeof ClassicSoftwareProjectSidebarType
>(() => {
	setPerformanceMarkLoadStart();

	// eslint-disable-next-line jira/import/webpack-magic-comment-entries
	return import(/* webpackChunkName: "async-sidebar-software-classic" */ './index').then(
		(bundle) => {
			setPerformanceMarkLoadEnd();
			return bundle;
		},
	);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AfterPaintClassicSoftwareProjectSidebar = memo<Record<any, any>>(() => (
	<SidebarBoundary id="classicSoftwareProject">
		<LazyAfterPaintClassicSoftwareProjectSidebar />
	</SidebarBoundary>
));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ForPaintClassicSoftwareProjectSidebar = memo<Record<any, any>>(() => (
	<SidebarBoundary id="classicSoftwareProject">
		<LazyForPaintClassicSoftwareProjectSidebar />
	</SidebarBoundary>
));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ClassicSoftwareProjectSidebarLazyForPaintIssueRouteOnly = memo<Record<any, any>>(() => {
	const isIssueRoute = useIsActiveRoute('issue');
	return isIssueRoute ? (
		<ForPaintClassicSoftwareProjectSidebar />
	) : (
		<AfterPaintClassicSoftwareProjectSidebar />
	);
});

const ClassicSoftwareProjectSidebar = componentWithFG(
	'nav_sidebar_software_classic_lazyforpaint',
	ClassicSoftwareProjectSidebarLazyForPaintIssueRouteOnly,
	AfterPaintClassicSoftwareProjectSidebar,
);

ClassicSoftwareProjectSidebar.displayName = 'ClassicSoftwareProjectSidebar';
ForPaintClassicSoftwareProjectSidebar.displayName = 'ClassicSoftwareProjectSidebar';

export { ForPaintClassicSoftwareProjectSidebar };
export default ClassicSoftwareProjectSidebar;

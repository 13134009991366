/**
 * @generated SignedSource<<88f31aba52c11a49404aff90301c572a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraNavigationItemTypeKey = "APP" | "APPROVALS" | "APPS" | "ARCHIVED_ISSUES" | "ATTACHMENTS" | "BACKLOG" | "BOARD" | "CALENDAR" | "CODE" | "COMPONENTS" | "DEPLOYMENTS" | "DEVELOPMENT" | "FORMS" | "GOALS" | "INCIDENTS" | "ISSUES" | "LIST" | "ON_CALL" | "PAGES" | "RELEASES" | "REPORTS" | "REQUESTS" | "SECURITY" | "SHORTCUTS" | "SUMMARY" | "TIMELINE" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type JiraBusinessProjectMenu$data = {
  readonly avatar: {
    readonly large: string;
  };
  readonly defaultNavigationItem: {
    readonly typeKey?: JiraNavigationItemTypeKey | null | undefined;
  } | null | undefined;
  readonly editProjectConfig: {
    readonly canPerform: boolean;
  } | null | undefined;
  readonly isFavourite: boolean | null | undefined;
  readonly name: string;
  readonly navigationMetadata?: {
    readonly boardId?: string;
    readonly isSimpleBoard?: boolean;
  } | null | undefined;
  readonly projectId: string;
  readonly projectKey: string;
  readonly projectType: JiraProjectType;
  readonly " $fragmentSpreads": FragmentRefs<"ui_navigationProjectActionMenu_ProjectActionMenu">;
  readonly " $fragmentType": "JiraBusinessProjectMenu";
};
export type JiraBusinessProjectMenu$key = {
  readonly " $data"?: JiraBusinessProjectMenu$data;
  readonly " $fragmentSpreads": FragmentRefs<"JiraBusinessProjectMenu">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "simplifyBoardQuery"
    }
  ],
  "kind": "Fragment",
  "name": "JiraBusinessProjectMenu",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraAvatar",
        "kind": "LinkedField",
        "name": "avatar",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "large"
            },
            "action": "THROW",
            "path": "avatar.large"
          }
        ]
      },
      "action": "THROW",
      "path": "avatar"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "name"
      },
      "action": "THROW",
      "path": "name"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "projectKey",
        "kind": "ScalarField",
        "name": "key"
      },
      "action": "THROW",
      "path": "projectKey"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "projectId"
      },
      "action": "THROW",
      "path": "projectId"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "projectType"
      },
      "action": "THROW",
      "path": "projectType"
    },
    {
      "kind": "ScalarField",
      "name": "isFavourite"
    },
    {
      "kind": "LinkedField",
      "name": "defaultNavigationItem",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "typeKey"
            }
          ],
          "type": "JiraNavigationItem",
          "abstractKey": "__isJiraNavigationItem"
        }
      ]
    },
    {
      "condition": "simplifyBoardQuery",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "navigationMetadata",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "boardId"
                },
                {
                  "kind": "ScalarField",
                  "name": "isSimpleBoard"
                }
              ],
              "type": "JiraSoftwareProjectNavigationMetadata"
            }
          ]
        }
      ]
    },
    {
      "alias": "editProjectConfig",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "EDIT_PROJECT_CONFIG"
        }
      ],
      "concreteType": "JiraProjectAction",
      "kind": "LinkedField",
      "name": "action",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "canPerform"
        }
      ],
      "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
    },
    {
      "kind": "FragmentSpread",
      "name": "ui_navigationProjectActionMenu_ProjectActionMenu"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "df93d59f999935c392e0e73aa67583f4";

export default node;

import React, { Fragment } from 'react';
import { graphql, useFragment } from 'react-relay';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { MENU_ID_APPS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { useSidebarNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-context/index.tsx';
import { useIsGeneralConnectRoute } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-is-apps-route/index.tsx';
import { SectionActions } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-apps/src/common/ui/section-actions/SectionActions.tsx';
import { Nav4AppsDefault } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-apps/src/ui/apps/default/Nav4AppsDefault.tsx';
import { Nav4AppsForLanding } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-apps/src/ui/apps/for-landing/Nav4AppsForLanding.tsx';
import type { Nav4Apps$key } from '@atlassian/jira-relay/src/__generated__/Nav4Apps.graphql';
import type { Nav4Apps_GlobalAppsFragment$key } from '@atlassian/jira-relay/src/__generated__/Nav4Apps_GlobalAppsFragment.graphql';
import { useIsGlobalConnectAppRoute } from '../../controllers/use-is-connect-route/index.tsx';
import { Nav4AppsSpotlightWrapper } from './Nav4AppsSpotlightWrapper.tsx';

export type Nav4AppsProps = {
	isExpanded: boolean;
	queryRef: Nav4Apps$key;
};

const Wrapper = componentWithFG('jira_nav4_beta_drop_1', Nav4AppsSpotlightWrapper, Fragment);

export function Nav4Apps(props: Nav4AppsProps) {
	return (
		<Wrapper>
			<Nav4AppsInner {...props} />
		</Wrapper>
	);
}

function Nav4AppsInner({ isExpanded, queryRef }: Nav4AppsProps) {
	const { isInitialSelectedPath } = useSidebarNav4();
	const data = useFragment<Nav4Apps$key>(
		graphql`
			fragment Nav4Apps on Query {
				jira @required(action: THROW) {
					...Nav4AppsForLanding @include(if: $isAppsExpanded)
					...Nav4Apps_GlobalAppsFragment
				}
			}
		`,
		queryRef,
	);

	/* eslint-disable @atlassian/relay/unused-fields */
	const globalAppNavigationItems = useFragment<Nav4Apps_GlobalAppsFragment$key>(
		graphql`
			fragment Nav4Apps_GlobalAppsFragment on JiraQuery {
				globalAppNavigationItems(cloudId: $cloudId)
					@optIn(to: "JiraNavigationItems")
					@include(if: $isAppsExpanded) {
					edges {
						node {
							... on JiraAppNavigationItem {
								appType
								url
								sections {
									links {
										url
									}
								}
							}
						}
					}
				}
			}
		`,
		data.jira,
	);
	/* eslint-enable @atlassian/relay/unused-fields */

	// $isAppsExpanded (in the query) is based on the same logic as in isInitialSelectedPath(MENU_ID_APPS).
	// However, below we filter out false positives based on extra data we retrieve from GraphQL.
	// We simply can't determine whether the general Connect app is a part of the "Apps" section or not without that extra data.
	// And we would like to avoid situations where the "Apps" section is expanded when it shouldn't be.
	// Keep in mind that global Connect app != general Connect app. Global app is a subset of the general app.
	// General app can be rendered in various contexts, while the global one only under the "Apps" section.
	const isMaybeAppsSectionRoute = isInitialSelectedPath(MENU_ID_APPS);
	const isGeneralConnectRoute = useIsGeneralConnectRoute();
	const isGlobalConnectAppRoute = useIsGlobalConnectAppRoute({ data: globalAppNavigationItems });
	const isAppsSectionExpanded =
		isMaybeAppsSectionRoute && (isGeneralConnectRoute ? isGlobalConnectAppRoute : true);

	const sectionActions = <SectionActions />;

	return isAppsSectionExpanded ? (
		<Nav4AppsForLanding
			actionsOnHover={sectionActions}
			isExpanded={isExpanded}
			queryRef={data.jira}
		/>
	) : (
		<Nav4AppsDefault actionsOnHover={sectionActions} isExpanded={isExpanded} />
	);
}

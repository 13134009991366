import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { fieldSettingsRelayResource } from '@atlassian/jira-router-resources-project-settings-fields/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { projectSettingsSoftwareFieldsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-software-routes/src/projectSettingsSoftwareFieldsRoute.tsx';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout/src/index.tsx';
import FieldSettingsPage, { LazyFieldsSettings } from './ui/project-settings/fields/index.tsx';
import { getSettingsNavigationResources } from './ui/project-settings/product-routes/common/index.tsx';

export const projectSettingsSoftwareFieldsRouteEntry = createEntry(
	projectSettingsSoftwareFieldsRoute,
	() => ({
		group: ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE,
		ufoName: 'project-settings.fields.jsw-tmp',
		component: componentWithFG(
			'project_fields_page_for_tmp_-_mvp',
			FieldSettingsPage,
			ErrorPagesNotFound,
		),
		layout: softwareProjectLayout,
		navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getSettingsNavigationResources(), fieldSettingsRelayResource],
		forPaint: [LazyAtlassianNavigation, LazyFieldsSettings],
	}),
);

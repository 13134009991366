import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings-nextgen.title',
		defaultMessage: 'Project settings',
		description: 'Navigation section title.',
	},
	itemDetails: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings-nextgen.item-details',
		defaultMessage: 'Details',
		description: 'Details link item.',
	},
	itemAccess: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings-nextgen.item-access',
		defaultMessage: 'Access',
		description: 'Project access link item.',
	},
	itemFieldsNonFinal: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings-nextgen.item-fields-non-final',
		defaultMessage: 'Fields',
		description: 'Fields link item.',
	},
	itemIssueTypes: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings-nextgen.item-issue-types',
		defaultMessage: 'Issue types',
		description: 'Issue types link item.',
	},
	itemAdvancedForms: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings-nextgen.item-advanced-forms',
		defaultMessage: 'Advanced Forms',
		description: 'JWM ProForma Forms link item.',
	},
	newLozenge: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings-nextgen.new-lozenge',
		defaultMessage: 'New',
		description:
			'Small lozenge that appears to the right of the nav item, indicating a new feature.',
	},
	automation: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings-nextgen.automation',
		defaultMessage: 'Automation',
		description: 'Automation link item',
	},
	itemNotifications: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings-nextgen.item-notifications',
		defaultMessage: 'Notifications',
		description: 'Notifications link item.',
	},
	itemApprovals: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings-nextgen.item-approvals',
		defaultMessage: 'Approvals',
		description: 'Approvals link item.',
	},
	dataClassificationNonFinal: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings-nextgen.data-classification-non-final',
		defaultMessage: 'Data classification',
		description: 'Data classification link item within data protection sub-menu',
	},
	itemIssueTypesIssueTermRefresh: {
		id: 'spa-apps-sidebar-core.multi-project-menu.active-project.project-menu.settings-menu.settings-nextgen.item-issue-types-issue-term-refresh',
		defaultMessage: 'Issue types',
		description: 'Issue types link item.',
	},
});

import React, { useCallback } from 'react';
import AddIcon from '@atlaskit/icon/utility/add';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { FIXED_ITEM_BUTTON } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { LEVEL_ONE } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import { MENU_ID_PLANS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { CommonNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/common-nav-menu-button/main.tsx';
import { useCreateNewPlanWizardDrawer } from '@atlassian/jira-portfolio-3-create-new-plan-wizard-drawer/src/controllers/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics';
import messages from './messages.tsx';

export const CreatePlanMenuButton = () => {
	const { formatMessage } = useIntl();
	const [, { openDrawer: openCreateNewPlanWizardDrawer }] = useCreateNewPlanWizardDrawer();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const clickHandler = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_PLANS],
			attributes: {
				level: LEVEL_ONE,
				componentType: FIXED_ITEM_BUTTON,
				itemId: 'createPlan',
			},
		});
		openCreateNewPlanWizardDrawer();
	}, [openCreateNewPlanWizardDrawer, createAnalyticsEvent]);

	return (
		<Tooltip content={formatMessage(messages.createPlan)}>
			<CommonNavMenuButton
				icon={AddIcon}
				label={formatMessage(messages.createPlan)}
				onClick={clickHandler}
			/>
		</Tooltip>
	);
};

import React, { useEffect } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import useIsAdminHubAIEnabled from '@atlassian/jira-platform-react-hooks-use-is-admin-hub-ai-enabled/src/controllers/index.tsx';
import type ServicedeskKnowledgeHubSuggestedArticlesType from '@atlassian/jira-spa-apps-servicedesk-knowledge-hub-suggested-articles/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyServicedeskKnowledgeHubSuggestedArticles = lazyForPaint<
	typeof ServicedeskKnowledgeHubSuggestedArticlesType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-knowledge-hub-suggested-articles", jiraSpaEntry: "async-servicedesk-knowledge-hub-suggested-articles" */ '@atlassian/jira-spa-apps-servicedesk-knowledge-hub-suggested-articles'
		),
	),
);

export const ServiceDeskKnowledgeHubSuggestedArticles = () => {
	const {
		data: isAdminHubAIEnabled,
		loading,
		error,
		fetchAIEnablement,
	} = useIsAdminHubAIEnabled('jira-servicedesk');

	const showSuggestedTopics = fg('suggested_topics_eap')
		? !loading && !error && isAdminHubAIEnabled
		: fg('jsm-suggested-articles-in-kb');

	useEffect(() => {
		fg('suggested_topics_eap') && fetchAIEnablement();
	}, [fetchAIEnablement]);

	return (
		<>
			{showSuggestedTopics && (
				<LazyPage
					Page={LazyServicedeskKnowledgeHubSuggestedArticles}
					pageId="spa-apps/servicedesk/knowledge-hub/suggested-articles"
					shouldShowSpinner
				/>
			)}
		</>
	);
};

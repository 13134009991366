import { useCallback } from 'react';
import { graphql, fetchQuery, useRelayEnvironment } from 'react-relay';
import type {
	useJqlBuilderFetchQuery,
	useJqlBuilderFetchQuery$data,
} from '@atlassian/jira-relay/src/__generated__/useJqlBuilderFetchQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { GenericPickerOption, JQLTermType } from '../../common/types.tsx';

type Props = {
	projectId: string | undefined;
};

export const useJQLBuilderFetch = ({ projectId }: Props) => {
	if (projectId === undefined) {
		throw new Error('Project ID is undefined');
	}

	const cloudId = useCloudId();
	const environment = useRelayEnvironment();

	return useCallback(
		async (jqlTerm: JQLTermType, searchString: string) =>
			fetchQuery<useJqlBuilderFetchQuery>(
				environment,
				graphql`
					query useJqlBuilderFetchQuery(
						$cloudId: ID!
						$jqlTerm: String!
						$jql: String!
						$searchString: String!
					) {
						jira {
							jqlBuilder(cloudId: $cloudId) {
								fieldValues(jqlTerm: $jqlTerm, jqlContext: $jql, searchString: $searchString) {
									edges {
										node {
											... on JiraJqlIssueTypeFieldValue {
												displayName
												issueTypes {
													issueTypeId
													avatar {
														small
													}
												}
											}
											... on JiraJqlLabelFieldValue {
												displayName
											}
										}
									}
								}
							}
						}
					}
				`,
				{
					cloudId,
					jqlTerm,
					jql: `project = ${projectId}`,
					searchString,
				},
			)
				.toPromise()
				.then((response: useJqlBuilderFetchQuery$data | undefined) => {
					if (response?.jira?.jqlBuilder?.fieldValues?.edges) {
						const data: GenericPickerOption[] =
							response.jira.jqlBuilder.fieldValues.edges.map((edge) => {
								if (jqlTerm === 'type') {
									return {
										label: edge?.node?.displayName || '',
										value: (edge?.node?.issueTypes && edge?.node?.issueTypes[0]?.issueTypeId) || '',
										avatar:
											(edge?.node?.issueTypes && edge?.node?.issueTypes[0].avatar?.small) || '',
									};
								}

								return {
									label: edge?.node?.displayName || '',
									value: edge?.node?.displayName || '',
								};
							}) || [];
						return data;
					}

					throw new Error('No response');
				}),
		[cloudId, environment, projectId],
	);
};

import {
	type OperatorValue,
	OPERATOR_EQUALS,
	OPERATOR_NOT_EQUALS,
	OPERATOR_WAS_NOT_IN,
	OPERATOR_IS,
	OPERATOR_WAS_NOT,
	OPERATOR_IN,
	OPERATOR_IS_NOT,
	OPERATOR_NOT_IN,
	OPERATOR_WAS_IN,
	OPERATOR_WAS,
	OPERATOR_CHANGED,
	OPERATOR_GT,
	OPERATOR_GT_EQUALS,
	OPERATOR_LT,
	OPERATOR_LT_EQUALS,
	OPERATOR_LIKE,
	OPERATOR_NOT_LIKE,
} from '@atlaskit/jql-ast';

export const mapOperators = (jiraJqlOpeartor: string): OperatorValue => {
	switch (jiraJqlOpeartor) {
		case 'EQUALS':
			return OPERATOR_EQUALS;
		case 'NOT_EQUALS':
			return OPERATOR_NOT_EQUALS;
		case 'IS':
			return OPERATOR_IS;
		case 'IN':
			return OPERATOR_IN;
		case 'IS_NOT':
			return OPERATOR_IS_NOT;
		case 'NOT_IN':
			return OPERATOR_NOT_IN;
		case 'CONTAINS':
			return OPERATOR_LIKE;
		case 'DOES_NOT_CONTAIN':
			return OPERATOR_NOT_LIKE;
		case 'WAS_NOT_IN':
			return OPERATOR_WAS_NOT_IN;
		case 'WAS_NOT':
			return OPERATOR_WAS_NOT;
		case 'WAS_IN':
			return OPERATOR_WAS_IN;
		case 'WAS':
			return OPERATOR_WAS;
		case 'CHANGED':
			return OPERATOR_CHANGED;
		case 'GREATER_THAN':
			return OPERATOR_GT;
		case 'GREATER_THAN_OR_EQUAL':
			return OPERATOR_GT_EQUALS;
		case 'LESS_THAN':
			return OPERATOR_LT;
		case 'LESS_THAN_OR_EQUAL':
			return OPERATOR_LT_EQUALS;

		default:
			throw new Error(`Operator ${jiraJqlOpeartor} not supported`);
	}
};

import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import parameters from '@atlassian/jira-relay/src/__generated__/JourneysPageQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const journeysEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-servicedesk-journeys", jiraSpaEntry: "async-servicedesk-journeys" */ './src/ui/JourneysPage'
			),
	),
	getPreloadProps: ({ context, tenantContext }: EntryPointRouteParams) => ({
		queries: {
			journeysPageQueryRef: {
				parameters,
				variables: {
					cloudId: tenantContext.cloudId,
					projectKey: context.match.params.projectKey || '',
				},
				options: {
					fetchPolicy: 'store-and-network' as const,
				},
			},
		},
		entryPoints: {},
		extraProps: {},
	}),
});

import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const FieldDescriptionContentEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-jql-builder-field-description-content" */ './main'),
	),
	getPreloadProps: () => ({}),
});

export default FieldDescriptionContentEntryPoint;

import type { ComponentType } from 'react';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { projectLayout } from '@atlassian/jira-project-layout/src/index.tsx';
import {
	composeLayouts,
	withProjectContext,
	type Layout,
} from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';

// TODO Remove this whenever all software project route paths are no longer ambiguous
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withAnySoftwareProjectContext = (Component: ComponentType<Record<any, any>>) =>
	withProjectContext(Component, {
		project: {
			style: 'any',
			type: SOFTWARE_PROJECT,
		},
	});

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const Onboarding = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-software-project-onboarding" */ './ui/onboarding'),
	{ ssr: false },
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const OnboardingCode = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "async-software-project-onboarding-code" */ './ui/onboarding-code/OnboardingCode.tsx'
		),
	{ ssr: false },
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const RightSidebars = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "async-software-project-right-sidebars" */ './ui/right-sidebars/RightSidebars.tsx'
		),
	{ ssr: false },
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const RightSidebarsCode = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "async-software-project-right-sidebars-code" */ './ui/right-sidebars-code/RightSidebarsCode.tsx'
		),
	{ ssr: false },
);

const SoftWareOnboarding = withAnySoftwareProjectContext(Onboarding);
const SoftWareOnboardingCode = withAnySoftwareProjectContext(OnboardingCode);

const nonSettingsRightSidebars = [withAnySoftwareProjectContext(RightSidebars)];
const nonSettingsRightSidebarsCode = [withAnySoftwareProjectContext(RightSidebarsCode)];

const sharedSoftwareProjectLayout: Layout = composeLayouts(projectLayout, {});

export const classicSoftwareProjectLayout: Layout = composeLayouts(sharedSoftwareProjectLayout, {
	rightSidebars: nonSettingsRightSidebars,
	globalComponents: [SoftWareOnboarding],
});

export const softwareProjectLayout: Layout = composeLayouts(sharedSoftwareProjectLayout, {
	rightSidebars: nonSettingsRightSidebars,
	globalComponents: [SoftWareOnboarding],
});

export const softwareProjectLayoutNoOnboarding: Layout = composeLayouts(
	sharedSoftwareProjectLayout,
);

export const classicSoftwareProjectLayoutNoOnboarding: Layout = composeLayouts(
	sharedSoftwareProjectLayout,
);

/**
 * Conditional onboarding - displays Quickstart in CiJ for Devops Bundle
 */
export const softwareProjectLayoutCode: Layout = composeLayouts(sharedSoftwareProjectLayout, {
	rightSidebars: nonSettingsRightSidebarsCode,
	globalComponents: [SoftWareOnboardingCode],
});

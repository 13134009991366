import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { trashedPlanDirectLinkRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-spa-routes/src/trashedPlanDirectLinkRoute.tsx';
import {
	TrashedPlanDirectLink,
	LazyTrashedPlanDirectLink,
} from './ui/trashed-plan-direct-link/index.tsx';

export const trashedPlanDirectLinkRouteEntry = createEntry(trashedPlanDirectLinkRoute, () => ({
	group: ROUTE_GROUPS_SOFTWARE,
	component: TrashedPlanDirectLink,
	layout: globalLayout,
	resources: [...getNavigationResources()],
	forPaint: [LazyAtlassianNavigation, LazyTrashedPlanDirectLink],
	ufoName: 'plan-trashed',
}));

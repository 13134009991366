/**
 * @generated SignedSource<<a6bcd378a0813c5989c51fc5fe0b29fc>>
 * @relayHash aa7063fb256b1dc300f76736d4ea8527
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 29e87ebc3ed88a85bdd91cb14f18971105d6be18a205fc39acc576fc00397636

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4ProjectsContentQuery$variables = {
  cloudId: string;
  currentURL?: AGG$URL | null | undefined;
  simplifyBoardQuery: boolean;
};
export type Nav4ProjectsContentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4ProjectsContent">;
};
export type Nav4ProjectsContentQuery = {
  response: Nav4ProjectsContentQuery$data;
  variables: Nav4ProjectsContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "currentURL"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "simplifyBoardQuery"
  }
],
v1 = {
  "kind": "Variable",
  "name": "currentURL",
  "variableName": "currentURL"
},
v2 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    (v4/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v6 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "EDIT_PROJECT_CONFIG"
  }
],
v7 = [
  {
    "kind": "ScalarField",
    "name": "canPerform"
  }
],
v8 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v9 = [
  {
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          (v4/*: any*/),
          {
            "alias": "projectKey",
            "kind": "ScalarField",
            "name": "key"
          },
          {
            "kind": "ScalarField",
            "name": "status"
          },
          {
            "kind": "ScalarField",
            "name": "projectType"
          },
          {
            "concreteType": "JiraAvatar",
            "kind": "LinkedField",
            "name": "avatar",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "large"
              }
            ]
          },
          {
            "kind": "ScalarField",
            "name": "name"
          },
          {
            "kind": "ScalarField",
            "name": "projectId"
          },
          {
            "kind": "ScalarField",
            "name": "isFavourite"
          },
          {
            "kind": "LinkedField",
            "name": "defaultNavigationItem",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "typeKey"
                  }
                ],
                "type": "JiraNavigationItem",
                "abstractKey": "__isJiraNavigationItem"
              },
              (v5/*: any*/)
            ]
          },
          {
            "alias": "editProjectConfig",
            "args": (v6/*: any*/),
            "concreteType": "JiraProjectAction",
            "kind": "LinkedField",
            "name": "action",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
          },
          {
            "kind": "ScalarField",
            "name": "projectStyle"
          },
          {
            "kind": "ScalarField",
            "name": "webUrl"
          },
          {
            "alias": "canEditProjectConfig",
            "args": (v6/*: any*/),
            "concreteType": "JiraProjectAction",
            "kind": "LinkedField",
            "name": "action",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
          },
          {
            "alias": "canViewProjectConfig",
            "args": [
              {
                "kind": "Literal",
                "name": "type",
                "value": "VIEW_PROJECT_CONFIG"
              }
            ],
            "concreteType": "JiraProjectAction",
            "kind": "LinkedField",
            "name": "action",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": "action(type:\"VIEW_PROJECT_CONFIG\")"
          },
          {
            "args": (v6/*: any*/),
            "concreteType": "JiraProjectAction",
            "kind": "LinkedField",
            "name": "action",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
          },
          {
            "kind": "ScalarField",
            "name": "softwareBoardCount"
          },
          {
            "condition": "simplifyBoardQuery",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "kind": "LinkedField",
                "name": "navigationMetadata",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "boardId"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "isSimpleBoard"
                      },
                      (v4/*: any*/)
                    ],
                    "type": "JiraSoftwareProjectNavigationMetadata"
                  }
                ]
              }
            ]
          },
          (v8/*: any*/)
        ],
        "type": "JiraProject"
      },
      (v5/*: any*/)
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "Nav4ProjectsContentQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "Nav4ProjectsContent"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Nav4ProjectsContentQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "alias": "favouriteProjects",
            "args": [
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "includeArchivedProjects": false,
                  "sort": {
                    "order": "DESC"
                  },
                  "type": "PROJECT"
                }
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 15
              }
            ],
            "concreteType": "JiraFavouriteConnection",
            "kind": "LinkedField",
            "name": "favourites",
            "plural": false,
            "selections": [
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  }
                ]
              },
              {
                "concreteType": "JiraFavouriteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": (v9/*: any*/)
              },
              (v8/*: any*/)
            ]
          },
          {
            "alias": "recentProjects",
            "args": [
              (v2/*: any*/),
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "includeArchivedProjects": false
                }
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 20
              },
              {
                "kind": "Literal",
                "name": "types",
                "value": [
                  "PROJECT"
                ]
              }
            ],
            "concreteType": "JiraSearchableEntityConnection",
            "kind": "LinkedField",
            "name": "recentItems",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraSearchableEntityEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": (v9/*: any*/)
              },
              (v8/*: any*/)
            ]
          },
          {
            "args": [
              (v2/*: any*/)
            ],
            "concreteType": "JiraWorkManagementLicensing",
            "kind": "LinkedField",
            "name": "jwmLicensing",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "currentUserSeatEdition"
              }
            ]
          }
        ]
      },
      {
        "concreteType": "GrowthRecQuery",
        "kind": "LinkedField",
        "name": "growthRecommendations",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "fields": [
                  {
                    "kind": "Literal",
                    "name": "product",
                    "value": "jira"
                  },
                  {
                    "kind": "Variable",
                    "name": "tenantId",
                    "variableName": "cloudId"
                  },
                  {
                    "kind": "Literal",
                    "name": "useCase",
                    "value": "jiraProjectMenuRecommendations"
                  }
                ],
                "kind": "ObjectValue",
                "name": "context"
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 2
              }
            ],
            "kind": "LinkedField",
            "name": "recommendations",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "entityId"
                          }
                        ],
                        "type": "GrowthRecJiraTemplateRecommendation"
                      }
                    ]
                  }
                ],
                "type": "GrowthRecRecommendations"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "29e87ebc3ed88a85bdd91cb14f18971105d6be18a205fc39acc576fc00397636",
    "metadata": {},
    "name": "Nav4ProjectsContentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "8b2dedc24b16febccff422b581357d53";

export default node;

import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { PLAN_CALENDAR } from '@atlassian/jira-capabilities/src/constants.tsx';
import { setCapability } from '@atlassian/jira-capabilities/src/services/active-capability-tracker/index.tsx';
import { ROUTE_GROUPS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { AsyncHorizontalNavPlans } from '@atlassian/jira-horizontal-nav-plans/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarPlan from '@atlassian/jira-navigation-apps-sidebar-plan/src/async.tsx';
import { planLayout } from '@atlassian/jira-plan-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import {
	advancedRoadmapsPlanPageInitialChecksResource,
	advancedRoadmapsReduxStoreStateResource,
	advancedRoadmapsLastVisitedProgramBoardIdResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarPlanResources,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { softwarePlanCalendarRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/softwarePlanCalendarRoute.tsx';
import { CalendarSkeleton } from '@atlassian/jira-skeletons/src/ui/calendar/CalendarSkeleton.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { planCalendarRouteEntry } from './ui/plan-calendar/index.tsx';

export const softwarePlanCalendarRouteEntry: Route = createEntry(softwarePlanCalendarRoute, () => ({
	group: ROUTE_GROUPS_SOFTWARE,
	ufoName: 'software-advanced-roadmaps-calendar',
	skeleton: () => <CalendarSkeleton />,
	entryPoint() {
		setCapability(PLAN_CALENDAR);
		return planCalendarRouteEntry;
	},
	layout: composeLayouts(globalLayout, planLayout),
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarPlanResources(),
		advancedRoadmapsPlanPageInitialChecksResource,
		advancedRoadmapsReduxStoreStateResource,
		advancedRoadmapsLastVisitedProgramBoardIdResource,
	],
	preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},
	navigation: {
		menuId: MENU_ID.PLANS,
		sidebar: AsyncSidebarPlan,
		horizontal: AsyncHorizontalNavPlans,
		onlyShowHorizontalOnNav4: true,
	},
	meta: {
		capability: PLAN_CALENDAR,
	},
	forPaint: [LazyAtlassianNavigation],
}));

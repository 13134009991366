import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { projectSettingsServicedeskAutomationCopyRuleRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-routes/src/projectSettingsServicedeskAutomationCopyRuleRoute.tsx';
import { getLegacyAutomationRoutesCommon } from './common/getLegacyAutomationRoutesCommon.tsx';
import ServiceDeskAutomationCopyRule, {
	LazyProjectSettingsServiceDeskAutomationCopyRule,
} from './ui/service-desk-automation/copy-rule/index.tsx';

export const projectSettingsServicedeskAutomationCopyRuleRouteEntry = createEntry(
	projectSettingsServicedeskAutomationCopyRuleRoute,
	() => ({
		group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
		...getLegacyAutomationRoutesCommon(),
		ufoName: 'service-management.automation.copy-rule',
		component: ServiceDeskAutomationCopyRule,
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskAutomationCopyRule],
	}),
);

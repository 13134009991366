import { isCMDBEnabledInEnvironment } from '@atlassian/jira-servicedesk-cmdb-fedramp/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';

export function useShowAssets() {
	const tenantContext = useTenantContext();
	const { appPermissions } = tenantContext;

	const isJSMUser = appPermissions.hasServiceDeskAccess;

	if (isCMDBEnabledInEnvironment() && isJSMUser) {
		return true;
	}

	return false;
}

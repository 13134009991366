import React, { useCallback, type FC } from 'react';
import { type ContentProps, Popup, type TriggerProps } from '@atlaskit/popup';
import type { EntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useOpenable } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-openable/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import type {
	MenuItemsComponentType,
	OnCloseProps,
	OnClose,
	CustomTriggerComponentType,
} from '../../types.tsx';
import { MenuContent } from './MenuContent.tsx';
import { MenuTriggerButton } from './MenuTriggerButton.tsx';

type MenuContainerPropsOld = OnCloseProps & {
	MenuItems: MenuItemsComponentType;
	CustomTrigger?: CustomTriggerComponentType;
	onClick?: () => void;
};

// Remove this component when `use-relay-entrypoints-for-project-actions-menu` is cleaned up
export const MenuContainerOld: FC<MenuContainerPropsOld> = ({
	MenuItems,
	onClose: onCloseCustom,
	CustomTrigger,
	onClick,
}: MenuContainerPropsOld) => {
	const { isOpen, onClose, onToggle } = useOpenable();
	const route = useCurrentRoute();

	const content = (contentProps: ContentProps) => (
		<MenuContent {...contentProps} MenuItems={MenuItems} />
	);

	const handleClose = useCallback<OnClose>(
		(e) => {
			try {
				onCloseCustom?.(e);
			} finally {
				onClose();
			}
		},
		[onCloseCustom, onClose],
	);

	const onClickHandler = useCallback(() => {
		onToggle();
		onClick?.();
	}, [onClick, onToggle]);

	const trigger = (triggerProps: TriggerProps) =>
		CustomTrigger ? (
			<CustomTrigger isSelected={isOpen} onClick={onClickHandler} {...triggerProps} />
		) : (
			<MenuTriggerButton {...triggerProps} isSelected={isOpen} onClick={onClickHandler} />
		);

	return (
		<Popup
			content={content}
			isOpen={isOpen}
			onClose={handleClose}
			placement="bottom-start"
			trigger={trigger}
			shouldRenderToParent={getWillShowNav4() ? !route.name.includes('legacy') : true}
			strategy={getWillShowNav4() ? 'absolute' : 'fixed'}
		/>
	);
};

export type MenuContainerProps = OnCloseProps & {
	content: (props: ContentProps) => React.ReactNode;
	CustomTrigger?: CustomTriggerComponentType;
	onClick?: () => void;
	buttonTriggerRef?: EntryPointButtonTrigger;
};
export const MenuContainer: FC<MenuContainerProps> = ({
	content,
	CustomTrigger,
	onClick,
	onClose: onCloseCustom,
	buttonTriggerRef,
}: MenuContainerProps) => {
	const { isOpen, onClose, onToggle } = useOpenable();
	const route = useCurrentRoute();

	const handleClose = useCallback<OnClose>(
		(e) => {
			try {
				onCloseCustom?.(e);
			} finally {
				onClose();
			}
		},
		[onCloseCustom, onClose],
	);

	const onClickHandler = useCallback(() => {
		onToggle();
		onClick?.();
	}, [onClick, onToggle]);

	const trigger = (triggerProps: TriggerProps) => {
		const { ref, ...restTriggerProps } = triggerProps;

		return CustomTrigger ? (
			<CustomTrigger
				isSelected={isOpen}
				onClick={onClickHandler}
				ref={(element: HTMLButtonElement) => {
					buttonTriggerRef && buttonTriggerRef(element);
					if (typeof ref === 'function') {
						ref(element);
					}
				}}
				{...restTriggerProps}
			/>
		) : (
			<MenuTriggerButton
				isSelected={isOpen}
				onClick={onClickHandler}
				ref={(element: HTMLButtonElement) => {
					buttonTriggerRef && buttonTriggerRef(element);
					if (typeof ref === 'function') {
						ref(element);
					}
				}}
				{...restTriggerProps}
			/>
		);
	};

	return (
		<Popup
			content={content}
			isOpen={isOpen}
			onClose={handleClose}
			placement="bottom-start"
			trigger={trigger}
			shouldRenderToParent={getWillShowNav4() ? !route.name.includes('legacy') : true}
			strategy={getWillShowNav4() ? 'absolute' : 'fixed'}
		/>
	);
};

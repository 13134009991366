import { ROUTE_NAMES_SERVICEDESK_ATLASSIAN_ANALYTICS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';
import {
	SERVICE_DESK_ROUTE_PREFIX,
	ATLASSIAN_ANALYTICS_ROUTE_SUFFIX,
} from './common/constants.tsx';

export const servicedeskAtlassianAnalyticsRoute: InvariantRoute = {
	name: ROUTE_NAMES_SERVICEDESK_ATLASSIAN_ANALYTICS,
	path: `${SERVICE_DESK_ROUTE_PREFIX}${ATLASSIAN_ANALYTICS_ROUTE_SUFFIX}`,
	exact: true,
};

import React from 'react';
import { SkeletonItem } from '@atlaskit/menu';
import { xcss, Stack, Box } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { FlyoutContentContainer } from '../index.tsx';
import messages from './messages.tsx';

export const FlyoutMenuContentFallback = () => {
	const { formatMessage } = useIntl();
	if (fg('a11y_violations_fixes_fase_2'))
		return (
			<FlyoutContentContainer>
				<Box
					paddingBlockStart="space.100"
					xcss={skeletonItemContainerStyles}
					role="menuitem"
					aria-label={formatMessage(messages.loadingLabel)}
				>
					<SkeletonItem hasIcon width="100%" />
				</Box>
				<Box
					paddingBlockStart="space.100"
					xcss={skeletonItemContainerStyles}
					role="menuitem"
					aria-label={formatMessage(messages.loadingLabel)}
				>
					<SkeletonItem hasIcon width="100%" />
				</Box>
			</FlyoutContentContainer>
		);

	return (
		<FlyoutContentContainer>
			<Stack xcss={skeletonItemContainerStyles} alignBlock="center">
				<SkeletonItem hasIcon width="100%" />
			</Stack>
			<Stack xcss={skeletonItemContainerStyles} alignBlock="center">
				<SkeletonItem hasIcon width="100%" />
			</Stack>
		</FlyoutContentContainer>
	);
};

const skeletonItemContainerStyles = xcss({
	height: '56px',
});

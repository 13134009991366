import React, { type ReactNode, createContext, useContext } from 'react';
import { ConnectionHandler, commitLocalUpdate, useRelayEnvironment } from 'react-relay';
import type RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment';
import { fg } from '@atlassian/jira-feature-gating';

type UpdateProjectsProviderProps = {
	children: ReactNode;
	favouriteConnectionId: string;
	recentConnectionId: string;
};

type UpdateProjectsConfig = {
	deleteProject: (projectDataId: string) => void;
	archiveProject: (projectDataId: string) => void;
};

const UpdateProjectsContext = createContext<UpdateProjectsConfig | null>(null);

export const useUpdateRelayStore = ({
	environment,
	favouriteConnectionId,
	recentConnectionId,
}: {
	environment: RelayModernEnvironment;
	favouriteConnectionId: string;
	recentConnectionId: string;
}) => {
	const removeProjectRecord = (projectDataId: string) => {
		commitLocalUpdate(environment, (store) => {
			const favorites = store.get(favouriteConnectionId);
			const recents = store.get(recentConnectionId);

			if (favorites && projectDataId) {
				ConnectionHandler.deleteNode(favorites, projectDataId);
			}

			if (recents && projectDataId) {
				ConnectionHandler.deleteNode(recents, projectDataId);
			}
		});
	};

	const archiveProject = (projectDataId: string) => {
		commitLocalUpdate(environment, (store) => {
			store.get(projectDataId)?.setValue('ARCHIVED', 'status');
		});
	};

	return {
		deleteProject: removeProjectRecord,
		archiveProject: fg('blu-4674-filter-out-archive-projects-nav4-sidebar')
			? removeProjectRecord
			: archiveProject,
	};
};

export const UpdateProjectsProvider = ({
	children,
	favouriteConnectionId,
	recentConnectionId,
}: UpdateProjectsProviderProps) => {
	const environment = useRelayEnvironment();

	const updateRelayStore = useUpdateRelayStore({
		environment,
		favouriteConnectionId,
		recentConnectionId,
	});

	return (
		<UpdateProjectsContext.Provider value={updateRelayStore}>
			{children}
		</UpdateProjectsContext.Provider>
	);
};

export const useUpdateProjectsInRelayStoreContext = () => useContext(UpdateProjectsContext);

import { useOgTenantInfo } from '@atlassian/jira-eoc-tenant-info/src/controllers/index.tsx';
import { useByokComplianceSettingWithInitialisation } from '@atlassian/jira-products-jsm-compliance-main/src/services/byok-compliance-setting/index.tsx';
import { SERVICE_DESK } from '@atlassian/jira-shared-types/src/application-key.tsx';
import { FREE_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { useLicensedProducts } from '@atlassian/jira-tenant-context-controller/src/components/licensed-products/index.tsx';

export const useFeatures = () => {
	const { isByokCustomer } = useByokComplianceSettingWithInitialisation();
	const isSiteAdmin = useIsSiteAdmin();
	const isAdmin = useIsAdmin();
	const licensedProducts = useLicensedProducts();
	const { shouldUseJSM, loading: ogTenantInfoLoading } = useOgTenantInfo();
	const appEditions = useAppEditions();

	const hasReports =
		!ogTenantInfoLoading &&
		(isSiteAdmin || isAdmin) &&
		!isByokCustomer &&
		shouldUseJSM &&
		!!licensedProducts[SERVICE_DESK] &&
		appEditions.serviceDesk !== FREE_EDITION;

	return { hasReports };
};

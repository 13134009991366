import React from 'react';
import type ArjDependencySettingsType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/settings/dependencies/index.tsx';
import type ArjLoadingLimitsSettingsType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/settings/loading-limits/index.tsx';
import type ArjPermissionsSettingsType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/settings/permissions/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

/**
 * Plans Settings - Dependencies
 */
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyArjSettingsDependencies = lazyForPaint<typeof ArjDependencySettingsType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-arj-settings-dependencies", jiraSpaEntry: "async-arj-settings-dependencies" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/settings/dependencies'
			),
		),
	{ ssr: false },
);

export const ArjSettingsDependencies = () => (
	<LazyPage
		Page={LazyArjSettingsDependencies}
		pageId="arj-settings-dependencies"
		shouldShowSpinner
	/>
);

/**
 * Plans Settings - Loading Limits
 */
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyArjSettingsLoadingLimits = lazyForPaint<typeof ArjLoadingLimitsSettingsType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-arj-settings-loading-limits", jiraSpaEntry: "async-arj-settings-loading-limits" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/settings/loading-limits'
			),
		),
	{ ssr: false },
);

export const ArjSettingsLoadingLimits = () => (
	<LazyPage
		Page={LazyArjSettingsLoadingLimits}
		pageId="arj-settings-loading-limits"
		shouldShowSpinner
	/>
);

/**
 * Plans Settings - Permissions
 */
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyArjSettingsPermissions = lazyForPaint<typeof ArjPermissionsSettingsType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-arj-settings-permissions", jiraSpaEntry: "async-arj-settings-permissions" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/settings/permissions'
			),
		),
	{ ssr: false },
);

export const ArjSettingsPermissions = () => (
	<LazyPage Page={LazyArjSettingsPermissions} pageId="arj-settings-permissions" shouldShowSpinner />
);

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { entitlementsCustomerQueryResource } from '@atlassian/jira-router-resources-service-desk-customer-service-customer-details/src/services/entitlements-customer-query-resource/index.tsx';
import { uiCustomerDetailsPageQueryResource } from '@atlassian/jira-router-resources-service-desk-customer-service-customer-details/src/services/ui-customer-details-page-query-resource/index.tsx';
import { servicedeskCustomerDetailsRoute } from '@atlassian/jira-router-routes-servicedesk-customers-routes/src/servicedeskCustomerDetailsRoute.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { getCustomersRoutesCommon } from './common/getCustomersRoutesCommon.tsx';
import { ServicedeskCustomerDetails, LazyServicedeskCustomerDetails } from './ui/index.tsx';

export const servicedeskCustomerDetailsRouteEntry: Route = createEntry(
	servicedeskCustomerDetailsRoute,
	() => ({
		...getCustomersRoutesCommon(),
		component: ServicedeskCustomerDetails,
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCustomerDetails],
		perfMetricKey: 'jsm-customer-details-component-load',
		meta: {
			reporting: {
				id: APP_NAMES.CUSTOMER_DETAILS,
				packageName: toPackageName(APP_NAMES.CUSTOMER_DETAILS),
				teamName: 'boysenberry',
			},
		},
		resources: [
			...getCustomersRoutesCommon().resources,
			entitlementsCustomerQueryResource,
			uiCustomerDetailsPageQueryResource,
		],
	}),
);

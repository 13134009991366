/**
 * @generated SignedSource<<26c3fb86c99d8f6423013ecc021c50ae>>
 * @relayHash 7a45095043f56990d89a276777d222e6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1932382d2760a8f56b95470d5e7a694260755e9bfbffef22dc7eb910d386cabd

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraProjectStatus = "ACTIVE" | "ARCHIVED" | "DELETED" | "%future added value";
export type servicesTrialSprintBoardProjectDataQuery$variables = {
  projectAri: string;
};
export type servicesTrialSprintBoardProjectDataQuery$data = {
  readonly jira: {
    readonly jiraProject: {
      readonly boards: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly boardId: AGG$Long | null | undefined;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly key: string;
      readonly status: JiraProjectStatus | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type servicesTrialSprintBoardProjectDataQuery = {
  response: servicesTrialSprintBoardProjectDataQuery$data;
  variables: servicesTrialSprintBoardProjectDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectAri"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectAri"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "status"
},
v3 = {
  "kind": "ScalarField",
  "name": "key"
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v5 = {
  "kind": "ScalarField",
  "name": "boardId"
},
v6 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "servicesTrialSprintBoardProjectDataQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProject",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": (v4/*: any*/),
                "concreteType": "JiraBoardConnection",
                "kind": "LinkedField",
                "name": "boards",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraBoardEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraBoard",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ]
                      }
                    ]
                  }
                ],
                "storageKey": "boards(first:1)"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "servicesTrialSprintBoardProjectDataQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProject",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": (v4/*: any*/),
                "concreteType": "JiraBoardConnection",
                "kind": "LinkedField",
                "name": "boards",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraBoardEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraBoard",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ]
                      }
                    ]
                  }
                ],
                "storageKey": "boards(first:1)"
              },
              (v6/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "1932382d2760a8f56b95470d5e7a694260755e9bfbffef22dc7eb910d386cabd",
    "metadata": {},
    "name": "servicesTrialSprintBoardProjectDataQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "2793f4acb36ace35bec2c93ab6ac60ef";

export default node;

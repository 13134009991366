import React from 'react';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyRoleBasedNotifications = lazyForPaint<any>(() =>
	import(
		/* webpackChunkName: "async-jira-eoc-role-based-notifications", jiraSpaEntry: "async-jira-eoc-role-based-notifications" */ '@atlassian/jira-eoc-role-based-notifications'
	).then((module) => module.RoleBasedNotificationsPage),
);

export const RoleBasedNotificationsComponent = () => (
	<LazyPage
		Page={LazyRoleBasedNotifications}
		pageId="async-jira-eoc-role-based-notifications"
		shouldShowSpinner={false}
	/>
);

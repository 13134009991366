import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	configurationErrorTitle: {
		id: 'portfolio-3-create-sample-plan.common.configuration-error-title',
		defaultMessage: "Your demo plan couldn't be created because of a configuration error",
		description: 'Title for demo plan creation configuration errors.',
	},
	permissionErrorTitle: {
		id: 'portfolio-3-create-sample-plan.common.permission-error-title',
		defaultMessage:
			"Your demo plan couldn't be created because you’re missing a required permission",
		description: 'Title for demo plan creation permission errors.',
	},
	tryAgainErrorTitle: {
		id: 'portfolio-3-create-sample-plan.common.try-again-error-title',
		defaultMessage: 'Try to create your demo plan again',
		description: 'Title for unknown demo plan creation errors.',
	},
	requiredFieldsConfiguredOnIssueError: {
		id: 'portfolio-3-create-sample-plan.common.required-fields-configured-on-issue-error',
		defaultMessage:
			'Your issues have required fields which blocked your demo plan from being created. {readMoreLink}',
		description: 'Description for required fields configured on issue error.',
	},
	emptyIssueLinksError: {
		id: 'portfolio-3-create-sample-plan.common.empty-issue-links-error',
		defaultMessage:
			"Your site doesn't have issue links configured, which are required to create a demo plan. {readMoreLink}",
		description: 'Description for empty issue links error.',
	},
	noBrowseProjectsPermissionError: {
		id: 'portfolio-3-create-sample-plan.common.no-browse-projects-permission-error',
		defaultMessage:
			"Your demo plan couldn't be created because you don’t have the <permission-type>Browse Projects</permission-type> permission. {readMoreLink}",
		description: 'Description for missing Browse Projects permissions error.',
	},
	noCreateIssuesPermissionError: {
		id: 'portfolio-3-create-sample-plan.common.no-create-issues-permission-error',
		defaultMessage:
			"Your demo plan couldn't be created because you don’t have the <permission-type>Create Issues</permission-type> permission. {readMoreLink}",
		description: 'Description for missing Create Issues permissions error.',
	},
	noTransitionIssuesPermissionError: {
		id: 'portfolio-3-create-sample-plan.common.no-transition-issues-permission-error',
		defaultMessage:
			"Your demo plan couldn't be created because you don’t have the <permission-type>Transition Issues</permission-type> permission. {readMoreLink}",
		description: 'Description for missing Transition Issues permissions error.',
	},
	noEditIssuesPermissionError: {
		id: 'portfolio-3-create-sample-plan.common.no-edit-issues-permission-error',
		defaultMessage:
			"Your demo plan couldn't be created because you don’t have the <permission-type>Edit Issues</permission-type> permission. {readMoreLink}",
		description: 'Description for missing Edit Issues permissions error.',
	},
	watchingDisabledError: {
		id: 'portfolio-3-create-sample-plan.common.watching-disabled-error',
		defaultMessage:
			"Your demo plan couldn't be created because you have issue watching disabled. {readMoreLink}",
		description: 'Description for issue watching disabled error.',
	},
	unknownExceptionError: {
		id: 'portfolio-3-create-sample-plan.common.unknown-exception-error',
		defaultMessage:
			"Your demo plan couldn't be created, but we’re not sure why. If the problem persists, contact Atlassian support. {readMoreLink}",
		description: 'Description for an unknown exception error.',
	},
	learnMore: {
		id: 'portfolio-3-create-sample-plan.common.learn-more',
		defaultMessage: 'Learn more',
		description: 'Text for Learn more error message link',
	},
	contactSupport: {
		id: 'portfolio-3-create-sample-plan.common.contact-support',
		defaultMessage: 'Contact Atlassian support',
		description: 'Text for Contact Atlassian support error message link',
	},
	requiredFieldsConfiguredOnIssueErrorIssueTermRefresh: {
		id: 'portfolio-3-create-sample-plan.common.required-fields-configured-on-issue-error-issue-term-refresh',
		defaultMessage:
			'Your issues have required fields which blocked your demo plan from being created. {readMoreLink}',
		description: 'Description for required fields configured on issue error.',
	},
	emptyIssueLinksErrorIssueTermRefresh: {
		id: 'portfolio-3-create-sample-plan.common.empty-issue-links-error-issue-term-refresh',
		defaultMessage:
			"Your site doesn't have issue links configured, which are required to create a demo plan. {readMoreLink}",
		description: 'Description for empty issue links error.',
	},
	noCreateIssuesPermissionErrorIssueTermRefresh: {
		id: 'portfolio-3-create-sample-plan.common.no-create-issues-permission-error-issue-term-refresh',
		defaultMessage:
			"Your demo plan couldn't be created because you don’t have the <permission-type>Create Issues</permission-type> permission. {readMoreLink}",
		description: 'Description for missing Create Issues permissions error.',
	},
	noTransitionIssuesPermissionErrorIssueTermRefresh: {
		id: 'portfolio-3-create-sample-plan.common.no-transition-issues-permission-error-issue-term-refresh',
		defaultMessage:
			"Your demo plan couldn't be created because you don’t have the <permission-type>Transition Issues</permission-type> permission. {readMoreLink}",
		description: 'Description for missing Transition Issues permissions error.',
	},
	noEditIssuesPermissionErrorIssueTermRefresh: {
		id: 'portfolio-3-create-sample-plan.common.no-edit-issues-permission-error-issue-term-refresh',
		defaultMessage:
			"Your demo plan couldn't be created because you don’t have the <permission-type>Edit Issues</permission-type> permission. {readMoreLink}",
		description: 'Description for missing Edit Issues permissions error.',
	},
	watchingDisabledErrorIssueTermRefresh: {
		id: 'portfolio-3-create-sample-plan.common.watching-disabled-error-issue-term-refresh',
		defaultMessage:
			"Your demo plan couldn't be created because you have issue watching disabled. {readMoreLink}",
		description: 'Description for issue watching disabled error.',
	},
});

import flow from 'lodash/flow';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { deleteFilter } from '../../state/actions.tsx';
import {
	getFilter,
	isDeleteInProgress,
	getCanDelete,
	getOnCancel,
} from '../../state/selectors.tsx';
import type { State } from '../../state/types.tsx';
import Footer from './view.tsx';

const mapStateToProps = (state: State) => ({
	filter: getFilter(state),
	canDelete: getCanDelete(state),
	deleteInProgress: isDeleteInProgress(state),
	onCancel: getOnCancel(state),
});

export default flow(
	withFireUiAnalytics({ onDelete: 'filterDeleted', onCancel: 'filterDeleteCanceled' }),
	connect(mapStateToProps, {
		onDelete: deleteFilter,
	}),
)(Footer);

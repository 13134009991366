import React, { useLayoutEffect, type ComponentType } from 'react';
import { JQL_BUILDER_PERFORMANCE_MARKS } from '@atlassian/jira-jql-builder-common/src/ui/performance-context/constants.tsx';
import { usePerformanceContext } from '@atlassian/jira-jql-builder-common/src/ui/performance-context/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { JQLEditorUIOldProps, JQLEditorUIProps } from './common/ui/types.tsx';
import type { JQLBuilderBasicOldProps, JQLBuilderBasicProps } from './main.tsx';
import JQLBuilderBasicUI, {
	JQLBuilderBasicUIWithAnalyticsOld as JQLBuilderBasicUIOld,
} from './ui/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { FieldDataMap } from './common/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { isQueryTooComplex } from './utils/is-query-too-complex';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { mapOperators } from './utils/operators/map-operators.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { JQLBuilderBasicFallback } from './ui';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncJQLEditorUIOld = lazyForPaint<ComponentType<JQLEditorUIOldProps>>(
	() =>
		import(/* webpackChunkName: "async-jql-builder-basic-jql-editor-old" */ './ui/jql-editor').then(
			(module) => module.JQLEditorUIWithErrorBoundaryOld,
		),
	{ ssr: false },
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncJQLEditorUI = lazyForPaint<ComponentType<JQLEditorUIProps>>(
	() => import(/* webpackChunkName: "async-jql-builder-basic-jql-editor" */ './ui/jql-editor'),
	{ ssr: false },
);

export const preloadJqlBuilderBasic = () => AsyncJQLEditorUI.preload();

export const JQLBuilderBasicOldAsync = (props: JQLBuilderBasicOldProps) => {
	const onPerformanceMark = usePerformanceContext();

	useLayoutEffect(() => {
		onPerformanceMark({
			mark: JQL_BUILDER_PERFORMANCE_MARKS.JQL_BUILDER_BASIC_JS_START,
		});
	}, [onPerformanceMark]);

	return <JQLBuilderBasicUIOld {...props} JQLEditor={AsyncJQLEditorUIOld} />;
};

const JQLBuilderBasicAsync = (props: JQLBuilderBasicProps) => {
	const onPerformanceMark = usePerformanceContext();

	useLayoutEffect(() => {
		onPerformanceMark({
			mark: JQL_BUILDER_PERFORMANCE_MARKS.JQL_BUILDER_BASIC_JS_START,
		});
	}, [onPerformanceMark]);

	return <JQLBuilderBasicUI {...props} JQLEditor={AsyncJQLEditorUI} />;
};

export default JQLBuilderBasicAsync;

import React, { useCallback } from 'react';
import SettingsIcon from '@atlaskit/icon/core/settings';
import { LinkItem, Section } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	CUSTOM_ITEM_BUTTON,
	CUSTOM_ITEM_ACTION,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { APP } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-type.tsx';
import {
	LEVEL_THREE,
	LEVEL_TWO,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import { MENU_ID_APPS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import type { OnCloseProps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { MoreNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

type AppActionsProps = { settingsUrl: string; appId: string } & OnCloseProps;

export function AppActions({ settingsUrl, appId, onClose }: AppActionsProps) {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireOpenEvent = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_APPS],
			attributes: {
				level: LEVEL_TWO,
				componentType: CUSTOM_ITEM_BUTTON,
				itemId: 'meatballDropdown',
				section: 'yourApps',
			},
		});
	}, [createAnalyticsEvent]);

	const fireClickEvent = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'clicked',
			actionSubject: 'dropdownItem',
			actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_APPS],
			attributes: {
				level: LEVEL_THREE,
				componentType: CUSTOM_ITEM_ACTION,
				parentItemId: appId,
				parentItemType: APP,
				itemId: 'appSettings',
				section: 'yourApps',
			},
		});
	}, [createAnalyticsEvent, appId]);

	if (!settingsUrl) {
		return null;
	}

	return (
		<MoreNavMenuButton
			MenuItems={(props) => (
				<Section>
					<LinkItem
						iconBefore={<SettingsIcon label="" color={token('color.icon')} />}
						href={settingsUrl}
						target="_blank"
						onClick={(...args) => {
							fireClickEvent();
							props.onClose?.(...args);
						}}
					>
						{formatMessage(messages.appSettings)}
					</LinkItem>
				</Section>
			)}
			onOpen={fireOpenEvent}
			onClose={onClose}
		/>
	);
}

/**
 * @generated SignedSource<<1a2fbec60e53686cc15936be193c6419>>
 * @relayHash 3135d365d88950050d150a9e9c118a61
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ba4fcfcd09e227177aa23416dd3fdedaf9a469a4454cbd34e0ea60cb63a5bf4e

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraJqlViewContext = "JSW_PLANS" | "JWM" | "SHADOW_REQUEST" | "%future added value";
export type hydrationRelayJqlBuilderAdvancedQuery$variables = {
  cloudId: string;
  jql: string;
  viewContext?: JiraJqlViewContext | null | undefined;
};
export type hydrationRelayJqlBuilderAdvancedQuery$data = {
  readonly jira: {
    readonly jqlBuilder: {
      readonly hydrateJqlQuery: {
        readonly fields?: ReadonlyArray<{
          readonly field?: {
            readonly jqlTerm: string;
            readonly searchTemplate: {
              readonly key: string | null | undefined;
            } | null | undefined;
          };
          readonly values?: ReadonlyArray<{
            readonly values?: ReadonlyArray<{
              readonly __typename: string;
              readonly displayName: string;
              readonly jqlTerm: string;
              readonly user?: {
                readonly picture: AGG$URL;
              } | null | undefined;
            } | null | undefined>;
          } | null | undefined>;
        }>;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type hydrationRelayJqlBuilderAdvancedQuery = {
  response: hydrationRelayJqlBuilderAdvancedQuery$data;
  variables: hydrationRelayJqlBuilderAdvancedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jql"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewContext"
},
v3 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "jql"
  },
  {
    "kind": "Variable",
    "name": "viewContext",
    "variableName": "viewContext"
  }
],
v5 = {
  "kind": "ScalarField",
  "name": "jqlTerm"
},
v6 = {
  "concreteType": "JiraJqlField",
  "kind": "LinkedField",
  "name": "field",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "concreteType": "JiraJqlSearchTemplate",
      "kind": "LinkedField",
      "name": "searchTemplate",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "key"
        }
      ]
    }
  ]
},
v7 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v8 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v9 = {
  "kind": "ScalarField",
  "name": "picture"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "hydrationRelayJqlBuilderAdvancedQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "JiraJqlBuilder",
            "kind": "LinkedField",
            "name": "jqlBuilder",
            "plural": false,
            "selections": [
              {
                "args": (v4/*: any*/),
                "kind": "LinkedField",
                "name": "hydrateJqlQuery",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "fields",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v6/*: any*/),
                              {
                                "kind": "LinkedField",
                                "name": "values",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "name": "values",
                                        "plural": true,
                                        "selections": [
                                          (v5/*: any*/),
                                          (v7/*: any*/),
                                          (v8/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "name": "user",
                                                "plural": false,
                                                "selections": [
                                                  (v9/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraJqlUserFieldValue"
                                          }
                                        ]
                                      }
                                    ],
                                    "type": "JiraJqlQueryHydratedValue"
                                  }
                                ]
                              }
                            ],
                            "type": "JiraJqlQueryHydratedField"
                          }
                        ]
                      }
                    ],
                    "type": "JiraJqlHydratedQuery"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "hydrationRelayJqlBuilderAdvancedQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "JiraJqlBuilder",
            "kind": "LinkedField",
            "name": "jqlBuilder",
            "plural": false,
            "selections": [
              {
                "args": (v4/*: any*/),
                "kind": "LinkedField",
                "name": "hydrateJqlQuery",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "fields",
                        "plural": true,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v6/*: any*/),
                              {
                                "kind": "LinkedField",
                                "name": "values",
                                "plural": true,
                                "selections": [
                                  (v8/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "name": "values",
                                        "plural": true,
                                        "selections": [
                                          (v5/*: any*/),
                                          (v7/*: any*/),
                                          (v8/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "name": "user",
                                                "plural": false,
                                                "selections": [
                                                  (v8/*: any*/),
                                                  (v9/*: any*/),
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "id"
                                                  }
                                                ]
                                              }
                                            ],
                                            "type": "JiraJqlUserFieldValue"
                                          }
                                        ]
                                      }
                                    ],
                                    "type": "JiraJqlQueryHydratedValue"
                                  }
                                ]
                              }
                            ],
                            "type": "JiraJqlQueryHydratedField"
                          }
                        ]
                      }
                    ],
                    "type": "JiraJqlHydratedQuery"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ba4fcfcd09e227177aa23416dd3fdedaf9a469a4454cbd34e0ea60cb63a5bf4e",
    "metadata": {},
    "name": "hydrationRelayJqlBuilderAdvancedQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b26239c922b8627fea8a8e88766856e6";

export default node;

/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { AppSkeletonImage } from '../../../common/ui/AppSkeletonImage.tsx';
import imgSVG from './assets/virtual-agent-browse-standard-flows-app-skeleton.tpl.svg';

const customCssStyles = css({
	margin: '0 auto',
	maxWidth: '960px',
});

export const VirtualAgentBrowseStandardFlowsSkeleton = () => (
	<AppSkeletonImage src={imgSVG} css={customCssStyles} />
);

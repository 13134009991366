/**
 * @generated SignedSource<<7661fff4909318f923a859e991c3f990>>
 * @relayHash 6e5fec99171872e06e6faef5354994ff
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1c54fe404b07b4a4533c203ab6c68bf936d5a086953aa1a446da9bec5a0a67f5

import type { ConcreteRequest, Query } from 'relay-runtime';
export type useSavedFiltersFetchQuery$variables = {
  cloudId: string;
  searchString: string;
};
export type useSavedFiltersFetchQuery$data = {
  readonly jira: {
    readonly savedFilters: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly filterId?: string;
          readonly name?: string;
        };
      } | null | undefined> | null | undefined;
    };
  } | null | undefined;
};
export type useSavedFiltersFetchQuery = {
  response: useSavedFiltersFetchQuery$data;
  variables: useSavedFiltersFetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchString"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  {
    "kind": "Variable",
    "name": "keyword",
    "variableName": "searchString"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "filterId"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    }
  ],
  "type": "JiraCustomFilter"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "useSavedFiltersFetchQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "args": (v1/*: any*/),
              "concreteType": "JiraFilterConnection",
              "kind": "LinkedField",
              "name": "savedFilters",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraFilterEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ]
                      },
                      "action": "THROW",
                      "path": "jira.savedFilters.edges.node"
                    }
                  ]
                }
              ]
            },
            "action": "THROW",
            "path": "jira.savedFilters"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSavedFiltersFetchQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraFilterConnection",
            "kind": "LinkedField",
            "name": "savedFilters",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraFilterEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "id"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "1c54fe404b07b4a4533c203ab6c68bf936d5a086953aa1a446da9bec5a0a67f5",
    "metadata": {},
    "name": "useSavedFiltersFetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "69a6cd0661c8d852b37f79d9513a1db0";

export default node;

import React from 'react';
import type MarketplaceType from '@atlassian/jira-spa-apps-marketplace/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyMarketplace = lazyForPaint<typeof MarketplaceType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-marketplace", jiraSpaEntry: "async-marketplace" */ '@atlassian/jira-spa-apps-marketplace'
			),
		),
	{ ssr: false },
);

export const MarketplaceComponent = () => (
	<LazyPage Page={LazyMarketplace} pageId="marketplace" shouldShowSpinner />
);

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	modalConfirmButton: {
		id: 'software-board-create.modal.form.actions.modal-confirm-button',
		defaultMessage: 'Create',
		description: 'Text for the modal Confirm Button',
	},
	modalCancelButton: {
		id: 'software-board-create.modal.form.actions.modal-cancel-button',
		defaultMessage: 'Cancel',
		description: 'Text for the modal Cancel button',
	},
});

import type { Collection } from '@atlassian/jira-polaris-domain-collection/src/index.tsx';

export const filterCollectionByOwnerIds =
	(ownerIds?: string[]) =>
	(collection: Collection): boolean =>
		!ownerIds?.length || ownerIds.some((accountId) => accountId === collection.ownerId);

export const filterCollectionByProjectKeys =
	(projectKeys?: string[]) =>
	(collection: Collection): boolean =>
		!projectKeys?.length ||
		projectKeys.some((projectKey) => collection.projectKeys.includes(projectKey));

export const filterCollectionByText = (search = '') => {
	const searchLower = search.toLocaleLowerCase();
	return ({ name }: Collection): boolean =>
		!search || name?.toLocaleLowerCase().includes(searchLower);
};

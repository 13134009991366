import React, { useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import {
	FLYOUT_MENU_CONTENT_ID,
	BOARD_CREATE_PACKAGE_NAME,
	TEAM_NAME,
} from '../../../common/constants.tsx';
import type { BoardCreateFlyoutMenuProps } from '../types.tsx';
import { flyoutMenuContentEntryPoint } from './entrypoint.tsx';
import { FlyoutMenuContentFallback } from './flyout-menu-content-fallback/index.tsx';

const entryPointParams = {};

export const AsyncFlyoutMenuContent = (
	props: Omit<BoardCreateFlyoutMenuProps, 'renderTrigger'>,
) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		flyoutMenuContentEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			id={FLYOUT_MENU_CONTENT_ID}
			packageName={BOARD_CREATE_PACKAGE_NAME}
			teamName={TEAM_NAME}
			entryPointReferenceSubject={entryPointReferenceSubject}
			fallback={<FlyoutMenuContentFallback />}
			errorFallback="flag"
			runtimeProps={props}
		/>
	);
};

import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import { createJpdExperience } from '../../utils/experience/main.tsx';

const openDialog = createJpdExperience(
	new UFOExperience('jpd.view-permissions.open-dialog', {
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	}),
);

const loadViewAccess = createJpdExperience(
	new UFOExperience('jpd.view-permissions.load-view-access', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const updateViewAccess = createJpdExperience(
	new UFOExperience('jpd.view-permissions.update-view-access', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const updatePrincipals = createJpdExperience(
	new UFOExperience('jpd.view-permissions.update-principals', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadCurrentUserViewPermissions = createJpdExperience(
	new UFOExperience('jpd.view-permissions.load-current-user-view-permissions', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	openDialog,
	loadViewAccess,
	updateViewAccess,
	updatePrincipals,
	loadCurrentUserViewPermissions,
};

import type { ExperienceDetails } from '@atlassian/jira-business-experience-tracking/src/types.tsx';

export const CREATE_CUSTOM_BACKGROUND_EXPERIENCE_OLD: ExperienceDetails = {
	experience: 'createCustomBackground',
	packageName: 'jiraBusinessThemeComponents',
	teamName: 'work-management-frontier',
};

export const CREATE_CUSTOM_BACKGROUND_EXPERIENCE = {
	experience: 'createCustomBackground',
	analyticsSource: 'jiraProjectThemeComponents',
	teamName: 'work-management-frontier',
};

export const UPDATE_ALT_TEXT_EXPERIENCE_OLD: ExperienceDetails = {
	experience: 'updateCustomBackground',
	packageName: 'jiraBusinessThemeComponents',
	teamName: 'work-management-frontier',
};

export const UPDATE_ALT_TEXT_EXPERIENCE = {
	experience: 'updateCustomBackground',
	analyticsSource: 'jiraProjectThemeComponents',
	teamName: 'work-management-frontier',
};

export const DELETE_CUSTOM_BACKGROUND_EXPERIENCE_OLD: ExperienceDetails = {
	experience: 'deleteCustomBackground',
	packageName: 'jiraBusinessThemeComponents',
	teamName: 'work-management-frontier',
};

export const DELETE_CUSTOM_BACKGROUND_EXPERIENCE = {
	experience: 'deleteCustomBackground',
	analyticsSource: 'jiraProjectThemeComponents',
	teamName: 'work-management-frontier',
};

export const THEME_PICKER_ELEMENT_ID = 'background-picker-popup-wrapper';

import React, { type ReactNode, type ComponentType } from 'react';
import { token } from '@atlaskit/tokens';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { messages } from './messages.tsx';

type SpotlightProps = typeof JiraSpotlight extends ComponentType<infer TProps> ? TProps : unknown;

interface Props {
	target: string;
	actionsBeforeElement: ReactNode;
	headingAfterElement: ReactNode;
	actions: (arg0: { learnMoreURL: string }) => SpotlightProps['actions'];
}

export const HeartOfPlanSpotlight = ({
	target,
	actionsBeforeElement,
	actions,
	headingAfterElement,
}: Props) => {
	const { formatMessage } = useIntl();
	return (
		<JiraSpotlight
			target={target}
			targetBgColor={getWillShowNav4() ? undefined : token('elevation.surface', 'white')}
			dialogPlacement="top left"
			heading={formatMessage(messages.title)}
			actionsBeforeElement={actionsBeforeElement}
			actions={actions({
				learnMoreURL:
					'https://support.atlassian.com/jira-software-cloud/docs/how-do-i-read-my-advanced-roadmaps-plan/#1.-Issue-column',
			})}
			headingAfterElement={headingAfterElement}
			messageId="portfolio-3-onboarding.ui.onboarding.spotlight-heart-of-plan.jira-spotlight"
			messageType="transactional"
		>
			<FormattedMessage {...messages.content} />
		</JiraSpotlight>
	);
};

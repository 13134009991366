/**
 * @generated SignedSource<<e16a421cd63a03726ba255c6fa0d8d35>>
 * @relayHash a14678005c0efece5968cd872faea897
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 52ca9c35574dbcab17d2cfec15faa2bb20879cf03478b895754fbb46a56732a3

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessListEmbedQuery } from './src_jiraBusinessListEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessListEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "52ca9c35574dbcab17d2cfec15faa2bb20879cf03478b895754fbb46a56732a3",
    "metadata": {},
    "name": "src_jiraBusinessListEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import React, {
	createContext,
	type ReactNode,
	useCallback,
	useContext,
	useMemo,
	useState,
	useEffect,
} from 'react';
import { useRunOnce } from '@atlassian/jira-polaris-lib-run-once/src/index.tsx';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import {
	DEFAULT_SEARCH_MODE,
	mapSearchModeToAnalyticAtribute,
	SEARCH_MODE_ADVANCED,
	SEARCH_MODE_BASIC,
} from '../../common/constants.tsx';
import type { SearchModeChangeReason, SearchMode } from '../../common/types.tsx';
import { useIsQuerySupported } from '../use-query/index.tsx';

const getSafeSearchMode = (mode: string): SearchMode | undefined => {
	switch (mode) {
		case SEARCH_MODE_BASIC:
			return SEARCH_MODE_BASIC;
		case SEARCH_MODE_ADVANCED:
			return SEARCH_MODE_ADVANCED;
		default:
			return undefined;
	}
};

type Props = {
	searchMode: SearchMode | undefined;
	children: ReactNode;
	onSearchModeChange?: (searchMode: SearchMode, reason?: SearchModeChangeReason) => void;
};

type SingleSearchModeContainerProps = {
	searchMode: SearchMode;
	children: ReactNode;
};

export type SelectSearchModeProviderProps = {
	searchMode: SearchMode | undefined;
	children: ReactNode;
	onSearchModeChange?: (searchMode: SearchMode) => void;
};

type SelectedSearchModeContextProps = {
	searchMode: SearchMode;
	setSelectedSearchMode: (searchMode: SearchMode, userTriggered: boolean) => void;
};

const SelectedSearchModeContext = createContext<SelectedSearchModeContextProps>({
	searchMode: DEFAULT_SEARCH_MODE,
	setSelectedSearchMode: () => null,
});

const getValidSelectedSearchMode = (
	searchMode: SearchMode | undefined,
	querySupported: boolean,
) => {
	if (searchMode === SEARCH_MODE_ADVANCED) {
		return SEARCH_MODE_ADVANCED;
	}

	if (searchMode === SEARCH_MODE_BASIC && querySupported) {
		return SEARCH_MODE_BASIC;
	}

	return SEARCH_MODE_ADVANCED;
};

export const SelectedSearchModeContainer = ({
	children,
	searchMode,
	onSearchModeChange,
}: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { querySupported } = useIsQuerySupported();

	useRunOnce(() => {
		// Send analytics on inital load (like when you open a url with a query too complex)
		if (!querySupported && searchMode !== SEARCH_MODE_ADVANCED) {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'queryTooComplex triggered', {
				searchMode,
			});
		}
	});

	const [searchModeInternal, setSearchModeInternal] = useState<SearchMode>(
		getValidSelectedSearchMode(searchMode, querySupported),
	);
	if (!querySupported && searchModeInternal !== SEARCH_MODE_ADVANCED) {
		fireOperationalAnalytics(createAnalyticsEvent({}), 'queryTooComplex triggered', {
			searchMode: searchModeInternal,
		});

		setSearchModeInternal(SEARCH_MODE_ADVANCED);
	}

	useEffect(() => {
		if (
			!querySupported &&
			searchMode === SEARCH_MODE_BASIC &&
			searchModeInternal === SEARCH_MODE_ADVANCED
		) {
			onSearchModeChange?.(SEARCH_MODE_ADVANCED, 'QueryUnsupported');
		}
	}, [onSearchModeChange, querySupported, searchMode, searchModeInternal]);

	const setSelectedSearchMode = useCallback(
		(newSearchMode: SearchMode, userTriggered: boolean) => {
			const safeSearchMode = getSafeSearchMode(newSearchMode);

			if (!safeSearchMode) {
				return;
			}

			setSearchModeInternal(safeSearchMode);

			onSearchModeChange?.(safeSearchMode, userTriggered ? 'UserTriggered' : 'ExternalTriggered');

			if (userTriggered) {
				fireUIAnalytics(
					createAnalyticsEvent({
						action: 'clicked',
						actionSubject: 'button',
					}),
					'switchJQLEditor',
					{
						switchToEditor: mapSearchModeToAnalyticAtribute[safeSearchMode],
					},
				);
			}
		},
		[createAnalyticsEvent, onSearchModeChange],
	);

	const contextValue: SelectedSearchModeContextProps = useMemo(
		() => ({
			searchMode: searchModeInternal,
			setSelectedSearchMode,
		}),
		[searchModeInternal, setSelectedSearchMode],
	);

	return (
		<SelectedSearchModeContext.Provider value={contextValue}>
			{children}
		</SelectedSearchModeContext.Provider>
	);
};

export const SingleSearchModeContainer = ({
	children,
	searchMode,
}: SingleSearchModeContainerProps) => {
	const contextValue = useMemo(
		() => ({
			searchMode,
			setSelectedSearchMode: () => null,
		}),
		[searchMode],
	);

	return (
		<SelectedSearchModeContext.Provider value={contextValue}>
			{children}
		</SelectedSearchModeContext.Provider>
	);
};

export const useSelectedSearchMode = () => useContext(SelectedSearchModeContext);

import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { calendarEmbedEntrypoint } from '@atlassian/jira-spa-apps-business-calendar-embed/entrypoint.tsx';
import { calendarEntryPoint } from '@atlassian/jira-spa-apps-business-calendar-new/entrypoint.tsx';

export const calendarEmbedPageEntrypoint = createChromelessEntryPoint({
	main: calendarEntryPoint,
});

export const legacyCalendarEmbedPageEntrypoint = createChromelessEntryPoint({
	main: calendarEmbedEntrypoint,
});

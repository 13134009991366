import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { jsmEmailDomainsListResource } from '@atlassian/jira-router-resources-jsm-get-email-domains-list/src/index.tsx';
import { productsJsmOrganizationDetailsResource } from '@atlassian/jira-router-resources-jsm-organization-details/src/index.tsx';
import { entitlementsOrganizationQueryResource } from '@atlassian/jira-router-resources-service-desk-customer-service-organization-details/src/services/entitlements-organization-query-resource/index.tsx';
import { uiOrganizationDetailsPageQueryResource } from '@atlassian/jira-router-resources-service-desk-customer-service-organization-details/src/services/ui-organization-details-page-query-resource/index.tsx';
import { servicedeskOrganizationDetailsRoute } from '@atlassian/jira-router-routes-servicedesk-organizations-routes/src/servicedeskOrganizationDetailsRoute.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { getOrganizationsRoutesCommon } from './common/getOrganizationsRoutesCommon.tsx';
import { ServicedeskOrganizationDetails, LazyServicedeskOrganizationDetails } from './ui/index.tsx';

export const servicedeskOrganizationDetailsRouteEntry: Route = createEntry(
	servicedeskOrganizationDetailsRoute,
	() => ({
		...getOrganizationsRoutesCommon(),
		component: ServicedeskOrganizationDetails,
		forPaint: [LazyAtlassianNavigation, LazyServicedeskOrganizationDetails],
		perfMetricKey: 'jsm-organization-details-component-load',
		meta: {
			reporting: {
				id: APP_NAMES.ORGANIZATION_DETAILS,
				packageName: toPackageName(APP_NAMES.ORGANIZATION_DETAILS),
				teamName: 'boysenberry',
			},
		},
		resources: [
			...getOrganizationsRoutesCommon().resources,
			entitlementsOrganizationQueryResource,
			uiOrganizationDetailsPageQueryResource,
			productsJsmOrganizationDetailsResource,
			jsmEmailDomainsListResource,
		],
	}),
);

import React from 'react';

import { useIntl } from 'react-intl-next';

import Heading from '@atlaskit/heading';
import { Box, Stack, Text, xcss } from '@atlaskit/primitives';

import { messages } from '../messages';

export const ErrorState = () => {
	const { formatMessage } = useIntl();

	return (
		<Box xcss={styles}>
			{/* TODO: Add illustration */}
			<Stack alignInline="center" space="space.050">
				<Heading size="xsmall">{formatMessage(messages.errorStateHeading)}</Heading>
				{/* TODO: content review, allow retry as an action */}
				<Text size="small" align="center" color="color.text.subtle">
					{formatMessage(messages.errorStateDescription)}
				</Text>
			</Stack>
		</Box>
	);
};

const styles = xcss({
	paddingBlock: 'space.1000',
	paddingInline: 'space.100',
});

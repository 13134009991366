import { fg } from '@atlassian/jira-feature-gating';
import {
	uifBacklogResource,
	uifBacklogResourceOld,
} from '@atlassian/jira-router-resources-uif-backlog/src/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { boardConfigApiResource } from './config-api/index.tsx';
import { boardDataResource } from './data-api/index.tsx';
import { rapidboardResource } from './rapidboard-resource/index.tsx';
import { wrmBundleResource } from './wrm-bundle/index.tsx';

export const shouldLoadUIFBoardResources = () => !__SERVER__;
export const shouldLoadLegacyBoardResources = () => !shouldLoadUIFBoardResources();

export const getRapidboardResources = () => [
	wrmBundleResource,
	boardConfigApiResource,
	resourceWithCondition2(shouldLoadLegacyBoardResources, rapidboardResource),
];

export const getRapidBoardDataResource = () => boardDataResource;

export const getUIFBacklogDataResource = () => [
	resourceWithCondition2(
		() => shouldLoadUIFBoardResources() && !fg('indexeddb_cache_for_backlog'),
		uifBacklogResourceOld,
	),
	resourceWithCondition2(
		() => shouldLoadUIFBoardResources() && fg('indexeddb_cache_for_backlog'),
		uifBacklogResource,
	),
];

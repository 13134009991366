/**
 * @generated SignedSource<<0c891316f71e3d453d5f0b3fea27d721>>
 * @relayHash 6846c2b353d52935ffcd97c0c83888da
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID dd6752d7ca61f340baeb3c609b862f187f8cf5af8c22d46be5786ce68d776813

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { mainVirtualServiceAgentCtaCardQuery } from './mainVirtualServiceAgentCtaCardQuery.graphql';

const node: PreloadableConcreteRequest<mainVirtualServiceAgentCtaCardQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "dd6752d7ca61f340baeb3c609b862f187f8cf5af8c22d46be5786ce68d776813",
    "metadata": {},
    "name": "mainVirtualServiceAgentCtaCardQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import { useRef, useCallback } from 'react';

type GetStableItemsParams<T> = {
	nodes: T[];
	idKey: keyof T;
};

/**
 * A custom hook that maintains the stability of item order based on a unique identifier.
 *
 * This hook keeps track of the order of items by their unique IDs across renders. When a new array
 * of items is passed to the `getStableItems` function, the hook attempts to preserve the order
 * of items that were previously encountered, regardless of their new positions within the array.
 *
 * New items in the array (those that haven't been seen before) are added to the beginning of the
 * list, unless they are the last item, in which case they are added to the end.
 *
 * @returns {object} An object containing:
 * - `getStableItems`: A function that accepts an object with `nodes` (the array of items) and `idKey` (the key to access the unique ID for each item).
 *
 * @template T - The type of items in the array.
 * @param {Array<T>} nodes - The array of items to be ordered.
 * @param {string} idKey - The key to access the unique ID of each item in the array.
 *
 * @returns {Array<T>} - The array of items with a stable order based on the previously tracked order.
 */
export const useStableItems = () => {
	const stableIdMapRef = useRef<Map<string, number>>(new Map());

	const getStableItems = useCallback(<T,>({ nodes, idKey }: GetStableItemsParams<T>) => {
		const getStringId = (node: T) => `${node[idKey]}`;

		const intersectedNodes = nodes
			.filter((node) => stableIdMapRef.current.has(getStringId(node)))
			.sort((nodeA, nodeB) => {
				const indexA = stableIdMapRef.current.get(getStringId(nodeA)) ?? 0;
				const indexB = stableIdMapRef.current.get(getStringId(nodeB)) ?? 0;
				return indexA - indexB;
			});

		const hasIntersectedNodes = Boolean(intersectedNodes.length);

		const stableOrderedNodes = hasIntersectedNodes
			? nodes.reduceRight((acc, node, index, array) => {
					if (!stableIdMapRef.current.has(getStringId(node))) {
						const addToStart = index + 1 !== array.length;
						addToStart ? acc.unshift(node) : acc.push(node);
					}

					return acc;
				}, intersectedNodes)
			: nodes;

		stableIdMapRef.current = new Map(
			stableOrderedNodes.map((node, index) => [getStringId(node), index]),
		);

		return stableOrderedNodes;
	}, []);

	return { getStableItems };
};

import React, { useEffect, useState } from 'react';
import { Inline, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { helpCenterSettingsDetailsRoute } from '@atlassian/jira-router-routes-help-center-settings-routes/src/helpCenterSettingsDetailsRoute.tsx';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/ScreenRedirect.tsx';
import { usePathParam } from '@atlassian/react-resource-router';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

export const Redirect = () => {
	const [projectKey = ''] = usePathParam('projectKey');
	const [projectId, setProjectId] = useState('');
	const [error, setError] = useState(false);

	const helpCenterSettingsDetails = helpCenterSettingsDetailsRoute.path
		.replace(':projectKey', projectKey)
		.replace(':projectId', projectId);

	useEffect(() => {
		projectKey &&
			performGetRequest(`/rest/api/3/project/${projectKey}`)
				.then((project) => setProjectId(project.id))
				.catch(() => setError(true));
	}, [projectKey]);

	if (error || !projectKey) {
		return <ErrorPagesNotFound />;
	}

	if (!projectId) {
		return (
			<Inline alignInline="center" xcss={spinnerStyles}>
				<Spinner size="large" />
			</Inline>
		);
	}

	return <ScreenRedirect to={helpCenterSettingsDetails} persistSearchParams />;
};

const spinnerStyles = xcss({ paddingBlock: 'space.1000' });

import type { OperatorValue } from '@atlaskit/jql-ast';
import { fg } from '@atlassian/jira-feature-gating';
import type {
	FieldType,
	SearchTemplate,
} from '@atlassian/jira-jql-builder-basic-picker/src/common/types.tsx';
import {
	CASCADE_SELECT_FIELD_SEARCH_TEMPLATE,
	NUMBER_RANGE_FIELD_SEARCH_TEMPLATE,
	CMDB_FIELD_SEARCH_TEMPLATE,
	JSM_REQUEST_TYPE_SEARCH_TEMPLATE,
	JSM_ORGANIZATIONS_SEARCH_TEMPLATE,
	JSM_WORK_CATEGORY_SEARCH_TEMPLATE,
} from '../../common/constants.tsx';
import {
	type PropTypes,
	type PickerData,
	PickerTypes,
	PickerPropTypes,
	GenericPropTypes,
	TextAreaPropTypes,
} from '../../common/types.tsx';
import { getAllowedOperators } from './get-allowed-operators/index.tsx';

type GetPickerType = {
	searchTemplate: SearchTemplate;
	fieldType: FieldType;
	operators?: OperatorValue[];
	simplifiedOperators?: boolean;
};

export const getUnsupportedPickerType = ({
	searchTemplate,
	fieldType,
}: {
	searchTemplate: SearchTemplate;
	fieldType: FieldType;
}): PickerData => ({
	searchTemplate,
	fieldType,
	pickerType: PickerTypes.Unsupported,
	propType: GenericPropTypes.NoProp,
});

const getPickerData = ({
	searchTemplate,
	fieldType,
	pickerType,
	propType,
	fieldOperators,
	simplifiedOperators = false,
}: {
	searchTemplate: SearchTemplate;
	fieldType: FieldType;
	pickerType: PickerTypes;
	propType: PropTypes;
	fieldOperators?: OperatorValue[];
	simplifiedOperators?: boolean;
}): PickerData => ({
	searchTemplate,
	fieldType,
	pickerType,
	propType,
	allowedOperators: getAllowedOperators({
		pickerType,
		fieldOperators,
		propType,
		simplifiedOperators,
	}),
});

export const getPickerType = ({
	searchTemplate,
	fieldType,
	operators: fieldOperators,
	simplifiedOperators,
}: GetPickerType): PickerData => {
	const commonAttributes = {
		searchTemplate,
		fieldType,
		fieldOperators,
		simplifiedOperators,
	};

	switch (searchTemplate) {
		// move text case next to summary when cleanup nin-text-fields-new-text-search-field
		case 'text':
			if (fg('nin-text-fields-new-text-search-field')) {
				return getPickerData({
					...commonAttributes,
					pickerType: PickerTypes.TextArea,
					propType: TextAreaPropTypes.Text,
				});
			}

			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.Text,
				propType: GenericPropTypes.NoProp,
			});
		case 'textFields':
			if (fg('nin-text-fields-new-text-search-field')) {
				return getPickerData({
					...commonAttributes,
					pickerType: PickerTypes.Text,
					propType: GenericPropTypes.NoProp,
				});
			}

			return getUnsupportedPickerType({ searchTemplate, fieldType });
		case 'summary':
		case 'description':
		case 'environment':
		case 'com.atlassian.jira.plugin.system.customfieldtypes:textsearcher':
		case 'comment':
		case 'com.pyxis.greenhopper.jira:gh-epic-label-searcher':
		case 'jira.polaris:textsearcher':
		case 'com.atlassian.jira.ext.charting:textsearcher':
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.TextArea,
				propType: TextAreaPropTypes.Text,
			});
		case 'com.atlassian.jira.plugin.system.customfieldtypes:exacttextsearcher':
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.TextArea,
				propType: TextAreaPropTypes.ExactText,
			});
		case 'created':
		case 'updated':
		case 'resolutiondate':
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.DateTime,
				propType: GenericPropTypes.NoProp,
			});
		case 'com.atlassian.jira.plugin.system.customfieldtypes:daterange':
		case 'statuscategorychangedate':
		case 'com.atlassian.jpo:jpo-custom-field-baseline-start-searcher':
		case 'com.atlassian.jpo:jpo-custom-field-baseline-end-searcher':
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.DateTime,
				propType: GenericPropTypes.NoProp,
			});
		case 'com.atlassian.jira.toolkit:LastCommentDateSearcher':
		case 'com.atlassian.jira.ext.charting:firstreponsedatesearcher':
		case 'com.atlassian.servicedesk:sd-request-feedback-date-searcher':
		case 'com.atlassian.jira.plugin.system.customfieldtypes:datetimerange':
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.DateTimeRange,
				propType: GenericPropTypes.NoProp,
			});
		case 'duedate':
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.DueDate,
				propType: GenericPropTypes.NoProp,
			});
		case 'labels':
		case 'priority':
		case 'resolution':
		case 'status':
		case 'statusCategory':
		case 'com.atlassian.jira.plugin.system.customfieldtypes:labelsearcher':
		case 'com.atlassian.jira.plugin.system.customfieldtypes:multiselectsearcher':
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.Picker,
				propType: PickerPropTypes.Common,
			});
		case 'components':
		case 'parent':
		case 'com.pyxis.greenhopper.jira:gh-epic-link-searcher':
		case 'ticketCategory':
		case 'com.pyxis.greenhopper.jira:gh-epic-status-searcher':
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.Picker,
				propType: PickerPropTypes.CommonWithEmpty,
			});
		case 'versions':
		case 'fixVersions':
		case 'com.atlassian.jira.plugin.system.customfieldtypes:versionsearcher':
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.Picker,
				propType: PickerPropTypes.Version,
			});
		case 'assignee':
		case 'creator':
		case 'reporter':
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.Picker,
				propType: PickerPropTypes.User,
			});
		case 'com.atlassian.jira.plugin.system.customfieldtypes:userpickergroupsearcher':
		case 'com.atlassian.jira.plugin.system.customfieldtypes:next-gen-people-searcher':
		case 'com.atlassian.jira.toolkit:participantsearcher':
		case 'com.atlassian.jira.toolkit:lastparticipantsearcher':
		case 'com.atlassian.servicedesk:sd-request-participants-searcher':
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.Picker,
				propType: PickerPropTypes.User,
			});
		case 'issuetype':
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.Picker,
				propType: PickerPropTypes.IssueType,
			});
		case 'project':
		case 'com.atlassian.jira.plugin.system.customfieldtypes:projectsearcher':
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.Picker,
				propType: PickerPropTypes.Project,
			});
		case 'com.pyxis.greenhopper.jira:gh-sprint-searcher':
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.Picker,
				propType: PickerPropTypes.Sprint,
			});
		case 'com.atlassian.jira.plugin.system.customfieldtypes:grouppickersearcher':
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.Picker,
				propType: PickerPropTypes.Group,
			});
		case CASCADE_SELECT_FIELD_SEARCH_TEMPLATE:
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.CascadeSelect,
				propType: GenericPropTypes.NoProp,
			});
		case 'com.atlassian.jira.plugin.system.customfieldtypes:exactnumber':
		case 'com.pyxis.greenhopper.jira:jsw-story-points-searcher':
		case 'jira.polaris:exact-number-searcher':
		case 'com.atlassian.servicedesk:sd-request-feedback-searcher':
		case 'com.atlassian.jira.toolkit:commentcountsearcher':
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.TextArea,
				propType: TextAreaPropTypes.Number,
			});
		case 'com.atlassian.jira.toolkit:lastcommentersearcher':
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.BooleanType,
				propType: GenericPropTypes.NoProp,
			});
		case 'workratio':
		case NUMBER_RANGE_FIELD_SEARCH_TEMPLATE:
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.NumberRange,
				propType: GenericPropTypes.NoProp,
			});
		case CMDB_FIELD_SEARCH_TEMPLATE:
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.TextArea,
				propType: TextAreaPropTypes.Aql,
			});
		case JSM_REQUEST_TYPE_SEARCH_TEMPLATE:
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.Picker,
				propType: PickerPropTypes.CommonWithEmpty,
			});
		case JSM_ORGANIZATIONS_SEARCH_TEMPLATE:
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.Picker,
				propType: PickerPropTypes.CommonWithEmpty,
			});
		case JSM_WORK_CATEGORY_SEARCH_TEMPLATE:
			return getPickerData({
				...commonAttributes,
				pickerType: PickerTypes.Picker,
				propType: PickerPropTypes.CommonWithEmpty,
			});
		default:
			return getUnsupportedPickerType({ searchTemplate, fieldType });
	}
};

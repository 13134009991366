import React, { useMemo, useCallback, useState } from 'react';
import { graphql, useRefetchableFragment } from 'react-relay';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { Nav4OnboardingComponentNames } from '@atlassian/jira-atlassian-onboarding-nav4/src/types.tsx';
import { OnboardingSpotlightMaybe } from '@atlassian/jira-atlassian-onboarding-nav4/src/ui/onboarding-spotlight-maybe/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { UpdateProjectsProvider } from '@atlassian/jira-navigation-apps-common/src/controllers/update-project-context/index.tsx';
import {
	MENU_ID_PROJECTS,
	MENU_ID_PROJECTS_VIEW_ALL_STARRED,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { useSidebarNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-context/index.tsx';
import { useRefetchWhenIsSelected } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/controller/use-refetch-when-is-selected/index.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import { useSubscribeAndUpdateFavorites } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/services/set-favorite/index.tsx';
import { useStableItems } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-stable-items/index.tsx';
import { Recommendations } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-growth-recommendations/src/index.tsx';
import { Nav4ProjectsEmptyContent } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-core/src/common/ui/empty-content/Nav4ProjectsEmptyContent.tsx';
import { Nav4ProjectMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-core/src/common/ui/project-menu-item/Nav4ProjectMenuItem.tsx';
import type { ProjectToRestore } from '@atlassian/jira-navigation-project-action-menu/src/types.tsx';
import ProjectRestoreModal from '@atlassian/jira-project-restore-modal/src/ui/index.tsx';
import type { Nav4ProjectsContent$key } from '@atlassian/jira-relay/src/__generated__/Nav4ProjectsContent.graphql';
import QUERY, {
	type Nav4ProjectsContentQuery,
} from '@atlassian/jira-relay/src/__generated__/Nav4ProjectsContentQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	MenuListItem,
	MenuList,
	MenuSection,
	MenuSectionHeading,
} from '@atlassian/navigation-system';
import messages from './messages.tsx';

const MAX_RECENT_PROJECTS = 5;
export const MAX_FAVORITE_PROJECTS = 15;
const VIEW_ALL_STARRED_HREF = '/jira/your-work?starred';

type Nav4ProjectsContentProps = {
	queryRef: Nav4ProjectsContent$key;
};

/*
At the moment, growthRecommendations fragment in GQL returns different response each time. This results in a behaviour where recommendation component in UI sometimes appear and disappear 
as user interacts with the page.

Further observations were:
1) User clicks on a page. It may causes a change in url. this results in the refetch of Nav4ProjectsContent fragment to keep projects recent section up-to-date.
As part of this refetch, growthRecommendations is also refetched as it is part of the same fragment.

2) On each refetch, backend returns different entity ids for recommendations. 
3) As a result, It causes the behavior where a recommendation appears and then disappears on different user interactions.

In order to solve it, a below cache is implemented. The purpose of which is to cache the first response and then use it to render the recommendations component which will also be wrapped
in a memo to avoid further re-renders.
*/
const cache: { growthRecommendations: string[] } = { growthRecommendations: [] };

export function Nav4ProjectsContent({ queryRef }: Nav4ProjectsContentProps) {
	const cloudId = useCloudId();
	const { getStableItems } = useStableItems();
	const { isSelectedPath } = useSidebarNav4();
	const [projectToRestore, setProjectToRestore] = useState<ProjectToRestore | null>(null);

	// https://jplat.jira.atlassian.cloud/browse/BLU-2719
	// We intentionally request 10 starred and 15 recent projects.
	// Then, if all 10 starred projects were recently visited, we still show 5 recent projects as well.
	const [
		{
			jira: { favouriteProjects, recentProjects, jwmLicensing },
			growthRecommendations,
		},
		refetch,
	] = useRefetchableFragment<Nav4ProjectsContentQuery, Nav4ProjectsContent$key>(
		graphql`
			fragment Nav4ProjectsContent on Query
			@refetchable(queryName: "Nav4ProjectsContentQuery")
			@argumentDefinitions(currentURL: { type: URL }) {
				jira @required(action: THROW) {
					favouriteProjects: favourites(
						cloudId: $cloudId
						filter: { type: PROJECT, sort: { order: DESC }, includeArchivedProjects: false }
						first: 15
					) @required(action: THROW) {
						__id
						pageInfo {
							hasNextPage
						}
						edges {
							node @required(action: THROW) {
								... on JiraProject {
									id @required(action: THROW)
									projectKey: key @required(action: THROW)
									...Nav4ProjectMenuItem
								}
							}
						}
					}
					recentProjects: recentItems(
						cloudId: $cloudId
						currentURL: $currentURL
						types: [PROJECT]
						filter: { includeArchivedProjects: false }
						first: 20
					) @required(action: THROW) @optIn(to: "JiraRecentSearch") {
						__id
						edges {
							node @required(action: THROW) {
								... on JiraProject {
									id @required(action: THROW)
									projectKey: key @required(action: THROW)
									...Nav4ProjectMenuItem
								}
							}
						}
					}
					jwmLicensing(cloudId: $cloudId) {
						currentUserSeatEdition
					}
				}
				growthRecommendations {
					recommendations(
						context: {
							useCase: "jiraProjectMenuRecommendations"
							tenantId: $cloudId
							product: "jira"
						}
						first: 2
					) {
						... on GrowthRecRecommendations {
							data {
								... on GrowthRecJiraTemplateRecommendation {
									entityId
								}
							}
						}
					}
				}
			}
		`,
		queryRef,
	);
	useRefetchWhenIsSelected({ cloudId, isSelectedPath, QUERY, refetch, menuId: MENU_ID_PROJECTS });

	const refetchOnChange = useCallback(
		({ onComplete }: { onComplete: () => void }) => {
			refetch(
				{ cloudId },
				{
					fetchPolicy: 'store-and-network',
					onComplete,
				},
			);
		},
		[cloudId, refetch],
	);
	useSubscribeAndUpdateFavorites({
		entityTypename: 'JiraProject',
		favouriteConnectionId: favouriteProjects.__id,
		recentConnectionId: recentProjects.__id,
		refetch: refetchOnChange,
	});

	const { formatMessage } = useIntl();
	const starred = formatMessage(messages.starred);
	const recent = formatMessage(messages.recent);

	const favorites = useMemo(() => {
		const ids = favouriteProjects.edges?.map((edge) => edge?.node.id).filter(Boolean) ?? [];

		const list =
			favouriteProjects.edges?.filter(Boolean).map((edge) => {
				return (
					<Nav4ProjectMenuItem
						key={edge.node.projectKey}
						queryRef={edge.node}
						isCorePremiumUserSeat={jwmLicensing?.currentUserSeatEdition === 'PREMIUM'}
						setProjectToRestore={setProjectToRestore}
						// Show onboarding spotlight on the first item
						spotlight={
							edge.node.id === ids[0]
								? Nav4OnboardingComponentNames.SIDEBAR_PROJECT_SPOTLIGHT
								: undefined
						}
					/>
				);
			}) ?? [];

		return {
			idSet: new Set(ids),
			list: list.slice(0, MAX_FAVORITE_PROJECTS),
			hasItems: Boolean(list.length),
			hasNextPage: favouriteProjects.pageInfo?.hasNextPage || list.length > MAX_FAVORITE_PROJECTS,
		};
	}, [favouriteProjects, jwmLicensing]);

	const recentItems = useMemo(() => {
		const nodes =
			recentProjects.edges
				?.filter(Boolean)
				.filter((edge) => edge.node.id && !favorites.idSet.has(edge.node.id))
				.map((edge) => edge.node)
				.slice(0, MAX_RECENT_PROJECTS) ?? [];

		const stableOrderedNodes = getStableItems({ nodes, idKey: 'id' });
		const firstItemKey = stableOrderedNodes[0]?.projectKey;

		const list = stableOrderedNodes.map((node) => {
			// Show onboarding spotlight on the first item if there are no favorites
			const showSpotlight = !favorites.hasItems && node.projectKey === firstItemKey;

			return (
				<Nav4ProjectMenuItem
					key={node.projectKey}
					queryRef={node}
					isCorePremiumUserSeat={jwmLicensing?.currentUserSeatEdition === 'PREMIUM'}
					setProjectToRestore={setProjectToRestore}
					spotlight={
						showSpotlight ? Nav4OnboardingComponentNames.SIDEBAR_PROJECT_SPOTLIGHT : undefined
					}
				/>
			);
		});

		return { list, hasItems: Boolean(list.length) };
	}, [
		recentProjects.edges,
		getStableItems,
		favorites.idSet,
		jwmLicensing?.currentUserSeatEdition,
		favorites.hasItems,
	]);

	const recommendations = useMemo(() => {
		const appRecommendations =
			growthRecommendations?.recommendations?.data?.map((item) => item.entityId).filter(Boolean) ??
			[];

		if (cache.growthRecommendations.length === 0 && appRecommendations.length > 0) {
			cache.growthRecommendations = appRecommendations;
		}

		return cache.growthRecommendations.length > 0 && fg('blu-3929-sidebar-menu-recommended') ? (
			<Recommendations appRecommendations={cache.growthRecommendations} />
		) : null;
	}, [growthRecommendations?.recommendations?.data]);

	const restoreProjectModal = useCallback(() => {
		if (projectToRestore) {
			return (
				<ModalTransition>
					<ProjectRestoreModal
						{...projectToRestore}
						onProjectRestoreSuccess={() => {
							setProjectToRestore(null);
							refetch(
								{ cloudId },
								{
									fetchPolicy: 'store-and-network',
								},
							);
						}}
						onProjectRestoreFailure={() => {
							setProjectToRestore(null);
						}}
						onClose={() => setProjectToRestore(null)}
					/>
				</ModalTransition>
			);
		}
	}, [cloudId, projectToRestore, refetch]);

	if (!favorites.hasItems && !recentItems.hasItems) {
		return (
			<OnboardingSpotlightMaybe spotlight={Nav4OnboardingComponentNames.SIDEBAR_PROJECT_SPOTLIGHT}>
				<Nav4ProjectsEmptyContent />
				{recommendations}
				{restoreProjectModal()}
			</OnboardingSpotlightMaybe>
		);
	}

	return (
		<UpdateProjectsProvider
			favouriteConnectionId={favouriteProjects.__id}
			recentConnectionId={recentProjects.__id}
		>
			{favorites.hasItems && (
				<>
					<MenuListItem>
						<MenuSection>
							<MenuSectionHeading>{starred}</MenuSectionHeading>
							<MenuList>{favorites.list}</MenuList>
						</MenuSection>
					</MenuListItem>
					{favorites.hasNextPage && (
						<Nav4MenuLinkItem
							href={VIEW_ALL_STARRED_HREF}
							menuId={MENU_ID_PROJECTS_VIEW_ALL_STARRED}
						>
							{formatMessage(messages.viewAllStarred)}
						</Nav4MenuLinkItem>
					)}
				</>
			)}
			{recentItems.hasItems && (
				<MenuListItem>
					<MenuSection>
						<MenuSectionHeading>{recent}</MenuSectionHeading>
						<MenuList>{recentItems.list}</MenuList>
					</MenuSection>
				</MenuListItem>
			)}

			{recommendations}
			{restoreProjectModal()}
		</UpdateProjectsProvider>
	);
}

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { dashboardBackgroundScriptsLayout } from '@atlassian/jira-background-scripts-layout/src/index.tsx';
import { ROUTE_GROUPS_DASHBOARD } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { NORMAL_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { gadgetDirectoryResource } from '@atlassian/jira-router-resources-dashboard/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { systemDashboardEditRoute } from '@atlassian/jira-router-routes-dashboard-routes/src/systemDashboardEditRoute.tsx';
import type { Route } from '@atlassian/react-resource-router/src/common/types.tsx';
import {
	SystemDashboardEditPage,
	dashboardResources,
	LazySystemDashboardEdit,
} from './ui/index.tsx';

export const systemDashboardEditRouteEntry: Route = createEntry(systemDashboardEditRoute, () => ({
	group: ROUTE_GROUPS_DASHBOARD,
	component: SystemDashboardEditPage,
	layout: composeLayouts(globalSettingsLayout, dashboardBackgroundScriptsLayout),
	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsSystem,
	},
	resources: [
		...getNavigationResources(),
		navigationSidebarGlobalResource,
		// IMPORTANT - SSR requires wrm resource must come first
		...dashboardResources,
		gadgetDirectoryResource,
	],
	forPaint: [LazyAtlassianNavigation, LazySystemDashboardEdit],
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
}));

import { useRef } from 'react';

export const useRunOnce = (cb: Function, identifier?: string) => {
	const ref = useRef(true);
	const identifierRef = useRef(identifier);
	if (identifierRef.current !== identifier) {
		ref.current = true;
		identifierRef.current = identifier;
	}
	if (ref.current) cb();
	ref.current = false;
};

/**
 * @generated SignedSource<<08989dca37b73ac077e14ffcdf91eb2a>>
 * @relayHash e2e733511382689d57799209a7cda80f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 934f3682c160b827c7506cbc3968797ce17aff74dc207c68cfa0074008c56e8e

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentBrowseIntentsQuery } from './srcVirtualAgentBrowseIntentsQuery.graphql';

import checkForAiEnabledFF_provider from '@atlassian/jira-relay-provider/src/jsm-va-is-require-ai-enabled.relayprovider';

const node: PreloadableConcreteRequest<srcVirtualAgentBrowseIntentsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "934f3682c160b827c7506cbc3968797ce17aff74dc207c68cfa0074008c56e8e",
    "metadata": {},
    "name": "srcVirtualAgentBrowseIntentsQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider": checkForAiEnabledFF_provider
    }
  }
};

export default node;

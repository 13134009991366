import React, { useCallback } from 'react';
import AddIcon from '@atlaskit/icon/core/add';
import SettingsIcon from '@atlaskit/icon/core/settings';
import { MenuGroup, Section, LinkItem, ButtonItem } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { FIXED_ITEM_BUTTON } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { LEVEL_TWO } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import { MENU_ID_PLANS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { HideL1MenuItemSection } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/hide-l1-menu-item-action/index.tsx';
import { MoreNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/main.tsx';
import { useCreateSamplePlan } from '@atlassian/jira-portfolio-3-create-sample-plan/src/controllers/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics';
import messages from './messages.tsx';

const MoreMenuItems = ({
	hasCreateSamplePlanPermissions,
	hasGlobalPlansAdminPermissions,
}: {
	hasCreateSamplePlanPermissions: boolean;
	hasGlobalPlansAdminPermissions: boolean;
}) => {
	const { formatMessage } = useIntl();
	const [, { open: openCreateSamplePlanModal }] = useCreateSamplePlan();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const createDemoPlanClickHandler = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'clicked',
			actionSubject: 'dropdownItem',
			actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_PLANS],
			attributes: {
				level: LEVEL_TWO,
				componentType: FIXED_ITEM_BUTTON,
				itemId: 'createDemoPlan',
			},
		});
		openCreateSamplePlanModal();
	}, [openCreateSamplePlanModal, createAnalyticsEvent]);

	const adminSettingsClickHandler = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'clicked',
			actionSubject: 'dropdownItem',
			actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_PLANS],
			attributes: {
				level: LEVEL_TWO,
				componentType: FIXED_ITEM_BUTTON,
				itemId: 'adminSettings',
			},
		});
	}, [createAnalyticsEvent]);

	return (
		<MenuGroup>
			{(hasCreateSamplePlanPermissions || hasGlobalPlansAdminPermissions) && (
				<Section isList>
					{hasCreateSamplePlanPermissions && (
						<ButtonItem
							iconBefore={<AddIcon label="" spacing="spacious" color={token('color.icon')} />}
							onClick={createDemoPlanClickHandler}
						>
							{formatMessage(messages.createDemoPlan)}
						</ButtonItem>
					)}
					{hasGlobalPlansAdminPermissions && (
						<LinkItem
							iconBefore={<SettingsIcon label="" spacing="spacious" color={token('color.icon')} />}
							href="/jira/plans/settings/permissions"
							onClick={adminSettingsClickHandler}
						>
							{formatMessage(messages.adminSettings)}
						</LinkItem>
					)}
				</Section>
			)}
			<HideL1MenuItemSection menuId={L1_MENU_ID.PLANS} />
		</MenuGroup>
	);
};

export const MoreMenuButton = ({
	hasCreateSamplePlanPermissions,
	hasGlobalPlansAdminPermissions,
}: {
	hasCreateSamplePlanPermissions: boolean;
	hasGlobalPlansAdminPermissions: boolean;
}) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const clickHandler = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_PLANS],
			attributes: {
				level: '1',
				componentType: 'fixedItemButton',
				itemId: 'meatballDropdown',
			},
		});
	}, [createAnalyticsEvent]);
	return (
		<MoreNavMenuButton
			MenuItems={() => (
				<MoreMenuItems
					hasCreateSamplePlanPermissions={hasCreateSamplePlanPermissions}
					hasGlobalPlansAdminPermissions={hasGlobalPlansAdminPermissions}
				/>
			)}
			name={formatMessage(messages.plansMoreMenu)}
			onOpen={clickHandler}
		/>
	);
};

import React from 'react';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { LazyIntegrationCreate } from './async.tsx';

export const IntegrationCreate = () => (
	<LazyPage
		Page={LazyIntegrationCreate}
		pageId="async-jira-eoc-integration-automation"
		shouldShowSpinner
	/>
);

import { ROUTE_GROUPS_GLOBAL_SETTINGS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalSettingsIssuesPrioritiesRoute } from '@atlassian/jira-router-routes-admin-pages-priorities-routes/src/globalSettingsIssuesPrioritiesRoute.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import PrioritiesPage, {
	LazyPrioritiesPage,
} from './ui/spa/admin-pages/priorities/issue-priorities/index.tsx';

export const globalSettingsIssuesPrioritiesRouteEntry: Route = createEntry(
	globalSettingsIssuesPrioritiesRoute,
	() => ({
		group: ROUTE_GROUPS_GLOBAL_SETTINGS,
		component: componentWithFG(
			'priorities_page_frontend_statsig',
			PrioritiesPage,
			ErrorPagesNotFound,
		),
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},
		resources: [...getNavigationResources(), navigationSidebarGlobalResource],
		forPaint: [LazyPrioritiesPage],
	}),
);

/**
 * @generated SignedSource<<051e81191e9528d0339140ee7536941d>>
 * @relayHash 66baa10107c8516c24b8ed85cb97aa27
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b23a1defc70b85ce697c0dc1d84377c11fe03c64d3dbd4d0cb309c2158ac9e77

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraUpdateUserNavigationConfigurationInput = {
  cloudID: string;
  navItems: ReadonlyArray<JiraConfigurableNavigationItemInput>;
  navKey: string;
};
export type JiraConfigurableNavigationItemInput = {
  isVisible: boolean;
  menuId: string;
};
export type hideL1MenuItemActionMutation$variables = {
  input: JiraUpdateUserNavigationConfigurationInput;
};
export type hideL1MenuItemActionMutation$data = {
  readonly jira: {
    readonly updateUserNavigationConfiguration: {
      readonly success: boolean;
      readonly userNavigationConfiguration: {
        readonly navItems: ReadonlyArray<{
          readonly isVisible: boolean;
          readonly menuId: string;
        }>;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type hideL1MenuItemActionMutation = {
  response: hideL1MenuItemActionMutation$data;
  variables: hideL1MenuItemActionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "JiraConfigurableNavigationItem",
  "kind": "LinkedField",
  "name": "navItems",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "menuId"
    },
    {
      "kind": "ScalarField",
      "name": "isVisible"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "hideL1MenuItemActionMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraUserNavigationConfigurationPayload",
            "kind": "LinkedField",
            "name": "updateUserNavigationConfiguration",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "concreteType": "JiraUserNavigationConfiguration",
                "kind": "LinkedField",
                "name": "userNavigationConfiguration",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "hideL1MenuItemActionMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraUserNavigationConfigurationPayload",
            "kind": "LinkedField",
            "name": "updateUserNavigationConfiguration",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "concreteType": "JiraUserNavigationConfiguration",
                "kind": "LinkedField",
                "name": "userNavigationConfiguration",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "b23a1defc70b85ce697c0dc1d84377c11fe03c64d3dbd4d0cb309c2158ac9e77",
    "metadata": {},
    "name": "hideL1MenuItemActionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "1b96ba09e281b673ec1bfa126587621b";

export default node;

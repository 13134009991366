import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { fg } from '@atlassian/jira-feature-gating';
import { getJQLForCollection } from '@atlassian/jira-polaris-component-collections/src/common/utils/jql.tsx';
import { getIdeasCountForCollection } from '../../../services/ideas/index.tsx';

const debouncedGetIdeasCountWithJQL = debounce(
	(jql: string, res: (count: number) => void, rej?: (err: Error) => void) => {
		getIdeasCountForCollection(jql).then(res, rej);
	},
	500,
);

export const useCollectionIdeasCount = (
	projectKeys: string[],
	filtersJql: string,
): [undefined | number, boolean, Error | null] => {
	const jql = getJQLForCollection(projectKeys, filtersJql);
	const [ideasCount, setIdeasCount] = useState<undefined | number>(undefined);
	const [isLoading, setLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);

	useEffect(() => {
		if (fg('jpd-aurora-roadmap-advanced-filtering')) {
			if (jql) {
				setLoading(true);
				debouncedGetIdeasCountWithJQL(
					jql,
					(newCount) => {
						setIdeasCount(newCount);
						setLoading(false);
						setError(null);
					},
					(err: Error) => {
						setIdeasCount(undefined);
						setLoading(false);
						setError(err);
					},
				);
			} else {
				setIdeasCount(undefined);
			}
		}
	}, [jql]);

	return [ideasCount, isLoading, error];
};

import React, { type ElementType } from 'react';
import { type MessageDescriptor, useIntl } from '@atlassian/jira-intl';

export const useFormattedMessageAndIcon = (message: MessageDescriptor, Icon: ElementType) => {
	const { formatMessage } = useIntl();

	const formattedMessage = formatMessage(message);
	const icon = <Icon label={formattedMessage} spacing="spacious" color="currentColor" />;

	return { formattedMessage, icon };
};

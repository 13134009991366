/**
 * @generated SignedSource<<00967a64cef8c686dca07727f080bd28>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ConfigurableMenuItems$data = {
  readonly jira: {
    readonly userNavigationConfiguration: {
      readonly navItems: ReadonlyArray<{
        readonly isVisible: boolean;
        readonly menuId: string;
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ConfigurableMenuItem">;
  readonly " $fragmentType": "ConfigurableMenuItems";
};
export type ConfigurableMenuItems$key = {
  readonly " $data"?: ConfigurableMenuItems$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConfigurableMenuItems">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "ConfigurableMenuItems",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "cloudID",
              "variableName": "cloudId"
            },
            {
              "kind": "Literal",
              "name": "navKey",
              "value": "NAV_SIDEBAR"
            }
          ],
          "concreteType": "JiraUserNavigationConfiguration",
          "kind": "LinkedField",
          "name": "userNavigationConfiguration",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraConfigurableNavigationItem",
              "kind": "LinkedField",
              "name": "navItems",
              "plural": true,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "kind": "ScalarField",
                    "name": "menuId"
                  },
                  "action": "THROW",
                  "path": "jira.userNavigationConfiguration.navItems.menuId"
                },
                {
                  "kind": "RequiredField",
                  "field": {
                    "kind": "ScalarField",
                    "name": "isVisible"
                  },
                  "action": "THROW",
                  "path": "jira.userNavigationConfiguration.navItems.isVisible"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "ConfigurableMenuItem"
    }
  ],
  "type": "Query"
};

(node as any).hash = "186fc107bcd2a8ade003841f3137d27b";

export default node;

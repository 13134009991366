import type { Users, Groups } from '../../model/index.tsx';

export const createUserUrl = (baseUrl: string, id: string): string => `/jira/people/${id}`;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	response: {
		subscriptions: {
			items: [];
		};
	},
	baseUrl: string,
): {
	users: Users;
	groups: Groups;
} => {
	const subscriptions = (response && response.subscriptions && response.subscriptions.items) || [];
	const users: Array<{
		readonly displayName: string;
		readonly href: string;
	}> = [];
	const groups: Array<{
		readonly name: string;
	}> = [];

	subscriptions.forEach((subscription) => {
		const { user, group } = subscription;
		if (group) {
			return groups.push({
				// @ts-expect-error - TS2339 - Property 'name' does not exist on type 'never'.
				name: group.name,
			});
		}
		// @ts-expect-error - TS2339 - Property 'accountId' does not exist on type 'never'.
		const href = createUserUrl(baseUrl, user.accountId);

		return (
			user &&
			users.push({
				// @ts-expect-error - TS2339 - Property 'displayName' does not exist on type 'never'.
				displayName: user.displayName,
				href,
			})
		);
	});

	return { users, groups };
};

/**
 * @generated SignedSource<<d536907a0598ce04a49f72c5514868c8>>
 * @relayHash 11252970b654e498b6e2d09f939eed6d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 89a8c176d1565fa33778cc9e0cb60bc6d0b431dd054203cbb95787e35046d057

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessFormQuery } from './src_jiraBusinessFormQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessFormQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "89a8c176d1565fa33778cc9e0cb60bc6d0b431dd054203cbb95787e35046d057",
    "metadata": {},
    "name": "src_jiraBusinessFormQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

/**
 * @generated SignedSource<<5fa0bf155fd2c474370d77c19fabe838>>
 * @relayHash f6faefc3eb683f7b4205e7bde3dce3d8
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID cbff3b2f4a0d23e32bcd28cc3036e99f400d17a6c35fac79336e3078ad42f762

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraFavouriteType = "BOARD" | "DASHBOARD" | "FILTER" | "PLAN" | "PROJECT" | "QUEUE" | "%future added value";
export type SortDirection = "ASC" | "DESC" | "%future added value";
export type JiraFavouriteFilter = {
  includeArchivedProjects?: boolean | null | undefined;
  keyword?: string | null | undefined;
  sort?: JiraFavouriteSortInput | null | undefined;
  type?: JiraFavouriteType | null | undefined;
  types?: ReadonlyArray<JiraFavouriteType> | null | undefined;
};
export type JiraFavouriteSortInput = {
  order: SortDirection;
};
export type starredMenuQuery$variables = {
  cloudId: string;
  count: number;
  filter: JiraFavouriteFilter;
};
export type starredMenuQuery$data = {
  readonly jira: {
    readonly allFavourites: {
      readonly pageInfo: {
        readonly startCursor: string | null | undefined;
      };
    } | null | undefined;
  };
  readonly " $fragmentSpreads": FragmentRefs<"starredMenu_navigationAppsSidebarNav4SidebarsContentStarred">;
};
export type starredMenuQuery = {
  response: starredMenuQuery$data;
  variables: starredMenuQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v3 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v4 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "filter"
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v6 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "startCursor"
    }
  ]
},
v7 = [
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v8 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v9 = {
  "kind": "ScalarField",
  "name": "id"
},
v10 = {
  "kind": "ScalarField",
  "name": "name"
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    (v9/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v12 = {
  "kind": "ScalarField",
  "name": "boardId"
},
v13 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isNode"
},
v14 = [
  (v9/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      (v10/*: any*/),
      {
        "kind": "ScalarField",
        "name": "filterUrl"
      },
      {
        "kind": "ScalarField",
        "name": "filterId"
      },
      (v13/*: any*/)
    ],
    "type": "JiraFilter",
    "abstractKey": "__isJiraFilter"
  }
],
v15 = {
  "kind": "ScalarField",
  "name": "title"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "starredMenuQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "alias": "allFavourites",
              "args": (v5/*: any*/),
              "concreteType": "JiraFavouriteConnection",
              "kind": "LinkedField",
              "name": "favourites",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": (v6/*: any*/),
                  "action": "THROW",
                  "path": "jira.allFavourites.pageInfo"
                }
              ]
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      },
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          }
        ],
        "kind": "FragmentSpread",
        "name": "starredMenu_navigationAppsSidebarNav4SidebarsContentStarred"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "starredMenuQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "alias": "allFavourites",
            "args": (v5/*: any*/),
            "concreteType": "JiraFavouriteConnection",
            "kind": "LinkedField",
            "name": "favourites",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ]
          },
          {
            "alias": "starredItems",
            "args": (v7/*: any*/),
            "concreteType": "JiraFavouriteConnection",
            "kind": "LinkedField",
            "name": "favourites",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraFavouriteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "key"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "projectType"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "webUrl"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "projectId"
                          },
                          {
                            "kind": "LinkedField",
                            "name": "defaultNavigationItem",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "typeKey"
                                  }
                                ],
                                "type": "JiraNavigationItem",
                                "abstractKey": "__isJiraNavigationItem"
                              },
                              (v11/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "name": "navigationMetadata",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v12/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "isSimpleBoard"
                                  },
                                  (v9/*: any*/)
                                ],
                                "type": "JiraSoftwareProjectNavigationMetadata"
                              }
                            ]
                          },
                          {
                            "concreteType": "JiraAvatar",
                            "kind": "LinkedField",
                            "name": "avatar",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "large"
                              }
                            ]
                          },
                          (v13/*: any*/)
                        ],
                        "type": "JiraProject"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v14/*: any*/),
                        "type": "JiraSystemFilter"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v14/*: any*/),
                        "type": "JiraCustomFilter"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v9/*: any*/),
                          (v15/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "dashboardUrl"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "dashboardId"
                          },
                          (v13/*: any*/)
                        ],
                        "type": "JiraDashboard"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v9/*: any*/),
                          (v15/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "planUrl"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "planId"
                          },
                          (v13/*: any*/)
                        ],
                        "type": "JiraPlan"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": "title",
                            "kind": "ScalarField",
                            "name": "name"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "boardUrl"
                          },
                          (v12/*: any*/),
                          (v13/*: any*/)
                        ],
                        "type": "JiraBoard"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v9/*: any*/),
                          (v15/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "queueUrl"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "queueId"
                          },
                          (v13/*: any*/)
                        ],
                        "type": "JiraServiceManagementQueue"
                      },
                      (v11/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "cursor"
                  }
                ]
              },
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "endCursor"
                  }
                ]
              }
            ]
          },
          {
            "alias": "starredItems",
            "args": (v7/*: any*/),
            "filters": [
              "cloudId",
              "filter"
            ],
            "handle": "connection",
            "key": "StarredMenuListContent_starredItems",
            "kind": "LinkedHandle",
            "name": "favourites"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "cbff3b2f4a0d23e32bcd28cc3036e99f400d17a6c35fac79336e3078ad42f762",
    "metadata": {},
    "name": "starredMenuQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "a986e516464a8c05df425dc6bf3d574d";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;

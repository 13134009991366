import React, { memo, useMemo } from 'react';
import { DropdownItemGroup } from '@atlaskit/dropdown-menu';
import BoardIcon from '@atlaskit/icon/core/board';
import SprintIcon from '@atlaskit/icon/core/sprint';
import { xcss, Box, Bleed } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { BOARD_TYPES } from '../../../common/constants.tsx';
import commonMessages from '../../../common/messages.tsx';
import type { BoardCreateFlyoutMenuProps } from '../types.tsx';
import { DropdownItemContainer } from './dropdown-item-container/index.tsx';
import type { DropdownItem } from './types.tsx';

export const FlyoutContentContainer = ({ children }: { children: React.ReactNode }) => {
	const { formatMessage } = useIntl();
	return (
		<Box xcss={menuContainerStyles}>
			<Box xcss={headerContainerStyles}>
				<Box xcss={headerStyles}>{formatMessage(commonMessages.boardCreationTitle)}</Box>
			</Box>
			<Bleed block="space.050">{children}</Bleed>
		</Box>
	);
};

const BoardCreateFlyoutMenuContent = ({
	props: { projectId, projectAvatar, projectKey, triggerRef },
}: {
	props: Omit<BoardCreateFlyoutMenuProps, 'renderTrigger'>;
}) => {
	const { formatMessage } = useIntl();

	const dropdownItems = useMemo(() => {
		const items: DropdownItem[] = [
			{
				boardType: BOARD_TYPES.SCRUM,
				title: formatMessage(commonMessages.scrumBoardCreationHeader),
				description: formatMessage(commonMessages.scrumBoardCreationDescription),
				icon: <SprintIcon label="" color={token('color.icon')} />,
			},
			{
				boardType: BOARD_TYPES.KANBAN,
				title: formatMessage(commonMessages.kanbanBoardCreationHeader),
				description: formatMessage(commonMessages.kanbanBoardCreationDescription),
				icon: <BoardIcon label="" color={token('color.icon')} />,
			},
		];

		return items.map(({ boardType, ...props }, index) => (
			<DropdownItemContainer
				key={boardType}
				entryPointProps={{ boardType, projectId, projectAvatar, projectKey, triggerRef }}
				{...(fg('forward_ref_modalentrypointdropdownitemtrigger') && { index })}
				{...props}
			/>
		));
	}, [formatMessage, projectId, projectAvatar, projectKey, triggerRef]);

	return (
		<FlyoutContentContainer>
			<DropdownItemGroup>{dropdownItems}</DropdownItemGroup>
		</FlyoutContentContainer>
	);
};

export default memo(BoardCreateFlyoutMenuContent);

const headerStyles = xcss({
	font: 'font.heading.xxsmall',
	color: 'color.text.subtlest',
});

const menuContainerStyles = xcss({
	width: '273px',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
});

const headerContainerStyles = xcss({
	height: 'space.200',
	padding: 'space.200',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
});

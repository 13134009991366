/**
 * @generated SignedSource<<ab8189a4d60e37ca33c72cc50edbd6c7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraJQLBuilderSearchMode = "ADVANCED" | "BASIC" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type main_jqlBuilder_JQLBuilder$data = {
  readonly jqlBuilderSearchMode: JiraJQLBuilderSearchMode | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ui_jqlBuilder_JQLBuilderUI">;
  readonly " $fragmentType": "main_jqlBuilder_JQLBuilder";
};
export type main_jqlBuilder_JQLBuilder$key = {
  readonly " $data"?: main_jqlBuilder_JQLBuilder$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_jqlBuilder_JQLBuilder">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_jqlBuilder_JQLBuilder",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_jqlBuilder_JQLBuilderUI"
    },
    {
      "kind": "ScalarField",
      "name": "jqlBuilderSearchMode"
    }
  ],
  "type": "JiraUserPreferences"
};

(node as any).hash = "939f009c6569621eae121104d8e94579";

export default node;

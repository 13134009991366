import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

export const landingPageRoute: InvariantRoute = {
	// We name this virtualagentonboarding without the "-" as otherwise it would trigger
	// the nesting stack, which we don't want for onboarding
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-virtualagentonboarding`,
	exact: true,
	path: '/jira/servicedesk/projects/:projectKey/settings/virtual-agent',
};

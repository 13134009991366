import { ASSIGNEE_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import type { AnalyticsAttributes } from '../../common/types/analytics/index.tsx';

const ACTIONS_TO_WATCH = ['unassign', 'assignToMe'];

// a way to set isCommandPaletteEditing to true when action is
// from issue shortcut registry
export const setEventAttribute = (attributes: AnalyticsAttributes) => {
	if (
		attributes.actionCategory === 'issueActions' &&
		typeof attributes.action === 'string' &&
		ACTIONS_TO_WATCH.includes(attributes.action)
	) {
		getUpdateAnalyticsFlowHelper().setAttributes(ASSIGNEE_TYPE, {
			isCommandPaletteEditing: true,
		});
	}
};

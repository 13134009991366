import { resources as alertIssueSyncingResources } from '@atlassian/eoc-alert-issue-syncing/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_EOC } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { eocAlertIssueSyncingRoute } from '@atlassian/jira-router-routes-eoc-alert-issue-syncing-routes/src/eocAlertIssueSyncingRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { AlertIssueSync } from './ui/AlertIssueSync.tsx';
import { LazyAlertIssueSync } from './ui/async.tsx';

export const eocAlertIssueSyncingRouteEntry: Route = createEntry(eocAlertIssueSyncingRoute, () => ({
	group: ROUTE_GROUPS_EOC,
	component: AlertIssueSync,
	slot: null,
	basePath: '/jira/settings/products/ops',
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		alertIssueSyncingResources.configuredSyncsResource,
		alertIssueSyncingResources.userAccessRightsResource,
	],
	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		onlyShowHorizontalOnNav4: true,
	},
	forPaint: [LazyHorizontalOperationsConfigurationNav, LazyAtlassianNavigation, LazyAlertIssueSync],
}));

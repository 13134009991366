import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	fixErrorWithAiNonFinal: {
		id: 'jql-debugger.trigger-button.fix-error-with-ai-non-final',
		defaultMessage: 'Fix with AI',
		description:
			'Button label for the trigger which invokes the JQL-Debugger AI feature. This feature allows users to fix JQL errors in the issue-navigator JQL editor',
	},
	jsErrorNonFinal: {
		id: 'jql-debugger.trigger-button.js-error-non-final',
		defaultMessage: 'Something went wrong',
		description:
			'Error message to be shown when an unknown system error happens, making the feature unusable',
	},
	errorIconLabelNonFinal: {
		id: 'jql-debugger.trigger-button.error-icon-label-non-final',
		defaultMessage: 'Error Icon',
		description: 'Label text for the error icon associated with the unknown error scenario',
	},
});

import type { PreloadFetchPolicy } from 'react-relay';
import projectActionsMenuQueryParameters from '@atlassian/jira-relay/src/__generated__/async_AsyncMenuContentQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const fetchPolicy: PreloadFetchPolicy = 'network-only';

type QueryParams = {
	cloudId: string;
	projectKey: string;
};
export const projectActionMenuEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-project-actions-menu-content" */ './async').then(
			(module) => module.AsyncMenuWithQuery,
		),
	),
	getPreloadProps: ({ cloudId, projectKey }: QueryParams) => {
		const projectActionMenuQuery = {
			parameters: projectActionsMenuQueryParameters,
			variables: {
				cloudId,
				projectKey,
			},
			options: {
				fetchPolicy,
			},
		};

		return {
			queries: {
				projectActionMenuQuery,
			},
		};
	},
});

export const RESOURCE_TYPE_SERVICEDESK_QUEUES_NAVIGATION_ITEMS =
	'SERVICEDESK_QUEUES_NAVIGATION_ITEMS';

export const RESOURCE_TYPE_SERVICEDESK_QUEUES_PRIORITY_GROUPS =
	'SERVICEDESK_QUEUES_PRIORITY_GROUPS';

export const RESOURCE_TYPE_SERVICEDESK_QUEUES_PRIORITY_GROUPS_BY_PROJECT_ID =
	'SERVICEDESK_QUEUES_PRIORITY_GROUPS_BY_PROJECT_ID';

export const RESOURCE_TYPE_SERVICEDESK_QUEUES_ISSUE_LIST_SSR = 'SERVICEDESK_QUEUES_ISSUE_LIST_SSR';

export const ONE_DAY_IN_MILLIS = 60 * 60 * 1000 * 24;

import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import { createJpdExperience } from '../../utils/experience/main.tsx';

const loadChildIssues = createJpdExperience(
	new UFOExperience('jpd.delivery-items.load.childIssues', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadAggregatedDeliveryProgressBasedOnProjectId = createJpdExperience(
	new UFOExperience('jpd.delivery-items.load.aggregatedDeliveryProgressBasedOnProjectId', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadAggregatedDeliveryProgress = createJpdExperience(
	new UFOExperience('jpd.delivery-items.load.aggregatedDeliveryProgress', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadLinkedDeliveryTickets = createJpdExperience(
	new UFOExperience('jpd.delivery-items.load.linkedDeliveryTickets', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadTransitiveChildrenArjHierarchy = createJpdExperience(
	new UFOExperience('jpd.delivery-items.load.transitiveChildrenArjHierarchy', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

const loadTransitiveChildrenInTwoLevelCoreHierarchy = createJpdExperience(
	new UFOExperience('jpd.delivery-items.load.transitiveChildrenInTwoLevelCoreHierarchy', {
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	}),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	loadChildIssues,
	loadAggregatedDeliveryProgressBasedOnProjectId,
	loadAggregatedDeliveryProgress,
	loadLinkedDeliveryTickets,
	loadTransitiveChildrenArjHierarchy,
	loadTransitiveChildrenInTwoLevelCoreHierarchy,
};

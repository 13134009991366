/* eslint-disable @atlassian/relay/must-colocate-fragment-spreads */
import React from 'react';
import { graphql, useFragment } from 'react-relay';
import {
	JIRA_PROJECT_TYPE_CORE_PROJECT,
	JIRA_PROJECT_TYPE_CUSTOMER_SERVICE_PROJECT,
	JIRA_PROJECT_TYPE_PRODUCT_DISCOVERY_PROJECT,
	JIRA_PROJECT_TYPE_SERVICE_DESK_PROJECT,
	JIRA_PROJECT_TYPE_SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { JiraBusinessProjectMenu } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-jira-business-project-menu/src/ui/JiraBusinessProjectMenu.tsx';
import { AsyncJpdProjectMenu as JpdProjectMenu } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-jpd-project-menu/src/async.tsx';
import { AsyncJsmProjectMenu as JsmProjectMenu } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-jsm-project-menu/src/async.tsx';
import { JswProjectMenu } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-jsw-project-menu/src/ui/JswProjectMenu.tsx';
import { ProjectRestoreContext } from '@atlassian/jira-navigation-project-action-menu/src/common/context/project-restore-context.tsx';
import type { ProjectToRestore } from '@atlassian/jira-navigation-project-action-menu/src/types.tsx';
import type { Nav4ProjectMenuItem$key } from '@atlassian/jira-relay/src/__generated__/Nav4ProjectMenuItem.graphql';

type Nav4ProjectMenuItemProps = {
	queryRef: Nav4ProjectMenuItem$key;
	isCorePremiumUserSeat?: boolean;
	setProjectToRestore: React.Dispatch<React.SetStateAction<ProjectToRestore | null>>;
	spotlight?: string;
};

export function Nav4ProjectMenuItem({
	queryRef,
	setProjectToRestore,
	isCorePremiumUserSeat = false,
	spotlight,
}: Nav4ProjectMenuItemProps) {
	// remove "status" when cleaning up the fg('blu-4674-filter-out-archive-projects-nav4-sidebar')
	const data = useFragment<Nav4ProjectMenuItem$key>(
		graphql`
			fragment Nav4ProjectMenuItem on JiraProject {
				status @required(action: THROW)
				projectType @required(action: THROW)
				...JiraBusinessProjectMenu
				...JsmProjectMenu
				...JpdProjectMenu
				...JswProjectMenu
			}
		`,
		queryRef,
	);

	if (!data) {
		return null;
	}

	if (!fg('blu-4674-filter-out-archive-projects-nav4-sidebar') && data.status === 'ARCHIVED') {
		return null;
	}

	const renderMenuItem = () => {
		switch (data.projectType) {
			case JIRA_PROJECT_TYPE_SERVICE_DESK_PROJECT:
			case JIRA_PROJECT_TYPE_CUSTOMER_SERVICE_PROJECT:
				return <JsmProjectMenu projectData={data} spotlight={spotlight} />;
			case JIRA_PROJECT_TYPE_PRODUCT_DISCOVERY_PROJECT:
				return <JpdProjectMenu projectData={data} spotlight={spotlight} />;
			case JIRA_PROJECT_TYPE_SOFTWARE_PROJECT:
				return <JswProjectMenu projectData={data} spotlight={spotlight} />;
			case JIRA_PROJECT_TYPE_CORE_PROJECT:
			default:
				// TODO: One day, we will call this JwmProjectMenu, and subsequently JiraProjectMenu (Spork!)
				return (
					<JiraBusinessProjectMenu
						projectData={data}
						isCorePremiumUserSeat={isCorePremiumUserSeat}
						spotlight={spotlight}
					/>
				);
		}
	};

	// Using a switch to support a fallback option
	return (
		<ProjectRestoreContext.Provider value={setProjectToRestore}>
			{renderMenuItem()}
		</ProjectRestoreContext.Provider>
	);
}

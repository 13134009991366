import React, { useMemo } from 'react';
import { JiraIcon, JiraProductDiscoveryIcon, JiraServiceManagementIcon } from '@atlaskit/logo';
import { Flex, Stack, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import {
	JIRA_PROJECT_TYPE_SERVICE_DESK_PROJECT,
	JIRA_PROJECT_TYPE_CUSTOMER_SERVICE_PROJECT,
	JIRA_PROJECT_TYPE_PRODUCT_DISCOVERY_PROJECT,
	JIRA_PROJECT_TYPE_CORE_PROJECT,
	JIRA_PROJECT_TYPE_SOFTWARE_PROJECT,
	type JiraProjectType,
	convertToProjectTypeUiText,
	COMPANY_MANAGED_PROJECT,
	type JiraProjectStyle,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export type ProjectTypeTileProps = {
	projectType: JiraProjectType;
	projectStyle?: JiraProjectStyle | undefined | null;
};

export const ProjectTypeTile = ({ projectType, projectStyle }: ProjectTypeTileProps) => {
	const { formatMessage } = useIntl();

	const IconComponent = useMemo(() => {
		switch (projectType) {
			case JIRA_PROJECT_TYPE_SERVICE_DESK_PROJECT:
			case JIRA_PROJECT_TYPE_CUSTOMER_SERVICE_PROJECT:
				return JiraServiceManagementIcon;
			case JIRA_PROJECT_TYPE_PRODUCT_DISCOVERY_PROJECT:
				return JiraProductDiscoveryIcon;
			default:
				return JiraIcon;
		}
	}, [projectType]);

	/** Team-managed or Company-managed */
	const projectStyleName = useMemo(
		() =>
			projectStyle === COMPANY_MANAGED_PROJECT
				? formatMessage(messages.companyManagedDescription)
				: formatMessage(messages.teamManagedDescription),
		[projectStyle, formatMessage],
	);

	/** Jira - software, Jira - business, Jira Service Management or Jira Product Discovery */
	const projectTypeMessage = useMemo(() => {
		// JPD
		if (projectType === JIRA_PROJECT_TYPE_PRODUCT_DISCOVERY_PROJECT) {
			return formatMessage(
				fg('jira_nav4_beta_drop_2')
					? messages.projectTypeDescriptionProductDiscovery
					: messages.projectTypeDescriptionJiraProductDiscovery,
			);
		}

		// JSM
		if (
			projectType === JIRA_PROJECT_TYPE_SERVICE_DESK_PROJECT ||
			projectType === JIRA_PROJECT_TYPE_CUSTOMER_SERVICE_PROJECT
		) {
			return formatMessage(
				fg('jira_nav4_beta_drop_2')
					? messages.projectTypeDescriptionServiceProject
					: messages.projectTypeDescriptionServiceDesk,
			);
		}

		// JWM
		if (projectType === JIRA_PROJECT_TYPE_CORE_PROJECT && fg('jira_nav4_beta_drop_2')) {
			return formatMessage(messages.projectTypeDescriptionBusinessProject);
		}

		// JSW
		if (projectType === JIRA_PROJECT_TYPE_SOFTWARE_PROJECT && fg('jira_nav4_beta_drop_2')) {
			return formatMessage(messages.projectTypeDescriptionSoftwareProject);
		}

		// JSW or JWM - delete on 'jira_nav4_beta_drop_2' cleanup
		const projectTypeNameShort = convertToProjectTypeUiText(projectType);

		return formatMessage(messages.projectTypeDescriptionJiraDash, {
			projectTypeName: projectTypeNameShort.toLowerCase(),
		});
	}, [projectType, formatMessage]);

	return (
		<div role="group" aria-label={formatMessage(messages.projectTypeGroupAccessibleLabel)}>
			<Flex
				alignItems="start"
				xcss={projectTypeStyles}
				testId="project-type-tile.project-type-tile"
			>
				<IconComponent size="xsmall" appearance="brand" label="" />
				<Stack>
					<Text
						size="UNSAFE_small"
						weight="medium"
						color="color.text"
						testId="project-type-tile.project-description"
					>
						{projectTypeMessage}
					</Text>
					{projectType !== JIRA_PROJECT_TYPE_PRODUCT_DISCOVERY_PROJECT && (
						<Text
							size="UNSAFE_small"
							color="color.text.subtle"
							testId="project-type-tile.project-caption"
						>
							{projectStyleName}
						</Text>
					)}
				</Stack>
			</Flex>
		</div>
	);
};

const projectTypeStyles = xcss({
	paddingBlock: 'space.200',
	paddingInline: 'space.250',
	gap: 'space.200',
	borderBlockStart: `1px solid ${token('color.border')}`,
	backgroundColor: 'elevation.surface.sunken',
});

import type { Users, Groups } from '../../model/index.tsx';
import getAssociatedSubscription from '../../rest/get-associated-subscriptions/index.tsx';
import transformResponse from './transformers.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async (
	baseUrl: string,
	filterId: string,
): Promise<{
	users: Users;
	groups: Groups;
}> => {
	const response: {
		subscriptions: {
			items: [];
		};
	} = await getAssociatedSubscription(baseUrl, filterId);
	return transformResponse(response, baseUrl);
};

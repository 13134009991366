import { useCallback, useState } from 'react';

export const useOpenable = (initialState = false) => {
	const [isOpen, setIsOpen] = useState(initialState);
	const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);
	const onOpen = useCallback(() => setIsOpen(true), [setIsOpen]);
	const onToggle = useCallback(() => setIsOpen((value) => !value), [setIsOpen]);

	return { isOpen, onClose, onOpen, onToggle, setIsOpen };
};

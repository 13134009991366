/**
 * @generated SignedSource<<54e4cd9af3126e1803e1a25d884d1209>>
 * @relayHash cc1bbe83afeb7274c80831503704efb5
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 30d00a603e063cd0c224df1287d43579532806f35c6c43a87f769b3d96287e03

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { async_AsyncMenuContentQuery } from './async_AsyncMenuContentQuery.graphql';

const node: PreloadableConcreteRequest<async_AsyncMenuContentQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "30d00a603e063cd0c224df1287d43579532806f35c6c43a87f769b3d96287e03",
    "metadata": {},
    "name": "async_AsyncMenuContentQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import { createContext, useContext } from 'react';
import type { BoardCreateContextProps } from './types.tsx';

const initialContext: BoardCreateContextProps = {
	projectId: undefined,
	projectAvatar: undefined,
	projectKey: undefined,
};

export const BoardCreateContext = createContext<BoardCreateContextProps>(initialContext);

export const useBoardCreateContext = (): BoardCreateContextProps => useContext(BoardCreateContext);

import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { MENU_ID_OVERVIEWS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { useSidebarNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-context/index.tsx';
import type { Nav4Overviews$key } from '@atlassian/jira-relay/src/__generated__/Nav4Overviews.graphql';
import { SectionActions } from '../common/ui/section-actions/SectionActions.tsx';
import { Nav4OverviewsDefault } from './default/Nav4OverviewsDefault.tsx';
import { Nav4OverviewsForLanding } from './for-landing/Nav4OverviewsForLanding.tsx';

export type Nav4OverviewsProps = {
	isExpanded: boolean;
	queryRef: Nav4Overviews$key;
};

export function Nav4Overviews({ isExpanded, queryRef }: Nav4OverviewsProps) {
	const { isInitialSelectedPath } = useSidebarNav4();

	const data = useFragment<Nav4Overviews$key>(
		graphql`
			fragment Nav4Overviews on Query {
				jira {
					jwmOverviews(cloudId: $cloudId) {
						totalCount
					}
				}
				...Nav4OverviewsForLanding @include(if: $isOverviewsExpanded)
			}
		`,
		queryRef,
	);

	const actionButtons = useMemo(
		() => <SectionActions totalCount={data?.jira?.jwmOverviews?.totalCount ?? 0} />,
		[data?.jira?.jwmOverviews?.totalCount],
	);

	return isInitialSelectedPath(MENU_ID_OVERVIEWS) ? (
		<Nav4OverviewsForLanding
			isExpanded={isExpanded}
			queryRef={data}
			actionsOnHover={actionButtons}
		/>
	) : (
		<Nav4OverviewsDefault isExpanded={isExpanded} actionsOnHover={actionButtons} />
	);
}

import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { performCmdbGetRequest } from '@atlassian/jira-servicedesk-cmdb-client/src/fetch.tsx';
import { getDataManagerFetchContainerTokenUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/data-manager.tsx';
import type { ActionApi, FetchTokensResponse } from '../../common/types.tsx';

export const fetchDataManagerTokens =
	(): ActionApi =>
	async ({ setState, getState }, { workspaceId, createAnalyticsEvent }) => {
		const state = getState();

		if (state.dataManager.tokensLoading) {
			return;
		}
		setState({
			...state,
			dataManager: { ...state.dataManager, tokensLoading: true },
		});

		try {
			const tokenDetails: FetchTokensResponse = await performCmdbGetRequest(
				getDataManagerFetchContainerTokenUrl(workspaceId),
			);

			setState({
				dataManager: {
					...state.dataManager,
					tokensData: tokenDetails.tokens,
					tokensLoading: false,
				},
			});
			fireTrackAnalytics(createAnalyticsEvent({}), 'fetchDataManagerTokens succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				...state,
				dataManager: { ...state.dataManager, tokensLoading: false, tokensError: error },
			});
			fireErrorAnalytics({
				meta: {
					id: 'fetchDataManagerTokens',
					packageName: 'jiraServicedeskInsightGlobalConfigurationStore',
				},
				error,
			});

			throw error;
		}
	};

import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectsAnalyticsL1 } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/controllers/use-projects-analytics-l1/index.tsx';
import { MENU_ID_PROJECTS_VIEW_ALL } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { ViewAllEntityMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/view-all-entity-menu-item/index.tsx';
import { projectsDirectoryRoute } from '@atlassian/jira-router-routes-directories-routes/src/projectsDirectoryRoute.tsx';
import { MenuList, MenuListItem, MenuSection } from '@atlassian/navigation-system';
import messages from './messages.tsx';

const VIEW_ALL_PROJECTS_HREF = projectsDirectoryRoute.path;

export function Footer() {
	const { formatMessage } = useIntl();
	const { sendViewAllProjectsAnalyticsEvent } = useProjectsAnalyticsL1();

	return (
		<MenuListItem>
			<MenuSection>
				<MenuList>
					<ViewAllEntityMenuItem
						onClick={() => {
							sendViewAllProjectsAnalyticsEvent();
						}}
						href={VIEW_ALL_PROJECTS_HREF}
						menuId={MENU_ID_PROJECTS_VIEW_ALL}
					>
						{formatMessage(messages.viewAllProjects)}
					</ViewAllEntityMenuItem>
				</MenuList>
			</MenuSection>
		</MenuListItem>
	);
}

import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ProjectMetadata } from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import type { ProjectsResponse } from './types.tsx';

export const getJpdProjects = async (searchQuery = ''): Promise<ProjectMetadata[]> => {
	const queryParams = new URLSearchParams({
		typeKey: PRODUCT_DISCOVERY_PROJECT,
		query: searchQuery,
	});

	const projects = await fetchJson<ProjectsResponse>(
		`/rest/api/3/project/search?${queryParams.toString()}`,
	);

	return projects.values.map(({ id, key, name, avatarUrls }) => ({
		id,
		key,
		name,
		avatarUrls,
	}));
};

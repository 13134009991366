import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	jsmAssetsDataManagerAdapters: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-assets.common.data-manager-adapter.jsm-assets-data-manager-adapters',
		defaultMessage: 'Adapters',
		description: 'Assets dropdown item to link customer to Data manager Adapters page.',
	},
	jsmAssetsOpenDataManager: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-assets.common.data-manager-adapter.jsm-assets-open-data-manager',
		defaultMessage: 'Open data manager',
		description: 'Open data manager icon label',
	},
});

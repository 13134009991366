import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { HorizontalOperationsConfiguration } from './ui/index.tsx';

export const LazyHorizontalOperationsConfigurationNav = lazyForPaint<
	typeof HorizontalOperationsConfiguration
>(() =>
	import(/* webpackChunkName: "jira-operations-configurations-horizontal-nav" */ './ui').then(
		({ HorizontalOperationsConfiguration }) => HorizontalOperationsConfiguration,
	),
);

export const AsyncHorizontalOperationsConfigurationNav = () => (
	<Placeholder name="jira-operations-configurations-horizontal-nav" fallback={null}>
		<JSErrorBoundary
			id="asyncHorizontalOperationsConfigurationNav"
			packageName="jiraOperationsConfigurationsHorizontalNav"
			fallback="flag"
			teamName="50cent"
		>
			<LazyHorizontalOperationsConfigurationNav />
		</JSErrorBoundary>
	</Placeholder>
);

import { GraphQLErrors } from '@atlassian/jira-business-graphql-errors/src/index.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import {
	BOARD_ISSUES_JQL,
	CREATED_THIS_WEEK_JQL,
	DONE_THIS_WEEK_JQL,
	DUE_THIS_WEEK_JQL,
	OPEN_ISSUES_JQL,
	PAGED_DATA_PAGE_SIZE,
	UPDATED_THIS_WEEK_JQL,
} from '../constants.tsx';
import { SUMMARY_PAGE_DATA_QUERY_NO_PRIORITY_JQL, SUMMARY_PAGE_DATA_QUERY } from './gql.tsx';
import type { Response, SummaryPageDataType } from './types.tsx';
import { transformData } from './utils.tsx';

const QUERY_NAME = 'SummaryPageData';

type Props = {
	cloudId: string;
	jqlContext: string;
	projectKey: string;
};

export const fetchSummaryPageData = async ({
	cloudId,
	jqlContext,
	projectKey,
}: Props): Promise<SummaryPageDataType> => {
	// We must use UNSAFE_noExposureExp to check if experiment is enabled without actually firing exposure events
	const [expInlineStatusConfig] = UNSAFE_noExposureExp('business_summary_page_inline_status');
	const isExperimentSummaryPageInlineStatusEnabled = expInlineStatusConfig.get(
		'isInlineStatusChangeEnabled',
		false,
	);

	const response = await performPostRequest<Response>(`/rest/gira/1/?operation=${QUERY_NAME}`, {
		body: JSON.stringify({
			query: fg('summary_page_query_priority_jql_term')
				? SUMMARY_PAGE_DATA_QUERY
				: SUMMARY_PAGE_DATA_QUERY_NO_PRIORITY_JQL,
			variables: {
				cloudId,
				jqlContext,
				projectKey,
				doneThisWeekJql: `${jqlContext} AND ${DONE_THIS_WEEK_JQL}`,
				updatedThisWeekJql: `${jqlContext} AND ${UPDATED_THIS_WEEK_JQL}`,
				createdThisWeekJql: `${jqlContext} AND ${CREATED_THIS_WEEK_JQL}`,
				dueThisWeekJql: `${jqlContext} AND ${DUE_THIS_WEEK_JQL}`,
				issuesByStatusJql: `${jqlContext} AND ${BOARD_ISSUES_JQL}`,
				issuesByPriorityJql: `${jqlContext} AND ${OPEN_ISSUES_JQL}`,
				issuesByAssigneeJql: `${jqlContext} AND ${OPEN_ISSUES_JQL}`,
				issuesByTypeJql: `${jqlContext} AND ${OPEN_ISSUES_JQL}`,
				pagedDataPageSize: PAGED_DATA_PAGE_SIZE,
				isWorkflowsCountEnabled: isExperimentSummaryPageInlineStatusEnabled,
			},
		}),
	});

	if (response.errors != null) {
		throw new GraphQLErrors(response.errors);
	}

	if (response.data == null) {
		throw new Error('GraphQL response has no data');
	}

	return transformData(response.data);
};

import React, {
	type ReactNode,
	type ReactElement,
	memo,
	useEffect,
	useState,
	useCallback,
} from 'react';
import type { ApolloError } from 'apollo-client';
import { getGraphqlErrorAttributes } from '@atlassian/jira-business-error-handling/src/utils/partial-data/index.tsx';
import { ErrorPage } from '@atlassian/jira-business-error-page/src/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { ExperienceAttributes } from '@atlassian/jira-experience-tracker/src/common/types.tsx';
import { useExperienceAbort as usePlatformExperienceAbort } from '@atlassian/jira-experience-tracker/src/ui/experience-abort/index.tsx';
import { useExperienceFail as usePlatformExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceStart as usePlatformExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import {
	useExperienceSuccess as usePlatformExperienceSuccess,
	ExperienceSuccess as PlatformExperienceSuccess,
} from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { ExperienceDetails } from '../../types.tsx';

export type Props = ExperienceDetails;
export type AutomaticTrackerProps = {
	shouldStartExperience?: boolean;
	loading: boolean;
	error?: Error | ApolloError;
};

export type Attributes = {
	readonly [key: string]: string | number | boolean | null;
};

export type ExperienceOnFail = ReturnType<typeof usePlatformExperienceFail>;
export type ExperienceOnAbort = ReturnType<typeof usePlatformExperienceAbort>;

export const useExperienceStart = ({ experience, experienceId, packageName }: ExperienceDetails) =>
	usePlatformExperienceStart({ experience, experienceId, analyticsSource: packageName });

export const useExperienceAbort = (
	{ experience }: ExperienceDetails,
	attributes?: ExperienceAttributes,
) => usePlatformExperienceAbort({ experience, attributes });

export const useExperienceSuccess = ({ experience }: ExperienceDetails) =>
	usePlatformExperienceSuccess({
		experience,
	});

export const useExperienceFail = ({
	experience,
	experienceId,
	packageName,
	teamName,
}: ExperienceDetails) => {
	const onExperienceFail = usePlatformExperienceFail({
		experience,
	});

	const onFail = useCallback(
		(
			location: string,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			error: (Error | ApolloError) & { networkError?: any },
			extraAttributes?: Attributes,
		) => {
			const relevantError = error?.networkError ?? error;

			if (
				relevantError instanceof ValidationError &&
				relevantError.message === '' &&
				relevantError.errors.length
			) {
				relevantError.message = relevantError.errors[0].error;
			}

			extraAttributes !== undefined
				? onExperienceFail(location, relevantError, extraAttributes)
				: onExperienceFail(location, relevantError);

			fireErrorAnalytics({
				meta: {
					id: `experience-tracker-${experienceId ?? experience}`,
					packageName,
					teamName,
				},
				attributes: {
					location,
				},
				error: relevantError,
				sendToPrivacyUnsafeSplunk: true,
			});
		},
		[onExperienceFail, experienceId, experience, packageName, teamName],
	);

	return onFail;
};

// tracks experience for views depending on the result of useQuery
export const useQueryBasedViewAutomaticTracking = (
	experience: Props,
	{ loading, error, shouldStartExperience }: AutomaticTrackerProps,
) => {
	const [hasStarted, setHasStarted] = useState(loading);
	const [hasCompleted, setHasCompleted] = useState(false);
	const [hasExperienceBeenTracked, setHasExperienceBeenTracked] = useState(false);

	const startExperience = useExperienceStart(experience);
	const onFail = useExperienceFail(experience);
	const onSuccess = useExperienceSuccess(experience);

	useEffect(() => {
		if (shouldStartExperience === true) {
			startExperience();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldStartExperience]);

	useEffect(() => {
		if (hasStarted === false && loading === true) {
			setHasStarted(true);
		}

		if (hasStarted === true && loading === false) {
			setHasCompleted(true);
			setHasExperienceBeenTracked(false);
		}
	}, [hasStarted, loading]);

	useEffect(() => {
		if (hasCompleted && !hasExperienceBeenTracked) {
			// make sure experience is tracked once despite re-rendering of the view
			setHasExperienceBeenTracked(true);

			if (typeof error !== 'undefined') {
				onFail(
					`${experience.experience} ${experience.experienceId || ''} useEffect error handling`,
					error,
					getGraphqlErrorAttributes(error),
				);
				return;
			}

			onSuccess({
				loaded: true,
			});
		}
	}, [error, experience, hasCompleted, hasExperienceBeenTracked, onFail, onSuccess]);
};

type ExperienceStartProps = {
	experience: ExperienceDetails;
};

export const ExperienceStart = memo<ExperienceStartProps>(
	({ experience }: ExperienceStartProps) => {
		const start = useExperienceStart(experience);

		useEffect(() => {
			start();
		}, [start]);

		return null;
	},
);

type ExperienceFailedProps = {
	experience: ExperienceDetails;
	error: Error | ApolloError;
	attributes?: ExperienceAttributes;
};

export const ExperienceFailed = memo<ExperienceFailedProps>(
	({ experience, error, attributes }: ExperienceFailedProps) => {
		const onFail = useExperienceFail(experience);

		useEffect(() => {
			onFail(experience.experience, error, attributes);
		}, [attributes, error, experience, onFail]);

		return null;
	},
);

type ErrorBoundaryProps = {
	shouldStartExperience?: boolean;
	experience: ExperienceDetails;
	children: ReactNode;
	fallback?: 'flag' | 'unmount' | ((props: { error: Error }) => ReactElement);
};

export const ExperienceErrorBoundary = ({
	experience,
	shouldStartExperience,
	fallback = ErrorPage,
	children,
}: ErrorBoundaryProps) => {
	const onFail = useExperienceFail(experience);

	return (
		<JSErrorBoundary
			packageName={experience.packageName}
			teamName={experience.teamName}
			id={experience.experience}
			onError={onFail}
			fallback={fallback}
		>
			{shouldStartExperience === true && <ExperienceStart experience={experience} />}
			{children}
		</JSErrorBoundary>
	);
};

type ExperienceSuccessProps = {
	experience: ExperienceDetails;
	attributes?: ExperienceAttributes;
};

export const ExperienceSuccess = memo<ExperienceSuccessProps>(
	({ experience, attributes }: ExperienceSuccessProps) => (
		<PlatformExperienceSuccess
			experience={experience.experience}
			attributes={{
				...(experience.experienceId == null
					? undefined
					: { experienceId: experience.experienceId }),
				...attributes,
			}}
		/>
	),
);

export const ExperienceAbort = memo<ExperienceFailedProps>(
	({ experience, error }: ExperienceFailedProps) => {
		const onAbort = useExperienceAbort(experience);

		useEffect(() => {
			onAbort(experience.experience, { error: error.message });
		}, [error, experience, onAbort]);

		return null;
	},
);

import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_REDIRECTS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import type ArchivedDirectLinkType from '@atlassian/jira-project-archived-direct-link/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { archivedProjectDirectLinkRoute } from '@atlassian/jira-router-routes-archived-project-direct-link-routes/src/archivedProjectDirectLinkRoute.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

const LazyArchivedDirectLink = lazyForPaint<typeof ArchivedDirectLinkType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-archived-direct-link", jiraSpaEntry: "async-project-archived-direct-link" */ '@atlassian/jira-project-archived-direct-link'
		),
	),
);

const ArchivedProjectDirectLink = () => (
	<LazyPage
		Page={LazyArchivedDirectLink}
		pageId="archived-project-direct-link"
		shouldShowSpinner={false}
	/>
);

export const archivedProjectDirectLinkRouteEntry = createEntry(
	archivedProjectDirectLinkRoute,
	() => ({
		group: ROUTE_GROUPS_REDIRECTS,
		component: ArchivedProjectDirectLink,
		layout: globalLayout,
		resources: [...getNavigationResources()],
		forPaint: [LazyAtlassianNavigation, LazyArchivedDirectLink],
		ufoName: 'project-archived',
	}),
);

import React from 'react';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { ROUTE_GROUPS_ERRORS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type LoginEmbedType from '@atlassian/jira-error-pages/src/ui/login-embed-error-page/LoginEmbedErrorPage.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { loginEmbedRoute } from '@atlassian/jira-router-routes-login-embed-routes/src/loginEmbedRoute.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

const LazyLoginEmbed = lazyForPaint<typeof LoginEmbedType>(
	() =>
		import(
			/* webpackChunkName: "async-login-embed", jiraSpaEntry: "async-login-embed" */ '@atlassian/jira-spa-apps-login-embed'
		),
);

const LoginEmbed = () => (
	<LazyPage Page={LazyLoginEmbed} pageId="spa-apps/login-embed" shouldShowSpinner />
);

export const loginEmbedRouteEntry = createEntry(loginEmbedRoute, () => ({
	group: ROUTE_GROUPS_ERRORS,
	component: LoginEmbed,
	layout: chromelessLayout,
}));

import type { ComponentType } from 'react';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	composeLayouts,
	withProjectContext,
	type Layout,
} from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import IntentTemplatesSidebar from './ui/right-sidebars/index.tsx';

// TODO Remove this whenever all service project route paths are no longer ambiguous
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withServiceProjectContext = (Component: ComponentType<Record<any, any>>) =>
	withProjectContext(Component, {
		project: {
			style: 'any',
			type: SERVICE_DESK_PROJECT,
		},
	});

export const browseIntentsLayout: Layout = composeLayouts(serviceProjectLayout, {
	rightSidebars: [withServiceProjectContext(IntentTemplatesSidebar)],
});

import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { calendarEntryPoint } from '@atlassian/jira-spa-apps-business-calendar-new/entrypoint.tsx';
import { calendarEntrypoint as legacyCalendarEntrypoint } from '@atlassian/jira-spa-apps-business-calendar/entrypoint.tsx';

export const calendarPageEntrypoint = createPageEntryPoint({
	main: calendarEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const legacyCalendarPageEntrypoint = createPageEntryPoint({
	main: legacyCalendarEntrypoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

import React, { type ReactNode, useRef, useState, useLayoutEffect } from 'react';
import { styled } from '@compiled/react';

type CollapsibleWrapperProps = {
	animationDuration: number;
	containerHeight: number | null;
};

type Props = {
	animationDuration?: number;
	children: ReactNode;
	id?: string;
	isExpanded: boolean;
};

export const Collapsible = ({ children, id, isExpanded, animationDuration = 250 }: Props) => {
	const didMountRef = useRef(false);
	const [containerHeight, setContainerHeight] = useState(isExpanded ? null : 0);
	const [isRendered, setIsRendered] = useState(isExpanded);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		if (!didMountRef.current) {
			didMountRef.current = true;
			return;
		}
		if (isExpanded) {
			setIsRendered(true);
			requestAnimationFrame(() => {
				setContainerHeight(contentRef.current?.offsetHeight ?? null);

				Promise.all(
					wrapperRef.current?.getAnimations().map((animation) => animation.finished) ?? [],
				).then(() => {
					setContainerHeight(null);
				});
			});
		} else {
			setContainerHeight(contentRef.current?.offsetHeight ?? null);
			requestAnimationFrame(() => {
				setContainerHeight(0);

				Promise.all(
					wrapperRef.current?.getAnimations().map((animation) => animation.finished) ?? [],
				).then(() => {
					setIsRendered(false);
				});
			});
		}
	}, [contentRef, isExpanded]);

	if (!isRendered) {
		return null;
	}

	return (
		<CollapsibleWrapper
			animationDuration={animationDuration}
			containerHeight={containerHeight}
			id={id}
			ref={wrapperRef}
		>
			<div ref={contentRef}>{children}</div>
		</CollapsibleWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const CollapsibleWrapper = styled.div<CollapsibleWrapperProps>({
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transition: ({ animationDuration }) => `max-height ${animationDuration}ms ease-in-out`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxHeight: ({ containerHeight }) => (containerHeight != null ? `${containerHeight}px;` : 'unset'),
});

import { setTitle } from './set-title/index.tsx';
import { addDocumentTitle } from './add-document-title/index.tsx';
import { removeDocumentTitle } from './remove-document-title/index.tsx';
import { updateTitle } from './update-title/index.tsx';

export const actions = {
	setTitle,
	addDocumentTitle,
	removeDocumentTitle,
	updateTitle,
};

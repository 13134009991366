import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	removeField: {
		id: 'jql-builder-basic.common.picker-trigger.remove-field',
		defaultMessage: 'Remove field',
		description:
			'Aria label for button that removes a specific field from search criteria in new issue navigator refinement bar',
	},
	deprecatedEpicLinkMessage: {
		id: 'jql-builder-basic.common.picker-trigger.deprecated-epic-link-message',
		defaultMessage: '<strong>Epic Link</strong> is replaced by <strong>Parent</strong>.',
		description: 'Warning message for Epic Link deprecation, with link to docs',
	},
});

export default messages;

import {
	CUSTOM_ITEM,
	CUSTOM_ITEM_ACTION,
	CUSTOM_ITEM_BUTTON,
	FIXED_ITEM,
	FIXED_ITEM_ACTION,
	FIXED_ITEM_BUTTON,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { MEATBALL_DROPDOWN } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-id.tsx';
import {
	DEFAULT_FILTER,
	FILTER,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-type.tsx';
import {
	LEVEL_FOUR,
	LEVEL_ONE,
	LEVEL_THREE,
	LEVEL_TWO,
	type Level,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import {
	SECTION_ITEM_RECENT,
	SECTION_ITEM_STARRED,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import {
	ENTITY_ID,
	MENU_ID_FILTERS,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';

type Attributes = {
	itemId: string | null;
	section?: string;
	parentItemType?: string;
	parentItemId?: string | null;
	level: Level;
	componentType: string;
	itemType?: string;
};

export const useFilterActionsAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireFilterActionAnalytics = (actionSubject: string, attributes: Attributes) => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject,
		});
		fireUIAnalytics(analyticsEvent, MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_FILTERS], attributes);
	};
	return fireFilterActionAnalytics;
};

const systemFilterNameMap = [
	{ name: 'myOpenIssues', id: '-1' },
	{ name: 'reportedByMe', id: '-2' },
	{ name: 'allIssues', id: '-4' },
	{ name: 'openIssues', id: '-5' },
	{ name: 'doneIssues', id: '-9' },
	{ name: 'viewedRecently', id: '-3' },
	{ name: 'resolvedRecently', id: '-7' },
	{ name: 'updatedRecently', id: '-8' },
	{ name: 'createdRecently', id: '-6' },
];

export const mapSystemFilterIdToName = (id: string | undefined) => {
	const systemFilter = systemFilterNameMap.find((filter) => filter.id === id);
	return systemFilter?.name || '';
};

const getSection = (isCustom: Boolean, isFavourite: boolean) => {
	let section;

	if (!isCustom && !isFavourite) {
		section = 'defaultFilters';
	} else if (isFavourite) {
		section = SECTION_ITEM_STARRED;
	} else {
		section = SECTION_ITEM_RECENT;
	}

	return section;
};

const getFilterId = (isCustom: boolean, filterId: string | undefined) => {
	if (!filterId) {
		return null;
	}
	return ENTITY_ID.FILTER(isCustom ? filterId : mapSystemFilterIdToName(filterId));
};

export const getFilterActionAttributes = ({
	itemId,
	isCustom,
	isFavourite,
	filterId,
}: {
	itemId: string | null;
	isCustom: boolean;
	isFavourite: boolean | null | undefined;
	filterId: string;
}): Attributes => {
	const section = getSection(isCustom, Boolean(isFavourite));
	return {
		itemId,
		section,
		level: section === 'defaultFilters' ? LEVEL_FOUR : LEVEL_THREE,
		componentType: isCustom ? CUSTOM_ITEM_ACTION : FIXED_ITEM_ACTION,
		parentItemType: isCustom ? FILTER : DEFAULT_FILTER,
		parentItemId: getFilterId(isCustom, filterId),
	};
};

export const getFilterMeatBallAttributes = ({
	isCustom,
	isFavourite,
	filterId,
}: {
	isCustom: boolean;
	isFavourite: boolean | null | undefined;
	filterId: string;
}): Attributes => {
	const section = getSection(isCustom, Boolean(isFavourite));
	return {
		section,
		level: section === 'defaultFilters' ? LEVEL_THREE : LEVEL_TWO,
		componentType: isCustom ? CUSTOM_ITEM_BUTTON : FIXED_ITEM_BUTTON,
		itemId: MEATBALL_DROPDOWN,
		parentItemType: isCustom ? FILTER : DEFAULT_FILTER,
		parentItemId: getFilterId(isCustom, filterId),
	};
};

export const getRecentAndFavouriteFilterAttributes = ({
	isCustom,
	filterId,
	section,
}: {
	isCustom: boolean;
	filterId: string | undefined;
	section: string;
}): Attributes => {
	return {
		level: LEVEL_ONE,
		section,
		componentType: isCustom ? CUSTOM_ITEM : FIXED_ITEM,
		itemId: getFilterId(isCustom, filterId),
		itemType: isCustom ? FILTER : DEFAULT_FILTER,
	};
};

export const getDefaulFilterAttributes = ({
	filterId,
}: {
	filterId: string | undefined;
}): Attributes => {
	return {
		level: LEVEL_TWO,
		itemId: getFilterId(false, filterId),
		section: 'defaultFilters',
		componentType: FIXED_ITEM,
		itemType: DEFAULT_FILTER,
	};
};

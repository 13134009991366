import { issueTypesResource } from '@atlassian/jira-admin-pages-issue-types-resources/src/controllers/issue-types/utils.tsx';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_GLOBAL_SETTINGS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalSettingsIssueTypesRoute } from '@atlassian/jira-router-routes-admin-pages-issue-types-routes/src/globalSettingsIssueTypesRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import IssueTypesDirectory, {
	LazyIssueTypesDirectory,
} from './ui/spa/admin-pages/issue-types/issue-types-directory/index.tsx';

export const globalSettingsIssueTypesRouteEntry: Route = createEntry(
	globalSettingsIssueTypesRoute,
	() => ({
		group: ROUTE_GROUPS_GLOBAL_SETTINGS,
		ufoName: 'global-admin.issues.issue-types',
		component: IssueTypesDirectory,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},
		resources: [...getNavigationResources(), navigationSidebarGlobalResource, issueTypesResource],
		forPaint: [LazyAtlassianNavigation, LazyIssueTypesDirectory],
	}),
);

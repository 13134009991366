import type { Jast, OrderByDirectionValue } from '@atlaskit/jql-ast';

export type AstSortField = { jqlTerm: string; direction: OrderByDirectionValue | undefined };

export const getAstSortFields = (ast: Jast): AstSortField[] => {
	if (ast && ast.query && ast.query.orderBy) {
		return ast.query.orderBy.fields.map((orderByFieldFromAst) => ({
			jqlTerm: orderByFieldFromAst.field.value,
			direction: orderByFieldFromAst.direction ? orderByFieldFromAst.direction.value : undefined,
		}));
	}
	return [];
};

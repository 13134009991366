import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskNavigationResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskReports1Route } from '@atlassian/jira-router-routes-servicedesk-reports-routes/src/servicedeskReports1Route.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { Redirect, type RouteContext, type Route } from '@atlassian/react-resource-router';
import { WORKLOAD_ROUTE_SUFFIX } from './common/constants.tsx';
import { getReportsWithKbResources } from './common/getReportsWithKbResources.tsx';
import HomePage, { LazyServicedeskReportsHomePage } from './ui/home-page/index.tsx';

const DefaultReportsRedirect = ({ match }: RouteContext) => (
	<Redirect
		to={`/jira/servicedesk/projects/${String(match.params.projectKey)}${WORKLOAD_ROUTE_SUFFIX}`}
	/>
);

export const servicedeskReports1RouteEntry: Route = createEntry(servicedeskReports1Route, () => ({
	group: ROUTE_GROUPS_SERVICEDESK,
	component: componentWithCondition(getWillShowNav4, HomePage, DefaultReportsRedirect),
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},
	resources: [
		...getServiceDeskNavigationResources(),
		...getReportsWithKbResources(),
		getConsolidationStateResource(),
	],
	forPaint: [LazyAtlassianNavigation, LazyServicedeskReportsHomePage],
}));

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		id: 'navigation-apps-sidebar-nav4-customise-sidebar.header',
		defaultMessage: 'Customize sidebar',
		description: 'Message for header of customise sidebar modal',
	},
	reorder: {
		id: 'navigation-apps-sidebar-nav4-sidebar-content.configurable-menu.customise-sidebar.reorder',
		defaultMessage: 'Reorder {itemLabel}',
		description: 'Accessible label for the item reordering button in the Customise Sidebar dialog',
	},
	separator: {
		id: 'navigation-apps-sidebar-nav4-sidebar-content.configurable-menu.customise-sidebar.separator',
		defaultMessage: 'Separator',
		description: 'Message for separator of nav menu items in the Customise Sidebar dialog',
	},
});

export const CttSpotlights = {
	Inactive: 'INACTIVE',
	CreateIssue: 'portfolio-3/ctt-onboarding/create-issue',
	TitleInput: 'portfolio-3/ctt-onboarding/title-input',
	Settings: 'portfolio-3/ctt-onboarding/settings',
	IssueSources: 'portfolio-3/ctt-onboarding/issue-source',
	BackToPlan: 'portfolio-3/ctt-onboarding/back-to-plan',
	EpicSection: 'portfolio-3/ctt-onboarding/epic-section',
	Scope: 'portfolio-3/ctt-onboarding/scope',
	ReviewChanges: 'portfolio-3/ctt-onboarding/review-changes',
} as const;
export type CttSpotlights = (typeof CttSpotlights)[keyof typeof CttSpotlights];

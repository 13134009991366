import parameters from '@atlassian/jira-relay/src/__generated__/Nav4AppsContentViewQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = { cloudId: string };

export const asyncNav4AppsContentViewQuery = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-nav4-apps-content-view-query" */ './Nav4AppsContentViewQuery'
		).then(({ Nav4AppsContentViewQuery }) => Nav4AppsContentViewQuery),
	),
	getPreloadProps: ({ cloudId }: EntryPointParams) => ({
		queries: {
			apps: {
				parameters,
				variables: { cloudId },
			},
		},
	}),
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	selectPlaceholder: {
		id: 'software-board-create.modal.form.source-type-picker.select-placeholder',
		defaultMessage: 'Select',
		description: 'Placeholder for the source type selector',
	},
	sourceTypeFieldLabel: {
		id: 'software-board-create.modal.form.source-type-picker.source-type-field-label',
		defaultMessage: 'Choose what to include in this board',
		description:
			'Label for the field which asks the user to select sources for the new board they are creating',
	},
	teamTitle: {
		id: 'software-board-create.modal.form.source-type-picker.team-title',
		defaultMessage: 'Team',
		description: 'Label for the team source type dropdown option',
	},
	teamSelectedTitle: {
		id: 'software-board-create.modal.form.source-type-picker.team-selected-title',
		defaultMessage: 'Issues based on <strong>Team</strong>',
		description: 'Label for when team is selected as the source type of the new board',
	},
	teamDescription: {
		id: 'software-board-create.modal.form.source-type-picker.team-description',
		defaultMessage: 'Show all issues assigned to a team',
		description: 'Description for the team source type dropdown option',
	},
	issueTypesTitle: {
		id: 'software-board-create.modal.form.source-type-picker.issue-types-title',
		defaultMessage: 'Issue type',
		description: 'Label for the issue types source type option',
	},
	issueTypesSelectedTitle: {
		id: 'software-board-create.modal.form.source-type-picker.issue-types-selected-title',
		defaultMessage: 'Issues based on <strong>Issue type</strong>',
		description: 'Label for when issue type is selected as the source type of the new board',
	},
	issueTypesDescription: {
		id: 'software-board-create.modal.form.source-type-picker.issue-types-description',
		defaultMessage: 'Show all issues associated with an issue type',
		description: 'Description for the issue types source type option',
	},
	labelsTitle: {
		id: 'software-board-create.modal.form.source-type-picker.labels-title',
		defaultMessage: 'Label',
		description: 'Label for the labels source type option',
	},
	labelsSelectedTitle: {
		id: 'software-board-create.modal.form.source-type-picker.labels-selected-title',
		defaultMessage: 'Issues based on <strong>Label</strong>',
		description: 'Label for when labels is selected as the source type of the new board',
	},
	labelsDescription: {
		id: 'software-board-create.modal.form.source-type-picker.labels-description',
		defaultMessage: 'Show all issues tagged with a specific label',
		description: 'Description for the labels source type option',
	},
	allIssuesFromProjectTitle: {
		id: 'software-board-create.modal.form.source-type-picker.all-issues-from-project-title',
		defaultMessage: 'All issues from your project',
		description:
			'Text shown in the closed dropdown when the all issues from your project source type option is selected',
	},
	allIssuesFromProjectSelectedTitle: {
		id: 'software-board-create.modal.form.source-type-picker.all-issues-from-project-selected-title',
		defaultMessage: 'Issues based on <strong>All issues from your project</strong>',
		description:
			'Label for when "All issues in project" is selected as the source type of the new board',
	},
	savedFilterTitle: {
		id: 'software-board-create.modal.form.source-type-picker.saved-filter-title',
		defaultMessage: 'Advanced',
		description: 'Label for the saved filters source type dropdown option',
	},
	savedFilterSelectedTitle: {
		id: 'software-board-create.modal.form.source-type-picker.saved-filter-selected-title',
		defaultMessage: 'Issues based on a <strong>Saved filter</strong>',
		description: 'Label for when issue type is selected as the source type of the new board',
	},
	savedFilterDescription: {
		id: 'software-board-create.modal.form.source-type-picker.saved-filter-description',
		defaultMessage: 'Show issues from a saved JQL filter',
		description: 'Description for the saved filters source type dropdown option',
	},
	sourceTypePickerAriaLabel: {
		id: 'software-board-create.modal.form.source-type-picker.source-type-picker-aria-label',
		defaultMessage: 'Select what to include in this board',
		description: 'Label for the source type selector',
	},
	sourceTypeEmptyError: {
		id: 'software-board-create.modal.form.source-type-picker.source-type-empty-error',
		defaultMessage: 'Select what to include in this board',
		description:
			'Error message for board source type telling the user that board source is required to create a board',
	},
});

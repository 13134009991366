import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { businessProjectLayout } from '@atlassian/jira-business-project-layout/src/index.tsx';
import { ROUTE_GROUPS_CLASSIC_BUSINESS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async.tsx';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { classicBusinessProjectCalendarRoute } from '@atlassian/jira-router-routes-business-routes/src/classicBusinessProjectCalendarRoute.tsx';
import {
	calendarPageEntrypoint,
	legacyCalendarPageEntrypoint,
} from './ui/spa/business/calendar/index.tsx';

export const classicBusinessProjectCalendarRouteEntry = createEntry(
	classicBusinessProjectCalendarRoute,
	() => ({
		group: ROUTE_GROUPS_CLASSIC_BUSINESS,
		ufoName: 'jwm.calendar-view',

		entryPoint() {
			return expValEquals('business_projects_shared_calendar', 'cohort', 'variation')
				? calendarPageEntrypoint
				: legacyCalendarPageEntrypoint;
		},

		layout: businessProjectLayout,
		skeleton: ThemedLazySkeleton,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			// delete when cleaning up getWillShowNav4
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalNavJira,
		},

		resources: getProjectViewResources(),
	}),
);

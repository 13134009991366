/**
 * @generated SignedSource<<bf10716a4c00972068007e73ef5b1c3f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4Apps$data = {
  readonly jira: {
    readonly " $fragmentSpreads": FragmentRefs<"Nav4AppsForLanding" | "Nav4Apps_GlobalAppsFragment">;
  };
  readonly " $fragmentType": "Nav4Apps";
};
export type Nav4Apps$key = {
  readonly " $data"?: Nav4Apps$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Apps">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isAppsExpanded"
    }
  ],
  "kind": "Fragment",
  "name": "Nav4Apps",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "condition": "isAppsExpanded",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "Nav4AppsForLanding"
              }
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "Nav4Apps_GlobalAppsFragment"
          }
        ]
      },
      "action": "THROW",
      "path": "jira"
    }
  ],
  "type": "Query"
};

(node as any).hash = "c3df504da3663fd99ebe67a0d32de67f";

export default node;

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	byokComplianceSettingEnabledSuccessTitle: {
		id: 'products-jsm-compliance-main.services.byok-compliance-setting.byok-compliance-setting-enabled-success-title',
		defaultMessage: 'HIPAA-compliant alert notifications turned on',
		description: 'Description for the flag title when enabling safe notifications',
	},
	byokComplianceSettingEnabledSuccessDescription: {
		id: 'products-jsm-compliance-main.services.byok-compliance-setting.byok-compliance-setting-enabled-success-description',
		defaultMessage: 'Let your team admins know about the recommended actions they can take.',
		description: 'Description for the flag description when enabling safe notifications',
	},
	flagOk: {
		id: 'products-jsm-compliance-main.services.byok-compliance-setting.flag-ok',
		defaultMessage: 'Ok',
		description: 'The action to confirm the information',
	},
	flagLearnMore: {
		id: 'products-jsm-compliance-main.services.byok-compliance-setting.flag-learn-more',
		defaultMessage: 'Learn more',
		description: 'The action to learn more in the success flag',
	},
});

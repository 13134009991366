import { _haveValuesArrived as testTenantContext } from '@atlassian/jira-common-util-get-tenant-context/src/index.tsx';
import { _haveValuesArrived as testLaunchDarkly } from '@atlassian/jira-feature-flagging-using-meta/src/utils/get-all-feature-flags/index.tsx';
import { _haveValuesArrived as testStatsig } from '@atlassian/jira-feature-gates-unsafe-init/src/utils.tsx';

const POLLING_INTERVAL_MS = 30;

async function testForThingWithTimeout<T>(testFn: () => T | null, timeoutMs = 5000): Promise<T> {
	const startTime = Date.now();
	let result;
	return new Promise((resolve, reject) => {
		result = testFn();
		if (result) {
			return resolve(result);
		}

		const interval = setInterval(() => {
			if (Date.now() - startTime >= timeoutMs) {
				clearInterval(interval);
				return reject();
			}

			result = testFn();
			if (result) {
				clearInterval(interval);
				resolve(result);
			}
		}, POLLING_INTERVAL_MS);
	});
}

export class SpaBootstrapDependencies {
	#windowObject: Window;

	#timeoutMs: number;

	constructor(
		private readonly window: Window,
		private readonly timeoutMs = 5000,
	) {
		this.#windowObject = window;
		this.#timeoutMs = timeoutMs;
	}

	domElement(): Promise<HTMLElement> {
		return testForThingWithTimeout(
			() => this.#windowObject.document.getElementById('jira-frontend'),
			this.#timeoutMs,
		).catch(() => {
			throw Error('Could not locate jira-frontend element');
		});
	}

	spaState(): Promise<Record<string, unknown>> {
		return testForThingWithTimeout(() => this.#windowObject.SPA_STATE, this.#timeoutMs).catch(
			() => {
				throw Error('Could not locate SPA_STATE');
			},
		);
	}

	statsigValues(): Promise<boolean> {
		return testForThingWithTimeout(testStatsig, this.#timeoutMs).catch(() => {
			throw Error('Could not locate Statsig values');
		});
	}

	launchDarklyValues(): Promise<boolean> {
		return testForThingWithTimeout(testLaunchDarkly, this.#timeoutMs).catch(() => {
			throw Error('Could not locate LaunchDarkly values');
		});
	}

	/** @deprecated using tenant context and meta tags is a bad data access pattern that we want to see disappear. */
	tenantContext(): Promise<boolean> {
		return testForThingWithTimeout(testTenantContext, this.#timeoutMs).catch(() => {
			throw Error('Could not locate tenant context values');
		});
	}
}

import React, { useCallback, type FC } from 'react';
import { type ContentProps, Popup, type TriggerProps } from '@atlaskit/popup';
import type { OnClose } from '../../common/types.tsx';
import { useOpenable } from '../../common/utils/use-openable/index.tsx';
import { MoreNavMenuButtonSubmenu } from './submenu/index.tsx';
import { MoreNavMenuButtonTrigger } from './trigger/main.tsx';
import type { MoreNavMenuButtonProps } from './types.tsx';
/*
 * An icon only button to be used on the right side of nav menu items.
 * Opens a dropdown menu with additional options.
 */
export const MoreNavMenuButton: FC<MoreNavMenuButtonProps> = ({
	MenuItems,
	name,
	onOpen,
	onClose: onCloseFlyout,
}) => {
	const { isOpen, onClose: onCloseDropdown, onToggle } = useOpenable();
	const content = (contentProps: ContentProps) => (
		<MoreNavMenuButtonSubmenu {...contentProps} MenuItems={MenuItems} />
	);

	const onClose = useCallback<OnClose>(
		(e) => {
			try {
				onCloseFlyout?.(e);
			} finally {
				onCloseDropdown();
			}
		},
		[onCloseFlyout, onCloseDropdown],
	);

	const onTriggerClick = useCallback(() => {
		try {
			if (!isOpen) {
				onOpen?.();
			}
		} finally {
			onToggle();
		}
	}, [isOpen, onOpen, onToggle]);

	const trigger = (triggerProps: TriggerProps) => (
		<MoreNavMenuButtonTrigger {...triggerProps} name={name} onClick={onTriggerClick} />
	);

	return (
		<Popup
			content={content}
			isOpen={isOpen}
			onClose={onClose}
			placement="bottom-start"
			trigger={trigger}
			shouldRenderToParent
		/>
	);
};

/**
 * @generated SignedSource<<43a8cee8bfdf430e242ac6d6e2f5e807>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4AppsForLanding$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4AppsContent">;
  readonly " $fragmentType": "Nav4AppsForLanding";
};
export type Nav4AppsForLanding$key = {
  readonly " $data"?: Nav4AppsForLanding$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4AppsForLanding">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "Nav4AppsForLanding",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "Nav4AppsContent"
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "9c28cb4a0c92a920a58010f91f4f4f0e";

export default node;

/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import Button, { type ButtonProps } from '@atlaskit/button';
import { ARTICLE_TYPE } from '@atlaskit/help';
import Link from '@atlaskit/link';
import { fg } from '@atlassian/jira-feature-gating';
import {
	HELP_PANEL_ID,
	HELP_PANEL_WIDTH,
} from '@atlassian/jira-help-panel-common-constant/src/index.tsx';
import { useHelpPanelActions } from '@atlassian/jira-help-panel-services/src/components/index.tsx';
import { useRightSidebarController } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller/index.tsx';

export interface HelpPanelButtonProps extends Omit<ButtonProps, 'type'> {
	articleId?: string;
	type?: keyof typeof ARTICLE_TYPE;
	children?: ReactNode;
	/**
	 * @deprecated No longer used. You can safely delete this prop.
	 */
	fallbackComponent?: ReactNode;
	/** href added to underlying link, note: articleId is used to open the help panel article, this link is for copying, Cmd+clicking, etc */
	href?: string;
}

export const useHelpPanelLink = (
	resetHistory = false,
): ((id: string, type?: keyof typeof ARTICLE_TYPE) => void) => {
	const { openRightSidebar } = useRightSidebarController();
	const [, { setNavigationData }] = useHelpPanelActions();

	const handleOnClick = (id: string, type: keyof typeof ARTICLE_TYPE = 'HELP_ARTICLE') => {
		openRightSidebar(HELP_PANEL_ID, HELP_PANEL_WIDTH, false, true);

		if (resetHistory) {
			setNavigationData({
				articleId: {
					id,
					type: ARTICLE_TYPE[type],
				},
				history: [],
			});
		} else {
			setNavigationData({
				articleId: {
					id,
					type: ARTICLE_TYPE[type],
				},
			});
		}
	};
	return handleOnClick;
};

const HelpPanelLink = ({
	articleId = '',
	type = 'HELP_ARTICLE',
	children,
	onClick,
	href,
	fallbackComponent, // unused, but needs to be here so it's not passed to Link/Button in the ...props splat
	...props
}: HelpPanelButtonProps) => {
	const onOpenInProductHelpArticle = useHelpPanelLink();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleOnClick = (event: any, analyticsEvent: any) => {
		if (fg('help_panel_as_link')) {
			event.preventDefault();
		}
		onClick?.(event, analyticsEvent);
		onOpenInProductHelpArticle(articleId, ARTICLE_TYPE[type]);
	};

	if (fg('help_panel_as_link')) {
		return (
			<Link
				{...props}
				onClick={handleOnClick}
				href={href ?? 'https://support.atlassian.com'}
				appearance="default"
				testId={props.testId}
			>
				{children}
			</Link>
		);
	}

	return (
		<Button
			{...props}
			onClick={handleOnClick}
			// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
			css={props.appearance === 'link' && buttonWrapperStyles}
		>
			{children}
		</Button>
	);
};
export default HelpPanelLink;
const buttonWrapperStyles = css({
	textDecoration: 'underline',
	'&:hover': {
		textDecoration: 'none',
	},
});

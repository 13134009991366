import { defineMessages } from 'react-intl-next';

const messages = defineMessages({
	/*
   Misc common messages
  */

	noOne: {
		id: 'eoc-schedule.common.no-one',
		defaultMessage: 'No one',
		description: 'Name of the user whose type is no-one.',
	},
	error: {
		id: 'eoc-schedule.common.error',
		defaultMessage: 'Error',
		description: 'Just simple error message.',
	},
	tryAgain: {
		id: 'eoc-schedule.common.try-again',
		defaultMessage: 'Try again',
		description: 'Try again message when an error occurs.',
	},
	cancel: {
		id: 'eoc-schedule.common.cancel',
		defaultMessage: 'Cancel',
		description: 'A cancel button.',
	},
	confirm: {
		id: 'eoc-schedule.common.confirm',
		defaultMessage: 'Confirm',
		description: 'A confirm button.',
	},
	addOverride: {
		id: 'eoc-schedule.common.add-override',
		defaultMessage: 'Add override',
		description: 'Adding override for a period item.',
	},
	overrideButton: {
		id: 'eoc-schedule.common.override',
		defaultMessage: 'Override',
		description: 'A override button.',
	},
	saveButton: {
		id: 'eoc-schedule.common.save',
		defaultMessage: 'Save',
		description: 'A save button.',
	},
	edit: {
		id: 'eoc-schedule.common.edit',
		defaultMessage: 'Edit',
		description: 'Button for editing schedule.',
	},
	turnOff: {
		id: 'eoc-schedule.common.turn-off',
		defaultMessage: 'Turn off',
		description: 'Button for turn-offing schedule.',
	},
	turnOn: {
		id: 'eoc-schedule.common.turn-on',
		defaultMessage: 'Turn on',
		description: 'Button for turn-on schedule.',
	},
	delete: {
		id: 'eoc-schedule.common.delete',
		defaultMessage: 'Delete',
		description: 'Button for deleting schedule or rotation.',
	},
	duplicate: {
		id: 'eoc-schedule.common.duplicate',
		defaultMessage: 'Duplicate',
		description: 'Button for duplicating schedule or rotation.',
	},
	remove: {
		id: 'eoc-schedule.common.remove',
		defaultMessage: 'Remove',
		description: 'Button for removing participant.',
	},
	start: {
		id: 'eoc-schedule.common.start',
		defaultMessage: 'Start',
		description: 'Label for start time and date.',
	},
	end: {
		id: 'eoc-schedule.common.end',
		defaultMessage: 'End',
		description: 'Label for end time and date.',
	},
	startTime: {
		id: 'eoc-schedule.common.start-time',
		defaultMessage: 'Start time',
		description: 'Label for start time and date.',
	},
	endTime: {
		id: 'eoc-schedule.common.end-time',
		defaultMessage: 'End time',
		description: 'Label for end time and date.',
	},
	startDate: {
		id: 'eoc-schedule.common.start-date',
		defaultMessage: 'Start date',
		description: 'Label for start time and date.',
	},
	endDate: {
		id: 'eoc-schedule.common.end-date',
		defaultMessage: 'End date',
		description: 'Label for end time and date.',
	},
	team: {
		id: 'eoc-schedule.common.team',
		defaultMessage: 'Team',
		description: 'Label that indicates responder is of type: Team',
	},
	escalation: {
		id: 'eoc-schedule.common.escalation',
		defaultMessage: 'Escalation',
		description: 'Label that indicates responder is of type: Escalation',
	},
	schedule: {
		id: 'eoc-schedule.common.schedule',
		defaultMessage: 'Schedule',
		description: 'Label that indicates responder is of type: Schedule',
	},
	selectionCount: {
		id: 'eoc-schedule.common.selection-count',
		defaultMessage: '{count} shifts selected',
		description: 'Indicates how many shifts are selected for override.',
	},
	quickOverrideTitle: {
		id: 'eoc-schedule.common.quick-override-title',
		defaultMessage: 'Override',
		description: 'Title for quick override card.',
	},
	search: {
		id: 'eoc-schedule.common.search',
		defaultMessage: 'Search',
		description: 'Label of search icon that shown inside search participant field',
	},
	replaceWith: {
		id: 'eoc-schedule.common.replace-with',
		defaultMessage: 'Replace with',
		description: 'A button for opening popup for replacing a participant.',
	},
	participants: {
		id: 'eoc-schedule.common.participants',
		defaultMessage: 'Participants',
		description: 'Label for participants.',
	},
	addParticipants: {
		id: 'eoc-schedule.common.add-participants',
		defaultMessage: 'Add people, teams, and escalation policies',
		description: 'Placeholder text for auto complete field for participants (responders).',
	},
	select: {
		id: 'eoc-schedule.common.select',
		defaultMessage: 'Select',
		description: 'Label that indicates selecting.',
	},
	teamDeleted: {
		id: 'eoc-schedule.common.team-deleted',
		defaultMessage: 'Team deleted',
		description: 'Fallback responder name that suggest team is deleted.',
	},
	deletedSchedule: {
		id: 'eoc-schedule.common.deleted-schedule',
		defaultMessage: 'Deleted schedule',
		description: 'Fallback responder name that suggest schedule is deleted.',
	},
	deletedEscalation: {
		id: 'eoc-schedule.common.deleted-escalation',
		defaultMessage: 'Deleted escalation',
		description: 'Fallback responder name that suggest escalation is deleted.',
	},
	formerUser: {
		id: 'eoc-schedule.common.former-user',
		defaultMessage: 'Former user',
		description: 'Fallback responder name that suggest user is deactivated or deleted.',
	},
	discard: {
		id: 'eoc-schedule.common.discard',
		defaultMessage: 'Discard',
		description: 'Button for discarding.',
	},
	confirmProceed: {
		id: 'eoc-schedule.common.confirm-proceed',
		defaultMessage: 'Are you sure to proceed?',
		description: 'A confirmation for proceeding.',
	},

	/*
   Common validation related messages
  */

	required: {
		id: 'eoc-schedule.common.required',
		defaultMessage: 'This field is required.',
		description: 'A text that informs users that field should be filled.',
	},
	noSpaceAtEndOrStart: {
		id: 'eoc-schedule.common.no-space-at-end-or-start',
		defaultMessage: 'Field cannot contain spaces at start or end.',
		description: 'A text that informs users that field cannot start or end with spaces.',
	},
	maxLength: {
		id: 'eoc-schedule.common.max-length',
		defaultMessage: 'Field cannot be longer than {max} characters.',
		description: 'A text that informs users that field should be shorter than certain length.',
	},
	maxValue: {
		id: 'eoc-schedule.common.max-value',
		defaultMessage: 'Field should be less than {max}.',
		description: 'A text that informs users that field should be less than certain value.',
	},
	minValue: {
		id: 'eoc-schedule.common.min-value',
		defaultMessage: 'Enter digit 1 or more.',
		description:
			'This sentence instructs the user to input a numeric value of at least 1 digit or greater.',
	},
	timePast: {
		id: 'eoc-schedule.common.time-past',
		defaultMessage: 'Time should be later than current time.',
		description: 'Validation error that indicates time values is in past.',
	},
	startLaterEnd: {
		id: 'eoc-schedule.common.start-later-end',
		defaultMessage: 'Start time should be earlier than the end time.',
		description: 'Validation error that indicates start time is later than the end time.',
	},
	endEarlierStart: {
		id: 'eoc-schedule.common.end-earlier-start',
		defaultMessage: 'End time should be later than the start time.',
		description: 'Validation error that indicates end time is earlier than the start time.',
	},
	startEndSame: {
		id: 'eoc-schedule.common.start-end-same',
		defaultMessage: 'Start and end time cannot be same.',
		description: 'Validation error that indicates start and end time should be different.',
	},
	thirtyMinuteInterval: {
		id: 'eoc-schedule.common.thirty-minute-intervals',
		defaultMessage: 'Time should be defined as 30 minute intervals.',
		description: 'Validation error that indicates time can be in 30 minutes intervals.',
	},
	nameSpaceValidationError: {
		id: 'eoc-schedule.common.name-space-validation-error',
		defaultMessage: 'The name cannot start or end with a space.',
		description: 'Error message for name validation when it starts or ends with a space',
	},
});

export { messages };

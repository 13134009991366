/**
 * Based on custom icon example:
 * https://atlassian.design/components/icon/custom-icon/examples
 */

import React from 'react';
import Icon from '@atlaskit/icon';
import type { CustomGlyphProps, IconProps } from '@atlaskit/icon/types';

const JSMAssetsSchemasGlyph = (props: CustomGlyphProps) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		data-testid={props['data-testid']}
		aria-label={props['aria-label']}
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7.5 7.5C8.05228 7.5 8.5 7.05228 8.5 6.5C8.5 5.94772 8.05228 5.5 7.5 5.5C6.94772 5.5 6.5 5.94772 6.5 6.5C6.5 7.05228 6.94772 7.5 7.5 7.5ZM10 6.5C10 7.61941 9.26428 8.56698 8.25 8.88555V10.5H14.1145C14.433 9.48572 15.3806 8.75 16.5 8.75C17.8807 8.75 19 9.86929 19 11.25C19 12.6307 17.8807 13.75 16.5 13.75C15.3806 13.75 14.433 13.0143 14.1145 12H8.25V16.25C8.25 16.6642 8.58579 17 9 17H14.05C14.2816 15.8589 15.2905 15 16.5 15C17.8807 15 19 16.1193 19 17.5C19 18.8807 17.8807 20 16.5 20C15.4748 20 14.5938 19.383 14.208 18.5H9C7.75736 18.5 6.75 17.4926 6.75 16.25V12V10.5V8.88555C5.73572 8.56698 5 7.61941 5 6.5C5 5.11929 6.11929 4 7.5 4C8.88071 4 10 5.11929 10 6.5ZM16.5 18.5C17.0523 18.5 17.5 18.0523 17.5 17.5C17.5 16.9477 17.0523 16.5 16.5 16.5C15.9477 16.5 15.5 16.9477 15.5 17.5C15.5 18.0523 15.9477 18.5 16.5 18.5ZM17.5 11.25C17.5 11.8023 17.0523 12.25 16.5 12.25C15.9477 12.25 15.5 11.8023 15.5 11.25C15.5 10.6977 15.9477 10.25 16.5 10.25C17.0523 10.25 17.5 10.6977 17.5 11.25Z"
			fill="currentColor"
		/>
	</svg>
);

const JSMAssetsSchemasIcon = (props: IconProps) => (
	<Icon {...props} glyph={JSMAssetsSchemasGlyph} />
);

export default JSMAssetsSchemasIcon;

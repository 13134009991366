import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_GLOBAL_SETTINGS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { compassProductSettingsResource } from '@atlassian/jira-router-resources-compass-product-settings/src/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { compassProductSettingsRoute } from '@atlassian/jira-router-routes-compass-product-settings/src/compassProductSettingsRoute.tsx';
import type CompassProductSettingsType from '@atlassian/jira-spa-apps-compass-product-settings/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

const LazyCompassProductSettingsPage = lazyForPaint<typeof CompassProductSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-compass-product-settings", jiraSpaEntry: "async-compass-product-settings" */ '@atlassian/jira-spa-apps-compass-product-settings'
		),
	),
);

const CompassProductSettingsComponent = () => (
	<LazyPage
		Page={LazyCompassProductSettingsPage}
		pageId="async-compass-product-settings"
		shouldShowSpinner
	/>
);

export const compassProductSettingsRouteEntry = createEntry(compassProductSettingsRoute, () => ({
	group: ROUTE_GROUPS_GLOBAL_SETTINGS,
	component: CompassProductSettingsComponent,
	layout: globalSettingsLayout,

	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
	},

	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		compassProductSettingsResource,
	],

	forPaint: [LazyAtlassianNavigation, LazyCompassProductSettingsPage],
}));

import React, { type ReactNode, createContext, useCallback, useMemo } from 'react';
import noop from 'lodash/noop';
import {
	CUSTOM_ITEM,
	FIXED_ITEM,
	FIXED_ITEM_ACTION,
	FIXED_ITEM_BUTTON,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { ROADMAP } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-type.tsx';
import {
	LEVEL_ONE,
	LEVEL_TWO,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import type { SectionItemType } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import type { StarType } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/star.tsx';
import { MENU_ID_ROADMAPS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { VIEW_ALL_ROADMAPS, type FixButtonTypeId } from '../../constants.tsx';

type AnalyticContextType = {
	fireUIAnalyticForFixMenuItem(): void;
	fireUIAnalyticForFixButton({ itemId }: { itemId: FixButtonTypeId }): void;
	fireUIAnalyticForFavouriteToggle({
		starType,
		parentItemId,
	}: {
		starType: StarType;
		parentItemId: string;
	}): void;
	fireUIAnalyticForRoadmapsItems({
		itemId,
		section,
	}: {
		itemId: string;
		section: SectionItemType;
	}): void;
};

export const AnalyticContext = createContext<AnalyticContextType>({
	fireUIAnalyticForFixMenuItem: noop,
	fireUIAnalyticForFixButton: noop,
	fireUIAnalyticForFavouriteToggle: noop,
	fireUIAnalyticForRoadmapsItems: noop,
});

export const AnalyticContextProvider = ({ children }: { children: ReactNode }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireUIAnalyticForFixMenuItem = useCallback<
		AnalyticContextType['fireUIAnalyticForFixMenuItem']
	>(() => {
		fireUIAnalytics(createAnalyticsEvent({}), {
			action: 'clicked',
			actionSubject: 'navigationItem',
			actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_ROADMAPS],
			attributes: {
				level: LEVEL_ONE,
				componentType: FIXED_ITEM,
				itemId: VIEW_ALL_ROADMAPS,
			},
		});
	}, [createAnalyticsEvent]);

	const fireUIAnalyticForFixButton = useCallback<AnalyticContextType['fireUIAnalyticForFixButton']>(
		({ itemId }) => {
			fireUIAnalytics(createAnalyticsEvent({}), {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_ROADMAPS],
				attributes: {
					level: LEVEL_ONE,
					componentType: FIXED_ITEM_BUTTON,
					itemId,
				},
			});
		},
		[createAnalyticsEvent],
	);

	const fireUIAnalyticForFavouriteToggle = useCallback<
		AnalyticContextType['fireUIAnalyticForFavouriteToggle']
	>(
		({ starType, parentItemId }) => {
			fireUIAnalytics(createAnalyticsEvent({}), {
				action: 'clicked',
				actionSubject: 'dropdownItem',
				actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_ROADMAPS],
				attributes: {
					level: LEVEL_TWO,
					componentType: FIXED_ITEM_ACTION,
					parentItemId,
					parentItemType: 'roadmap',
					itemId: starType,
				},
			});
		},
		[createAnalyticsEvent],
	);

	const fireUIAnalyticForRoadmapsItems = useCallback<
		AnalyticContextType['fireUIAnalyticForRoadmapsItems']
	>(
		({ itemId, section }) => {
			fireUIAnalytics(createAnalyticsEvent({}), {
				action: 'clicked',
				actionSubject: 'navigationItem',
				actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_ROADMAPS],
				attributes: {
					level: LEVEL_ONE,
					componentType: CUSTOM_ITEM,
					itemType: ROADMAP.toLowerCase(),
					itemId,
					section,
				},
			});
		},
		[createAnalyticsEvent],
	);

	const value = useMemo(
		() => ({
			fireUIAnalyticForFixMenuItem,
			fireUIAnalyticForFixButton,
			fireUIAnalyticForFavouriteToggle,
			fireUIAnalyticForRoadmapsItems,
		}),
		[
			fireUIAnalyticForFavouriteToggle,
			fireUIAnalyticForFixButton,
			fireUIAnalyticForFixMenuItem,
			fireUIAnalyticForRoadmapsItems,
		],
	);

	return <AnalyticContext.Provider value={value}>{children}</AnalyticContext.Provider>;
};

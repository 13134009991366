import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noSubscriptionsAndBoards: {
		id: 'directories.filters-v2.delete-modal.content.no-subscriptions-and-boards',
		defaultMessage: 'Are you sure you want to delete this filter?',
		description:
			'Generic informational message that is displayed in the delete modal when the filter can be deleted.',
	},
	associatedItems: {
		id: 'directories.filters-v2.delete-modal.content.associated-items',
		defaultMessage: "This filter is used elsewhere. Deleting it may impact other people's work.",
		description:
			'Generic informational message that is displayed in the delete modal when the filter can be deleted.',
	},
	multipleSubscriptions: {
		id: 'directories.filters-v2.delete-modal.content.multiple-subscriptions',
		defaultMessage: 'This filter has currently {number} subscriptions:',
		description:
			'Generic informational message that is displayed in the delete modal when the filter can be deleted.',
	},
	singleSubscription: {
		id: 'directories.filters-v2.delete-modal.content.single-subscription',
		defaultMessage: 'This filter has a subscription for ',
		description:
			'Generic informational message that is displayed in the delete modal when the filter can be deleted.',
	},
	multipleSubscriptionsWarning: {
		id: 'directories.filters-v2.delete-modal.content.multiple-subscriptions-warning',
		defaultMessage:
			'Deleting this filter will cause subscription emails for these users and groups to no longer be delivered.',
		description:
			'Generic informational message that is displayed in the delete modal when the filter can be deleted.',
	},
	multipleBoards: {
		id: 'directories.filters-v2.delete-modal.content.multiple-boards',
		defaultMessage: 'This filter is currently being used by {number} boards:',
		description:
			'Generic informational message that is displayed in the delete modal when the filter can be deleted.',
	},
	singleBoard: {
		id: 'directories.filters-v2.delete-modal.content.single-board',
		defaultMessage: 'This filter is currently being used by ',
		description:
			'Generic informational message that is displayed in the delete modal when the filter can be deleted.',
	},
	multipleBoardsWarning: {
		id: 'directories.filters-v2.delete-modal.content.multiple-boards-warning',
		defaultMessage:
			"You'll need to remove this filters associations to the boards before it can be removed.",
		description:
			'Generic informational message that is displayed in the delete modal when the filter can be deleted.',
	},
});

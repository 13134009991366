import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_GLOBAL_SETTINGS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getNavigationSidebarGlobalSettingsResources } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import AsyncSidebarGlobalSettingsApps from '@atlassian/jira-navigation-apps-sidebar-global-settings-apps/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { adminOauthCredentialsResource } from '@atlassian/jira-router-resources-admin-oauth-credentials/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { settingsOauthCredentialsRoute } from '@atlassian/jira-router-routes-settings-oauth-credentials-routes/src/settingsOauthCredentialsRoute.tsx';
import AdminOAuthCredentials, { LazyOAuthCredentialsAdmin } from './ui/index.tsx';

export const settingsOauthCredentialsRouteEntry = createEntry(
	settingsOauthCredentialsRoute,
	() => ({
		group: ROUTE_GROUPS_GLOBAL_SETTINGS,
		component: AdminOAuthCredentials,
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_APPS_SETTINGS,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarGlobalSettingsApps,
		},

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarGlobalSettingsResources(),
			navigationSidebarGlobalResource,
			adminOauthCredentialsResource,
		],

		forPaint: [LazyAtlassianNavigation, LazyOAuthCredentialsAdmin],
		ufoName: 'admin.oauth-credentials',
	}),
);

/**
 * @generated SignedSource<<0782c06a40008d3625f1e12681d9d9ae>>
 * @relayHash c4c7edcb69c35f51ea220574d0815858
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID af9ae1db2f285065c82807efe7982bd15a72bebc7c10b3ab0526c030f540c5ad

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraProjectNotificationConfigurationState = "CONFIGURED" | "DEFAULT" | "%future added value";
export type JiraProjectSortField = "CATEGORY" | "FAVOURITE" | "KEY" | "LAST_ISSUE_UPDATED_TIME" | "LEAD" | "NAME" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
export type SortDirection = "ASC" | "DESC" | "%future added value";
export type JiraProjectFilterInput = {
  keyword?: string | null | undefined;
  notificationConfigurationState?: JiraProjectNotificationConfigurationState | null | undefined;
  projectCategoryId?: string | null | undefined;
  sortBy?: JiraProjectSortInput | null | undefined;
  types?: ReadonlyArray<JiraProjectType> | null | undefined;
};
export type JiraProjectSortInput = {
  order?: SortDirection | null | undefined;
  sortBy?: JiraProjectSortField | null | undefined;
};
export type pageLayout_projectsDirectoryLayout_RootQuery$variables = {
  cloudId: string;
  filter: JiraProjectFilterInput;
  first?: number | null | undefined;
  isAdminSettingsContext: boolean;
  isAnonymous: boolean;
  last?: number | null | undefined;
};
export type pageLayout_projectsDirectoryLayout_RootQuery$data = {
  readonly jira: {
    readonly canBrowseProjects: {
      readonly hasPermission: boolean | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"emptyView_projectsDirectoryV3" | "header_projectsDirectoryV3" | "noPermissionViews_projectsDirectoryV3" | "pageLayout_projectsDirectoryV3_ProjectDirectoryLayout" | "projectsList_projectsDirectoryV3_rootQueryRef" | "refinementBar_projectsDirectoryV3_Filters">;
};
export type pageLayout_projectsDirectoryLayout_RootQuery = {
  response: pageLayout_projectsDirectoryLayout_RootQuery$data;
  variables: pageLayout_projectsDirectoryLayout_RootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isAdminSettingsContext"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isAnonymous"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v6 = [
  {
    "kind": "Variable",
    "name": "isAnonymous",
    "variableName": "isAnonymous"
  }
],
v7 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v8 = {
  "alias": "canBrowseProjects",
  "args": [
    (v7/*: any*/),
    {
      "kind": "Literal",
      "name": "type",
      "value": "BROWSE_PROJECTS"
    }
  ],
  "concreteType": "JiraPermission",
  "kind": "LinkedField",
  "name": "permission",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "hasPermission"
    }
  ]
},
v9 = [
  (v7/*: any*/)
],
v10 = [
  {
    "kind": "ScalarField",
    "name": "canPerform"
  }
],
v11 = {
  "kind": "ScalarField",
  "name": "id"
},
v12 = {
  "kind": "ScalarField",
  "name": "name"
},
v13 = {
  "kind": "ScalarField",
  "name": "isFavourite"
},
v14 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v15 = {
  "kind": "ScalarField",
  "name": "key"
},
v16 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v17 = {
  "kind": "ScalarField",
  "name": "totalCount"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "name": "pageLayout_projectsDirectoryLayout_RootQuery",
    "selections": [
      {
        "args": (v6/*: any*/),
        "kind": "FragmentSpread",
        "name": "header_projectsDirectoryV3"
      },
      {
        "args": (v6/*: any*/),
        "kind": "FragmentSpread",
        "name": "refinementBar_projectsDirectoryV3_Filters"
      },
      {
        "args": (v6/*: any*/),
        "kind": "FragmentSpread",
        "name": "projectsList_projectsDirectoryV3_rootQueryRef"
      },
      {
        "args": [
          {
            "kind": "Variable",
            "name": "isAdminSettingsContext",
            "variableName": "isAdminSettingsContext"
          }
        ],
        "kind": "FragmentSpread",
        "name": "pageLayout_projectsDirectoryV3_ProjectDirectoryLayout"
      },
      {
        "kind": "FragmentSpread",
        "name": "emptyView_projectsDirectoryV3"
      },
      {
        "kind": "FragmentSpread",
        "name": "noPermissionViews_projectsDirectoryV3"
      },
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          (v8/*: any*/)
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "pageLayout_projectsDirectoryLayout_RootQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "alias": "canCreateProject",
            "args": [
              (v7/*: any*/),
              {
                "kind": "Literal",
                "name": "type",
                "value": "CREATE_PROJECT"
              }
            ],
            "kind": "ScalarField",
            "name": "canPerform"
          },
          {
            "args": (v9/*: any*/),
            "concreteType": "JiraWorkManagementLicensing",
            "kind": "LinkedField",
            "name": "jwmLicensing",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "currentUserSeatEdition"
              }
            ]
          },
          {
            "args": [
              (v7/*: any*/),
              {
                "kind": "Variable",
                "name": "filter",
                "variableName": "filter"
              },
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "first"
              },
              {
                "kind": "Variable",
                "name": "last",
                "variableName": "last"
              }
            ],
            "concreteType": "JiraProjectConnection",
            "kind": "LinkedField",
            "name": "allJiraProjects",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": "canViewIssue",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "type",
                            "value": "VIEW_ISSUES"
                          }
                        ],
                        "concreteType": "JiraProjectAction",
                        "kind": "LinkedField",
                        "name": "action",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": "action(type:\"VIEW_ISSUES\")"
                      },
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "concreteType": "JiraFavouriteValue",
                        "kind": "LinkedField",
                        "name": "favouriteValue",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v11/*: any*/)
                        ]
                      },
                      (v15/*: any*/),
                      {
                        "concreteType": "JiraAvatar",
                        "kind": "LinkedField",
                        "name": "avatar",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "medium"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "projectStyle"
                      },
                      {
                        "kind": "LinkedField",
                        "name": "navigationMetadata",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "boardId"
                              },
                              (v11/*: any*/)
                            ],
                            "type": "JiraSoftwareProjectNavigationMetadata"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "queueId"
                              }
                            ],
                            "type": "JiraServiceManagementProjectNavigationMetadata"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "projectType"
                      },
                      {
                        "concreteType": "JiraProjectCategory",
                        "kind": "LinkedField",
                        "name": "category",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v11/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "name": "lead",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isUser"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "accountId"
                          },
                          (v12/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "picture"
                          },
                          (v11/*: any*/)
                        ]
                      },
                      {
                        "condition": "isAdminSettingsContext",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "alias": "lastUpdatedToolTipDate",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "format",
                                "value": "COMPLETE_DATETIME_FORMAT"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "lastUpdatedFormatted",
                            "storageKey": "lastUpdatedFormatted(format:\"COMPLETE_DATETIME_FORMAT\")"
                          },
                          {
                            "alias": "lastUpdatedDisplayDate",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "format",
                                "value": "RELATIVE"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "lastUpdatedFormatted",
                            "storageKey": "lastUpdatedFormatted(format:\"RELATIVE\")"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "projectUrl"
                      },
                      {
                        "alias": "canEdit",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "type",
                            "value": "EDIT_PROJECT_CONFIG"
                          }
                        ],
                        "concreteType": "JiraProjectAction",
                        "kind": "LinkedField",
                        "name": "action",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
                      },
                      {
                        "alias": "canViewProjectConfig",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "type",
                            "value": "VIEW_PROJECT_CONFIG"
                          }
                        ],
                        "concreteType": "JiraProjectAction",
                        "kind": "LinkedField",
                        "name": "action",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": "action(type:\"VIEW_PROJECT_CONFIG\")"
                      },
                      {
                        "alias": "jiraProjectType",
                        "kind": "ScalarField",
                        "name": "projectType"
                      },
                      {
                        "alias": "jiraProjectName",
                        "kind": "ScalarField",
                        "name": "name"
                      }
                    ]
                  }
                ]
              },
              (v17/*: any*/),
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  }
                ]
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isHasTotal"
              }
            ]
          },
          {
            "alias": "canContactAdmin",
            "args": [
              (v7/*: any*/),
              {
                "kind": "Literal",
                "name": "keys",
                "value": "jira.show.contact.administrators.form"
              }
            ],
            "concreteType": "JiraApplicationProperty",
            "kind": "LinkedField",
            "name": "applicationPropertiesByKey",
            "plural": true,
            "selections": [
              (v15/*: any*/),
              {
                "kind": "ScalarField",
                "name": "value"
              },
              (v11/*: any*/)
            ]
          },
          (v8/*: any*/),
          {
            "condition": "isAnonymous",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "args": (v9/*: any*/),
                "concreteType": "JiraUserPreferences",
                "kind": "LinkedField",
                "name": "userPreferences",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "projectListRightPanelState"
                  },
                  (v14/*: any*/)
                ]
              },
              {
                "alias": "sidebarExperimentTemplates",
                "args": [
                  (v7/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "experimentKey",
                    "value": "sidebarExperiment"
                  }
                ],
                "concreteType": "JiraProjectListViewTemplateConnection",
                "kind": "LinkedField",
                "name": "projectListViewTemplates",
                "plural": false,
                "selections": [
                  (v17/*: any*/)
                ]
              },
              {
                "args": (v9/*: any*/),
                "concreteType": "JiraProjectCategoryConnection",
                "kind": "LinkedField",
                "name": "allJiraProjectCategories",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraProjectCategoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v16/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "condition": "isAnonymous",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "args": (v9/*: any*/),
                "concreteType": "JiraProjectCategoryConnection",
                "kind": "LinkedField",
                "name": "allJiraProjectCategories",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraProjectCategoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v16/*: any*/),
                      {
                        "concreteType": "JiraProjectCategory",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v12/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "args": (v9/*: any*/),
            "concreteType": "JiraProjectTypeDetailsConnection",
            "kind": "LinkedField",
            "name": "allJiraProjectTypes",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraProjectTypeDetailsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraProjectTypeDetails",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      (v11/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "af9ae1db2f285065c82807efe7982bd15a72bebc7c10b3ab0526c030f540c5ad",
    "metadata": {},
    "name": "pageLayout_projectsDirectoryLayout_RootQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "79e5d8b7d31b85f08887cf9e30d9953a";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;

import React, { memo } from 'react';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-common/src/async.tsx';
import {
	setPerformanceMarkLoadStart,
	setPerformanceMarkLoadEnd,
} from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/performance-marks/index.tsx';
import { lazyAfterPaint, lazyForPaint } from '@atlassian/react-loosely-lazy';
import { useIsActiveRoute } from '@atlassian/react-resource-router';
import type { SidebarNextGen as SoftwareProjectSidebarType } from './ui/index.tsx';

const LazyAfterPaintClassicSoftwareProjectSidebar = lazyAfterPaint<
	typeof SoftwareProjectSidebarType
>(() => {
	setPerformanceMarkLoadStart();
	return import(/* webpackChunkName: "async-sidebar-next-gen" */ './index').then((bundle) => {
		setPerformanceMarkLoadEnd();
		return bundle;
	});
});

const LazyForPaintClassicSoftwareProjectSidebar = lazyForPaint<typeof SoftwareProjectSidebarType>(
	() => {
		setPerformanceMarkLoadStart();
		// eslint-disable-next-line jira/import/webpack-magic-comment-entries
		return import(/* webpackChunkName: "async-sidebar-next-gen" */ './index').then((bundle) => {
			setPerformanceMarkLoadEnd();
			return bundle;
		});
	},
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AfterPaintSoftwareProjectSidebar = memo<Record<any, any>>(() => (
	<SidebarBoundary id="softwareProject">
		<LazyAfterPaintClassicSoftwareProjectSidebar />
	</SidebarBoundary>
));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ForPaintSoftwareProjectSidebar = memo<Record<any, any>>(() => (
	<SidebarBoundary id="softwareProject">
		<LazyForPaintClassicSoftwareProjectSidebar />
	</SidebarBoundary>
));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SoftwareProjectSidebarLazyForPaintIssueRouteOnly = memo<Record<any, any>>(() => {
	const isIssueRoute = useIsActiveRoute('issue');
	return isIssueRoute ? <ForPaintSoftwareProjectSidebar /> : <AfterPaintSoftwareProjectSidebar />;
});

const SoftwareProjectSidebar = componentWithFG(
	'nav_sidebar_software_classic_lazyforpaint',
	SoftwareProjectSidebarLazyForPaintIssueRouteOnly,
	AfterPaintSoftwareProjectSidebar,
);

SoftwareProjectSidebar.displayName = 'SoftwareProjectSidebar';
ForPaintSoftwareProjectSidebar.displayName = 'SoftwareProjectSidebar';

export { ForPaintSoftwareProjectSidebar };
export default SoftwareProjectSidebar;

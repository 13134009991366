import { createIllustration } from '@atlassian/jira-illustration/src/ui/index.tsx';
import type { SVGViewBox } from '@atlassian/jira-illustration/src/ui/types.tsx';
import srcDark from '../../assets/bug-and-task-cards-in-a-column.svg'; // eslint-disable-line import/no-duplicates, jira/import/no-duplicates
import srcLight from '../../assets/bug-and-task-cards-in-a-column.svg'; // eslint-disable-line import/no-duplicates

const Illustration = createIllustration({
	srcLight,
	srcDark,
});

export default Illustration;

const viewBox: SVGViewBox = [0, 0, 448, 334];
export { viewBox };

import parameters from '@atlassian/jira-relay/src/__generated__/AlertsContentViewQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import type { PreloadParams } from './AlertsContentViewQuery.tsx';

export const asyncAlertsContentViewQuery = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-nav4-alerts-content-view-query" */ './AlertsContentViewQuery'
		).then(({ AlertsContentViewQuery }) => AlertsContentViewQuery),
	),
	getPreloadProps: ({ cloudId }: PreloadParams) => ({
		queries: {
			queryRef: {
				parameters,
				variables: {
					cloudId,
				},
			},
		},
	}),
});

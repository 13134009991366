import { ROUTE_NAMES_SOFTWARE_CLASSIC_PROFORMA_ISSUE_FORMS_DIRECT } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

const jiraIssueFormCreateEndpoint =
	'projects/:projectKey/forms/form/direct/:templateFormId/:issueTypeId?'; // the 1 query param is "requestTypeId"

export const softwareClassicProformaIssueFormsDirectRoute: InvariantRoute = {
	name: ROUTE_NAMES_SOFTWARE_CLASSIC_PROFORMA_ISSUE_FORMS_DIRECT,
	path: `/jira/software/c/${jiraIssueFormCreateEndpoint}`,
	exact: true,
};

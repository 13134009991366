import camelCase from 'lodash/camelCase';
import groupBy from 'lodash/groupBy';
import mapKeys from 'lodash/mapKeys';
import merge from 'lodash/merge';
import { fg } from '@atlassian/jira-feature-gating';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import {
	AUTHORIZATION_FAILED_INSUFFICIENT_PERMISSIONS,
	DATA_CLASSIFICATION_NO_ACCESS,
	FAILED_TO_FETCH,
	getErrorType,
} from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-error-type/index.tsx';
import { measureExecutionTimeMetrics } from '@atlassian/jira-forge-ui-analytics/src/common/utils/measure-execution-time/index.tsx';
import { FORGE_SAMPLING_RATE } from '@atlassian/jira-forge-ui-analytics/src/constants.tsx';
import {
	fireInitializationFetchFailedEvent,
	fireInitializationFetchFinishedEvent,
} from '@atlassian/jira-forge-ui-analytics/src/services/fetch-modules/index.tsx';
import {
	SOURCE_UNKNOWN,
	getEmptyExtensionsObject,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type { Source } from '@atlassian/jira-forge-ui-types/src/common/types/analytics.tsx';
import type {
	DataClassificatedExtensionContexts,
	ExtensionContextsArrayMap,
	ExtensionContextsMap,
	Extension,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { ExtensionPointModule } from '@atlassian/jira-forge-ui-types/src/common/types/module.tsx';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { QueryExtras, QueryIncludeConfig, DataClassificationProps } from '../../types.tsx';
import { canFetchForgeModules } from '../can-fetch-forge/index.tsx';
import { filterOutDuplicatedExtensions } from '../filter-out-duplicate-extensions/index.tsx';
import { getDataClassification } from './data-classification/index.tsx';
import { ForgeFetchError } from './errors/index.tsx';
import { getTraceIdFromError, getTraceIdFromExtensions } from './get-traceid/index.tsx';
import { buildForgeQuery } from './query/index.tsx';

const moduleToVariableKey = (module: ExtensionPointModule) => camelCase(module);

export const transformResponse = (extensions: Extension[]): ExtensionContextsArrayMap => {
	const groupedExtensions = groupBy(extensions, 'type');
	const groupedExtensionsWithMappedKeys = mapKeys(groupedExtensions, (_, key) =>
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		moduleToVariableKey(key as ExtensionPointModule),
	);
	return merge(groupedExtensionsWithMappedKeys, getEmptyExtensionsObject());
};

export const transformResponseWithAccessNarrowedExtensions = (
	extensions: Extension[],
): DataClassificatedExtensionContexts => {
	const groupedExtensions = groupBy(extensions, 'type');
	const groupedExtensionsWithMappedKeys = merge(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		mapKeys(groupedExtensions, (_, key) => moduleToVariableKey(key as ExtensionPointModule)),
		getEmptyExtensionsObject(),
	);
	const filteredOutDuplicatedExtensions = filterOutDuplicatedExtensions(
		groupedExtensionsWithMappedKeys,
	);

	const allowedExtensions = getEmptyExtensionsObject();
	const accessNarrowedExtensions = getEmptyExtensionsObject();

	Object.entries(filteredOutDuplicatedExtensions).forEach(([key, module]) => {
		const allowedExtensionsMap = new Map();
		const accessNarrowedExtensionsMap = new Map();
		module.forEach((moduleItem) => {
			if (moduleItem?.dataClassificationPolicyDecision?.status === 'BLOCKED') {
				accessNarrowedExtensionsMap.set(moduleItem.id, moduleItem);
			} else {
				allowedExtensionsMap.set(moduleItem.id, moduleItem);
			}
		});
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		allowedExtensions[key as keyof ExtensionContextsMap] = [...allowedExtensionsMap.values()];
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		accessNarrowedExtensions[key as keyof ExtensionContextsMap] = [
			...accessNarrowedExtensionsMap.values(),
		];
	});

	return {
		extensions: allowedExtensions,
		accessNarrowedExtensions,
	};
};

const EXCLUDED_ERROR_TYPES = [
	FAILED_TO_FETCH,
	AUTHORIZATION_FAILED_INSUFFICIENT_PERMISSIONS,
	DATA_CLASSIFICATION_NO_ACCESS,
];

const fetchModulesWithTraceId = async (
	cloudId: CloudId,
	modules: ExtensionPointModule[],
	dataClassification: DataClassificationProps,
	extras?: QueryExtras,
	contextIds?: string[],
	source?: Source,
	locale?: string,
): Promise<{
	data: { extensionContexts: [{ extensions: Extension[] }] };
	traceId?: string;
}> => {
	if (modules.length === 0) {
		throw new Error('No modules to fetch provided');
	}

	const { data, errors, extensions } = await measureExecutionTimeMetrics(async () => {
		const dataClassificationTags: string[] = await getDataClassification(
			dataClassification,
			source,
		);

		const includeConfig: Partial<QueryIncludeConfig> = extras?.include || {};
		const query = buildForgeQuery(includeConfig);

		const variables = {
			contextIds: contextIds || [`ari:cloud:jira::site/${cloudId}`],
			extensionsFilter: [
				{
					type: 'EXTENSION_TYPE',
					value: modules,
				},
			],
			dataClassificationTags: dataClassificationTags.map(
				(id) => `ari:cloud:platform::classification-tag/${id}`,
			),
			locale: fg('forge-ui-i18n-locale-enabled') ? locale : undefined,
		};

		return performPostRequest('/gateway/api/graphql', {
			body: JSON.stringify({ query, variables }),
			method: 'POST',
		});
	});

	const traceId = getTraceIdFromExtensions({ extensions });
	if (errors) {
		throw new ForgeFetchError(
			`Response ended with an error message: ${errors[0].message}`,
			traceId,
			errors,
		);
	}

	if (!data) {
		throw new ForgeFetchError('No data returned', traceId, errors);
	}

	if (data.extensionContexts.length < 1) {
		throw new ForgeFetchError('No extensionContext returned', traceId, errors);
	}
	return { data, traceId };
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async (
	cloudId: CloudId,
	modules: ExtensionPointModule[],
	dataClassification: DataClassificationProps,
	extras?: QueryExtras,
	source: Source = SOURCE_UNKNOWN,
	contextIds?: string[],
	locale?: string,
): Promise<DataClassificatedExtensionContexts> => {
	try {
		// Just in case something goes very much wrong we don't want to call the XIS
		if (!canFetchForgeModules()) {
			return {
				extensions: getEmptyExtensionsObject(),
				accessNarrowedExtensions: getEmptyExtensionsObject(),
			};
		}

		const { data, traceId } = await fetchModulesWithTraceId(
			cloudId,
			modules,
			dataClassification,
			extras,
			contextIds,
			source,
			locale,
		);

		if (Math.random() * 100 < FORGE_SAMPLING_RATE) {
			fireInitializationFetchFinishedEvent(source, { traceId });
		}

		return transformResponseWithAccessNarrowedExtensions(data.extensionContexts[0].extensions);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		const errorType = getErrorType(error);
		const traceId = getTraceIdFromError(error);

		fireInitializationFetchFailedEvent(source, {
			isSSR: __SERVER__,
			errorType,
			error,
			traceId,
		});

		if (EXCLUDED_ERROR_TYPES.includes(errorType)) {
			return {
				extensions: getEmptyExtensionsObject(),
				accessNarrowedExtensions: getEmptyExtensionsObject(),
			};
		}

		throw error;
	}
};

/**
 * @generated SignedSource<<6bea1fa861c834b226cf77a2772a05e1>>
 * @relayHash 0414079a9fea403df5ac71ab54332294
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e6cb48a6e7e0dd98d666af80686764e3a677989f42b44c012aa0514cb6f70841

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentBrowseConversationsQuery } from './srcVirtualAgentBrowseConversationsQuery.graphql';

import conversationFilterEnabled_provider from '@atlassian/jira-relay-provider/src/is-conversation-filter-enabled.relayprovider';
import checkForAiEnabledFF_provider from '@atlassian/jira-relay-provider/src/jsm-va-is-require-ai-enabled.relayprovider';

const node: PreloadableConcreteRequest<srcVirtualAgentBrowseConversationsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "e6cb48a6e7e0dd98d666af80686764e3a677989f42b44c012aa0514cb6f70841",
    "metadata": {},
    "name": "srcVirtualAgentBrowseConversationsQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcisconversationfilterenabledrelayprovider": conversationFilterEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider": checkForAiEnabledFF_provider
    }
  }
};

export default node;

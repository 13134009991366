/** @jsx jsx */
import React, { useCallback } from 'react';
import { css, jsx, styled } from '@compiled/react';
import noop from 'lodash/noop';
import type { OnChange as SmartUserPickOnChange } from '@atlaskit/smart-user-picker';
import { ScreenReaderText } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useCreateTeamDialog } from '@atlassian/jira-issue-field-create-team-dialog/src/ui/use-create-team-dialog/index.tsx';
import type { Team, TeamValue } from '@atlassian/jira-issue-field-team/src/common/types.tsx';
import { TeamPickerEdit } from '@atlassian/jira-issue-field-team/src/ui/edit-mode/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { FieldValueSinglePickerProps, TeamFieldValue } from '../../../../../common/types.tsx';
import messages from './messages.tsx';

const TEAM_A11Y_LABEL_ID = 'software-board-create.source-value.team';

type TeamPickerProps = {
	label: string;
} & FieldValueSinglePickerProps<TeamFieldValue>;

export const TeamPicker = ({ onChange, value, isInvalid, label }: TeamPickerProps) => {
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();

	const handleOnCreate = useCallback(
		(team: TeamValue) => {
			if (!team) return;

			// wait for the team to be created before updating the value
			setTimeout(() => {
				onChange(team);
			}, 1000);
		},
		[onChange],
	);

	const [createTeamDialog, createTeamDialogTrigger] = useCreateTeamDialog({
		onCreate: handleOnCreate,
	});

	const handleChange: SmartUserPickOnChange = (team) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		onChange?.((team as Team) || null);
	};

	return (
		<TeamPickerEditWrapper>
			{/* Prevent ESC press on Select bubbling up to the modal */}
			{fg('nav4_simplified_board_create_beta') ? (
				<>
					<ScreenReaderText id={TEAM_A11Y_LABEL_ID}>{label}</ScreenReaderText>
					<EnterEscapeHandler onEscape={noop}>
						<TeamPickerEdit
							siteId={cloudId}
							isCompact={false}
							hasNoTeamOption={false}
							createTeamDialogTrigger={createTeamDialogTrigger}
							placeholder={formatMessage(messages.placeholder)}
							onChange={handleChange}
							defaultValue={value}
							isDropdownMenuFixedAndLayered
							isInvalid={isInvalid}
							isClearable={false}
							aria-labelledby={TEAM_A11Y_LABEL_ID}
						/>
					</EnterEscapeHandler>
				</>
			) : (
				<TeamPickerEdit
					siteId={cloudId}
					isCompact={false}
					hasNoTeamOption={false}
					createTeamDialogTrigger={createTeamDialogTrigger}
					placeholder={formatMessage(messages.placeholder)}
					onChange={handleChange}
					defaultValue={value}
					isDropdownMenuFixedAndLayered
					isInvalid={isInvalid}
					isClearable={false}
				/>
			)}
			{/* catch the error in the create team dialog */}
			<ErrorBoundary id="board-create-team-create-dialog">
				{/* Preventing the parent form from submitting */}
				<form
					css={removeFormSpaceStyle}
					tabIndex={-1}
					name="create-team-dialog-form"
					onSubmit={(e: React.FormEvent) => {
						e.stopPropagation();
					}}
				>
					{createTeamDialog}
				</form>
			</ErrorBoundary>
		</TeamPickerEditWrapper>
	);
};

const removeFormSpaceStyle = css({
	display: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const TeamPickerEditWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'.fabric-user-picker__control': {
		minHeight: '40px',
		height: '40px',
	},
});

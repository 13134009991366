import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { servicedeskCmdbAssetsObjectSchema1Route } from '@atlassian/jira-router-routes-servicedesk-cmdb-routes/src/servicedeskCmdbAssetsObjectSchema1Route.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { sharedRouteProps } from './common/constants.tsx';
import { getCmdbResources } from './common/getCmdbResources.tsx';
import { LazyServicedeskCmdbObjectSchemaV2, ObjectSchemaV2 } from './ui/object-schema/index.tsx';

export const servicedeskCmdbAssetsObjectSchemaRouteEntry: Route = createEntry(
	servicedeskCmdbAssetsObjectSchema1Route,
	() => ({
		...sharedRouteProps,
		component: ObjectSchemaV2,
		resources: getCmdbResources(),
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbObjectSchemaV2],
		perfMetricKey: 'service-management.insight-cmdb.load-schema-page',
	}),
);

import { useCallback } from 'react';
import { graphql, useMutation } from 'react-relay';
import type {
	useBoardCreateMutation$data as useBoardCreateMutationData,
	useBoardCreateMutation as useBoardCreateMutationQuery,
	JiraCreateBoardInput,
} from '@atlassian/jira-relay/src/__generated__/useBoardCreateMutation.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

export const useBoardCreateMutation = () => {
	const cloudId = useCloudId();

	const [commit] = useMutation<useBoardCreateMutationQuery>(graphql`
		mutation useBoardCreateMutation($cloudId: ID!, $input: JiraCreateBoardInput!) {
			jira @optIn(to: ["JiraCreateBoard"]) {
				createBoard(cloudId: $cloudId, input: $input) {
					board {
						__id
						boardId
						name
						id
						boardType
						boardUrl
					}
					success
					errors {
						message
						extensions {
							errorType
							statusCode
						}
					}
				}
			}
		}
	`);

	const handleMutation = useCallback(
		async (input: JiraCreateBoardInput): Promise<useBoardCreateMutationData> =>
			new Promise((resolve, reject) => {
				commit({
					variables: {
						cloudId,
						input,
					},
					onCompleted: (result) => {
						resolve(result);
					},
					onError: (error) => {
						reject(error);
					},
				});
			}),
		[cloudId, commit],
	);

	return { handleMutation };
};

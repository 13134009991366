/**
 * @generated SignedSource<<09f284fba0bd864c78a53aab83cbaed8>>
 * @relayHash 53f00552ddf647d57a6dcb09a41ca25e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 206704749fbc50a72cbfdd2420ed9772ef89668d5b44b0ccaee54ae518e53140

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentSettingsQuery } from './srcVirtualAgentSettingsQuery.graphql';

import newSettingsPage_provider from '@atlassian/jira-relay-provider/src/jsm-va-enable-ai-answers-page.relayprovider';
import checkForAiEnabledFF_provider from '@atlassian/jira-relay-provider/src/jsm-va-is-require-ai-enabled.relayprovider';

const node: PreloadableConcreteRequest<srcVirtualAgentSettingsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "206704749fbc50a72cbfdd2420ed9772ef89668d5b44b0ccaee54ae518e53140",
    "metadata": {},
    "name": "srcVirtualAgentSettingsQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaenableaianswerspagerelayprovider": newSettingsPage_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider": checkForAiEnabledFF_provider
    }
  }
};

export default node;

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	jsmAssetsGlobalConfiguration: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-assets.assets-menu.section-actions.jsm-assets-global-configuration',
		defaultMessage: 'General configuration',
		description: 'Assets dropdown item to link customer to global configuration page.',
	},
	jsmAssetsSettingsIcon: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-assets.assets-menu.section-actions.jsm-assets-settings-icon',
		defaultMessage: 'Settings',
		description: 'Settings icon in the Assets global configuration dropdown item',
	},
});

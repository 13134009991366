import { createLayout, type Layout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const RightSidebars = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-ops-team-right-sidebars" */ './ui/right-sidebars'),
	{ ssr: false },
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const Onboarding = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-ops-team-onboarding" */ './ui/onboarding'),
	{ ssr: false },
);

export const opsTeamLayout: Layout = createLayout({
	get globalComponents() {
		return [Onboarding];
	},
	rightSidebars: [RightSidebars],
});

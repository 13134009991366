import React, { useEffect, useRef } from 'react';
// eslint-disable-next-line jira/restricted/react-18
import { createRoot } from 'react-dom/client';
import { JiraContextContainer } from '@atlassian/jira-context-container/src/JiraContextContainer.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createRouterSelector } from '@atlassian/react-resource-router';
import { ConcurrentAdvisory } from '../concurrent-advisory-component/index.tsx';

const useRouterLocation = createRouterSelector((state) => state.location);
const useRouterAction = createRouterSelector((state) => state.action);
const useRouterQuery = createRouterSelector((state) => state.query);
const useRouterRoute = createRouterSelector((state) => state.route);
const useRouterMatch = createRouterSelector((state) => state.match);

const RouteComponentWithRouterProps = () => {
	const location = useRouterLocation();
	const action = useRouterAction();
	const query = useRouterQuery();
	const route = useRouterRoute();
	const match = useRouterMatch();

	const { component: RouteComponent } = route;
	return (
		RouteComponent && (
			<RouteComponent
				location={location}
				route={route}
				match={match}
				action={action}
				query={query}
			/>
		)
	);
};

export function ConcurrentRootComponent() {
	const containerRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!containerRef.current) {
			// this is actually "impossible"
			throw new Error('ref is not set');
		}
		const root = createRoot(containerRef.current);
		root.render(
			<JiraContextContainer>
				<RouteComponentWithRouterProps />
				{fg('concurrent-rendering-change-advisory') ? <ConcurrentAdvisory /> : null}
			</JiraContextContainer>,
		);

		return () => {
			// We need the setTimeout to avoid this error:
			// `Attempted to synchronously unmount a root while React was already rendering. React cannot finish unmounting the root until the current render has completed, which may lead to a race condition.`
			setTimeout(() => {
				root.unmount();
			});
		};
	}, []);

	return (
		<div
			id="concurrent-route-component"
			// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop
			style={{ display: 'contents' }}
			ref={containerRef}
		/>
	);
}

import React, { useCallback, forwardRef, type Ref } from 'react';
import CustomizeIcon from '@atlaskit/icon/core/customize';
import { SECTION_ITEM_CUSTOMIZE_SIDEBAR } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { useFormattedMessageAndIcon } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-formatted-message-and-icon/index.tsx';
import { useSectionItemAnalytics } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-section-item-analytics/index.tsx';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { MenuButtonItem, type MenuButtonItemProps } from '@atlassian/navigation-system';
import messages from './messages.tsx';

export type CustomiseSidebarTriggerProps = Pick<MenuButtonItemProps, 'onClick'>;

export const CustomiseSidebarTrigger = forwardRef(
	({ onClick }: CustomiseSidebarTriggerProps, ref: Ref<HTMLButtonElement> | undefined) => {
		const { formattedMessage, icon } = useFormattedMessageAndIcon(
			messages.customiseSidebar,
			CustomizeIcon,
		);
		const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();

		const onClickHandler = useCallback(
			(
				event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
				analyticsEvent: UIAnalyticsEvent,
			) => {
				sendSectionItemAnalyticsEvent({
					sectionItem: SECTION_ITEM_CUSTOMIZE_SIDEBAR,
				});
				onClick?.(event, analyticsEvent);
			},
			[onClick, sendSectionItemAnalyticsEvent],
		);

		return (
			<MenuButtonItem
				interactionName="nav4-sidebar-customise-modal"
				elemBefore={icon}
				onClick={onClickHandler}
				ref={ref}
			>
				{formattedMessage}
			</MenuButtonItem>
		);
	},
);

import { ConnectionHandler, type RecordSourceSelectorProxy } from 'relay-runtime';
import RelayDataID from '@atlassian/relay-data-id';

type UpdateRelayStoreParams = {
	cloudId: string;
	entityId: string;
	isFavourite: boolean;
	favouriteConnectionId?: string;
	store: RecordSourceSelectorProxy;
};

/**
 * Updates the relay store for favourites.
 *
 * @param {UpdateRelayStoreParams} params - The parameters for updating the relay store.
 * @param {string} params.cloudId - The cloud ID.
 * @param {string} params.entityId - The entity ID.
 * @param {boolean} params.isFavourite - A flag indicating whether item is favourite.
 * @param {RecordSourceSelectorProxy} params.store - The Relay store.
 * @param {string} params.favouriteConnectionId - The favouriteConnectionId from graphql query.
 *
 * If `isFavourite` is false, the function finds the existing edge in favourites that matches the `entityId`,
 * and if found, it removes the edge from the favourites.
 */
export const updateRelayStore = ({
	cloudId,
	isFavourite,
	store,
	favouriteConnectionId,
	entityId,
}: UpdateRelayStoreParams) => {
	const entityTypeName = 'JiraPlan';
	const nodeId = RelayDataID({ id: entityId }, entityTypeName) ?? '';
	const node = store.get(nodeId);

	let favourites;

	if (favouriteConnectionId) {
		/**
		 * We first try to fetch connection using connectionId, but in the header of individual
		 * plan, we don't have access to this connection. Hence we are manually access
		 * the connection in the else block.
		 */
		favourites = store.get(favouriteConnectionId);
	} else {
		/**
		 * Make sure the "first: 15" below matches with the firstCount in the graphql query written in the
		 * file below. Else this will not work.
		 *
		 * jira/src/packages/navigation-apps/sidebar-nav4/plans/src/ui/plans/common/plans-list/PlansList.tsx
		 */
		const jiraRecord = store.getRoot().getLinkedRecord('jira');
		favourites = jiraRecord?.getLinkedRecord('favourites', {
			cloudId,
			filter: { type: 'PLAN' },
			first: 15,
		});
	}

	// Exit if we can't find the relevant node or favourite connection
	if (!node || !favourites) {
		return;
	}

	node.getLinkedRecord('favouriteValue')?.setValue(isFavourite, 'isFavourite');
	if (isFavourite) {
		const edge = ConnectionHandler.createEdge(store, favourites, node, 'JiraFavouriteEdge');
		ConnectionHandler.insertEdgeBefore(favourites, edge);
	} else {
		ConnectionHandler.deleteNode(favourites, nodeId);
	}
};

import type { OptionsType } from '@atlaskit/select';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { getJpdProjects } from '../../../services/projects/index.tsx';
import type { ProjectOption } from './types.tsx';

export const loadProjectsOptions = async (
	searchQuery: string,
): Promise<OptionsType<ProjectOption>> =>
	getJpdProjects(searchQuery)
		.then((projects) =>
			projects.map((project) => ({
				id: project.id,
				label: project.name,
				imageUrl: project.avatarUrls['16x16'],
				value: project.key,
			})),
		)
		.catch((error) => {
			fireErrorAnalytics(
				createErrorAnalytics('polaris.error.availableProjectsLoadingFailed', error),
			);
			throw error;
		});

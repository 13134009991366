import React, { type ReactNode } from 'react';
import { Box, Flex, xcss } from '@atlaskit/primitives';

export const AdvancedEditorWrapper = ({ children }: { children: ReactNode }) => (
	<Flex xcss={[negativeMarginOffsetStyles, advancedEditorWrapperStyles]}>{children}</Flex>
);

export const BeforeJqlBuilderContainer = ({ children }: { children: ReactNode }) => (
	<Box xcss={negativeMarginOffsetStyles}>{children}</Box>
);

export const BeforeJqlBuilderListItemContainer = ({ children }: { children: ReactNode }) => (
	<Box as="li" xcss={[negativeMarginOffsetStyles, beforeJqlBuilderListItemContainer]}>
		{children}
	</Box>
);

const negativeMarginOffsetStyles = xcss({
	marginBlock: 'space.050',
	marginLeft: 'space.0',
	marginRight: 'space.050',
});

const advancedEditorWrapperStyles = xcss({
	flexGrow: 1,
});

const beforeJqlBuilderListItemContainer = xcss({
	listStyle: 'none',
});

import React from 'react';
import {
	useIsStandardOrFreeJSW,
	useIsUnlicensedJSW,
} from '@atlassian/jira-atlassian-navigation/src/controllers/edition/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './ui/constants.tsx';

import {
	Nav4Plans as Nav4PlansWithoutErrorBoundary,
	type Nav4PlansProps,
} from './ui/plans/Nav4Plans.tsx';
import { Nav4PlansPremiumUpsellFeatureGate } from './ui/plans/premium-upsell-feature-gate/Nav4PlansPremiumUpsellFeatureGate.tsx';

export function Nav4Plans(props: Nav4PlansProps) {
	const isStandardOrFree = useIsStandardOrFreeJSW();
	const isUnlicensedJsw = useIsUnlicensedJSW();

	if (isUnlicensedJsw) {
		return null;
	}

	if (isStandardOrFree) {
		return <Nav4PlansPremiumUpsellFeatureGate />;
	}

	return (
		<UFOSegment name="nav4.sidebar.plans">
			<JSErrorBoundary
				fallback="flag"
				id="plans-section"
				packageName={PACKAGE_NAME}
				teamName={TEAM_NAME}
			>
				<Nav4PlansWithoutErrorBoundary {...props} />
			</JSErrorBoundary>
		</UFOSegment>
	);
}

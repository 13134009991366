import React from 'react';
import { Field, useFormState, ErrorMessage } from '@atlaskit/form';
import { useIntl } from '@atlassian/jira-intl';
import type { BoardCreateFormValues } from '../../../../common/types.tsx';
import { fireBoardCreateErrorAnalytics } from '../../../../common/utils.tsx';
import messages from './messages.tsx';
import { boardSourceFieldComponentMap } from './utils.tsx';

export const VALIDATION_ERROR_EMPTY = 'VALIDATION_ERROR_EMPTY';
export const SOURCE_VALUE_FIELD_NAME = 'sourceValue';

export const SourceValuePicker = () => {
	const { formatMessage } = useIntl();
	const formState = useFormState<BoardCreateFormValues>();
	const sourceType: BoardCreateFormValues['sourceType'] | undefined = formState?.values.sourceType;

	if (!sourceType) {
		return null;
	}

	const mappedSource = boardSourceFieldComponentMap[sourceType];

	if (!mappedSource) {
		fireBoardCreateErrorAnalytics({
			error: new Error(`No source field component found for ${sourceType}`),
			id: 'unmappedSourceType',
		});

		return null;
	}

	const { component: PickerComponent, extraProps, label } = mappedSource;

	return (
		<Field<BoardCreateFormValues['sourceValue']>
			key={`sourceValue-${sourceType}`}
			name={SOURCE_VALUE_FIELD_NAME}
			label={formatMessage(label)}
			isRequired
		>
			{({ fieldProps, error }) => (
				<>
					<PickerComponent
						key={`sourceValue-${sourceType}-picker`}
						label={formatMessage(label)}
						{...fieldProps}
						{...extraProps}
					/>
					{error === VALIDATION_ERROR_EMPTY && (
						<ErrorMessage testId="software-board-create.ui.modal.form.source-value-picker.error">
							{formatMessage(messages.sourceValueEmptyError)}
						</ErrorMessage>
					)}
				</>
			)}
		</Field>
	);
};

// import shuffle from 'lodash/shuffle';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { AppRecommendations } from '../common/types.tsx';
import { recommendationsFG as recommendations } from '../ui/index.tsx';
import { useRecommendationContextFG as useRecommendationContext } from './useRecommendationContextFG.tsx';

export const useSelectedRecommendationFG = ({
	appRecommendations,
}: {
	appRecommendations: AppRecommendations;
}) => {
	const recommendationContext = useRecommendationContext();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	if (!recommendationContext) {
		return {
			recommendationKey: null,
		};
	}
	// NOTE: having shufle here will cause different order of data on SSR and client resulting in a UI change after first render
	// which may not seem like a good UX

	// const shuffledRecommendations = shuffle(recommendations);

	const recommendationStatus = recommendations.map((recommendation) => {
		const isEnabled = recommendation.shouldRender(
			recommendationContext,
			createAnalyticsEvent({}),
			appRecommendations,
		);

		return {
			key: recommendation.key,
			isEnabled,
		};
	});

	const selectedRecommendation = recommendationStatus.find((result) => result.isEnabled);

	return {
		recommendationKey: selectedRecommendation?.key || null,
	};
};

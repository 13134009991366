import { useRouter, createRouterSelector } from '@atlassian/react-resource-router';

export function useGetCurrentUrl() {
	const [routerContext] = useRouter();

	const searchParams = new URLSearchParams(routerContext.query ?? {});
	const url = new URL(`http://example.com${routerContext.match.url}/?${searchParams.toString()}`);
	return url.toString();
}

export const useGetCurrentUrlPath = createRouterSelector((routerContext) => {
	const url = new URL(`http://example.com${routerContext.match.url}`);
	return url.toString();
});

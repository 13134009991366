/**
 * @generated SignedSource<<4da33fb6beb23dae4ec2c9e10e8cef71>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraNavigationItemTypeKey = "APP" | "APPROVALS" | "APPS" | "ARCHIVED_ISSUES" | "ATTACHMENTS" | "BACKLOG" | "BOARD" | "CALENDAR" | "CODE" | "COMPONENTS" | "DEPLOYMENTS" | "DEVELOPMENT" | "FORMS" | "GOALS" | "INCIDENTS" | "ISSUES" | "LIST" | "ON_CALL" | "PAGES" | "RELEASES" | "REPORTS" | "REQUESTS" | "SECURITY" | "SHORTCUTS" | "SUMMARY" | "TIMELINE" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type JswNonExpandableProject$data = {
  readonly avatar: {
    readonly large: string;
  };
  readonly defaultNavigationItem?: {
    readonly typeKey?: JiraNavigationItemTypeKey | null | undefined;
  } | null | undefined;
  readonly isFavourite: boolean | null | undefined;
  readonly name: string;
  readonly navigationMetadata?: {
    readonly boardId?: string;
    readonly isSimpleBoard?: boolean;
  } | null | undefined;
  readonly projectId: string;
  readonly projectKey: string;
  readonly projectType: JiraProjectType | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectActions">;
  readonly " $fragmentType": "JswNonExpandableProject";
};
export type JswNonExpandableProject$key = {
  readonly " $data"?: JswNonExpandableProject$data;
  readonly " $fragmentSpreads": FragmentRefs<"JswNonExpandableProject">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "simplifyBoardQuery"
    }
  ],
  "kind": "Fragment",
  "name": "JswNonExpandableProject",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "projectId"
      },
      "action": "THROW",
      "path": "projectId"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "projectKey",
        "kind": "ScalarField",
        "name": "key"
      },
      "action": "THROW",
      "path": "projectKey"
    },
    {
      "kind": "ScalarField",
      "name": "isFavourite"
    },
    {
      "kind": "ScalarField",
      "name": "projectType"
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraAvatar",
        "kind": "LinkedField",
        "name": "avatar",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "large"
            },
            "action": "THROW",
            "path": "avatar.large"
          }
        ]
      },
      "action": "THROW",
      "path": "avatar"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "name"
      },
      "action": "THROW",
      "path": "name"
    },
    {
      "condition": "simplifyBoardQuery",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "navigationMetadata",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "boardId"
                },
                {
                  "kind": "ScalarField",
                  "name": "isSimpleBoard"
                }
              ],
              "type": "JiraSoftwareProjectNavigationMetadata"
            }
          ]
        },
        {
          "kind": "LinkedField",
          "name": "defaultNavigationItem",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "typeKey"
                }
              ],
              "type": "JiraNavigationItem",
              "abstractKey": "__isJiraNavigationItem"
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "ProjectActions"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "fe2e668be082e6feecd3148f504c2cdb";

export default node;

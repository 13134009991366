import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { ContextualAnalyticsData, DRAWER } from '@atlassian/jira-product-analytics-bridge';
import { LazyWait } from '@atlassian/react-loosely-lazy';
import HelpPanelLoading from './common/ui/help-panel-loading/index.tsx';
import { SearchContextProvider } from './contexts/searchcontext.tsx';
import { AsyncLazyHelpPanel } from './ui/async.tsx';
import type { HelpPanelProps } from './ui/index.tsx';

type Props = {
	hasBeenRendered: boolean;
} & HelpPanelProps;

export const LazyHelpPanel = ({
	navigationData: { articleId, history },
	setNavigationData,
	releaseNotesNotifications,
	releaseNotesNotificationsSetter,
	isOpen,
	onCloseHelpPanel,
	hasBeenRendered,
	routeGroup,
	routeName,
}: Props) => {
	const user = useCurrentUser();

	return (
		<ErrorBoundary
			id="help-panel"
			packageName="jiraHelpPanel"
			render={() => <HelpPanelLoading loadingArticle={articleId.id !== ''} />}
		>
			<ReportErrors id="root" packageName="jiraHelpPanel" teamName="self-help-experiences">
				<SearchContextProvider>
					<ContextualAnalyticsData
						tags={['iph']}
						sourceName="jiraHelpPanel"
						sourceType={DRAWER}
						attributes={{
							searchService: expVal(
								'20240422_self_help_iph_ms3_expt',
								'iph_ms3_experience_display_flag',
								false,
							)
								? 'help-aggregator'
								: 'algolia',
							routeGroup,
							routeName,
							userId: user?.data?.user?.accountId,
						}}
					>
						<LazyWait until={hasBeenRendered}>
							<Placeholder
								name="async-lazy-help-panel"
								fallback={isOpen ? <HelpPanelLoading loadingArticle={articleId.id !== ''} /> : null}
							>
								<AsyncLazyHelpPanel
									navigationData={{ articleId, history }}
									releaseNotesNotificationsSetter={releaseNotesNotificationsSetter}
									releaseNotesNotifications={releaseNotesNotifications}
									setNavigationData={setNavigationData}
									isOpen={isOpen}
									onCloseHelpPanel={onCloseHelpPanel}
									routeGroup={routeGroup}
									routeName={routeName}
								/>
							</Placeholder>
						</LazyWait>
					</ContextualAnalyticsData>
				</SearchContextProvider>
			</ReportErrors>
		</ErrorBoundary>
	);
};

import React from 'react';
import { Popup, PopupContent, PopupTrigger } from '@atlaskit/popup/experimental';
import { Box, xcss } from '@atlaskit/primitives';
import { MenuButtonItem } from '@atlassian/navigation-system';
import type { MoreNavMenuItemProps } from './types.tsx';
import { useMoreFormattedMessageAndIcon } from './utils.tsx';

/**
 * A nav menu item which contains a nav submenu that appears in a dropdown when opened.
 *
 * Must have menu items.
 *
 * Note, this could be a dropdown nav menu item, but the only place it is currently required is for "More".
 * Consequently, the message and icon are hardcoded and there is no async usage built in.
 */
export const MoreNavMenuItem = ({
	isOpen,
	onClose,
	onToggle,
	// Submenu props
	MenuItems,
}: MoreNavMenuItemProps) => {
	const { formattedMessage, icon } = useMoreFormattedMessageAndIcon();

	return (
		<Popup isOpen={isOpen}>
			<PopupTrigger>
				{(triggerProps) => (
					<MenuButtonItem {...triggerProps} elemBefore={icon} onClick={onToggle}>
						{formattedMessage}
					</MenuButtonItem>
				)}
			</PopupTrigger>

			{/* Adding listitem role as the parent is a UL */}
			<Box xcss={popupContentContainerStyles} role="listitem">
				<PopupContent
					shouldFitContainer
					placement="bottom-start"
					// Offset the top margin of the menu section.
					// This will keep an even spacing of menu items between those inside the dropdown and those above,
					// and keep the icons aligned
					offset={[-4, 4]}
					onClose={onClose}
					shouldRenderToParent
					strategy="absolute"
				>
					{() => <MenuItems onClose={onClose} />}
				</PopupContent>
			</Box>
		</Popup>
	);
};

const popupContentContainerStyles = xcss({
	position: 'relative',
	marginInline: 'space.negative.050',
});

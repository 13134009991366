import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	triggerLabel: {
		id: 'jet-plan-action-menu.trigger-label',
		defaultMessage: 'Plan settings',
		description: 'ARIA label for dropdown menu trigger in the plans navigation sidebar.',
	},
	help: {
		id: 'jet-plan-action-menu.help',
		defaultMessage: 'Get started with plans',
		description:
			'Label for dropdown menu item in the plans navigation sidebar. Goes to our documentation.',
	},
	duplicate: {
		id: 'jet-plan-action-menu.duplicate',
		defaultMessage: 'Duplicate plan',
		description: 'Label for dropdown menu item in the plans navigation sidebar. Opens a modal.',
	},
	trash: {
		id: 'jet-plan-action-menu.trash',
		defaultMessage: 'Move to trash',
		description: 'Label for dropdown menu item in the plans navigation sidebar. Opens a modal.',
	},
	archive: {
		id: 'jet-plan-action-menu.archive',
		defaultMessage: 'Archive',
		description: 'Label for dropdown menu item in the plans navigation sidebar. Opens a modal.',
	},
	restore: {
		id: 'jet-plan-action-menu.restore',
		defaultMessage: 'Restore',
		description: 'Label for dropdown menu item in the plans navigation sidebar. Opens a modal.',
	},
	takeTour: {
		id: 'jet-plan-action-menu.take-tour',
		defaultMessage: 'Take tour',
		description:
			'Label for dropdown menu item in the plans navigation sidebar. Goes to Timeline view and begin onboarding tour.',
	},
	starPlan: {
		id: 'jet-plan-action-menu.star-plan',
		defaultMessage: 'Add to starred',
		description:
			'Label for dropdown menu item in the plans navigation sidebar. Adds a plan to starred plans.',
	},
	unstarPlan: {
		id: 'jet-plan-action-menu.unstar-plan',
		defaultMessage: 'Remove from starred',
		description:
			'Label for dropdown menu item in the plans navigation sidebar. Removes a plan from starred plans.',
	},
	planSettings: {
		id: 'jet-plan-action-menu.plan-settings',
		defaultMessage: 'Plan settings',
		description:
			'Label for dropdown menu item in the plans navigation sidebar. Goes to plan settings.',
	},
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	manageApps: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-apps.common.section-actions.manage-apps',
		defaultMessage: 'Manage apps',
		description:
			'Link to go manage your apps in admin settings. It is shown in the dropdown next to the Apps menu on a global sidebar',
	},
	appAuditLogs: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-apps.common.section-actions.app-audit-logs',
		defaultMessage: 'App audit logs',
		description:
			'Link to go to app audit logs in admin settings. It is shown in the dropdown next to the Apps menu on a global sidebar',
	},
	viewAppRequests: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-apps.common.section-actions.view-app-requests',
		defaultMessage: 'View app requests',
		description:
			'Link to go to app requests in admin settings. It is shown in the dropdown next to the Apps menu on a global sidebar',
	},
});

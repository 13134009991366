import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { planLayout } from '@atlassian/jira-plan-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import {
	advancedRoadmapsPlanReportPageInitialChecksResource,
	advancedRoadmapsReduxStoreStateResource,
	advancedRoadmapsLastVisitedProgramBoardIdResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { softwareAdvancedRoadmaps3Route } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/softwareAdvancedRoadmaps3Route.tsx';
import type { Route } from '@atlassian/react-resource-router';
import PlanReportPage, { LazyPlanReport } from './ui/plan-report/index.tsx';

/**
 * Report Page
 */

export const planReportPageRouteEntry: Route = createEntry(softwareAdvancedRoadmaps3Route, () => ({
	group: ROUTE_GROUPS_SOFTWARE,
	ufoName: 'software-advanced-roadmaps-report',
	component: PlanReportPage,
	layout: composeLayouts(globalLayout, planLayout),

	resources: [
		...getNavigationResources(),
		advancedRoadmapsPlanReportPageInitialChecksResource,
		advancedRoadmapsReduxStoreStateResource,
		advancedRoadmapsLastVisitedProgramBoardIdResource,
	],

	preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},

	navigation: {
		menuId: MENU_ID.PLANS,
	},

	forPaint: [LazyAtlassianNavigation, LazyPlanReport],
}));

/**
 * @generated SignedSource<<8391836c727c96074b367b5cee339a0d>>
 * @relayHash a752dde009c93b4975b47e740db8b1bd
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 43c453bca1e65ebbd87f7974bea4d6b5c78178b9b9507ff8b40972a4e4fe9ecf

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { mainVirtualAgentSidebarQuery } from './mainVirtualAgentSidebarQuery.graphql';

import checkForAiEnabledFF_provider from '@atlassian/jira-relay-provider/src/jsm-va-is-require-ai-enabled.relayprovider';

const node: PreloadableConcreteRequest<mainVirtualAgentSidebarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "43c453bca1e65ebbd87f7974bea4d6b5c78178b9b9507ff8b40972a4e4fe9ecf",
    "metadata": {},
    "name": "mainVirtualAgentSidebarQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider": checkForAiEnabledFF_provider
    }
  }
};

export default node;

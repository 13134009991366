import React from 'react';
import type { FieldProps } from '@atlaskit/form';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import type { EditorContainerProps } from '@atlassian/jira-jql-builder/src/common/types.tsx';
import JQLBuilder, {
	type SearchMode,
	type SearchModeSwitcherProps,
} from '@atlassian/jira-jql-builder/src/index.tsx';
import ToggleButtons from '@atlassian/jira-toggle-buttons/src/index.tsx';
import messages from './messages.tsx';

const SearchModeSwitcher = ({
	isBasicModeDisabled,
	onUpdateSearchMode,
	searchMode,
}: SearchModeSwitcherProps) => {
	const { formatMessage } = useIntl();

	const options = [
		{
			id: 'basic',
			label: isBasicModeDisabled ? (
				<Tooltip content={formatMessage(messages.basicModeDisabledTooltip)} tag="span">
					<Box as="span">{formatMessage(messages.basicMode)}</Box>
				</Tooltip>
			) : (
				formatMessage(messages.basicMode)
			),
			isDisabled: isBasicModeDisabled,
			testId: '',
		},
		{
			id: 'advanced',
			label: formatMessage(messages.advancedMode),
			testId: '',
		},
	];
	return (
		<ToggleButtons
			label={formatMessage(messages.label)}
			options={options}
			selectedOption={searchMode}
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			onChange={(value) => onUpdateSearchMode(value as SearchMode)}
		/>
	);
};

const EditorContainer = ({ children }: EditorContainerProps) => (
	<Stack xcss={editorContainerStyles}>{children}</Stack>
);

type Props = Omit<FieldProps<string>, 'onChange'> & {
	projectKeys: string[];
	onChange?: (value: string) => void;
};

export const Filters = (props: Props) => {
	const { projectKeys, onChange, value } = props;

	return (
		<JQLBuilder
			query={value || ''}
			analyticsSource="jpdRoadmapFilters"
			components={{ SearchModeSwitcher, EditorContainer }}
			userPreferencesData={null}
			onSearch={onChange}
			projectKeys={projectKeys}
			excludedFields={['project']}
			basicModeVisibleFields={[]}
			hideTextSearchInput
		/>
	);
};
const editorContainerStyles = xcss({ flex: 1 });

/**
 * @generated SignedSource<<66c8f3a46bf5d25e9be38ccee8bc4ca4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ConfigurableMenuItem$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Apps" | "Nav4Dashboards" | "Nav4Filters" | "Nav4Operations" | "Nav4Overviews" | "Nav4Plans" | "Nav4Projects">;
  readonly " $fragmentType": "ConfigurableMenuItem";
};
export type ConfigurableMenuItem$key = {
  readonly " $data"?: ConfigurableMenuItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConfigurableMenuItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ConfigurableMenuItem",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "Nav4Projects"
    },
    {
      "kind": "FragmentSpread",
      "name": "Nav4Filters"
    },
    {
      "kind": "FragmentSpread",
      "name": "Nav4Plans"
    },
    {
      "kind": "FragmentSpread",
      "name": "Nav4Apps"
    },
    {
      "kind": "FragmentSpread",
      "name": "Nav4Dashboards"
    },
    {
      "kind": "FragmentSpread",
      "name": "Nav4Operations"
    },
    {
      "kind": "FragmentSpread",
      "name": "Nav4Overviews"
    }
  ],
  "type": "Query"
};

(node as any).hash = "b29f7203e75d32305dac70f5397d151c";

export default node;

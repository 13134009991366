import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskReportsCustomEditRoute } from '@atlassian/jira-router-routes-servicedesk-reports-routes/src/servicedeskReportsCustomEditRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import CustomEdit, { LazyServicedeskReportsCustomEdit } from './ui/custom-edit-view/index.tsx';

export const servicedeskReportsCustomEditRouteEntry: Route = createEntry(
	servicedeskReportsCustomEditRoute,
	() => ({
		group: ROUTE_GROUPS_SERVICEDESK,
		perfMetricKey: 'service-management.reports.custom-edit-view',
		component: CustomEdit,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},
		resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyServicedeskReportsCustomEdit],
	}),
);

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { roadmapSettingsResource } from '@atlassian/jira-router-resources-roadmap/src/services/roadmap-settings/index.tsx';
import { projectSettingsSoftwareBoardsRoadmapRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-software-routes/src/projectSettingsSoftwareBoardsRoadmapRoute.tsx';
import { softwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { RoadmapSettingsRedirectToTimelineSettings } from './ui/index.tsx';
import { getSettingsNavigationResources } from './ui/project-settings/product-routes/common/index.tsx';
import { LazyRoadmap } from './ui/project-settings/product-routes/software/board-settings/roadmap/index.tsx';

export const projectSettingsSoftwareBoardsRoadmapRouteEntry: Route = createEntry(
	projectSettingsSoftwareBoardsRoadmapRoute,
	() => ({
		group: ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE,
		component: RoadmapSettingsRedirectToTimelineSettings,
		layout: softwareProjectLayoutNoOnboarding,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getSettingsNavigationResources(), roadmapSettingsResource],
		forPaint: [LazyAtlassianNavigation, LazyRoadmap],
	}),
);

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	delete: {
		id: 'directories.filters-v2.delete-modal.footer.delete',
		defaultMessage: 'Delete',
		description:
			'Label for the delete button that is located at the footer of the delete modal. Clicking this button will delete the filter.',
	},
	cancelButtonCaption: {
		id: 'directories.filters-v2.delete-modal.footer.cancel-button-caption',
		defaultMessage: 'Cancel',
		description:
			'Button that cancels all forms of action modals, e.g. to create, edit, or delete an entity',
	},
});

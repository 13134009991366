import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createDashboard: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-dashboards.common.action-buttons.create-dashboard',
		defaultMessage: 'Create dashboard',
		description:
			'Used as the alt text for an icon button that when used navigates to create dashboard',
	},
});

/**
 * @generated SignedSource<<9ef862bbad198437612764ee40840ba0>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4PlansForLanding$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PlansList" | "PlansListOld">;
  readonly " $fragmentType": "Nav4PlansForLanding";
};
export type Nav4PlansForLanding$key = {
  readonly " $data"?: Nav4PlansForLanding$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4PlansForLanding">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "Nav4PlansForLanding",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "PlansList"
    },
    {
      "kind": "FragmentSpread",
      "name": "PlansListOld"
    }
  ],
  "type": "Query"
};

(node as any).hash = "9426d3d41c02ec5eb5bb5841e78402ae";

export default node;

import React from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import type { Clause } from '@atlaskit/jql-ast';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import PickerTrigger from '../picker-trigger/main.tsx';

export const TriggerComponentFallback = ({ label = '' }: { label?: string | null }) => (
	<Container>
		<LabelContainer>{label}</LabelContainer>
	</Container>
);

type Props = {
	clause: Clause | undefined;
	fieldId: string;
	fieldDisplayName: string | undefined | null;
	fieldDescription: string | null;
	isIrremovable?: boolean;
};

const PickerFallback = ({
	clause,
	fieldId,
	fieldDisplayName,
	fieldDescription,
	isIrremovable,
}: Props) => (
	<PickerTrigger
		isInvalid={false}
		isSelected={false}
		hasValues={!!clause}
		onClick={noop}
		testId={`jql-builder-basic.ui.jql-editor.picker.filter-button.${fieldId}`}
		isLoading={!!clause}
		fieldDescription={fieldDescription}
		isIrremovable={isIrremovable}
	>
		<TriggerComponentFallback label={fieldDisplayName} />
	</PickerTrigger>
);

export default PickerFallback;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.span({
	display: 'inline-flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelContainer = styled.span({
	display: 'inline-flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${36 * gridSize}px`,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

import parameters from '@atlassian/jira-relay/src/__generated__/OverviewsContentViewQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = { cloudId: string };

export const asyncOverviewsContentViewQuery = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-nav4-overviews-content-view-query" */ './OverviewsContentViewQuery'
		).then(({ OverviewsContentViewQuery }) => OverviewsContentViewQuery),
	),
	getPreloadProps: ({ cloudId }: EntryPointParams) => ({
		queries: {
			queryRef: {
				parameters,
				variables: {
					cloudId,
				},
			},
		},
	}),
});

import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { Nav4FilterActions } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-filters/src/common/ui/filter-actions/Nav4FilterActions.tsx';
import type { Nav4FilterMenuItem$key } from '@atlassian/jira-relay/src/__generated__/Nav4FilterMenuItem.graphql';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { SimpleFilterMenuItem } from '../simple-filter-menu-item/index.tsx';

type Nav4FilterMenuItemProps = {
	queryRef: Nav4FilterMenuItem$key;
	onRefetch: (options: { onComplete: () => void }) => void;
	onClick: () => void;
};

export function Nav4FilterMenuItem({ queryRef, onRefetch, onClick }: Nav4FilterMenuItemProps) {
	const data = useFragment<Nav4FilterMenuItem$key>(
		graphql`
			fragment Nav4FilterMenuItem on JiraFilter {
				filterId
				name
				...Nav4FilterActions
			}
		`,
		queryRef,
	);

	const isAnonymous = useIsAnonymous();

	return (
		<SimpleFilterMenuItem
			actionsOnHover={!isAnonymous && <Nav4FilterActions queryRef={data} onRefetch={onRefetch} />}
			filterId={data.filterId}
			name={data.name}
			onClick={onClick}
		/>
	);
}

import React, { useCallback, forwardRef, useMemo, type Ref } from 'react';
import ShowMoreIcon from '@atlaskit/icon/core/show-more-horizontal';
import { MenuGroup, Section, LinkItem } from '@atlaskit/menu';
import type { TriggerProps, ContentProps } from '@atlaskit/popup';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { GrowthRecommendationsEntrypoint } from '@atlassian/jira-navigation-apps-sidebar-nav4-growth-recommendations/entrypoint.tsx';
import type { CustomTriggerComponentType } from '@atlassian/jira-navigation-project-action-menu/src/types.tsx';
import {
	MenuContainerOld,
	MenuContainer,
} from '@atlassian/jira-navigation-project-action-menu/src/ui/menu-container/index.tsx';
import { MenuContent } from '@atlassian/jira-navigation-project-action-menu/src/ui/menu-container/MenuContent.tsx';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';
import messages from './messages.tsx';

type Props = {
	onClick?: () => void;
	onLearnMoreClicked: () => void;
	onDismissClicked: () => void;
	body: React.ReactNode | string;
	primaryCta: string;
	title: string;
};

const MENU_TEST_ID = 'recommendations-common.ui.recommendation-popup-menu.menu-group';

const CustomTrigger: CustomTriggerComponentType = forwardRef<HTMLButtonElement, TriggerProps>(
	({ ...props }, ref) => {
		const { formatMessage } = useIntl();

		return (
			<ThemedButton
				{...props}
				ref={ref}
				appearance="subtle"
				iconBefore={
					<ShowMoreIcon
						label={formatMessage(messages.moreActions)}
						color="currentColor"
						spacing="spacious"
					/>
				}
				testId="navigation-apps-sidebar-nav4-sidebars-content-growth-recommendations.common.ui.recommendation-action-menu.themed-button"
			/>
		);
	},
);

type LearnMoreTriggerProps = {
	onLearnMoreClicked: () => void;
};

const LearnMoreTrigger = forwardRef(
	({ onLearnMoreClicked }: LearnMoreTriggerProps, ref: Ref<HTMLButtonElement> | undefined) => {
		const { formatMessage } = useIntl();

		return (
			<LinkItem ref={ref} onClick={onLearnMoreClicked}>
				{formatMessage(messages.whyAmISeeingThis)}
			</LinkItem>
		);
	},
);

export const RecommendationActionMenu = ({
	onClick,
	onLearnMoreClicked,
	onDismissClicked,
	body,
	primaryCta,
	title,
}: Props) => {
	const { formatMessage } = useIntl();

	const entryPointParams = useMemo(
		() => ({
			body,
			primaryCta,
			title,
		}),
		[body, primaryCta, title],
	);
	const modalProps = useMemo(
		() => ({
			width: 400,
			height: 440,
		}),
		[],
	);

	const MenuItems = useCallback(
		() => (
			<MenuGroup testId={MENU_TEST_ID}>
				<Section isList>
					<LinkItem onClick={() => onDismissClicked()}>
						{formatMessage(messages.dismissButtonText)}
					</LinkItem>
					<ModalEntryPointPressableTrigger
						entryPoint={GrowthRecommendationsEntrypoint}
						entryPointProps={entryPointParams}
						modalProps={modalProps}
						useInternalModal={false}
					>
						{({ ref }) => (
							<LearnMoreTrigger
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								ref={ref as Ref<HTMLButtonElement>}
								onLearnMoreClicked={onLearnMoreClicked}
							/>
						)}
					</ModalEntryPointPressableTrigger>
				</Section>
			</MenuGroup>
		),
		[entryPointParams, formatMessage, modalProps, onDismissClicked, onLearnMoreClicked],
	);

	const content = (contentProps: ContentProps) => (
		<MenuContent {...contentProps} MenuItems={MenuItems} />
	);

	return fg('use-relay-entrypoints-for-project-actions-menu') ? (
		<MenuContainer content={content} onClick={onClick} CustomTrigger={CustomTrigger} />
	) : (
		<MenuContainerOld MenuItems={MenuItems} onClick={onClick} CustomTrigger={CustomTrigger} />
	);
};

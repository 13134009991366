import { createResource } from 'react-resource-router';

import { getListInfo } from '../endpoints';

export const globalEscalationsListInfoResource = createResource({
	type: 'globalEscalationsListInfoResource',
	getKey: () => 'globalEscalationsListInfoResource',
	getData: getListInfo,
	isBrowserOnly: true,
});

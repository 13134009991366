import React, { type ReactNode } from 'react';
import { createContainer, createHook, createStore } from '@atlassian/react-sweet-state';
import type { IrremovableFields } from '../../utils/irremovable-fields/index.tsx';
import { useClauseMap } from '../ast/index.tsx';
import type { Store, Props } from './types.tsx';
import { getClausesData } from './utils.tsx';

type Actions = {};
const actions = Object.freeze<Actions>({});

const store = createStore<Store, Actions>({
	initialState: {
		astClauseOrder: [],
		clausesByFieldId: {},
		secondaryClausesByFieldId: {},
	},
	actions,
	name: 'JQLBasic-SeachStateService',
});

const Container = createContainer<Store, Actions, Props>(store, {
	onInit:
		() =>
		({ setState }, props) => {
			setState(getClausesData(props));
		},

	onUpdate:
		() =>
		({ setState }, props) => {
			setState(getClausesData(props));
		},
});

export type WrapperProps = {
	excludedFields?: string[];
	irremovableFields: IrremovableFields;
	children: ReactNode;
};

const DEFAULT_EXCLUDED_FIELDS: string[] = [];

export const SearchStateContainer = ({
	excludedFields = DEFAULT_EXCLUDED_FIELDS,
	irremovableFields,
	children,
}: WrapperProps) => {
	const [clauseMap] = useClauseMap();

	return (
		<Container
			clauseMap={clauseMap}
			excludedFields={excludedFields}
			irremovableFields={irremovableFields}
		>
			{children}
		</Container>
	);
};

export const useSearchState = createHook(store);

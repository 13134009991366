import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SUPPORT_CENTER_SETTINGS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { NORMAL_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { agentStudioRoute } from '@atlassian/jira-router-routes-agent-studio-routes/src/agentStudioRoute.tsx';
import { AsyncSupportCenterHorizontalNav } from '@atlassian/jira-support-center-settings-agent-studio/src/ui/horizontal-nav/index.tsx';

import { AgentStudioOverviewPage, LazyAgentStudioOverviewPage } from './ui/index.tsx';

export const agentStudioRouteEntry = createEntry(agentStudioRoute, () => ({
	group: ROUTE_GROUPS_SUPPORT_CENTER_SETTINGS,
	component: AgentStudioOverviewPage,
	layout: globalLayout,
	navigation: {
		horizontal: AsyncSupportCenterHorizontalNav,
	},
	resources: [...getNavigationResources()],
	forPaint: [LazyAtlassianNavigation, LazyAgentStudioOverviewPage],
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
}));

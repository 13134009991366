import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	adminSettings: {
		defaultMessage: 'Admin configuration',
		id: 'navigation-apps-sidebar-nav4-plans.plans.common.action-buttons.more-menu-button.admin-settings',
		description:
			'Used as the button tooltip for the admin configuration cog button. Item navigates to the Plans settings page',
	},
	plansMoreMenu: {
		defaultMessage: 'Plans',
		id: 'navigation-apps-sidebar-nav4-plans.plans.common.action-buttons.more-menu-button.plans-more-menu',
		description: 'Used as label for the plans more menu button',
	},
	createDemoPlan: {
		defaultMessage: 'Create demo plan',
		id: 'navigation-apps-sidebar-nav4-plans.plans.common.action-buttons.more-menu-button.create-demo-plan',
		description: 'Used as the menu-title for the Create demo plan menu link item',
	},
});

import React, { Component } from 'react';
import type { Store } from 'redux';
import noop from 'lodash/noop';
import { AnalyticsSource } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import AppBase from '@atlassian/jira-app-base/src/index.tsx';
import AppStyle from '@atlassian/jira-common-components-app-style/src/index.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { MODAL } from '@atlassian/jira-product-analytics-bridge';
import type { Props } from './model/index.tsx';
import rootEpic from './ops/index.tsx';
import { setAppProps, getAssociatedItems } from './state/actions.tsx';
import createStore from './state/index.tsx';
import type { State } from './state/types.tsx';
import View from './view/index.tsx';

// eslint-disable-next-line jira/react/no-class-components
class DeleteModal extends Component<Props> {
	static defaultProps = {
		onInitialised: noop,
		onFilterDeleted: noop,
		onCancel: noop,
	};

	constructor(props: Props) {
		super(props);

		this.store = createStore(rootEpic);
		this.store.dispatch(setAppProps(this.props));
		this.store.dispatch(getAssociatedItems());
	}

	store: Store<State>;

	render() {
		return (
			<AppBase store={this.store} hasErrorBoundary={false}>
				<ReportErrors id="unhandled" packageName="jiraDeleteFiltersModal" teamName="jira-cosmos">
					<AppStyle>
						<View />
					</AppStyle>
				</ReportErrors>
			</AppBase>
		);
	}
}

export default AnalyticsSource('deleteFilter', MODAL)(DeleteModal);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useDeleteChangeActions } from './controllers/delete-change-actions';

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_DIRECTORIES } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { filtersDirectoryResource } from '@atlassian/jira-router-resources-directories-filters/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { secureFiltersDirectoryRedirectRoute } from '@atlassian/jira-router-routes-directories-routes/src/secureFiltersDirectoryRedirectRoute.tsx';
import { FiltersDirectorySkeleton } from '@atlassian/jira-skeletons/src/ui/directories/FiltersDirectorySkeleton.tsx';
import {
	FiltersDirectoryComponent,
	FiltersDirectoryRedirect,
	LazyFiltersDirectory,
} from './ui/filters/index.tsx';

export const secureFiltersDirectoryRedirectRouteEntry = createEntry(
	secureFiltersDirectoryRedirectRoute,
	() => ({
		group: ROUTE_GROUPS_DIRECTORIES,
		resources: [...getNavigationResources(), filtersDirectoryResource],
		component: !__SERVER__ ? FiltersDirectoryRedirect : FiltersDirectoryComponent,
		skeleton: FiltersDirectorySkeleton,
		layout: globalLayout,

		navigation: {
			menuId: MENU_ID.FILTERS,
		},

		forPaint: [LazyAtlassianNavigation, LazyFiltersDirectory],
		isRedirect: true,
	}),
);

import { ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_DETAILS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';
import { jsmProjectSettingsUrl } from './common/index.tsx';

export const projectSettingsServicedeskDetailsRoute: InvariantRoute = {
	name: ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_DETAILS,
	// /settings/details
	path: `${jsmProjectSettingsUrl}/details`,
	exact: true,
};

import { JiraProjectOverviewAri } from '@atlassian/ari/jira/project-overview';
import { mapOverviewFragment } from '@atlassian/jira-business-entity-overview-utils/src/utils.tsx';
import { GraphQLErrors } from '@atlassian/jira-business-graphql-errors/src/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { QUERY } from './gql.tsx';
import {
	SUCCESS_RESPONSE_TYPE,
	type OverviewError,
	type OverviewSuccess,
	type OverviewDetailsResponse,
} from './types.tsx';

export const fetchOverviewDetails = async (
	cloudId: string,
	activationId: string,
	overviewId?: string | null,
) => {
	if (!overviewId) {
		return null;
	}

	try {
		const response = await performPostRequest<OverviewDetailsResponse>('/gateway/api/graphql', {
			body: JSON.stringify({
				query: QUERY,
				variables: {
					id: JiraProjectOverviewAri.create({
						siteId: cloudId,
						activationId,
						overviewId,
					}).toString(),
				},
			}),
			headers: {
				'content-type': 'application/json',
				'X-ExperimentalApi': 'projectStyle',
			},
		});

		if (response?.data?.jira?.overview?.__typename === SUCCESS_RESPONSE_TYPE) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return mapOverviewFragment(response.data.jira.overview as OverviewSuccess);
		}

		throw new GraphQLErrors(
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(response?.data?.jira?.overview as OverviewError) ?? response.errors,
		);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		// 410 means that overviews have been migrated to plans and
		// we will show a different message on the not found page.
		if (error?.statusCode === 410) {
			throw error;
		}

		log.safeErrorWithoutCustomerData(
			'jiraRouterResourcesBusinessOverviewDetails',
			'Something went wrong while fetching business overview details',
		);

		const isClientError = error?.statusCode >= 400 && error?.statusCode < 500;
		if (!isClientError) {
			fireErrorAnalytics({
				meta: {
					id: 'overviewDetailsResource',
					packageName: 'jiraRouterResourcesBusinessOverviewDetails',
					teamName: 'wanjel',
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});

			throw error;
		}
	}

	return null;
};

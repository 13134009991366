import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { MessageDescriptor } from '@atlassian/jira-intl';
import { TEAM_NAME, BOARD_CREATE_PACKAGE_NAME, BOARD_TYPES } from './constants.tsx';
import commonMessages from './messages.tsx';

type Props = {
	attributes?: {
		[key: string]: string | number | undefined | null;
	};
	id: string;
	error: Error;
};

export const fireBoardCreateErrorAnalytics = ({ attributes, id, error }: Props) => {
	fireErrorAnalytics({
		error,
		attributes,
		meta: {
			id: `jiraSoftwareBoardCreate_${id}`,
			packageName: BOARD_CREATE_PACKAGE_NAME,
			teamName: TEAM_NAME,
		},
	});
};

export const descriptionMapping: {
	[key: string]: MessageDescriptor;
} = {
	[BOARD_TYPES.KANBAN]: commonMessages.kanbanBoardCreationDescription,
	[BOARD_TYPES.SCRUM]: commonMessages.scrumBoardCreationDescription,
};

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { projectSettingsSoftwareProformaJiraFormBuilderRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-software-routes/src/projectSettingsSoftwareProformaJiraFormBuilderRoute.tsx';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout/src/index.tsx';
import { getSettingsNavigationResources } from './ui/project-settings/product-routes/common/index.tsx';
import ProformaJiraFormBuilder, {
	LazyProjectSettingsProformaJiraFormBuilder,
} from './ui/project-settings/proforma-jira-form-builder/index.tsx';

export const projectSettingsSoftwareProformaJiraFormBuilderRouteEntry = createEntry(
	projectSettingsSoftwareProformaJiraFormBuilderRoute,
	() => ({
		group: ROUTE_GROUPS_PROJECT_SETTINGS_SOFTWARE,
		component: ProformaJiraFormBuilder,
		layout: softwareProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getSettingsNavigationResources()],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsProformaJiraFormBuilder],
		perfMetricKey: 'form-builder',
	}),
);

import { ISSUE_VIEW } from '@atlassian/jira-capabilities/src/constants.tsx';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { ROUTE_GROUPS_ISSUE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { NORMAL_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { issueViewRelayResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-agg-data/index.tsx';
import { issueForgeDataResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-forge-data/index.tsx';
import { issueGiraDataResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-gira-data/index.tsx';
import { issueGiraNonCriticalDataResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-gira-non-critical-data/index.tsx';
import { getModernIssueTransitionResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { browseIssueEmbedRoute } from '@atlassian/jira-router-routes-issue-routes/src/browseIssueEmbedRoute.tsx';
import { workspaceResource } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/services/resources/index.tsx';
import { IssueViewSkeleton } from '@atlassian/jira-skeletons/src/ui/issue/IssueViewSkeleton.tsx';
import {
	conditionalSmartRepliesOptInStatusResources,
	LazyIssuePage,
	IssueEmbedComponent,
	getUnusedEmbedRouteResources,
} from './ui/index.tsx';

export const browseIssueEmbedRouteEntry = createEntry(browseIssueEmbedRoute, () => ({
	group: ROUTE_GROUPS_ISSUE,
	shouldOptOutConcurrentMode: fg('jira-concurrent-incremental-opt-out-issue-view'),
	preloadOptions: {
		earlyChunks: ['issue-app-early-entry'],
	},
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
	component: IssueEmbedComponent,
	skeleton: IssueViewSkeleton,
	layout: chromelessLayout,

	resources: [
		...getModernIssueTransitionResources(),
		...getUnusedEmbedRouteResources(),
		issueGiraDataResource,
		issueGiraNonCriticalDataResource,
		issueForgeDataResource,
		issueViewRelayResource,
		workspaceResource,
		themePreferenceResource,
		conditionalSmartRepliesOptInStatusResources,
	],
	forPaint: [LazyIssuePage],
	ufoName: 'issue-view-embed',
	meta: {
		capability: ISSUE_VIEW,
	},
	bifrost: {
		bundling: {
			packageName: '@atlassian/jira-router-routes-issue-entries/src/browseIssueEmbedRouteEntry.tsx',
			whichExportToUse: 'browseIssueEmbedRouteEntry',
		},
		enabled: true,
	},
}));

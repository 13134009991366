import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { CustomerServiceMenuLinkItem } from './ui/index.tsx';

export const CustomerService = () => (
	<JSErrorBoundary
		fallback="flag"
		id="assets"
		packageName="jiraNavigationAppsSidebarNav4SidebarsContentCustomerService"
		teamName="Boysenberry"
	>
		<CustomerServiceMenuLinkItem />
	</JSErrorBoundary>
);

import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { NavItemsContext } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/controllers/context.tsx';
import { getConfigurableMenuIds } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-configurable-menu-ids/index.tsx';
import type { ConfigurableMenuItems$key } from '@atlassian/jira-relay/src/__generated__/ConfigurableMenuItems.graphql';
import { ConfigurableMenuItem } from '../../common/ui/configurable-menu-item/ConfigurableMenuItem.tsx';
import { useFilterL1MenuItemsByEditionAndLicense } from '../../controllers/use-filter-l1-menu-items-by-edition-and-license/index.tsx';
import { useRefreshL1MenuItems } from '../../controllers/use-refresh-l1-menu-items/index.tsx';
import { CustomiseSidebar } from './customise-sidebar/index.tsx';
import { MoreMenuIsOpenContextProvider } from './more-menu-is-open-context-provider/index.tsx';
import { MoreMenuItem } from './more-menu-item/MoreMenuItem.tsx';
import { PeekMenuIdContextProvider } from './peek-menu-id-context-provider/index.tsx';

export type ConfigurableMenuItemsProps = {
	menuData: ConfigurableMenuItems$key;
};

export function ConfigurableMenuItems({ menuData }: ConfigurableMenuItemsProps) {
	const data = useFragment<ConfigurableMenuItems$key>(
		graphql`
			fragment ConfigurableMenuItems on Query {
				jira @optIn(to: "JiraUserNavConfig") {
					userNavigationConfiguration(cloudID: $cloudId, navKey: "NAV_SIDEBAR") {
						navItems {
							# eslint-disable-next-line @atlassian/relay/unused-fields
							menuId @required(action: THROW)
							# eslint-disable-next-line @atlassian/relay/unused-fields
							isVisible @required(action: THROW)
						}
					}
				}
				...ConfigurableMenuItem
			}
		`,
		menuData,
	);

	const navItems = useFilterL1MenuItemsByEditionAndLicense(
		// Add back any missing menu ids before filtering in case licensing has changed or a new L1 has been created.
		// This also handles when a user has no configuration yet.
		useRefreshL1MenuItems(data.jira?.userNavigationConfiguration?.navItems),
	);

	const navItemsContext = useMemo(
		() => ({
			navItems,
			visibleMenuIds: getConfigurableMenuIds(navItems, true),
			moreMenuIds: getConfigurableMenuIds(navItems, false),
		}),
		[navItems],
	);

	return (
		<NavItemsContext.Provider value={navItemsContext}>
			<PeekMenuIdContextProvider>
				<MoreMenuIsOpenContextProvider>
					{navItemsContext.visibleMenuIds.map((menuId) => (
						<ConfigurableMenuItem key={menuId} menuData={data} menuId={menuId} />
					))}
					<MoreMenuItem
						customiseSidebar={<CustomiseSidebar navItems={navItems} />}
						menuData={data}
					/>
				</MoreMenuIsOpenContextProvider>
			</PeekMenuIdContextProvider>
		</NavItemsContext.Provider>
	);
}

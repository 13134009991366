import type { EmojiProvider } from '@atlaskit/emoji';
import getMeta from '@atlassian/jira-get-meta';

const providersRepository: {
	[key: string]: Promise<EmojiProvider>;
} = {};
export const EMOJI_SERVICEID = 'emoji';
export const EMOJI_WITH_CUSTOM_SERVICEID = 'emoji-with-custom';
export const EMOJI_WITH_CUSTOM_UPLOAD_DISABLED_SERVICEID = 'emoji-with-custom-upload-disabled';
const getServiceBaseUrl = (serviceId: string) => {
	if (
		serviceId === EMOJI_WITH_CUSTOM_SERVICEID ||
		serviceId === EMOJI_WITH_CUSTOM_UPLOAD_DISABLED_SERVICEID
	) {
		return getMeta('ajs-fabric-emoji-url');
	}
	return getMeta(`ajs-fabric-${serviceId}-url`);
};
/**
 * Retrieves or creates a service provider instance for a given service ID, ensuring each ID uses a single provider instance.
 */
export const getServiceProvider = (
	serviceId: string,
	serviceProviderGenerator: (arg1: string) => Promise<EmojiProvider>,
) => {
	try {
		let serviceProvider = providersRepository[serviceId];
		if (serviceProvider === undefined) {
			const serviceBaseUrl = getServiceBaseUrl(serviceId);
			if (serviceBaseUrl) {
				serviceProvider = Promise.resolve(serviceProviderGenerator(serviceBaseUrl));
				providersRepository[serviceId] = serviceProvider;
			}
		}
		return serviceProvider;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		return null;
	}
};
// This is only for testing purpose
// Reset providersRepository to prevent the implicit state sharing between tests.
export const clearProvidersCache = () => {
	Object.keys(providersRepository).forEach((key) => {
		delete providersRepository[key];
	});
};
export default getServiceBaseUrl;

/**
 * @generated SignedSource<<429e02d286b04c7accf7e80d441f187a>>
 * @relayHash 23128c7f975773e696f5dc815605552c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8bd2c50d7613ef27c0c7f5d9ff9b9f059c7e8c6add7f7091c71b35680d18da7b

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_growthRecommendationsInProjectList_RecommendationsInProjectListQuery } from './src_growthRecommendationsInProjectList_RecommendationsInProjectListQuery.graphql';

const node: PreloadableConcreteRequest<src_growthRecommendationsInProjectList_RecommendationsInProjectListQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "8bd2c50d7613ef27c0c7f5d9ff9b9f059c7e8c6add7f7091c71b35680d18da7b",
    "metadata": {},
    "name": "src_growthRecommendationsInProjectList_RecommendationsInProjectListQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_ARCHIVE_ISSUES } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { AsyncHorizontalNavJiraSoftware as AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticSoftwareProjectHorizontalNavResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/software-horizontal-navigation/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResourceOnNav3OnlyNonCriticalRoutes } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { archivedIssuesJswCompanyRoute } from '@atlassian/jira-router-routes-archives-routes/src/archivedIssuesJswCompanyRoute.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { Archives, LazyArchives, getCommonProjectLayout } from './ui/index.tsx';

export const archivedIssuesJswCompanyRouteEntry: Route = createEntry(
	archivedIssuesJswCompanyRoute,
	() => ({
		group: ROUTE_GROUPS_ARCHIVE_ISSUES,
		// Refer: projectIssueNavigatorClassicRoute from issue-navigator
		component: componentWithFF('issues-archive-route_a4t84', Archives, ErrorPagesNotFound),

		layout: getCommonProjectLayout(),

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			horizontal: AsyncHorizontalNavJira,
			onlyShowHorizontalOnNav4: true,
		},

		resources: [
			...getNavigationResources(), // these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
			incidentExperimentTraitResourceOnNav3OnlyNonCriticalRoutes,
			projectContextResource,
			forgeProjectModuleResource,
			staticSidebarShortcutsResource,
			staticProjectSidebarNavigationResource,
			staticProjectSidebarConnectAddonsNavigationResource,
			staticSoftwareProjectHorizontalNavResource,
			// end of resources expanded from getNavigationSidebarProjectResource
		],
		forPaint: [LazyAtlassianNavigation, LazyArchives],
	}),
);

import React, { useState, useCallback } from 'react';
import noop from 'lodash/noop';
import PersonIcon from '@atlaskit/icon/core/person';
import { ButtonItem } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import {
	type JiraProjectType,
	convertToProjectType,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import AsyncProjectAddPeopleModal, {
	type Props as AddPeopleModalProps,
} from '@atlassian/jira-project-add-people/src/async.tsx';
import messages from './messages.tsx';

type AddPeopleProps = Pick<AddPeopleModalProps, 'isAdmin' | 'projectId' | 'projectKey'>;

type Props = AddPeopleProps & {
	projectType: JiraProjectType;
	onClick?: () => void;
};

export const AddPeopleButton = ({
	isAdmin,
	projectId,
	projectKey,
	projectType,
	onClick,
}: Props) => {
	const { formatMessage } = useIntl();

	const [isOpen, setIsOpen] = useState(false);
	const handleOnClick = useCallback(() => {
		setIsOpen(true);
		onClick?.();
	}, [onClick]);
	const handleOnClose = useCallback(() => setIsOpen(false), []);

	return (
		<>
			<ButtonItem
				iconBefore={<PersonIcon label="" spacing="spacious" color={token('color.icon')} />}
				onClick={handleOnClick}
			>
				{formatMessage(messages.addPeople)}
			</ButtonItem>
			<AsyncProjectAddPeopleModal
				isAdmin={isAdmin}
				isOpen={isOpen}
				projectId={projectId}
				projectKey={projectKey}
				allowFlags
				projectType={convertToProjectType(projectType)}
				analyticsPrefix="sidebar-nav4-add-people"
				onStopPerfMetric={noop}
				onAddSuccess={noop}
				onClose={handleOnClose}
				onError={noop}
			/>
		</>
	);
};

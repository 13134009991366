import { ROUTE_NAMES_DASHBOARD_EDIT } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

// IMPORTANT - must precede the view route due to substring path match
export const dashboardEditRoute: InvariantRoute = {
	name: ROUTE_NAMES_DASHBOARD_EDIT,
	path: '/jira/dashboards/:dashboardId(\\d+)/edit',
	query: ['maximized?'],
	exact: true,
};

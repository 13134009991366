import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	whyAmISeeingThis: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-projects-core.common.recommendations.common.recommendation-action-menu.why-am-i-seeing-this',
		defaultMessage: 'Why am I seeing this?',
		description: 'The label for the link that navigates to the [why am i seeing this] modal',
	},
	moreActions: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-projects-core.common.recommendations.common.recommendation-action-menu.more-actions',
		defaultMessage: 'More actions',
		description:
			'Used as the alt text for an icon button that when used shows a popup menu of more options.',
	},
	dismissButtonText: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-projects-core.common.recommendations.common.recommendation-action-menu.dismiss-button-text',
		defaultMessage: 'Not interested',
		description:
			'Text for the "Not interested" button in the "setup required" section of the projects dropdown',
	},
	reasonsModalTitle: {
		id: 'atlassian-navigation-recommendations.jpd-recommendation.reasons-modal-title',
		defaultMessage: 'Why am I seeing this?',
		description: 'Title of the reasons modal',
	},
	reasonsModalExplanation: {
		id: 'atlassian-navigation-recommendations.jpd-recommendation.reasons-modal-explanation',
		defaultMessage:
			"We think <strong>Jira Product Discovery</strong> will improve your team's productivity. The recommendation is based on:",
		description: 'Explanation as to why the placeholder template is being shown',
	},
	reasonsModalBulletOneSpork: {
		id: 'atlassian-navigation-recommendations.jpd-recommendation.reasons-modal-bullet-one-spork',
		defaultMessage: 'the tools you use (Jira)',
		description: 'First bullet point of the reasons modal',
	},
	reasonsModalBulletTwo: {
		id: 'atlassian-navigation-recommendations.jpd-recommendation.reasons-modal-bullet-two',
		defaultMessage: 'the way you use your existing tools and products',
		description: 'Second bullet point of the reasons modal',
	},
	privacyPolicy: {
		id: 'atlassian-navigation-recommendations.jpd-recommendation.privacy-policy',
		defaultMessage: 'Learn more about your data privacy with Atlassian',
		description: "Link to Atlassian's privacy policy",
	},
	reasonsModalButtonOk: {
		id: 'atlassian-navigation-recommendations.jpd-recommendation.reasons-modal-button-ok',
		defaultMessage: 'Ok',
		description: 'Ok text for the reasons modal',
	},
});

import React from 'react';
import type ProjectSettingsJiraAutomationType from '@atlassian/jira-spa-apps-project-settings-jira-automation/src/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyProjectSettingsJiraAutomation = lazyForPaint<
	typeof ProjectSettingsJiraAutomationType
>(
	() =>
		import(
			/* webpackChunkName: "async-project-settings-jira-automation", jiraSpaEntry: "async-project-settings-jira-automation" */ '@atlassian/jira-spa-apps-project-settings-jira-automation'
		),
);

export const AutomationLazyPage = () => (
	<LazyPage
		Page={LazyProjectSettingsJiraAutomation}
		pageId="project-settings-jira-automation"
		shouldShowSpinner
	/>
);

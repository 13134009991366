// eslint-disable-next-line jira/import-whitelist
import {
	getTeamsResource as platformGetTeamsResource,
	getTeams,
} from '@atlassian/eoc-overview/resources';
import { fg } from '@atlassian/jira-feature-gating';
import { createResource } from '@atlassian/react-resource-router';
// eslint-disable-next-line jira/import-whitelist
import { checkIsOpsAdmin } from '@atlassian/jira-eoc-tenant-info/src/controllers/utils.tsx';
// eslint-disable-next-line jira/import/no-restricted-import
import { CONSOLIDATION_STATE_RESOURCE_TYPE } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/constants.tsx';
// eslint-disable-next-line jira/import/no-restricted-import
import type { TenantInfo } from '@atlassian/jira-router-resources-eoc-tenant-info/types.tsx';

export const getTeamsResource = createResource({
	type: platformGetTeamsResource.type,
	getKey: platformGetTeamsResource.getKey,
	getData: async (
		{ dependencies },
		{ tenantContext: { atlassianAccountId, isSiteAdmin, isAdmin } },
	) => {
		if (!atlassianAccountId) {
			return null;
		}
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const tenantInfo = (await dependencies[CONSOLIDATION_STATE_RESOURCE_TYPE]
			?.promise) as TenantInfo;
		const isOpsAdmin = checkIsOpsAdmin(tenantInfo);
		const shouldSeeAllTeams = fg('operations-tenant-info-endpoint-update')
			? isSiteAdmin || isAdmin || isOpsAdmin
			: isSiteAdmin || isAdmin;

		return getTeams({
			queryParams: {
				accountId: !shouldSeeAllTeams ? atlassianAccountId : undefined,
				showAvatar: true,
			},
		});
	},
	isBrowserOnly: true,
	maxAge: 0,
});

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, type Ref } from 'react';
import { useSidebarNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-context/index.tsx';
import { useSidebarNav4Controller } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-controller-context/index.tsx';
import MenuLinkItem, {
	type MenuLinkItemWithAnalyticsProps,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/menu-link-item-with-analytics/index.tsx';
import { getTestId } from '../../utils/get-test-id/index.tsx';

export type Nav4MenuLinkItemProps<RouterLinkConfig extends Record<string, any> = never> = Omit<
	MenuLinkItemWithAnalyticsProps<RouterLinkConfig>,
	'isSelected'
> & {
	menuId: string;
};

/**
 * Nav4MenuLinkItem provides `menuId` instead of `isSelected`,
 * which is used to set the selected-state of the underlying
 * MenuLinkItem, using the useSidebarNav4() context-hook.
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const Nav4MenuLinkItem = forwardRef(_Nav4MenuLinkItem) as <
	RouterLinkConfig extends Record<string, any> = never,
>(
	props: Nav4MenuLinkItemProps<RouterLinkConfig> & {
		ref?: Ref<HTMLAnchorElement>;
	},
) => ReturnType<typeof MenuLinkItem>;

function _Nav4MenuLinkItem<RouterLinkConfig extends Record<string, any> = never>(
	props: Nav4MenuLinkItemProps<RouterLinkConfig>,
	ref: Ref<HTMLAnchorElement>,
) {
	const { menuId } = props;
	const { isSelectedPath } = useSidebarNav4();
	const { isControlledPath } = useSidebarNav4Controller();

	return (
		<MenuLinkItem<RouterLinkConfig>
			ref={ref}
			{...props}
			isSelected={isSelectedPath(menuId) || isControlledPath(menuId)}
			testId={getTestId(menuId)}
		/>
	);
}

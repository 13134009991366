import React from 'react';
import ArrowUpRightIcon from '@atlaskit/icon/core/migration/arrow-up-right--open';
import JSMAssetsDataManagerIcon from '@atlassian/jira-icons/src/ui/jsm-assets-data-manager/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ASSETS_DATA_MANAGER } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-id.tsx';
import { useFormattedMessageAndIcon } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-formatted-message-and-icon/index.tsx';
import { getDataManagerUrl } from '@atlassian/jira-servicedesk-insight-urls/src/index.tsx';
import { MenuLinkItem } from '@atlassian/navigation-system';
import { useAssetsUIAnalytics } from '../../use-assets-ui-analytics/index.tsx';
import messages from './messages.tsx';

export const DataManagerMenuItems = () => {
	const { formatMessage } = useIntl();

	const { icon: formattedOpenIcon } = useFormattedMessageAndIcon(
		messages.jsmAssetsOpenDataManager,
		ArrowUpRightIcon,
	);
	const { icon: formattedDataManagerIcon } = useFormattedMessageAndIcon(
		messages.jsmAssetsDataManager,
		JSMAssetsDataManagerIcon,
	);

	const { fireUINavigationItemAnalytics } = useAssetsUIAnalytics();

	return (
		<MenuLinkItem
			elemBefore={formattedDataManagerIcon}
			elemAfter={formattedOpenIcon}
			href={getDataManagerUrl()}
			target="_blank"
			onClick={() => fireUINavigationItemAnalytics(ASSETS_DATA_MANAGER)}
		>
			{formatMessage(messages.jsmAssetsDataManager)}
		</MenuLinkItem>
	);
};

import React from 'react';
import { CreatePlanMenuButton } from './create-plan-menu-button/CreatePlanMenuButton.tsx';
import { MoreMenuButton } from './more-menu-button/MoreMenuButton.tsx';

type Props = {
	hasEditPermissions: boolean;
	hasCreateSamplePlanPermissions: boolean;
	hasGlobalPlansAdminPermissions: boolean;
};

export const Nav4PlansActionButtons = ({
	hasEditPermissions,
	hasCreateSamplePlanPermissions,
	hasGlobalPlansAdminPermissions,
}: Props) => {
	return (
		<>
			{hasEditPermissions && <CreatePlanMenuButton />}
			<MoreMenuButton
				hasCreateSamplePlanPermissions={hasCreateSamplePlanPermissions}
				hasGlobalPlansAdminPermissions={hasGlobalPlansAdminPermissions}
			/>
		</>
	);
};

import React, { useMemo } from 'react';
import noop from 'lodash/noop';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { useTextSearchInputClause } from '../../../../controllers/ast/index.tsx';
import TextFieldType from '../../../../controllers/field-type/text/index.tsx';
import { removeSurroundingQuotes } from '../../../../utils/remove-surrounding-quotes.tsx';
import messages from '../../../messages.tsx';
import TextField from '../../text-field/index.tsx';

export const TextSearchInputLoading = () => {
	const intl = useIntl();

	const [textSearchInputClause] = useTextSearchInputClause();

	const value = useMemo(
		() => removeSurroundingQuotes(TextFieldType.value(textSearchInputClause) || ''),
		[textSearchInputClause],
	);

	return (
		<TextField
			label={intl.formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.refinementSearchIssueTermRefresh
					: messages.refinementSearch,
			)}
			value={value}
			onChange={noop}
			isDisabled
		/>
	);
};

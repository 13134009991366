import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { servicedeskCmdbInsightConfigureRoute } from '@atlassian/jira-router-routes-servicedesk-cmdb-routes/src/servicedeskCmdbInsightConfigureRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { sharedRouteProps } from './common/constants.tsx';
import { getCmdbResources } from './common/getCmdbResources.tsx';
import InsightConfigure, { LazyServicedeskCmdbConfigure } from './ui/configure/index.tsx';

export const servicedeskCmdbInsightConfigureRouteEntry: Route = createEntry(
	servicedeskCmdbInsightConfigureRoute,
	() => ({
		...sharedRouteProps,
		ufoName: 'servicedesk-cmdb-insight-configure',
		component: InsightConfigure,
		resources: getCmdbResources(),
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbConfigure],
	}),
);

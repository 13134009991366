import { ROUTE_NAMES_LEGACY_REDIRECT } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

// This route is used to catch transitions to non-matching paths
// Make sure `path` is kept as ''
// Make sure `exact` is kept as false
export const legacyRedirectRoute: InvariantRoute = {
	path: '',
	name: ROUTE_NAMES_LEGACY_REDIRECT,
	exact: false,
};

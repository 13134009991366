import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_POLARIS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectCreatePermissionsResource } from '@atlassian/jira-router-resources-project-permissions/src/services/project-permissions/index.tsx';
import { polarisOnboardingRoute } from '@atlassian/jira-router-routes-polaris-routes/src/polarisOnboardingRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import OnboardingPage, { LazyOnboardingPage } from './ui/onboarding/index.tsx';

export const polarisOnboardingRouteEntry: Route = createEntry(polarisOnboardingRoute, () => ({
	group: ROUTE_GROUPS_POLARIS,
	component: OnboardingPage,
	layout: globalLayout,
	canTransitionIn: () => true,
	resources: [...getNavigationResources(), projectCreatePermissionsResource],
	forPaint: [LazyAtlassianNavigation, LazyOnboardingPage],
}));

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_DIRECTORIES } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { dashboardsDirectoryResource } from '@atlassian/jira-router-resources-directories-dashboards/src/services/dashboards-directory/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { dashboardsDirectoryRoute } from '@atlassian/jira-router-routes-directories-routes/src/dashboardsDirectoryRoute.tsx';
import { DashboardsDirectorySkeleton } from '@atlassian/jira-skeletons/src/ui/directories/FiltersDirectorySkeleton.tsx';
import { DashboardsDirectoryComponent, LazyDashboardsDirectory } from './ui/dashboards/index.tsx';

export const dashboardsDirectoryRouteEntry = createEntry(dashboardsDirectoryRoute, () => ({
	group: ROUTE_GROUPS_DIRECTORIES,
	resources: [...getNavigationResources(), dashboardsDirectoryResource],
	component: DashboardsDirectoryComponent,
	skeleton: DashboardsDirectorySkeleton,
	layout: globalLayout,

	navigation: {
		menuId: MENU_ID.DASHBOARDS,
	},

	forPaint: [LazyAtlassianNavigation, LazyDashboardsDirectory],
}));

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_PROFORMA } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { proformaExportRoute } from '@atlassian/jira-router-routes-proforma-routes/src/proformaExportRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import ProformaExport, { LazyProformaExport } from './ui/export/index.tsx';

export const proformaExportRouteEntry: Route = createEntry(proformaExportRoute, () => ({
	group: ROUTE_GROUPS_PROFORMA,
	layout: globalLayout,
	resources: [...getNavigationResources()],
	component: ProformaExport,
	forPaint: [LazyAtlassianNavigation, LazyProformaExport],
}));

import React, { useEffect, useMemo, useState } from 'react';
import {
	NestableNavigationContent,
	NavigationHeader,
	SideNavigation,
	SkeletonItem,
} from '@atlaskit/side-navigation';
import {
	ROUTE_NAMES_GLOBAL_SETTINGS_MIGRATIONS_DASHBOARD,
	ROUTE_NAMES_GLOBAL_SETTINGS_MIGRATIONS_HOME,
	ROUTE_NAMES_GLOBAL_SETTINGS_MIGRATIONS_FOCUS_PAGE,
	ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_METRIC_DETAIL,
	ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_OVERVIEW,
	ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_PROJECT_CLEANUP,
	ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_PROJECT_ROLE_ACTOR,
	ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_CUSTOM_FIELD,
	ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_ISSUE,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { MenuItem } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/menu-item/main.tsx';
import { TitleHeader } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/title-header/main.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-navigation-apps-sidebar-common/src/ui/contextual-analytics-data/index.tsx';
import { getSystemNavigationPaths } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/get-navigation-path/system/index.tsx';
import { matchUrlPathname } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/url-matchers/index.tsx';
import { ConnectMenu } from '@atlassian/jira-navigation-apps-sidebar-connect/src/common/ui/connect-menu/main.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { SECTION_ID } from '../../common/constants/index.tsx';
import {
	useFeatures,
	useGlobalSettingsSystemConnectItems,
} from '../../controllers/features/index.tsx';
import { AdminHelperSection } from './admin-helper/index.tsx';
import { AdvancedSection } from './advanced/index.tsx';
import { AutomationSection } from './automation/index.tsx';
import { ImportExportSection } from './import-export/index.tsx';
import { MailSection } from './mail/index.tsx';
import messages from './messages.tsx';
import { SecuritySection } from './security/index.tsx';
import { SharedSection } from './shared/index.tsx';
import { TroubleshootingAndSupportSection } from './troubleshooting-and-support/index.tsx';
import { UserInterfaceSection } from './user-interface/index.tsx';
import { isGeneralConfigurationSelected } from './utils.tsx';

// @ts-expect-error - TS7006 - Parameter 'route' implicitly has an 'any' type.
const calculateStack = (route) => {
	const routeName = (route && route.name) || '';
	if (
		[
			ROUTE_NAMES_GLOBAL_SETTINGS_MIGRATIONS_DASHBOARD,
			ROUTE_NAMES_GLOBAL_SETTINGS_MIGRATIONS_HOME,
			ROUTE_NAMES_GLOBAL_SETTINGS_MIGRATIONS_FOCUS_PAGE,
		].includes(routeName)
	) {
		return [SECTION_ID.MIGRATE_TO_CLOUD_SITE];
	}

	if (
		[
			ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_METRIC_DETAIL,
			ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_OVERVIEW,
			ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_PROJECT_CLEANUP,
			ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_PROJECT_ROLE_ACTOR,
			ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_CUSTOM_FIELD,
			ROUTE_NAMES_GLOBAL_SETTINGS_PERFORMANCE_ISSUE,
		].includes(routeName)
	) {
		return [SECTION_ID.PERFORMANCE_AND_SCALE];
	}

	return [];
};

/**
 *  Returns Menu navigation respecting the current user permissions
 *  When updating this file, make sure to also update the following files if necessary:
 * - src/packages/navigation-apps/sidebar/common/src/controllers/use-navigation-path/system
 */
export const Menu = () => {
	const { data: features } = useFeatures();
	const { formatMessage } = useIntl();
	const route = useCurrentRoute();

	const { data: connectItems } = useGlobalSettingsSystemConnectItems();
	const [stack, setStack] = useState<string[]>(calculateStack(route));

	useEffect(() => {
		setStack(calculateStack(route));
	}, [route]);

	const navigationPath = useMemo(() => getSystemNavigationPaths(), []);

	const generalConfigurationPath = navigationPath.generalConfiguration;

	if (features == null || connectItems == null) return <SkeletonItem />;

	const navigationContent = (
		<>
			{features.hasGeneralConfiguration && (
				<MenuItem
					analytics={{ itemId: 'general-configuration' }}
					href={generalConfigurationPath}
					selectedOn={isGeneralConfigurationSelected}
				>
					{formatMessage(messages.generalConfiguration)}
				</MenuItem>
			)}
			{features.hasDarkFeatures && (
				<MenuItem
					analytics={{ itemId: 'dark-features' }}
					href={navigationPath.darkFeatures}
					selectedOn={matchUrlPathname()}
				>
					{formatMessage(messages.darkFeatures)}
				</MenuItem>
			)}
			{features.hasTroubleshootingAndSupportSection && <TroubleshootingAndSupportSection />}
			{features.hasSecuritySection && <SecuritySection />}
			{features.hasCollectors && (
				<MenuItem
					analytics={{ itemId: 'collectors' }}
					href={navigationPath.issueCollectors}
					selectedOn={matchUrlPathname()}
				>
					{formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.issueCollectorsIssueTermRefresh
							: messages.issueCollectors,
					)}
				</MenuItem>
			)}
			<AutomationSection />
			{features.hasUserInterfaceSection && <UserInterfaceSection />}
			{features.hasImportExportSection && <ImportExportSection />}
			{features.hasMailSection && <MailSection />}
			{features.hasAdminHelperSection && <AdminHelperSection />}
			{features.hasSharedSection && <SharedSection />}
			{features.hasAdvancedSection && <AdvancedSection />}
			<ConnectMenu
				items={connectItems}
				overrides={{
					BackMenuItem: {
						href: '/jira/settings/system/general-configuration',
						title: formatMessage(
							fg('eax-958_fix_a11y_issues_on_jira_system_settings')
								? messages.systemGroup
								: messages.systemGroupOld,
						),
					},
				}}
			/>
		</>
	);

	return (
		<ContextualAnalyticsData menuId="global-settings/system" stack={stack}>
			<SideNavigation label={formatMessage(messages.globalSettingsSystemSidebarLabel)}>
				<NavigationHeader>
					<TitleHeader>
						{formatMessage(
							fg('eax-958_fix_a11y_issues_on_jira_system_settings')
								? messages.systemGroup
								: messages.systemGroupOld,
						)}
					</TitleHeader>
				</NavigationHeader>
				<NestableNavigationContent stack={stack} onChange={setStack}>
					{navigationContent}
				</NestableNavigationContent>
			</SideNavigation>
		</ContextualAnalyticsData>
	);
};

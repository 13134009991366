import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { getServicedeskPageContextResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskCmdbAssetsReportsUnsubscribeRoute } from '@atlassian/jira-router-routes-servicedesk-cmdb-routes/src/servicedeskCmdbAssetsReportsUnsubscribeRoute.tsx';
import {
	toPackageName,
	APP_NAMES,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import { workspaceResource } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/services/resources/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { sharedRouteProps } from './common/constants.tsx';
import {
	AssetsReportsUnsubscribe,
	LazyServicedeskCmdbAssetsReportsUnsubscribe,
} from './ui/reports/index.tsx';

export const servicedeskCmdbAssetsReportsUnsubscribeRouteEntry: Route = createEntry(
	servicedeskCmdbAssetsReportsUnsubscribeRoute,
	() => ({
		...sharedRouteProps,
		component: AssetsReportsUnsubscribe,
		resources: [
			...getNavigationResources(),
			...getServicedeskPageContextResources(),
			workspaceResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCmdbAssetsReportsUnsubscribe],
		meta: {
			reporting: {
				id: APP_NAMES.INSIGHT_ASSETS_REPORTS_UNSUBSCRIBE,
				packageName: toPackageName(APP_NAMES.INSIGHT_ASSETS_REPORTS_UNSUBSCRIBE),
				teamName: 'ITSM SAGA',
			},
		},
	}),
);

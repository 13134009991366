import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	recent: {
		id: 'navigation-apps-sidebar-nav4-plans.plans.common.plans-list.recent',
		defaultMessage: 'Recent',
		description: 'Message for recent heading in the plans menu items',
	},
	starred: {
		id: 'navigation-apps-sidebar-nav4-plans.plans.common.plans-list.starred',
		defaultMessage: 'Starred',
		description: 'Message for starred heading in the plans menu items',
	},
});

import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { projectSettingsServicedeskLegacyAutomationRedirectViewLogRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-routes/src/projectSettingsServicedeskLegacyAutomationRedirectViewLogRoute.tsx';
import { getLegacyAutomationRoutesCommon } from './common/getLegacyAutomationRoutesCommon.tsx';
import { automationRedirect } from './ui/service-desk-automation/redirect/index.tsx';

/* Old urls.
 * Do not remove these redirects, ever.
 * Because customers will have bookmarks and saved links and we need them to keep working forever.
 */
export const projectSettingsServicedeskLegacyAutomationRedirectViewLogRouteEntry = createEntry(
	projectSettingsServicedeskLegacyAutomationRedirectViewLogRoute,
	() => ({
		...getLegacyAutomationRoutesCommon(),
		forPaint: [],
		component: automationRedirect('viewlog'),
	}),
);

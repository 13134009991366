/** @jsx jsx */
import React, { useCallback } from 'react';
import { css, jsx } from '@compiled/react';
import Form, { FormFooter, FormSection } from '@atlaskit/form';
import { Box, Flex, Stack, xcss } from '@atlaskit/primitives';
import Skeleton from '@atlaskit/skeleton';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { JiraBoardType } from '@atlassian/jira-relay/src/__generated__/useBoardCreateMutation.graphql';
import type { BoardCreateFormValues, ModalClosehandler } from '../../../common/types.tsx';
import { useBoardCreateController } from '../../../controllers/use-board-create-controller/index.tsx';
import { ModalHeader } from '../header/index.tsx';
import { ModalIllustration } from '../illustration/index.tsx';
import { FormActions } from './actions/index.tsx';
import { BoardNameField } from './board-name/index.tsx';
import { SourceTypePicker } from './source-type-picker/index.tsx';
import { SourceValuePicker, SOURCE_VALUE_FIELD_NAME } from './source-value-picker/index.tsx';
import { validateFormData } from './utils.tsx';

export type BoardCreateFormProps = {
	boardType: JiraBoardType;
	projectId: string;
	onClose: ModalClosehandler;
};

export const BoardCreateFormFallback = () => {
	return (
		<Flex xcss={formContainerStyles}>
			<Flex gap="space.1000" xcss={formContentStyles}>
				<Stack xcss={columnStyles} spread="space-between">
					<Stack space="space.300">
						<Stack space="space.150">
							<Skeleton width="150px" height="20px" borderRadius="3px" />
							<Skeleton width="100%" height="12px" borderRadius="3px" />
							<Skeleton width="20%" height="12px" borderRadius="3px" />
						</Stack>
						<Stack space="space.100">
							<Skeleton width="100px" height="12px" borderRadius="3px" />
							<Skeleton width="100%" height="30px" borderRadius="3px" />
						</Stack>
						<Stack space="space.100">
							<Skeleton width="200px" height="12px" borderRadius="3px" />
							<Skeleton width="100%" height="30px" borderRadius="3px" />
						</Stack>
					</Stack>
					<Flex justifyContent="end">
						<Flex gap="space.050">
							<Skeleton width="70px" height="32px" borderRadius="3px" />
							<Skeleton width="70px" height="32px" borderRadius="3px" />
						</Flex>
					</Flex>
				</Stack>
				<Stack xcss={columnStyles} space="space.200">
					<Skeleton width="150px" height="20px" borderRadius="3px" />
					<Skeleton width="412px" height="307px" borderRadius="16px" />
				</Stack>
			</Flex>
		</Flex>
	);
};

export const BoardCreateForm = ({ boardType, projectId, onClose }: BoardCreateFormProps) => {
	const { submit } = useBoardCreateController();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleOnSubmit = useCallback(
		async (data: BoardCreateFormValues) => {
			const errors = validateFormData(data);
			const hasValidationErrors = errors.boardName || errors.sourceType || errors.sourceValue;

			let sourceValueLength = 0;
			if (data.sourceValue != null) {
				sourceValueLength = Array.isArray(data.sourceValue) ? data.sourceValue.length : 1;
			}

			const event = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			});
			fireUIAnalytics(event, 'createNewBoard', {
				sourceType: data.sourceType,
				sourceValueLength,
				errors: hasValidationErrors && errors,
			});

			if (hasValidationErrors) {
				return errors;
			}

			await submit({
				boardType,
				formValues: data,
				locationProjectId: projectId ?? '',
				onSuccess: () => onClose(undefined, undefined, true),
			});
		},
		[createAnalyticsEvent, submit, boardType, projectId, onClose],
	);

	const resetSourceValue = useCallback(
		(
			setFieldValue: (
				name: string,
				value: BoardCreateFormValues['sourceType'] | null | undefined,
			) => void,
		) =>
			() =>
				setFieldValue(SOURCE_VALUE_FIELD_NAME, undefined),
		[],
	);

	return (
		<Flex xcss={formContainerStyles}>
			<Form onSubmit={handleOnSubmit}>
				{({ formProps, submitting, setFieldValue }) => (
					<form {...formProps} css={formStyles} noValidate>
						<Flex gap="space.1000" xcss={formContentStyles}>
							<Stack xcss={columnStyles}>
								<ModalHeader type={boardType} />
								<Box xcss={formFieldsContainerStyles}>
									<FormSection>
										<BoardNameField />
									</FormSection>
									<FormSection>
										<SourceTypePicker resetSourceValue={resetSourceValue(setFieldValue)} />
										<SourceValuePicker />
									</FormSection>
								</Box>
								<Box xcss={buttonGroupContainerStyles}>
									<FormFooter>
										<FormActions submitting={submitting} onClose={onClose} />
									</FormFooter>
								</Box>
							</Stack>
							<Stack xcss={columnStyles}>
								<ModalIllustration />
							</Stack>
						</Flex>
					</form>
				)}
			</Form>
		</Flex>
	);
};

const formStyles = css({
	overflowY: 'auto',
	width: '100%',
});

const formContentStyles = xcss({
	height: '100%',
	width: '100%',
});

const columnStyles = xcss({
	width: 'calc(50% - 40px)', // space.1000 is 80px, thus 40px is the space on each side
	flex: 'none',
	paddingTop: 'space.200',
});

const formFieldsContainerStyles = xcss({
	flexGrow: 1,
});

const buttonGroupContainerStyles = xcss({
	alignSelf: 'end',
	justifySelf: 'end',
});

const formContainerStyles = xcss({
	paddingTop: 'space.600',
	paddingLeft: 'space.600',
	paddingRight: 'space.600',
	paddingBottom: 'space.500',
	height: '100%',
});

import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { logSafeErrorWithoutCustomerData } from '@atlassian/jira-common-directory-v2/src/utils/log-safe-error/index.tsx';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { FILTERS_ERROR_ANALYTICS } from '@atlassian/jira-filters-common/src/common/constant.tsx';
import getAssociatedBoards from '../../services/get-associated-boards/index.tsx';
import getAssociatedSubscriptions from '../../services/get-associated-subscriptions/index.tsx';
import {
	type Action,
	GET_ASSOCIATED_ITEMS,
	associatedItemsRetrieved,
	getAssociatedItemsFailed,
} from '../../state/actions.tsx';
import {
	getBaseUrl,
	getFilter,
	getOnInitialised,
	getOnFilterInitiateFailed,
} from '../../state/selectors.tsx';
import type { State } from '../../state/types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(GET_ASSOCIATED_ITEMS).mergeMap(() => {
		const state = store.getState();
		const baseUrl = getBaseUrl(state);
		const filter = getFilter(state);

		return Observable.forkJoin(
			Observable.fromPromise(getAssociatedBoards(baseUrl, filter.id)),
			Observable.fromPromise(getAssociatedSubscriptions(baseUrl, filter.id)),
		) // eslint-disable-next-line @typescript-eslint/no-explicit-any
			.map(([boards, subscriptions]: [any, any]) => {
				const { users, groups } = subscriptions;
				getOnInitialised(state)();
				return associatedItemsRetrieved({ boards, users, groups });
			})
			.catch((error: FetchError) => {
				logSafeErrorWithoutCustomerData(
					error,
					'directories.filters-v2.delete-modal.ops.get-associated-items',
					FILTERS_ERROR_ANALYTICS,
				);
				getOnFilterInitiateFailed(state)(filter);
				return Observable.of(getAssociatedItemsFailed());
			});
	});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	refinementProject: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-project',
		defaultMessage: 'Project',
		description:
			"The label of the filtering field for values corresponding to Jira's project field.",
	},
	refinementStatus: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-status',
		defaultMessage: 'Status',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue Status field.",
	},
	refinementStatusCategory: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-status-category',
		defaultMessage: 'Status category',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue Status category field.",
	},
	refinementSummary: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-summary',
		defaultMessage: 'Summary',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue Summary field.",
	},
	refinementAssignee: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-assignee',
		defaultMessage: 'Assignee',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue Assignee field.",
	},
	refinementReporter: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-reporter',
		defaultMessage: 'Reporter',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue Reporter field.",
	},
	refinementType: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-type',
		defaultMessage: 'Type',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue Type field.",
	},
	refinementPriority: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-priority',
		defaultMessage: 'Priority',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue Priority field.",
	},
	refinementResolution: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-resolution',
		defaultMessage: 'Resolution',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue Resolution field.",
	},
	refinementUpdated: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-updated',
		defaultMessage: 'Updated date',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue updated field.",
	},
	refinementCreated: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-created',
		defaultMessage: 'Created date',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue created field.",
	},
	refinementDueDate: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-due-date',
		defaultMessage: 'Due date',
		description:
			"The label of the filtering field for values corresponding to Jira's issue due date field.",
	},
	refinementResolutionDate: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-resolution-date',
		defaultMessage: 'Resolution date',
		description:
			"The label of the filtering field for values corresponding to Jira's issue Resolution date field.",
	},
	refinementComponent: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-component',
		defaultMessage: 'Component',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue Component field.",
	},
	refinementParent: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-parent',
		defaultMessage: 'Parent',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue Parent field.",
	},
	refinementFixVersion: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-fix-version',
		defaultMessage: 'Fix version',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue Fix version field. https://www.atlassian.com/agile/tutorials/versions",
	},
	refinementAffectedVersion: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-affected-version',
		defaultMessage: 'Affects version',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue Affects version field. https://www.atlassian.com/agile/tutorials/versions",
	},
	refinementLabel: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-label',
		defaultMessage: 'Label',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue Label field.",
	},
	refinementLabels: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-labels',
		defaultMessage: 'Labels',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue Labels field.",
	},
	refinementEpicLink: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-epic-link',
		defaultMessage: 'Epic link',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue Epic link field.",
	},
	refinementSprint: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-sprint',
		defaultMessage: 'Sprint',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue Sprint field.",
	},
	refinementCreator: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-creator',
		defaultMessage: 'Creator',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue Creator field.",
	},
	refinementDescription: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-description',
		defaultMessage: 'Description',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue Description field.",
	},
	refinementEnvironment: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-environment',
		defaultMessage: 'Environment',
		description:
			"The label of the filtering field for values corresponding to Jira's default issue Environment field.",
	},
	refinementNotFound: {
		id: 'jql-builder-basic.utils.get-field-display-name-by-field-id.refinement-not-found',
		defaultMessage: 'Unknown Field',
		description: 'The label of the filtering field when corresponding field could not be found.',
	},
});

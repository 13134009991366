import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_HELP_CENTER_SETTINGS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { helpCenterSettingsRoute } from '@atlassian/jira-router-routes-help-center-settings-routes/src/helpCenterSettingsRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { navigation } from './common/constants.tsx';
import { Redirect } from './ui/redirect/index.tsx';

export const helpCenterSettingsRedirectRouteEntry: Route = createEntry(
	helpCenterSettingsRoute,
	() => ({
		group: ROUTE_GROUPS_HELP_CENTER_SETTINGS,
		layout: serviceProjectLayout,
		navigation,
		component: Redirect,
		forPaint: [LazyAtlassianNavigation],
		resources: [...getServiceDeskSettingsResources()],
	}),
);

/**
 * @generated SignedSource<<96b4cce51ba6e0ff7c8004e93170ae98>>
 * @relayHash f07c5fbb5fc9fda47c89b1ea4e0b6538
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 39733200dfeb8f5ca8e119e986adf5ee373acba404794290d4a84a17b45673c2

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraBoardLocationType = "PROJECT" | "USER" | "%future added value";
export type JiraBoardType = "KANBAN" | "SCRUM" | "%future added value";
export type JiraCreateBoardInput = {
  createBoardSource: JiraCreateBoardSource;
  location: JiraBoardLocation;
  name: string;
  preset: JiraBoardType;
};
export type JiraCreateBoardSource = {
  fieldInput?: JiraCreateBoardFieldInput | null | undefined;
  savedFilterId?: AGG$Long | null | undefined;
};
export type JiraCreateBoardFieldInput = {
  issueTypes?: ReadonlyArray<JiraIssueTypeInput> | null | undefined;
  labels?: ReadonlyArray<JiraLabelsInput> | null | undefined;
  projects: ReadonlyArray<JiraProjectInput>;
  teams?: ReadonlyArray<JiraAtlassianTeamInput> | null | undefined;
};
export type JiraIssueTypeInput = {
  id?: string | null | undefined;
  issueTypeId: string;
};
export type JiraLabelsInput = {
  name?: string | null | undefined;
};
export type JiraProjectInput = {
  id?: string | null | undefined;
  projectId: string;
};
export type JiraAtlassianTeamInput = {
  teamId: string;
};
export type JiraBoardLocation = {
  locationId?: string | null | undefined;
  locationType: JiraBoardLocationType;
};
export type useBoardCreateMutation$variables = {
  cloudId: string;
  input: JiraCreateBoardInput;
};
export type useBoardCreateMutation$data = {
  readonly jira: {
    readonly createBoard: {
      readonly board: {
        readonly __id: string;
        readonly boardId: AGG$Long | null | undefined;
        readonly boardType: JiraBoardType | null | undefined;
        readonly boardUrl: AGG$URL | null | undefined;
        readonly id: string;
        readonly name: string | null | undefined;
      } | null | undefined;
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly errorType: string | null | undefined;
          readonly statusCode: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type useBoardCreateMutation = {
  response: useBoardCreateMutation$data;
  variables: useBoardCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "id"
},
v3 = {
  "concreteType": "JiraBoard",
  "kind": "LinkedField",
  "name": "board",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "boardId"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "name": "boardType"
    },
    {
      "kind": "ScalarField",
      "name": "boardUrl"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "success"
},
v5 = {
  "kind": "ScalarField",
  "name": "message"
},
v6 = {
  "kind": "ScalarField",
  "name": "errorType"
},
v7 = {
  "kind": "ScalarField",
  "name": "statusCode"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "useBoardCreateMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraCreateBoardPayload",
            "kind": "LinkedField",
            "name": "createBoard",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useBoardCreateMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraCreateBoardPayload",
            "kind": "LinkedField",
            "name": "createBoard",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "type": "BulkMutationErrorExtension"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "39733200dfeb8f5ca8e119e986adf5ee373acba404794290d4a84a17b45673c2",
    "metadata": {},
    "name": "useBoardCreateMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "6559f8aeeafe0e444aad2224af38c3cb";

export default node;

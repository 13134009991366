/**
 * @generated SignedSource<<4be84d6802ab064c0763ebca5cfcec99>>
 * @relayHash 4462855f8db76a7de7bb14267217794e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 52c31ffec495f808bddcad051bf07a6da82536cafd2e7f14928caf023c49694e

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcVirtualAgentLandingQuery } from './srcVirtualAgentLandingQuery.graphql';

import checkForAiEnabledFF_provider from '@atlassian/jira-relay-provider/src/jsm-va-is-require-ai-enabled.relayprovider';
import roiTeaserM1Enabled_provider from '@atlassian/jira-relay-provider/src/jsm-va-roi-teaser-m1-enabled.relayprovider';

const node: PreloadableConcreteRequest<srcVirtualAgentLandingQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "52c31ffec495f808bddcad051bf07a6da82536cafd2e7f14928caf023c49694e",
    "metadata": {},
    "name": "srcVirtualAgentLandingQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaroiteaserm1enabledrelayprovider": roiTeaserM1Enabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjsmvaisrequireaienabledrelayprovider": checkForAiEnabledFF_provider
    }
  }
};

export default node;

import React, { useMemo } from 'react';
import DashboardIcon from '@atlaskit/icon/core/dashboard';
import { ButtonItem } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import messages from './messages.tsx';
import { projectActionMenuSaveAsTemplateEntryPoint } from './save-project-as-template-modal/entrypoint.tsx';

export const SaveProjectAsTemplate = () => {
	const { formatMessage } = useIntl();
	const modalProps = useMemo(
		() => ({
			autoFocus: true,
			width: 'x-large',
			shouldScrollInViewport: true,
		}),
		[],
	);

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={projectActionMenuSaveAsTemplateEntryPoint}
			useInternalModal
			modalProps={modalProps}
		>
			{({ ref }) => (
				<ButtonItem
					interactionName="open-save-as-template-modal"
					ref={ref}
					onClick={() => {
						// TODO fire analytics
					}}
					iconBefore={<DashboardIcon label="" spacing="spacious" color={token('color.icon')} />}
				>
					{formatMessage(messages.saveAsTemplateNonFinal)}
				</ButtonItem>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

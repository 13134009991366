import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	projectTitle: {
		id: 'atlassian-navigation-recommendations.jpd-recommendation.project-title',
		defaultMessage: 'New discovery project',
		description: 'Project description when resurfacing unused ITSM project',
	},
	lozengeText: {
		id: 'atlassian-navigation-recommendations.jpd-recommendation.lozenge-text',
		defaultMessage: 'try',
		description: 'Lozenge indicating users to try the product',
	},
	reasonsModalTitle: {
		id: 'atlassian-navigation-recommendations.jpd-recommendation.reasons-modal-title',
		defaultMessage: 'Why am I seeing this?',
		description: 'Title of the reasons modal',
	},
	reasonsModalExplanation: {
		id: 'atlassian-navigation-recommendations.jpd-recommendation.reasons-modal-explanation',
		defaultMessage:
			"We think <strong>Jira Product Discovery</strong> will improve your team's productivity. The recommendation is based on:",
		description: 'Explanation as to why the placeholder template is being shown',
	},
	reasonsModalBulletOneSpork: {
		id: 'atlassian-navigation-recommendations.jpd-recommendation.reasons-modal-bullet-one-spork',
		defaultMessage: 'the tools you use (Jira)',
		description: 'First bullet point of the reasons modal',
	},
	reasonsModalBulletTwo: {
		id: 'atlassian-navigation-recommendations.jpd-recommendation.reasons-modal-bullet-two',
		defaultMessage: 'the way you use your existing tools and products',
		description: 'Second bullet point of the reasons modal',
	},
	privacyPolicy: {
		id: 'atlassian-navigation-recommendations.jpd-recommendation.privacy-policy',
		defaultMessage: 'Learn more about your data privacy with Atlassian',
		description: "Link to Atlassian's privacy policy",
	},
	reasonsModalButtonOk: {
		id: 'atlassian-navigation-recommendations.jpd-recommendation.reasons-modal-button-ok',
		defaultMessage: 'Ok',
		description: 'Ok text for the reasons modal',
	},
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	illustrationPlaceHolderHeader: {
		id: 'software-board-create.modal.illustration.illustration-place-holder-header',
		defaultMessage: 'New board',
		description: 'Appears above an illustration of a new board in the board creation modal',
	},
	illustrationAltText: {
		id: 'software-board-create.modal.illustration.illustration-alt-text',
		defaultMessage: 'Bug and task cards in a board column',
		description: 'Alt text for an illustration of a new board in the board creation modal',
	},
});

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskNavigationResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { forgeServicedeskQueueRoute } from '@atlassian/jira-router-routes-forge-routes/src/forgeServicedeskQueueRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router/src/common/types';
import ForgeServiceDeskQueuePage, {
	LazyForgeServiceDeskQueuePage,
} from './ui/servicedesk-queue/index.tsx';

export const forgeServicedeskQueueRouteEntry: Route = createEntry(
	forgeServicedeskQueueRoute,
	() => ({
		component: ForgeServiceDeskQueuePage,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},
		resources: [...getServiceDeskNavigationResources(), getConsolidationStateResource()],
		forPaint: [LazyAtlassianNavigation, LazyForgeServiceDeskQueuePage],
	}),
);

import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getNavigationForgeGlobalPageResources } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import AsyncSidebarForgeGlobalPage from '@atlassian/jira-navigation-apps-sidebar-forge-global-page/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { forgeGlobalRoute } from '@atlassian/jira-router-routes-forge-routes/src/forgeGlobalRoute.tsx';
import type { Route } from '@atlassian/react-resource-router/src/common/types';
import ForgeGlobalPage, { LazyForgeGlobalPage } from './ui/global/index.tsx';

export const forgeGlobalRouteEntry: Route = createEntry(forgeGlobalRoute, () => ({
	component: ForgeGlobalPage,
	layout: createLayout(),
	navigation: {
		menuId: MENU_ID.ADDONS,
		sidebar: AsyncSidebarForgeGlobalPage,
	},
	resources: [...getNavigationResources(), ...getNavigationForgeGlobalPageResources()],
	forPaint: [LazyAtlassianNavigation, LazyForgeGlobalPage],
}));

import React from 'react';
import { Box, Text } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { NinChangeboardingTourTarget } from '@atlassian/jira-issue-navigator-changeboarding/src/controllers/enable-nin-changeboarding/index.tsx';
import ToggleButtons from '@atlassian/jira-toggle-buttons/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { SEARCH_MODE_ADVANCED, SEARCH_MODE_BASIC } from '../../../constants.tsx';
import type { SearchMode, SearchModeSwitcherProps } from '../../../types.tsx';
import { messages } from './messages.tsx';

export const SearchModeSwitcher = ({
	isBasicModeDisabled,
	onUpdateSearchMode,
	searchMode,
}: SearchModeSwitcherProps) => {
	const { formatMessage } = useIntl();
	const testIdPrefix = 'issue-navigator.ui.refinement-bar.search-mode-switcher.toggle-button.';

	const options = [
		{
			id: SEARCH_MODE_BASIC,
			label: isBasicModeDisabled ? (
				<Tooltip content={formatMessage(messages.basicModeDisabledTooltip)} tag="span">
					{isVisualRefreshEnabled() ? (
						// using Box instead of Text to avoid overriding ToggleButtons styles
						<Box as="span">{formatMessage(messages.basicMode)}</Box>
					) : (
						<Text size="UNSAFE_small" weight="semibold" color="inherit">
							{formatMessage(messages.basicMode)}
						</Text>
					)}
				</Tooltip>
			) : (
				formatMessage(messages.basicMode)
			),
			isDisabled: isBasicModeDisabled,
			testId: `${testIdPrefix}${SEARCH_MODE_BASIC}`,
		},
		{
			id: SEARCH_MODE_ADVANCED,
			label: formatMessage(messages.advancedMode),
			testId: `${testIdPrefix}${SEARCH_MODE_ADVANCED}`,
		},
	];
	return (
		<ToggleButtons
			label={formatMessage(messages.label)}
			options={options}
			selectedOption={searchMode}
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			onChange={(value) => onUpdateSearchMode(value as SearchMode)}
		/>
	);
};

export const SearchModeSwitcherWithTarget = (props: SearchModeSwitcherProps) => (
	<NinChangeboardingTourTarget engagementId="nin.jql-builder.searchmode-switcher">
		<SearchModeSwitcher {...props} />
	</NinChangeboardingTourTarget>
);

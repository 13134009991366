/**
 * @generated SignedSource<<dd64a381d167fb00af9003be21285297>>
 * @relayHash 229c33efdf01e38ebee4d770694e78a2
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b6947a931a52263dfd65802f0016dab4e8ddcfb028ba95b8529ebd204d636287

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4ProjectsContentViewQuery$variables = {
  cloudId: string;
  simplifyBoardQuery: boolean;
};
export type Nav4ProjectsContentViewQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4ProjectsContent">;
};
export type Nav4ProjectsContentViewQuery = {
  response: Nav4ProjectsContentViewQuery$data;
  variables: Nav4ProjectsContentViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "simplifyBoardQuery"
  }
],
v1 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v2 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v5 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "EDIT_PROJECT_CONFIG"
  }
],
v6 = [
  {
    "kind": "ScalarField",
    "name": "canPerform"
  }
],
v7 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v8 = [
  {
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          (v3/*: any*/),
          {
            "alias": "projectKey",
            "kind": "ScalarField",
            "name": "key"
          },
          {
            "kind": "ScalarField",
            "name": "status"
          },
          {
            "kind": "ScalarField",
            "name": "projectType"
          },
          {
            "concreteType": "JiraAvatar",
            "kind": "LinkedField",
            "name": "avatar",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "large"
              }
            ]
          },
          {
            "kind": "ScalarField",
            "name": "name"
          },
          {
            "kind": "ScalarField",
            "name": "projectId"
          },
          {
            "kind": "ScalarField",
            "name": "isFavourite"
          },
          {
            "kind": "LinkedField",
            "name": "defaultNavigationItem",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "typeKey"
                  }
                ],
                "type": "JiraNavigationItem",
                "abstractKey": "__isJiraNavigationItem"
              },
              (v4/*: any*/)
            ]
          },
          {
            "alias": "editProjectConfig",
            "args": (v5/*: any*/),
            "concreteType": "JiraProjectAction",
            "kind": "LinkedField",
            "name": "action",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
          },
          {
            "kind": "ScalarField",
            "name": "projectStyle"
          },
          {
            "kind": "ScalarField",
            "name": "webUrl"
          },
          {
            "alias": "canEditProjectConfig",
            "args": (v5/*: any*/),
            "concreteType": "JiraProjectAction",
            "kind": "LinkedField",
            "name": "action",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
          },
          {
            "alias": "canViewProjectConfig",
            "args": [
              {
                "kind": "Literal",
                "name": "type",
                "value": "VIEW_PROJECT_CONFIG"
              }
            ],
            "concreteType": "JiraProjectAction",
            "kind": "LinkedField",
            "name": "action",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": "action(type:\"VIEW_PROJECT_CONFIG\")"
          },
          {
            "args": (v5/*: any*/),
            "concreteType": "JiraProjectAction",
            "kind": "LinkedField",
            "name": "action",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
          },
          {
            "kind": "ScalarField",
            "name": "softwareBoardCount"
          },
          {
            "condition": "simplifyBoardQuery",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "kind": "LinkedField",
                "name": "navigationMetadata",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "boardId"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "isSimpleBoard"
                      },
                      (v3/*: any*/)
                    ],
                    "type": "JiraSoftwareProjectNavigationMetadata"
                  }
                ]
              }
            ]
          },
          (v7/*: any*/)
        ],
        "type": "JiraProject"
      },
      (v4/*: any*/)
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "Nav4ProjectsContentViewQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Nav4ProjectsContent"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Nav4ProjectsContentViewQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "alias": "favouriteProjects",
            "args": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "includeArchivedProjects": false,
                  "sort": {
                    "order": "DESC"
                  },
                  "type": "PROJECT"
                }
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 15
              }
            ],
            "concreteType": "JiraFavouriteConnection",
            "kind": "LinkedField",
            "name": "favourites",
            "plural": false,
            "selections": [
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  }
                ]
              },
              {
                "concreteType": "JiraFavouriteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": (v8/*: any*/)
              },
              (v7/*: any*/)
            ]
          },
          {
            "alias": "recentProjects",
            "args": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "includeArchivedProjects": false
                }
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 20
              },
              {
                "kind": "Literal",
                "name": "types",
                "value": [
                  "PROJECT"
                ]
              }
            ],
            "concreteType": "JiraSearchableEntityConnection",
            "kind": "LinkedField",
            "name": "recentItems",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraSearchableEntityEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": (v8/*: any*/)
              },
              (v7/*: any*/)
            ]
          },
          {
            "args": [
              (v1/*: any*/)
            ],
            "concreteType": "JiraWorkManagementLicensing",
            "kind": "LinkedField",
            "name": "jwmLicensing",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "currentUserSeatEdition"
              }
            ]
          }
        ]
      },
      {
        "concreteType": "GrowthRecQuery",
        "kind": "LinkedField",
        "name": "growthRecommendations",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "fields": [
                  {
                    "kind": "Literal",
                    "name": "product",
                    "value": "jira"
                  },
                  {
                    "kind": "Variable",
                    "name": "tenantId",
                    "variableName": "cloudId"
                  },
                  {
                    "kind": "Literal",
                    "name": "useCase",
                    "value": "jiraProjectMenuRecommendations"
                  }
                ],
                "kind": "ObjectValue",
                "name": "context"
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 2
              }
            ],
            "kind": "LinkedField",
            "name": "recommendations",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "entityId"
                          }
                        ],
                        "type": "GrowthRecJiraTemplateRecommendation"
                      }
                    ]
                  }
                ],
                "type": "GrowthRecRecommendations"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "b6947a931a52263dfd65802f0016dab4e8ddcfb028ba95b8529ebd204d636287",
    "metadata": {},
    "name": "Nav4ProjectsContentViewQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "a9eeea4cf9de80dffe1c1e32fef3c57f";

export default node;

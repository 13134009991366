import React from 'react';
import { JQLEditorAsync } from '@atlaskit/jql-editor';
import type { JQLBuilderAdvancedProps } from './types.tsx';
import JQLBuilderAdvancedUI from './ui/index.tsx';

const JQLBuilderAdvancedAsync = (props: JQLBuilderAdvancedProps) => (
	<JQLBuilderAdvancedUI {...props} JQLEditor={JQLEditorAsync} />
);

export default JQLBuilderAdvancedAsync;

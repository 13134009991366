import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';

export const getContainersByTypeQueryOld = () => `
containersByType(input:{systemContainerTypes: [CONTENT, PRIMARY, SECONDARY, REQUEST_PORTAL], supportDefaultTab: true}) {
__typename
... on JiraIssueItemContainers {
containers {
  containerType
  items {
    nodes {
      type: __typename
      ... on JiraIssueItemFieldItem {
        fieldItemId        
      }
      ... on JiraIssueItemPanelItem {
        panelItemId
      }
      ... on JiraIssueItemTabContainer {
        name
        tabContainerId              
        items {
          nodes {
            type: __typename
            ... on JiraIssueItemFieldItem {
              fieldItemId
            }
          }
        }
      }
    }
  }
}
}
}
`;

export const getContainersByTypeQueryWithCustomerContext = () => `
containersByType(input:{systemContainerTypes: [CONTENT, PRIMARY, SECONDARY, REQUEST_PORTAL, CUSTOMER_CONTEXT], supportDefaultTab: true}) {
__typename
... on JiraIssueItemContainers {
containers {
  containerType
  items {
    nodes {
      type: __typename
      ... on JiraIssueItemFieldItem {
        fieldItemId        
      }
      ... on JiraIssueItemPanelItem {
        panelItemId
      }
      ... on JiraIssueItemTabContainer {
        name
        tabContainerId              
        items {
          nodes {
            type: __typename
            ... on JiraIssueItemFieldItem {
              fieldItemId
            }
          }
        }
      }
    }
  }
}
}
}
`;

export const getContainersByTypeQuery = functionWithCondition(
	() => fg('jcs_issue_layout_eap'),
	getContainersByTypeQueryWithCustomerContext,
	getContainersByTypeQueryOld,
);

import React, { type ComponentType, type FC } from 'react';
import { MenuGroup } from '@atlaskit/menu';
import type { ContentProps } from '@atlaskit/popup';
import type { OnCloseProps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';

type MoreNavMenuButtonMenuItemsProps = OnCloseProps;

export type MoreNavMenuButtonSubmenuPassThroughProps = {
	MenuItems: ComponentType<MoreNavMenuButtonMenuItemsProps>;
};

type MoreNavMenuButtonSubmenuProps = MoreNavMenuButtonSubmenuPassThroughProps & ContentProps;

export const MoreNavMenuButtonSubmenu: FC<MoreNavMenuButtonSubmenuProps> = ({
	MenuItems,
	onClose,
}) => (
	<MenuGroup minWidth={250}>
		<MenuItems onClose={onClose} />
	</MenuGroup>
);

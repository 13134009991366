import log from '@atlassian/jira-common-util-logging/src/log.tsx';

/** @Deprecated Please use logSafeErrorWithoutCustomerData. */
export const logSafeError = (error: Error, location: string, suffix: string, message?: string) =>
	log.safeErrorWithoutCustomerData(location, message || error.message, error);

/** This function logs privacy safe error.
 * The content must not include any personally identifiable information (PII) or user generated content (UGC).
 * In Jira, UGC includes issue keys, project keys etc. Please see go/jira-ugc for more information.
 *
 * @param error the error that is thrown.
 * @param location the lower-case, '.' separated path to the file that you are logging from.
 * @param suffix the lower-case '.' separated suffix for the event name.
 * @param message the message to be logged.
 */
export const logSafeErrorWithoutCustomerData = (
	error: Error,
	location: string,
	suffix: string,
	message?: string,
) => log.safeErrorWithoutCustomerData(location, message || error.message, error);

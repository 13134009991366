import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { ROUTE_GROUPS_SOFTWARE } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { softwareVersionDetailNoTabPathRedirectRoute } from '@atlassian/jira-router-routes-software-versions-routes/src/softwareVersionDetailNoTabPathRedirectRoute.tsx';
import { AsyncHorizontalNavJiraSoftware as AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { softwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { LazyVersionDetail } from './ui/version-detail/index.tsx';
import { VersionDetailPageLegacyRedirect } from './ui/VersionDetailPageLegacyRedirect.tsx';

export const softwareVersionDetailNoTabPathRedirectRouteEntry: Route = createEntry(
	softwareVersionDetailNoTabPathRedirectRoute,
	() => ({
		group: ROUTE_GROUPS_SOFTWARE,
		component: VersionDetailPageLegacyRedirect,
		canTransitionIn: () => true,
		layout: softwareProjectLayoutNoOnboarding,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: [AsyncSidebarNextGen, AsyncSidebarSoftwareClassic],
			horizontal: AsyncHorizontalNavJira,
			onlyShowHorizontalOnNav4: true,
		},
		resources: [...getNavigationResources()],
		forPaint: [LazyAtlassianNavigation, LazyVersionDetail],
	}),
);

/** @jsx jsx */
import type { PropsWithChildren } from 'react';
import { css, jsx, styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';

const AppStyle = ({ children }: PropsWithChildren) => <div css={appStyles}>{children}</div>;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const AppStyleOld = styled.div({
	font: token('font.body'),
	height: 'inherit',
});

const appStyles = css({
	font: token('font.body'),
	height: 'inherit',
});

const AppStyleFinal = componentWithFG('jfp-1020-use-compiled', AppStyle, AppStyleOld);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles
export default AppStyleFinal;

import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { ExperienceTrackerProvider } from '@atlassian/jira-experience-tracker/src/ui/experience-tracker-provider/ExperienceTrackerProvider.tsx';
import { routeAnalyticsAttributes } from '@atlassian/jira-platform-route-analytics/src/main.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { SubProductUpdater } from './subproduct-updater/index.tsx';
import type { MetricsContainersProps } from './types.tsx';

export const MetricsContainers = ({ children }: MetricsContainersProps) => (
	<ContextualAnalyticsData attributes={routeAnalyticsAttributes}>
		<ExperienceTrackerProvider>
			{!__SERVER__ && (
				<JSErrorBoundary
					fallback="unmount"
					id="metrics-containers-subproduct-updater"
					packageName="page-container"
				>
					<SubProductUpdater />
				</JSErrorBoundary>
			)}
			{children}
		</ExperienceTrackerProvider>
	</ContextualAnalyticsData>
);

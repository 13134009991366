import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	heading: {
		id: 'portfolio-3-onboarding.onboarding.spotlight-views.heading',
		defaultMessage: 'Views for different needs',
		description: 'The title of the spotlight',
	},
	description: {
		id: 'portfolio-3-onboarding.onboarding.spotlight-views.description',
		defaultMessage:
			'Each page shows you a different aspect of your plan so you can quickly find the information you need.',
		description: 'The content for the spotlight',
	},
});

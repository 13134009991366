import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import srcVirtualAgentPerformanceStatisticsQuery from '@atlassian/jira-relay/src/__generated__/srcVirtualAgentPerformanceQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const DEFAULT_TIME_RANGE_MS = 7 * 24 * 60 * 60 * 1000; // 7 days

const formatFetchDate = (date: Date) => date.toISOString().substring(0, 10); // has to be truncated for proper caching in SSR

export const performanceEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-virtual-agent-performance" */ './src'),
	),
	getPreloadProps: ({ context, tenantContext }: EntryPointRouteParams) => ({
		queries: {
			page: {
				parameters: srcVirtualAgentPerformanceStatisticsQuery,
				variables: {
					cloudId: tenantContext.cloudId,
					projectKey: context.match.params.projectKey?.toUpperCase() ?? '',
					startDate: formatFetchDate(new Date(Date.now() - DEFAULT_TIME_RANGE_MS)),
					endDate: formatFetchDate(new Date()),
				},
			},
		},
	}),
});

/**
 * @generated SignedSource<<e7a4f9b2c7ea46621cd664591e142a2b>>
 * @relayHash 5d62b62066d2e4341b25445f5710faf6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ea28f854d9d34cb357f7b0a2d32ddcfaabb301d65019ccde74b53cf76b1aeb39

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraJQLBuilderSearchMode = "ADVANCED" | "BASIC" | "%future added value";
export type searchModeJqlBuilder_updateJQLBuilderSearchModeMutation$variables = {
  cloudId: string;
  searchMode?: JiraJQLBuilderSearchMode | null | undefined;
};
export type searchModeJqlBuilder_updateJQLBuilderSearchModeMutation$data = {
  readonly jira: {
    readonly userPreferences: {
      readonly setJQLBuilderSearchMode: {
        readonly errors: ReadonlyArray<{
          readonly message: string | null | undefined;
        }> | null | undefined;
        readonly success: boolean;
        readonly userSearchMode: JiraJQLBuilderSearchMode | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type searchModeJqlBuilder_updateJQLBuilderSearchModeMutation = {
  response: searchModeJqlBuilder_updateJQLBuilderSearchModeMutation$data;
  variables: searchModeJqlBuilder_updateJQLBuilderSearchModeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchMode"
  }
],
v1 = [
  {
    "concreteType": "JiraMutation",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
          }
        ],
        "concreteType": "JiraUserPreferencesMutation",
        "kind": "LinkedField",
        "name": "userPreferences",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "searchMode",
                "variableName": "searchMode"
              }
            ],
            "concreteType": "JiraJQLBuilderSearchModeMutationPayload",
            "kind": "LinkedField",
            "name": "setJQLBuilderSearchMode",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "success"
              },
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "message"
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "name": "userSearchMode"
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "searchModeJqlBuilder_updateJQLBuilderSearchModeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "searchModeJqlBuilder_updateJQLBuilderSearchModeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "ea28f854d9d34cb357f7b0a2d32ddcfaabb301d65019ccde74b53cf76b1aeb39",
    "metadata": {},
    "name": "searchModeJqlBuilder_updateJQLBuilderSearchModeMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "92ba3afb837dca41d4ee89012d43ffd1";

export default node;

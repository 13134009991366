import React, { useCallback } from 'react';
import { MenuGroup } from '@atlaskit/menu';
import { BUTTON } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import { FIXED_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { MEATBALL_DROPDOWN } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-id.tsx';
import { LEVEL_ONE } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import {
	type OnCloseProps,
	L1_MENU_ID,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { HideL1MenuItemSection } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/hide-l1-menu-item-action/index.tsx';
import { MoreNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/main.tsx';
import type { MoreNavMenuButtonMenuItemsProps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/submenu/index.tsx';
import { useFilterActionsAnalytics } from '../../utils/analytics/index.tsx';

type ActionButtonsProps = OnCloseProps;

export function ActionButtons({ onClose }: ActionButtonsProps) {
	const sendFilterAnalytics = useFilterActionsAnalytics();
	const sendMeatballAnalytics = useCallback(() => {
		sendFilterAnalytics(BUTTON, {
			level: LEVEL_ONE,
			componentType: FIXED_ITEM,
			itemId: MEATBALL_DROPDOWN,
		});
	}, [sendFilterAnalytics]);
	return (
		<MoreNavMenuButton onOpen={sendMeatballAnalytics} MenuItems={MoreMenuItems} onClose={onClose} />
	);
}

function MoreMenuItems(_: MoreNavMenuButtonMenuItemsProps) {
	return (
		<MenuGroup>
			<HideL1MenuItemSection menuId={L1_MENU_ID.FILTERS} hasSeparator={false} />
		</MenuGroup>
	);
}

import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';

export const SearchModeSwitcherContainer = ({
	testId,
	children,
	isSearchModeSwitcherOnLeft,
}: {
	testId: string;
	children: React.ReactNode;
	isSearchModeSwitcherOnLeft: boolean;
}) => (
	// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
	<Box xcss={getContainerXcss(isSearchModeSwitcherOnLeft)} testId={testId}>
		{children}
	</Box>
);

const getContainerXcss = (isSearchModeSwitcherOnLeft: boolean) => {
	if (getWillShowNav4()) {
		if (isSearchModeSwitcherOnLeft) {
			return [searchModeSwitcherBaseContainerStyle];
		}
		return [searchModeSwitcherBaseContainerStyle, searchModeSwitcherRightAlignedContainerStyle];
	}

	return [searchModeSwitcherContainerStyle];
};

const searchModeSwitcherBaseContainerStyle = xcss({
	alignSelf: 'start',
	// The toggle container is inline aligned with the JQL editor, and needs to match the height to avoid any misalignment along the horizontal axis
	// However, prior to this change, it only matched the height because some of the content coincidentally matched the height of the left side elements
	// The following two styles will fix that height to match the JQL editor, and align the items to the vertical center
	minHeight: token('space.400', '32px'),
	display: 'flex',
});

const searchModeSwitcherRightAlignedContainerStyle = xcss({
	marginTop: 'space.025',
	marginLeft: 'space.100',
});

const searchModeSwitcherContainerStyle = xcss({
	marginLeft: 'space.100',
	alignSelf: 'start',
	marginTop: 'space.025',

	// The toggle container is inline aligned with the JQL editor, and needs to match the height to avoid any misalignment along the horizontal axis
	// However, prior to this change, it only matched the height because some of the content coincidentally matched the height of the left side elements
	// The following two styles will fix that height to match the JQL editor, and align the items to the vertical center
	minHeight: token('space.400', '32px'),
	display: 'flex',
});

import React, { type ReactNode, type ComponentType } from 'react';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';

export type SpotlightTargetProps = {
	children: ReactNode;
	engagementId: string;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const EngagementSpotlightAsync = lazy<ComponentType<{ engagementId: string }>>(
	() =>
		import(/* webpackChunkName: "async-engagement-spotlight" */ '@atlassiansox/engagekit-ts').then(
			({ EngagementSpotlight }) => EngagementSpotlight,
		),
	{ ssr: false },
);

const SpotlightTourTarget = ({ children, engagementId }: SpotlightTargetProps) => (
	<>
		<SpotlightTarget name={engagementId}>{children}</SpotlightTarget>
		<JSErrorBoundary
			packageName="jiraIssueNavigatorChangeboarding"
			id="async-engagement-spotlight"
			sendToPrivacyUnsafeSplunk
			fallback="unmount"
		>
			<Placeholder name="async-engagement-spotlight">
				<EngagementSpotlightAsync engagementId={engagementId} />
			</Placeholder>
		</JSErrorBoundary>
	</>
);

export default SpotlightTourTarget;

/**
 * @generated SignedSource<<24235a2fe0a3fa513b4cbd04beee84b5>>
 * @relayHash 25fd10f797cfbc8f2ea275990db00ed7
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d35fba039f478a32001720414c597a8c17f630b064e5dd960be7a1d59c61980c

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessTimelineQuery } from './src_jiraBusinessTimelineQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessTimelineQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "d35fba039f478a32001720414c597a8c17f630b064e5dd960be7a1d59c61980c",
    "metadata": {},
    "name": "src_jiraBusinessTimelineQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;

import React, { forwardRef, type Ref } from 'react';
import { IconButton } from '@atlaskit/button/new';
import type { CommonNavMenuButtonProps } from './types.tsx';

export const CommonNavMenuButton = forwardRef(
	({ onClick, icon: Icon, ...props }: CommonNavMenuButtonProps, ref: Ref<HTMLButtonElement>) => (
		<IconButton
			{...props}
			icon={(iconProps) => <Icon {...iconProps} size="small" />}
			onClick={onClick}
			ref={ref}
			spacing="compact"
			appearance="subtle"
		/>
	),
);

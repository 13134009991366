import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorTitle: {
		id: 'jet-plan-action-menu.trash-modal.error-title',
		defaultMessage: "We couldn't delete your plan",
		description: 'Message to be shown in an error flag if the trash plan operation fails.',
	},
	errorDescription: {
		id: 'jet-plan-action-menu.trash-modal.error-description',
		defaultMessage: "We couldn't move {planTitle} to the trash.\nRefresh the page and try again.",
		description: 'Message to be shown in an error flag if the trash plan operation fails.',
	},
});

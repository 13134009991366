import React, { type ReactElement, useEffect, useMemo } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { SECTION_ITEM_DASHBOARDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_DASHBOARDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { useGetCurrentUrl } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/controller/use-get-current-url/index.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { Nav4ExpandableMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-expandable-menu-item/index.tsx';
import { SkeletonNavMenuItems } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/skeleton-nav-menu-items/main.tsx';
import { getTestId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-test-id/index.tsx';
import { useSectionItemAnalytics } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-section-item-analytics/index.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import { useExpandableEntryPointTrigger } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-expandable-entry-point-trigger/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { ExpandableMenuItemContent, ExpandableMenuItemTrigger } from '@atlassian/navigation-system';
import { PACKAGE_NAME, TEAM_NAME } from '../../../common/constants.tsx';
import { Footer } from '../../../common/ui/footer/index.tsx';
import { asyncDashboardsContentViewQuery } from './async-content/entrypoint.tsx';

export type DashboardsDefaultProps = {
	actionsOnHover: ReactElement;
	isExpanded: boolean;
};

export function DashboardsDefault(props: DashboardsDefaultProps) {
	const { actionsOnHover, isExpanded } = props;
	const cloudId = useCloudId();
	const currentURL = useGetCurrentUrl();

	const entryPointParams = useMemo(() => ({ cloudId, currentURL }), [cloudId, currentURL]);
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		asyncDashboardsContentViewQuery,
		entryPointParams,
	);
	const triggerRef = useExpandableEntryPointTrigger(entryPointActions);
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(L1_MENU_ID.DASHBOARDS);
	const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();

	// If starting life expanded, make sure to load.
	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (isExpanded) {
			entryPointActions.load();
		}
	}, [entryPointActions, isExpanded]);

	const menuId = MENU_ID_DASHBOARDS;

	return (
		<Nav4ExpandableMenuItem
			isExpanded={isExpanded}
			menuId={menuId}
			onSystemToggle={(hasExpanded: boolean) => {
				if (hasExpanded) {
					entryPointActions.load();
				}
			}}
			onExpansionToggle={(hasExpanded: boolean) => {
				if (hasExpanded) {
					entryPointActions.load();

					sendSectionItemAnalyticsEvent({
						menuId: L1_MENU_ID.DASHBOARDS,
						sectionItem: SECTION_ITEM_DASHBOARDS,
					});
				}
			}}
		>
			<ExpandableMenuItemTrigger
				interactionName="nav4-sidebar-dashboards-default"
				actionsOnHover={actionsOnHover}
				aria-label={formattedMessage}
				elemBefore={icon}
				ref={triggerRef}
				testId={getTestId(menuId)}
			>
				{formattedMessage}
			</ExpandableMenuItemTrigger>
			<ExpandableMenuItemContent>
				<JiraEntryPointContainer
					// Data stream, will subscribe to load updates.
					entryPointReferenceSubject={entryPointReferenceSubject}
					// Required props for error JSErrorBoundary.
					id="nav4-sidebar-dashboards-default"
					packageName={PACKAGE_NAME}
					// Optional props for error JSErrorBoundary.
					errorFallback="flag"
					teamName={TEAM_NAME}
					// Fallback for `Suspense`.
					fallback={<SkeletonNavMenuItems />}
					// Props for the root component.
					runtimeProps={entryPointParams}
				/>
				<Footer />
			</ExpandableMenuItemContent>
		</Nav4ExpandableMenuItem>
	);
}
